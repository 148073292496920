import React from 'react';
import { observer } from 'mobx-react';
import ChannelImg from 'assets/1ChooseaChannel.png';
import { Input, Radio, Icon } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const CenteredContent = styled.div`
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
  && > * {
    margin-top: 20px;
  }
  && i {
    margin-right: 6px;
  }
  img {
    height: '350px';
    margin: 0 auto;
  }
`;

class CampaignNameSection extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    setValue: PropTypes.func,
    contactMethod: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: 1,
      type: 'chat'
    };
  }

  handleChange(e) {
    this.setState({ value: e });
  }

  render() {
    return (
      <React.Fragment>
        <h1>Name a new campaign</h1>
        <CenteredContent>
          <img alt="Set a name for the campaign" src={ChannelImg} />
          <Input
            size="large"
            placeholder="Enter a Campaign Title"
            value={this.props.title}
            name="title"
            onChange={ev => this.props.setValue('title', ev.target.value)}
          />

          <RadioGroup
            defaultValue={this.props.contactMethod}
            size="large"
            onChange={ev =>
              this.props.setValue('contactMethod', ev.target.value)
            }
          >
            <RadioButton value="chat">
              <Icon type="message" />
              Pitstop Chat Message
            </RadioButton>
            <RadioButton value="email">
              <Icon type="mail" />
              Customer Email
            </RadioButton>
          </RadioGroup>
        </CenteredContent>
      </React.Fragment>
    );
  }
}

export default observer(CampaignNameSection);
