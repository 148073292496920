const AlgorithmScoreExplanations = (algorithm) => {
  if (algorithm === 'Battery') {
    return 'The battery score represents the probability of a battery failure in the next two weeks. Typically, anything below 0.3 is not a cause for concern';
  } else if (algorithm === 'Brakes'){
    return 'The brake score rate of brake wear relative to other vehicles in your fleet, eg a score of 98 means that the vehicle is wearing its brakes faster then 98% of other vehicles in your fleet.';
  } else if (algorithm === 'Diesel Exhaust Fluid'){
    return 'This is the current level of the Diesel Exhaust Fluid tank as a percentage';
  } else if (algorithm === 'Cranking Voltage'){
    return 'The cranking model score is the average battery voltage while the vehicle was starting during its most recent trip.';
  } else if (algorithm === 'Sensor Health'){
    return 'The Sensor Health score is the number of faulty sensors that were detected';
  } else if (algorithm === 'Parked Vehicle  Battery'){
    return 'The Parked Vehicle score is the number of days since the vehicle has started. A high score is only a concern if the battery is already weak. Battery weakness is tracked by the Battery Algorithm.';
  }
};

export default AlgorithmScoreExplanations;