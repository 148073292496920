/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';

const TitleWithSearch = styled.div`
  div {
    margin: 8px 0px;
  }
`;

/**
 *
 * @param {object} options
 * @param {function(e): *} options.onChange
 * @param {string} options.title
 * @param {string} options.dataIndex
 * @param {string} [options.defaultValue]
 * @param {string=""} [options.placeholder]
 * @param {boolean=false} [options.filter]
 * @param {function} options.onChange
 * @param {TableStore} options.tableStore
 * @returns {function(): React.Component}
 * @private
 */
export const titleWithSearch = (options) => {
  return (
    <TitleWithSearch>
      <div>{options.title}</div>

      <Input
        placeholder={options.placeholder}
        disabled={!options.filter}
        onChange={options.onChange}
      />
    </TitleWithSearch>
  );
};
