import React from 'react';

const Cancel = () => {
  return (
    <img
      src={require('assets/svg/cancel.svg')}
      alt="split"
      width="22px"
      height="22px"
      style={{ marginRight: 5 }}
    />
  );
};

export default Cancel;
