import { observable, decorate, action } from 'mobx';
import { webServiceProvider } from 'shared';
import AsyncData from 'stores/abstract/AsyncData';

class TripObject {
  loaded = false;
  type = null;
  id = 0;
  attachments = new AsyncData();

  constructor(jsonObject) {
    const { ...actualObject } = jsonObject;
    Object.assign(this, actualObject);
    this.loaded = true;
    this.id = this.tripId;
  }

  updateType = async type => {
    type = type.toLowerCase();
    if (type !== 'personal' && type !== 'business') {
      throw new Error('Invalid type for trip!');
    }
    await webServiceProvider.put(`v1/trip/${this.tripId}`, { type: type });
    this.type = type;
  };

  getAttachments = async () => {
    try {
      this.attachments.pending = true;
      this.attachments.loaded = false;

      let { data } = await webServiceProvider.get(
        `v1/trip/${this.id}/attachments`
      );

      this.attachments = data;
      this.attachments.isError = false;
    } catch (err) {
      this.attachments.isError = true;
      throw new Error('Error in getting trip file attachments!');
    } finally {
      this.attachments.pending = false;
      this.attachments.loaded = true;
    }
  };
}

export default decorate(TripObject, {
  attachments: observable,
  loaded: observable,
  type: observable,
  updateTrip: action
});
