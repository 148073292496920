import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { computed, decorate } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Alert, Switch } from 'antd';

import { CarStore, ShopStore } from 'stores';

class SampleDataBar extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  description = () => {
    return (
      <div style={{ fontSize: 16 }}>
        Sample data for vehicles and other records are available to help you
        explore how <b>Pitstop</b> works.
        <br />
        <b>Manage sample data</b>{' '}
        <Switch
          onClick={CarStore.toggleDemo}
          checkedChildren="On"
          unCheckedChildren="Off"
          checked={CarStore.demo}
        />
      </div> 
    );
  };

  render() {
    if (
      _.isNil(CarStore.demo) ||
      ShopStore.currentShop.id === -1
    ) return <></>;

    let unallowedPathnames = ['/reset-password', '/login', '/register'];

    const preventDisplayOnFleets = [
      1241, 1245, 1246, 1247, 1249, 1250, 1251, 1252, 1253,
      0, // fallback if shop is not set
      1177 // ferrovial - FSNA
    ];

    const shouldPreventDisplay = () => {
      const dontDisplay = preventDisplayOnFleets.includes(parseInt(ShopStore?.currentShop?.id || 0, 10));
      console.log('dontDisplay SampleDataBar', dontDisplay);
      return dontDisplay;
    };

    return (
      <>
        {
          !unallowedPathnames.includes(this.props.location.pathname) &&
          !shouldPreventDisplay() && (
          <Alert
            style={{ marginBottom: 10, width: 'fit-content' }}
            description={this.description()}
            type="info"
          />
        )}
      </>
    );
  }
}

decorate(SampleDataBar, {
  cars: computed,
  realCars: computed,
  demoCars: computed,
});

export default withRouter(observer(SampleDataBar));
