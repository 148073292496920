import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import moment from 'moment';
import _ from 'lodash';

import AppStore from 'stores/App';

import AlarmMarker from 'components/AlarmMarker';
import { Empty } from 'antd';
import { getLocationPolylines } from '../utils';

const startMarkerUrl = require('./Icons/startmarker.png');
const endMarkerUrl = require('./Icons/endmarker.png');

const markerStyle = {
  position: 'absolute',
  width: '12px',
  height: '12px',
  left: '-6px',
  top: '-6px',
};

function rad(x) {
  return (x * Math.PI) / 180;
}

const AnyReactComponent = ({ style, lat, lng, alt, src }) => (
  <img style={style} lat={lat} lng={lng} alt={alt} src={src} />
);

class SingleTrip extends Component {
  static defaultProps = {
    zoom: 11,
  };
  constructor() {
    super();
    this.startMarker = null;
    this.endMarker = null;
    this.map = null;
    this.maps = null;
    this.polyline = [];
    this.snappedCoordinates = [];
    this.state = {
      center: null,
      lat: null,
      lng: null,
      geodesicPolyline: null,
      alarms: [],
    };
  }

  async componentDidMount() {
    await this.handleMapRender(this.map, this.maps);
    this.getAlarms();
    if (this.map && this.maps) {
      this.renderPolylines(this.map, this.maps);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.geodesicPolyline !== this.state.geodesicPolyline) {
      this.snappedCoordinates = [];
      for (var i = 0; i < this.polyline.length; i++) {
        var latlng = {
          lat: this.polyline[i].location.latitude,
          lng: this.polyline[i].location.longitude,
        };
        this.snappedCoordinates.push(latlng);
      }
    }

    if (
      this.props.snapToRoad !== prevProps.snapToRoad ||
      this.props.selectedTrip.timeEnd !== prevProps.selectedTrip.timeEnd
    ) {
      await this.handleMapRender(this.map, this.maps);
      this.getAlarms();
      if (this.map && this.maps) {
        this.renderPolylines(this.map, this.maps);
      }
    }

    // get analyticsmarker (Graph hover marker) only called when polyline
    if (this.props.currentMarkerTime !== prevProps.currentMarkerTime) {
      this.getAnalyticsMarker(String(this.props.currentMarkerTime));
    }

    // resize the map when toggle the sensor data or emission button
    if (
      this.props.showAnalytics !== prevProps.showAnalytics ||
      this.props.showEmissionTable !== prevProps.showEmissionTable
    ) {
      if (this.map && this.maps) {
        this.renderPolylines(this.map, this.maps);
      }
    }
  }

  getAnalyticsMarker = (time) => {
    // get closest marker on polyline by time
    // similar to getAlarmLocation()
    // let time = String(this.props.CurrentMarkerTime);
    time = _.slice(time, 0, -3);
    const timestampList = [];

    _.forEach(getLocationPolylines(this.props.selectedTrip), (element) => {
      timestampList.push(element.timestamp);
    });

    // get closest timestamp in markers
    const closest = _.reduce(timestampList, (prev, curr) => {
      return Math.abs(curr - Number(time)) < Math.abs(prev - Number(time))
        ? curr
        : prev;
    });

    // get lat and lng for marker with certain timestamp, break loop when found for better performance
    _.each(getLocationPolylines(this.props.selectedTrip), (element) => {
      if (closest !== element.timestamp) return;

      let lat = Number(element.location.latitude);
      let lng = Number(element.location.longitude);

      const closerMarker = this.findClosestMarker(lat, lng);

      this.setState({
        lat: this.snappedCoordinates[closerMarker].lat,
        lng: this.snappedCoordinates[closerMarker].lng,
      });
      return false; // https://stackoverflow.com/questions/33266946/how-to-stop-lodash-js-each-loop
    });
  };

  findClosestMarker(lat, lng) {
    var R = 6371; // radius of earth in km
    var distances = [];
    var closest = -1;
    for (var i = 0; i < this.snappedCoordinates.length; i++) {
      var mlat = this.snappedCoordinates[i].lat;
      var mlng = this.snappedCoordinates[i].lng;
      var dLat = rad(mlat - lat);
      var dLong = rad(mlng - lng);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(lat)) *
          Math.cos(rad(lat)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      distances[i] = d;
      if (closest === -1 || d < distances[closest]) {
        closest = i;
      }
    }
    return closest;
  }

  getDownSampleValues = (pathValues) => {
    // snap to road accepts only 100 points max per call
    if (pathValues.length <= 100) {
      return pathValues;
    } else {
      let sampledValues = [];

      let delnumber = Math.ceil(pathValues.length / 100);

      for (var i = 0; i < pathValues.length; i += delnumber) {
        sampledValues.push(pathValues[i]);
      }

      return sampledValues;
    }
  };

  snapToRoad = async () => {
    let polylines = _.map(
      getLocationPolylines(this.props.selectedTrip),
      (polyline) => [polyline.location.latitude, polyline.location.longitude]
    );

    //sampe down to 100 points max
    let path = this.getDownSampleValues(polylines);

    try {
      const results = await fetch(
        `https://roads.googleapis.com/v1/snapToRoads?path=${path.join(
          '|'
        )}&interpolate=true&key=AIzaSyDxDzCBe05AjpvIN1IJOq1u1q2tqzGzHpo`
      );
      let data = await results.json();

      return data.snappedPoints;
    } catch (e) {
      AppStore.addError('unable to get trip markers');
    }
  };

  handleMapRender = async (map, maps) => {
    // handler for map rendering
    // needs to be called once in render to save map ref
    this.map = map;
    this.maps = maps;

    if (
      this.props.snapToRoad &&
      !_.isEmpty(getLocationPolylines(this.props.selectedTrip))
    ) {
      this.polyline = await this.snapToRoad();
    } else {
      this.polyline = getLocationPolylines(this.props.selectedTrip);
    }

    if (this.map && this.maps) {
      this.renderPolylines(this.map, this.maps);
    }
  };

  async renderPolylines(map, maps) {
    //delete previous polyline
    if (this.state.geodesicPolyline !== null) {
      this.state.geodesicPolyline.setMap(null);
      this.startMarker.setMap(null);
      this.endMarker.setMap(null);
    }

    if (!_.isEmpty(this.polyline)) {
      // if result from google snap to road api
      //render polyline
      let snappedCoordinates = [];
      let startMarker = new maps.LatLng(
        this.props.selectedTrip.locationStart.latitude,
        this.props.selectedTrip.locationStart.longitude
      );

      let endMarker = new maps.LatLng(
        this.props.selectedTrip.locationEnd.latitude,
        this.props.selectedTrip.locationEnd.longitude
      );

      for (var i = 0; i < this.polyline.length; i++) {
        var latlng = new maps.LatLng(
          this.polyline[i].location.latitude,
          this.polyline[i].location.longitude
        );
        snappedCoordinates.push(latlng);
      }

      let geodesicPolyline = new maps.Polyline({
        path: snappedCoordinates,
        geodesic: true,
        options: {
          strokeColor: 'blue',
          strokeOpacity: 0.75,
          strokeWeight: 5,
        },
      });
      geodesicPolyline.setMap(map);

      //render markers
      //startmarker
      this.startMarker = new maps.Marker({
        position: startMarker,
        map: map,
        icon: {
          size: new maps.Size(12, 12),
          scaledSize: new maps.Size(12, 12),
          url: startMarkerUrl,
        },
      });
      this.startMarker.setMap(map);

      //endmarker
      this.endMarker = new maps.Marker({
        position: endMarker,
        width: '12px',
        height: '12px',
        map: map,
        icon: {
          size: new maps.Size(55, 55),
          scaledSize: new maps.Size(55, 55),
          url: endMarkerUrl,
        },
      });
      this.endMarker.setMap(map);
      // set bounds
      var bounds = new maps.LatLngBounds();
      var points = snappedCoordinates;
      for (var n = 0; n < points.length; n++) {
        bounds.extend(points[n]);
      }
      map.fitBounds(bounds);
      this.setState({
        geodesicPolyline: geodesicPolyline,
      });
    } else {
      // no result from snap to road
      let snappedCoordinates = [];

      let polylines = _.map(
        getLocationPolylines(this.props.selectedTrip),
        (polyline) => polyline.location
      );

      if (
        !_.isNil(this.props.selectedTrip.location_polyline) &&
        !_.isEmpty(this.props.selectedTrip.location_polyline)
      ) {
        _.forEach(polylines, (location_polyline) => {
          var pointslatlng = new maps.LatLng(
            location_polyline[0],
            location_polyline[1]
          );
          snappedCoordinates.push(pointslatlng);
        });
      } else {
        if (
          !_.isNil(this.props.selectedTrip.locationStart) &&
          !_.isNil(this.props.selectedTrip.locationEnd) &&
          !_.isEmpty(this.props.selectedTrip.locationStart) &&
          !_.isEmpty(this.props.selectedTrip.locationEnd)
        ) {
          let locationStartLatLng = new maps.LatLng(
            this.props.selectedTrip.locationStart.latitude,
            this.props.selectedTrip.locationStart.longitude
          );
          snappedCoordinates.push(locationStartLatLng);

          let locationEndLatLng = new maps.LatLng(
            this.props.selectedTrip.locationEnd.latitude,
            this.props.selectedTrip.locationEnd.longitude
          );
          snappedCoordinates.push(locationEndLatLng);
        }
      }

      let startMarker = new maps.LatLng(
        this.props.selectedTrip.locationStart.latitude,
        this.props.selectedTrip.locationStart.longitude
      );

      let endMarker = new maps.LatLng(
        this.props.selectedTrip.locationEnd.latitude,
        this.props.selectedTrip.locationEnd.longitude
      );

      if (!_.isEmpty(snappedCoordinates)) {
        let geodesicPolyline = new maps.Polyline({
          path: snappedCoordinates,
          geodesic: true,
          options: {
            strokeColor: 'blue',
            strokeOpacity: 0.75,
            strokeWeight: 5,
          },
        });
        geodesicPolyline.setMap(map);

        //render markers
        //startmarker
        this.startMarker = new maps.Marker({
          position: startMarker,
          map: map,
          icon: {
            size: new maps.Size(12, 12),
            scaledSize: new maps.Size(12, 12),
            url: startMarkerUrl,
          },
        });
        this.startMarker.setMap(map);
        //endmarker
        this.endMarker = new maps.Marker({
          position: endMarker,
          width: '12px',
          height: '12px',
          map: map,
          icon: {
            size: new maps.Size(55, 55),
            scaledSize: new maps.Size(55, 55),
            url: endMarkerUrl,
          },
        });
        this.endMarker.setMap(map);
        // set bounds
        var propsbounds = new maps.LatLngBounds();
        var propspoints = snappedCoordinates;
        for (var m = 0; m < propspoints.length; m++) {
          propsbounds.extend(propspoints[m]);
        }
        map.fitBounds(propsbounds);
        this.setState({
          geodesicPolyline: geodesicPolyline,
        });
      }
    }
  }

  getAlarms = () => {
    //read alarms out of tripobject
    let alarms = [];

    let { selectedTrip } = this.props;

    let tripsData = [selectedTrip];

    _.forEach(tripsData, (trip) => {
      if (_.isNil(trip.alarms) || _.isEmpty(trip.alarms)) {
        return;
      }

      _.forEach(trip.alarms, (element) => {
        this.getAlarmLocation(
          moment(element.triggeredAt)
            .tz('America/Toronto')
            .format('X')
        );

        if (this.latlng) {
          alarms.push({
            name: element.name,
            lat: this.latlng.lat,
            lng: this.latlng.lng,
            triggeredAt: moment(element.triggeredAt).format(
              '"dddd, MMMM Do YYYY, h:mm:ss a"'
            ),
          });

          this.setState({
            alarms: [...alarms],
          });
        }
      });
    });
  };

  getAlarmLocation = (time) => {
    //getting closest marker on polyline by time
    // time = time.slice(0, -3);
    let timelist = [];

    let polylines = getLocationPolylines(this.props.selectedTrip);

    _.forEach(polylines, (element) => {
      timelist.push(element.timestamp);
    });

    //get closest timestamp in markers
    let closest = timelist.reduce(function(prev, curr) {
      return Math.abs(curr - Number(time)) < Math.abs(prev - Number(time))
        ? curr
        : prev;
    });

    if (_.isEmpty(this.snappedCoordinates)) return [];

    _.forEach(polylines, (element) => {
      if (closest === element.timestamp) {
        let lat = Number(element.location[0]);
        let lng = Number(element.location[1]);
        closest = this.findClosestMarker(lat, lng);
        let latlng = {
          lat: this.snappedCoordinates[closest].lat,
          lng: this.snappedCoordinates[closest].lng,
        };
        this.latlng = latlng;
      }
    });
  };

  getMapOptions = (maps) => {
    return {
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.TOP_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },
    };
  };

  render() {
    if (_.isEmpty(getLocationPolylines(this.props.selectedTrip))) {
      return <Empty description="No available location data" />;
    }

    return (
      // Important! Always set the container height explicitly
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyDxDzCBe05AjpvIN1IJOq1u1q2tqzGzHpo',
          }}
          defaultCenter={this.state.center || { lat: -79, lng: 43 }}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => this.handleMapRender(map, maps)}
          hoverDistance={12 / 2}
          options={this.getMapOptions}
        >
          {_.map(this.state.alarms, (alarm, i) => {
            return (
              <AlarmMarker
                key={i}
                lat={alarm.lat}
                lng={alarm.lng}
                name={alarm.name}
                triggeredAt={alarm.triggeredAt}
              />
            );
          })}
          {this.state.lat !== null && this.state.lng !== null ? (
            // remove errors invalid attribute name
            <AnyReactComponent
              style={markerStyle}
              lat={this.state.lat}
              lng={this.state.lng}
              alt="marker"
              src={startMarkerUrl}
            />
          ) : (
            <AnyReactComponent />
          )}
        </GoogleMapReact>
      </div>
    );
  }
}

AnyReactComponent.propTypes = {
  text: PropTypes.object,
  style: PropTypes.object,
  lat: PropTypes.number,
  lng: PropTypes.number,
  alt: PropTypes.string,
  src: PropTypes.string,
};

SingleTrip.propTypes = {
  selectedTrip: PropTypes.object,
  trips: PropTypes.object,
  zoom: PropTypes.number,
  currentMarkerTime: PropTypes.number,
  showAnalytics: PropTypes.bool,
  showEmissionTable: PropTypes.bool,
  snapToRoad: PropTypes.bool,
  sorting: PropTypes.bool,
};

export default SingleTrip;
