import { transaction, decorate, action } from 'mobx';
import AppStore from './App';
import ShopStore from './ShopStore';
import CurrentUserStore from './CurrentUserStore';
import AbstractStore from './abstract/AbstractStore';
import UserObject, { UserRoles } from './Classes/UserObject';
import { webServiceProvider } from '../shared';
import TableStore from './abstract/TableStore';
import { errors } from 'helpers';

class UserStoreC extends AbstractStore {
  MESSAGE = 'Loading In User Data';

  fetchUser = async (userId, fetchCars = true) => {
    try {
      if (!userId) {
        throw new Error('Invalid Id');
      }
      const user = await webServiceProvider.getById('user', userId);
      const userObject = new UserObject(user);
      if (userObject.id === undefined) {
        AppStore.addError('Unable to load user');
        return;
      }

      this.data.set(Number(userId), userObject);

      if (fetchCars) {
        await userObject.getCars({ limit: userObject.totalCarsAssociated });
      }
    } catch (err) {
      errors.response(err, `Error fetching user data with id ${userId}`);
    }
  };

  UserObject(props) {
    return new UserObject(props);
  }

  createUsers = async (users) => {
    const result = await Promise.all(users.map(this.createUser));
    return result;
  };

  addUsersToShops = async (users) => {
    const result = await Promise.all(users.map(this.addUserToShop));
    return result;
  };

  getUserCredentialsForPrinting = async (user) => {
    try {
      const response = await webServiceProvider.get(
        `user/${user.id}/credentials-for-printing`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  addUserToShop = async (user) => {
    try {
      const result = await webServiceProvider.post(
        'user/add-user-to-shop',
        user
      );
      const userObject = new UserObject(result);
      this.data.set(userObject.id, userObject);
      return userObject;
    } catch (error) {
      throw error;
    }
  };

  createUser = async (data) => {
    try {
      const user = await webServiceProvider.post('user', data);
      const userObject = new UserObject(user);
      this.data.set(userObject.id, userObject);
      return user;
    } catch (err) {
      throw err;
    }
  };

  async changePassword(currentPassword, newPassword, callback = () => {}) {
    try {
      const response = await webServiceProvider.post(
        `user/${CurrentUserStore.user.userId}/password`,
        {
          oldPass: currentPassword,
          newPass: newPassword,
        }
      );
      callback(true, response.message);
    } catch (e) {
      callback(false, e.message);
    }
  }

  async dealershipChangePassword(userId, newPassword) {
    try {
      if (['dealership', 'admin'].includes(CurrentUserStore.user.role) === false) {
        throw new Error('You do not have permission to change this password');
      }
      const response = await webServiceProvider.post(
        `user/${userId}/dealership-change-password`,
        {
          newPass: newPassword,
        }
      );
      return response;
    } catch (e) {
      throw e;
    }
  }

  addUserToStore = (userId, user) => {
    this.data.set(
      userId,
      user instanceof UserObject ? user : new UserObject(user)
    );
  };

  fetchShopUsers = async (shopId = ShopStore.currentShop.id) => {
    try {
      if (shopId === '-1' || shopId === -1) {
        return [];
      }
      const { items: data } = await webServiceProvider.getMany('v1/user', {
        offset: 0,
        limit: 1000,
        shopId,
      });
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  fetchUsersForTable = async (
    { offset, limit, filter, sort } = {},
    tableStore = new TableStore(),
    shopId = ShopStore.currentShop.id
  ) => {
    if (tableStore.pending) {
      return;
    }
    tableStore.pending = true;
    tableStore.loaded = false;
    offset = offset === undefined ? tableStore.offset : offset;
    limit = limit === undefined ? tableStore.limit : limit;
    filter = filter || tableStore.filter;
    sort = sort === undefined ? tableStore.sort : sort;
    if (shopId === -1 && CurrentUserStore.user.role === 'admin') {
      shopId = undefined;
    }
    try {
      const params = {
        offset: offset,
        limit,
        ...filter,
        ...(shopId && { shopId }),
      };

      if (sort) {
        params.sort = sort;
      } else {
        sort = undefined;
      }

      const { items: data, total } = await webServiceProvider.getMany(
        'v1/user',
        params
      );
      transaction(() => {
        tableStore.data = [];
        for (let i = 0; i < data.length; i++) {
          const userObj = new UserObject(data[i]);
          this.data.set(Number(userObj.id), userObj);
          tableStore.data.push(Number(userObj.id));
        }
        tableStore.setDataMetadata(total, offset, limit, sort, filter);
      });
      return data;
    } catch (e) {
      tableStore.setError('Unable to load services.');
    }
  };

  updateUser = async (id, updatedValues, callback = () => {}) => {
    const { firstName, lastName, displayedRole, phone } = updatedValues;
    let user = this.data.get(id);
    if (id === CurrentUserStore.user.id) {
      user = CurrentUserStore.user;
    }
    if (user) {
      let {
        firstName: oldFirstName,
        lastName: oldLastName,
        shopRole: oldShopRole,
        phone: oldPhone,
      } = user;
      transaction(() => {
        user.firstName = firstName || oldFirstName;
        user.lastName = lastName || oldLastName;
        if (displayedRole === 'Fleet Manager') {
          user.role = 'dealership';
        } else if (displayedRole === 'Driver') {
          user.role = 'customer';
        } else {
          user.role = displayedRole;
        }

        user.shopRole = user.shopApiRole || oldShopRole;
        user.phone = phone || oldPhone;
      });
      try {
        await webServiceProvider.put('v1/user', {
          userId: id,
          id: id,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: phone || '',
          ...(CurrentUserStore.user.role !== UserRoles.customer && {
            shopRole: user.shopApiRole,
            role: user.role,
          }),
        });
        // throw new Error();
        AppStore.addSuccess('Successfully updated user.');
        callback(true);
      } catch (e) {
        transaction(() => {
          user.firstName = oldFirstName;
          user.lastName = oldLastName;
          user.shopRole = oldShopRole;
          user.phone = oldPhone;
        });
        AppStore.addError('Unable to update user.');
        callback(false);
      }
    } else {
      AppStore.addError('Unable to update user.');
      callback(false);
    }
  };

  deleteUser = async (id) => {
    const result = await webServiceProvider.delete(`v1/users/${id}`);
    return result;
  };

  async updateUser(userId, firstName, lastName, phone, callback = () => {}) {
    try {
      if (userId == null) {
        userId = CurrentUserStore.user.userId;
      }
      if (firstName == null) {
        firstName = '';
      }
      if (lastName == null) {
        lastName = '';
      }
      if (phone == null) {
        phone = '';
      }

      callback(true);
    } catch (e) {
      callback(false, e.message);
    }
  }

  sendInvitationEmail = async (shopId, userId, inviterId) =>
    await webServiceProvider.post(
      `v1/shops/${shopId}/users/${userId}/invitation`,
      {
        inviterId: inviterId,
      }
    );
}

decorate(UserStoreC, {
  fetchUsersForTable: action,
});

var UserStore = new UserStoreC();
export default UserStore;
