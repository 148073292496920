import React, { Component, Fragment } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Row, Col, DatePicker, Select, Statistic, Icon, Card, Spin } from 'antd';

import { LineGraph } from 'shared/PitstopUI/PitstopChart';
import CurrentUserStore from 'stores/CurrentUserStore';
import { ShopStore } from 'stores';

const { MonthPicker, WeekPicker } = DatePicker;

const TitleWrapper = styled.div`
  position: relative;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px;
`;

const { Option } = Select;

class FireflyUtilizationPage extends Component {
  state = {
    startDate: moment(),
    endDate: moment(),
    currentView: 'm',
    loading: false,
    totalHours: 0,
    tripsData: [],
    graphOptions: {
      title: '',
      exporting: {
        enabled: true,
      },
      chart: {
        type: 'line',
        zoomType: 'x',
        events: {
          selection: this.onMouseSelection,
        },
      },
      plotOptions: {
        series: {
          point: {
            events: {
              mouseOver: this.onMouseOver,
            },
          },
        },
      },
      tooltip: {
        split: true,
        distance: 30,
        snap: 2000,
        padding: 5,
      },
      xAxis: {
        type: 'category',
        crosshair: true,
        events: {
          setExtremes: this.onSetExtremes,
        },
      },
      time: {
        getTimezoneOffset: (timestamp) => {
          var zone = CurrentUserStore.user.settings.timezone,
            timezoneOffset = -moment.tz(timestamp, zone).utcOffset();

          return timezoneOffset;
        },
      },
      series: [],
    },
    alarmBounds: [],
    containerProps: {
      'style': {
        'height': 350,
        'width': undefined
      }
    },
  };

  componentDidMount () {
    this.setState({
      startDate: moment().startOf('year'),
      endDate: moment(),
      graphOptions: {
        ...this.state.graphOptions,
        series: []
      }
    }, this.getTripsInRange);
  }

  setEmissionTime = (data) => {}

  setCurrentMarkerTime = (data) => {}

  onMouseOver = (point) => {
    this.setCurrentMarkerTime &&
      this.setCurrentMarkerTime(point.target.x);
  };

  onMouseSelection = (event) => {
    if (event.xAxis) {
      this.setEmissionTime({
        startTimestamp: moment
          .tz(event.xAxis[0].min, CurrentUserStore.user.settings.timezone)
          .unix(),
        endTimestamp: moment
          .tz(event.xAxis[0].max, CurrentUserStore.user.settings.timezone)
          .unix(),
      });
    }
  };

  onSetExtremes = async (event) => {
    if (typeof event.min == 'undefined' && typeof event.max == 'undefined') {
      this.setEmissionTime({
        startTimestamp: moment().subtract(6, 'months')
          .tz(CurrentUserStore.user.settings.timezone)
          .unix(),
        endTimestamp: moment()
          .tz(CurrentUserStore.user.settings.timezone)
          .unix(),
      });
    }
  };

  calculateTheTotalHours = () => {
    const totalHours = _.sumBy(this.state.tripsData, 'hours');
    this.setState({ totalHours });
  }

  getMonthNamesBetweenDates = (from = this.state.startDate, to = this.state.endDate) => {
    const monthYear = [];

    const auxFrom = moment(from);
    const auxTo = moment(to);

    while (auxTo > auxFrom || auxFrom.format('M') === auxTo.format('M')) {
      monthYear.push(auxFrom.format('MMM - YYYY'));
      auxFrom.add(1,'month');
    }

    return monthYear;
  }

  getDaysBetweenDates = (from = this.state.startDate, to = this.state.endDate) => {
    const dayArr = [];

    const auxFrom = moment(from);
    const auxTo = moment(to);

    while (auxTo > auxFrom || auxFrom.format('YYYY-MM-DD') === auxTo.format('YYYY-MM-DD')) {
      dayArr.push(auxFrom.format('YYYY-MM-DD'));
      auxFrom.add(1,'day');
    }

    return dayArr;
  }

  getWeekNamesBetweenDates = (from = this.state.startDate, to = this.state.endDate) => {
    const weekNamesArr = [];

    const auxFrom = moment(from);
    const auxTo = moment(to);

    const formattedWeekAndMonth = (m) => {
      return `${m.format('MMM/YYYY')} - ${m.format('DD')}`;
    };

    while (auxTo > auxFrom || formattedWeekAndMonth(auxFrom) === formattedWeekAndMonth(auxTo)) {
      weekNamesArr.push({
        name: formattedWeekAndMonth(auxFrom),
        startDate: moment.tz(auxFrom, CurrentUserStore.user.settings.timezone).startOf('week'),
        endDate: moment.tz(auxFrom, CurrentUserStore.user.settings.timezone).endOf('week'),
      });
      auxFrom.add(1, 'week');
    }

    return weekNamesArr;
  }

  /**
   *
   * @param {*} monthName format: YYYY-MM
   * @param {*} tripsData []
   */
  getTripsInMonthTotalHours = (monthName, tripsData) => {
    return _.sumBy(tripsData, (dateAndHours) => {
      if (moment(dateAndHours.date, 'YYYY-MM-DD').format('MMM - YYYY') === monthName) {
        return dateAndHours.hours;
      }
      return 0;
    });
  }

  getTripsInWeekTotalHours = (weekStartDate, weekEndDate, tripsData) => {
    return _.sumBy(tripsData, (dateAndHours) => {
      if (moment(dateAndHours.date, 'YYYY-MM-DD').isBetween(weekStartDate, weekEndDate)) {
        return dateAndHours.hours;
      }
      return 0;
    });
  }

  getTripsInDayTotalHours = (dayName, tripsData) => {
    return _.sumBy(tripsData, (dateAndHours) => {
      if (dateAndHours.date === dayName) {
        return dateAndHours.hours;
      }
      return 0;
    });
  }

  updateChartSeriesBasedOnViewType = () => {
    const { currentView, tripsData } = this.state;
    const series = [];
    if (currentView === 'm') {
      // get the month names between the start and end dates
      const monthNames = this.getMonthNamesBetweenDates();
      series.push({
        name: 'Total Driving Hours',
        data: _.map(monthNames, (monthName) => {
          return [
            monthName,
            parseFloat(this.getTripsInMonthTotalHours(monthName, tripsData).toFixed(2)),
          ];
        })
      });
    } else if (currentView === 'w') {
      // get the week names between the start and end dates
      const weeks = this.getWeekNamesBetweenDates();
      series.push({
        name: 'Total Driving Hours',
        data: _.map(weeks, (week) => {
          return [
            week.name,
            parseFloat(this.getTripsInWeekTotalHours(week.startDate, week.endDate, tripsData).toFixed(2)),
          ];
        })
      });
    } else if (currentView === 'd') {
      // get days between the start and end dates
      const dayNames = this.getDaysBetweenDates();
      series.push({
        name: 'Total Driving Hours',
        data: _.map(dayNames, (dayName) => {
          return [
            dayName,
            parseFloat(this.getTripsInDayTotalHours(dayName, tripsData).toFixed(2)),
          ];
        })
      });
    }
    this.setState({
      graphOptions: {
        ...this.state.graphOptions,
        series,
      },
    });
  }

  getTripsInRange = async () => {
    try {
      this.setState({ loading: true });
      const { startDate, endDate } = this.state;
      const tripsData = await ShopStore.getShopTripsInRange(
        ShopStore.currentShop.id,
        moment.tz(startDate, CurrentUserStore.user.settings.timezone).unix(),
        moment.tz(endDate, CurrentUserStore.user.settings.timezone).unix(),
      );
      this.setState({ tripsData });
      this.calculateTheTotalHours();
      this.updateChartSeriesBasedOnViewType();
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  onChangeView = (value) => {
    this.setState({ currentView: value }, () => {
      this.updateChartSeriesBasedOnViewType();
    });
  }

  datePicker = () => {
    return (
      <>
        <Col lg={3} xs={6}>
            <>Start Date</>
            <br />
            <DatePicker
              placeholder="Start Date"
              value={this.state.startDate}
              onChange={(startDate) => {
                this.setState({
                  startDate,
                }, this.getTripsInRange);

              }}
            />
          </Col>

          <Col lg={3} xs={6}>
            <>End Date</>
            <br />
            <DatePicker
              placeholder="End Date"
              value={this.state.endDate}
              onChange={(endDate) => {
                this.setState({
                  endDate,
                }, this.getTripsInRange);
              }}
            />
          </Col>
      </>
    );
  }

  monthPicker = () => {
    return (
      <>
        <Col lg={3} xs={6}>
            <>Start Month</>
            <br />
            <MonthPicker
              placeholder="Start Month"
              value={this.state.startDate}
              onChange={(startDate) => {
                this.setState({
                  startDate,
                }, this.getTripsInRange);

              }}
            />
          </Col>

          <Col lg={3} xs={6}>
            <>End Month</>
            <br />
            <MonthPicker
              placeholder="End Month"
              value={this.state.endDate}
              onChange={(endDate) => {
                this.setState({
                  endDate,
                }, this.getTripsInRange);
              }}
            />
          </Col>
      </>
    );
  }

  weekPicker = () => {
    return (
      <>
        <Col lg={3} xs={6}>
            <>Start Week</>
            <br />
            <WeekPicker
              placeholder="Start Week"
              format="YYYY-wo"
              value={this.state.startDate}
              onChange={(startDate) => {
                this.setState({
                  startDate: startDate.startOf('week'),
                }, this.getTripsInRange);

              }}
            />
          </Col>

          <Col lg={3} xs={6}>
            <>End Week</>
            <br />
            <WeekPicker
              placeholder="End Week"
              format="YYYY-wo"
              value={this.state.endDate}
              onChange={(endDate) => {
                this.setState({
                  endDate: endDate.endOf('week'),
                }, this.getTripsInRange);
              }}
            />
          </Col>
      </>
    );
  }

  renderDateOrMonthOrWeekPicker () {
    const { currentView } = this.state;
    if (currentView === 'd') {
      return this.datePicker();
    }
    if (currentView === 'w') {
      return this.weekPicker();
    }
    return this.monthPicker();
  }

  render () {
    let { graphOptions, containerProps, alarmBounds } = this.state;

    return this.state.loading
    ? <LoadingContainer><Spin tip="Loading..." /></LoadingContainer>
    : (
      <Fragment>
        <TitleWrapper>
          <h1>Firefly Utilization Report</h1>
        </TitleWrapper>

        <Row style={{ height: 95 }} gutter={8}>
          {this.renderDateOrMonthOrWeekPicker()}
        </Row>

        <Row type="flex" justify="space-between" align="bottom">
          <Col span={8}>
            <Card>
              <Statistic
                title={`Total hours between ${moment(this.state.startDate).format('MMM Do YYYY')} and ${moment(this.state.endDate).format('MMM Do YYYY')}`}
                prefix={<Icon type="clock-circle" />}
                value={`${parseFloat(this.state.totalHours).toFixed(2)} hours`}
              />
            </Card>
          </Col>
          <Col span={4}>
            <>Select View</>
            <br />
            <Select
              value={this.state.currentView}
              style={{ width: '100%' }}
              placeholder="Select View"
              onChange={this.onChangeView}
            >
              <Option
                key={'m'}
                value={'m'}
              >
                Monthly
              </Option>
              <Option
                key={'w'}
                value={'w'}
              >
                Weekly
              </Option>
              <Option
                key={'d'}
                value={'d'}
              >
                Daily
              </Option>
            </Select>
          </Col>
        </Row>

        <Row>
          <Col lg={24}>
            <LineGraph
              tripId={1}
              options={graphOptions}
              alarmBounds={alarmBounds}
              containerProps={containerProps}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default observer(FireflyUtilizationPage);
