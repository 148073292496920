import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Button, Col, Row, Select } from 'antd';

import { PitstopModal } from 'shared/PitstopUI';
import { AppStore } from 'stores';

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/boost')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);

const LineGraph = (props) => {
  const modalID = `SCALE_CUSTOMIZATION_${props.tripId}`;

  const [graphOptions, setGraphOptions] = useState(props.options);
  const [scaleSettings, setScaleSettings] = useState({});

  const getYAxis = useCallback(() => {
    let { series } = props.options;
    let keys = _.map(series, (serie) => serie.name);

    return _.map([...keys], (key, index) => {
      return {
        title: {
          text: key,
        },
        opposite: index % 2 !== 0,
        max: scaleSettings[key] ? scaleSettings[key].max : null,
        min: scaleSettings[key] ? scaleSettings[key].min : null,
      };
    });
  }, [props.options, scaleSettings]);

  const getSeries = useCallback(() => {
    let { series } = props.options;
    let yAxis = getYAxis();

    // check if series has updated keys to avoid serie not found key
    // when unselect graph keys
    let _series = _.filter(
      [...series],
      (s) => yAxis.findIndex((x) => x.title.text === s.name) !== -1
    );

    let result = _.map([..._series], (s) => {
      return {
        ...s,
        yAxis: yAxis.findIndex((x) => x.title.text === s.name),
        size: s.data.length,
        boostThreshold: 1,
        boost: {
          useGPUTranslations: true,
          // Chart-level boost when there are more than 3 series in the chart
          seriesThreshold: 3,
        },
      };
    });

    return result;
  }, [getYAxis, props.options]);

  useEffect(() => {
    let chartMenuItems =
      Highcharts.getOptions().exporting.buttons.contextButton.menuItems;

    if (
      _.filter(chartMenuItems, (item) => item.text === 'Scale Customization')
        .length === 0
    ) {
      chartMenuItems.push({
        text: 'Scale Customization',
        onclick: function () {
          AppStore.openModals.set(modalID, true);
        },
      });
    }

    setGraphOptions(prevGraphOptions => {return {
      ...prevGraphOptions,
      xAxis: {
        ...prevGraphOptions.xAxis,
        plotBands: props.alarmBounds,
      },
      series: getSeries(),
      yAxis: getYAxis(),
    };
  });
  }, [props.options.series, props.alarmBounds, modalID, getYAxis, getSeries]);

  const onScaleChange = (selectedKey, scaleOnKey) => {
    let serieByScaleOnKey = _.filter(
      getSeries(),
      (serie) => serie.name === scaleOnKey
    );

    let dataByScaleOnKey = _.get(serieByScaleOnKey[0], 'data');

    dataByScaleOnKey = _.map(dataByScaleOnKey, (data) => data[1] || 0);

    let _scaleSettings = scaleSettings;

    if (selectedKey === scaleOnKey && _.get(scaleSettings, selectedKey)) {
      delete scaleSettings[selectedKey];
    } else {
      _scaleSettings[selectedKey] = {
        scaleOnKey,
        max: _.max(dataByScaleOnKey),
        min: _.min(dataByScaleOnKey),
      };

      _scaleSettings[scaleOnKey] = {
        scaleOnKey,
        max: _.max(dataByScaleOnKey),
        min: _.min(dataByScaleOnKey),
      };
    }

    setScaleSettings(_scaleSettings);

    setGraphOptions({
      ...graphOptions,
      xAxis: {
        ...graphOptions.xAxis,
        plotBands: props.alarmBounds,
      },
      series: getSeries(),
      yAxis: getYAxis(),
    });
  };

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={graphOptions}
        containerProps={props.containerProps}
        immutable={true}
      />

      <PitstopModal id={modalID} title={null} width={500} onOk={() => {}}>
        <p style={{ fontWeight: 600, marginBottom: 16 }}>
          You can customize graph scale on your preference
        </p>

        <Row type="flex" gutter={[16, 16]}>
          <Col span={8} style={{ fontWeight: 600 }}>
            PID Selected Key
          </Col>
          <Col span={8} style={{ fontWeight: 600 }}>
            Scale On
          </Col>
        </Row>

        {_.map(getSeries(), (serie) => {
          return (
            <Row type="flex" gutter={[16, 16]} key={serie.name}>
              <Col span={8}>{serie.name}</Col>

              <Col span={8}>
                <Select
                  value={
                    _.get(scaleSettings, serie.name)
                      ? _.get(scaleSettings, serie.name)['scaleOnKey']
                      : serie.name
                  }
                  style={{ width: '100%' }}
                  onChange={(val) => onScaleChange(serie.name, val)}
                >
                  {_.map(getSeries(), (serie) => {
                    return (
                      <Select.Option key={serie.name} value={serie.name}>
                        {serie.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          );
        })}

        <Row gutter={[16, 16]}>
          <Col>
            <Button
              onClick={() => {
                _.forEach(
                  _.keys(scaleSettings),
                  (key) => delete scaleSettings[key]
                );

                setGraphOptions({
                  ...graphOptions,
                  xAxis: {
                    ...graphOptions.xAxis,
                    plotBands: props.alarmBounds,
                  },
                  series: getSeries(),
                  yAxis: getYAxis(),
                });
              }}
            >
              Reset Default Scale
            </Button>
          </Col>
        </Row>
      </PitstopModal>
    </>
  );
};

LineGraph.propTypes = {
  alarmBounds: PropTypes.array,
  options: PropTypes.object.isRequired,
  containerProps: PropTypes.object,
  tripId: PropTypes.number,
};

export default LineGraph;
