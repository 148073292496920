import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import { Button, DatePicker, Form, Icon, Tooltip } from 'antd';
import PitstopModal from 'shared/PitstopUI/PitstopModal';

import { AppStore, IntegrationStore, ShopStore } from 'stores';

import { SmartcarAuth } from './smartcarAuth';

class MyForm extends Component {
  static propTypes = {
    form: PropTypes.object,
  };

  disabledDate(current) {
    return (
      current &&
      current <
        moment()
          .subtract(90, 'day')
          .endOf('day')
    );
  }

  resetSmartcarConnection() {
    const { setFieldsValue } = this.props.form;
    setFieldsValue({ smartcarConnection: null });
  }

  getConnectionButton() {
    const { getFieldValue, setFieldsValue } = this.props.form;

    const smartcarConnection = getFieldValue('smartcarConnection');

    if (smartcarConnection) {
      return (
        <div style={{ display: 'block' }}>
          <span style={{ color: 'green' }}>connected </span>
          <Icon
            type="close-circle"
            onClick={() => this.resetSmartcarConnection()}
          />
        </div>
      );
    } else {
      const smartcarAuth = new SmartcarAuth({
        onComplete: (err, code, status) => {
          const smartcarConnection = { err, code, status };
          setFieldsValue({ smartcarConnection });
        },
      });
      return <Button onClick={() => smartcarAuth.authorize()}>Connect</Button>;
    }
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    const layout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Form {...layout} onSubmit={this.handleSubmit}>
        <Form.Item label="Alias">
          {getFieldDecorator('alias', {
            initialValue: ShopStore.currentShop.name,
          })(<b>{ShopStore.currentShop.name}</b>)}
        </Form.Item>
        <Form.Item label="Connect to Smartcar">
          {getFieldDecorator('smartcarConnection', {
            rules: [
              {
                validator: (rule, value, callback) => {
                  const smartcarConnection = getFieldValue(
                    'smartcarConnection'
                  );
                  if (!smartcarConnection) {
                    return callback('not connected to smartcar.');
                  }
                  return callback();
                },
              },
            ],
          })(this.getConnectionButton())}
        </Form.Item>
        <Form.Item
          label={
            <span>
              Start pulling data from &nbsp;
              <Tooltip
                title={
                  'If empty, the integration syncs data since current time'
                }
              >
                <Icon type={'question-circle-o'} />
              </Tooltip>
            </span>
          }
        >
          {getFieldDecorator('migrationStartAt', [{ type: 'object' }])(
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              disabledDate={this.disabledDate}
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          )}
        </Form.Item>
      </Form>
    );
  }
}

const WrappedForm = Form.create({ name: 'sc_integration_form' })(MyForm);

class SmartcarFormModal extends React.Component {
  constructor(...args) {
    super(...args);

    this.handleCreate = this.handleCreate.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.saveFormRef = this.saveFormRef.bind(this);
  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    shopId: PropTypes.number.isRequired,
    afterCreate: PropTypes.func.isRequired,
    form: PropTypes.object,
  };

  async handleCreate() {
    const { shopId, afterCreate } = this.props;
    const { form } = this.formRef.props;
    const values = await new Promise((resolve, reject) => {
      form.validateFields((err, values) => {
        if (err) {
          // hack to bypass PitstopModal's behaviour of closing modal after handleCreate() returns;
          setImmediate(() => form.validateFields());
          return reject(err);
        }
        return resolve(values);
      });
    });

    try {
      const { alias, migrationStartAt, smartcarConnection } = values;
      const authorizationCode = _.get(smartcarConnection, 'code');
      if (!authorizationCode) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('Cannot retrieve smartcar authorization code');
      }

      await IntegrationStore.createSmartcarIntegrationRecord({
        shopId: shopId,
        alias: alias,
        authorizationCode: authorizationCode,
        defaultMigrationStartTimestamp: migrationStartAt,
      });
      //
      form.resetFields();
      afterCreate && afterCreate();
      IntegrationStore.isReloaded = true;
    } catch (err) {
      const message = _.get(err, 'response.data.message') || err.message;
      AppStore.addError(`unable to create integration record: '${message}`);
    }
  }

  handleCancel() {}

  saveFormRef(formRef) {
    this.formRef = formRef;
  }

  render() {
    const { id } = this.props;

    return (
      <PitstopModal
        id={id}
        title="Add Smartcar Integration"
        okText="Create"
        onCancel={this.handleCancel}
        onOk={() => this.handleCreate()}
        successMessage={'Integration Record Created.'}
      >
        <WrappedForm wrappedComponentRef={this.saveFormRef} />
      </PitstopModal>
    );
  }
}

export default SmartcarFormModal;
