import React, { Component } from 'react';
import { Modal } from 'antd';
import PurchaseOrderForm from './PurchaseOrdersForm';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DarkHeaderModal = styled(Modal)`
  & {
    width: 70% !important;

    @media (max-width: 1615px) {
      width: 80% !important;
    }

    @media (max-width: 1366px) {
      width: 90% !important;
    }

    @media (max-width: 1024px) {
      width: 95% !important;
    }

    @media (max-width: 768px) {
      width: 95% !important;
    }
  }

  .ant-modal-content {
    .ant-modal-close {
      .ant-modal-close-x {
        color: #ffffff;
      }
    }

    .ant-modal-header {
      background-color: #05103a;
      border-bottom: 0;

      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-body {
      padding: 12px 18px;
    }
  }

  .footer-row {
    margin-top: 21px;
  }

  .save-btn {
    margin-left: 8px;
  }
`;

class PurchaseOrderAddModal extends Component {
  randomNumber;

  componentDidMount() {
    this.randomNumber = Math.floor(100000 + Math.random() * 900000);
  }

  render() {
    return (
      <DarkHeaderModal
        title="Add Purchase Order"
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        footer={null}
        style={{ top: 20 }}
      >
        <PurchaseOrderForm
          childRef={() => {}}
          purchaseOrder={{
            purchase_order_number: this.randomNumber,
          }}
          onSave={this.props.onSave}
          onCancel={this.props.onCancel}
          isNew={true}
          hideSelectFleet={true}
        />
      </DarkHeaderModal>
    );
  }
}

// PurchaseOrderAddModal props
PurchaseOrderAddModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default PurchaseOrderAddModal;
