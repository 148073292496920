import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { Spin, Typography, Icon } from 'antd';

import { momentLocalizer } from 'react-big-calendar';

import {
  ShopStore,
  CurrentUserStore,
  IssueStore,
  ServiceScheduleStore,
  AppointmentStore
} from 'stores';
import { UserRoles } from 'stores/Classes/UserObject';

import DealershipChooser from 'components/DealershipChooser';
import PitstopCalendar from './PitstopCalendar';

// import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { decorate, observable, observe } from 'mobx';

const { Text } = Typography;

const rescheduleSuggestionColor = '#EAE0CC';
const currentDayColor = '#E1EFFE';

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const RescheduleSuggestionDayColorLegend = styled.div`
  width: 25px;
  height: 25px;
  background-color: ${rescheduleSuggestionColor};
  margin-right: 3px;
`;

const CurrentDayColorLegend = styled.div`
  width: 25px;
  height: 25px;
  background-color: ${currentDayColor};
  margin-right: 3px;
`;

const ColorsLegendRow = styled.div`
  display: flex;
  margin-top: 5px;
`;

// a localizer for BigCalendar
const localizer = momentLocalizer(moment);

class ServiceScheduleCalendar extends Component {
  state = {
    date: this.props.date,
    services: [],
    appointments: [],
    selectedStart: null,
    selectedEnd: null,
    startDate: moment().startOf('month').subtract(3, 'days').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').add(3, 'days').format('YYYY-MM-DD'),
    suggestedRescheduleDates: [],
    currentCalendarView: 'month'
  };

  isLoading = false;

  getShopId = () => {
    return this.props.shopId || ShopStore.currentShop.id;
  };

  getArrayOfNextDays = (range) => {
    if (!range) {
      throw new Error('Range is required');
    }
    const days = [];
    const dateStart = moment();
    const dateEnd = moment().add(range, 'days');
    while (dateEnd.diff(dateStart, 'days') >= 0) {
      days.push(dateStart.format());
      dateStart.add(1, 'days');
    }
    return days;
  };

  loadPMsServices = async (
    startDate = this.state.startDate,
    endDate = this.state.endDate
  ) => {
    if (this.getShopId() !== -1) {
      try {
        this.isLoading = true;
        const services = await IssueStore.getPMsAndAssetsByShop({
          startDueDate: startDate,
          endDueDate: endDate,
        }, this.getShopId());
        const getTitleBasedOnOtherServices = (service) => {
          // check if there are services with priority higher than 3 - Critical
          const hasHighPriorityServices = _.some(service.otherServicesCount, service => service.priority > 3);
          if (hasHighPriorityServices) {
            return 'Service Immediately';
          }
          // check if there are services with priority higher than 1 - Major
          const hasMediumPriorityServices = _.some(service.otherServicesCount, service => service.priority > 1);
          if (hasMediumPriorityServices) {
            // check if the PM service is less than 7 days ahead
            const isPMServiceLessThan7DaysAhead = moment(service.due_date).diff(moment(), 'days') <= 7;
            if (isPMServiceLessThan7DaysAhead) {
              return 'No Reschedule Required';
            }
            return 'Reschedule Required';
          }
          // if only low priority services - Minors
          return 'No Reschedule Required';
        };
        const titleDescription = service => getTitleBasedOnOtherServices(service);
        const getCarName = service => {
          if (service.car_name) {
            return service.car_name;
          }
          return String(`${service.car_make} ${service.car_model} ${service.car_year}`).trim();
        };
        const mappedServices = services.map((service) => {
          return {
            ...service,
            start: moment(service.due_date).set('hour', 13).toDate(),
            end: moment(service.due_date).set('hour', 13).add(1, 'hour').toDate(),
            title: `${getCarName(service)} - ${titleDescription(service)}`,
            allDay: true,
          };
        });
        this.setState({
          services: mappedServices,
          suggestedRescheduleDates: []
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  loadAppointments = async (
    startDate = this.state.startDate,
    endDate = this.state.endDate
  ) => {
    if (this.getShopId() !== -1) {
      try {
        this.isLoading = true;
        const response = await AppointmentStore.getShopAppointmentEventsInRange(
          this.getShopId(),
          moment(startDate).toDate(),
          moment(endDate).toDate()
        );
        const getCarName = carData => {
          return String(`${carData.carMake} ${carData.carModel} ${carData.carYear}`).trim();
        };
        const mappedAppointments = response.results.map((result) => {
          return {
            ...result,
            start: moment(result.appointment.appointmentDate).toDate(),
            end: moment(result.appointment.appointmentDate).add(1, 'hour').toDate(),
            title: `Booked Service - ${getCarName(result.car)}`,
            allDay: true,
          };
        });
        this.setState({
          appointments: mappedAppointments
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  init = () => {
    this.loadPMsServices();
    this.loadAppointments();
  }

  componentDidMount () {
    this.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.shopId !== this.props.shopId) {
      this.init();
    }
  }

  customCalendarStyle = (date) => {
    let backgroundColor = '#FFFFFF';
    const currentDisplayingDate = this.state.date;
    if (this.state.suggestedRescheduleDates.find(d => moment(d).isSame(moment(date), 'day'))) {
      backgroundColor = rescheduleSuggestionColor;
    } else if (moment(date).isSame(moment(), 'day')) {
      backgroundColor = currentDayColor;
    } else if (date.getMonth() !== moment(currentDisplayingDate || new Date()).month()) {
      backgroundColor = '#eeeeee';
    }
    return {
      style: {
        backgroundColor,
        margin: 0,
        padding: 0
      }
    };
  }

  onSelectEvent = (event) => {
    // if the event title is Reschedule Required, mark the calendar with the dates that should be rescheduled to
    if (event.title.includes('Service Immediately') || event.title.includes('No Reschedule Required')) {
      return this.props.history.push(`/car/${event.id_car}`);
    }
  };

  onClickDetails = (event) => {
    return this.props.history.push(`/car/${event.id_car}`);
  }

  onClickSuggestedRescheduleDates = (event) => {
    if (event.selected && this.state.suggestedRescheduleDates.length) {
      this.setState({
        suggestedRescheduleDates: []
      });
      event.selected = false;
      return;
    }
    const rescheduleDates = this.getArrayOfNextDays(7);
    this.setState({
      suggestedRescheduleDates: rescheduleDates,
    }, () => {
      event.selected = true;
    });
  }

  disposer = observe(ServiceScheduleStore, 'selectedEventToSeeDetails', () => {
    const selectedEvent = ServiceScheduleStore.selectedEventToSeeDetails;
    if (selectedEvent && selectedEvent.id_car) {
      this.onClickDetails(selectedEvent);
    } else if (selectedEvent && selectedEvent.car) {
      this.onClickDetails({
        id_car: selectedEvent.car.id,
      });
    }
  });

  disposer1 = observe(ServiceScheduleStore, 'selectedEventToSeeSuggestions', () => {
    const selectedEvent = ServiceScheduleStore.selectedEventToSeeSuggestions;
    if (selectedEvent) {
      this.onClickSuggestedRescheduleDates(selectedEvent);
    }
  });

  componentWillUnmount() {
    this.disposer();
    this.disposer1();
  }

  onSelectSlot = () => {};

  onRangeChange = (range) => {
    let startDate, endDate;
    if (_.isArray(range)) {
      // day and week view
      startDate = moment(range[0]).startOf('day').format();
      endDate = moment(range[range.length - 1]).endOf('day').format();
      return this.setState({
        startDate,
        endDate,
        currentCalendarView: range.length > 1 ? 'week' : 'day',
      });
    }
    // month view
    startDate = moment(range.start).startOf('day').format();
    endDate = moment(range.end).endOf('day').format();
    return this.setState({
      startDate,
      endDate,
      currentCalendarView: 'month',
    }, () => {
      this.loadPMsServices();
    });
  }

  onEventDrop = async ({ event, start }) => {
    // update the due_date of the service to the new date
    await IssueStore.updatePMService(
      this.getShopId(),
      event.id,
      event.service_customized_id,
      {
        due_date: moment(start).format(),
      }
    );
    this.loadPMsServices();
  }

  onNavigate = (date) => {
    this.setState({ date });
  }

  onShowMore = (events, date) => {
    this.setState({
      date,
      currentCalendarView: 'day',
    });
  }

  render() {
    let { date } = this.state;
    let { views } = this.props;

    let minTime = moment().set('hour', 6);
    minTime = minTime.set('minute', 0).toDate();

    let maxTime = moment().set('hour', 22);
    maxTime = maxTime.set('minute', 0).toDate();

    const services = [...this.state.services, ...this.state.appointments];

    return (
      <>
        {CurrentUserStore.user.role === UserRoles.admin && (
          <DealershipChooser />
        )}
        {this.isLoading ? (<LoadingWrapper><Spin tip="Loading Services..." /></LoadingWrapper>) : (<></>)}
        <>
          <PitstopCalendar
            services={services}
            defaultView={this.state.currentCalendarView}
            views={views}
            minTime={minTime}
            maxTime={maxTime}
            localizer={localizer}
            onSelectEvent={this.onSelectEvent}
            onClickDetails={this.onClickDetails}
            onClickSuggestedRescheduleDates={this.onClickSuggestedRescheduleDates}
            onSelectSlot={this.onSelectSlot}
            onRangeChange={this.onRangeChange}
            onEventDrop={this.onEventDrop}
            onNavigate={this.onNavigate}
            onShowMore={this.onShowMore}
            date={moment(date).toDate()}
            defaultDate={moment(date).toDate()}
            customCalendarStyle={this.customCalendarStyle}
          />
          <Text><Icon type="info-circle" theme="twoTone" /> Click on the Reschedule Required event to see the suggested days. Click again to hide the suggested days</Text>
          <ColorsLegendRow>
            <RescheduleSuggestionDayColorLegend /><Text>Suggested day to Reschedule</Text>
          </ColorsLegendRow>
          <ColorsLegendRow>
            <CurrentDayColorLegend /><Text>Current day</Text>
          </ColorsLegendRow>
        </>
      </>
    );
  }
}

ServiceScheduleCalendar.propTypes = {
  history: PropTypes.object,
  shopId: PropTypes.number,
  defaultView: PropTypes.string,
  views: PropTypes.array,
  ableSlotSelection: PropTypes.bool,
  date: PropTypes.instanceOf(moment),
  selectedStart: PropTypes.instanceOf(Date),
  selectedEnd: PropTypes.instanceOf(Date),
};

ServiceScheduleCalendar.defaultProps = {
  defaultView: 'month',
  views: ['month', 'week', 'day'],
};

export default withRouter(observer(
  decorate(ServiceScheduleCalendar, {
    isLoading: observable,
  })
));
