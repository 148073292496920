import React, { Component } from 'react';
import UserStore from 'stores/UserStore';
import { Avatar } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SelectedUserSection = styled.div``;

const UserBadge = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 12px;

  && .ant-avatar {
    margin-right: 8px;
  }
`;

export default class SelectedUsers extends Component {
  static propTypes = {
    selectedUsers: PropTypes.array
  };

  render() {
    return (
      <SelectedUserSection>
        {this.props.selectedUsers.map(el => (
          <UserBadge key={el}>
            <Avatar shape="square" icon="user" />
            <span>{UserStore.data.get(el).name}</span>
          </UserBadge>
        ))}
        {this.props.selectedUsers.length === 0 ? <p>None Selected</p> : null}
      </SelectedUserSection>
    );
  }
}
