import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate, observable, computed } from 'mobx';
import PropTypes from 'prop-types';

import { Col, Row, Typography, Select, Icon, Tag } from 'antd';

import IssueObject, {
  getPriorityTagColor,
  getPriorityTagDescription,
} from 'stores/Classes/IssueObject';

const { Text } = Typography;
const { Option } = Select;

class WorkOrderLineItemIssuesItemDesktop extends Component {
  static propTypes = {
    childRef: PropTypes.func,
    item: PropTypes.object,
    onRemoveItem: PropTypes.func,
    currentCarIssues: PropTypes.array,
    onUpdateLocalItem: PropTypes.func.isRequired,
  };

  localItem = {
    id_vehicle_service: '',
  };

  get localCurrentCarIssues() {
    return this.props.currentCarIssues;
  }

  get issuesOptions() {
    const formatLabel = (issue) => {
      const issueObject = new IssueObject(issue);
      return (
        <>
          <Tag color={getPriorityTagColor(issue.priority)}>
            {getPriorityTagDescription(issue.priority)}
          </Tag>
          <Text>{issueObject.getName()}</Text>
        </>
      );
    };
    return this.localCurrentCarIssues.map((issue, index) => (
      <Option key={index} value={issue.id}>
        {formatLabel(issue)}
      </Option>
    ));
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.localItem = this.props.item;
  }

  onChangeSelectField = (value, fieldName) => {
    this.localItem[fieldName] = value;

    if (this.props.onUpdateLocalItem) {
      this.props.onUpdateLocalItem(this.localItem);
    }
  };

  onRemoveItem = (event, itemKey) => {
    if (this.props.onRemoveItem) {
      this.props.onRemoveItem(event, itemKey);
    }
  };

  render() {
    return (
      <Row style={{ margin: 0 }} gutter={[8, 8]}>
        <Col key={'name'} span={23}>
          <Text strong>Issue</Text>
          <Select
            value={this.localItem.id_vehicle_service}
            onChange={(value) =>
              this.onChangeSelectField(value, 'id_vehicle_service')
            }
            placeholder="Select a Issue"
          >
            {this.issuesOptions}
          </Select>
        </Col>
        <Col
          key={'deleteBtn'}
          span={1}
          style={{
            display: 'flex',
            height: '57px',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '25px',
          }}
        >
          <Icon
            type="delete"
            onClick={(event) => {
              event.stopPropagation();
              this.onRemoveItem(event, this.props.item.key);
            }}
          />
        </Col>
      </Row>
    );
  }
}

decorate(WorkOrderLineItemIssuesItemDesktop, {
  localItem: observable,
  localCurrentCarIssues: computed,
  issuesOptions: computed,
});

export default observer(WorkOrderLineItemIssuesItemDesktop);
