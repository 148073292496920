import React, { Component } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import PropTypes from 'prop-types';

import { Col, Row, Typography, Collapse, Button, Icon } from 'antd';
import WorkOrderLineItemDetails from './WOLineItemDetails';

const { Text } = Typography;
const { Panel } = Collapse;

const AddServiceWrapper = styled.div`
  width: 100%;
  border: solid 1px rgb(91, 124, 206);
  border-style: dashed;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 20px;
`;

class WorkOrderLineItemsDesktop extends Component {
  static propTypes = {
    childRef: PropTypes.func,
    lineItems: PropTypes.array,
    onAddLineItem: PropTypes.func,
    onRemoveLineItem: PropTypes.func,
    onChangeLineItemField: PropTypes.func,
    shopUsers: PropTypes.array,
    shopParts: PropTypes.array,
    currentCarIssues: PropTypes.array,
    onAddNewPart: PropTypes.func.isRequired,
    onUpdateLaborItem: PropTypes.func.isRequired,
    onUpdateMiscItem: PropTypes.func.isRequired,
    onUpdatePartItem: PropTypes.func.isRequired,
    onUpdateServiceItem: PropTypes.func.isRequired,
    onChangeCollapseItems: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    onSelectSystemCode: PropTypes.func,
    onSelectAsmCode: PropTypes.func,
  };

  workOrderLinesDetailsRefs = {};

  get currentLineItems() {
    return this.props.lineItems;
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  updateLineItemHeader = (e, directValue = '', lineItem) => {
    let value;
    if (e) {
      value = e.target.value;
    } else {
      value = directValue;
    }
    if (!value) {
      lineItem.header = `New Work Order Line ${lineItem.key}`;
    } else {
      lineItem.header = value;
    }
    this.forceUpdate();
  };

  onChangeLineItemField = (lineKey, newVal, fieldName) => {
    if (this.props.onChangeLineItemField) {
      this.props.onChangeLineItemField(lineKey, newVal, fieldName);
    }
  };

  render() {
    const getActionIcons = (key) => {
      return (
        <Icon
          type="delete"
          onClick={(event) => {
            event.stopPropagation();
            this.props.onRemoveLineItem(key);
          }}
        />
      );
    };

    const panels = this.currentLineItems.map((lineItem) => {
      return (
        <Panel
          header={lineItem.header}
          key={lineItem.key}
          extra={getActionIcons(lineItem.key)}
        >
          <WorkOrderLineItemDetails
            childRef={(ref) =>
              (this.workOrderLinesDetailsRefs[lineItem.key] = ref)
            }
            lineItem={lineItem}
            shopUsers={this.props.shopUsers}
            currentCarIssues={this.props.currentCarIssues}
            shopParts={this.props.shopParts}
            onChangeServiceTask={(e, value) =>
              this.updateLineItemHeader(e, value, lineItem)
            }
            onChangeField={(newVal, fieldName) =>
              this.onChangeLineItemField(lineItem.key, newVal, fieldName)
            }
            onAddNewPart={(name) =>
              this.props.onAddNewPart(name || '', lineItem.key)
            }
            onUpdateLaborItem={(labourKey, newVal) =>
              this.props.onUpdateLaborItem(lineItem.key, labourKey, newVal)
            }
            onUpdateMiscItem={(miscKey, newVal) =>
              this.props.onUpdateMiscItem(lineItem.key, miscKey, newVal)
            }
            onUpdatePartItem={(partKey, newVal, changedField) =>
              this.props.onUpdatePartItem(
                lineItem.key,
                partKey,
                newVal,
                changedField
              )
            }
            onUpdateServiceItem={(serviceKey, newVal) =>
              this.props.onUpdateServiceItem(lineItem.key, serviceKey, newVal)
            }
            onSelectSystemCode={(systemCode) => {
              if (this.props.onSelectSystemCode) {
                this.props.onSelectSystemCode(lineItem.key, systemCode);
              }
            }}
            onSelectAsmCode={(asmCode) => {
              if (this.props.onSelectAsmCode) {
                this.props.onSelectAsmCode(lineItem.key, asmCode);
              }
            }}
            isDisabled={this.props.isDisabled}
          />
        </Panel>
      );
    });

    return (
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Text strong>Line Items</Text>
        </Col>
        <Col span={24}>
          <Collapse
            activeKey={this.currentLineItems
              .filter((li) => li.expanded)
              .map((li) => li.key)}
            onChange={this.props.onChangeCollapseItems}
          >
            {panels}
          </Collapse>
        </Col>
        <Col span={24}>
          <AddServiceWrapper>
            <Button onClick={this.props.onAddLineItem} icon="plus">
              Service Task
            </Button>
          </AddServiceWrapper>
        </Col>
      </Row>
    );
  }
}

decorate(WorkOrderLineItemsDesktop, {
  currentLineItems: computed,
});

export default observer(WorkOrderLineItemsDesktop);
