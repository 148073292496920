import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';

import { Empty, Icon } from 'antd';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

import { calcMileage } from 'helpers/unitCalculations';

import { ReportsDataStore, CurrentUserStore, CarStore } from 'stores';

const NoIssuesSection = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  i {
    font-size: 36px;
    margin-right: 12px;
  }
`;

const ReactTableStyled = styled(ReactTable)`
  text-align: center;
  maragin-top: 20px;
`;

class SpeedingTable extends Component {
  render() {
    if (CarStore.demo === false) return <Empty />;

    if (_.isEmpty(ReportsDataStore.alarmReport.data)) {
      return (
        <NoIssuesSection>
          <Icon type="check-circle" theme="twoTone" />
          <p>Your fleet has no alarms!</p>
        </NoIssuesSection>
      );
    }

    return (
      <div style={{ marginTop: '20px' }}>
        <br />
        <ReactTableStyled
          columns={[
            {
              Header: 'Vehicle',
              id: 'car.make_model_year',
              accessor: (data) => {
                return (
                  <div>
                    <Link to={`/car/${data.id_car}`}>
                      <span>
                        {data.car_make} {data.car_model} {data.car_year}
                      </span>
                    </Link>
                  </div>
                );
              },
            },
            {
              Header: 'Total Events',
              id: 'high_speed_end_event_count',
              accessor: 'high_speed_end_event_count',
            },
            {
              Header:
                'Avg Top Speed ' +
                CurrentUserStore.user.displayedOdometer +
                '/hr)',
              id: 'distance',
              accessor: 'speeding_max_average',
              Cell: (data) => {
                let maxspeed = Math.round(
                  calcMileage(data.original.speeding_max_average)
                ).toReadable();
                return <span>{maxspeed || 'Not available'}</span>;
              },
            },
          ]}
          sortable={true}
          defaultSorted={[
            {
              id: 'high_speed_end_event_count',
              desc: true,
            },
          ]}
          data={ReportsDataStore.alarmReport.data}
          pages={Math.round(ReportsDataStore.alarmReport.data.length / 5)} // Display the total number of pages
          loading={ReportsDataStore.alarmReport.pending} // Display the loading overlay when we need it
          defaultPageSize={5}
          className="-highlight smallTable"
        />
      </div>
    );
  }
}

export default observer(SpeedingTable);
