import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'antd';
import { AppStore, CarStore } from 'stores';

const GoogleSearchBtn = ({ issue, onClick, title, type, shouldDisableButton, style, icon }) => {
  return (
    <Button
      style={style}
      icon={icon}
      onClick={async () => {
        AppStore.addLoading('Loading...');

        const carData = await CarStore.getCarByIdAndReturn(issue.car.id);
        const code = (issue.name && issue.name[0] === 'P') ? issue.name : `SPN ${issue.name}`;
        const getSearchString = (field) => {
          return field ? `${field} ` : '';
        };
        // 'Make + Model + Year + (SPN Code or DTC Code)';
        const searchString = `${getSearchString(carData.make)}${getSearchString(carData.model)}${getSearchString(carData.year)}${code}`;

        window.open(`https://www.google.com/search?q=${searchString}`, '_blank');

        AppStore.removeLoading('Loading...');

        await onClick();
      }}
      type={type}
      disabled={shouldDisableButton}
    >
      { title || 'Google Search' }
    </Button>
  );
};

GoogleSearchBtn.propTypes = {
  issue: PropTypes.object.isRequired,
  style: PropTypes.object,
  onClick: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  shouldDisableButton: PropTypes.bool
};

GoogleSearchBtn.defaultProps = {
  shouldDisableButton: false,
  issue: {},
  style: {},
  icon: '',
};

export default GoogleSearchBtn;
