import React, { Component } from 'react';
import styled from 'styled-components';

import { Slider } from 'v3/components/pitstop';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  padding: 4vw 5vw 0vw 12vw;

  background: #02103d;
  border: 1px dashed #ffffff;
  box-sizing: border-box;

  h2 {
    color: #ffffff;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.375rem;
  }

  h3 {
    color: #4f75b9;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  p {
    color: #d9d9d9;
    line-height: 1.5rem;
    font-weight: 400;
  }

  li {
    font-size: 1rem;
    color: #d9d9d9;
  }
`;

const Img1 = styled.div`
  height: 48vh;
  margin: 5vh 0vh;
`;

class OnboardingSliderBackground extends Component {
  state = { step: 0 };

  intervalID = 0;

  componentDidMount() {
    this.intervalID = setInterval(() => {
      let step = this.state.step;

      step++;

      if (step === 4) {
        step = 0;
      }

      this.setState({
        step,
      });
    }, 5 * 3600);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const { step } = this.state;

    return (
      <Container>
        <Background style={{ height: '100%' }}>
          {step === 0 && (
            <>
              <a
                href="https://pitstopconnect.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('assets/logos/pitstop-logo.png')}
                  alt="logo"
                  height="70"
                />
              </a>

              <Img1>
                <img
                  src={require('v3/assets/failures.png')}
                  alt="failures"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </Img1>

              <h3>FEATURE</h3>

              <h2>Prioritize Vehicles and Potential Failures</h2>
            </>
          )}

          {step === 1 && (
            <>
              <a
                href="https://pitstopconnect.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('assets/logos/pitstop-logo.png')}
                  alt="logo"
                  height="70"
                />
              </a>

              <Img1>
                <img
                  src={require('v3/assets/notifications.png')}
                  alt="notifications"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </Img1>

              <h3>FEATURE</h3>

              <h2>Notifications</h2>
            </>
          )}

          {step === 2 && (
            <>
              <a
                href="https://pitstopconnect.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('assets/logos/pitstop-logo.png')}
                  alt="logo"
                  height="70"
                />
              </a>

              <Img1>
                <img
                  src={require('v3/assets/predictive_insights.png')}
                  alt="predictive_insights"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </Img1>

              <h3>FEATURE</h3>

              <h2>Predictive Insights</h2>
            </>
          )}

          {step === 3 && (
            <>
              <a
                href="https://pitstopconnect.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('assets/logos/pitstop-logo.png')}
                  alt="logo"
                  height="70"
                />
              </a>

              <Img1>
                <img
                  src={require('v3/assets/routine_service.png')}
                  alt="routine_service"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </Img1>

              <h3>FEATURE</h3>

              <h2>Create usage based reminders for routine service alerts</h2>
            </>
          )}

          <Slider
            isActive={step === 0}
            onClick={() => this.setState({ step: 0 })}
          />
          <Slider
            isActive={step === 1}
            onClick={() => this.setState({ step: 1 })}
          />
          <Slider
            isActive={step === 2}
            onClick={() => this.setState({ step: 2 })}
          />
          <Slider
            isActive={step === 3}
            onClick={() => this.setState({ step: 3 })}
          />
        </Background>
      </Container>
    );
  }
}

export default OnboardingSliderBackground;
