import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, Observer } from 'mobx-react';
import { observe, toJS } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import { DatePicker, Icon, List, Popover, Tag, Col, Row } from 'antd';

import { calcFuel, calcMileage } from 'helpers/unitCalculations';

import { PitstopButton, PitstopModal, customFleets } from 'shared';

import { CarObject } from 'stores/Classes';
import CurrentUserStore from 'stores/CurrentUserStore';
import AppStore from 'stores/App';
import CarStore from 'stores/CarStore';

import TripMatchSelection from './TripMatchSelection';
import TripCompairison from 'components/TripCompairison';
import Utilities from './Utilities';

const CheckableTag = Tag.CheckableTag;

const TripItem = styled(List.Item)`
  && {
    padding: 20px;
    background: ${(props) => (props.selected ? '#f0f8ff' : 'inherit')};

    div {
      display: flex;
      flex-direction: column;
      label,
      p {
        font-size: 14px;
      }
    }
  }
`;

const TripInfoContainer = styled.div`
  && {
    display: flex;
    flex-direction: row !important;
    width: fit-content;
    margin: 5px 0px;

    label {
      font-weight: bold;
    }

    p {
      margin-left: 2px;
      margin-bottom: 0px;
    }
  }
`;

const ExploreIcon = styled(Icon)`
  && {
    align-self: center;
    flex: none;
  }
`;

const TypeSelector = styled.div`
  display: flex;
  align-items: center;

  h6,
  i {
    display: inline-block;
    margin-bottom: 0px;
    margin-right: 12px;
  }
`;

const GuideContainer = styled.div`
  display: flex;

  i,
  p {
    margin: auto 5px;
  }
`;

const ModalContainer = styled(PitstopModal)`
  &&& {
    .ant-modal-footer {
      display: none;
    }
  }
`;

class CarTripsView extends Component {
  static propTypes = {
    car: PropTypes.instanceOf(CarObject),
    history: PropTypes.object.isRequired,
    shopId: PropTypes.number,
  };

  VIDEO_MODAL_ID = 'VIDEO_MODAL_ID';

  state = {
    selectedTripId: 0,
    startDate: this.startDate,
    endDate: this.endDate,
    page: 1,
    pageSize: 5,
    isOpenedSimilarTrips: false,
    isOpenedCompareTrips: false,
    isAllTripsOnMap: false,
  };

  disposer = observe(CarStore, 'demo', async () => {
    if (CarStore.demo) {
      this.props.car.trips.reset();
      await this.getTrips();
    }
  });

  async componentDidMount() {
    if (CarStore.demo) {
      this.setState({
        pageSize: 10,
      });
    }

    await this.getTrips();
  }

  get startDate() {
    if (customFleets.gmrv.includes(this.props.shopId))
      return moment().subtract(2, 'years');

    if (customFleets.isMultipleTrips.includes(this.props.shopId))
      return moment().subtract(1, 'months');

    // default start date for demos to have trips data
    if (String(this.props.car.vin).includes('DEMO-')) {
      return moment('2020-01-01', 'YYYY-MM-DD');
    }

    return moment().subtract(1, 'years');
  }

  get endDate() {
    if (customFleets.isMultipleTrips.includes(this.props.shopId))
      return moment(this.startDate).add(1, 'months');

    return moment();
  }

  async componentDidUpdate(prevProps, prevState) {
    let { startDate, endDate, isAllTripsOnMap } = this.state;

    if (
      !moment(startDate).isSame(moment(prevState.startDate)) ||
      !moment(endDate).isSame(moment(prevState.endDate))
    ) {
      if (!_.isNil(startDate) && !_.isNil(endDate)) {
        await this.props.car.trips.reset();
        await this.getTrips();
      }
    }

    if (isAllTripsOnMap !== prevState.isAllTripsOnMap && isAllTripsOnMap) {
      endDate = startDate ? moment(startDate).add(1, 'months') : null;

      this.setState({
        endDate,
      });
    }
  }

  componentWillUnmount() {
    this.disposer();
  }

  get reports() {
    const reports = this.props.car.reports.data.reduce((prev, el) => {
      if (el.length !== 0) {
        prev.push({
          ...el[0],
          key: el[0].item,
          testHistory: el
            .slice(1)
            .map((el, i) => ({ ...el, key: el.item + i })),
        });
      }
      return prev;
    }, []);

    return reports;
  }

  changeType = (type, trip) => {
    trip.updateType(type);
  };

  getTrips = async () => {
    const { car } = this.props;
    const { startDate, endDate, page, pageSize } = this.state;

    if (!car.trips.loaded) {
      try {
        await car.getTrips(
          startDate.unix(),
          endDate.unix(),
          customFleets.isMultipleTrips.includes(this.props.shopId)
            ? 0
            : pageSize * (page - 1),
          customFleets.isMultipleTrips.includes(this.props.shopId)
            ? 500
            : pageSize,
          true
        );

        // quick-fix for demo account
        // show trips has more than 10km and has sensor data
        if (CarStore.demo) {
          let tripsDataDemo = _.filter(toJS(car.trips.data), (trip) => {
            return (
              Math.ceil(
                calcMileage(parseFloat(trip.mileageAccum).toFixed(2))
              ) >= 10
            );
          });

          let excludedDemoVins = ['DEMO-001'];

          if (excludedDemoVins.includes(car.vin)) {
            tripsDataDemo = [];
          }

          car.trips.data = tripsDataDemo;
        }

        this.setState({
          selectedTripId: car.trips.data[0] && car.trips.data[0].tripId,
        });
      } catch (err) {
        AppStore.addError(`Error in getting trips data: ${err.message}`);
      }
    }
  };

  SimilarTrips = () => {
    this.setState({ similarTrips: true });
  };

  compareTrips = () => {
    this.setState({ compareTrips: true });
  };

  dateRangeChange = (dates) => {
    this.setState({
      startDate: dates[0],
      endDate: dates[1],
      page: 1,
    });
  };

  onCloseSimilarTrips = () => {
    this.setState({
      isOpenedSimilarTrips: false,
    });
  };

  onCloseCompareTrips = (page) => {
    const { trips } = this.props.car;

    this.setState({
      isOpenedCompareTrips: false,
      page: page,
      selectedTripId: trips.data[0].tripId,
    });
  };

  changePagination = (page, pageSize) => {
    if (this.state.page !== page) {
      this.setState(
        {
          page: page,
          pageSize: pageSize,
          selectedTripId: null,
        },
        async () => {
          await this.props.car.trips.reset();
          await this.getTrips();
        }
      );
    }
  };

  render() {
    let {
      startDate,
      endDate,
      page,
      pageSize,
      isOpenedSimilarTrips,
      isOpenedCompareTrips,
    } = this.state;

    let { car, shopId } = this.props;

    let { trips } = car;

    if (CarStore.demo === false || !this.props.car) {
      return <h3>There is no data to display</h3>;
    }

    let selectedTrip = _.filter(
      [...this.props.car.trips.data],
      (trip) => trip.id === this.state.selectedTripId
    )[0];

    if (!selectedTrip && this.props.car.trips.data[0]) {
      selectedTrip = this.props.car.trips.data[0];
    }

    return (
      <div>
        {customFleets.gmrv.includes(shopId) && (
          <PitstopButton
            type="primary"
            onClick={() => AppStore.openModals.set(this.VIDEO_MODAL_ID, true)}
          >
            <GuideContainer>
              <Icon type="info-circle" />
              <p>User Guide</p>
            </GuideContainer>
          </PitstopButton>
        )}

        <ModalContainer
          title=""
          id={this.VIDEO_MODAL_ID}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          width="60%"
          height="50%"
          style={{ marginTop: 30 }}
          bodyStyle={{ padding: 0 }}
          onOk={() => {}}
          onCancel={() => () =>
            AppStore.openModals.set(this.VIDEO_MODAL_ID, false)}
        >
          <video width="100%" height="100%" controls muted>
            <source src={require('./trip_view_v1.mp4')} type="video/mp4" />
          </video>
        </ModalContainer>

        <Row gutter={[16, 16]}>
          <Col lg={6} xs={24}>
            <Row>
              <Col span={24}>
                {/* <RangePicker
                  style={{ padding: '8px 0px' }}
                  onChange={this.dateRangeChange}
                  value={[this.state.startDate, this.state.endDate]}
                  format="MMM DD, YY"
                /> */}
                <Row style={{ height: 95 }} gutter={8}>
                  <Col lg={12} xs={6}>
                    <>Start Date</>
                    <br />
                    <DatePicker
                      placeholder="Start Date"
                      value={this.state.startDate}
                      onChange={(date) => {
                        let startDate = date;
                        let endDate = this.state.isAllTripsOnMap
                          ? moment(startDate).add(1, 'months')
                          : this.state.endDate;

                        this.setState({
                          startDate,
                          endDate,
                        });
                      }}
                    />
                  </Col>

                  <Col lg={12} xs={6}>
                    <>End Date</>
                    <br />
                    <DatePicker
                      placeholder="End Date"
                      value={this.state.endDate}
                      disabledDate={(current) => {
                        if (
                          customFleets.isMultipleTrips.includes(
                            this.props.shopId
                          )
                        ) {
                          return (
                            current >
                            moment(this.state.startDate).add(1, 'months')
                          );
                        }
                      }}
                      onChange={(date) => {
                        let endDate = date;
                        let startDate = this.state.isAllTripsOnMap
                          ? moment(endDate).subtract(1, 'months')
                          : this.state.startDate;

                        this.setState({
                          startDate,
                          endDate,
                        });
                      }}
                    />
                  </Col>

                  {this.state.isAllTripsOnMap && (
                    <>
                      <Col span={24} style={{ paddingTop: 8 }}>
                        <Icon
                          style={{ fontSize: 14, marginRight: 8 }}
                          type="info-circle"
                        />
                        <i>All Trips On Map restrict 1 month data</i>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>

              <Col span={24} style={{ padding: 0 }}>
                <List
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    width: '100%',
                    height: 575,
                    border: '1px solid #e8e8e8',
                  }}
                  pagination={{
                    style: {
                      height: 50,
                      display: 'flex',
                      justifyContent: 'center',
                    },
                    position: 'top',
                    pageSize: customFleets.isMultipleTrips.includes(
                      this.props.shopId
                    )
                      ? 5
                      : this.state.pageSize,
                    ...(!customFleets.isMultipleTrips.includes(
                      this.props.shopId
                    ) && {
                      current: this.state.page,
                      onChange: this.changePagination,
                    }),
                    total: trips.count,
                    disabled: !trips.loaded,
                    simple: true,
                  }}
                  dataSource={trips.data}
                  loading={!trips.loaded}
                  renderItem={(item) => (
                    <Observer>
                      {() => {
                        const tripMileage =
                          item.mileageAccum && parseFloat(item.mileageAccum) > 0
                            ? `${Math.round(
                                calcMileage(parseFloat(item.mileageAccum))
                              ).toReadable()} ${CurrentUserStore.user.settings
                                .odometer || 'km'}`
                            : 'N/A';
                        const fuelConsumption =
                          item.fuelConsumptionAccum &&
                          parseFloat(item.fuelConsumptionAccum) > 0
                            ? `${parseFloat(
                                calcFuel(item.fuelConsumptionAccum, car?.scanner?.scannerId)
                              ).toFixed(3)} ${
                                CurrentUserStore.user.settings.fuelUnit
                              }`
                            : 'N/A';
                        const {
                          good_battery,
                          good_fueltrim,
                          good_airfilter,
                        } = item;
                        const battery = _.isNil(good_battery)
                          ? 'N/A'
                          : good_battery
                          ? 'Good'
                          : 'Bad';

                        const fuelTrim = _.isNil(good_fueltrim)
                          ? 'N/A'
                          : good_fueltrim
                          ? 'Good'
                          : 'Bad';
                        const airFilter = _.isNil(good_airfilter)
                          ? 'N/A'
                          : good_airfilter
                          ? 'Good'
                          : 'Bad';
                        const items = {
                          Mileage: tripMileage,
                          'Fuel Consumption': fuelConsumption,
                          Battery: battery,
                          'Fuel Trim': fuelTrim,
                          'Air Filter': airFilter,
                        };
                        return (
                          <TripItem
                            onClick={() =>
                              this.setState({
                                selectedTripId: item.tripId,
                              })
                            }
                            selected={item.tripId === this.state.selectedTripId}
                          >
                            <div>
                              <h6>
                                {moment(item.timeStart * 1000).format('ll')}
                              </h6>

                              <p>
                                {`${moment(item.timeStart * 1000).format(
                                  'LT'
                                )} - ${moment(item.timeEnd * 1000).format(
                                  'LT'
                                )}`}
                              </p>

                              {Object.keys(items).map((i) => {
                                if (
                                  [
                                    'Battery',
                                    'Fuel Trim',
                                    'Air Filter',
                                  ].includes(i) &&
                                  items[i] === 'N/A'
                                )
                                  return '';
                                return (
                                  <TripInfoContainer key={i}>
                                    <label>{i}:</label>
                                    <p>{items[i]}</p>
                                  </TripInfoContainer>
                                );
                              })}
                              <Popover
                                trigger={item.type ? 'click' : 'hover'}
                                content={
                                  <TypeSelector>
                                    <Icon type="edit" />
                                    <h6>Type:</h6>
                                    <CheckableTag
                                      key="Personal"
                                      checked={item.type === 'personal'}
                                      onChange={() =>
                                        this.changeType('Personal', item)
                                      }
                                    >
                                      Personal
                                    </CheckableTag>
                                    <CheckableTag
                                      key="Business"
                                      checked={item.type === 'business'}
                                      onChange={() =>
                                        this.changeType('Business', item)
                                      }
                                    >
                                      Business
                                    </CheckableTag>
                                  </TypeSelector>
                                }
                              >
                                <Tag>
                                  {item.type
                                    ? item.type.capitalize()
                                    : 'Select Trip Type'}
                                </Tag>
                              </Popover>
                            </div>
                            <ExploreIcon type="right" />
                          </TripItem>
                        );
                      }}
                    </Observer>
                  )}
                />
              </Col>
            </Row>
          </Col>

          <Col lg={18} xs={24}>
            <Row justify="center">
              <Utilities
                trips={trips}
                selectedTrip={selectedTrip}
                car={car}
                startDate={startDate}
                endDate={endDate}
                page={page}
                pageSize={pageSize}
                getTrips={this.getTrips}
                openSimilarTrips={() =>
                  this.setState({
                    isOpenedSimilarTrips: true,
                  })
                }
                openCompareTrips={() =>
                  this.setState({ isOpenedCompareTrips: true })
                }
                setIsAllTripsOnMap={(isAllTripsOnMap) =>
                  this.setState({ isAllTripsOnMap })
                }
              />
            </Row>
          </Col>
        </Row>

        <TripMatchSelection
          car={car}
          visible={isOpenedSimilarTrips}
          onCloseSimilarTrips={this.onCloseSimilarTrips}
        />
        <TripCompairison
          car={car}
          visible={isOpenedCompareTrips}
          onCloseCompareTrips={this.onCloseCompareTrips}
          startDate={startDate}
          endDate={endDate}
          page={page}
        />
      </div>
    );
  }
}

export default withRouter(observer(CarTripsView));
