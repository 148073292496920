import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { DealershipChooser } from 'components';
import { Form, Select, InputNumber, Button, Card, Col, Row, Spin, notification } from 'antd'; // New: Import necessary components for filters
import styled from 'styled-components';
import { observe } from 'mobx';
import { ShopStore } from 'stores';
import { get } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const { Option } = Select; // New: Import Option from Select

const H2Styled = styled.h2`
  font-size: 1.2rem;
`;

class FleetAvgCostPerVisitPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  state = {
    isLoadingVehicleAverageCostPerVisit: false,
    dataForVehicleAverageCostPerVisit: [],
    timePeriod: 30, // New: Add timePeriod to state
    limit: 20, // New: Add limit to state
  };

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.disposer();
  }

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.init();
  });

  loadDataForVehicleAverageCostPerVisit = async () => { // of interest
    try {
      this.setState({ isLoadingVehicleAverageCostPerVisit: true });
      const { timePeriod, limit } = this.state; // New: Destructure timePeriod and limit from state
      const response = await ShopStore.getShopDataForVehicleAverageCostPerVisit(
        ShopStore.currentShop.id,
        timePeriod, // New: Pass timePeriod as parameter
        limit // New: Pass limit as parameter
      );
      const data = get(response, 'data', []);

      const idCars = Array.isArray(data) ? data.map(item => item.id_car) : [];
      const response2 = await ShopStore.getUnitIDsFromCarIds(idCars);
      const carNamesData = get(response2, 'data', []);
      // Create a mapping of car IDs to car names
      const carNamesMap = {};
      carNamesData.forEach(car => {
        carNamesMap[car.id] = car.car_name;
      });

      // Process the data
      const combinedData = [];
      const yAxisCategories = data.map(item => carNamesMap[item.id_car]);

      data.forEach((item, index) => {
        const vehicleId = item.id_car;
        let avgCost = parseFloat(item.avg_cost)
        if (isNaN(avgCost)) {
          avgCost = 0
        }

        combinedData.push({
          x: index,
          y: parseFloat(avgCost.toFixed(2)),
          vehicleId: vehicleId,
          carName: carNamesMap[vehicleId]
        });
      });

      this.setState({
        dataForVehicleAverageCostPerVisit: combinedData,
        vehicles: yAxisCategories
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
      this.setState({ dataForVehicleAverageCostPerVisit: [] });
    } finally {
      this.setState({ isLoadingVehicleAverageCostPerVisit: false });
    }
  };

  init = async () => {
    // load data for Vehicle Costs
    this.loadDataForVehicleAverageCostPerVisit();
  };

  handleFilterChange = (changedFields) => { // New: Handle filter changes
    this.setState(changedFields);
  };

  handleApplyFilters = () => { // New: Apply filters
    this.init();
  };

  render() {
    const { dataForVehicleAverageCostPerVisit, timePeriod, limit, isLoadingVehicleAverageCostPerVisit } = this.state;

    const vehicleAverageCostPerVisitChartOptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: this.state.vehicles, // Adjusted field name
        crosshair: true,
        title: {
          text: 'Unit ID',
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: null,
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (this.y < 1000 && this.y > 0) {
                return Math.round(this.y); // Display numbers less than 1000 rounded to the nearest whole number
              } else if (this.y === 0) {
                return null
              } else {
                return (Math.round(this.y / 100) / 10) + 'k'; // Display 1000 and greater rounded to the nearest hundred with 'k'
              }
            }
          }
        },
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: (options) => {
                // Construct the URL dynamically
                const vehicleId = options?.target?.point?.vehicleId;
                if (vehicleId) {
                  const customUrl = `/car/${vehicleId}/work-orders`;
                  this.props.history.push(customUrl);
                }
              }
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (this.y < 1000 && this.y > 0) {
                return Math.round(this.y); // Display numbers less than 1000 rounded to the nearest whole number
              } else if (this.y === 0) {
                return null
              } else {
                return (Math.round(this.y / 100) / 10) + 'k'; // Display 1000 and greater rounded to the nearest hundred with 'k'
              }
            },
            style: {
              fontSize: '10px'
            }
          }
        }
      },
      series: [
        {
          name: 'Average Cost Per Visit',
          data: dataForVehicleAverageCostPerVisit.map(item => ({
            y: item.y,
            vehicleId: item.vehicleId,
            carName: item.carName
          })),
          color: '#183cbc',
        }
      ],
    };

    return (
      <Fragment>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <h1>Fleet Average Cost Per Visit</h1>
          </Col>
        </Row>
        <DealershipChooser />
        <Form layout="inline" onValuesChange={this.handleFilterChange}> {/* New: Add form for filters */}
          <Form.Item label="Time Period">
            <Select defaultValue={30} value={timePeriod} onChange={value => this.handleFilterChange({ timePeriod: value })}>
              <Option value={30}>30 days</Option>
              <Option value={90}>90 days</Option>
              <Option value={180}>180 days</Option>
              <Option value={360}>360 days</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Limit">
            <InputNumber min={1} defaultValue={20} value={limit} onChange={value => this.handleFilterChange({ limit: value })} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={this.handleApplyFilters}>Apply Filters</Button> {/* New: Apply Filters button */}
          </Form.Item>
        </Form>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Spin spinning={isLoadingVehicleAverageCostPerVisit}>
              <Card>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <H2Styled>Vehicle Average Cost Per Visit</H2Styled>
                  </Col>
                  <Col span={24}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={vehicleAverageCostPerVisitChartOptions}
                    />
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default withRouter(observer(FleetAvgCostPerVisitPage));