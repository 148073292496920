import { action, decorate, observable } from 'mobx';

import { AbstractStore } from 'stores/abstract';

class ServiceScheduleStoreC extends AbstractStore {
  selectedEventToSeeDetails = null;
  selectedEventToSeeSuggestions = null;

  onClickRescheduleRequiredEventDetails (event) {
    this.selectedEventToSeeDetails = event;
  }

  onClickRescheduleRequiredSuggestedRescheduleDates (event) {
    this.selectedEventToSeeSuggestions = event;
  }
}

decorate(ServiceScheduleStoreC, {
  onClickRescheduleRequiredEventDetails: action,
  onClickRescheduleRequiredSuggestedRescheduleDates: action,
  selectedEventToSeeDetails: observable,
  selectedEventToSeeSuggestions: observable
});

const ServiceScheduleStore = new ServiceScheduleStoreC();
export default ServiceScheduleStore;
