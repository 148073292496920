import React from 'react';
import { PDF } from 'shared/PitstopUI/pdf';

interface MediaPreviewProps {
  media: string;
}

/**
 * @param props
 * @param props.media - The URL or base64 media to preview
 */
const MediaPreview: React.FC<MediaPreviewProps> = ({ media }) => {
  if (!media) {
    return null;
  }

  // if is a base64 pdf
  if (media.includes('application/pdf') || media.includes('.pdf')) {
    // return (
    //   <object
    //     id="media-preview"
    //     data={media}
    //     type="application/pdf"
    //     width="100%"
    //     height="1024px"
    //   >
    //     <p>Unable to display PDF file.</p>
    //   </object>
    // );
    return <div id="media-preview">
      <PDF maxHeight="1024px" file={media} type="single" />
    </div>
  }

  return (
    <div>
      <img
        id="media-preview"
        src={media}
        alt="Media Preview"
        style={{ width: '100%', height: 'auto' }}
      />
    </div>
  );
};

export default MediaPreview;
