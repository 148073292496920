import React from 'react';
import { observer } from 'mobx-react';

// icons
import UserStore from 'stores/UserStore';
import CurrentUserStore from 'stores/CurrentUserStore';
import UserDetailsSection from './UserDetailsSection';
// import ChatModule from './ChatModule';
import { Spin } from 'antd';
import ShopStore from 'stores/ShopStore';
import styled from 'styled-components';
import { PitstopButton } from 'shared';
import PropTypes from 'prop-types';
import AppStore from 'stores/App';
import PrintLoginCredentialsButton from 'components/Users/PrintLoginCredentialsButton';

const SendInvitationButton = styled(PitstopButton)`
  margin: 0px 10px;
  width: 250px;
`;

class UserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userExpanded: false,
      selected_car: 0,
      current_pane: 6,
      sendingInvitationEmail: false,
      loadingPrint: false,
    };
  }

  componentDidMount = () => {
    !this.user && this.setupUser();
  };

  get userId() {
    return Number(this.props.match.params.userid);
  }

  get user() {
    return UserStore.data.get(this.userId);
  }

  componentDidUpdate = (prevProps) => {
    if (this.userId !== Number(prevProps.match.params.userid)) {
      this.setupUser();
    }
  };

  setupUser = () => {
    UserStore.fetchUser(this.userId);
  };

  selectCar = (index) => {
    this.setState({ selected_car: index });
  };

  selectPanel = (index) => {
    this.setState({ current_pane: index });
  };

  sendInvitationEmail = () => {
    this.setState({
      sendingInvitationEmail: true,
    });
    UserStore.sendInvitationEmail(
      ShopStore.currentShop.id,
      this.userId,
      CurrentUserStore.user.id
    )
      .then(() => {
        AppStore.addSuccess('invitation email sent');
      })
      .catch((err) => {
        AppStore.addError(`failed to send invitation email: ${err}`);
      })
      .finally(() => this.setState({ sendingInvitationEmail: false }));
  };

  render() {
    return (
      <div className="container-fluid dashboard user-page">
        {!this.user ? (
          <React.Fragment>
            <h1>User Profile</h1>
            <Spin />
          </React.Fragment>
        ) : (
          <div>
            {/** User Details Card * */}
            <h1>User Profile - {this.user.name}</h1>
            <p>
              {this.user.cars &&
                `Number of Vehicles: ${this.user.totalCarsAssociated}`}
            </p>

            <UserDetailsSection user={this.user} />

            {this.user.email.indexOf('username.pitstop') === -1 ? (
              <SendInvitationButton
                type="primary"
                onClick={() => this.sendInvitationEmail()}
              >
                Resend Invitation Email
              </SendInvitationButton>
            ) : (
              <PrintLoginCredentialsButton users={[this.user]} />
            )}

            {/* <ChatModule user={this.user} /> */}
          </div>
        )}
      </div>
    );
  }
}

UserPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
};

export default observer(UserPage);
