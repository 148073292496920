import React, { Component } from 'react';
import { DealershipChooser } from 'components';
import { observe } from 'mobx';
import { ShopStore } from 'stores';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import TechinicianEfficiency from 'assets/demo-charts/1. Techinician Efficiency.png';

const FullWidthImage = styled.img`
  width: 100%;
`;

class ReportsDemoPage extends Component {
  state = {};

  componentDidMount() {}

  disposer = observe(ShopStore.currentShop, 'id', async () => {});

  demoCharts = [
    {
      name: 'Technician Efficiency',
      imagePath: TechinicianEfficiency,
    },
  ];

  render() {
    return (
      <div>
        <Row style={{ marginBottom: 10 }}>
          <Col span={12}>
            <h1>Technician Efficiency</h1>
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col span={24}>
            <DealershipChooser />
          </Col>
        </Row>
        {this.demoCharts.map((chart, index) => (
          <Row key={index} style={{ marginBottom: 10 }}>
            <Col span={24}>
              <FullWidthImage src={chart.imagePath} alt={chart.name} />
            </Col>
          </Row>
        ))}
      </div>
    );
  }
}

ReportsDemoPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ReportsDemoPage);
