import { AppStore } from 'stores';

const response = (err = new Error(), message = '') => {
  if (err.name === 'AbortError') return;

  AppStore.addError(message || err.message);
};

const logErrorWithoutAlert = (err = new Error(), message = '') => {
  if (err.name === 'AbortError') return;

  console.log(message);
};

export const errors = {
  response,
  logErrorWithoutAlert
};

export default errors;
