import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Empty } from 'antd';

import UserCell from './UserCell';

const ChatUserList = ({ chatUsers, selectedSectionId, onSelectUser }) => {
  if (_.isEmpty(chatUsers)) {
    return <Empty test-id="empty" description="No user found" />;
  }

  return (
    <div className="gx-chat-user">
      {chatUsers.map((chatUser, index) => (
        <UserCell
          key={index}
          chatUser={chatUser}
          selectedSectionId={selectedSectionId}
          onSelectUser={onSelectUser}
        />
      ))}
    </div>
  );
};

ChatUserList.propTypes = {
  chatUsers: PropTypes.array.isRequired,
  selectedSectionId: PropTypes.number,
  onSelectUser: PropTypes.func.isRequired,
};

export default ChatUserList;
