import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

const WidgetToolTip = (props) => {
  return(
  <div style={{display:'flex'}}>
    <Tooltip title={props.title}>
        <div style={{'display':'flex'}}>
          <Icon type="info-circle" style={{'marginTop':'5px', color: props.colour ? props.colour : null}}/>
          <p style={{'marginLeft':'15px'}}>What is this?</p>
        </div>
    </Tooltip>
  </div>
  );
};

WidgetToolTip.propTypes = {
  title: PropTypes.string,
  colour: PropTypes.string
};

WidgetToolTip.defaultProps = {
  title: '',
  colour: ''
};

export default withRouter(observer(WidgetToolTip));