import React, { Component, createRef } from 'react';
import { Button, notification } from 'antd';
import PropTypes from 'prop-types';
import { Logger } from 'stores/Classes';
import { ShopStore, UserStore } from 'stores';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';

const PrintContainer = styled.div`
  margin: 30px;
  padding: 20px;
  font-family: Arial, sans-serif;
  position: relative;
  min-height: calc(100vh - 60px);
  box-sizing: border-box;
  page-break-after: always;
`;

const Logo = styled.img`
  position: absolute;
  top: 20px;
  left: 20px;
  height: 50px;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 24px;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-size: 18px;
`;

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 1.6;
`;

const Section = styled.div`
  text-align: left;
  margin-top: 20px;
`;

const CenteredSection = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const StepTitle = styled.h3`
  font-size: 16px;
`;

const StepParagraph = styled.p`
  font-size: 14px;
`;

const PageNumber = styled.div`
  position: absolute;
  bottom: 20px;
  width: calc(100% - 60px);
  text-align: center;
  font-size: 12px;
`;

const defaultPitstopLogo = require('assets/logos/small-logo.png');

class PrintComponent extends Component {
  render() {
    const { users } = this.props;

    return (
      <div>
        {users.map((user, index) => (
          <PrintContainer key={user.id}>
            <Logo src={defaultPitstopLogo} alt="Logo" />
            <Title>Welcome to Pitstop</Title>
            <Subtitle>
              Invitation to manage assets for {user.currentShopName}
            </Subtitle>
            <Paragraph>
              You have been invited to join the Pitstop exclusive experience.
              Your assets will thank you in cost savings and performance. By
              taking the step to gain proactive insights on maintenance, driver
              safety and vehicle utilization your Fleet will take care of
              itself.
            </Paragraph>
            <CenteredSection>
              <StepTitle>Login Credentials</StepTitle>
              <StepParagraph style={{ marginBottom: 3 }}>
                Username: {user.username}
              </StepParagraph>
              <StepParagraph>Password: {user.password}</StepParagraph>
            </CenteredSection>
            <Section>
              <StepTitle>Step 1:</StepTitle>
              <StepParagraph>
                Go to{' '}
                <a href="https://dashboard.pitstopconnect.com/login">
                  https://dashboard.pitstopconnect.com/login
                </a>
              </StepParagraph>
              <StepTitle>Step 2:</StepTitle>
              <StepParagraph>
                Enter the username and password above in the fields provided on
                the login page.
              </StepParagraph>
              <StepTitle>Step 3:</StepTitle>
              <StepParagraph>
                You should land on the Assets page of the dashboard. If you need
                to navigate somewhere else, you can access all pages of the
                dashboard from the side panel on the left.
              </StepParagraph>
            </Section>
            <PageNumber>
              Page {index + 1} of {users.length}
            </PageNumber>
          </PrintContainer>
        ))}
      </div>
    );
  }
}

PrintComponent.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      username: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      currentShopName: PropTypes.string.isRequired,
    })
  ).isRequired,
};

class PrintLoginCredentialsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      printData: [],
    };
    this.componentRef = createRef();
    this.printTriggerRef = createRef();
  }

  componentDidMount() {
    if (this.props.ref) {
      this.props.ref(this);
    }
  }

  handlePrint = async () => {
    try {
      this.setState({ isLoading: true });
      const printDataPromises = this.props.users.map(async (user) => {
        let email = user.email;
        let username = email;
        let password = user.password;
        try {
          let response = await UserStore.getUserCredentialsForPrinting(user);

          if (response.email) {
            email = response.email;
            username = response.email;
            password = response.password;
          }

          if (username.indexOf('@username.pitstop') > -1) {
            username = username.replace('@username.pitstop', '');
          }
        } catch (error) {
          return {};
        }

        return {
          id: user.id,
          username,
          password,
          currentShopName: ShopStore.currentShop.name,
        };
      });

      const printData = await Promise.all(printDataPromises);

      const onlyNotEmpty = printData.filter((user) => user.username);

      if (onlyNotEmpty.length === 0) {
        notification.error({
          message: 'Error printing login credentials',
          description:
            'No login credentials to print. Maybe the user was created using the signup form.',
        });
        return;
      }

      this.setState({ printData: onlyNotEmpty }, () => {
        this.printTriggerRef.current.handlePrint();
      });
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { label } = this.props;
    const { isLoading, printData } = this.state;

    return (
      <>
        <Button
          loading={isLoading}
          type="primary"
          ghost
          onClick={this.handlePrint}
        >
          {label}
        </Button>
        <ReactToPrint
          ref={this.printTriggerRef}
          content={() => this.componentRef.current}
          trigger={() => <div />} // Invisible trigger to initialize ReactToPrint
        />
        <div style={{ display: 'none' }}>
          {printData.length > 0 && (
            <PrintComponent ref={this.componentRef} users={printData} />
          )}
        </div>
      </>
    );
  }
}

PrintLoginCredentialsButton.propTypes = {
  label: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  ref: PropTypes.object,
};

PrintLoginCredentialsButton.defaultProps = {
  label: 'Print Login Credentials',
};

export default PrintLoginCredentialsButton;
