import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { Row, Col } from 'antd';

import AppStore from 'stores/App';
import CarStore from 'stores/CarStore';
import AppointmentStore from 'stores/AppointmentStore';
import CurrentUserStore from 'stores/CurrentUserStore';
import AppointmentPreviewSection from 'containers/CreateAppointment/AppointmentPreviewSection';
import { PitstopButton } from 'shared';

const ButtonArea = styled.div`
  display: flex;
  button:nth-child(2) {
    margin-left: auto;
  }
  margin: 10px 0px;
`;

class ShareVehicleHealthReportPage extends Component {
  state = {
    loading: false,
  }

  setValue = (name, value, callback = () => { }) => {
    this.setState({ [name]: value }, callback);
  };

  setChildCallables = (callables) => {
    this.childCallables = callables;
  };

  share = async () => {
    let {
      description,
      assignedTo,
    } = this.state;

    const selectedCarId = this.props.selectedCarId;

    const selectedCar = CarStore.data.get(selectedCarId);

    await this.childCallables.handleSubmit();

    this.setState({ loading: true });

    for (const contactId of assignedTo) {
      var appointmentObject = {
        carId: selectedCarId,
        userId: CurrentUserStore.user.id,
        comments: description,
        appointmentDetails: {
          state: 'reminder',
          startTime: moment().toISOString(),
          endTime: moment().add(1, 'hour').toISOString(),
          timezone: CurrentUserStore.user.settings.timezone
        },
        existingUser: true,
        contactId: contactId
      };

      try {
        await AppointmentStore.sendReportToContact(
          selectedCar.shopId,
          appointmentObject
        );
      } catch (err) {
        console.log(err);
        AppStore.addError(`Sorry! We are unable to share with ${contactId}!`);
      }
    }

    // close the modal
    AppStore.openModals.set('SHARE_VEHICLE_HEALTH_REPORT_MODAL', false);
    AppStore.addSuccess('Report shared');
    this.setState({ loading: false });

    return;
  }

  render () {
    return (
      <Row>
        <Col span={24}>
          <h1>Share Vehicle Health Report</h1>
        </Col>
        <Col span={24}>
          <AppointmentPreviewSection
            setCallables={this.setChildCallables}
            key="appointmentPreview"
            showAppointmentTime={false}
            selectedCarId={this.props.selectedCarId}
            description={''}
            setValue={this.setValue}
          />
        </Col>
        <Col span={24}>
          <ButtonArea>
            <PitstopButton
              loading={this.state.loading}
              disabled={this.isDisabled}
              type="primary"
              onClick={() => this.share()}
            >
              Share
            </PitstopButton>
          </ButtonArea>
        </Col>
      </Row>
    );
  }
}

ShareVehicleHealthReportPage.propTypes = {
  selectedCarId: PropTypes.number,
};

export default withRouter(observer(ShareVehicleHealthReportPage));
