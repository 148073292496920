import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import Picker from 'emoji-picker-react';

import { Col, Input, Row, Spin, Tabs, Tooltip } from 'antd';

import { Send } from 'assets/icons';
import { SimpleCard } from 'components/Cards';
import CustomScrollbars from 'components/CustomScrollbars';

import ChatUserList from 'components/Chat/ChatUserList/ChatUserList';
import Conversation from 'components/Chat/Conversation/Conversation';

import { chatUtils } from 'helpers';
import { default as sampleChatUser } from './data/chatUsers';
import { default as sampleConversationList } from './data/conversationList';
import { ChatStore, CurrentUserStore, CarStore } from 'stores';

const { TabPane } = Tabs;
const { Search } = Input;

class Chat extends Component {
  constructor() {
    super();
    this.state = {
      isEmojiPickerOpened: false,
      selectedUser: null,
      message: '',
      chatUsers: [],
      conversationList: [],
      conversation: {},
      searchContactName: '',
    };
  }

  disposer = observe(ChatStore.chatHistory, 'loaded', () => {
    if (ChatStore.chatHistory.loaded) {
      this.setChatData();
    }
  });

  async componentDidMount() {
    if (CurrentUserStore.user.role === 'admin' || CarStore.demo) {
      this.setState({
        chatUsers: sampleChatUser,
        conversationList: sampleConversationList,
      });
    }

    this.setChatData();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedUser &&
      this.state.selectedUser !== prevState.selectedUser
    ) {
      await this.getConversation();
    }
  }

  setChatData = () => {
    let chatUsers = _.map(
      CurrentUserStore.fleetManagersDrivers.data,
      (user) => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        lastMessage: '',
        unreadMessage: 0,
      })
    );

    if (_.isEmpty(ChatStore.chatHistory.data)) return;

    _.forEach(ChatStore.chatHistory.data, (chat) => {
      let chatData = _.get(chat, 'data');

      let unreadMessagesCount = chatUtils.getAppUnreadMessagesCounts(
        CurrentUserStore.user.name,
        chatData
      );

      let lastMessage =
        _.get(chatData, 'allMessages').length !== 0
          ? _.get(chatData, 'allMessages')[
              _.get(chatData, 'allMessages').length - 1
            ]['text']
          : '';

      _.forEach(chatUsers, (chatUser) => {
        if (chatUser.id === chat.userId) {
          chatUser.lastMessage = lastMessage;
          chatUser.unreadMessage = unreadMessagesCount;
        }
      });
    });

    let _chatUsers = _.orderBy(chatUsers, ['unreadMessage'], ['desc']);

    this.setState(
      {
        chatUsers:
          CurrentUserStore.user.role === 'admin' ? sampleChatUser : _chatUsers,
        conversationList:
          CurrentUserStore.user.role === 'admin'
            ? sampleConversationList
            : ChatStore.chatHistory.data,
      },
      () => this.getConversation()
    );
  };

  getConversation = async () => {
    let { conversationList, selectedUser } = this.state;
    let conversation = [];

    if (selectedUser) {
      if (CurrentUserStore.user.role === 'admin' || CarStore.demo) {
        conversation = _.filter(
          conversationList,
          (conversation) => conversation.id === selectedUser.id
        );

        this.setState({
          conversation: {
            ...this.state.conversation,
            conversationData: conversation[0].conversationData,
          },
        });
      } else {
        conversation = _.filter(
          conversationList,
          (conversation) =>
            conversation.userId ===
            (CurrentUserStore.user.role === 'customer'
              ? CurrentUserStore.user.id
              : selectedUser.id)
        );

        let currentUserName = CurrentUserStore.user.name;

        if (process.env.REACT_APP_ENV === 'production') {
          // temporary fix for production since all the fleetManager is return with Pistop name in conversation

          currentUserName = ['dealership', 'fleetManager'].includes(
            CurrentUserStore.user.role
          )
            ? 'Pitstop'
            : CurrentUserStore.user.name;
        }

        if (!_.isEmpty(conversation)) {
          let _conversation = _.map(
            conversation[0].data.allMessages,
            (conv) => {
              let sentAt = `Sent at ${moment
                .unix(conv.received)
                .format('LLL')}`;

              return {
                type: currentUserName === conv.name ? 'sent' : 'received',
                name: currentUserName,
                message: conv.text,
                messageType: conv.type,
                mediaUrl: conv.mediaUrl,
                mediaType: conv.mediaType,
                altText: conv.altText,
                sentAt: sentAt,
              };
            }
          );

          this.setState({
            conversation: {
              ...this.state.conversation,
              conversationData: _conversation,
            },
          });
        }
      }
    }
  };

  hasReadMessage = async () => {
    let { selectedUser, chatUsers } = this.state;

    let chatDataOfSelectedUser = _.chain(chatUsers)
      .filter((user) => user.id === selectedUser.id)
      .head()
      .value();

    if (
      CurrentUserStore.user.id !== selectedUser.id &&
      _.get(chatDataOfSelectedUser, 'unreadMessage') > 0
    ) {
      ChatStore.hasReadMessage(selectedUser.id);
    }
  };

  onMessageWasSent = async (message) => {
    let { selectedUser } = this.state;

    if (CurrentUserStore.user.role === 'admin' || CarStore.demo) {
      this.setState({
        message: '',
        conversation: {
          ...this.state.conversation,
          conversationData: [
            ...this.state.conversation.conversationData,
            {
              type: 'sent',
              message: message,
              sentAt: `${moment().format('LLL')}`,
            },
          ],
        },
      });
    } else {
      let _data = {
        type: 'chat',
        desc: 'Sending via messenger',
        payload: {
          content: {
            type: 'text',
            text: message,
            role: CurrentUserStore.user.role,
            name: CurrentUserStore.user.name,
          },
        },
      };

      ChatStore.sendMessage(
        CurrentUserStore.user.role === 'customer'
          ? CurrentUserStore.user.id
          : selectedUser.id,
        _data
      );
    }
  };

  showCommunication = () => {
    let {
      selectedUser,
      conversation,
      isEmojiPickerOpened,
      message,
    } = this.state;

    if (!selectedUser) {
      return <p>Please select a user to start a chat</p>;
    }

    return (
      <div className="gx-chat-main">
        <div className="gx-chat-main-header-info">
          <div className="gx-name">{selectedUser.name}</div>
        </div>

        <hr />

        <CustomScrollbars
          className="gx-chat-list-scroll"
          conversationData={conversation.conversationData}
        >
          <Conversation
            conversationData={conversation.conversationData}
            selectedUser={selectedUser}
          />
        </CustomScrollbars>

        <Row className="gx-chat-main-footer" type="flex">
          {isEmojiPickerOpened && (
            <div style={{ position: 'fixed', bottom: 200 }}>
              <Picker
                onEmojiClick={(e, emojiObject) =>
                  this.setState({
                    message: message + emojiObject.emoji,
                  })
                }
              />
            </div>
          )}

          <Col span={1} style={{ margin: 'auto 0px' }}>
            <span
              role="img"
              aria-label="smiley"
              style={{
                fontSize: 20,
                filter: 'grayscale(1)',
                cursor: 'pointer',
              }}
              onClick={() =>
                this.setState({ isEmojiPickerOpened: !isEmojiPickerOpened })
              }
            >
              &#128512;
            </span>
          </Col>

          <Col span={20}>
            <Input
              id="required"
              className="chat-textarea"
              onPressEnter={this.submitComment}
              onChange={(e) => this.updateMessageValue(e)}
              onFocus={(e) => {
                this.hasReadMessage();
              }}
              value={message}
              placeholder="Type a Message"
            />
          </Col>

          <Col span={1} style={{ margin: 'auto 10px' }}>
            <Tooltip title="Send">
              <img
                alt="send"
                style={{ cursor: 'pointer' }}
                src={Send}
                onClick={this.submitComment}
              />
            </Tooltip>
          </Col>
        </Row>
      </div>
    );
  };

  onSelectUser = (user) => {
    this.setState({
      selectedUser: user,
    });
  };

  submitComment = () => {
    let { message } = this.state;

    if (message !== '') {
      this.onMessageWasSent(message);

      this.setState({
        message: '',
      });
    }
  };

  updateMessageValue = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  render() {
    const { chatUsers, selectedUser, searchContactName } = this.state;

    if (ChatStore.pending) {
      return <Spin tip="Getting chat history..." />;
    }

    return (
      <Row className="main-content" gutter={[16, 16]}>
        <Col xxl={6} lg={8}>
          <SimpleCard>
            <Tabs>
              <TabPane label="Contacts" tab="Contacts" key="1">
                <Search
                  className="search-textarea"
                  placeholder="Search name"
                  onChange={(e) => {
                    let name = e.target.value;

                    this.setState({
                      searchContactName: name,
                      selectedUser: null,
                    });
                  }}
                />

                <ChatUserList
                  chatUsers={
                    _.isEmpty(searchContactName)
                      ? chatUsers
                      : _.filter(
                          chatUsers,
                          (chatUser) =>
                            chatUser.name
                              .toLowerCase()
                              .indexOf(searchContactName) !== -1
                        )
                  }
                  selectedSectionId={selectedUser && selectedUser.id}
                  onSelectUser={this.onSelectUser}
                />
              </TabPane>
            </Tabs>
          </SimpleCard>
        </Col>

        <Col xxl={18} lg={16}>
          <SimpleCard>{this.showCommunication()}</SimpleCard>
        </Col>
      </Row>
    );
  }
}

export default observer(Chat);
