import { action, computed, decorate, observable, transaction } from 'mobx';
import moment from 'moment-timezone';
import _ from 'lodash';

import { webServiceProvider } from 'shared';

import { AppStore, CarStore } from 'stores';
import { AsyncData } from 'stores/abstract';
import ShopStore from 'stores/ShopStore';

export const UserRoles = {
  customer: 'customer',
  dealership: 'dealership',
  admin: 'admin',
  technician: 'technician',
};

export const RoleDisplayMappingForShopUser = {
  dealership: 'Fleet Manager',
  customer: 'Driver',
};

export const ShopRoleDisplayMapping = {
  admin: 'Admin',
  driver: 'Driver',
  fleetManager: 'Fleet Manager',
  customer: 'Driver',
};

export const ShopRolesApi = {
  driver: 'driver',
  fleetManager: 'fleetManager',
};

export const RolesOptions = [
  { name: 'Fleet Manager', id: UserRoles['dealership'] },
  { name: 'Driver', id: UserRoles['customer'] },
  { name: 'Technician', id: UserRoles['technician'] },
];

class UserObject {
  userId;
  id;
  payments = [];
  subscriptions = [];
  defaultCardId;
  cards = [];
  loaded = false;
  allowManager = false;
  cars = [];
  shopUrl = 'https://dashboard-campaign.s3.us-east-2.amazonaws.com/plogo.svg';

  messages = new AsyncData();

  constructor(jsonObject = {}) {
    // eslint-disable-next-line no-unused-vars
    const { name, cars, ...assignObject } = jsonObject;
    if (Object.keys(assignObject).length === 0) {
      return;
    }
    this.loaded = true;
    Object.assign(this, assignObject);
    this.userId = jsonObject.userId || jsonObject.id;
    this.id = this.userId;
    this.allowManager = jsonObject.role !== 'technician' ? true : false;

    const customShopUrl = _.get(jsonObject, 'shop[0].logoUrl');
    if (customShopUrl) {
      this.shopUrl = customShopUrl;
    }
  }

  get shopId() {
    if (this.shop && this.shop.length > 0) {
      return this.shop[0].id;
    }
    return undefined;
  }

  get name() {
    if (_.isNil(this.firstName) && _.isNil(this.lastName)) {
      return 'No Name Set';
    }

    return `${this.firstName || ''} ${this.lastName || ''}`
      .trim()
      .capitalizeFirstLetter();
  }

  get shopApiRole() {
    switch (this.role) {
      case UserRoles.customer:
        return ShopRolesApi.driver;
      case UserRoles.dealership:
        return ShopRolesApi.fleetManager;
      default:
        return this.role;
    }
  }

  get displayedRole() {
    const displayedRole = _.get(ShopRoleDisplayMapping, this.shopApiRole);
    return displayedRole || this.role;
  }

  get displayedOdometer() {
    return this.settings.odometer || 'km';
  }

  getCars = async ({ limit }) => {
    if (ShopStore.currentShop.id !== -1) {
      await CarStore.getCarsForUser({ limit }, this.id, this.shopId);
    }
  };

  assignToShop = async (shopId, callback = () => {}) => {
    try {
      await webServiceProvider.patch(`shop/${shopId}`, {
        userId: +this.id,
      });
      callback(true, 'success');
    } catch (e) {
      callback(false, 'failed while assigning dealership to user');
    }
  };

  getChatHistory = async () => {
    try {
      this.messages.resetError();
      this.messages.loaded = false;
      const { result: chatHistory } = await webServiceProvider.getMany(
        `user/${this.id}/message`,
        {
          count: 0,
        }
      );
      transaction(() => {
        this.messages.data.replace(this.messageFormatChat(chatHistory));
        this.messages.loaded = true;
      });
    } catch (e) {
      console.error(e);
      this.messages.setError('Could not fetch chat data');
    }
  };

  setCarIds = async (carIds) => {
    await webServiceProvider.put(`v1/user/${this.id}/car`, {
      carIds: carIds.toString(),
    });
    transaction(() => {
      this.cars.replace(carIds);
      for (let i = 0; i < carIds.length; i++) {
        if (CarStore.data.has(carIds[i])) {
          CarStore.data.get(carIds[i]).userId = this.id;
        }
      }
      this.totalCarsAssociated = carIds.length;
    });
  };

  unassignCarId = async (carId) => {
    await webServiceProvider.delete(`v1/users/${this.id}/cars/${carId}`);
    transaction(() => {
      AppStore.addSuccess('Unassign user completed.');
      this.cars.replace(this.cars.filter((el) => el !== carId));
      if (CarStore.data.has(carId)) {
        CarStore.data.get(carId).userId = undefined;
      }
      this.totalCarsAssociated = this.cars.length;
    });
  };

  assignAllFleetVehicles = async () => {
    await webServiceProvider.post(
      `v1/users/${this.id}/assign-all-fleet-vehicles`
    );
  };

  sendMessage = async (userId, data) => {
    try {
      if (userId && userId !== 'undefined') {
        const response = await webServiceProvider.post(
          `user/${userId}/message`,
          data
        );
        console.log(`sent: ${response}`);
        return response;
      }
    } catch (e) {
      this.errorCallback(e);
      throw e;
    }
  };

  messageFormatChat = (chat) => {
    return _.orderBy(chat, 'received', 'asc').map((item) => {
      const role = item.role;
      let msgContent = item.text || '';
      const dateReceived = new Date(item.received * 1000).toUTCString();
      msgContent = `${msgContent} ${dateReceived}`;

      let msgRole = 'me';
      if (role === 'appUser') {
        msgRole = 'them';
      }
      return {
        type: 'text',
        author: msgRole,
        data: { text: msgContent, date: dateReceived },
      };
    });
  };

  getSubscriptions = async () => {
    try {
      const { data } = await webServiceProvider.get(
        `v1/users/${this.id}/subscriptions`
      );
      this.subscriptions = data;
    } catch (e) {
      this.subscriptions = undefined;
    }
  };

  getFullName = () => {
    if (!this.firstName && !this.lastName) {
      return this.email;
    }
    return `${this.firstName} ${this.lastName}`.trim();
  };

  updateSettings = async (values) => {
    try {
      await webServiceProvider.patch(`v1/user/${this.id}/settings`, {
        settings: {
          ...values,
        },
      });

      this.settings = { ...this.settings, ...values };

      if (values.timezone) {
        moment.tz.setDefault(this.settings.timezone || 'America/New_York');
      }
    } catch (e) {
      AppStore.addError('Unable to update settings.');
      throw e;
    }
  };

  getPayments = async () => {
    try {
      const { data } = await webServiceProvider.get(
        `v1/users/${this.id}/payment`
      );
      this.subscriptions = data.subscriptions;
      this.payments = data.charges;
      this.cards = data.cards;
    } catch (e) {
      AppStore.addError('Error in getting payments!');
    }
  };

  addCardPayment = async (token) => {
    try {
      await webServiceProvider.post(`v1/users/${this.id}/payments/cards`, {
        token,
      });
    } catch (e) {
      throw Error('Error in getting payments!');
    }
  };

  updateCardPayment = async (card) => {
    try {
      await webServiceProvider.put(`v1/users/${this.id}/payments/cards`, card);
    } catch (e) {
      throw Error('Error in getting payments!');
    }
  };

  deleteCardPayment = async (cardId) => {
    try {
      await webServiceProvider.delete(
        `v1/users/${this.id}/payments/cards/${cardId}`
      );
    } catch (e) {
      throw Error('Error in getting payments!');
    }
  };

  addSubscription = async (quantity, tokenId, plan) => {
    const response = await webServiceProvider.post(
      `v1/users/${this.id}/subscriptions`,
      {
        quantity: Number(quantity),
        token: tokenId,
        plan,
      }
    );
    return response;
  };

  updateDefaultCardPayment = async (cardId) => {
    try {
      const response = await webServiceProvider.put(
        `v1/users/${this.id}/payments/cards/${cardId}`
      );
      this.defaultCardId = response.data.default_source;
      return response;
    } catch (err) {
      throw Error('Error in updating your card payment!');
    }
  };

  getStripeCustomerDetails = async () => {
    try {
      const response = await webServiceProvider.get(
        `v1/users/${this.id}/stripe`
      );
      this.defaultCardId = response.data.default_source;
      return response;
    } catch (err) {
      throw Error('Error in getting your card details!');
    }
  };

  addToMessageList = (content) => {
    this.messages.data.push({
      author: 'me',
      type: 'text',
      data: { text: content },
    });
  };
}

export default decorate(UserObject, {
  name: computed,
  allowManager: observable,
  shopId: computed,
  displayedOdometer: computed,
  userId: observable,
  shopUrl: observable,
  subscriptions: observable,
  payments: observable,
  cards: observable,
  defaultCardId: observable,
  id: observable,
  userActivity: observable,
  totalCarsAssociated: observable,
  valid: observable,
  cars: observable,
  firstName: observable,
  email: observable,
  lastName: observable,
  settings: observable,
  phone: observable,
  setCarIds: action,
});
