import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Row, Col, Checkbox, Icon, Dropdown, Menu } from 'antd';
import { PitstopButton, PitstopModal } from 'shared';

import { AppStore, CarStore } from 'stores';
import { ShareVehicleHealthReportPage } from 'containers/ShareVehicleHealthReport';

const DownloadVehicleHealthReport = ({ carId, category }) => {
  const [issueReportStatus, setIssueReportStatus] = useState(['new']);
  const [issuesLimit] = useState(1000);
  const [issueSources, setIssueSources] = useState([
    'dtc',
    'routine',
    'recall',
    'user',
    'algorithm',
    'archive',
    'lightVehicleDTC',
    'heavyVehicleDTC',
  ]);

  const HEALTH_REPORT_MODAL_ID = 'HEALTH_REPORT_MODAL_ID';

  useEffect(() => {
    if (_.isNil(carId)) AppStore.addError('Vehicle ID is missing!');
    setIssueReportStatus([category]);
  }, [category, carId]);

  const onDownloadVehicleHealthReport = async () => {
    try {
      AppStore.addLoading('Getting Health Report');
      await CarStore.data
        .get(carId)
        .downloadVehicleHealthReport(
          issueReportStatus,
          issuesLimit,
          issueSources
        );
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      AppStore.removeLoading('Getting report...');
    }
  };

  const statusOptions = [
    { label: 'New', value: 'new' },
    { label: 'Waiting For Invoice', value: 'waitingForApproval' },
    { label: 'Done', value: 'done' },
  ];

  const sourceOptions = [
    { label: 'Engine Codes', value: 'dtc' },
    { label: 'Routine/OEM Recommended Services', value: 'routine' },
    { label: 'Recall', value: 'recall' },
    { label: 'Fleet Manager/Driver Reported', value: 'user' },
    { label: 'Pitstop Algorithm Generated Services', value: 'algorithm' },
    { label: 'Service Records', value: 'archive' },
    { label: 'Light Vehicle DTCs', value: 'lightVehicleDTC' },
    { label: 'Heavy Vehicle DTCs', value: 'heavyVehicleDTC' },
  ];

  const handleShareClick = ({ key }) => {
    if (key === 'download') {
      AppStore.openModals.set(HEALTH_REPORT_MODAL_ID, true);
    } else if (key === 'share') {
      AppStore.openModals.set('SHARE_VEHICLE_HEALTH_REPORT_MODAL', true);
    }
  };

  return (
    <>
      <PitstopModal
        id={HEALTH_REPORT_MODAL_ID}
        title=""
        width={700}
        onOk={() => {
          AppStore.openModals.set(HEALTH_REPORT_MODAL_ID, false);
          onDownloadVehicleHealthReport();
        }}
        onCancel={() => AppStore.openModals.set(HEALTH_REPORT_MODAL_ID, false)}
      >
        <Row>
          <h3>Please select report's format:</h3>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <b>Issues Status</b>
            <Checkbox.Group
              style={{ width: '100%' }}
              value={issueReportStatus}
              onChange={(values) => setIssueReportStatus(values)}
            >
              <Row>
                {_.map(statusOptions, (status) => (
                  <Col key={status.value} span={8}>
                    <Checkbox value={status.value}>{status.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Col>

          <Col span={24}>
            <b>Issues Sources</b>
            <Checkbox.Group
              style={{ width: '100%' }}
              value={issueSources}
              onChange={(values) => setIssueSources(values)}
            >
              <Row>
                {_.map(sourceOptions, (source) => (
                  <Col key={source.value} span={12}>
                    <Checkbox value={source.value}>{source.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>
      </PitstopModal>

      <PitstopModal
        okButtonProps={{ style: { display: 'none' } }}
        onOk={() => {}}
        onCancel={() => {
          AppStore.openModals.set('SHARE_VEHICLE_HEALTH_REPORT_MODAL', false);
        }}
        title=""
        width={800}
        id="SHARE_VEHICLE_HEALTH_REPORT_MODAL"
      >
        <ShareVehicleHealthReportPage selectedCarId={carId} />
      </PitstopModal>

      <Dropdown
        overlay={
          <Menu onClick={handleShareClick}>
            <Menu.Item key={'download'}>
              <Icon type="download" />
              Download Report
            </Menu.Item>
            <Menu.Item key={'share'}>
              <Icon type="share-alt" />
              Share Report
            </Menu.Item>
          </Menu>
        }
      >
        <PitstopButton ghost>
          <Icon type="share-alt" />
          Vehicle Health Report
        </PitstopButton>
      </Dropdown>
    </>
  );
};

DownloadVehicleHealthReport.propTypes = {
  carId: PropTypes.number.isRequired,
  category: PropTypes.string,
};

export default DownloadVehicleHealthReport;
