import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card } from 'antd';

import Checked from 'assets/checked.png';

const ImageCard = styled(Card)`
  opacity: ${props => props.isSelected && 0.45};
`;

const CheckedCard = styled(Card)`
    display: ${props => (props.isSelected ? 'block' : 'none')};
    position: ${props => props.isSelected && 'absolute'};
    max-width: ${props => props.isSelected && '15%'};
    z-index: ${props => props.isSelected && 10};
    right: ${props => props.isSelected && '8px'};
    border: ${props => props.isSelected && 'none'};
    border-radius: ${props => props.isSelected && '50%'};
  }
`;

class Image extends Component {
  static propTypes = {
    isSelected: PropTypes.bool,
    handleImagePick: PropTypes.func,
    imgSrc: PropTypes.string,
  };

  static defaultProps = {
    isSelected: false,
  };

  render() {
    let { isSelected, handleImagePick, imgSrc } = this.props;

    return (
      <>
        <CheckedCard bodyStyle={{ padding: 0 }} isSelected={isSelected}>
          {
            <img
              style={{ maxHeight: '100%', maxWidth: '100%' }}
              alt="checked"
              src={Checked}
            />
          }
        </CheckedCard>

        <ImageCard
          bodyStyle={{ padding: 0 }}
          onClick={handleImagePick}
          hoverable
          isSelected={isSelected}
        >
          {
            <img
              style={{ maxHeight: '100%', maxWidth: '100%' }}
              alt={imgSrc}
              src={imgSrc}
            />
          }
        </ImageCard>
      </>
    );
  }
}

export default Image;
