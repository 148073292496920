import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import moment from 'moment';
import AppStore from 'stores/App';
import AlarmMarker from 'components/AlarmMarker';
import { each as _each } from 'lodash';

const markerStyle = {
  position: 'absolute',
  width: '12px',
  height: '12px',
  left: '-6px',
  top: '-6px',
};

function rad(x) {
  return (x * Math.PI) / 180;
}

class SimpleMap extends PureComponent {
  static defaultProps = {
    zoom: 11,
  };
  constructor(props) {
    super(props);
    this.geodesicPolyline = null;
    this.startMarker = null;
    this.endMarker = null;
    this.map = null;
    this.maps = null;
    this.polyline = null;
    this.alarms = [];
    this.state = {
      center: null,
      lat: null,
      lng: null,
      geodesicPolyline: null,
      showAnalytics: this.props.showAnalytics,
      showEmissionTable: this.props.showEmissionTable,
      snapToRoad: this.props.snapToRoad,
    };
  }

  componentDidMount() {
    console.log(this.props);
    this.setState({
      showAnalytics: this.props.showAnalytics,
      showEmissionTable: this.props.showEmissionTable,
      snapToRoad: this.props.snapToRoad,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    //for size change
    if (
      prevProps.showAnalytics !== this.props.showAnalytics ||
      prevProps.showEmissionTable !== this.props.showEmissionTable ||
      prevProps.snapToRoad !== this.props.snapToRoad
    ) {
      this.setState({
        showAnalytics: this.props.showAnalytics,
        showEmissionTable: this.props.showEmissionTable,
        snapToRoad: this.props.snapToRoad,
      });
    }
    // get alarms when new polyline
    if (
      this.polyline &&
      this.state.geodesicPolyline &&
      this.alarms.length === 0 &&
      prevState.geodesicPolyline !== this.state.geodesicPolyline &&
      this.polyline.snappedPoints
    ) {
      this.snappedCoordinates = [];
      for (var t = 0; t < this.polyline.snappedPoints.length; t++) {
        var latlng = {
          lat: this.polyline.snappedPoints[t].location.latitude,
          lng: this.polyline.snappedPoints[t].location.longitude,
        };
        this.snappedCoordinates.push(latlng);
      }
      this.getAlarms();
    }

    if (prevProps.data.timeEnd !== this.props.data.timeEnd) {
      if (
        this.props.data.location_polyline !== null &&
        this.props.data.location_polyline.length > 0
      ) {
        this.alarms = [];
        this.handleMapRender(this.map, this.maps);
      }
    }
    //get analyticsmarker (Graph hover marker) only called when polyline
    if (this.props.data.location_polyline) {
      if (
        this.polyline !== null &&
        this.state.geodesicPolyline !== null &&
        this.props.data.location_polyline.length > 0
      ) {
        if (this.polyline.snappedPoints) {
          this.getAnalyticsMarker(String(this.props.CurrentMarkerTime));
        }
      }
    }
  }

  getAnalyticsMarker = (time) => {
    // get closest marker on polyline by time
    // similar to getAlarmLocation()
    // let time = String(this.props.CurrentMarkerTime);
    time = time.slice(0, -3);
    const timestampList = [];
    this.props.data.location_polyline.forEach((element) => {
      timestampList.push(element.timestamp);
    });
    // get closest timestamp in markers
    const closest = timestampList.reduce(function(prev, curr) {
      return Math.abs(curr - Number(time)) < Math.abs(prev - Number(time))
        ? curr
        : prev;
    });

    // get lat and lng for marker with certain timestamp, break loop when found for better performance
    _each(this.props.data.location_polyline, (element) => {
      if (closest !== element.timestamp) return;

      let lat = Number(element.location[1].data);
      let lng = Number(element.location[0].data);
      const closerMarker = this.findClosestMarker(lat, lng);
      this.setState({
        lat: this.snappedCoordinates[closerMarker].lat,
        lng: this.snappedCoordinates[closerMarker].lng,
      });
      return false; // https://stackoverflow.com/questions/33266946/how-to-stop-lodash-js-each-loop
    });
  };

  findClosestMarker(lat, lng) {
    var R = 6371; // radius of earth in km
    var distances = [];
    var closest = -1;
    for (var i = 0; i < this.snappedCoordinates.length; i++) {
      var mlat = this.snappedCoordinates[i].lat;
      var mlng = this.snappedCoordinates[i].lng;
      var dLat = rad(mlat - lat);
      var dLong = rad(mlng - lng);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(lat)) *
          Math.cos(rad(lat)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      distances[i] = d;
      if (closest === -1 || d < distances[closest]) {
        closest = i;
      }
    }
    return closest;
  }

  downSampleValues = (pathValues) => {
    // snap to road accepts only 100 points max per call
    if (pathValues.length <= 100) {
      return pathValues;
    } else {
      let delnumber = Math.ceil(pathValues.length / 100);
      let SampledValues = [];
      for (var i = 0; i < pathValues.length; i += delnumber) {
        SampledValues.push(pathValues[i]);
      }
      return SampledValues;
    }
  };

  SnaptoRoad = async () => {
    // fetch polyline from google snap to road
    //reformat polyline data
    const path = this.props.data.location_polyline;
    if (this.props.sorting === true) {
      var pathValues = [];
      for (var i = 0; i < path.length; i++) {
        pathValues.push([
          Number(path[i].location[1].data),
          Number(path[i].location[0].data),
        ]);
      }
    } else {
      pathValues = path;
    }

    //sampe down to 100 points max
    let SampledValues = this.downSampleValues(pathValues);
    try {
      const results = await fetch(
        `https://roads.googleapis.com/v1/snapToRoads?path=${SampledValues.join(
          '|'
        )}&interpolate=true&key=AIzaSyDxDzCBe05AjpvIN1IJOq1u1q2tqzGzHpo`
      );
      let data = await results.json();
      return data;
    } catch (e) {
      console.error(e);
      AppStore.addError('unable to get trip markers');
    }
  };

  handleMapRender = async (map, maps) => {
    // handler for map rendering
    // needs to be called once in render to save map ref
    this.map = map;
    this.maps = maps;

    console.log('should snap to road:', this.state.snapToRoad);
    if (this.state.snapToRoad) {
      this.polyline = await this.SnaptoRoad();
    } else {
      this.polyline = null;
    }

    if (
      this.map !== null &&
      this.maps !== null &&
      this.props.data.location_polyline.length > 0 &&
      this.props.data.location_polyline !== null
    ) {
      this.renderPolylines(this.map, this.maps);
    }
  };

  renderPolylines(map, maps) {
    //delete previous polyline
    if (this.state.geodesicPolyline !== null) {
      this.state.geodesicPolyline.setMap(null);
      this.startMarker.setMap(null);
      this.endMarker.setMap(null);
    }

    if (this.polyline && this.polyline.snappedPoints) {
      // if result from google snap to road api
      //render polyline
      let snappedCoordinates = [];
      for (var i = 0; i < this.polyline.snappedPoints.length; i++) {
        var latlng = new maps.LatLng(
          this.polyline.snappedPoints[i].location.latitude,
          this.polyline.snappedPoints[i].location.longitude
        );
        snappedCoordinates.push(latlng);
      }
      let geodesicPolyline = new maps.Polyline({
        path: snappedCoordinates,
        geodesic: true,
        options: {
          strokeColor: 'blue',
          strokeOpacity: 0.75,
          strokeWeight: 5,
        },
      });
      geodesicPolyline.setMap(map);

      //render markers
      //startmarker
      this.startMarker = new maps.Marker({
        position: snappedCoordinates[0],
        map: map,
        icon: {
          size: new maps.Size(12, 12),
          scaledSize: new maps.Size(12, 12),
          url: require('./Icons/startmarker.png'),
        },
      });
      this.startMarker.setMap(map);
      //endmarker
      this.endMarker = new maps.Marker({
        position: snappedCoordinates[Number(snappedCoordinates.length - 1)],
        width: '12px',
        height: '12px',
        map: map,
        icon: {
          size: new maps.Size(55, 55),
          scaledSize: new maps.Size(55, 55),
          url: require('./Icons/endmarker.png'),
        },
      });
      this.endMarker.setMap(map);
      // set bounds
      var bounds = new maps.LatLngBounds();
      var points = snappedCoordinates;
      for (var n = 0; n < points.length; n++) {
        bounds.extend(points[n]);
      }
      map.fitBounds(bounds);
      this.setState({
        geodesicPolyline: geodesicPolyline,
      });
    } else {
      console.log(this.props.data.location_polyline[0][1]);
      // no result from snap to road
      let snappedCoordinates = [];
      for (var e = 0; e < this.props.data.location_polyline.length; e++) {
        var pointslatlng = new maps.LatLng(
          this.props.data.location_polyline[e][0],
          this.props.data.location_polyline[e][1]
        );
        snappedCoordinates.push(pointslatlng);
      }
      let geodesicPolyline = new maps.Polyline({
        path: snappedCoordinates,
        geodesic: true,
        options: {
          strokeColor: 'blue',
          strokeOpacity: 0.75,
          strokeWeight: 5,
        },
      });
      geodesicPolyline.setMap(map);

      //render markers
      //startmarker
      this.startMarker = new maps.Marker({
        position: snappedCoordinates[0],
        map: map,
        icon: {
          size: new maps.Size(12, 12),
          scaledSize: new maps.Size(12, 12),
          url: require('./Icons/startmarker.png'),
        },
      });
      this.startMarker.setMap(map);
      //endmarker
      this.endMarker = new maps.Marker({
        position: snappedCoordinates[Number(snappedCoordinates.length - 1)],
        width: '12px',
        height: '12px',
        map: map,
        icon: {
          size: new maps.Size(55, 55),
          scaledSize: new maps.Size(55, 55),
          url: require('./Icons/endmarker.png'),
        },
      });
      this.endMarker.setMap(map);
      // set bounds
      var propsbounds = new maps.LatLngBounds();
      var propspoints = snappedCoordinates;
      for (var m = 0; m < propspoints.length; m++) {
        propsbounds.extend(propspoints[m]);
      }
      map.fitBounds(propsbounds);
      this.setState({
        geodesicPolyline: geodesicPolyline,
      });
    }
  }

  getAlarms = () => {
    //read alarms out of tripobject
    this.alarms = [];
    if (!this.props.data.alarms) {
      return;
    }
    if (this.props.data.alarms === null) {
      return;
    }
    if (this.props.data.alarms.length === 0) {
      return;
    }
    this.props.data.alarms.forEach((element) => {
      this.getAlarmLocation(
        moment(element.triggeredAt)
          .tz('America/Toronto')
          .format('X')
      );
      if (this.latlng !== undefined) {
        this.alarms.push({
          name: element.name,
          lat: this.latlng.lat,
          lng: this.latlng.lng,
          triggeredAt: moment(element.triggeredAt).format(
            '"dddd, MMMM Do YYYY, h:mm:ss a"'
          ),
        });
      }
    });
  };

  getAlarmLocation = (time) => {
    //getting closest marker on polyline by time
    // time = time.slice(0, -3);
    let timelist = [];
    this.props.data.location_polyline.forEach((element) => {
      timelist.push(element.timestamp);
    });
    //get closest timestamp in markers
    var closest = timelist.reduce(function(prev, curr) {
      return Math.abs(curr - Number(time)) < Math.abs(prev - Number(time))
        ? curr
        : prev;
    });
    this.props.data.location_polyline.forEach((element) => {
      if (closest === element.timestamp) {
        let lat = Number(element.location[1].data);
        let lng = Number(element.location[0].data);
        let closest = this.findClosestMarker(lat, lng);
        let latlng = {
          lat: this.snappedCoordinates[closest].lat,
          lng: this.snappedCoordinates[closest].lng,
        };
        this.latlng = latlng;
      }
    });
  };

  getMapOptions = (maps) => {
    return {
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.TOP_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },
    };
  };
  render() {
    console.log(this.props);
    return this.props.data.location_polyline === null ||
      this.props.data.location_polyline.length < 1 ? (
      <div>
        <h4 style={{ textAlign: 'center' }}>
          There is no GPS data for the trip you selected.
        </h4>
      </div>
    ) : (
      // Important! Always set the container height explicitly
      <div
        style={{
          height:
            this.state.showEmissionTable || this.state.showAnalytics
              ? '311px'
              : '613px',
          width: '100%',
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyDxDzCBe05AjpvIN1IJOq1u1q2tqzGzHpo',
          }}
          defaultCenter={this.state.center || { lat: -79, lng: 43 }}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => this.handleMapRender(map, maps)}
          hoverDistance={12 / 2}
          options={this.getMapOptions}
        >
          {this.alarms.map((element) => {
            return (
              <AlarmMarker
                key={element.triggeredAt}
                lat={element.lat}
                lng={element.lng}
                name={element.name}
                triggeredAt={element.triggeredAt}
              />
            );
          })}
          {this.state.lat !== null && this.state.lng !== null ? (
            <img
              style={markerStyle}
              lat={this.state.lat}
              lng={this.state.lng}
              height="12px"
              width="12px"
              alt="marker"
              src={require('./Icons/startmarker.png')}
            />
          ) : (
            <div />
          )}
        </GoogleMapReact>
      </div>
    );
  }
}

SimpleMap.propTypes = {
  data: PropTypes.object,
  zoom: PropTypes.number,
  CurrentMarkerTime: PropTypes.number,
  showAnalytics: PropTypes.bool,
  showEmissionTable: PropTypes.bool,
  snapToRoad: PropTypes.bool,
  sorting: PropTypes.bool,
};

export default SimpleMap;
