import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate, observable, observe } from 'mobx';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment-timezone';
// import humanizeDuration from 'humanize-duration';

import { ShareVehicleHealthReportPage } from 'containers/ShareVehicleHealthReport';

import { Button, Empty, Icon, Spin, Table, Dropdown, Menu, message, List, Typography, Tag, Tooltip, Checkbox, Popconfirm } from 'antd';
import { PitstopButton, PitstopModal } from 'shared';

import { AppStore, TableStore, CarStore, IssueStore, ReportsDataStore, CarStatusStore, ShopStore } from 'stores';

import {
  renderIndicator,
  renderVehicleId,
  renderIssues,
  renderSuggestedAction
} from 'containers/CarListTable/CarListTableColumns';

import {
  renderIndicatorV2,
  renderVehicleIdV2,
  renderIssuesV2,
  renderLastTriggeredAtV2,
  renderEngineLightStatus,
  renderVehicleResolvedV2
} from 'containers/CarListTable/CarListTableColumnsV2';

import { IssuesTable } from 'containers/Issues';
import { CreateAppointmentPage } from 'containers/CreateAppointment';
import DateRangeBasedTitle from 'components/PriorityVehicles/DateRangeBasedTitle';
import ResolveVehicleModal from './ResolveVehicleModal';

const { Text } = Typography;

const NavigationButton = styled(Link)`
  top: 18px;
  position: absolute;
  right: 18px;
`;

const DVIRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tag {
    cursor: pointer;
    font-size: 1rem;
    padding: 4px 10px;
  }
`;

const IndicatorTableWrapper = styled.div`
  && {
    table th.indicator,
    table td.indicator {
      position: relative;
      padding: 0;
      width: 8px;
    }

    .ant-table-wrapper {
      overflow-x: scroll;
    }
  }

  .status-menu {
    .menu-item {
      cursor: default;
    }
  }
`;

const NoIssuesSection = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  i {
    font-size: 36px;
    margin-right: 12px;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;

  > * {
    margin-top: 5px;
  }
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 5px;
  }

  .btn-status {
    color: white;

    :hover {
      border: 1px solid #1890ff;
      color: white;
    }
  }
`;

class CriticalIssues extends Component {
  state = {
    vehicleName: 'vin',
    selectedCarId: undefined,
    isResolvedModalOpen: false,
    modalCarId: null,
    modalCarVin: null,
    modalCar: null,
    newShopCarStatuses: [],
    currentShopStatuses: [],
    isLoadingStatuses: false,
    isSavingStatuses: false
  };

  tableStore = new TableStore();

  /**
   * Get the car action button type. Primary if the car has the action, default otherwise.
   * @param {String} action
   * @param {CarObject} car
   * @returns String - primary | default
   */
  getCarActionButtonType = (action, car) => {
    if (action === 'contactDriver') {
      // if contactDriver button, check if there are major_call_driver issues
      const shouldCallDriverCount = parseInt(_.get(car.issueCounts, 'major_call_driver', 0));
      return shouldCallDriverCount > 0 ? 'primary' : 'default';
    } else if (action === 'bookService') {
      // if bookService button, check if there are major_no_call issues
      const shouldNotCallDriverCount = parseInt(_.get(car.issueCounts, 'major_no_call', 0));
      return shouldNotCallDriverCount > 0 ? 'primary' : 'default';
    }
    // by default the button will be default (white)
    return 'default';
  }

  cancelResolvedModal = () => {
    const canceledVehicle = this.data.data.find(item => item.id_car === this.state.modalCarId);
    canceledVehicle.resolved = false;
    this.closeResolvedModal();
  }

  closeResolvedModal = () => {
    this.setState({ isResolvedModalOpen: false });
  }

  mapEngineLightToValue = (car) => {
    const engineLightStatus = _.get(car, 'engine_light_status');
    let engineLightValue = 0;
    if (_.isNil(engineLightStatus)) {
      return engineLightValue;
    }
    if (_.get(engineLightStatus, 'generalVehicleWarningLight')) {
      return 2;
    }
    if (_.get(engineLightStatus, 'redStopLamp')) {
      engineLightValue += 6;
    }
    if (_.get(engineLightStatus, 'malfunctionLamp')) {
      engineLightValue += 3;
    }
    if (_.get(engineLightStatus, 'amberWarningLamp')) {
      engineLightValue += 2;
    }
    if (_.get(engineLightStatus, 'protectWarningLamp')) {
      engineLightValue += 2;
    }

    return engineLightValue;
  }

  stopPropagation = (e) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (e.domEvent?.stopPropagation) {
      e.domEvent.stopPropagation();
    }
  }

  onClickNewStatus = async (e, status, car) => {
    this.stopPropagation(e);
    const newStatus = status.key;
    const carId = car?.id_car;
    car.car_status = newStatus;
    // update the table with new status
    CarStore.updateCarStatusOnView(car, newStatus);
    // refresh component
    this.setState({ selectedCarId: carId });

    try {
      // update the car status in the server
      await CarStore.updateCarStatusOnServer(car, newStatus);
      message.info('Status has been updated successfully!');
    } catch (error) {
      console.error(error);
    }
  }

  openShopStatusesModal = (e) => {
    this.stopPropagation(e);
    if (this.state.isLoadingStatuses) {
      return;
    }
    this.setState({
      newShopCarStatuses: this.state.currentShopStatuses.length > 0
        ? JSON.parse(JSON.stringify(this.state.currentShopStatuses))
        : CarStatusStore.getDefaultStatusList
    });
    AppStore.openModals.set('SHOP_STATUSES_MODAL', true);
  }

  getVehiclePriorityFromCodes = (car) => {
    const priorityScore = 1000000 * car.critical + 1000 * car.major + car.minor;
    return priorityScore;
  }

  get columnsV2() {
    return [
      {
        key: 'indicator',
        className: 'indicator',
        title: 'Priority',
        render: renderIndicatorV2,
        sorter: (car1, car2) => {
          return this.getVehiclePriorityFromCodes(car1) - this.getVehiclePriorityFromCodes(car2);
        },
        sortDirections: ['descend']
      },
      {
        key: 'vehicleId',
        title: 'Vehicle ID',
        render: renderVehicleIdV2,
        sorter: (car1, car2) => {
          return car1.car_name.localeCompare(car2.car_name, 'en', { numeric: true });
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        key: 'engineLightStatus',
        title: 'Engine Light',
        render: renderEngineLightStatus,
        sorter: (car1, car2) => {
          return (this.mapEngineLightToValue(car1) - this.mapEngineLightToValue(car2));
        },
        defaultSortOrder: 'descend',
        sortDirections: ['descend'],
        align: 'center'
      },
      {
        key: 'issues',
        title: 'Issues',
        width: '15%',
        render: renderIssuesV2,
      },
      {
        key: 'last_triggered_at',
        title: 'Last Urgent Alert',
        sorter: (car1, car2) => {
          return (new Date(car1.last_triggered_at)).getTime() - (new Date(car2.last_triggered_at)).getTime();
        },
        sortDirections: ['descend', 'ascend'],
        render: renderLastTriggeredAtV2
      },
      {
        key: 'dvir',
        title: 'DVIR',
        render: (car) => {
          if (!car.dvir) {
            return (
              <DVIRWrapper>
                <Text>-</Text>
              </DVIRWrapper>
            );
          }
          const safetyStatus = _.get(car, 'dvir.record.safetyStatus');
          if (!safetyStatus) {
            return (
              <DVIRWrapper>
                <Text>-</Text>
              </DVIRWrapper>
            );
          }
          let color;
          let label;
          if (safetyStatus === 'safe') {
            color = 'green';
            label = 'Safe';
          } else if (safetyStatus === 'unsafe') {
            color = 'red';
            label = 'Unsafe';
          } else {
            color = 'geekblue';
            label = 'Resolved';
          }
          const vehicleDefects = _.get(car, 'dvir.record.payload.vehicleDefects', []);
          const trailerDefects = _.get(car, 'dvir.record.payload.trailerDefects', []);
          const totalDefects = vehicleDefects.length + trailerDefects.length;
          return (
            <DVIRWrapper>
              <Tag className='tag' color={color} key={car.dvir}>
                <Link
                  onClick={evt => evt.stopPropagation()}
                  to={`/dvir/${car.id_car}`}
                >
                  {label} {totalDefects > 0 ? '('+ totalDefects +')' : ''}
                </Link>
              </Tag>
              {/* {humanizedTime && <Text>{humanizedTime}</Text>} */}
            </DVIRWrapper>
          );
        }
      },
      {
        field: 'actions',
        key: 'actions',
        title: 'Actions',
        render: (car) => {
          return (
            <ActionsWrapper>
              <PitstopButton
                ghost
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({
                    selectedCarId: car.id,
                    initialBookServiceDateTime: moment(),
                  }, () =>
                    AppStore.openModals.set('SHARE_VEHICLE_HEALTH_REPORT_MODAL', true)
                  );
                }}
              >
                <Icon type="share-alt" />
                Health Report
              </PitstopButton>
            </ActionsWrapper>
          );
        },
        rowClick: false,
      },
      {
        field: 'status',
        key: 'status',
        title: 'Status',
        render: (car) => {
          const statuses = this.state.currentShopStatuses.length === 0
            ? CarStatusStore.getDefaultStatusList
            : this.state.currentShopStatuses;
          let currentCarStatus = statuses.find((status) => status.key === car.car_status);
          if (car.car_status && !currentCarStatus) {
            currentCarStatus = {
              key: car.car_status,
              description: 'Status Deleted',
              color: 'grey'
            };
          }
          if (!currentCarStatus) {
            // get the default status
            currentCarStatus = statuses.find((status) =>
              status.is_default
            );
          }
          if (!currentCarStatus) {
            // use the first one as default
            currentCarStatus = statuses[0];
          }
          return (
            <StatusWrapper>
              <Dropdown overlay={
                <Menu
                  className='status-menu'
                  onClick={(e) => {
                    if (e.domEvent?.stopPropagation) {
                      e.domEvent.stopPropagation();
                    }
                  }}
                >
                  {
                    // for next version, we should get the user status list from the server
                    statuses.map((status) => {
                      return (
                        <Menu.Item onClick={(e) => this.onClickNewStatus(e, status, car)} className='menu-item' key={status.key}>
                          <Button
                            onClick={(e) => this.onClickNewStatus(e, status, car)}
                            className='btn-status'
                            style={{
                              color: 'white',
                              backgroundColor: status.color
                            }}
                          >
                            {status.description}
                          </Button>
                        </Menu.Item>
                      );
                    })
                  }
                  <Menu.Divider />
                  <Menu.Item onClick={this.stopPropagation} className='menu-item' key='actions'>
                    <Button
                      block
                      icon="edit"
                      ghost
                      type="primary"
                      onClick={this.openShopStatusesModal}
                    >Edit Statuses</Button>
                  </Menu.Item>
                </Menu>
              }>
                {
                  <Button
                    onClick={e => e.stopPropagation()}
                    className='btn-status'
                    style={{
                      backgroundColor: currentCarStatus?.color
                    }}
                  >
                    {currentCarStatus?.description || 'Status Deleted'} <Icon type="down" />
                  </Button>
                }
              </Dropdown>
            </StatusWrapper>
          );
        },
        rowClick: false,
      },
      {
        key: 'resolved',
        title: 'Vehicle Resolved',
        width: '15%',
        render: renderVehicleResolvedV2,
        align: 'center',
        onCell: (record, rowIndex) => {
          return {
            onClick: event => {
              event.stopPropagation();
              this.setState({ modalCarId: record.id_car });
              this.setState({ modalCarVin: record.vin });
              this.setState({ modalCar: record });
              record.resolved = true;
              this.setState({ isResolvedModalOpen: true });
            }
          };
        }
      },
    ];
  }

  get columns() {
    return [
      {
        key: 'indicator',
        className: 'indicator',
        title: null,
        render: renderIndicator,
      },
      {
        key: 'vehicleId',
        title: 'Vehicle ID',
        render: renderVehicleId,
      },
      {
        key: 'suggesstedAction',
        title: 'Suggested Action',
        render: renderSuggestedAction
      },
      {
        key: 'issues',
        title: 'Issues',
        width: '20%',
        render: renderIssues,
      },
      {
        field: 'actions',
        key: 'actions',
        title: 'Actions',
        render: (car) => {
          return (
            <ActionsWrapper>
              <PitstopButton
                ghost
                onClick={() => {
                  this.setState({
                    selectedCarId: car.id,
                    initialBookServiceDateTime: moment(),
                  }, () =>
                    AppStore.openModals.set('SHARE_VEHICLE_HEALTH_REPORT_MODAL', true)
                  );
                }}
              >
                <Icon type="share-alt" />
                Health Report
              </PitstopButton>
              {/* <PitstopButton
                type={this.getCarActionButtonType('bookService', car)}
                onClick={() => {
                  this.setState({ selectedCarId: car.id }, () =>
                    AppStore.openModals.set('BOOK_SERVICE_MODAL', true)
                  );
                }}
              >
                Book Service
              </PitstopButton>
              <PitstopButton
                type={this.getCarActionButtonType('contactDriver', car)}
                onClick={() => {
                  AppStore.addSuccess('Message has sent to the driver!');
                }}
              >
                Contact Driver
              </PitstopButton> */}
            </ActionsWrapper>
          );
        },
        rowClick: false,
      },
    ];
  }

  get tableId() {
    return '';
  }

  /*get data () {
    let data = _.map(this.tableStore.data, (id) => CarStore.data.get(id));
    return _.filter(data, Boolean);
  }*/
  get data() {
    return ReportsDataStore.highPriorityVehicles;
  }

  get carIds() {
    return ReportsDataStore.reportMajorOrCriticalCarIdsInRange || [];
  }

  toggleName = () => {
    if (this.state.vehicleName === 'vin') {
      this.setState({ vehicleName: 'name' });
    } else {
      this.setState({ vehicleName: 'vin' });
    }
  };

  /*fetchData = (filters, table) => {
    return CarStore.getHighPriorityTableByCarsIds(filters, table, ShopStore.currentShop.id, this.carIds);
  }*/

  /*fetchData = () => {
    return ReportsDataStore.getHighPriorityVehicles();
  }*/

  disposer = observe(ReportsDataStore, 'reportMajorOrCriticalCarIdsInRange', () => {
    setTimeout(() => {
      this.tableStore.loaded = false;
      this.tableStore.reset();
      this.setState({ selectedCarId: undefined });
    }, 300);
  });

  disposer1 = observe(ShopStore.currentShop, 'id', () => {
    this.loadShopStatuses();
  });

  componentWillUnmount() {
    this.disposer();
    this.disposer1();
  }

  loadShopStatuses = async () => {
    const shopId = ShopStore.currentShop.id;
    try {
      this.setState({ isLoadingStatuses: true });
      const shopStatuses = await CarStatusStore.fetchShopCarStatuses(shopId);
      this.setState({ currentShopStatuses: shopStatuses || [] });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoadingStatuses: false });
    }
  }

  componentDidMount() {
    this.loadShopStatuses();
  }

  saveNewShopStatuses = async () => {
    const shopId = ShopStore.currentShop.id;
    const statuses = this.state.newShopCarStatuses;
    // check if there's a default status
    if (!_.some(statuses, { is_default: true })) {
      message.error('Please select a default status');
      return;
    }
    try {
      this.setState({ isSavingStatuses: true });
      const newStatuses = await CarStatusStore.updateShopStatuses(shopId, statuses);
      this.setState({ currentShopStatuses: newStatuses || [] });
      message.success('Statuses have been updated successfully!');
      AppStore.openModals.set('SHOP_STATUSES_MODAL', false);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isSavingStatuses: false });
    }
  }

  selectStatusAsDefault = (event, key) => {
    if (event.target.checked) {
      return this.setState({
        newShopCarStatuses: this.state.newShopCarStatuses.map((status) => {
          if (status.key === key) {
            status.is_selected = true;
            status.is_default = true;
          } else {
            status.is_selected = false;
            status.is_default = false;
          }
          return status;
        })
      });
    }
    return this.setState({
      newShopCarStatuses: this.state.newShopCarStatuses.map((status) => {
        if (status.key === key) {
          status.is_selected = false;
          status.is_default = false;
        }
        return status;
      })
    });
  }

  render() {
    let { selectedCarId } = this.state;
    return (
      <>
        <div id="high-priority-vehicles-title"></div>
        <DateRangeBasedTitle titlePrefix='High Priority Vehicles' />

        <NavigationButton to="/cars">
          <Button onClick={() => { }}>View All</Button>
        </NavigationButton>
        <p>
          These are the vehicles with the highest priority issues. Click on them for more information.
        </p>

        {CarStore.demo === false ? (
          <Empty />
        ) : (
          <>
            <Spin tip="Loading..." hidden={this.data.loaded} />
            <IndicatorTableWrapper
              hidden={
                this.data.pending || this.data.length === 0
              }
            >{this.data.loaded ?
              <Table
                columns={this.columnsV2}
                dataSource={this.data.data}
                style={{ cursor: 'pointer' }}
                onRowClick={(record) => {
                  this.props.history.push(`car/${record.id_car}`);
                }}
                expandedRowRender={(car) => (
                  <IssuesTable
                    car={{ id: car.id_car }}
                    id={`carList-issues-${car.id_car}`}
                    fetchData={(params, store) => {
                      IssueStore.getIssuesForCarId(params, store, car.id_car);
                    }}
                    showVehicle={false}
                    limit={5}
                    defaultTab="new"
                    defaultSort="-priority"
                    page="carPage"
                  />
                )}
              /> : null}
            </IndicatorTableWrapper>
            <NoIssuesSection
              hidden={
                !this.tableStore.loaded || this.tableStore.data.length !== 0
              }
            >
              <Icon type="check-circle" theme="twoTone" />
              <p>Your fleet is in healthy condition!</p>
            </NoIssuesSection>
          </>
        )}

        <PitstopModal
          okButtonProps={{ style: { display: 'none' } }}
          onOk={() => { }}
          onCancel={() => {
            AppStore.openModals.set('BOOK_SERVICE_MODAL', false);
          }}
          title=""
          width={800}
          id="BOOK_SERVICE_MODAL"
        >
          <CreateAppointmentPage selectedCarId={selectedCarId} />
        </PitstopModal>

        <PitstopModal
          okButtonProps={{ style: { display: 'none' } }}
          onOk={() => { }}
          onCancel={() => {
            AppStore.openModals.set('SHARE_VEHICLE_HEALTH_REPORT_MODAL', false);
          }}
          title=""
          width={800}
          id="SHARE_VEHICLE_HEALTH_REPORT_MODAL"
        >
          <ShareVehicleHealthReportPage
            selectedCarId={selectedCarId}
          />
        </PitstopModal>

        <PitstopModal
          okButtonProps={{ loading: this.state.isSavingStatuses }}
          onOk={this.saveNewShopStatuses}
          onCancel={() => {
            AppStore.openModals.set('SHOP_STATUSES_MODAL', false);
          }}
          title="Edit Status Options"
          width={800}
          id="SHOP_STATUSES_MODAL"
          style={{ maxWidth: '35%' }}
          isManualClose
        >
          <Text type="secondary" style={{ marginBottom: 5 }}>
            <span style={{
              fontWeight: 'bold',
              color: 'red',
              marginRight: 5
            }}>*</span>Changes to these options will adjust all affected fields
          </Text>
          <List
            itemLayout="horizontal"
            dataSource={this.state.newShopCarStatuses}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <input
                      type="color"
                      id={`color-picker-${item.key}`}
                      name={`color-picker-${item.key}`}
                      value={item.color}
                      onChange={(e) => {
                        item.color = e.target.value;
                        this.setState({ newShopCarStatuses: this.state.newShopCarStatuses });
                      }}
                      style={{
                        border: 0,
                        padding: 0
                      }}
                    />
                  }
                  title={item.description ? (
                    <input
                      placeholder='Insert new status name...'
                      id={`description-${item.key}`}
                      value={item.description}
                      style={{
                        border: 0,
                        width: '100%'
                      }}
                      onChange={(e) => {
                        item.description = e.target.value;
                        this.setState({ newShopCarStatuses: this.state.newShopCarStatuses });
                      }}
                    />
                  ) : (
                    <input
                      id={`description-${item.key}`}
                      placeholder='Insert new status name...'
                      style={{
                        border: 0,
                        width: '100%'
                      }}
                      onChange={(e) => {
                        item.description = e.target.value;
                        this.setState({ newShopCarStatuses: this.state.newShopCarStatuses });
                      }}
                    />
                  )}
                />
                {
                  item.is_default && (
                    <Text style={{ marginRight: '0.5rem' }}>Set as default</Text>
                  )
                }
                <Tooltip title={item.is_default ? 'Remove default' : 'Set as default'}>
                  <Checkbox
                    checked={item.is_selected}
                    value={item.key}
                    onChange={event => {
                      this.selectStatusAsDefault(event, item.key);
                    }}
                  />
                </Tooltip>
                <Popconfirm
                  placement="right"
                  title={'Are you sure you want to delete this status?'}
                  onConfirm={() => {
                    // remove item from array
                    this.setState({ newShopCarStatuses: this.state.newShopCarStatuses.filter((status) => status.key !== item.key) });
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="link"
                    style={{ color: 'red' }}
                    key="1"
                    shape="circle"
                    icon="delete"
                  />
                </Popconfirm>
              </List.Item>
            )}
          />
          <div style={{ marginTop: 10 }}>
            <Button
              icon="plus"
              type="primary"
              ghost
              onClick={() => {
                this.state.newShopCarStatuses.push({
                  key: `new-${this.state.newShopCarStatuses.length + 1}`,
                  color: '#CFCFCF'
                });
                this.setState({ newShopCarStatuses: this.state.newShopCarStatuses }, () => {
                  // focus on the last item
                  setTimeout(() => {
                    document.getElementById(`description-new-${this.state.newShopCarStatuses.length}`).focus();
                  }, 100);
                });
              }}
            >
              Create New Status
            </Button>
          </div>
        </PitstopModal>
        {this.state.isResolvedModalOpen ?
          <ResolveVehicleModal visible={this.state.isResolvedModalOpen} carVin={this.state.modalCarVin} carId={this.state.modalCarId} car={this.state.modalCar} onCancel={this.cancelResolvedModal} closeModal={this.closeResolvedModal} style={{ width: '80%' }} />
          : null}
      </>
    );
  }
}

decorate(CriticalIssues, {
  tableStore: observable,
  data: computed,
  carIds: computed,
});

CriticalIssues.propTypes = {
  history: PropTypes.object,
  userId: PropTypes.number,
};

export default withRouter(observer(CriticalIssues));
