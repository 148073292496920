import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PitstopButton } from 'shared';
import { ShopStore } from 'stores';
import DealershipChooser from 'components/DealershipChooser';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 25px 0 10px;
`;

const ActionButtonsWrapper = styled.div`
  right: 15px;

  button {
    margin-left: 10px;
  }
`;

class ContactDetails extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object
  };

  shopId = ShopStore.currentShop.id

  state = {};

  render () {
    return (
      <Fragment>
        <DealershipChooser />
        <ActionsWrapper>
          <h1>Contact Details</h1>
          <ActionButtonsWrapper>
            <PitstopButton type='link' onClick={() => {}}>
              Cancel
            </PitstopButton>
            <PitstopButton onClick={() => {}}>
              Save Contact
            </PitstopButton>
          </ActionButtonsWrapper>
        </ActionsWrapper>

        <ActionsWrapper>
          <div></div>
          <ActionButtonsWrapper>
            <PitstopButton type='link' onClick={() => {}}>
              Cancel
            </PitstopButton>
            <PitstopButton onClick={() => {}}>
              Save
            </PitstopButton>
          </ActionButtonsWrapper>
        </ActionsWrapper>
      </Fragment>
    );
  }
}

decorate(ContactDetails, {
  shopId: observable,
  shopUsers: computed
});

export default withRouter(observer(ContactDetails));
