import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Empty } from 'antd';
import { DoughnutChart } from 'shared/PitstopUI/PitstopChart';

import { CarStore, ReportsDataStore } from 'stores';

class FleetSafetyViolation extends Component {
  get data() {
    let { speedingEventCount, brakingEventCount, accelerationEventCount } =
      ReportsDataStore.safetyViolationCount;

    let value = [
      {
        name: 'High Speeding',
        color: '#eb3d34',
        y: speedingEventCount || 0,
      },
      {
        name: 'Hard Braking',
        color: '#ED5565',
        y: brakingEventCount || 0,
      },
      {
        name: 'Acceleration',
        color: '#e2eb34',
        y: accelerationEventCount || 0,
      },
    ];

    return {
      seriesName: 'violations',
      emptyMessage: 'Your fleet has no Violation alarms',
      value,
    };
  }

  render() {
    if (CarStore.demo === false) return <Empty />;

    return (
      <DoughnutChart
        chart={{
          height: 250,
          margin: [0, 250, 0, 0],
          legend: {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'top',
            x: Number(`${window.innerWidth > 1600 ? -100 : 0}`),
            y: 90,
          },
        }}
        data={this.data}
        loaded={ReportsDataStore.alarmReport.loaded}
      />
    );
  }
}

export default observer(FleetSafetyViolation);
