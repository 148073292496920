import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { CurrentUserStore, ShopStore } from 'stores';

import AppointmentCalendar from 'containers/AppointmentCalendar';
import { AnalyticsView } from 'containers/AnalyticsView';
import User from 'containers/UserProfile/';

import Campaign from 'containers/Campaigns';
import {
  CreateCampaignPage,
  CreateCampaignSplashPage,
} from 'containers/CreateCampaign';
import { CreateAppointmentPage } from 'containers/CreateAppointment';
import Cars from 'containers/Cars/';
import Vehicles from 'containers/Vehicles/';
import Profile from 'containers/profile';
import Faq from 'containers/FAQ';

import CarProfile from 'containers/CarProfile';
import TrailerProfile from 'containers/TrailerProfile';
import { IssuesPage } from 'containers/Issues';
import { IssueProfilePage, IssueProfilePageV2 } from 'containers/IssueProfile';
import LiveLocationPage from 'containers/LiveLocation';
import { GeotabSyncStatus } from 'containers/Integration';
import WidgetPage from 'containers/Labs';
import SymptomAnalyser from 'containers/Widgets/SymptomAnalyser';
import RepairPricing from 'containers/Widgets/RepairPricing';
import FtConfig from 'containers/Widgets/FtConfig';
import TripCompairisonPage from 'containers/TripCompairisonPage';
import TripMatchingPage from 'containers/TripMatching';
import ShareTripPage from 'containers/ShareTripPage';
import { OnboardingPage } from 'v3/pages';

//Long Beach Dashboard Imports
import HighPriorityVehiclesDashboard from 'containers/Report/Dashboards/HighPriorityVehiclesDashboard';
import DowntimeCostDashboard from 'containers/Report/Dashboards/DowntimeCostDashboard';
import AlgorithmInsightsDashboard from 'containers/Report/Dashboards/AlgorithmInsightsDashboard';
import FleetHealthDashboard from 'containers/Report/Dashboards/FleetHealthDashboard';
import IdlingFuelReportsDashboard from 'containers/Report/Dashboards/IdlingFuelReportsDashboard';
import AlertsAccuracyDashboard from 'containers/Report/Dashboards/AlertsAccuracyDashboard';
import ShopPerformanceDashboard from 'containers/Report/Dashboards/ShopPerformanceDashboard';
//End of Long Beach Dashboard Imports

import RelevantServices from 'containers/Report/Dashboards/RelevantServices';
import UsersPage from 'containers/Users';
// import { IntegrationPage } from 'containers/Integration';
import { MaintenancePage } from 'containers/Maintenance';
import { ServiceIntervalPage } from 'containers/ServiceInterval'
import 'helpers/HelperFunctions';
import { GeneralReport } from 'containers/Report/DashboardUsageReport';
import Chat from 'containers/Chat/Chat';
import WorkOrderPage, {
  WorkOrderDetails,
  WorkOrderDetailsDesktop,
} from 'containers/WorkOrder';

import PurchaseOrdersPage, {
  PurchaseOrdersAddPage,
  PurchaseOrdersDetailsPage,
} from 'containers/PurchaseOrders';

import DVIRDetailsPage from 'containers/DVIR';

import PartsListPage, {
  AddPartPage,
  PartDetailsPage,
} from 'containers/PartsManagement';
import ContactsPage from 'containers/Contacts';

import FireflyUtilization from 'containers/FireflyUtilization';
import ArchivedAssetsPage from 'containers/Vehicles/ArchivedAssetsPage';
import FleetCostsPage from 'containers/FleetCosts/FleetCostsPage';
import FleetAvgCostPerVisitPage from 'containers/FleetAvgCostPerVisit/FleetAvgCostPerVisitPage';
import FleetRepeatVisitsPage from 'containers/FleetRepeatVisits/FleetRepeatVisitsPage';
import FleetPMQualityPage from 'containers/FleetPMQuality/FleetPMQualityPage';
import FleetReportsPage from 'containers/FleetReports/FleetReportsPage';
import PartReportsPage from 'containers/PartReports/PartReportsPage';
import PartRepeatVisitsPage from 'containers/PartRepeatVisits/PartRepeatVisitsPage';
import WarrantiesPage from 'containers/Warranties/WarrantiesPage';
import ReportsDemoPage from 'containers/ReportsDemoPage';

export const dealershipRoutes = () => {
  const isLongBeachShopAndAdminOrCatherine =
    ShopStore.currentShop.id === 1085 &&
    (CurrentUserStore.user.role === 'admin' ||
      CurrentUserStore.user.id === 11719);

  const defaultRoute = isLongBeachShopAndAdminOrCatherine ? (
    <Route exact path="/" key="/" component={HighPriorityVehiclesDashboard} />
  ) : (
    <Route exact path="/" key="/" component={HighPriorityVehiclesDashboard} />
  );
  return [
    defaultRoute,
    // New routes for Long Beach (+ Demo)
    // >>>>>>>>>>
    <Route
      exact
      path="/highpriorityvehicles"
      key="/highpriorityvehicles"
      component={HighPriorityVehiclesDashboard}
    />,
    <Route
      exact
      path="/fleet-reports"
      key="/fleet-reports"
      component={FleetReportsPage}
    />,
    <Route
      exact
      path="/fleet-costs"
      key="/fleet-costs"
      component={FleetCostsPage}
    />,
    <Route
      exact
      path="/fleet-repeat-visits"
      key="/fleet-repeat-visits"
      component={FleetRepeatVisitsPage}
    />,
    <Route
      exact
      path="/fleet-avg-cost-per-visit"
      key="/fleet-avg-cost-per-visit"
      component={FleetAvgCostPerVisitPage}
    />,
    <Route
      exact
      path="/fleet-quality-of-pm-analysis"
      key="/fleet-quality-of-pm-analysis"
      component={FleetPMQualityPage}
    />,
    <Route
      exact
      path="/part-reports"
      key="/part-reports"
      component={PartReportsPage}
    />,
    // <Route
    //   exact
    //   path="/part-costs"
    //   key="/part-costs"
    //   component={PartCostsPage}
    // />,
    <Route
      exact
      path="/part-repeat-visits"
      key="/part-repeat-visits"
      component={PartRepeatVisitsPage}
    />,
    // <Route
    //   exact
    //   path="/part-avg-cost-per-visit"
    //   key="/part-avg-cost-per-visit"
    //   component={PartAvgCostPerVisitPage}
    // />,
    <Route
      exact
      path="/downtimecost"
      key="/downtimecost"
      component={DowntimeCostDashboard}
    />,
    <Route
      exact
      path="/algorithminsights"
      key="/algorithminsights"
      component={AlgorithmInsightsDashboard}
    />,
    <Route
      exact
      path="/fleethealth"
      key="/fleethealth"
      component={FleetHealthDashboard}
    />,
    <Route
      exact
      path="/idlingfuelreports"
      key="/idlingfuelreports"
      component={IdlingFuelReportsDashboard}
    />,
    <Route
      exact
      path="/alertsaccuracy"
      key="/alertsaccuracy"
      component={AlertsAccuracyDashboard}
    />,
    <Route
      exact
      path="/shopperformance"
      key="/shopperformance"
      component={ShopPerformanceDashboard}
    />,
    // >>>>>>>>>>
    // END of new routes for long Beach
    <Route
      exact
      path="/appointments"
      key="/appointments"
      component={AppointmentCalendar}
    />,
    <Route path="/cars" key="/cars" component={Cars} />,
    <Route exact path="/vehicles" key="/vehicles" component={Vehicles} />,
    <Route
      exact
      path="/archived-assets"
      key="/archived-assets"
      component={ArchivedAssetsPage}
    />,
    <Route
      exact
      path="/trailer/:id"
      key="/trailer/:id"
      component={TrailerProfile}
    />,
    <Route
      exact
      path="/trailer/:id/:selector"
      key="/trailer/:id/:selector"
      component={TrailerProfile}
    />,
    <Route exact path="/car/:id" key="/car/:id" component={CarProfile} />,
    <Route
      exact
      path="/car/:id/:selector"
      key="/car/:id/:selector"
      component={CarProfile}
    />,
    <Route
      exact
      path="/car/:id/trips/compare/:tripIDs"
      key="/car/:id/trips/compare/:tripIDs"
      component={TripCompairisonPage}
    />,
    <Route
      exact
      path="/car/:id/trips/match"
      key="/car/:id/trips/match"
      component={TripMatchingPage}
    />,
    <Route
      exact
      path="/car/:id/sharetrip/:tripId"
      key="/car/:id/sharetrip/:tripId"
      component={ShareTripPage}
    />,
    <Route
      exact
      path="/report"
      key="/report"
      component={HighPriorityVehiclesDashboard}
    />,
    <Route
      exact
      path="/relevant-services"
      key="/relevant-services"
      component={RelevantServices}
    />,
    <Route
      exact
      path="/firefly-utilization"
      key="/firefly-utilization"
      component={FireflyUtilization}
    />,
    <Route
      exact
      path="/maintenance"
      key="/maintenance"
      component={MaintenancePage}
    />,
    <Route
      exact
      path="/maintenance/:selector"
      key="/maintenance/:selector"
      component={MaintenancePage}
    />,
    <Route
      exact
      path="/service-interval"
      key="/service-interval"
      component={ServiceIntervalPage}
    />,
    <Route
      exact
      path="/service-interval/:selector"
      key="/service-interval/:selector"
      component={ServiceIntervalPage}
    />,
    <Route
      exact
      path="/service-interval-new"
      key="/service-interval-new"
      component={ServiceIntervalPage}
    />,
    <Route exact path="/issues/" key="/issues/" component={IssuesPage} />,
    <Route
      exact
      path="/location/"
      key="/location/"
      component={LiveLocationPage}
    />,
    <Route
      exact
      path="/issues/:id/v2"
      key="/issues/:id/v2"
      component={(props) => <IssueProfilePageV2 key={props.location.key} />}
    />,
    <Route
      exact
      path="/issues/:id"
      key="/issues/:id"
      component={(props) => <IssueProfilePage key={props.location.key} />}
    />,
    <Route
      exact
      path="/car/:id/pid"
      key="/car/:id/pid"
      component={AnalyticsView}
    />,
    <Route exact path="/user/:userid" key="/user/:userid" component={User} />,
    <Route exact path="/campaign" key="/campaign" component={Campaign} />,
    <Route
      exact
      path="/campaign/create"
      key="/campaign/create"
      component={CreateCampaignSplashPage}
    />,
    <Route
      exact
      path="/campaign/create/steps"
      key="/campaign/create/steps"
      component={CreateCampaignPage}
    />,

    <Route
      exact
      path="/appointment/create/steps"
      key="/appointment/create/steps"
      component={CreateAppointmentPage}
    />,
    <Route exact path="/profile" key="/profile" component={Profile} />,
    <Route exact path="/FAQ" key="/FAQ" component={Faq} />,
    // <Route exact path="/billing" key="/billing" component={Billing} />,
    // <Route
    //   exact
    //   path="/billing/checkout/:device"
    //   key="/billing/checkout/:device"
    //   component={CheckoutPage}
    // />,
    // <Route
    //   exact
    //   path="/billing/orders"
    //   key="/billing/orders"
    //   component={OrderHistory}
    // />,
    // <Route
    //   exact
    //   path="/billing/payments"
    //   key="/billing/payments"
    //   component={PaymentMethod}
    // />,
    <Route
      exact
      path="/user-management"
      key="/user-management"
      component={UsersPage}
    />,
    // <Route
    //   exact
    //   path="/integrations"
    //   key="/integrations"
    //   component={IntegrationPage}
    // />,
    <Route
      exact
      path="/shops/:shopId/geotab/sync-status"
      key="/geotab"
      component={GeotabSyncStatus}
    />,
    <Route exact path="/labs" key="/labs" component={WidgetPage} />,
    <Route
      exact
      path="/labs/symptom-analyser"
      key="/labs/symptom-analyser"
      component={SymptomAnalyser}
    />,
    <Route
      exact
      path="/labs/repair-pricing"
      key="/labs/repair-pricing"
      component={RepairPricing}
    />,
    <Route
      exact
      path="/labs/ftconfig"
      key="/labs/ftconfig"
      component={FtConfig}
    />,
    <Route
      exact
      path="/usage-report"
      key="/usage-report"
      component={GeneralReport}
    />,
    <Route exact path="/pages/chat" key="/pages/chat" component={Chat} />,
    <Route
      exact
      path="/work-order"
      key="/work-order"
      component={WorkOrderPage}
    />,
    <Route
      path="/work-order/add"
      key="/work-order/add"
      component={WorkOrderDetails}
    />,
    <Route
      path="/work-order/:id/edit"
      key="/work-order/:id/edit"
      component={WorkOrderDetails}
    />,
    <Route
      path="/work-order-desktop/add"
      key="/work-order-desktop/add"
      component={WorkOrderDetailsDesktop}
    />,
    <Route
      path="/work-order-desktop/:id/edit"
      key="/work-order-desktop/:id/edit"
      component={WorkOrderDetailsDesktop}
    />,
    <Route
      exact
      path="/purchase-orders"
      key="/purchase-orders"
      component={PurchaseOrdersPage}
    />,
    <Route
      exact
      path="/purchase-orders/add"
      key="/purchase-orders/add"
      component={PurchaseOrdersAddPage}
    />,
    <Route
      exact
      path="/purchase-orders/:id"
      key="/purchase-orders/:id"
      component={PurchaseOrdersDetailsPage}
    />,
    <Route
      exact
      path="/dvir/:carId"
      key="/dvir/:carId"
      component={DVIRDetailsPage}
    />,
    <Route exact path="/contacts" key="/contacts" component={ContactsPage} />,
    <Route exact path="/parts" key="/parts" component={PartsListPage} />,
    <Route exact path="/parts/add" key="/parts/add" component={AddPartPage} />,
    <Route
      exact
      path="/parts/:id"
      key="/parts/:id"
      component={PartDetailsPage}
    />,
    <Route path="/onboarding" key="/onboarding" component={OnboardingPage} />,
    <Route
      exact
      path="/warranties"
      key="/warranties"
      component={WarrantiesPage}
    />,
    <Route exact path="/reports" key="/reports" component={ReportsDemoPage} />,
    <Redirect from="*" to="/report" key="redirect" />,
  ];
};

export const technicianRoutes = () => {
  return [
    <Route exact path="/" key="/" component={HighPriorityVehiclesDashboard} />,
    // New routes for Long Beach (+ Demo)
    // >>>>>>>>>>
    <Route
      exact
      path="/highpriorityvehicles"
      key="/highpriorityvehicles"
      component={HighPriorityVehiclesDashboard}
    />,
    <Route
      exact
      path="/fleet-reports"
      key="/fleet-reports"
      component={FleetReportsPage}
    />,
    <Route
      exact
      path="/fleet-costs"
      key="/fleet-costs"
      component={FleetCostsPage}
    />,
    <Route
      exact
      path="/fleet-repeat-visits"
      key="/fleet-repeat-visits"
      component={FleetRepeatVisitsPage}
    />,
    <Route
      exact
      path="/fleet-avg-cost-per-visit"
      key="/fleet-avg-cost-per-visit"
      component={FleetAvgCostPerVisitPage}
    />,
    <Route
      exact
      path="/fleet-quality-of-pm-analysis"
      key="/fleet-quality-of-pm-analysis"
      component={FleetPMQualityPage}
    />,
    <Route
      exact
      path="/part-reports"
      key="/part-reports"
      component={PartReportsPage}
    />,
    // <Route
    //   exact
    //   path="/part-costs"
    //   key="/part-costs"
    //   component={PartCostsPage}
    // />,
    <Route
      exact
      path="/part-repeat-visits"
      key="/part-repeat-visits"
      component={PartRepeatVisitsPage}
    />,
    // <Route
    //   exact
    //   path="/part-avg-cost-per-visit"
    //   key="/part-avg-cost-per-visit"
    //   component={PartAvgCostPerVisitPage}
    // />,
    <Route
      exact
      path="/downtimecost"
      key="/downtimecost"
      component={DowntimeCostDashboard}
    />,
    <Route
      exact
      path="/algorithminsights"
      key="/algorithminsights"
      component={AlgorithmInsightsDashboard}
    />,
    <Route
      exact
      path="/fleethealth"
      key="/fleethealth"
      component={FleetHealthDashboard}
    />,
    <Route
      exact
      path="/idlingfuelreports"
      key="/idlingfuelreports"
      component={IdlingFuelReportsDashboard}
    />,
    <Route
      exact
      path="/alertsaccuracy"
      key="/alertsaccuracy"
      component={AlertsAccuracyDashboard}
    />,
    <Route
      exact
      path="/shopperformance"
      key="/shopperformance"
      component={ShopPerformanceDashboard}
    />,
    // >>>>>>>>>>
    // END of new routes for long Beach
    <Route
      exact
      path="/appointments"
      key="/appointments"
      component={AppointmentCalendar}
    />,
    <Route path="/cars" key="/cars" component={Cars} />,
    <Route exact path="/vehicles" key="/vehicles" component={Vehicles} />,
    <Route
      exact
      path="/archived-assets"
      key="/archived-assets"
      component={ArchivedAssetsPage}
    />,
    <Route
      exact
      path="/trailer/:id"
      key="/trailer/:id"
      component={TrailerProfile}
    />,
    <Route
      exact
      path="/trailer/:id/:selector"
      key="/trailer/:id/:selector"
      component={TrailerProfile}
    />,
    <Route exact path="/car/:id" key="/car/:id" component={CarProfile} />,
    <Route
      exact
      path="/car/:id/:selector"
      key="/car/:id/:selector"
      component={CarProfile}
    />,
    <Route
      exact
      path="/car/:id/trips/compare/:tripIDs"
      key="/car/:id/trips/compare/:tripIDs"
      component={TripCompairisonPage}
    />,
    <Route
      exact
      path="/car/:id/trips/match"
      key="/car/:id/trips/match"
      component={TripMatchingPage}
    />,
    <Route
      exact
      path="/car/:id/sharetrip/:tripId"
      key="/car/:id/sharetrip/:tripId"
      component={ShareTripPage}
    />,
    <Route
      exact
      path="/report"
      key="/report"
      component={HighPriorityVehiclesDashboard}
    />,
    <Route
      exact
      path="/relevant-services"
      key="/relevant-services"
      component={RelevantServices}
    />,
    <Route
      exact
      path="/firefly-utilization"
      key="/firefly-utilization"
      component={FireflyUtilization}
    />,
    <Route
      exact
      path="/maintenance"
      key="/maintenance"
      component={MaintenancePage}
    />,
    <Route
      exact
      path="/maintenance/:selector"
      key="/maintenance/:selector"
      component={MaintenancePage}
    />,
    <Route exact path="/issues/" key="/issues/" component={IssuesPage} />,
    <Route
      exact
      path="/location/"
      key="/location/"
      component={LiveLocationPage}
    />,
    <Route
      exact
      path="/issues/:id/v2"
      key="/issues/:id/v2"
      component={(props) => <IssueProfilePageV2 key={props.location.key} />}
    />,
    <Route
      exact
      path="/issues/:id"
      key="/issues/:id"
      component={(props) => <IssueProfilePage key={props.location.key} />}
    />,
    <Route
      exact
      path="/car/:id/pid"
      key="/car/:id/pid"
      component={AnalyticsView}
    />,
    <Route exact path="/user/:userid" key="/user/:userid" component={User} />,
    <Route exact path="/campaign" key="/campaign" component={Campaign} />,
    <Route
      exact
      path="/campaign/create"
      key="/campaign/create"
      component={CreateCampaignSplashPage}
    />,
    <Route
      exact
      path="/campaign/create/steps"
      key="/campaign/create/steps"
      component={CreateCampaignPage}
    />,

    <Route
      exact
      path="/appointment/create/steps"
      key="/appointment/create/steps"
      component={CreateAppointmentPage}
    />,
    <Route exact path="/profile" key="/profile" component={Profile} />,
    <Route exact path="/FAQ" key="/FAQ" component={Faq} />,
    // <Route exact path="/billing" key="/billing" component={Billing} />,
    // <Route
    //   exact
    //   path="/billing/checkout/:device"
    //   key="/billing/checkout/:device"
    //   component={CheckoutPage}
    // />,
    // <Route
    //   exact
    //   path="/billing/orders"
    //   key="/billing/orders"
    //   component={OrderHistory}
    // />,
    // <Route
    //   exact
    //   path="/billing/payments"
    //   key="/billing/payments"
    //   component={PaymentMethod}
    // />,
    <Route
      exact
      path="/user-management"
      key="/user-management"
      component={UsersPage}
    />,
    // <Route
    //   exact
    //   path="/integrations"
    //   key="/integrations"
    //   component={IntegrationPage}
    // />,
    <Route
      exact
      path="/shops/:shopId/geotab/sync-status"
      key="/geotab"
      component={GeotabSyncStatus}
    />,
    <Route exact path="/labs" key="/labs" component={WidgetPage} />,
    <Route
      exact
      path="/labs/symptom-analyser"
      key="/labs/symptom-analyser"
      component={SymptomAnalyser}
    />,
    <Route
      exact
      path="/labs/repair-pricing"
      key="/labs/repair-pricing"
      component={RepairPricing}
    />,
    <Route
      exact
      path="/labs/ftconfig"
      key="/labs/ftconfig"
      component={FtConfig}
    />,
    <Route
      exact
      path="/usage-report"
      key="/usage-report"
      component={GeneralReport}
    />,
    <Route exact path="/pages/chat" key="/pages/chat" component={Chat} />,
    <Route
      exact
      path="/work-order"
      key="/work-order"
      component={WorkOrderPage}
    />,
    <Route
      path="/work-order/add"
      key="/work-order/add"
      component={WorkOrderDetails}
    />,
    <Route
      path="/work-order/:id/edit"
      key="/work-order/:id/edit"
      component={WorkOrderDetails}
    />,
    <Route
      path="/work-order-desktop/add"
      key="/work-order-desktop/add"
      component={WorkOrderDetailsDesktop}
    />,
    <Route
      path="/work-order-desktop/:id/edit"
      key="/work-order-desktop/:id/edit"
      component={WorkOrderDetailsDesktop}
    />,
    <Route
      exact
      path="/purchase-orders"
      key="/purchase-orders"
      component={PurchaseOrdersPage}
    />,
    <Route
      exact
      path="/purchase-orders/add"
      key="/purchase-orders/add"
      component={PurchaseOrdersAddPage}
    />,
    <Route
      exact
      path="/purchase-orders/:id"
      key="/purchase-orders/:id"
      component={PurchaseOrdersDetailsPage}
    />,
    <Route
      exact
      path="/dvir/:carId"
      key="/dvir/:carId"
      component={DVIRDetailsPage}
    />,
    <Route exact path="/parts" key="/parts" component={PartsListPage} />,
    <Route exact path="/parts/add" key="/parts/add" component={AddPartPage} />,
    <Route
      exact
      path="/parts/:id"
      key="/parts/:id"
      component={PartDetailsPage}
    />,
    <Route path="/onboarding" key="/onboarding" component={OnboardingPage} />,
    <Redirect from="*" to="/report" key="redirect" />,
  ];
};

export const userRoutes = () => {
  return [
    <Route path="/user/:userid" key="/user/:userid" component={User} />,
    <Route path="/cars" key="/cars" component={Cars} />,
    <Route exact path="/profile" key="/profile" component={Profile} />,
    <Route exact path="/FAQ" key="/FAQ" component={Faq} />,
    // <Route exact path="/billing" key="/billing" component={Billing} />,
    // <Route
    //   exact
    //   path="/billing/checkout/:device"
    //   key="/billing/checkout/:device"
    //   component={CheckoutPage}
    // />,
    // <Route
    //   exact
    //   path="/billing/orders"
    //   key="/billing/orders"
    //   component={OrderHistory}
    // />,
    // <Route
    //   exact
    //   path="/billing/payments"
    //   key="/billing/payments"
    //   component={PaymentMethod}
    // />,
    <Route
      exact
      path="/appointments"
      key="/appointments"
      component={AppointmentCalendar}
    />,
    <Route
      exact
      path="/appointment/create/steps"
      key="/appointment/create/steps"
      component={CreateAppointmentPage}
    />,
    <Route exact path="/car/:id" key="/car/:id" component={CarProfile} />,
    <Route
      exact
      path="/car/:id/:selector"
      key="/car/:id/:selector"
      component={CarProfile}
    />,
    <Route
      exact
      path="/car/:id/:selector/compare"
      key="/car/:id/:selector/compare"
      component={TripCompairisonPage}
    />,
    <Route
      exact
      path="/car/:id/:selector/match"
      key="/car/:id/:selector/match"
      component={TripMatchingPage}
    />,
    <Route
      exact
      path="/car/:id/sharetrip/:tripId"
      key="/car/:id/sharetrip/:tripId"
      component={ShareTripPage}
    />,
    <Route exact path="/labs" key="/labs" component={WidgetPage} />,
    <Route
      exact
      path="/labs/symptom-analyser"
      key="/labs/symptom-analyser"
      component={SymptomAnalyser}
    />,
    <Route
      exact
      path="/labs/repair-pricing"
      key="/labs/repair-pricing"
      component={RepairPricing}
    />,
    <Route
      exact
      path="/labs/ftconfig"
      key="/labs/ftconfig"
      component={FtConfig}
    />,
    <Route
      exact
      path="/usage-report"
      key="/usage-report"
      component={GeneralReport}
    />,
    <Route exact path="/pages/chat" key="/pages/chat" component={Chat} />,
    <Redirect
      from="*"
      to={`/user/${CurrentUserStore.user.id}`}
      key="redirect"
    />,
  ];
};
