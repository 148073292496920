import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

/**
 * Custom header cell to display a tooltip with the current sorting status
 * @param {*} props
 * @returns
 */
function CustomHeaderCell(props) {
  if (
    props.className.includes('ant-table-column-has-sorter')
  ) {
    const columnHeader = get(
      props,
      'children[0].props.children.props.children[0].props.children',
      ''
    );
    const sortIcons = get(
      props,
      'children[0].props.children.props.children[1].props.children.props.children',
      []
    );
    let specialTextAsc = 'ascending';
    let specialTextDesc = 'descending';
    if (columnHeader === 'Repair Priority') {
      specialTextAsc = 'Minor first';
      specialTextDesc = 'Critical first';
    } else if (columnHeader === 'DVIR') {
      specialTextAsc = 'Unsafe first';
      specialTextDesc = 'Safe first';
    } else if (columnHeader === 'Next PM') {
      specialTextAsc = 'soonest first';
      specialTextDesc = 'furthest first';
    } else if (columnHeader === 'Priority') {
      specialTextAsc = 'Critical first';
      specialTextDesc = 'Minor first';
    } else if (columnHeader === 'Due') {
      specialTextAsc = 'soonest first';
      specialTextDesc = 'furthest first';
    }
    let tooltipMessage = '';
    const sortedBy = sortIcons.find(i => i.props.className.includes('on'));
    if (!sortedBy) {
      tooltipMessage = `Click to sort ${specialTextAsc}`;
    } else if (sortedBy && sortedBy.props.className.includes('ant-table-column-sorter-up')) {
      tooltipMessage = `Click to sort ${specialTextDesc}`;
    } else if (sortedBy && sortedBy.props.className.includes('ant-table-column-sorter-down')) {
      tooltipMessage = 'Click to cancel sorting';
    }
    // if props contains 2 children, the first a span and the second an icon with className: ant-table-filter-icon,
    // then it's a column with a filter
    // wrap ony the span in a Tooltip
    if (props.children.length === 2) {
      return (
        <th {...props}>
          <Tooltip title={tooltipMessage}>
            {props.children[0]}
          </Tooltip>
          {props.children[1]}
        </th>
      );
    }
  }
  return (
    <th {...props} />
  );
}

CustomHeaderCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default CustomHeaderCell;
