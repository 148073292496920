export default [
  {
    id_shop: 614,
    id_car: 56161,
    vin: 'DEMO-017',
    car_name: '80-670406',
    rtc_time: 1646437123,
    def_system_health_alert: {
      one_week_alerts: 0,
      two_week_alerts: 0,
      system_health_flag: 0,
      total_alerts_detected: 1,
      four_week_rapid_drain_alerts: 0,
      four_week_rapid_drain_alerts_total: 0,
    },
  },
  {
    id_shop: 614,
    id_car: 56157,
    vin: 'DEMO-016',
    car_name: '317-5310',
    rtc_time: 1646437123,
    def_system_health_alert: {
      one_week_alerts: 0,
      two_week_alerts: 0,
      system_health_flag: 0,
      total_alerts_detected: 1,
      four_week_rapid_drain_alerts: 1,
      four_week_rapid_drain_alerts_total: 1,
    },
  },
  {
    id_shop: 614,
    id_car: 56160,
    vin: 'DEMO-018',
    car_name: '55-670397',
    rtc_time: 1646782708,
    def_system_health_alert: {
      one_week_alerts: 0,
      two_week_alerts: 1,
      system_health_flag: 0,
      total_alerts_detected: 1,
      four_week_rapid_drain_alerts: 1,
      four_week_rapid_drain_alerts_total: 1,
    },
  },
  {
    id_shop: 614,
    id_car: 52077,
    vin: 'DEMO-011',
    car_name: '10-670492',
    rtc_time: 1646782708,
    def_system_health_alert: {
      one_week_alerts: 1,
      two_week_alerts: 1,
      system_health_flag: 0,
      total_alerts_detected: 1,
      four_week_rapid_drain_alerts: 1,
      four_week_rapid_drain_alerts_total: 1,
    },
  },
  {
    id_shop: 614,
    id_car: 52081,
    vin: 'DEMO-015',
    car_name: '10-867688',
    rtc_time: 1646782709,
    def_system_health_alert: {
      one_week_alerts: 1,
      two_week_alerts: 1,
      system_health_flag: 0,
      total_alerts_detected: 4,
      four_week_rapid_drain_alerts: 1,
      four_week_rapid_drain_alerts_total: 4,
    },
  },
];
