import React from 'react';
import { Modal, Table, Select, Tag, Checkbox, Spin } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { IssueStore, TableStore, ShopStore, CurrentUserStore, AppStore } from 'stores';
import { webServiceProvider } from 'shared';

const {Option} = Select;

class ResolveVehicleModal extends React.Component {
  state = {
    issues: [],
    //Priorities that need to be updated of the form {issueId: issueId, priority: priority}
    prioritiesToUpdate: [],
    //Issue IDs of which "apply to similar issues" is true
    applyToSimilarIds: [],
    applyToMakeIds: [],
    applyToMakeModelIds: [],
    applyToMakeModelYearIds: [],
    applyToThisVehicleIds: [],
    vehicleIsHighPriority: false,
    issuesLoaded: false,
  }
  issueIdTableStore = new TableStore();
  issueTableStore = new TableStore();
  columns = [
    {
      key: 'priority',
      title: 'Priority',
      render: (issue) => this.renderPrioritySelector(issue)
    },
    {
      key: 'source',
      title: 'Source',
      render: item => <p>{item.source}</p>
    },
    {
      key: 'issueName',
      title: 'Issue Name',
      render: item => <p>{item.getName()}</p>
    },
    {
      key: 'applyTo',
      title: 'Apply Change to:',
      render: issueItem => this.renderApplyChangeTo(issueItem)
    },
    /*{
      key: 'applyToMake',
      title: 'Apply to Same Make',
      render: (issueItem) => {
        return this.renderApplyToMake(issueItem);
      }
    },
    {
      key: 'applyToMakeModel',
      title: 'Apply to Same Make, Model',
      render: (issueItem) => {
        return this.renderApplyToMakeModel(issueItem);
      }
    },
    {
      key: 'applyToMakeModelYear',
      title: 'Apply to Same Make, Model, Year',
      render: (issueItem) => {
        return this.renderApplyToMakeModelYear(issueItem);
      }
    },
    {
      key: 'applyToThisVehicle',
      title: 'Apply to this vehicle',
      render: (issueItem) => {
        return this.renderApplyToThisVehicle(issueItem);
      }
    },
    {
      key: 'applyToEntireFleet',
      title: 'Apply Change to Entire Fleet',
      render: (issueItem) => {
        return this.renderApplyChangeToEntireFleet(issueItem);
      }
    },*/
  ];

  handleVehicleIsHighPriority = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        vehicleIsHighPriority: !prevState.vehicleIsHighPriority
      };
    });
  };

  handleApplyToMake = (issue) => {
    const issueId = issue.id;
    if (this.state.applyToMakeIds.includes(issueId)) {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToMakeIds: prevState.applyToMakeIds.filter(value => value !== issueId)
          };
        }
      );
    } else {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToMakeIds: [...prevState.applyToMakeIds, issueId],
          applyToMakeModelIds: prevState.applyToMakeModelIds.filter(value => value !== issueId),
          applyToMakeModelYearIds: prevState.applyToMakeModelYearIds.filter(value => value !== issueId),
          applyToThisVehicleIds: prevState.applyToThisVehicleIds.filter(value => value !== issueId),
          applyToSimilarIds: prevState.applyToSimilarIds.filter(value => value !== issueId)
        };
      });
    }
  }

  handleApplyToMakeModel = (issue) => {
    const issueId = issue.id;
    if (this.state.applyToMakeModelIds.includes(issueId)) {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToMakeModelIds: prevState.applyToMakeModelIds.filter(value => value !== issueId)
          };
        }
      );
    } else {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToMakeIds: prevState.applyToMakeIds.filter(value => value !== issueId),
          applyToMakeModelIds: [...prevState.applyToMakeModelIds, issueId],
          applyToMakeModelYearIds: prevState.applyToMakeModelYearIds.filter(value => value !== issueId),
          applyToThisVehicleIds: prevState.applyToThisVehicleIds.filter(value => value !== issueId),
          applyToSimilarIds: prevState.applyToSimilarIds.filter(value => value !== issueId)
        };
      });
    }
  }

  handleApplyToMakeModelYear = (issue) => {
    const issueId = issue.id;
    if (this.state.applyToMakeModelYearIds.includes(issueId)) {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToMakeModelYearIds: prevState.applyToMakeModelYearIds.filter(value => value !== issueId)
          };
        }
      );
    } else {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToMakeIds: prevState.applyToMakeIds.filter(value => value !== issueId),
          applyToMakeModelIds: prevState.applyToMakeModelIds.filter(value => value !== issueId),
          applyToMakeModelYearIds: [...prevState.applyToMakeModelYearIds, issueId],
          applyToThisVehicleIds: prevState.applyToThisVehicleIds.filter(value => value !== issueId),
          applyToSimilarIds: prevState.applyToSimilarIds.filter(value => value !== issueId)
        };
      });
    }
  }

    handleApplyToThisVehicle = (issue) => {
    const issueId = issue.id;
    if (this.state.applyToThisVehicleIds.includes(issueId)) {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToThisVehicleIds: prevState.applyToThisVehicleIds.filter(value => value !== issueId)
          };
        }
      );
    } else {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToMakeIds: prevState.applyToMakeIds.filter(value => value !== issueId),
          applyToMakeModelIds: prevState.applyToMakeModelIds.filter(value => value !== issueId),
          applyToMakeModelYearIds: prevState.applyToMakeModelYearIds.filter(value => value !== issueId),
          applyToThisVehicleIds: [...prevState.applyToThisVehicleIds, issueId],
          applyToSimilarIds: prevState.applyToSimilarIds.filter(value => value !== issueId)
        };
      });
    }
  }

  handleApplyChangeToEntireFleet = (issue) => {
    const issueId = issue.id;
    if (this.state.applyToSimilarIds.includes(issueId)) {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToSimilarIds: prevState.applyToSimilarIds.filter(value => value !== issueId)
          };
        }
      );
    } else {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToMakeIds: prevState.applyToMakeIds.filter(value => value !== issueId),
          applyToMakeModelIds: prevState.applyToMakeModelIds.filter(value => value !== issueId),
          applyToMakeModelYearIds: prevState.applyToMakeModelYearIds.filter(value => value !== issueId),
          applyToSimilarIds: [...prevState.applyToSimilarIds, issueId]
        };
      });
    }
  }

  renderApplyChangeTo = (issue) => {
    return <Select style={{width: 170}} defaultValue='nochange' onChange={(e) => this.handleApplyToSelection(e, issue)}>
      <Option key='nochange'>Issue</Option>
      <Option key='id_car'>Vehicle</Option>
      <Option key='m'>Make</Option>
      <Option key='mm'>Make, Model</Option>
      <Option key='mmy'>Make, Model, Year</Option>
      <Option key='fleet'>Entire Fleet</Option>
    </Select>;
  }

  handleApplyToSelection = (event, issue) => {
    const issueId = issue.id;
    if (event === 'nochange'){
      this.setState(prevState => {
        return {
          ...prevState,
          applyToMakeIds: prevState.applyToMakeIds.filter(value => value !== issueId),
          applyToMakeModelIds: prevState.applyToMakeModelIds.filter(value => value !== issueId),
          applyToMakeModelYearIds: prevState.applyToMakeModelYearIds.filter(value => value !== issueId),
          applyToThisVehicleIds: prevState.applyToThisVehicleIds.filter(value => value !== issueId),
          applyToSimilarIds: prevState.applyToSimilarIds.filter(value => value !== issueId)
        };
      });
    } else if (event === 'm' && !this.state.applyToMakeIds.includes(issueId)) {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToMakeIds: [...prevState.applyToMakeIds, issueId],
          applyToMakeModelIds: prevState.applyToMakeModelIds.filter(value => value !== issueId),
          applyToMakeModelYearIds: prevState.applyToMakeModelYearIds.filter(value => value !== issueId),
          applyToThisVehicleIds: prevState.applyToThisVehicleIds.filter(value => value !== issueId),
          applyToSimilarIds: prevState.applyToSimilarIds.filter(value => value !== issueId)
        };
      });
    } else if (event === 'mm' && !this.state.applyToMakeModelIds.includes(issueId)) {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToMakeIds: prevState.applyToMakeIds.filter(value => value !== issueId),
          applyToMakeModelIds: [...prevState.applyToMakeModelIds, issueId],
          applyToMakeModelYearIds: prevState.applyToMakeModelYearIds.filter(value => value !== issueId),
          applyToThisVehicleIds: prevState.applyToThisVehicleIds.filter(value => value !== issueId),
          applyToSimilarIds: prevState.applyToSimilarIds.filter(value => value !== issueId)
        };
      });
    } else if (event === 'mmy' && !this.state.applyToMakeModelYearIds.includes(issueId)) {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToMakeIds: prevState.applyToMakeIds.filter(value => value !== issueId),
          applyToMakeModelIds: prevState.applyToMakeModelIds.filter(value => value !== issueId),
          applyToMakeModelYearIds: [...prevState.applyToMakeModelYearIds, issueId],
          applyToThisVehicleIds: prevState.applyToThisVehicleIds.filter(value => value !== issueId),
          applyToSimilarIds: prevState.applyToSimilarIds.filter(value => value !== issueId)
        };
      });
    } else if (event === 'id_car' && !this.state.applyToThisVehicleIds.includes(issueId)) {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToMakeIds: prevState.applyToMakeIds.filter(value => value !== issueId),
          applyToMakeModelIds: prevState.applyToMakeModelIds.filter(value => value !== issueId),
          applyToMakeModelYearIds: prevState.applyToMakeModelYearIds.filter(value => value !== issueId),
          applyToThisVehicleIds: [...prevState.applyToThisVehicleIds, issueId],
          applyToSimilarIds: prevState.applyToSimilarIds.filter(value => value !== issueId)
        };
      });
    } else if (event === 'fleet' && !this.state.applyToSimilarIds.includes(issueId)) {
      this.setState(prevState => {
        return {
          ...prevState,
          applyToMakeIds: prevState.applyToMakeIds.filter(value => value !== issueId),
          applyToMakeModelIds: prevState.applyToMakeModelIds.filter(value => value !== issueId),
          applyToMakeModelYearIds: prevState.applyToMakeModelYearIds.filter(value => value !== issueId),
          applyToThisVehicleIds: prevState.applyToThisVehicleIds.filter(value => value !== issueId),
          applyToSimilarIds: [...prevState.applyToSimilarIds, issueId]
        };
      });
    }

  }

  renderApplyToMake = (issue) => {
    return <Checkbox onChange={() => this.handleApplyToMake(issue)} checked={this.state.applyToMakeIds.includes(issue.id)}/>;
  }

  renderApplyToMakeModel = (issue) => {
    return <Checkbox onChange={() => this.handleApplyToMakeModel(issue)} checked={this.state.applyToMakeModelIds.includes(issue.id)}/>;
  }

  renderApplyToMakeModelYear = (issue) => {
    return <Checkbox onChange={() => this.handleApplyToMakeModelYear(issue)} checked={this.state.applyToMakeModelYearIds.includes(issue.id)}/>;
  }

  renderApplyToThisVehicle = (issue) => {
    return <Checkbox onChange={() => this.handleApplyToThisVehicle(issue)} checked={this.state.applyToThisVehicleIds.includes(issue.id)} />;
    }

  renderApplyChangeToEntireFleet = (issue) => {
    return <Checkbox onChange={() => this.handleApplyChangeToEntireFleet(issue)} checked={this.state.applyToSimilarIds.includes(issue.id)}/>;
  }

  handlePriorityChange = (issue, priority) => {
    const issueId = issue.id;
    const changeEntry = this.state.prioritiesToUpdate.find(item => item.issueId === issueId);
    if (changeEntry === null){
      changeEntry.push({issueId: issueId, priority: priority});
    } else {
      this.setState({prioritiesToUpdate: [
        ...this.state.prioritiesToUpdate.filter(item => item.issueId !== issueId),
        {issueId: issueId, priority: priority}]
      });
    }
    this.setState(prevState => {
      const issueIndex = prevState.issues.findIndex(item => item.id === issueId);
      const name = prevState.issues[issueIndex].getName();
      prevState.issues[issueIndex] = {...prevState.issues[issueIndex], priority: priority, getName: () => name};
      return prevState;
    });
  }

  renderPrioritySelector = (issue) => {
    return (
      <Select style={{ width: 110 }} placeholder="Priority" value={String(issue.priority)} onChange={(priority) => this.handlePriorityChange(issue, priority)}>
        <Option value="-1" key="-1">
          <Tag color="#c4c3c2">Not Valid</Tag>
        </Option>
        <Option value="1" key="1">
          <Tag color="#dec431">Minor</Tag>
        </Option>
        <Option value="2" key="2">
          <Tag color="#e28931">Major</Tag>
        </Option>
        <Option value="4" key="4">
          <Tag color="#f92d2e">Critical</Tag>
        </Option>
      </Select>
    );
  }

  getMMYConfig = (issueId) =>{
    if (this.state.applyToMakeIds.includes(issueId)){
      return 'm';
    } else if (this.state.applyToMakeModelIds.includes(issueId)){
      return 'mm';
    } else if (this.state.applyToMakeModelYearIds.includes(issueId)){
      return 'mmy';
    } else {
      return 'na';
    }
  }

  onOk = () => {
    const issuesToUpdate = this.state.prioritiesToUpdate;
    const applyToSimilarIds = this.state.applyToSimilarIds;
    if (!this.state.vehicleIsHighPriority){
      Promise.all(
        _.forEach(issuesToUpdate, async (issue) => {
          const issueId = issue.issueId;
          try {
            return await webServiceProvider.put(
              `v1/issues/${issueId}?triggerDriverNotification=false`,
              {
                applyToSimilarIssues: applyToSimilarIds.includes(issueId),
                updateBy: CurrentUserStore.user.id,
                carVin: this.props.carVin,
                issue: {carVin: this.props.carVin, applyToSimilarIssues: applyToSimilarIds.includes(issueId)},
                status: 'new',
                priority: parseInt(issue.priority),
                mmyConfig: issueId => this.getMMYConfig(issueId)
              }
            );
          } catch (e) {
            AppStore.addError('Unable to update issue.');
          }
        })).then(() => AppStore.addSuccess('Updated issues.'))
          .catch((e) => AppStore.addError('Unable to update issues.'));
      Promise.all(
        _.forEach(this.state.nonDisplayIssues, async (issue) => {
          const issueId = issue.id;
          return await webServiceProvider.put(`v1/issues/${issueId}`, {
            status: 'done',
            carVin: this.props.carVin,
            issue: {carVin: this.props.carVin, applyToSimilarIssues: false},
            applyToSimilarIssues: false,
          });
        })
      );
    } else {
      const issueIds = this.state.allIssues.map(item => item.id);
      Promise.all(
        _.forEach(issueIds, async (issueId) => {
          return await webServiceProvider.put(`v1/issues/${issueId}`, {
            status: 'done',
            carVin: this.props.carVin,
            issue: {carVin: this.props.carVin, applyToSimilarIssues: false},
            applyToSimilarIssues: false,
          });
        })
      );
    }
    this.props.closeModal();

  }

  async componentDidMount() {
    await IssueStore.getIssuesForCarId({limit: 20, filter: {status: 'new'}, includeHistory: true, sort: '-priority'}, this.issueIdTableStore, this.props.carId);
    let issueValues = await Promise.all(_.map(this.issueIdTableStore.data['$mobx'].values, async issueId => {
      return await IssueStore.returnIssueById(issueId, ShopStore.currentShop.id);
    }));
    const nonDisplayIssues = issueValues.filter(item => item.source === 'algorithm');
    const allIssues = [...issueValues];
    issueValues = issueValues.filter(item => item.source === 'dtc');
    this.setState({issues: issueValues, nonDisplayIssues: nonDisplayIssues, issuesLoaded: true, allIssues: allIssues});
  }

  onCancelModal = () => {
    this.props.car.resolved = false;
    this.props.onCancel();
  }

  render(){
    const visible = this.props.visible;
    return <>
      {visible ?
      <Modal visible={this.props.visible} onCancel={this.props.onCancel} onOk={this.onOk} width={1000} className='vehicle-resolved-modal' >
        <h1>Help us help you! </h1>
        <p>Reassign issue priorities to help Pitstop understand what's important to you and your team. Changing priority on issue codes will ensure a cleaner dashboard , where we only display the things that matter</p>
        <span><Checkbox style={{marginRight: '5px'}} checked={this.state.vehicleIsHighPriority === false} onChange={this.handleVehicleIsHighPriority} />This vehicle <strong>is not</strong> high priority and issue priorities need to be reassigned</span>
        {!this.state.issuesLoaded ?
          <div style={{height: '300px', width: '300px', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
            <Spin size='large' style={{marginTop: '125px'}} />
          </div> :
            this.state.issues.length > 0 ?
            <Table
              pagination={{pageSize: 5}}
              columns={this.columns}
              dataSource={this.state.issues}
            /> :
              <p style={{marginTop:'30px'}}>No reassignable issues, you can mark them as resolved instead by clicking OK.</p>}
      <span><Checkbox style={{marginRight: '5px'}} checked={this.state.vehicleIsHighPriority} onChange={this.handleVehicleIsHighPriority}/> This vehicle <strong>is</strong> high priority and its issues have been addressed</span>
      </Modal> : null}
    </>;
  }


}

ResolveVehicleModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  carId: PropTypes.number,
  closeModal: PropTypes.func,
  carVin: PropTypes.string,
  car: PropTypes.object,
};

export default ResolveVehicleModal;