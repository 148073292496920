import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Form, Input } from 'antd';

import {
  Azuga,
  ClearPath,
  CommandGPS,
  EagleEye,
  FleetComplete,
  GPSInsight,
  KeepTruckin,
  Linxup,
  NexTraq,
  OneStep,
  Telemax,
  Teletrac,
  Telogis,
  TitanGPS,
  VerizonConnect,
  VerizonNetworkFleet,
  Webfleet,
  Zubie,
} from 'assets/gps_telematic';

import { ImagePicker } from 'shared/ImagePicker';
import PitstopModal from 'shared/PitstopUI/PitstopModal';

import { AppStore, IntegrationStore } from 'stores';
import { ContactLink } from 'v3/components/pitstop';

class OtherForm extends Component {
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <Form>
          <Form.Item label="Input your Telematic solution">
            {getFieldDecorator('otherDevice', {
              rules: [
                {
                  required: true,
                  message: 'Please input Telematic solution!',
                },
              ],
            })(<Input />)}
          </Form.Item>
        </Form>
      </>
    );
  }

  static propTypes = {
    form: PropTypes.object,
  };
}

const WrappedOtherFormModal = Form.create({ name: 'otherForm' })(OtherForm);

class OtherFormModal extends React.Component {
  state = {
    selectedTelematic: null,
    selectedTelematicError: false,
  };

  childCallables = null;

  setChildCallables = callables => {
    this.childCallables = callables;
  };

  getImgName = imgFile => {
    let imgFileArr = _.split(imgFile, '/');

    return _.split(imgFileArr[imgFileArr.length - 1], '.')[0];
  };

  handleCreate = async () => {
    const { afterCreate } = this.props;
    const { form } = this.formRef.props;
    /*const values = await new Promise((resolve, reject) => {
      form.validateFields((err, values) => {
        if (err && !this.state.selectedTelematic) {
          // hack to bypass PitstopModal's behaviour of closing modal after handleCreate() returns;
          setImmediate(() => form.validateFields());
          this.setState({
            selectedTelematicError: true,
          });
          AppStore.addError('Please input or select telematic solutions!');
          return reject(err);
        }
        if (values.otherDevice && this.state.selectedTelematic) {
          AppStore.addError('Please add only one telematic solutions!');
          return reject('Please add only one telematic solutions!');
        }
        CurrentUserStore.updateHubspotContact({
          other_integrations: this.getImgName(
            this.state.selectedTelematic.value
          ),
        });

        return resolve(values);
      });
    });*/

    try {
      this.childCallables.clearImagePick();
      this.setState({
        selectedTelematicError: false,
      });

      form.resetFields();
      afterCreate && afterCreate();
      IntegrationStore.isReloaded = true;
      this.props.next && this.props.next();
    } catch (err) {
      const message = _.get(err, 'response.data.message') || err.message;
      AppStore.addError(`unable to create integration record: '${message}`);
      throw err;
    }
  };

  handleCancel = () => {};

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  getIntegrationList = () => {
    let imageList = [
      Azuga,
      ClearPath,
      CommandGPS,
      EagleEye,
      FleetComplete,
      GPSInsight,
      KeepTruckin,
      Linxup,
      NexTraq,
      OneStep,
      Telemax,
      Teletrac,
      Telogis,
      TitanGPS,
      VerizonConnect,
      VerizonNetworkFleet,
      Webfleet,
      Zubie,
    ];

    let getPickedImage = image => {
      this.setState({
        selectedTelematic: image,
      });
    };

    return (
      <>
        <label
          className="ant-form-item-required"
          style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '12px' }}
        >
          Or Select your Telematic solution
        </label>
        <ImagePicker
          setCallables={this.setChildCallables}
          getPickedImage={getPickedImage}
          imageList={imageList}
        />
        {this.state.selectedTelematicError && (
          <div className="ant-form-explain" style={{ color: '#f5222d' }}>
            Please select Telematic solution!
          </div>
        )}
      </>
    );
  };

  render() {
    const { id } = this.props;

    return (
      <PitstopModal
        id={id}
        title="Add Other Integration Devices"
        okText="Create"
        onCancel={this.handleCancel}
        onOk={this.handleCreate}
        successMessage={'Integration Record Created.'}
        width={1000}
        style={{ top: '2vw' }}
        bodyStyle={{
          padding: 24,
        }}
      >
        <WrappedOtherFormModal wrappedComponentRef={this.saveFormRef} />
        {this.getIntegrationList()}
        <ContactLink />
      </PitstopModal>
    );
  }

  static propTypes = {
    shopId: PropTypes.number,
    afterCreate: PropTypes.func,
    id: PropTypes.string,
    next: PropTypes.func,
  };
}

export default OtherFormModal;
