import React, { PureComponent } from 'react';
import axios from 'axios';
import {
  Steps,
  Input,
  Button,
  Spin,
  Radio,
  Result,
  Icon,
  Select,
  Modal,
} from 'antd';
import AppStore from 'stores/App';
import { webServiceProvider } from 'shared';
import Years from './SelectJSON/years.json';
import Makes from './SelectJSON/Makes.json';
import Models from './SelectJSON/model.json';

const { Step } = Steps;
const { Search } = Input;
const InputGroup = Input.Group;
const { Option } = Select;

const Loadingdivstyle = {
  textAlign: 'center',
  marginTop: '100px',
  padding: '30px 50px,',
  height: '100px',
};

const ACstyle = {
  textAlign: 'center',
  height: '100px',
  width: '100px',
  margin: '10px',
  paddingTop: '10px',
  paddingLeft: '30px',
  paddingRight: '30px',
};

const RadioButtonStyle = {
  textAlign: 'center',
  height: '100px',
  width: '100px',
  margin: '10px',
  paddingTop: '10px',
};

const splitspanstyle = {
  position: 'absolute',
  float: 'right',
};

export class RepairPricing extends PureComponent {
  constructor(props) {
    super(props);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.state = {
      Page: 1,
      showLoading: false,
      Data: null,
      make: '',
      model: '',
      year: null,
      category: null,
      repair: null,
      FirstStepfilled: true,
      SecondStepfilled: true,
      visible: false,
      sentFeedback: false,
    };
  }

  // Proceed to next step
  nextStep = () => {
    this.setState({
      Page: this.state.Page + 1,
    });
  };

  // Go back to prev step
  prevStep = () => {
    this.setState({
      Page: this.state.Page - 1,
    });
  };

  handleMakeChange = (e) => {
    this.setState({
      make: e.target.value,
    });
  };
  handleModelChange = (e) => {
    this.setState({
      model: e.target.value,
    });
  };
  handleYearChange = (e) => {
    this.setState({
      year: e,
    });
  };

  getImgStyle = (value) => {
    if (this.state.category === value) {
      return { filter: 'invert(100%)' };
    } else {
      return { filter: 'invert(0%)' };
    }
  };

  getLoadingMessage = () => {
    let m = Math.random();
    if (m < 0.33) {
      return 'Pitstop: Turning predictions into actionable insights';
    }
    if (m > 0.66) {
      return 'Pitstop cares about your vehicle health! Remember to download our app for the latest and most accurate analysis of your vehicle!';
    } else {
      return 'Running our model on over 70 000 different data sets to predict the repair and price!';
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
      sentFeedback: false,
    });
    this.Search_handler(this.state.repair);
  };

  cancelModal = () => {
    this.setState({
      visible: false,
    });
  };

  getCategoryName = () => {
    if (this.state.category === 'engine') {
      return 'Engine';
    }
    if (this.state.category === 'brakes') {
      return 'Brakes';
    }
    if (this.state.category === 'wheelsaxle') {
      return 'Wheels/Axle';
    }
    if (this.state.category === 'body') {
      return 'Body';
    }
    if (this.state.category === 'transmission') {
      return 'Transmission';
    }
    if (this.state.category === 'electrical') {
      return 'Electrical';
    }
    if (this.state.category === 'exhaust') {
      return 'Exhaust';
    }
    if (this.state.category === 'other') {
      return 'Other';
    }
    if (this.state.category === 'ac') {
      return 'Air Conditioning';
    }
    if (this.state.category === 'fuel') {
      return 'Fuel';
    }
    if (this.state.category === 'oil') {
      return 'Oil';
    }
    if (this.state.category === 'light') {
      return 'Lights';
    }
  };

  getCategoryExample = () => {
    if (this.state.category === 'engine') {
      return 'Oil Filter Change';
    }
    if (this.state.category === 'brakes') {
      return 'Brake Pads Replacement';
    }
    if (this.state.category === 'wheelsaxle') {
      return 'Rotate Tire';
    }
    if (this.state.category === 'body') {
      return 'Door Mirror Replacement';
    }
    if (this.state.category === 'transmission') {
      return 'Transmission Replacement';
    }
    if (this.state.category === 'electrical') {
      return 'Battery Replacement';
    }
    if (this.state.category === 'exhaust') {
      return 'Exhaust Pipe Replacement';
    }
    if (this.state.category === 'other') {
      return 'Key Fob Replacement';
    }
    if (this.state.category === 'ac') {
      return 'AC Recharge';
    }
    if (this.state.category === 'fuel') {
      return 'Fuel Filter Replacement';
    }
    if (this.state.category === 'oil') {
      return 'Engine Oil Pan Replacement';
    }
    if (this.state.category === 'light') {
      return 'Headlight Replacement';
    }
  };

  CheckFill = () => {
    if (this.state.make !== '') {
      if (this.state.model !== '') {
        if (this.state.year !== null) {
          if (this.state.Page === 1 || this.state.Page === 4) {
            this.setState({ FirstStepfilled: false });
          }
          if (this.state.Page === 2) {
            if (this.state.category !== null) {
              this.setState({ SecondStepfilled: false });
            }
          }
        } else {
          this.setState({ FirstStepfilled: true });
        }
      } else {
        this.setState({ FirstStepfilled: true });
      }
    } else {
      this.setState({ FirstStepfilled: true });
    }
  };

  componentDidUpdate = () => {
    this.CheckFill();
  };

  getmodels = () => {
    let currentmodels = [];
    if (this.state.make !== '') {
      Models.forEach((element) => {
        if (element.make === this.state.make.toUpperCase()) {
          if (currentmodels.includes(element.model) === false) {
            currentmodels.push(element.model);
          }
        }
      });
    } else {
      currentmodels.push('Please select a make first');
    }
    return currentmodels;
  };

  sendFeedback = (value) => {
    this.setState({ sentFeedback: true });
    try {
      webServiceProvider.getMany('v1/labs/repair/feedback', {
        make: String(this.state.make),
        model: String(this.state.model),
        year: String(this.state.year),
        category: String(this.state.category),
        repair: String(this.state.repair),
        result: JSON.stringify(this.state.Data),
        feedback: value,
      });
    } catch (e) {
      console.error(e);
      AppStore.addError('unable to send feedback');
    }
  };

  get_results = async () => {
    try {
      const postRequest = {method: 'post',
        url: 'https://9c6rpit7te.execute-api.us-east-2.amazonaws.com/default/RepairPricing',
        data: {
          make: String(this.state.make),
        model: String(this.state.model),
        year: String(this.state.year),
        category: String(this.state.category),
        repair: String(this.state.repair),
      }};
      const results = await axios(postRequest);
      const prediction = results.data.prediction;
      return prediction;
    } catch (e) {
      console.error(e);
      AppStore.addError('unable to get search results');
    }
  };

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  countDown() {
    let secondsToGo = 2;
    const modal = Modal.info({
      content: (
        <div style={{ width: '100%%', height: '100%%' }}>
          <img
            src={require('./Icons/fireworks.gif')}
            style={{ width: '100%', height: '100%' }}
            alt='Fireworks Icon'
          />
        </div>
      ),
      width: '100%',
      height: '100%',
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content: (
          <div style={{ width: '100%%', height: '100%%' }}>
            <img
              src={require('./Icons/fireworks.gif')}
              style={{ width: '100%', height: '100%' }}
              alt='Fireworks Icon'
            />
          </div>
        ),
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  }

  Search_handler = async (value) => {
    await this.setState({ repair: value });
    this.setState({ showLoading: true });
    this.setState({ Page: 4 });
    let results = await this.get_results();
    if (results === undefined) {
      this.setState({ Data: { pricefound: false } });
      this.setState({ showLoading: false });
    } else {
      this.setState({ Data: results });
      if (this.getRandomInt(0, 1000000) === 999000) {
        await this.countDown();
        this.setState({ showLoading: false });
      } else {
        this.setState({ showLoading: false });
      }
    }
  };

  render() {
    switch (this.state.Page) {
      case 1:
        return (
          <div style={{ minHeight: '500px' }}>
            <Steps
              direction="vertical"
              current={0}
              onChange={this.onChange}
              style={{
                width: '40%',
                float: 'left',
              }}
            >
              <Step title="Type in car details" />
              <Step title="Select a category" />
              <Step title="Search for your repair" />
            </Steps>
            <span style={splitspanstyle}>
              <InputGroup compact style={{ width: 300 }}>
                <Select
                  value={this.state.make || 'Make'}
                  showSearch
                  style={{ width: 300 }}
                  placeholder="Make"
                  optionFilterProp="children"
                  onChange={(e) => this.setState({ make: e })}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Makes.map((make) => {
                    return (
                      <Option value={make.value} key={make.value}>
                        {make.name}
                      </Option>
                    );
                  })}
                </Select>
                <Select
                  showSearch
                  value={this.state.model || 'Model'}
                  style={{ width: 300 }}
                  placeholder="Model"
                  onChange={(e) => this.setState({ model: e })}
                >
                  {this.getmodels().map((model) => {
                    return (
                      <Option value={model} key={model}>
                        {model}
                      </Option>
                    );
                  })}
                </Select>
                <Select
                  showSearch
                  value={this.state.year || 'Year'}
                  style={{ width: 300 }}
                  placeholder="Year"
                  onChange={(e) => this.setState({ year: e })}
                >
                  {Years.map((year) => {
                    return (
                      <Option value={year.value} key={year.value}>
                        {year.name}
                      </Option>
                    );
                  })}
                </Select>
              </InputGroup>
              <div
                style={{
                  margin: '40px',
                  float: 'right',
                  position: 'absolute',
                }}
              >
                <Button
                  onClick={this.nextStep}
                  disabled={this.state.FirstStepfilled}
                  type="primary"
                >
                  Next
                </Button>
              </div>
            </span>
          </div>
        );
      case 2:
        return (
          <div style={{ minHeight: '500px' }}>
            <Steps
              direction="vertical"
              current={1}
              onChange={this.onChange}
              style={{ width: '40%', float: 'left' }}
            >
              <Step title="Type in car details" />
              <Step title="Select a category" />
              <Step title="Search for your repair" />
            </Steps>
            <span style={splitspanstyle}>
              <Radio.Group
                style={{ width: '80%' }}
                defaultValue={this.state.category}
                size="large"
                buttonStyle="solid"
                onChange={(value) =>
                  this.setState({ category: value.target.value })
                }
              >
                <Radio.Button value="engine" style={RadioButtonStyle}>
                  <img
                    style={this.getImgStyle('engine')}
                    src={require('./Icons/engine.png')}
                    alt="Icon of Engine"
                    width="40px"
                    height="40px"
                  />
                  Engine
                </Radio.Button>
                <Radio.Button value="brakes" style={RadioButtonStyle}>
                  <img
                    style={this.getImgStyle('brakes')}
                    src={require('./Icons/brakes.svg')}
                    alt="Icon of Brakes"
                    width="40px"
                    height="40px"
                  />
                  Brakes
                </Radio.Button>
                <Radio.Button
                  value="wheelsaxle"
                  style={{
                    textAlign: 'center',
                    height: '100px',
                    width: '100px',
                    margin: '10px',
                    paddingTop: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                >
                  <img
                    style={this.getImgStyle('wheelsaxle')}
                    src={require('./Icons/wheelsaxle.png')}
                    alt="Icon of wheels and axles"
                    width="40px"
                    height="40px"
                  />
                  Wheels/Axle
                </Radio.Button>
                <Radio.Button value="body" style={RadioButtonStyle}>
                  <img
                    style={this.getImgStyle('body')}
                    src={require('./Icons/body.png')}
                    alt="Icon of Engine"
                    width="40px"
                    height="40px"
                  />
                  Body
                </Radio.Button>
                <Radio.Button
                  value="transmission"
                  style={{
                    textAlign: 'center',
                    height: '100px',
                    width: '100px',
                    margin: '10px',
                    paddingTop: '10px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                >
                  <img
                    style={this.getImgStyle('transmission')}
                    src={require('./Icons/transmission.png')}
                    alt="Icon of transmission"
                    width="40px"
                    height="40px"
                  />
                  Transmission
                </Radio.Button>
                <Radio.Button value="electrical" style={RadioButtonStyle}>
                  <img
                    style={this.getImgStyle('electrical')}
                    src={require('./Icons/electrical.png')}
                    alt="Icon of transmission"
                    width="40px"
                    height="40px"
                  />
                  Electrical
                </Radio.Button>
                <Radio.Button value="exhaust" style={RadioButtonStyle}>
                  <img
                    style={this.getImgStyle('exhaust')}
                    src={require('./Icons/exhaust.png')}
                    alt="Icon of Engine"
                    width="40px"
                    height="40px"
                  />
                  Exhaust
                </Radio.Button>
                <Radio.Button value="ac" style={ACstyle}>
                  <img
                    style={this.getImgStyle('ac')}
                    src={require('./Icons/ac.png')}
                    alt="Icon of AC"
                    width="40px"
                    height="40px"
                  />
                  A/C
                </Radio.Button>
                <Radio.Button value="fuel" style={ACstyle}>
                  <img
                    style={this.getImgStyle('fuel')}
                    src={require('./Icons/fuel.png')}
                    alt="Icon of fuel"
                    width="40px"
                    height="40px"
                  />
                  Fuel
                </Radio.Button>
                <Radio.Button value="oil" style={ACstyle}>
                  <img
                    style={this.getImgStyle('oil')}
                    src={require('./Icons/oil.png')}
                    alt="Icon of oil"
                    width="40px"
                    height="40px"
                  />
                  Oil
                </Radio.Button>
                <Radio.Button value="light" style={ACstyle}>
                  <img
                    style={this.getImgStyle('light')}
                    src={require('./Icons/light.png')}
                    alt="Icon of light"
                    width="40px"
                    height="40px"
                  />
                  Lights
                </Radio.Button>
                <Radio.Button value="other" style={RadioButtonStyle}>
                  <img
                    style={this.getImgStyle('other')}
                    src={require('./Icons/other.png')}
                    alt="Icon of wrench"
                    width="40px"
                    height="40px"
                  />
                  Other
                </Radio.Button>
              </Radio.Group>
              <div
                style={{
                  margin: '40px',
                  float: 'right',
                  position: 'absolute',
                }}
              >
                <Button onClick={this.prevStep}>Previous</Button>
                <Button
                  onClick={this.nextStep}
                  disabled={this.state.SecondStepfilled}
                  type="primary"
                >
                  Next
                </Button>
              </div>
            </span>
          </div>
          //['Engine', 'Brakes', 'Wheels/Axle', 'Body', 'Transmission', 'Electrical', 'Exhaust', 'Other']
        );
      case 3:
        return (
          <div style={{ minHeight: '500px' }}>
            <Steps
              direction="vertical"
              current={2}
              style={{ width: '40%', float: 'left' }}
            >
              <Step title="Type in car details" />
              <Step title="Select a category" />
              <Step title="Search for your repair" />
            </Steps>
            <span
              style={{
                position: 'absolute',
                float: 'right',
                width: '45%',
              }}
            >
              <h2>Enter Repair for category: {this.getCategoryName()}</h2>
              <Search
                placeholder={`For example: '${this.getCategoryExample()}'`}
                enterButton="Search"
                size="large"
                onSearch={(value) => this.Search_handler(value)}
                disabled={this.state.showLoading}
              />
              <div style={{ margin: '40px', position: 'absolute' }}>
                <Button onClick={this.prevStep}>Previous</Button>
              </div>
            </span>
          </div>
        );
      case 4:
        return this.state.showLoading ? (
          <div style={Loadingdivstyle}>
            <Spin size="large" tip={this.getLoadingMessage()} />
          </div>
        ) : (
          <div style={{ minHeight: '500px' }}>
            {this.state.Data.pricefound ? (
              <div>
                <h1>Repair: {this.state.Data.repair}</h1>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <p style={{ fontSize: 16 }}>Price ranges from:</p>
                    <h4>{`$${this.state.Data.min} - $${this.state.Data.max} US`}</h4>
                  </div>
                  <img
                    src={require(`./Icons/${this.state.category}.png`)}
                    width="80px"
                    height="80px"
                    alt={`icon of ${this.state.category}`}
                    style={{ marginLeft: '40px' }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '300px',
                  }}
                >
                  <div>
                    <Button
                      onClick={() =>
                        this.setState({ Page: 2, sentFeedback: false })
                      }
                      type="primary"
                      style={{ float: 'left', marginTop: '20px' }}
                    >
                      New Search
                    </Button>
                    <Button
                      onClick={() => this.showModal()}
                      style={{ float: 'left', marginTop: '20px' }}
                    >
                      Change Vehicle
                    </Button>
                  </div>
                  <div style={{ marginTop: '30px' }}>
                    <p style={{ fontsize: '16' }}>
                      Have you found what you were looking for?
                    </p>
                    <Button
                      onClick={() => this.sendFeedback('thumbsUp')}
                      style={{ color: 'green' }}
                      disabled={this.state.sentFeedback}
                    >
                      <Icon type="like" style={{ fontSize: 20 }} />
                    </Button>
                    <Button
                      onClick={() => this.sendFeedback('thumbsDown')}
                      style={{ color: 'red', marginLeft: '12px' }}
                      disabled={this.state.sentFeedback}
                    >
                      <Icon type="dislike" style={{ fontSize: 20 }} />
                    </Button>
                  </div>
                </div>
                <Modal
                  title="Change Your car"
                  visible={this.state.visible}
                  onOk={this.hideModal}
                  onCancel={this.cancelModal}
                  okText="Search"
                  cancelText="Cancel"
                  okButtonProps={{
                    disabled: this.state.FirstStepfilled,
                  }}
                >
                  <InputGroup compact>
                    <Select
                      value={this.state.make || 'Make'}
                      showSearch
                      style={{ width: 300 }}
                      placeholder="Make"
                      optionFilterProp="children"
                      onChange={(e) => this.setState({ make: e })}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {Makes.map((make) => {
                        return (
                          <Option value={make.value} key={make.value}>
                            {make.name}
                          </Option>
                        );
                      })}
                    </Select>
                    <Select
                      showSearch
                      value={this.state.model || 'Model'}
                      style={{ width: 300 }}
                      placeholder="Model"
                      onChange={(e) => this.setState({ model: e })}
                    >
                      {this.getmodels().map((model) => {
                        return (
                          <Option value={model} key={model}>
                            {model}
                          </Option>
                        );
                      })}
                    </Select>
                    <Select
                      value={this.state.year || 'Year'}
                      style={{ width: 300 }}
                      placeholder="Year"
                      onChange={(e) => this.setState({ year: e })}
                    >
                      {Years.map((year) => {
                        return (
                          <Option value={year.value} key={year.value}>
                            {year.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </InputGroup>
                </Modal>
              </div>
            ) : (
              <div>
                <Result
                  status="warning"
                  title="No Results were found. Please change your search and try again."
                />
                <Button
                  onClick={() => this.setState({ Page: 2 })}
                  type="primary"
                >
                  New Search
                </Button>
              </div>
            )}
          </div>
        );
      default:
        return (
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <Button type="primary" onClick={this.setState({ Page: 1 })}>
                Back to search
              </Button>
            }
          />
        );
    }
  }
}

export default RepairPricing;
