import Smartcar from '@smartcar/auth';

export class SmartcarAuth {
  constructor(options) {
    this.smartcar = new Smartcar({
      clientId: process.env.REACT_APP_SMARTCAR_CLIENT_ID,
      redirectUri: process.env.REACT_APP_SMARTCAR_REDIRECT_URI,
      scope: [
        'required:read_vin',
        'required:read_odometer',
        'read_battery',
        'read_charge',
        'read_fuel',
        'read_location',
        'read_tires',
        'read_vehicle_info'
      ],
      testMode: false,
      onComplete: this.onComplete,
      ...options
    });

    this.authorize = this.authorize.bind(this);
    this.onComplete = this.onComplete.bind(this);
  }

  authorize() {
    this.smartcar.openDialog({ forcePrompt: true });
  }

  onComplete(err, code, status) {
    console.log(err, code, status);
  }
}

// class App extends Component {
//   constructor(props) {
//     super(props);
//
//     this.state = {
//       vehicle: {}
//     };
//   }
//
//   onComplete(err, code, status) {
//     return axios
//       .get(`${process.env.REACT_APP_SERVER}/exchange?code=${code}`)
//       .then(_ => {
//         return axios.get(`${process.env.REACT_APP_SERVER}/vehicle`);
//       })
//       .then(res => {
//         this.setState({ vehicle: res.data });
//       });
//   }
//
//   authorize() {}
//
//   render() {
//     return Object.keys(this.state.vehicle).length !== 0 ? (
//       <Vehicle info={this.state.vehicle} />
//     ) : (
//       <Connect onClick={this.authorize} />
//     );
//   }
// }
//
// export default App;
