import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';

import { PitstopModal } from 'shared';
import { observer } from 'mobx-react';

class SuccessModal extends Component {
  render() {
    let { modalId, children } = this.props;

    return (
      <PitstopModal
        style={{ top: 150 }}
        id={modalId}
        title={null}
        width="450px"
        onOk={() => {
          if (this.props.onConfirm) {
            this.props.onConfirm();
          }
        }}
        okText="Confirm"
        onCancel={() => {}}
        footer={null}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <img
              src={require('assets/congratulation.png')}
              alt="success"
              width="100%"
            />
          </Col>

          <Col span={24}>{children}</Col>
        </Row>
      </PitstopModal>
    );
  }
}

SuccessModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  children: PropTypes.any,
  onConfirm: PropTypes.func,
};

export default observer(SuccessModal);