export const general = [
  {
    RONo: 833210,
    Loc: 179,
    RepairDate: '2/22/2021',
    Reason: 'Routine',
    Odometer: 97517,
    System: '999 - TOTAL VEHICLE',
    Assembly: 'Pickup and Delivery',
    Task: 'INSPECT Pickup and Delivery-Maintenance'
  },
  {
    RONo: 1134265,
    Loc: 179,
    RepairDate: '2/22/2021',
    Reason: 'Driver Report                                               ',
    Odometer: 97517,
    System: '002 - Cab & Sheet Metal',
    Assembly: 'Cab Or Front Door Pa',
    Task: 'REPLACE Seal - Channel, Door'
  },
  {
    RONo: 1134071,
    Loc: 179,
    RepairDate: '2/19/2021',
    Reason: 'Warranty                                                    ',
    Odometer: 97517,
    System: '002 - Cab & Sheet Metal',
    Assembly: 'Pickup and Delivery',
    Task: 'INSPECT Pickup and Delivery-Maintenance'
  },
  {
    RONo: 1134071,
    Loc: 179,
    RepairDate: '2/19/2021',
    Reason: 'Warranty                                                    ',
    Odometer: 97517,
    System: '071 - Body',
    Assembly: 'Floor Assembly',
    Task: 'REPAIR Sill - Door, Floor'
  },
  {
    RONo: 1134071,
    Loc: 179,
    RepairDate: '2/19/2021',
    Reason: 'Warranty                                                    ',
    Odometer: 97517,
    System: '071 - Body',
    Assembly: 'Floor Assembly',
    Task: 'REPAIR TREAD PLATE'
  }
];

export const cgp = [
  {
    'RO Created Date': '2/15/2021',
    'RO Complete Date': '1/7/2021',
    'RO Status': 'Paid',
    'RO ID': 25979017,
    'Vehicle': 'LACU38',
    'Driver': 'Nestor Mora',
    'Maint Cost Code': '#22',
    'Year': 2006,
    'Make': 'Chevrolet',
    'Model': 'Silverado 2500HD',
    'Odometer': 1,
    'RO Item ID': 108907307,
    'Item': 'Tow Service',
    'Service Desc': 'Towing/Roadside Assitance',
    'Qty': 1,
    '$/Unit': 568.9,
    'Parts': 0,
    'Labor': 0,
    'Subtotal': 568.9,
    'Savings': 0,
    'Total Price': 568.9,
    'Shop Name': 'SwoopMe Inc'
  },
  {
    'RO Created Date': '2/8/2021',
    'RO Complete Date': '2/8/2021',
    'RO Status': 'Paid',
    'RO ID': 25883498,
    'Vehicle': 'LACU38',
    'Driver': 'Nestor Mora',
    'Maint Cost Code': '#22',
    'Year': 2006,
    'Make': 'Chevrolet',
    'Model': 'Silverado 2500HD',
    'Odometer': 268374,
    'RO Item ID': -2,
    'Item': 'United States Tax',
    'Service Desc': '',
    'Qty': 1,
    '$/Unit': 4.64,
    'Parts': 4.64,
    'Labor': 0,
    'Subtotal': 4.64,
    'Savings': 0,
    'Total Price': 4.64,
    'Shop Name': 'JIFFY LUBE # 3004'
  },
  {
    'RO Created Date': '2/8/2021',
    'RO Complete Date': '2/8/2021',
    'RO Status': 'Paid',
    'RO ID': '25979017',
    'Vehicle': 'LACU38',
    'Driver': 'Nestor Mora',
    'Maint Cost Code': '#22',
    'Year': 2006,
    'Make': 'Chevrolet',
    'Model': 'Silverado 2500HD',
    'Odometer': 268374,
    'RO Item ID': 108377811,
    'Item': 'Oil Filter Engine',
    'Service Desc': 'Engine Air & Filter Replacement',
    'Qty': 1,
    '$/Unit': 0,
    'Parts': 0,
    'Labor': 0,
    'Subtotal': 0,
    'Savings': 0,
    'Total Price': 0,
    'Shop Name': 'JIFFY LUBE # 3004'
  },
  {
    'RO Created Date': '2/8/2021',
    'RO Complete Date': '2/8/2021',
    'RO Status': 'Paid',
    'RO ID': '25979017',
    'Vehicle': 'LACU38',
    'Driver': 'Nestor Mora',
    'Maint Cost Code': '#22',
    'Year': 2006,
    'Make': 'Chevrolet',
    'Model': 'Silverado 2500HD',
    'Odometer': 268374,
    'RO Item ID': 108377812,
    'Item': 'Semi Synthetic Engine Oil',
    'Service Desc': 'Engine Oil & Filter Replacement',
    'Qty': 1,
    '$/Unit': 8.99,
    'Parts': 8.99,
    'Labor': 0,
    'Subtotal': 8.99,
    'Savings': 0,
    'Total Price': 8.99,
    'Shop Name': 'JIFFY LUBE # 3004'
  },
  {
    'RO Created Date': '2/8/2021',
    'RO Complete Date': '2/8/2021',
    'RO Status': 'Paid',
    'RO ID': '25979017',
    'Vehicle': 'LACU38',
    'Driver': 'Nestor Mora',
    'Maint Cost Code': '#22',
    'Year': 2006,
    'Make': 'Chevrolet',
    'Model': 'Silverado 2500HD',
    'Odometer': 268374,
    'RO Item ID': 108377813,
    'Item': 'Semi-Synthetic Lube Oil Filter',
    'Service Desc': 'Engine Air Filter Replacement',
    'Qty': 0,
    '$/Unit': 0,
    'Parts': 0,
    'Labor': 53.99,
    'Subtotal': 53.99,
    'Savings': 0,
    'Total Price': 53.99,
    'Shop Name': 'JIFFY LUBE # 3004'
  }
];
