import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import { Result, Button, Alert, Table } from 'antd';

import AppStore from 'stores/App.js';
import CurrentUserStore from 'stores/CurrentUserStore';
import { Loading } from 'components';
import SimpleMap from './map';

import { webServiceProvider } from 'shared';
import { calcMileage } from 'helpers/unitCalculations';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const mapGraphTitles = (graphName) => {
  const graphTitles = {
    co2_vs_load: {
      title: 'CO2 vs Load',
      xAxis: `CO2 [g/${CurrentUserStore.user.displayedOdometer}]`,
      yAxis: 'Load [kg]',
    },
    co2_vs_speed: {
      title: 'CO2 vs Speed',
      xAxis: `CO2 [g/${CurrentUserStore.user.displayedOdometer}]`,
      yAxis: `Speed [${CurrentUserStore.user.displayedOdometer}/h]`,
    },
    fuel_vs_load: {
      title: 'Fuel vs Load',
      xAxis: `Fuel [l/${CurrentUserStore.user.displayedOdometer}]`,
      yAxis: 'Load [kg]',
    },
    fuel_vs_speed: {
      title: 'Fuel vs Speed',
      xAxis: `Fuel [l/${CurrentUserStore.user.displayedOdometer}]`,
      yAxis: `Speed [${CurrentUserStore.user.displayedOdometer}/h]`,
    },
    fuel_vs_time_seq: {
      title: 'Fuel vs Time Sequence',
      xAxis: `Fuel [l/${CurrentUserStore.user.displayedOdometer}]`,
      yAxis: 'Time Sequence',
    },
    co2_vs_pct_load_idle: {
      title: 'C02 vs PCT Load Idle',
      xAxis: `CO2 [g/${CurrentUserStore.user.displayedOdometer}]`,
      yAxis: 'PCT Load Idle',
    },
    fuel_vs_pct_load_idle: {
      title: 'Fuel vs PCT Load Idle',
      xAxis: `Fuel [l/${CurrentUserStore.user.displayedOdometer}]`,
      yAxis: 'PCT Load Idle',
    },
  };
  return graphTitles[graphName];
};

const tableStats = (statName) => {
  const tableTiltles = {
    mean_rpm: 'Mean RPM',
    mean_load: 'Mean Load [kg]',
    trip_time: 'Last time driven',
    mean_speed: `Mean Speed [${CurrentUserStore.user.displayedOdometer}/h]`,
    tidle_by_rpm: 'Idle time by RPM',
    tidle_by_speed: 'Idle time by Speed',
    integrated_load: 'Integrated Load',
    fuel_consumption: 'Fuel Consumption [l]',
    mean_sensor_speed: 'Mean Sensor Speed',
    integrated_load_idle: 'Integrated Load Idle',
  };
  return tableTiltles[statName];
};

function findLineByLeastSquares(values) {
  var sum_x = 0;
  var sum_y = 0;
  var sum_xy = 0;
  var sum_xx = 0;
  var count = 0;

  // We'll use those variables for faster read/write access.
  var x = 0;
  var y = 0;
  var values_length = values.length;

  if (values_length === 0) {
    return [];
  }

  // Calculate the sum for each of the parts necessary.
  for (let v = 0; v < values_length; v++) {
    x = values[v][0];
    y = values[v][1];
    sum_x += x;
    sum_y += y;
    sum_xx += x * x;
    sum_xy += x * y;
    count++;
  }

  // Calculate m and b for the formular:
  var m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x);
  var b = sum_y / count - (m * sum_x) / count;

  // calculate x and y values
  var result_values = [];

  values = values.sort();

  for (let v = 0; v < values_length; v++) {
    x = values[v][0];
    y = x * m + b;
    result_values.push([x, y]);
  }

  return result_values;
}

const DetailArea = styled.div`
  width: 70%;
`;

const MapArea = styled.div`
  width: 30%;
  min-height: 630px;
`;

const MatchPage = styled.div`
  display: flex;
  flex-direction: row;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

const columns = [
  {
    title: 'Statistic',
    dataIndex: 'stat',
    key: 'stat',
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  },
];

class TripMatchingPage extends PureComponent {
  state = { pending: false, data: null, error: false, dataSource: undefined };

  async componentDidMount() {
    await this.getData();
  }

  getData = async () => {
    let bucketHash = this.props.history.location.search;
    bucketHash = bucketHash.substring(1, bucketHash.length);

    try {
      this.setState({
        pending: true,
      });
      let data = await webServiceProvider.get(
        `v1/trips/match?bucketHash=${bucketHash}`
      );
      this.setState({ data: data.data }, () => this.get_dataSource());
    } catch (e) {
      this.setState({ error: true });
      AppStore.addError(e);
    } finally {
      this.setState({
        pending: false,
      });
    }
  };

  get_dataSource = () => {
    let dataSource = [];
    if (this.state.data === null) {
      return;
    }
    if (!this.state.data.current_agg_stats) {
      return;
    }
    if (this.state.data.current_agg_stats.length === 0) {
      return;
    }
    Object.entries(this.state.data.current_agg_stats).forEach(
      ([key, value]) => {
        let stat = tableStats(key);
        dataSource.push({
          key: key,
          stat: stat,
          value:
            key === 'trip_time'
              ? moment(value * 1000).format('LLL')
              : value.toFixed(2),
        });
      }
    );
    this.setState({ dataSource: dataSource });
  };

  render() {
    let { pending, error, data } = this.state;

    if (pending) return <Loading />;

    if (error) {
      return (
        <Result
          status="warning"
          title="There was a problem loading the data for your Trips."
          extra={
            <Button type="primary" onClick={this.props.history.goBack}>
              Go Back
            </Button>
          }
        />
      );
    }

    if (data === null) {
      return (
        <>
          <div style={{ width: '30%' }}>
            <Button
              icon={'left'}
              size={'large'}
              onClick={this.props.history.goBack}
            >
              Back to Trip Overview
            </Button>
          </div>
          <Result title="There is no graph data available." status="warning" />
        </>
      );
    }

    let { npool, vin, last_trip_time, waypoints, all_graphs } = data;

    return (
      <>
        <Header>
          <div style={{ width: '30%' }}>
            <Button
              icon={'left'}
              size={'large'}
              onClick={this.props.history.goBack}
            >
              Back to Trip Overview
            </Button>
          </div>
          <div style={{ width: '70%' }}>
            <h3 style={{ textAlign: 'center' }}>
              {`Trip was driven ${npool} times on car ${vin}`}
            </h3>
            <h6 style={{ textAlign: 'center' }}>
              {`Last driven: ${moment(last_trip_time).format('LLL')}`}
            </h6>
          </div>
        </Header>
        <br />
        <MatchPage>
          <MapArea>
            <SimpleMap
              data={{
                location_polyline: waypoints.data,
              }}
              sorting={false}
              zoom={11}
              snapToRoad={false}
            ></SimpleMap>
            <br></br>
            {this.state.dataSource ? (
              <Table
                columns={columns}
                dataSource={this.state.dataSource}
                pagination={false}
              ></Table>
            ) : (
              <Alert
                message="There are no statistics available."
                type="error"
              />
            )}
          </MapArea>
          <DetailArea>
            {all_graphs ? (
              _.map(all_graphs, (value, key) => {
                let titles = mapGraphTitles(key);
                let keys = Object.keys(value.graph);
                let scatterData = [];
                if (
                  titles.title === 'Fuel vs Speed' ||
                  titles.title === 'CO2 vs Speed'
                ) {
                  for (let i = 0; i < value.graph[keys[0]].length; i++) {
                    scatterData.push([
                      calcMileage(value.graph[keys[0]][i]),
                      calcMileage(value.graph[keys[1]][i]),
                    ]);
                  }
                } else if (
                  titles.title === 'CO2 vs Load' ||
                  titles.title === 'Fuel vs Time Sequence' ||
                  titles.title === 'C02 vs PCT Load Idle' ||
                  titles.title === 'Fuel vs PCT Load Idle'
                ) {
                  for (let i = 0; i < value.graph[keys[0]].length; i++) {
                    scatterData.push([
                      calcMileage(value.graph[keys[0]][i]),
                      value.graph[keys[1]][i],
                    ]);
                  }
                } else {
                  for (let i = 0; i < value.graph[keys[0]].length; i++) {
                    scatterData.push([
                      value.graph[keys[0]][i],
                      value.graph[keys[1]][i],
                    ]);
                  }
                }
                let options = {
                  title: {
                    text: titles
                      ? titles.title
                      : String(key).replace(/_/g, ' '),
                  },
                  credits: { enabled: false },
                  legend: { enabled: false },
                  tooltip: {
                    split: true,
                    formatter: function () {
                      return [
                        this.point.series.name +
                          ': ' +
                          `${
                            String(keys[1]) === 'fuel_vs_time_seq'
                              ? moment(this.y * 1000).format('LLL')
                              : this.point.y.toLocaleString()
                          }`,
                        `${
                          titles
                            ? titles.xAxis
                            : String(keys[0]).replace(/_/g, ' ')
                        }` +
                          ': ' +
                          this.x.toLocaleString(),
                      ];
                    },
                  },
                  xAxis: [
                    {
                      title: {
                        text: titles
                          ? titles.xAxis
                          : String(keys[0]).replace(/_/g, ' '),
                      },
                    },
                  ],
                  yAxis: [
                    {
                      title: {
                        text: titles
                          ? titles.yAxis
                          : String(keys[1]).replace(/_/g, ' '),
                      },
                      type: 'linear',
                      labels:
                        String(keys[1]) === 'fuel_vs_time_seq'
                          ? {
                              formatter: function () {
                                return moment(this.value * 1000).format('L');
                              },
                            }
                          : {
                              formatter: function () {
                                return this.value;
                              },
                            },
                    },
                  ],
                  series: [
                    {
                      type: 'line',
                      name: 'Regression Line',
                      data: findLineByLeastSquares(scatterData),
                      marker: {
                        enabled: false,
                      },
                      states: {
                        hover: {
                          lineWidth: 0,
                        },
                      },
                      enableMouseTracking: false,
                    },
                    {
                      type: 'scatter',
                      name: titles
                        ? titles.yAxis
                        : String(keys[1]).replace(/_/g, ' '),
                      data: scatterData,
                      marker: {
                        radius: 4,
                      },
                    },
                  ],
                };
                return (
                  <React.Fragment key={key}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                    />
                    <br></br>
                  </React.Fragment>
                );
              })
            ) : (
              <Result
                title="There is no graph data available."
                status="warning"
              />
            )}
          </DetailArea>
        </MatchPage>
      </>
    );
  }
}

TripMatchingPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(TripMatchingPage);
