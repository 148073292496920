import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';

import { Icon } from 'antd';

// Import React Table
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import { ReportsDataStore } from 'stores';

const NoIssuesSection = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  i {
    font-size: 36px;
    margin-right: 12px;
  }
`;

const ReactTableStyled = styled(ReactTable)`
  text-align: center;
  maragin-top: 20px;
`;

class HardBrakingTable extends Component {
  render() {
    if (_.isEmpty(ReportsDataStore.alarmReport.data)) {
      return (
        <NoIssuesSection>
          <Icon type="check-circle" theme="twoTone" />
          <p>Your fleet has no Braking alarms!</p>
        </NoIssuesSection>
      );
    }

    return (
      <div style={{ marginTop: '20px' }}>
        {/* <RaisedButton style={{"fontFamily": "Lato", "marginBottom": 30}} secondary label="Create New Campaign" onClick={() => { self.props.history.push('/campaign/create'); }} /> */}
        <br />
        <ReactTableStyled
          columns={[
            {
              Header: 'Vehicle',
              id: 'car.make_model_year',
              accessor: (data) => {
                return (
                  <div>
                    <Link to={'/car/' + data.id_car}>
                      <span>
                        {data.car_make} {data.car_model} {data.car_year}
                      </span>
                    </Link>
                  </div>
                );
              },
            },
            {
              Header: 'Total Events',
              id: 'braking_event_count',
              accessor: 'braking_event_count',
            },
          ]}
          sortable={true}
          defaultSorted={[
            {
              id: 'braking_event_count',
              desc: true,
            },
          ]}
          // manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          data={ReportsDataStore.alarmReport.data}
          pages={Math.round(ReportsDataStore.alarmReport.data.length / 5)} // Display the total number of pages
          loading={ReportsDataStore.alarmReport.pending} // Display the loading overlay when we need it
          defaultPageSize={5}
          className="-highlight smallTable"
        />
      </div>
    );
  }
}

export default observer(HardBrakingTable);
