import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spin, Button, notification, Tag, Typography } from 'antd';
import { PitstopModal } from 'shared';
import styled from 'styled-components';
import { AppStore } from 'stores';

import WorkOrderLeanForm from 'components/WorkOrder/WOLeanDetailsForm';
import { ShopStore } from 'stores';

import { getPriorityTagColor, getPriorityTagDescription } from 'stores/Classes/IssueObject';

const { Text } = Typography;

const StyledModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      .ant-modal-title {
        color: #ffffff;
      }
    }

    .footer-text {
      font-weight: bold;
      padding-right: 3vw;
    }
  }
`;

const Container = styled.div`
  padding-right: 2vw;
`;

const IssueLine = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const CREATE_WORK_ORDER_MODAL_ID = 'CREATE_WORK_ORDER_MODAL_ID';

const CreateWorkOrderModal = ({
  modalId,
  title,
  isLoading = false,
  onSave = () => { },
  loading = false,
  issues = [],
  type
}) => {
  const [formRef, setFormRef] = useState([]);

  if(type === "defects") {
    return (
      <StyledModal
        id={modalId}
        title={title}
        width={850}
        okText="Confirm"
        footer={(<>
          <Text class="footer-text">You can add more details, such as description, cost, etc., to this work order once it's created</Text>
          <Button
            key="back"
            onClick={() => {
              AppStore.openModals.set(CREATE_WORK_ORDER_MODAL_ID, false);
            }}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              formRef.props.form.validateFields((err, values) => {
                if (err) {
                  let friendlyMsg = '';
                  const errorKeys = Object.keys(err);
                  if (
                    errorKeys &&
                    errorKeys[0] &&
                    err[errorKeys[0]] &&
                    err[errorKeys[0]].errors &&
                    err[errorKeys[0]].errors.length &&
                    err[errorKeys[0]].errors[0].message
                  ) {
                    friendlyMsg = err[errorKeys[0]].errors[0].message;
                  } else {
                    friendlyMsg = 'Error on saving Work Order';
                  }
                  return notification.error({
                    message: 'Oops!',
                    key: 'orderSaveError',
                    description: <Container>{friendlyMsg}</Container>,
                  });
                }
                onSave({
                  ...values
                });
              });
            }}
          >
            Create
          </Button>
        </>)}
      >
        {isLoading ?
          (
            <Spin size="large" />
          )
          : (
            <>
              <Row>
                <Col span={24}>
                  <WorkOrderLeanForm
                    shopId={ShopStore.currentShop.id}
                    childRef={setFormRef}
                    workOrder={{}}
                    type="defects"
                  />
                </Col>
              </Row>
              {/* Show issues list if issues is not empty */}
              {issues.length > 0 && (
                <Row style={{ marginTop: 15 }}>
                  <Col span={24}>
                    <Text strong style={{ marginBottom: 10, display: 'block' }}>Defects that will be added to this Work Order</Text>
                    {issues.map((issue) => (
                      <IssueLine key={issue.id}>
                        <Text>{issue.defectType}: {issue.comment}</Text>
                      </IssueLine>
                    ))}
                  </Col>
                </Row>
              )}
            </>
          )
        }
      </StyledModal>
    );
  } else {
    return (
      <StyledModal
        id={modalId}
        title={title}
        width={850}
        okText="Confirm"
        footer={(<>
          <Button
            key="back"
            onClick={() => {
              AppStore.openModals.set(CREATE_WORK_ORDER_MODAL_ID, false);
            }}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              formRef.props.form.validateFields((err, values) => {
                if (err) {
                  let friendlyMsg = '';
                  const errorKeys = Object.keys(err);
                  if (
                    errorKeys &&
                    errorKeys[0] &&
                    err[errorKeys[0]] &&
                    err[errorKeys[0]].errors &&
                    err[errorKeys[0]].errors.length &&
                    err[errorKeys[0]].errors[0].message
                  ) {
                    friendlyMsg = err[errorKeys[0]].errors[0].message;
                  } else {
                    friendlyMsg = 'Error on saving Work Order';
                  }
                  return notification.error({
                    message: 'Oops!',
                    key: 'orderSaveError',
                    description: <Container>{friendlyMsg}</Container>,
                  });
                }
                onSave({
                  ...values,
                  woServiceTask: formRef.state.currentServiceTask,
                  woDescription: formRef.state.currentDescription,
                });
              });
            }}
          >
            Save
          </Button>
        </>)}
      >
        {isLoading ?
          (
            <Spin size="large" />
          )
          : (
            <>
              <Row>
                <Col span={24}>
                  <WorkOrderLeanForm
                    shopId={ShopStore.currentShop.id}
                    childRef={setFormRef}
                    workOrder={{}}
                    type="issues"
                  />
                </Col>
              </Row>
              {/* Show issues list if issues is not empty */}
              {issues.length > 0 && (
                <Row style={{ marginTop: 15 }}>
                  <Col span={24}>
                    <Text strong style={{ marginBottom: 10, display: 'block' }}>Issues that will be added to this Work Order</Text>
                    {issues.map((issue) => (
                      <IssueLine key={issue.id}>
                        <Tag color={getPriorityTagColor(issue.priority)}>{getPriorityTagDescription(issue.priority)}</Tag>
                        <Text>{issue.name}</Text>
                      </IssueLine>
                    ))}
                  </Col>
                </Row>
              )}
            </>
          )
        }
      </StyledModal>
    );
  }


};

CreateWorkOrderModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func,
  issues: PropTypes.array,
  loading: PropTypes.bool,
  type: PropTypes.string
};

export default CreateWorkOrderModal;
