import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Divider, Icon, Empty } from 'antd';

import { PitstopModal } from 'shared';
import { showDemoVinOrRealVin } from 'shared/utils';

import { CarStore, CurrentUserStore, ShopStore, IssueStore } from 'stores';

import ContentRow from 'components/ContentRow.js';

const AppointmentDetails = styled.div`
  h3 {
    margin-top: 16px;
  }
  i {
    margin-right: 8px;
  }
  hr {
    margin-bottom: 16px !important;
  }
`;

class AppointmentProfileModal extends Component {
  async componentDidMount() {
    await this.getCarData();
  }

  async componentDidUpdate() {
    await this.getCarData();
  }

  getCarData = async () => {
    let { appointmentDetails } = this.props;

    if (appointmentDetails && appointmentDetails.carId) {
      if (!CarStore.data.has(Number(appointmentDetails.carId))) {
        await CarStore.getCarById(appointmentDetails.carId);
      }
    }
  };

  render() {
    let { appointmentDetails } = this.props;
    let content;

    if (!appointmentDetails) {
      content = <Empty />;
    } else {
      let car =
        CarStore.data.get(Number(appointmentDetails.carId)) ||
        appointmentDetails.car;
      let user = ShopStore.data.get(Number(appointmentDetails.shopId));

      content = (
        <AppointmentDetails>
          <h3>
            <Icon type="user" /> Created By
          </h3>
          <ContentRow
            name="Name"
            value={`${user.name}`}
            show={!_.isNil(`${user.name}`)}
          />
          <ContentRow
            name="Email"
            value={user.email}
            show={!_.isNil(user.email)}
          />
          <ContentRow
            name="Phone"
            value={user.phoneNumber}
            show={!_.isNil(user.phoneNumber)}
          />
          <h3>
            <Icon type="calendar" /> Appointment Details
          </h3>
          <ContentRow
            name="Appointment Date"
            value={`${moment
              .tz(
                appointmentDetails.appointmentDate,
                CurrentUserStore.user.settings.timezone
              )
              .format('ll')} ${moment
              .tz(
                appointmentDetails.appointmentDate,
                CurrentUserStore.user.settings.timezone
              )
              .format('LT')} - ${moment
              .tz(
                appointmentDetails.appointmentEndTime,
                CurrentUserStore.user.settings.timezone
              )
              .format('LT')}`}
            show={!_.isNil(appointmentDetails.appointmentDate)}
          />
          <ContentRow
            name="Status"
            value={appointmentDetails.state}
            show={!_.isNil(appointmentDetails.state)}
          />
          <ContentRow
            name="Comments"
            value={appointmentDetails.comments}
            show={!_.isNil(appointmentDetails.comments)}
          />
          <ContentRow
            name="User Notified"
            value={appointmentDetails.isNotified ? 'Yes' : 'No'}
            show={!_.isNil(appointmentDetails.isNotified)}
          />
          <h3>
            <Icon type="car" /> Vehicle Details
          </h3>
          <ContentRow
            name="Unit ID"
            value={<Link to={`car/${car.id}`}>{car.carName}</Link>}
            show={!_.isNil(car.carName)}
          />
          <ContentRow
            name="Make/Model/Year"
            value={car.mmy}
            show={!_.isNil(car.mmy)}
          />
          <ContentRow name="VIN" value={showDemoVinOrRealVin(car.vin)} show={!_.isNil(car.vin)} />
          <ContentRow name="Trim" value={car.trim} show={!_.isNil(car.trim)} />
          <ContentRow
            name="Engine"
            value={car.engine}
            show={!_.isNil(car.engine)}
          />
          <Divider />

          {appointmentDetails.source !== 'routine' && (
            <>
              <h3>
                <Icon type="warning" /> Issues
              </h3>
              <ul>
                {car.issues.active.data.map((el) => {
                  const issue = IssueStore.data.get(el);
                  if (issue.issueDetail) {
                    return (
                      <li
                        key={el}
                      >{`${issue.issueDetail.action} - ${issue.issueDetail.item}`}</li>
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
            </>
          )}
        </AppointmentDetails>
      );
    }

    return (
      <PitstopModal
        id={this.props.id}
        title="Appointment Details"
        width={800}
        onOk={() => {}}
      >
        {content}
      </PitstopModal>
    );
  }
}

AppointmentProfileModal.propTypes = {
  appointmentDetails: PropTypes.object,
  id: PropTypes.string.isRequired,
  carId: PropTypes.number,
};

export default observer(AppointmentProfileModal);
