import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { Col, Icon, Input, Row } from 'antd';

import { PitstopModal } from 'shared';

import { Card, Button, Slider, ContactLink } from 'v3/components/pitstop';

import { AppStore, CurrentUserStore } from 'stores';

const Container = styled.div`
  height: 100%;
  text-align: center;
  color: #000000;
  margin: auto;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3rem;
  }

  h3 {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  label {
    font-weight: 600;
    display: block;
    text-align: left;
  }

  input {
    margin: 1.2vh 0vh;
  }
`;

const IconCheckCircle = styled(Icon)`
  width: 64px;
  height: 64px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledSlider = styled(Slider)`
  background: ${(props) => (props.isActive ? '#02103d' : '#bebeb6')};
`;

class SamsaraIntegrationForm extends Component {
  state = {
    samsaraAPI: null,
    step: 0,
  };

  modalId = 'samsara-integration-form';

  render() {
    let { step } = this.state;

    return (
      <PitstopModal
        id={this.modalId}
        style={{ top: '3vw' }}
        bodyStyle={{
          padding: 0,
        }}
        width={1030}
        footer={null}
      >
        <Container>
          <Card>
            <Row
              type="flex"
              justify="center"
              style={{ height: '100%', padding: '50px 0px' }}
            >
              {step === 0 && (
                <>
                  <Col span={16}>
                    <Row>
                      <h2>Connect To Samsara</h2>
                    </Row>
                    <Row style={{ margin: '8vw 0vw' }}>
                      <h3>
                        Before you start please ensure you are using Google
                        Chrome or Firefox browser
                      </h3>

                      <p>This is to ensure a hiccup-free integration</p>
                    </Row>
                  </Col>

                  <Col span={16}>
                    <Button
                      style={{ minWidth: '10rem' }}
                      onClick={() => this.setState({ step: 1 })}
                    >
                      Get Started
                    </Button>
                  </Col>
                </>
              )}

              {step !== 0 && (
                <>
                  <Col
                    span={4}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {step > 1 && step <= 4 && (
                      <div
                        onClick={() => {
                          let { step } = this.state;

                          if (step === 1) return;

                          step--;

                          this.setState({
                            step,
                          });
                        }}
                      >
                        <img
                          src={require('v3/assets/back_arrow.png')}
                          alt="back_arrow"
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                          }}
                        />
                      </div>
                    )}
                  </Col>

                  {step !== 0 && (
                    <Col span={16}>
                      {step < 5 && (
                        <>
                          <Row>
                            <h2>Connect To Samsara</h2>
                          </Row>

                          <Row
                            type="flex"
                            justify="center"
                            style={{ margin: '2vw 0vw' }}
                          >
                            <Col span={16}>
                              <label>Enter the API key</label>
                              <Input
                                name="apiKey"
                                placeholder="API Key"
                                onChange={(e) =>
                                  this.setState({
                                    samsaraAPI: e.target.value,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </>
                      )}

                      {step === 1 && (
                        <>
                          <Row>
                            <img
                              src={require('v3/assets/samsara-api-key.png')}
                              alt="apiKey"
                              width="100%"
                            />

                            <h3 style={{ margin: '2vw 0vw' }}>
                              On your Samsara Dashboard, navigate to Settings{' '}
                              {'>'} API Tokens
                            </h3>
                          </Row>
                        </>
                      )}

                      {step === 2 && (
                        <>
                          <Row>
                            <h3 style={{ margin: '2vw 0vw' }}>
                              Click + Add an API Token to create a new token and
                              name it “pitstop integration”
                            </h3>
                          </Row>
                        </>
                      )}

                      {step === 3 && (
                        <>
                          <Row>
                            <h3 style={{ margin: '2vw 0vw' }}>
                              Select a Permission Scope for your API token
                            </h3>
                          </Row>
                        </>
                      )}

                      {step === 4 && (
                        <>
                          <Row>
                            <h3 style={{ margin: '2vw 0vw' }}>
                              Select Save once you've inputted a Name and
                              Permission Scope
                            </h3>
                          </Row>
                        </>
                      )}
                    </Col>
                  )}

                  <Col
                    span={4}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {step >= 1 && step < 4 && (
                      <div
                        onClick={() => {
                          let { step } = this.state;

                          if (step === 4) return;

                          step++;

                          this.setState({
                            step,
                          });
                        }}
                      >
                        <img
                          src={require('v3/assets/forward_arrow.png')}
                          alt="forward_arrow"
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                          }}
                        />
                      </div>
                    )}
                  </Col>

                  {step < 5 && (
                    <Col span={16}>
                      <Row>
                        <StyledSlider isActive={step === 1} />
                        <StyledSlider isActive={step === 2} />
                        <StyledSlider isActive={step === 3} />
                        <StyledSlider isActive={step === 4} />
                      </Row>

                      <Row>
                        <Button
                          style={{ minWidth: '10rem', marginTop: '5vh' }}
                          onClick={async () => {
                            if (_.isNil(this.state.samsaraAPI)) {
                              AppStore.addWarning('Please enter API key!');
                              return;
                            }

                            try {
                              await CurrentUserStore.updateHubspotContact({
                                samsara_api: this.state.samsaraAPI,
                              });

                              this.setState({ step: 5 });
                            } catch (err) {
                              AppStore.addError(
                                'Sorry! Error in Samsara integration!'
                              );
                            }
                          }}
                        >
                          Connect
                        </Button>
                      </Row>
                    </Col>
                  )}

                  {step === 5 && (
                    <>
                      <Col
                        span={16}
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <IconCheckCircle
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                        />

                        <h3 style={{ marginTop: '1vh' }}>
                          Your Samsara fleet is connected
                        </h3>

                        <Button
                          style={{ minWidth: '10rem' }}
                          onClick={() => {
                            AppStore.openModals.set(this.modalId, false);
                            AppStore.openModals.set(
                              'ADD_INTEGRATION_MODAL_ID',
                              false
                            );

                            AppStore.openModals.set('PROFILE_FORM_ID', true);
                          }}
                        >
                          Complete Profile
                        </Button>
                      </Col>
                    </>
                  )}
                </>
              )}

              <Col span={16}>
                <ContactLink />
              </Col>
            </Row>
          </Card>
        </Container>
      </PitstopModal>
    );
  }
}

export default SamsaraIntegrationForm;
