import React from 'react';
import { Redirect } from 'react-router-dom';
import ShopStore from 'stores/ShopStore';
import { Card, Col, Row } from 'antd';
import fix from 'assets/fix.png';
import speedy from 'assets/speedy.png';
import novus from 'assets/novus.png';
import CurrentUserStore from 'stores/CurrentUserStore';
import { UserRoles } from 'stores/Classes/UserObject';

const { Meta } = Card;

const CreateAppointmentSplashPage = () => {
  // If Fix Auto Shop
  if (
    parseInt(ShopStore.currentShop.id) === 210 ||
    (CurrentUserStore.user.role === UserRoles.customer &&
      parseInt(CurrentUserStore.user.shopId) === 210)
  ) {
    return (
      <React.Fragment>
        <h3>Appointment Booking</h3>
        <div style={{ marginTop: 50 }}>
          <Row gutter={16}>
            <Col span={8}>
              <a
                href="https://fixauto.com/ca-on/en/schedule-an-appointment"
                target="_blank"
                rel="noopener noreferrer"
                alt="Fix Auto Link"
              >
                <Card
                  hoverable
                  style={{ width: 240 }}
                  cover={
                    <img
                      style={{
                        height: 149,
                        width: 100,
                        padding: 15,
                        marginLeft: 28
                      }}
                      alt="fix"
                      src={fix}
                    />
                  }
                >
                  <Meta title="Collision Repair" description="Fix Auto" />
                </Card>
              </a>
            </Col>

            <Col span={8}>
              <a
                href="https://www.speedy.com/ca-qc/en/request-an-appointment"
                target="_blank"
                rel="noopener noreferrer"
                alt="Speedy Link"
              >
                <Card
                  hoverable
                  style={{ width: 240 }}
                  cover={
                    <img alt="speedy" style={{ padding: 50 }} src={speedy} />
                  }
                >
                  <Meta title="Auto Service" description="Speedy" />
                </Card>
              </a>
            </Col>
            <Col span={8}>
              <a
                href="https://novusglass.com/ca/en/locations/"
                rel="noopener noreferrer"
                target="_blank"
                alt="Novus Glass"
              >
                <Card
                  hoverable
                  style={{ width: 240 }}
                  cover={
                    <img style={{ padding: 29 }} alt="novus" src={novus} />
                  }
                >
                  <Meta title="Glass Repair " description="Novus" />
                </Card>
              </a>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  } else {
    return <Redirect to="/appointment/create/steps" />;
  }
};

export default CreateAppointmentSplashPage;
