import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import PropTypes from 'prop-types';

import { Col, Row, Typography, InputNumber, Icon, Select } from 'antd';

const { Text } = Typography;
const { Option } = Select;

class WorkOrderLineItemLaborItemDesktop extends Component {
  static propTypes = {
    childRef: PropTypes.func,
    item: PropTypes.object,
    onUpdateTotal: PropTypes.func,
    onRemoveItem: PropTypes.func,
    shopUsers: PropTypes.array,
    onUpdateLocalItem: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
  };

  localItem = {
    id_labour: '',
    hours: 0,
    cost: 0,
    labourTechnician: {
      full_name: '',
      source: '',
    },
  };

  get localShopUsers() {
    return this.props.shopUsers;
  }

  get shopUsersOptions() {
    return this.localShopUsers.map((user, index) => (
      <Option key={index} value={user.id}>
        {user.name}
      </Option>
    ));
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.localItem = this.props.item;
  }

  get totalAmount() {
    let num = (this.localItem?.hours || 0) * (this.localItem?.cost || 0);
    return num.toFixed(2);
  }

  get laborValue() {
    const labourTechnician = this.localItem?.labourTechnician;
    const shopUsers = this.localShopUsers;
    const isSelectedLaborAShopUser = shopUsers.find(
      (user) => user.id === this.localItem.id_labour
    );
    if (labourTechnician?.source && labourTechnician?.source !== 'pitstop') {
      return labourTechnician?.full_name || 'N/A';
    } else if (labourTechnician?.id_technician && !isSelectedLaborAShopUser) {
      return parseInt(labourTechnician.id_technician);
    }
    return this.localItem.id_labour;
  }

  onChangeAmount = (newVal, fieldName) => {
    this.localItem[fieldName] = newVal;

    if (this.props.onUpdateLocalItem) {
      this.props.onUpdateLocalItem(this.localItem);
    }

    if (this.props.onUpdateTotal) {
      this.props.onUpdateTotal(this.totalAmount);
    }
  };

  onChangeSelectField = (value, fieldName) => {
    this.localItem[fieldName] = value;

    if (this.props.onUpdateLocalItem) {
      this.props.onUpdateLocalItem(this.localItem);
    }
  };

  onRemoveItem = (event, itemKey) => {
    if (this.props.onRemoveItem) {
      this.props.onRemoveItem(event, itemKey);
    }
    if (this.props.onUpdateTotal) {
      this.props.onUpdateTotal(this.totalAmount);
    }
  };

  render() {
    const filterOption = (input, option) => {
      return (
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    };

    return (
      <Row style={{ margin: 0 }} gutter={[8, 8]}>
        <Col key={'name'} span={9}>
          <Text strong>Select Labor</Text>
          <Select
            value={this.laborValue}
            onChange={(value) => this.onChangeSelectField(value, 'id_labour')}
            placeholder="Select a Labor"
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            disabled={this.props.isDisabled}
          >
            {this.shopUsersOptions}
          </Select>
        </Col>
        <Col key={'hours'} span={5}>
          <Text strong>Hours</Text>
          <InputNumber
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            value={this.localItem.hours}
            onChange={(val) => this.onChangeAmount(val, 'hours')}
            disabled={this.props.isDisabled}
          />
        </Col>
        <Col key={'cost'} span={5}>
          <Text strong>Rate</Text>
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            value={this.localItem.cost}
            onChange={(val) => this.onChangeAmount(val, 'cost')}
            disabled={this.props.isDisabled}
          />
        </Col>
        <Col key={'laborAmount'} span={4}>
          <Text strong>Amount:</Text>
          <InputNumber
            value={this.totalAmount}
            disabled={true}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
          />
        </Col>
        <Col
          key={'deleteBtn'}
          span={1}
          style={{
            display: 'flex',
            height: '57px',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '25px',
          }}
        >
          <Icon
            type="delete"
            onClick={(event) => {
              event.stopPropagation();
              this.onRemoveItem(event, this.props.item.key);
            }}
          />
        </Col>
      </Row>
    );
  }
}

decorate(WorkOrderLineItemLaborItemDesktop, {
  localItem: observable,
  totalAmount: computed,
  localShopUsers: computed,
});

export default observer(WorkOrderLineItemLaborItemDesktop);
