import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Col, Input, Row, Select } from 'antd';

import timeZones from 'v3/utils/timeZones.json';
import { Button } from 'v3/components/pitstop';
import { PitstopModal } from 'shared';
import { AppStore, CarStore, CurrentUserStore } from 'stores';

const Container = styled.div`
  text-align: center;

  h3 {
    font-weight: 600;
    font-size: 1.6rem;
  }

  label {
    display: block;
    text-align: left;
    width: 100%;
    font-weight: 600;
    margin: 10px 0px;
  }

  .ant-select {
    width: 100%;
  }

  input,
  .ant-select,
  .ant-select-selection--single {
    height: 48px;
  }

  .ant-select-selection__rendered {
    margin-top: 10px;
  }
`;

const BoxSelection = styled.div`
  border-radius: 5px;
  border: 1px solid #000000;
  height: 48px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.selected && '#02103d'};
  color: ${(props) => props.selected && '#ffffff'};
`;

class ProfileForm extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  state = {
    companyName: null,
    companyPhone: null,
    timezone: null,
    fleetSize: '50 < 100',
    odometer: 'km',
    fuel: 'Litre',
  };

  modalId = 'PROFILE_FORM_ID';

  render() {
    const { fleetSize, odometer, fuel } = this.state;

    return (
      <PitstopModal id={this.modalId} width={1030} footer={null}>
        <Container>
          <Row type="flex" justify="center" style={{ padding: '50px 0px' }}>
            <Col span={16}>
              <Row style={{ margin: '15px 0px' }}>
                <h3>Complete Your Profile</h3>
              </Row>

              <Row gutter={[16, 48]}>
                <Col span={12}>
                  <label>Company Name</label>
                  <Input
                    name="companyName"
                    placeholder="ABC Fleet"
                    onChange={(e) =>
                      this.setState({
                        companyName: e.target.value,
                      })
                    }
                  />
                </Col>

                <Col span={12}>
                  <label>Timezone</label>
                  <Select
                    showSearch
                    placeholder="Timezone"
                    defaultValue="America/Toronto"
                    onChange={(val) =>
                      this.setState({
                        timezone: val,
                      })
                    }
                  >
                    {Object.keys(timeZones).map((key) => (
                      <Select.Option key={key} value={key}>
                        {timeZones[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>

                <Col span={12}>
                  <label>Company Phone Number</label>
                  <Input
                    name="companyPhone"
                    placeholder="+000-000-0000"
                    onChange={(e) =>
                      this.setState({
                        companyPhone: e.target.value,
                      })
                    }
                  />
                </Col>

                <Col span={12}>
                  <label>Fleet Size</label>
                  <Row gutter={[16, 16]}>
                    <Col span={6}>
                      <BoxSelection
                        selected={fleetSize === '0 < 20'}
                        onClick={() =>
                          this.setState({
                            fleetSize: '0 < 20',
                          })
                        }
                      >
                        {'0 < 20'}
                      </BoxSelection>
                    </Col>

                    <Col span={6}>
                      <BoxSelection
                        selected={fleetSize === '20 < 50'}
                        onClick={() =>
                          this.setState({
                            fleetSize: '20 < 50',
                          })
                        }
                      >
                        {'20 < 50'}
                      </BoxSelection>
                    </Col>

                    <Col span={6}>
                      <BoxSelection
                        selected={fleetSize === '50 < 100'}
                        onClick={() =>
                          this.setState({
                            fleetSize: '50 < 100',
                          })
                        }
                      >
                        {'50 < 100'}
                      </BoxSelection>
                    </Col>

                    <Col span={6}>
                      <BoxSelection
                        selected={fleetSize === '> 100'}
                        onClick={() =>
                          this.setState({
                            fleetSize: '> 100',
                          })
                        }
                      >
                        {'> 100'}
                      </BoxSelection>
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <label>Odometer Unit</label>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <BoxSelection
                        selected={odometer === 'km'}
                        onClick={() =>
                          this.setState({
                            odometer: 'km',
                          })
                        }
                      >
                        Kilometer (km)
                      </BoxSelection>
                    </Col>

                    <Col span={12}>
                      <BoxSelection
                        selected={odometer === 'mi'}
                        onClick={() =>
                          this.setState({
                            odometer: 'mi',
                          })
                        }
                      >
                        Miles (mi)
                      </BoxSelection>
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <label>Fuel Unit</label>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <BoxSelection
                        selected={fuel === 'Litre'}
                        onClick={() =>
                          this.setState({
                            fuel: 'Litre',
                          })
                        }
                      >
                        Litre
                      </BoxSelection>
                    </Col>

                    <Col span={12}>
                      <BoxSelection
                        selected={fuel === 'Gallon'}
                        onClick={() =>
                          this.setState({
                            fuel: 'Gallon',
                          })
                        }
                      >
                        Gallon
                      </BoxSelection>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ marginBottom: 20 }}>
                <Button
                  onClick={async () => {
                    try {
                      let {
                        companyName,
                        companyPhone,
                        timezone,
                        odometer,
                        fuel,
                      } = this.state;

                      AppStore.addLoading('Updating your profile...');

                      await CurrentUserStore.updateHubspotContact({
                        company: companyName,
                        phone: companyPhone,
                      });

                      await CurrentUserStore.user.updateSettings({
                        timezone,
                        odometer,
                        fuelUnit: fuel,
                      });

                      AppStore.openModals.set(this.modalId, false);

                      CarStore.isReloaded = true;
                    } catch (err) {
                      AppStore.addError('Error in updating your profile!');
                    } finally {
                      AppStore.removeLoading('Updating your profile...');
                    }
                  }}
                >
                  <Link to="/report">Connect to Dashboard</Link>
                </Button>
              </Row>
            </Col>
          </Row>
        </Container>
      </PitstopModal>
    );
  }
}

export default ProfileForm;
