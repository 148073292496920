import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toJS } from 'mobx';
import moment from 'moment';
import styled from 'styled-components';

import { Icon, Table } from 'antd';

import FireflyUtilization from 'containers/FireflyUtilization';

import ShopStore from 'stores/ShopStore';
import { PitstopButton } from 'shared';
import { showDemoVinOrRealVin } from 'shared/utils';

const VehicleContainer = styled.div`
  p {
    margin-bottom: 0px;
  }
`;

const Annotation = styled.div`
  margin: 5px 0px;
  display: flex;
  font-style: italic;

  i,
  p {
    font-size: 14px;
    margin: auto 0px;
  }

  p {
    margin-left: 5px;
  }
`;

const LatestTrip = () => {
  const [latestTrips, setLatestTrips] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const columns = [
    {
      title: 'Vehicle ID',
      key: 'make',
      render: (record) => {
        let mmy = `${record.car.make || ''} ${record.car.model || ''} ${record
          .car.year || ''}`;

        return (
          <VehicleContainer>
            <b>{record.car.name || mmy}</b>
            <p>{record.car.name && mmy}</p>
            <p>{showDemoVinOrRealVin(record.car.vin)}</p>
          </VehicleContainer>
        );
      },
    },
    {
      title: 'Start Time',
      key: 'startTime',
      render: (record) => (
        <p>{moment.unix(record.trip.rtc_time_start).format('LLL')}</p>
      ),
    },
    {
      title: 'End Time',
      key: 'endTime',
      render: (record) => (
        <p>{moment.unix(record.trip.rtc_time_end).format('LLL')}</p>
      ),
    },
    {
      title: '',
      key: 'viewPID',
      render: (record) => (
        <PitstopButton
          onClick={() =>
            history.push(`/car/${record.car.id}/sharetrip/${record.trip.id}`)
          }
        >
          View PID Data
        </PitstopButton>
      ),
    },
  ];

  useEffect(() => {
    async function getLatestTrips() {
      setLoading(true);
      await ShopStore.getShopLatestTrips(ShopStore.currentShop.id, 10);
      setLoading(false);
      setLatestTrips(toJS(ShopStore.latestTrips.data));
    }

    getLatestTrips();
  }, []);

  return (
    <>
      <div>
        <b>Latest Trips Report</b>
        <Annotation>
          <Icon style={{ fontSize: 14, marginRight: 8 }} type="info-circle" />
          <p>Click on each record to see trip details!</p>
        </Annotation>
      </div>
      <Table
        id="latestTripsTable"
        columns={columns}
        dataSource={latestTrips}
        loading={loading}
        pagination={{ pageSize: 5 }}
        rowKey={(record) => record.trip.id}
      />
      <br />
      <FireflyUtilization />
    </>
  );
};

export default LatestTrip;
