import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  List,
  Tag,
  Drawer,
  Modal,
  Popover,
  Tooltip,
  Icon,
} from 'antd';
import { observer, Observer } from 'mobx-react';
import styled from 'styled-components';
import { calcMileage, calcFuel } from 'helpers/unitCalculations';
import CurrentUserStore from 'stores/CurrentUserStore';
import moment from 'moment';
import SingleTrip from 'containers/CarProfile/CarTripsView/map/SingleTrip';
import { withRouter } from 'react-router-dom';

const TripItem = styled(List.Item)`
  && {
    padding: 20px;
    display: flex;
    background: ${(props) => (props.selected ? '#f0f8ff' : 'inherit')};
  }
  && > div {
    flex: 1;
  }
  && > div > div {
    flex: 1;
  }

  && p {
    margin-bottom: 0px;
  }
  && .fuel {
    margin-bottom: 8px;
  }
`;

class TripCompairison extends Component {
  state = {
    selectedTrips: [],
    startDate: null,
    endDate: null,
    page: 0,
    pageSize: 5,
    loading: false,
  };

  async componentDidUpdate(prevProps) {
    const { car, page, startDate, endDate } = this.props;

    if (prevProps.visible !== this.props.visible) {
      this.setState({ page, startDate, endDate }, async () => {
        if (!car.trips.loaded) {
          await this.getTrips();
        }
      });
    }
  }

  componentWillUnmount() {
    this.setState({ selectedTrips: [] });
  }

  changePagination = (page, pageSize) => {
    this.setState(
      {
        page: page,
        pageSize: pageSize,
        loading: true,
      },
      async () => {
        await this.getTrips();
      }
    );
  };

  onChange = (e) => {
    let selectedTrips = this.state.selectedTrips;
    if (selectedTrips.includes(e.tripId)) {
      selectedTrips = selectedTrips.filter(function(value, index, arr) {
        return value !== e.tripId;
      });
    } else {
      selectedTrips.push(e.tripId);
    }
    this.setState({ selectedTrips: selectedTrips }, () => this.checkLength());
  };

  checkLength = () => {
    if (this.state.selectedTrips.length > 5) {
      this.warning();
    }
  };
  getTrips = async () => {
    const { car } = this.props;
    const { startDate, endDate, page, pageSize } = this.state;

    await car.getTrips(
      startDate.unix(),
      endDate.unix(),
      pageSize * (page - 1),
      pageSize,
      true
    );

    this.setState({ loading: false });
  };

  onClose = () => {
    if (this.props.visible === true) {
      // this.setState({ selectedTrips: [] });
      this.props.onCloseCompareTrips(this.state.page);
    }
  };

  warning = () => {
    Modal.warning({
      title: 'You can only select up to 5 trips!',
      zIndex: 13000,
    });
  };

  render() {
    const { car } = this.props;
    return (
      <Drawer
        visible={this.props.visible}
        onClose={this.onClose}
        destroyOnClose
      >
        <div>
          <h4>Select up to 5 trips to compare.</h4>
          <Button
            onClick={() =>
              this.props.history.push(
                `/car/${car.id}/trips/compare/${this.state.selectedTrips}`
              )
            }
            type="primary"
            block
            disabled={
              this.state.selectedTrips.length < 2 ||
              this.state.selectedTrips.length > 5
                ? true
                : false
            }
            style={{ marginBottom: '12px' }}
          >
            Compare{' '}
            {this.state.selectedTrips.length > 1
              ? this.state.selectedTrips.length
              : ''}{' '}
            trips
          </Button>

          <List
            style={{ width: '100%' }}
            pagination={{
              disabled: this.state.loading,
              position: 'top',
              pageSize: this.state.pageSize,
              current: this.state.page,
              onChange: this.changePagination,
              total: car.trips.count,
              simple: true,
            }}
            dataSource={car.trips.data}
            loading={this.state.loading}
            renderItem={(item) => (
              <Observer>
                {() => {
                  const tripMileage =
                    item.mileageAccum && parseFloat(item.mileageAccum) > 0
                      ? `${calcMileage(
                          parseFloat(item.mileageAccum)
                        ).toReadable()} ${
                          CurrentUserStore.user.displayedOdometer
                        }`
                      : 'Unavailable';
                  var fuelConsumption =
                    item.fuelConsumptionAccum &&
                    parseFloat(item.fuelConsumptionAccum) > 0
                      ? `${parseFloat(
                          calcFuel(item.fuelConsumptionAccum, car.scanner.scannerId)
                        ).toFixed(2)} ${
                          CurrentUserStore.user.settings.fuelUnit
                        }`
                      : 'Unavailable';

                  return (
                    <TripItem
                      onClick={() => this.onChange(item)}
                      selected={this.state.selectedTrips.includes(item.tripId)}
                    >
                      <Popover
                        placement="left"
                        trigger="click"
                        content={
                          <div
                            style={{
                              height: '368px',
                              width: '400px',
                            }}
                          >
                            <p className="mileage">
                              Mileage:
                              {tripMileage} - Fuel Consumption:
                              {fuelConsumption}
                            </p>
                            <SingleTrip data={item} zoom={11} />
                          </div>
                        }
                      >
                        <Tooltip title="click to show on map">
                          <Icon
                            type="compass"
                            style={{
                              fontSize: 30,
                              marginRight: '6px',
                            }}
                          />
                        </Tooltip>
                      </Popover>

                      <div>
                        <h6>
                          {moment.tz(
                            item.timeStart * 1000,
                            CurrentUserStore.user.settings.timezone
                          ).format('MMM DD, YYYY')}
                        </h6>
                        <p>
                          {moment.tz(
                            item.timeStart * 1000,
                            CurrentUserStore.user.settings.timezone
                          ).format('h:mmA')} -{' '}
                          {moment.tz(
                            item.timeEnd * 1000,
                            CurrentUserStore.user.settings.timezone
                          ).format('h:mm A')}
                        </p>
                        <Tag>{item.type ? item.type.capitalize() : 'N/A'}</Tag>
                      </div>

                      <Checkbox
                        key={item}
                        value={item}
                        onCklick={() => this.onChange(item)}
                        checked={this.state.selectedTrips.includes(item.tripId)}
                      />
                    </TripItem>
                  );
                }}
              </Observer>
            )}
          />
        </div>
      </Drawer>
    );
  }
}

TripCompairison.propTypes = {
  car: PropTypes.object,
  visible: PropTypes.bool,
  onCloseCompareTrips: PropTypes.func,
  page: PropTypes.number,
  history: PropTypes.object.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
};

export default withRouter(observer(TripCompairison));
