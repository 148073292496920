import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import { Spin, Select, Empty } from 'antd';

import AppStore from 'stores/App';
import CarStore from 'stores/CarStore';
import CurrentUserStore from 'stores/CurrentUserStore';

import { LineGraph } from 'shared/PitstopUI/PitstopChart';

import { getPidName, getCar, getPathName } from './utils';
import { customFleets } from 'shared';

const Option = Select.Option;

const LoadingState = styled.div`
  display: block;
  width: 100%;
  padding: 0px 40px;
  text-align: center;

  && {
    margin-top: 0px;
  }
`;

export class AnalyticsPlayground extends Component {
  constructor() {
    super();
    this.state = {
      graphOptions: {
        title: '',
        exporting: {
          enabled: true,
        },
        chart: {
          type: 'line',
          zoomType: 'x',
          events: {
            selection: this.onMouseSelection.bind(this),
          },
        },
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: this.onMouseOver.bind(this),
              },
            },
          },
        },
        tooltip: {
          split: true,
          distance: 30,
          snap: 2000,
          padding: 5,
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
          events: {
            setExtremes: this.onSetExtremes.bind(this),
          },
        },
        time: {
          getTimezoneOffset: (timestamp) => {
            var zone = CurrentUserStore.user.settings.timezone,
              timezoneOffset = -moment.tz(timestamp, zone).utcOffset();

            return timezoneOffset;
          },
        },
        series: [],
      },
      alarmBounds: [],
      graphPlotBands: [],
      graphKeys: [],
      graphSeries: [],
      containerProps: null,
      aggregatedKeys: [],
      aggregatedSeries: [],
      currentMarker: 0,
    };
  }

  onMouseOver = async (point) => {
    this.props.setCurrentMarkerTime &&
      this.props.setCurrentMarkerTime(point.target.x);
  };

  onMouseSelection = async (event) => {
    if (event.xAxis) {
      this.props.setEmissionTime({
        startTimestamp: moment(event.xAxis[0].min)
          .tz(CurrentUserStore.user.settings.timezone)
          .unix(),
        endTimestamp: moment(event.xAxis[0].max)
          .tz(CurrentUserStore.user.settings.timezone)
          .unix(),
      });
    }
  };

  onSetExtremes = async (event) => {
    if (typeof event.min == 'undefined' && typeof event.max == 'undefined') {
      this.props.setEmissionTime({
        startTimestamp: moment(this.props.startDate)
          .tz(CurrentUserStore.user.settings.timezone)
          .unix(),
        endTimestamp: moment(this.props.endDate)
          .tz(CurrentUserStore.user.settings.timezone)
          .unix(),
      });
    }
  };

  get carId() {
    let { match, car } = this.props;

    return match ? Number(match.params.id) : Number(car.id);
  }

  get shopId() {
    return getCar(this.carId).shopId;
  }

  get pids() {
    return getCar(this.carId).pids;
  }

  get allValidKeys() {
    const keys = Object.keys(this.pids.data.data);

    return _.filter(keys, (key) => !_.isEmpty(this.pids.data.data[key]));
  }

  async componentDidMount() {
    if (this.props.showAnalytics) {
      await this.updateSensorData();
    }

    this.setState({
      containerProps: {
        style: {
          height: customFleets.npl.includes(this.props.car.shopId) ? 500 : 300,
          width:
            document.getElementsByClassName('analytics')[0] &&
            document.getElementsByClassName('analytics')[0].offsetWidth,
        },
      },
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showAnalytics !== prevProps.showAnalytics &&
      this.props.showAnalytics &&
      !this.pids.loaded
    ) {
      await this.updateSensorData();
    }

    // update startDate, endDate state
    if (
      (!prevProps.startDate.isSame(this.props.startDate) ||
        !prevProps.endDate.isSame(this.props.endDate)) &&
      this.props.showAnalytics
    ) {
      await this.updateSensorData();
    }
  }

  updateSensorData = async () => {
    try {
      this.pids.reset();
      await this.getSensorData();
      this.setDefaultGraphKeys();
      // this.setAlarmBounds();
    } catch (err) {
      AppStore.addError(`Error in getting sensor data: ${err.message}`);
    }
  };

  setDefaultGraphKeys = () => {
    let { selectedGraphKeys } = this.props;

    let defaultKeys = _.filter(this.allValidKeys, (key) =>
      selectedGraphKeys.includes(key)
    );

    let graphKeys = _.isEmpty(defaultKeys) ? this.allValidKeys : defaultKeys;

    this.setState(
      {
        graphKeys,
      },
      () => {
        this.setGraphSeries();
      }
    );
  };

  getSensorData = async (replace = true, cancel = false) => {
    let { startDate, endDate } = this.props;
    let sampleSize = customFleets.gmrv.includes(this.shopId) ? 300000 : 1000;
    let allowedNull = customFleets.gmrv.includes(this.shopId);
    let excludeValues = customFleets.gmrv.includes(this.shopId)
      ? 'BatteryLevel,vin,#Satellites,tripId,tripIndicator,2Flow,2NDIR,2Operation,2Pump,2Sensors,FixType,Quality,deviceTimestamp,latitude,longitude'
      : 'deviceTimestamp,vin,latitude,longitude';

    await CarStore.data
      .get(this.carId)
      .getSensorData(
        startDate,
        endDate,
        1,
        replace,
        cancel,
        sampleSize,
        allowedNull,
        excludeValues
      );
  };

  setAggregatedSeries = () => {
    let aggregatedSeries = _.map([...this.state.aggregatedKeys], (key) => {
      let label = `${getPidName(key)}-aggregated`;

      return {
        name: label,
        data: [..._.get(this.pids.data.data, key)],
      };
    });

    let formatAggregatedSeries = [];

    _.forEach([...aggregatedSeries], (serie) => {
      let aggregatedSerie = {
        name: '',
        data: [],
      };

      aggregatedSerie['name'] = serie.name;

      _.forEach(serie.data, (a, i) => {
        if (i === 0) {
          aggregatedSerie.data.push([serie.data[i][0], serie.data[i][1]]);
        } else {
          aggregatedSerie.data.push([
            serie.data[i][0],
            serie.data[i][1] + aggregatedSerie.data[i - 1][1],
          ]);
        }
      });

      formatAggregatedSeries.push(aggregatedSerie);
    });

    this.setState(
      {
        aggregatedSeries: formatAggregatedSeries,
      },
      () => this.setSeries()
    );
  };

  setAlarmBounds = () => {
    if (_.isNil(this.props.alarms)) return {};

    let alarmBounds = _.map([...this.props.alarms], (alarm) => {
      if (alarm.name === 'Idling') {
        return {
          label: {
            text: alarm.name,
          },
          color: '#ED5565',
          from:
            Number(
              moment(alarm.triggeredAt).tz('America/Toronto').format('x')
            ) - Number(alarm.data.duration * 1000),
          to: Number(
            moment(alarm.triggeredAt).tz('America/Toronto').format('x')
          ),
        };
      }

      if (alarm.name === 'HighSpeedStart' && alarm.name === 'HighSpeedEnd') {
        return {
          label: {
            text: 'High Speeding',
            rotation: 90,
            textAlign: 'left',
            align: 'right',
          },
          color: '#A62639',
          from: Number(
            moment(alarm.highspeedstart).tz('America/Toronto').format('x')
          ),
          to: Number(
            moment(alarm.triggeredAt).tz('America/Toronto').format('x')
          ),
          zIndex: 2,
        };
      }

      return {
        //label
        label: {
          text: alarm
            ? alarm.name && alarm.name.capitalizeFirstLetter()
            : 'N/A',
          rotation: 90,
          textAlign: 'left',
          align: 'right',
        },
        // Color value
        color:
          alarm.name === 'Idling'
            ? '#ED5565'
            : alarm.name === 'High Speeding'
            ? '#A62639'
            : alarm.name === 'Braking'
            ? '#E54A1B'
            : alarm.name === 'Acceleration'
            ? '#253C78'
            : '#f70800',
        // Start of the plot band
        from: Number(
          moment(alarm.triggeredAt).tz('America/Toronto').format('x')
        ),
        // End of the plot band
        to:
          Number(moment(alarm.triggeredAt).tz('America/Toronto').format('x')) +
          Number(alarm.data.duration),
        zIndex: 5,
      };
    });

    this.setState({
      alarmBounds,
    });
  };

  setGraphSeries = () => {
    let graphSeries = _.map([...this.state.graphKeys], (key) => {
      let label = getPidName(key);

      return {
        name: label,
        data: [..._.get(this.pids.data.data, key)],
      };
    });

    this.setState(
      {
        graphSeries,
      },
      () => this.setSeries()
    );
  };

  setSeries = () => {
    let { graphSeries, aggregatedSeries } = this.state;

    this.setState({
      graphOptions: {
        ...this.state.graphOptions,
        series: _.concat(...graphSeries, ...aggregatedSeries),
      },
    });
  };

  setGraphKeys = (keys) => {
    this.setState({ graphKeys: keys }, () => {
      this.props.onSelectGraphKeys(keys);
      this.setGraphSeries();
    });
  };

  dropdownView = () => {
    let graphKeys = [...this.state.graphKeys];

    return (
      <Select
        mode="multiple"
        fullWidth
        value={graphKeys}
        style={{ width: '100%' }}
        placeholder="Select PID Values to graph"
        onChange={this.setGraphKeys}
      >
        {_.map(this.allValidKeys, (key) => (
          <Option key={key}>{getPidName(key)}</Option>
        ))}
      </Select>
    );
  };

  setAggregatedKeys = (keys) => {
    this.setState({ aggregatedKeys: keys }, () => this.setAggregatedSeries());
  };

  allValidAggregatedKeys = () => {
    let aggregatedKeys = [
      'CO2[g/s]',
      'CO[mg/s]',
      'C6H14[mg/s]',
      'NO[mg/s]',
      'VSS[mi/h]',
      'CO[g/s]',
      'C6H14[g/s]',
      'NO[g/s]',
      'Distance[km]',
      'Distance[mi]',
      'CO2[g]',
      'CO[g]',
      'C6H14[g]',
      'NO[g]',
      'CO2[g/mi]_1MileWin',
      'CO[g/mi]_1MileWin',
      'C6H14[g/mi]_1MileWin',
      'NO[g/mi]_1MileWin',
    ];

    const keys = Object.keys(this.pids.data.data);

    return _.filter(
      keys,
      (key) =>
        !_.isEmpty(this.pids.data.data[key]) &&
        !_.isNil(this.pids.data.data[key][0][1]) &&
        aggregatedKeys.indexOf(key) !== -1
    );
  };

  dropdownAggregatedView = () => {
    let aggregatedKeys = [...this.state.aggregatedKeys];

    return (
      <Select
        mode="multiple"
        fullWidth
        value={aggregatedKeys}
        style={{ width: '100%' }}
        placeholder="Select PID Values to graph"
        onChange={this.setAggregatedKeys}
      >
        {_.map(this.allValidAggregatedKeys(), (key) => (
          <Option key={key}>{getPidName(key)}</Option>
        ))}
      </Select>
    );
  };

  getGraphView = () => {
    if (!this.pids.loaded) {
      let pathname = getPathName(this.props.history);

      return (
        <LoadingState
          style={{
            height: pathname.includes('/sharetrip') ? 500 : 300,
          }}
        >
          <Spin tip="Fetching vehicle data from the dates you selected." />
        </LoadingState>
      );
    }

    if (this.pids.loaded) {
      if (_.isEmpty(this.pids.data.data)) {
        return (
          <Empty
            style={{ height: 300 }}
            description="There is no data present for the time period you selected.
          Please try another time range!"
          />
        );
      }

      let { graphOptions, containerProps, alarmBounds } = this.state;

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {!this.props.hideDropDown && (
            <div style={{ marginLeft: 12 }}>
              <p style={{ fontWeight: 'bold' }}>
                Select the data You want to display in the field below.
              </p>
              {this.dropdownView()}
            </div>
          )}

          {/* {customFleets.gmrv.includes(this.shopId) !== -1 && (
            <div style={{ margin: '12px 0px 12px 12px' }}>
              <p style={{ fontWeight: 'bold' }}>
                Select the aggregated data You want to display in the field
                below.
              </p>
              {this.dropdownAggregatedView()}
            </div>
          )} */}

          <LineGraph
            tripId={this.props.tripId}
            options={graphOptions}
            alarmBounds={alarmBounds}
            containerProps={containerProps}
          />
        </div>
      );
    }

    return <Empty />;
  };

  render() {
    if (!this.props.showAnalytics) return <></>;

    return <div className="analytics">{this.getGraphView()}</div>;
  }
}

AnalyticsPlayground.propTypes = {
  endDate: PropTypes.object,
  car: PropTypes.object,
  match: PropTypes.object,
  startDate: PropTypes.object,
  setCurrentMarkerTime: PropTypes.func,
  alarms: PropTypes.array,
  showAnalytics: PropTypes.bool,
  history: PropTypes.object,
  setEmissionTime: PropTypes.func,
  onSelectGraphKeys: PropTypes.func,
  selectedGraphKeys: PropTypes.array,
  hideDropDown: PropTypes.bool,
  tripId: PropTypes.number,
};

export default withRouter(observer(AnalyticsPlayground));
