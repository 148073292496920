import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { Col, DatePicker, Form, Input, Radio, Row } from 'antd';

import { CurrentUserStore } from 'stores';

const ServiceFeedback = ({ setFeedbackValue, setCompletedTime }) => {
  const [textArea, setTextArea] = useState('');
  const [currentSelectedOption, setCurrentSelectedOption] = useState('');

  useEffect(() => {
    if (textArea && currentSelectedOption) {
      setFeedbackValue(`${currentSelectedOption} - ${textArea}`);
      return;
    } else if (textArea) {
      setFeedbackValue(textArea);
      return;
    }
    setFeedbackValue(currentSelectedOption);
  }, [textArea, currentSelectedOption, setFeedbackValue]);

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <label>Service is completed on: </label>

          <DatePicker
            showTime
            placeholder="Select Time"
            defaultValue={moment()}
            onChange={(val) =>
              setCompletedTime(
                moment
                  .tz(val, CurrentUserStore.user.settings.timezone)
                  .toISOString()
              )
            }
          />
        </Col>

        <Col span={24}>
          <Radio.Group onChange={e => {
            setCurrentSelectedOption(e.target.value);
          }}>
            <Radio
              style={radioStyle}
              value="We performed service on this issue"
            >
              We performed service on this issue
            </Radio>
            <Radio
              style={radioStyle}
              value="The issue existed but can be ignored"
            >
              The issue existed but can be ignored
            </Radio>
            <Radio
              style={radioStyle}
              value="The issue did not exist and this alert is false"
            >
              The issue did not exist and this alert is false
            </Radio>
            <Radio
              style={radioStyle}
              value="The alert is outdated and no longer relevant"
            >
              The alert is outdated and no longer relevant
            </Radio>
          </Radio.Group>
          <div style={{ marginTop: '15px' }}>
            <label htmlFor="moreInfo" style={{ fontWeight: '500' }}>
              Can you tell us a little more?
            </label>
            <Input.TextArea
              rows={5}
              id='moreInfo'
              label="Can you tell us a little more?"
              placeholder="The more details that are shared allows the Pitstop algorithms to get smarter and more accurate"
              onInput={(e) => {
                setTextArea(e.target.value);
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

ServiceFeedback.propTypes = {
  setFeedbackValue: PropTypes.func,
  setCompletedTime: PropTypes.func,
};

const ServiceFeedbackForm = Form.create({ name: 'service_feedback' })(
  ServiceFeedback
);

export default ServiceFeedbackForm;
