import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { Row, Col, notification, Spin, Input, Form } from 'antd';

import OktaAuthService from './OktaAuthService';

import { ErrorField } from 'v3/components/pitstop';
import OktaLogo from 'v3/assets/okta-logo.png';

import { CurrentUserStore, ShopStore } from 'stores';

const Container = styled(Form)`
  padding-right: 7vw;

  h1 {
    font-size: 2.8rem;
    font-weight: 600;
    color: #000000;
  }

  p {
    font-weight: 400;
    color: #8c8c8c;
    opacity: 0.9;
  }

  label {
    font-weight: 600;
    display: block;
  }

  input {
    margin: 1.4vh 0vh;
  }
`;

const DashLine = styled.div`
  opacity: 0.2;
  border-top: 2px dashed #000000;
`;

const LoginButton = styled.button`
  cursor: pointer;
  border: none;
  background: #02103d;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: #ffffff;

  height: 3rem;
  min-width: 8rem;
  margin-top: 2vh;
`;

const OktaButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background: white;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: 3rem;
  min-width: 17rem;
`;

class LoginForm extends Component {
  static propTypes = {
    isUserLogin: PropTypes.func,
    history: PropTypes.object.isRequired,
  };

  state = {
    email: null,
    password: null,
    isSubmitted: false,
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getError = (type, value) => {
    if (_.isEmpty(value) || _.isNil(value)) {
      return <ErrorField>{type} is required!</ErrorField>;
    }

    if (type === 'password' && value.match(/(\s)/g)) {
      return (
        <ErrorField>
          Please, review your password. It contains blank spaces
        </ErrorField>
      );
    }

    return null;
  };

  onSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      isSubmitted: true,
    });

    const { email, password } = this.state;

    // we should validate the form before submit
    if (this.getError('email', email) || this.getError('password', password)) {
      return;
    }

    this.props.isUserLogin(true);

    notification.open({
      key: 'login',
      description: (
        <>
          <Spin size="large" style={{ marginRight: '0.6rem' }} />
          You are logging into Pitstop dashboard...
        </>
      ),
      duration: 0,
    });

    try {
      ShopStore.reset();

      const user = await CurrentUserStore.login(email.trim(), password.trim());
      if (user) {
        await CurrentUserStore.getCurrentUser();
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        key: 'error',
        description: <Container>{err.message}</Container>,
      });
    } finally {
      notification.close('login');
      this.props.isUserLogin(false);
    }
  };

  handleOktaLogin = async () => {
    try {
      const authUrl = await OktaAuthService.getAuthorizationUrl();
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error generating authorization URL:', error);
      notification.error({
        message: 'Error',
        key: 'error',
        description: <Container>Error generating OKTA authorization URL</Container>,
      });
    }
  };

  render() {
    const { email, password, isSubmitted } = this.state;

    return (
      <Container id="register-form">
        <Row>
          <Col>
            <h1>Welcome Back!</h1>
          </Col>

          <Col>
            <p>
              Ready to take your maintenance strategy to the next level? Get
              started below
            </p>

            <DashLine />
          </Col>

          <Col style={{ margin: '1.6vw 0vw' }}>
            <label>Registered email/username</label>
            <Input
              name="email"
              placeholder="yourname@companyname.com"
              onChange={this.onChange}
            />
            {isSubmitted && this.getError('email', email)}
          </Col>

          <Col style={{ margin: '1.6vw 0vw' }}>
            <label>Password</label>
            <Input
              name="password"
              type="password"
              placeholder="Min 6 characters"
              onChange={this.onChange}
            />
            {isSubmitted && this.getError('password', password)}
          </Col>

          <Col span={24} style={{ textAlign: 'center' }}>
            <LoginButton isActive={email && password} onClick={this.onSubmit}>
              Login
            </LoginButton>
            <Link
              to="/reset-password"
              style={{ color: '#02103d', fontWeight: 600, marginLeft: '1rem' }}
            >
              Forgot password?
            </Link>
            {/* <DashLine style={{ margin: '1.6vw 0vw' }} /> */}
          </Col>

          <Col span={24} style={{ margin: '1.6vw 0', textAlign: 'center', fontWeight: 'bold' }}>OR</Col>

          <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <OktaButton onClick={this.handleOktaLogin}>
                <div style={{ fontSize: 'larger', alignSelf: 'center', fontWeight: 800 }}>Continue with</div>
                <img src={OktaLogo} alt="okta-logo" style={{ height: '40px', marginLeft: '5px' }}/>
            </OktaButton>
          </Col>

        </Row>
      </Container>
    );
  }
}

export default withRouter(LoginForm);
