import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { PitstopTable } from 'shared';
import { showDemoVinOrRealVin } from 'shared/utils';

import { TableStore } from 'stores/abstract';
import { CarStore } from 'stores';

const Wrapper = styled.div`
  padding: 16px;
`;

class ViewUserCars extends Component {
  static propTypes = {
    shopId: PropTypes.number,
    userId: PropTypes.number.isRequired,
  };

  tableStore = new TableStore();

  get columns() {
    return [
      { dataIndex: 'make', title: 'Make' },
      { dataIndex: 'model', title: 'Model' },
      {
        dataIndex: 'vin',
        title: 'VIN',
        render: (fieldValue, row) => {
          return <Link to={`car/${row.id}`}>{showDemoVinOrRealVin(fieldValue)}</Link>;
        },
      },
      { dataIndex: 'carName', title: 'Name' },
    ];
  }

  get data() {
    let data = _.map(this.tableStore.data, (id, index) => ({
      key: index,
      ...CarStore.data.get(id),
    }));

    return _.filter(data, Boolean);
  }

  render() {
    return (
      <Wrapper>
        <h4>Vehicles</h4>
        <br />
        <PitstopTable
          id={`${this.props.userId}-user-cars`}
          columns={this.columns}
          data={this.data}
          fetchData={(filters, table) => {
            CarStore.getUserCarsForTable(filters, table, this.props.userId);
          }}
          getTableStore={(tableStore) => (this.tableStore = tableStore)}
          rowKey="key"
        />
      </Wrapper>
    );
  }
}

decorate(ViewUserCars, {
  tableStore: observable,
  data: computed,
});

export default observer(ViewUserCars);
