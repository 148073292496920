import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Card, Col, Row, Tooltip, Icon, Popover } from 'antd';

import { PitstopModal } from 'shared/PitstopUI';

import Connect from 'assets/connect.svg';
import SamsaraImg from 'v3/assets/samsara.png';
import GeotabImg from 'v3/assets/geotab.png';

import { AppStore } from 'stores';

import {
  GeotabIntegrationForm,
  SamsaraIntegrationForm,
} from 'v3/components/forms/onboarding';
import SmartcarFormModal from './SmartcarFormModal';
import OtherFormModal from './OtherFormModal';

import { ShopStore } from 'stores';

const StyledRow = styled(Row)`
  && {
    height: 130px;
    font-size: 12.5px;
  }
`;

class IntegrationItem extends React.Component {
  static propTypes = {
    shopId: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string,
    icon: PropTypes.object,
    description: PropTypes.string,
  };

  render() {
    const { text, icon, description } = this.props;

    return (
      <Popover content={text}>
        <Card
          onClick={this.props.onClick}
          bodyStyle={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: 8,
            height: 130,
          }}
        >
          {description && <h6>{description}</h6>}
          {icon && (
            <img
              alt={text}
              src={icon.url}
              style={{
                maxWidth: icon.maxWidth || '100%',
                maxHeight: icon.maxHeight || '100%',
              }}
            />
          )}
        </Card>
      </Popover>
    );
  }
}

class AddIntegrationModal extends React.Component {
  static propTypes = {
    shopId: PropTypes.number.isRequired,
    afterCreate: PropTypes.func.isRequired,
    noModal: PropTypes.bool,
    next: PropTypes.func,
  };

  openModal = (modalId) => {
    AppStore.openModals.set(modalId, true);
  };

  renderIntegrationItem = () => {
    const { shopId, afterCreate } = this.props;

    const geotabIntegrationModalId = 'geotab-integration-form';
    const samsaraIntegrationModalId = 'samsara-integration-form';
    const smartcarIntegrationModalId = 'smarcar-integration-form';
    const otherIntegrationModalId = 'other-integration-form';

    return (
      <Row style={{ height: 330 }} gutter={[16, 16]}>
        <Col span={16}>
          <Card bodyStyle={{ padding: 12 }} style={{ height: 300 }}>
            <StyledRow>
              <p>
                Connect your fleet with Pitstop by logging in with Geotab and
                many more!
              </p>
              <p>Obtain predictive analytics & alerts on your fleet health.</p>
            </StyledRow>

            <Row
              style={{ display: 'flex', justifyContent: 'center' }}
              gutter={[16, 16]}
            >
              <Col span={8}>
                <IntegrationItem // geotab
                  shopId={shopId}
                  text={'Geotab'}
                  icon={{ url: GeotabImg }}
                  onClick={() => this.openModal(geotabIntegrationModalId)}
                />
              </Col>

              <Col span={8}>
                <IntegrationItem // Samsara
                  shopId={shopId}
                  text={'Samsara'}
                  icon={{ url: SamsaraImg }}
                  onClick={() => this.openModal(samsaraIntegrationModalId)}
                  afterCreate={afterCreate}
                />
              </Col>

              <Col span={8}>
                <IntegrationItem // other
                  shopId={shopId}
                  text={'Others'}
                  icon={{ url: Connect, maxWidth: '30%', maxHeight: '30%' }}
                  description="Choose other telematic integrations"
                  onClick={() => this.openModal(otherIntegrationModalId)}
                  afterCreate={afterCreate}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        {/* <Col span={7}>
          <Card bodyStyle={{ padding: 12 }} style={{ height: 300 }}>
            <StyledRow>
              <p>
                Connect your fleet with telematics to prevent & predict failures
                and optimize uptime.
              </p>
              <p>
                Connect your car for <b>$10</b> per vehicle/month.
              </p>
            </StyledRow>
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
              <Col span={12}>
                <Link to="/billing" target="_blank">
                  <IntegrationItem // pitstop telematic solutions
                    shopId={shopId}
                    text={'Pitstop'}
                    icon={{ url: PitstopDevice }}
                    onClick={() => {}}
                  />
                </Link>
              </Col>
            </Row>
            <Tooltip
              style={{ marginTop: 10 }}
              placement="rightTop"
              title="This option requires telematics and is excellent for advanced maintenance insights."
            >
              <Icon style={{ fontSize: 18 }} type="info-circle" />
            </Tooltip>
          </Card>
        </Col> */}

        <Col span={8}>
          <Card bodyStyle={{ padding: 12 }} style={{ height: 300 }}>
            <StyledRow>
              <p>
                Connect your fleet to Pitstop by logging into your vehicles
                directly.
              </p>
              <p>
                Keep track of Odometer, Oil life, Fuel Consumption, Tire
                Pressure etc.
              </p>
            </StyledRow>
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
              <Col span={10}>
                <IntegrationItem // smartcar
                  shopId={shopId}
                  text={'SmartCar'}
                  icon={{ url: 'https://i.imgur.com/Z1WGD35.png' }}
                  onClick={() => this.openModal(smartcarIntegrationModalId)}
                  afterCreate={afterCreate}
                />
              </Col>
            </Row>
            <Tooltip
              style={{ marginTop: 10 }}
              placement="rightTop"
              title="This option requires no purchase of telematics and is good for basic maintenance insights."
            >
              <Icon style={{ fontSize: 18 }} type="info-circle" />
            </Tooltip>
          </Card>
        </Col>

        {/*================= forms =================*/}
        {/* <GeotabFormModal
          id={geotabIntegrationModalId}
          shopId={this.props.shopId}
          afterCreate={afterCreate}
        /> */}
        <SmartcarFormModal
          id={smartcarIntegrationModalId}
          shopId={this.props.shopId}
          afterCreate={afterCreate}
        />
        {/* <OtherFormModal
          id={otherIntegrationModalId}
          shopId={this.props.shopId}
          afterCreate={afterCreate}
          next={this.props.next}
        /> */}
        <GeotabIntegrationForm id={geotabIntegrationModalId} />
        <SamsaraIntegrationForm id={smartcarIntegrationModalId} />
        <OtherFormModal
          id={otherIntegrationModalId}
          shopId={ShopStore.currentShop.id}
          afterCreate={() => {}}
        />
      </Row>
    );
  };

  render() {
    return (
      <>
        {this.props.noModal ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {this.renderIntegrationItem()}
          </div>
        ) : (
          <PitstopModal {...this.props}>
            {this.renderIntegrationItem()}
          </PitstopModal>
        )}
      </>
    );
  }
}

export default AddIntegrationModal;
