import React from 'react';

const Split = () => {
  return (
    <img
      src={require('assets/svg/split.svg')}
      alt="split"
      width="22px"
      height="22px"
      style={{ marginRight: 5 }}
    />
  );
};

export default Split;
