import React from 'react';
import { observer } from 'mobx-react';
import { PitstopButton, PitstopStepper } from 'shared';
import styled from 'styled-components';

import CampaignStore from 'stores/CampaignStore';
import AppStore from 'stores/App';

import CampaignNameSection from './CampaignNameSection';
import CampaignMessageSection from './CampaignMessageSection';
import CampaignUsersSection from './CampaignUsersSection';
import CampaignCompletedSection from './CampaignCompletedSection';
import CampaignPreviewSection from './CampaignPreviewSection';
import CarStore from 'stores/CarStore';

const ButtonArea = styled.div`
  display: flex;
  button:nth-child(2) {
    margin-left: auto;
  }
`;
const ContentSection = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

class CreateCampaignPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      finished: false,
      stepIndex: 0,
      title: null,
      contactMethod: 'chat',
      messageContent: null,
      uploadedFileName: 'No file selected',
      mediaUrl: null,
      mediaType: null,
      fileList: [],
      selectedCars: []
    };
  }

  get isNextDisabled() {
    const { stepIndex, title, messageContent, selectedCars } = this.state;
    switch (stepIndex) {
      case 0:
        if (title && title.length > 0) {
          return false;
        }
        break;
      case 1:
        if (messageContent && messageContent.length > 0) {
          return false;
        }
        break;
      case 2:
        if (selectedCars.length > 0) {
          return false;
        }
        break;
      case 3:
      default:
        return false;
    }

    return true;
  }

  goToPage = index => {
    const { stepIndex } = this.state;

    if (stepIndex < index && this.isNextDisabled) {
      return;
    }

    if (index === 4) {
      this.submit();
    } else {
      this.setState({
        stepIndex: index
      });
    }
  };

  submit = () => {
    const {
      title,
      description,
      contactMethod,
      messageContent,
      mediaUrl,
      mediaType,
      selectedCars
    } = this.state;
    let messageObject = {};
    if (mediaUrl !== null && mediaUrl.length > 0) {
      messageObject.type = 'image';
      messageObject.mediaUrl = mediaUrl;
      messageObject.text = messageContent;
      messageObject.mediaType = mediaType;
    } else {
      messageObject.type = 'text';
      messageObject.text = messageContent;
    }
    CampaignStore.addCampaign(
      {
        recipients: selectedCars
          .map(el => CarStore.data.get(el).userId)
          .filter(el => el !== undefined && el !== null),
        message: {
          type: contactMethod,
          name: title,
          desc: description,
          payload: {
            content: messageObject,
            type: contactMethod,
            title: title
          }
        }
      },
      msg => {
        if (msg !== 'success') {
          AppStore.addError(msg);
        } else {
          this.setState({ finished: true, stepIndex: 4 });
        }
      }
    );
  };

  setValue = (name, value, callback = () => {}) => {
    this.setState({ [name]: value }, callback);
  };

  render() {
    const {
      stepIndex,
      finished,
      title,
      contactMethod,
      messageContent,
      mediaUrl,
      fileList,
      selectedCars
    } = this.state;

    if (finished) {
      return (
        <React.Fragment>
          <h1>Campaign Sent!</h1>
          <CampaignCompletedSection />
        </React.Fragment>
      );
    }

    let content = <div />;

    switch (stepIndex) {
      case 0:
        content = (
          <CampaignNameSection
            title={title}
            contactMethod={contactMethod}
            setValue={this.setValue}
          />
        );
        break;
      case 1:
        content = (
          <CampaignMessageSection
            contactMethod={contactMethod}
            messageContent={messageContent}
            fileList={fileList}
            setValue={this.setValue}
          />
        );
        break;
      case 2:
        content = (
          <CampaignUsersSection
            selectedCars={selectedCars}
            setValue={this.setValue}
          />
        );
        break;
      case 3:
      default:
        content = (
          <CampaignPreviewSection
            title={title}
            contactMethod={contactMethod}
            messageContent={messageContent}
            mediaUrl={mediaUrl}
            selectedCars={selectedCars}
          />
        );
        break;
    }
    return (
      <React.Fragment>
        <h1>Create Campaign</h1>
        <PitstopStepper
          steps={[
            'Choose Channel',
            'Add Message',
            'Select Users',
            'Preview Campaign'
          ]}
          index={stepIndex}
        />
        <ContentSection>{content}</ContentSection>

        <ButtonArea>
          <PitstopButton
            disabled={stepIndex === 0}
            type="secondary"
            onClick={() => this.goToPage(stepIndex - 1)}
          >
            Back
          </PitstopButton>
          <PitstopButton
            disabled={this.isNextDisabled}
            type="primary"
            onClick={() => this.goToPage(stepIndex + 1)}
          >
            {stepIndex === 3 ? 'Send Campaign' : 'Next'}
          </PitstopButton>
        </ButtonArea>
      </React.Fragment>
    );
  }
}

export default observer(CreateCampaignPage);
