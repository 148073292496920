import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import IconSelector from './IconSelector';
import AlgorithmIconCard from './AlgorithmIconCard';
const ResultIndicatorPass = styled.div`
  background-color: #f6ffed;
  color: #52c41a;
  position: absolute;
  height: 25%;
  bottom: -12px;
  border-radius: 0 0 13px 13px;
  left: 0;
  width: 100%;
  border-top: 1px solid #c4efa3;
  text-align: center;
`;

const ResultIndicatorFail = styled.div`
  background-color: #fff1f0;
  color: #ff4d4f;
  position: absolute;
  height: 25%;
  bottom: -12px;
  border-radius: 0 0 13px 13px;
  left: 0;
  width: 100%;
  border-top: 1px solid #ff4d4f;
  text-align: center;
`;

const IconWrapper = styled.div`
  display: grid;
  grid-template-rows: 3fr 1fr;
  text-align: center;
  height: 70%;
`;

const PartIcon = styled.div`
  height: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
`;

const PartName = styled.p`
  margin-top: 25%;
`;

const AlgorithmIcon = (props) => {
  const cardBody = <>
        <IconWrapper>
          <PartIcon>
            <IconSelector style={{height:'10px'}} selected={props.selected} iconName={props.iconName} />
          </PartIcon>
          <PartName>{props.iconName}</PartName>
        </IconWrapper>
        {props.status === 'pass' ? 
          <ResultIndicatorPass>Pass</ResultIndicatorPass> :
          <ResultIndicatorFail>{props.status}</ResultIndicatorFail>}
        </>;
  return (
    <AlgorithmIconCard selectFunction={props.selectFunction} selected={props.selected} cardBody={cardBody} />
  );
};

AlgorithmIcon.propTypes = {
    iconName: PropTypes.string,
    status: PropTypes.string,
    selected: PropTypes.bool,
    selectFunction: PropTypes.func 
};

export default AlgorithmIcon;