import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CarObject, Logger } from 'stores/Classes';
import { Button, Card, Col, Input, Popconfirm, Row, Spin, Tooltip } from 'antd';
import { AppStore, CarStore, ShopStore } from 'stores';
import { get } from 'lodash';
import AddDocumentsCard from 'components/Warranties/AddDocumentsCard';
import { webServiceProvider } from 'shared';
import styled from 'styled-components';

const GrayButton = styled(Button)`
  color: #888888;

  &:hover {
    color: #888888;
  }
  &:focus {
    color: #888888;
  }
  &:active {
    color: #888888;
  }
`;

class CarDocumentSections extends Component {
  state = {
    isLoading: true,
    isLoadingDocuments: false,
    isSavingCarDocuments: false,
    uploadingFileToSection: [],
    sections: [],
    editingSections: [],
    documentRefs: [],
    carDocuments: [],
    sectionDocuments: {},
  };

  componentDidMount() {
    this.init();
  }

  filterOutSectionDocuments = (sectionId, carDocuments = []) => {
    return carDocuments.filter(
      (doc) => doc.sectionId.toString() === sectionId.toString()
    );
  };

  init = async () => {
    const { currentShop } = ShopStore;
    if (!currentShop.id || currentShop.id === -1) {
      AppStore.addError('Please select a shop first');
      return;
    }
    try {
      this.setState({ isLoading: true });
      const shopSettings = await ShopStore.getShopSettings(currentShop.id);
      const { result } = shopSettings;
      const settings = get(result, '[0].settings', {
        assetDocumentSections: [],
      });
      const assetDocumentSections = get(settings, 'assetDocumentSections', []);
      this.setState(
        {
          sections: assetDocumentSections,
          isLoading: false,
        },
        () => {
          this.loadCarDocumentsAndAddItToCorrespondingSection();
        }
      );
    } catch (error) {
      Logger.error('Error getting shop settings', error);
      AppStore.addError('Error getting shop settings');
      this.setState({ isLoading: false });
    }
  };

  loadCarDocuments = async () => {
    try {
      this.setState({ isLoadingDocuments: true });
      const { car } = this.props;
      const { data: carDocuments } = await CarStore.getCarDocuments(car.id);
      this.setState({ carDocuments });
      return carDocuments;
    } catch (error) {
      Logger.error('Error getting car documents', error);
      AppStore.addError('Error getting car documents');
    } finally {
      this.setState({ isLoadingDocuments: false });
    }
  };

  loadCarDocumentsAndAddItToCorrespondingSection = async () => {
    const carDocuments = await this.loadCarDocuments();
    const { documentRefs } = this.state;
    documentRefs.forEach((docRef) => {
      const sectionDocuments = this.filterOutSectionDocuments(
        docRef.id,
        carDocuments
      );
      this.setState({
        sectionDocuments: {
          ...this.state.sectionDocuments,
          [docRef.id]: sectionDocuments,
        },
      });
    });
  };

  // Save sections to the shop settings
  saveSections = async () => {
    try {
      const { currentShop } = ShopStore;
      const { sections } = this.state;
      // get current settings
      const shopSettings = await ShopStore.getShopSettings(currentShop.id);
      const { result } = shopSettings;
      const settings = get(result, '[0].settings', {});
      await ShopStore.upsertShopSettings(currentShop.id, {
        ...settings,
        assetDocumentSections: sections,
      });
    } catch (err) {
      Logger.error('Error saving shop document', err);
      AppStore.addError('Error saving shop document');
    }
  };

  saveCarSectionDocuments = async (carId, section, fileData) => {
    try {
      const { data: currentDocuments } = await CarStore.getCarDocuments(carId);
      this.setState({ isSavingCarDocuments: true });
      await CarStore.upsertCarDocuments(carId, [
        ...currentDocuments,
        {
          sectionId: section.id.toString(),
          ...fileData,
        },
      ]);
      this.setState({ isSavingCarDocuments: false });
      this.loadCarDocumentsAndAddItToCorrespondingSection();
    } catch (err) {
      Logger.error('Error saving car document', err);
      AppStore.addError('Error saving car document');
      this.setState({ isSavingCarDocuments: false });
    }
  };

  onAddNewSection = () => {
    const { sections } = this.state;
    const { currentShop } = ShopStore;
    if (currentShop.id === -1) {
      AppStore.addError('Please select a shop first');
      return;
    }
    this.setState(
      {
        sections: [
          ...sections,
          {
            id: new Date().getTime(),
            name: 'Documents',
          },
        ],
      },
      () => {
        this.saveSections();
      }
    );
  };

  handleFileUploaded = async (section, file) => {
    const formData = new FormData();
    formData.append('singleInputFileName', file);
    try {
      this.setState({
        uploadingFileToSection: [
          ...this.state.uploadingFileToSection,
          section.id,
        ],
      });
      const data = await webServiceProvider.postFormData(
        'dashboard/v1/uploadimage',
        formData
      );
      this.setState({
        uploadingFileToSection: this.state.uploadingFileToSection.filter(
          (id) => id !== section.id
        ),
      });
      if (data.fileUrl) {
        const uploadedFileData = {
          name: file.name,
          url: data.fileUrl,
        };
        const { car } = this.props;
        this.saveCarSectionDocuments(car.id, section, uploadedFileData);
      }
    } catch (e) {
      AppStore.addError('Error uploading file');
      this.setState({
        uploadingFileToSection: this.state.uploadingFileToSection.filter(
          (id) => id !== section.id
        ),
      });
    }
  };

  handleFileRemoved = async (section, file) => {
    // get all current car documents
    const { carDocuments: initialCarDocuments } = this.state;
    const { car } = this.props;
    let newDocuments = [];
    if (file.url) {
      newDocuments = initialCarDocuments.filter((doc) => doc.url !== file.url);
    } else {
      // just added document... check by name and sectionId
      newDocuments = initialCarDocuments.filter(
        (doc) =>
          doc.name !== file.name || doc.sectionId !== section.id.toString()
      );
    }
    this.setState({ isSavingCarDocuments: true });
    await CarStore.upsertCarDocuments(car.id, [...newDocuments]);
    this.setState({ isSavingCarDocuments: false });
    this.loadCarDocumentsAndAddItToCorrespondingSection();
  };

  render() {
    const { isLoading, sections } = this.state;

    return isLoading ? (
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Spin />
        </Col>
      </Row>
    ) : (
      <Row type="flex" justify="center" align="top" gutter={[8, 8]}>
        <Col span={24}>
          <Button
            type="primary"
            ghost
            icon="plus"
            onClick={this.onAddNewSection}
          >
            Add New Section
          </Button>
        </Col>
        {sections.map((section, index) => (
          <Col span={12} key={index}>
            <Card
              title={
                <>
                  {this.state.editingSections.includes(section.id) ? (
                    <Input
                      value={section.name}
                      onChange={(e) => {
                        const { value } = e.target;
                        const newSections = [...sections];
                        newSections[index].name = value;
                        this.setState({ sections: newSections });
                      }}
                      onBlur={() => {
                        const { editingSections } = this.state;
                        this.setState(
                          {
                            editingSections: editingSections.filter(
                              (id) => id !== section.id
                            ),
                          },
                          () => {
                            this.saveSections();
                          }
                        );
                      }}
                    />
                  ) : (
                    section.name
                  )}
                  <Button
                    type="link"
                    icon="edit"
                    onClick={() => {
                      const { editingSections } = this.state;
                      this.setState({
                        editingSections: [...editingSections, section.id],
                      });
                    }}
                  />
                </>
              }
              extra={
                <Tooltip title="Delete Section" placement="top">
                  <Popconfirm
                    title="Are you sure you want to delete this section? This action will remove the section and all its uploaded content for all assets."
                    onConfirm={() => {
                      const newSections = sections.filter(
                        (s) => s.id !== section.id
                      );
                      this.setState({ sections: newSections }, () => {
                        this.saveSections();
                        AppStore.addSuccess('Section deleted successfully');
                      });
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <GrayButton type="link" icon="delete" />
                  </Popconfirm>
                </Tooltip>
              }
            >
              <Spin
                spinning={
                  this.state.isLoadingDocuments ||
                  this.state.isSavingCarDocuments ||
                  this.state.uploadingFileToSection.includes(section.id)
                }
                tip="Loading Documents..."
              >
                <AddDocumentsCard
                  ref={(ref) => {
                    const stateDocRef = this.state.documentRefs.find(
                      (docRef) => docRef.id === section.id
                    );
                    if (!stateDocRef) {
                      this.setState({
                        documentRefs: [
                          ...this.state.documentRefs,
                          { id: section.id, ref },
                        ],
                      });
                    }
                  }}
                  documents={this.state.sectionDocuments[section.id] || []}
                  onFileUploaded={(file) => {
                    this.handleFileUploaded(section, file);
                  }}
                  onFileRemove={(file) => {
                    this.handleFileRemoved(section, file);
                  }}
                  showBorder={false}
                  showTitle={false}
                  maxVisibleItems={4}
                  overrideUploadProps={{
                    accept: 'image/*,.pdf',
                    listType: 'picture',
                  }}
                />
              </Spin>
            </Card>
          </Col>
        ))}
      </Row>
    );
  }
}

CarDocumentSections.propTypes = {
  car: PropTypes.instanceOf(CarObject).isRequired,
  defaultSections: PropTypes.array,
};

export default CarDocumentSections;
