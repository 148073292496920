import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { Empty, Icon } from 'antd';
import { BarChart, WidgetToolTip } from 'shared/PitstopUI/PitstopChart';
import styled from 'styled-components';

import { CarStore, ReportsDataStore } from 'stores';

const NoIssuesSection = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  i {
    font-size: 36px;
    margin-right: 12px;
  }
`;

class VehicleHealthScore extends Component {
  state = {
    dataDisplay: 'Bottom10'
  }
  
  displayChange = (event) => {
    this.setState({dataDisplay: event.target.value});
  }

  get data() {
    let report = ReportsDataStore.vehicleHealthScoreReport.data;

    report = _.orderBy(report, 'score', 'asc');

    let data = _.uniqBy([...report], 'carName');
    data = _.filter(data, (d) => d['score']);

    if (!_.isEmpty(data)) {
      data = _.map(data, (d) => {
        let label = d['carName'] || d['vin'];

        return {
          name: label,
          y: d['score'],
          color: '#123fbf',
        };
      });
    }

    return {
      key: 'Vehicle Score',
      xAxis: 'Unit ID',
      yAxis: 'Score (Higher is better)',
      value: this.state.dataDisplay === 'Bottom10' ? data.slice(0,10) : data,
    };
  }

  render() {
    if (CarStore.demo === false) return <Empty />;

    return (
      <>
        <WidgetToolTip title="This chart ranks vehicles based on fault code history, 
                        with vehicles experiencing more frequent fault codes having lower scores" 
                       colour='#123fbf'/>
        {this.data.value.length > 0 ? 
        <>
        <BarChart
          loaded={ReportsDataStore.vehicleHealthScoreReport.loaded}
          data={this.data}
        />
        {ReportsDataStore.vehicleHealthScoreReport.loaded ?
          <>
            <p>Display:</p>
            <select onChange={this.displayChange} name="display" id="display">
              <option value="Bottom10">Bottom 10</option>
              <option value="Bottom50">Bottom 50</option>
            </select> 
          </>: null}</>
          :         
        <NoIssuesSection>
          <Icon type="check-circle" theme="twoTone" />
          <p>You have no fault code data, please check this chart again later</p>
        </NoIssuesSection>}
      </>
    );
  }
}

export default observer(VehicleHealthScore);
