const scatterDataBeforeBreakdown = [[-0.3774297800925926, 1],
 [-4.0390375347222225, 7],
 [-2.2691881944444443, 8],
 [-0.2532497337962963, 17],
 [-5.1527922800925925, 21],
 [-5.152792326388889, 21],
 [-5.1527922800925925, 21],
 [-5.1527922800925925, 21],
 [-0.3386873032407407, 25],
 [-5.0390815625, 29],
 [-16.10695497685185, 32],
 [-16.10695497685185, 32],
 [-9.331446064814815, 32],
 [-0.27616149305555554, 40],
 [-5.224367361111112, 40],
 [-2.059887349537037, 41],
 [-0.28498549768518516, 51],
 [-18.374003819444443, 52],
 [-6.388759641203704, 52],
 [-0.4601606481481481, 54],
 [-0.27966160879629626, 54],
 [-0.9810127314814815, 55],
 [-0.3026344560185185, 55],
 [-0.38732766203703706, 55],
 [-0.2308969560185185, 58],
 [-3.379257488425926, 65],
 [-2.1724807060185185, 65],
 [-0.3863820601851852, 67],
 [-0.16979197916666666, 68],
 [-5.218688923611111, 71],
 [-0.03511380787037037, 71],
 [-3.0012091435185186, 72],
 [-0.037166354166666665, 72],
 [-0.05183368055555556, 74],
 [-18.04294452546296, 74],
 [-6.018821145833333, 76],
 [-0.4097774652777778, 76],
 [-0.10956693287037038, 80],
 [-0.06450096064814814, 80],
 [-8.7576534375, 83],
 [-6.371005671296296, 91],
 [-4.03795806712963, 91],
 [-20.22738287037037, 93],
 [-20.112727002314813, 93],
 [-20.22738287037037, 93],
 [-20.112726967592593, 93],
 [-5.154215081018519, 94],
 [-12.171828969907407, 94],
 [-12.39087878472222, 94],
 [-7.459566666666667, 97],
 [-1.0800024652777778, 100],
 [-0.12923738425925926, 105],
 [-0.34706947916666664, 105],
 [-0.1292721064814815, 105],
 [-18.40261096064815, 108],
 [-10.36652994212963, 108],
 [-2.0836007291666667, 110],
 [-6.315963622685185, 110],
 [-19.13918715277778, 110],
 [-2.0836007291666667, 110],
 [-12.058225613425925, 111],
 [-6.207757719907407, 113],
 [-6.207757719907407, 113],
 [-1.0068207523148147, 113],
 [-6.454833599537038, 118],
 [-0.021880208333333335, 120],
 [-0.2672734953703704, 122],
 [-6.2972219907407405, 126],
 [-3.3615267013888888, 128],
 [-3.2366971412037033, 128],
 [-5.098646956018519, 128],
 [-0.19915899305555554, 128],
 [-0.09357427083333333, 135],
 [-2.122162650462963, 135],
 [-0.17656828703703703, 137],
 [-0.1765682523148148, 137],
 [-15.027556909722222, 138],
 [-0.35813391203703704, 139],
 [-0.3934018865740741, 139],
 [-0.3692974537037037, 141],
 [-16.155309525462965, 143],
 [-13.382091238425925, 144],
 [-2.370369560185185, 144],
 [-15.057868287037039, 159],
 [-0.059267673611111106, 163],
 [-13.260556979166665, 163],
 [-12.326746875, 163],
 [-0.059267673611111106, 163],
 [-0.043280208333333334, 165],
 [-0.0010828703703703703, 165],
 [-14.40116045138889, 165],
 [-0.1660611111111111, 180],
 [-3.979340590277778, 180],
 [-0.14745624999999998, 180],
 [-0.14745624999999998, 180],
 [-12.242875266203702, 187],
 [-1.942882488425926, 187],
 [-7.378783020833334, 188],
 [-0.0654824074074074, 188],
 [-0.19689818287037036, 188],
 [-0.0005024305555555556, 190],
 [-0.0032897337962962962, 190],
 [-1.4102057060185185, 190],
 [-0.0032898958333333335, 190],
 [-0.27412607638888886, 194],
 [-0.27412607638888886, 194],
 [-0.1061053587962963, 198],
 [-2.0729505787037037, 199],
 [-16.03171076388889, 199],
 [-16.007145682870373, 199],
 [-2.0729505787037037, 199],
 [-1.0012326388888888, 200],
 [-14.997970636574074, 201],
 [-11.3231653125, 204],
 [-4.326289548611111, 211],
 [-4.326289548611111, 211],
 [-20.048714583333332, 213],
 [-20.048714583333332, 213],
 [-20.371405208333332, 213],
 [-0.3685294328703704, 221],
 [-0.2172844097222222, 221],
 [-11.093539780092593, 223],
 [-11.093539780092593, 223],
 [-0.15214725694444445, 226],
 [-7.060052465277779, 226],
 [-5.939725428240741, 229],
 [-8.4074565625, 230],
 [-10.216981562500001, 230],
 [-1.0625947916666667, 235],
 [-2.0500715625, 235],
 [-1.1195206828703703, 235],
 [-13.064660960648148, 235],
 [-0.05262885416666666, 237],
 [-8.359229826388889, 245],
 [-7.211178854166667, 245],
 [-2.3005012731481482, 246],
 [-0.40846038194444445, 249],
 [-0.40846038194444445, 249],
 [-0.15664768518518518, 250],
 [-11.990016122685185, 253],
 [-7.173455173611111, 254],
 [-4.174820520833333, 255],
 [-0.02017063657407407, 266],
 [-0.3763491087962963, 275],
 [-7.383055011574074, 276],
 [-3.0101450578703703, 276],
 [-17.38212866898148, 276],
 [-12.989799803240741, 277],
 [-12.989799803240741, 277],
 [-1.2300998032407409, 279],
 [-7.981134224537037, 285],
 [-15.126180127314814, 285],
 [-16.002787037037038, 285],
 [-14.22701241898148, 285],
 [-15.2301715625, 285],
 [-0.06626346064814814, 286],
 [-10.064102743055555, 288],
 [-11.800508993055555, 288],
 [-14.299408761574073, 293],
 [-0.13287303240740742, 294],
 [-0.13287349537037038, 294],
 [-0.13287303240740742, 294],
 [-5.024999502314815, 297],
 [-5.024999270833333, 297],
 [-5.024990474537037, 297]];

export default scatterDataBeforeBreakdown;