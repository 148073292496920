import { decorate, action } from 'mobx';
import _ from 'lodash';

import AppStore from './App';
import ShopStore from './ShopStore';
import AbstractStore from './abstract/AbstractStore';
import { webServiceProvider } from '../shared';
import { Logger } from './Classes';

class ContactStore extends AbstractStore {
  contactAbortController = new AbortController();
  createContactAbortController = new AbortController();

  contactAbortSignal = this.contactAbortController.signal;

  getShopContacts = async (
    shopId = ShopStore.currentShop.id,
    { offset, limit, filter, sort } = {}
  ) => {
    if (!shopId || shopId === -1) {
      return;
    }
    try {
      try {
        if (this.contactAbortSignal) {
          this.contactAbortController.abort();
        }
      } catch (error) {
        Logger.error('Error in aborting contact request', error);
      }

      this.contactAbortController = new AbortController();
      this.contactAbortSignal = this.contactAbortController.signal;

      const { data } = await webServiceProvider.getMany(
        'v1/contact',
        {
          shopId,
        },
        this.contactAbortSignal
      );

      return data;
    } catch (e) {
      // AppStore.addError(`Error in getting contacts: ${e.message}`);
      throw e;
    }
  };

  createShopContact = async (payload) => {
    try {
      // check duplicate contact - with same email for shop
      const contacts = await this.getShopContacts(payload.shopId);
      const duplicateContact = _.find(contacts, (contact) => {
        return contact.email === payload.email;
      });
      if (duplicateContact) {
        throw new Error('Contact with same email already exists');
      }

      this.createContactAbortController.abort();

      this.createContactAbortController = new AbortController();

      const data = await webServiceProvider.post(
        'v1/contact',
        payload,
        this.createContactAbortController.signal
      );

      return data;
    } catch (e) {
      throw e;
    }
  };

  updateShopContact = async (contactId, payload) => {
    try {
      const data = await webServiceProvider.put(
        `v1/contact/${contactId}`,
        payload
      );

      return data;
    } catch (e) {
      AppStore.addError(`Error in updating contact: ${e.message}`);
      throw e;
    }
  };
}

decorate(ContactStore, {
  getShopContacts: action,
  createShopContact: action,
});

const ContactStoreInstance = new ContactStore();
export default ContactStoreInstance;
