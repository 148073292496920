import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Dropdown, Icon, Menu, Row, Table, Tooltip } from 'antd';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';

const StyledButton = styled(Button)`
  &.mark-resolved {
    margin-left: 5px;
  }
`;

const DefectResolveButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ant-btn {
    &.pitstopDropdown {
      color: #333;
      font-weight: bold;
      font-size: 1.2rem;
      padding-bottom: 30px;
      padding: 0.3rem 1.5rem;
      border-radius: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d9d9d9;
      background-color: white;

      &.green {
        border-color: green;
        color: green;
      }

      &.red {
        border-color: red;
        color: red;
      }

      &.disabled {
        cursor: not-allowed;
        background-color: #f5f5f5;
      }
    }

    &.save-btn {
      margin-top: 10px;
    }
  }
`;

const MechanicNotesWrapper = styled.div`
  position: relative;

  svg {
    width: 16px;
  }

  .mechanic-notes-edit-actions {
    position: absolute;
    right: 10px;
    bottom: 0;

    &.editing-note {
      bottom: 10px;
    }

    svg {
      cursor: pointer;
    }
  }
`;

const VehicleDefectsTable = ({
  data,
  loading,
  onDefectResolveClick,
  onDefectMechanicNotesChange,
  onSaveMechanicNotes,
  onConfirmResolveDefect,
  onMarkAllAsResolvedClick,
  onCreateWorkOrderFromDefectsClick,
  onCreateTMTWorkOrderFromDefectsClick,
  createTmtWorkOrdersEnabled,
  childRef,
  fetchError,
}) => {
  const [selectedDefects, setSelectedDefects] = useState([]);

  const [editingMechanicNotes, setEditingMechanicNotes] = useState([]);

  const [resolvingDefects, setResolvingDefects] = useState([]);

  // just a way to expose the editingMechanicNotes state to the parent component
  childRef(() => {
    return {
      editingMechanicNotes,
      setEditingMechanicNotes,
      resolvingDefects,
      setResolvingDefects,
    };
  });

  const columns = [
    {
      title: 'Defect',
      dataIndex: 'defectType',
      key: 'defectType',
      render: (defectType, row) => {
        return (
          <div>
            <div style={{ fontWeight: 'bold' }}>{defectType}</div>
            <div>
              {row.workOrder ? (
                <>
                  <Link to={`/work-order/${row.workOrder.work_order_id}/edit`}>
                    <Icon type="book" />
                    <span className="marginLeft5">Work Order</span>
                  </Link>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Added On',
      dataIndex: 'createdAtTime',
      key: 'createdAtTime',
      sorter: (a, b) => {
        return moment(a.createdAtTime).unix() - moment(b.createdAtTime).unix();
      },
      render: (createdAtTime) => {
        return (
          <div>
            <div style={{ fontWeight: 'bold' }}>
              {moment(createdAtTime).format('MMMM Do YYYY')}
            </div>
            <div>{moment(createdAtTime).format('LT')}</div>
          </div>
        );
      },
    },
    {
      title: 'Driver Notes',
      dataIndex: 'comment',
      key: 'comment',
      render: (comment) => {
        return comment || 'N/A';
      },
    },
    {
      title: 'Mechanic Notes',
      dataIndex: 'mechanicNotes',
      key: 'mechanicNotes',
      align: 'center',
      width: '15%',
      render: (mechanicNotes, row) => {
        return (
          <MechanicNotesWrapper>
            <TextArea
              rows={4}
              id={`mechanic-notes-${row.id}`}
              value={mechanicNotes}
              onChange={(e) => {
                const value = e.target.value;
                onDefectMechanicNotesChange(row, value);
                setEditingMechanicNotes([...editingMechanicNotes, row.id]);
              }}
            />
            <div
              className={
                'mechanic-notes-edit-actions ' +
                (editingMechanicNotes.indexOf(row.id) > -1
                  ? 'editing-note'
                  : '')
              }
            >
              {editingMechanicNotes.indexOf(row.id) > -1 ? (
                <>
                  <Button
                    type="primary"
                    size="small"
                    ghost
                    onClick={() => {
                      setEditingMechanicNotes(
                        editingMechanicNotes.filter((id) => id !== row.id)
                      );
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                      onSaveMechanicNotes(row, row.mechanicNotes);
                    }}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <EditIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditingMechanicNotes([...editingMechanicNotes, row.id]);
                    // focus on text area
                    document.getElementById(`mechanic-notes-${row.id}`).focus();
                  }}
                />
              )}
            </div>
          </MechanicNotesWrapper>
        );
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (unused, row) => {
        return (
          !fetchError && (
            <DefectResolveButtonWrapper>
              <Dropdown
                className="pitstopDropdown"
                disabled={row.resolvedBy}
                overlay={
                  <Menu
                    onClick={({ key }) => {
                      onDefectResolveClick(key, row);
                      if (key === 'resolved') {
                        setResolvingDefects([...resolvingDefects, row.id]);
                      } else {
                        setResolvingDefects(
                          resolvingDefects.filter((id) => id !== row.id)
                        );
                      }
                    }}
                  >
                    <Menu.Item key={'resolved'}>Resolved</Menu.Item>
                    <Menu.Item key={'unresolved'}>Unresolved</Menu.Item>
                  </Menu>
                }
                placement="bottomCenter"
              >
                <Button
                  className={`${row.isResolved ? 'green' : 'red'} ${
                    row.resolvedBy ? 'disabled' : ''
                  }`}
                  disabled={row.isResolved}
                >
                  {row.isResolved ? 'Resolved' : 'Unresolved'}
                  <Icon type="down" />
                </Button>
              </Dropdown>
              {resolvingDefects.indexOf(row.id) > -1 && (
                <Button
                  className="save-btn"
                  type="primary"
                  onClick={() => {
                    onConfirmResolveDefect(row);
                  }}
                >
                  Confirm Resolution
                </Button>
              )}
            </DefectResolveButtonWrapper>
          )
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows
      );
      setSelectedDefects(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: !!record.resolvedBy,
      name: record.name,
    }),
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {!fetchError && (
            <>
              <StyledButton
                disabled={selectedDefects.length === 0}
                type="primary"
                className="mark-resolved"
                onClick={() => {
                  onMarkAllAsResolvedClick(selectedDefects);
                }}
              >
                Mark all as resolved{' '}
                {selectedDefects.length > 0 && `(${selectedDefects.length})`}
              </StyledButton>
              <Tooltip
                placement="right"
                title={
                  'Resolve multiple defects at once. If all defects are resolved, the DVIR will also be resolved'
                }
              >
                <Button
                  style={{ marginLeft: 10, marginRight: 20 }}
                  size="small"
                  shape="circle"
                  icon="info"
                />
              </Tooltip>
            </>
          )}
          <StyledButton
            disabled={selectedDefects.length === 0}
            type="primary"
            ghost
            className="create-workorder"
            onClick={() => {
              onCreateWorkOrderFromDefectsClick(selectedDefects);
            }}
          >
            Create Work Order From Defects{' '}
            {selectedDefects.length > 0 && `(${selectedDefects.length})`}
          </StyledButton>
          <Tooltip
            placement="right"
            title={
              'Create Work Order From Defects. Select the defects you want create a Work Order'
            }
          >
            <Button
              style={{ marginLeft: 10, marginRight: 20 }}
              size="small"
              shape="circle"
              icon="info"
            />
          </Tooltip>
          {createTmtWorkOrdersEnabled && (
            <>
              <StyledButton
                disabled={selectedDefects.length === 0}
                type="primary"
                ghost
                className="create-workorder"
                onClick={() => {
                  onCreateTMTWorkOrderFromDefectsClick(selectedDefects);
                }}
              >
                Create TMT Work Order From Defects{' '}
                {selectedDefects.length > 0 && `(${selectedDefects.length})`}
              </StyledButton>
              <Tooltip
                placement="right"
                title={
                  'Create Work Order From Defects. Select the defects you want create a Work Order'
                }
              >
                <Button
                  style={{ marginLeft: 10, marginRight: 20 }}
                  size="small"
                  shape="circle"
                  icon="info"
                />
              </Tooltip>
            </>
          )}
        </Row>
      </Col>
      <Col span={24}>
        <Table
          rowKey={(record) => record.id}
          rowSelection={rowSelection}
          dataSource={data}
          columns={columns}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

VehicleDefectsTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onDefectResolveClick: PropTypes.func.isRequired,
  onDefectMechanicNotesChange: PropTypes.func.isRequired,
  onSaveMechanicNotes: PropTypes.func.isRequired,
  onConfirmResolveDefect: PropTypes.func.isRequired,
  onMarkAllAsResolvedClick: PropTypes.func.isRequired,
  onCreateWorkOrderFromDefectsClick: PropTypes.func,
  onCreateTMTWorkOrderFromDefectsClick: PropTypes.func,
  createTmtWorkOrdersEnabled: PropTypes.bool,
  childRef: PropTypes.func,
  fetchError: PropTypes.object,
};

VehicleDefectsTable.defaultProps = {
  data: [],
  loading: false,
  childRef: () => {},
  onCreateWorkOrderFromDefectsClick: () => {},
  onCreateTMTWorkOrderFromDefectsClick: () => {},
  createTmtWorkOrdersEnabled: true,
};

export default VehicleDefectsTable;
