import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { DatePicker } from 'antd';

import CarStore from 'stores/CarStore';
import { AppointmentCalendar } from 'containers/AppointmentCalendar';

class AppointmentDateTimeSection extends Component {
  state = {
    date: moment(),
  };

  setAppointmentTime = (startTime, endTime) => {
    this.props.setValue('startTimeAppointment', moment(startTime));
    this.props.setValue('endTimeAppointment', moment(endTime));
  };

  onChange = (date) => {
    this.setState({
      date: moment(date),
    }, () => {
      this.setAppointmentTime(date, moment(date).add(1, 'hour'));
    });
  };

  render() {
    let { selectedCarId } = this.props;

    let car = CarStore.data.get(selectedCarId);

    if (!car) return <></>;

    return (
      <>
        <DatePicker
          style={{ marginBottom: 10 }}
          onChange={this.onChange}
          value={this.state.date}
          showTime
        />
        <br />
        <AppointmentCalendar
          views={['month', 'week', 'day']}
          defaultView="month"
          ableSlotSelection={true}
          shopId={car.shopId}
          selectedCarId={selectedCarId}
          date={this.state.date}
        />
      </>
    );
  }
}

AppointmentDateTimeSection.propTypes = {
  setValue: PropTypes.func.isRequired,
  startTimeAppointment: PropTypes.instanceOf(moment),
  endTimeAppointment: PropTypes.instanceOf(moment),
  selectedCarId: PropTypes.number,
  selectedCars: PropTypes.array,
};

export default observer(AppointmentDateTimeSection);
