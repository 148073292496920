import { action, decorate, observable, transaction } from 'mobx';
import _ from 'lodash';

import { webServiceProvider } from 'shared';
import { errors } from 'helpers';

import { AbstractStore, TableStore } from 'stores/abstract';
import { ShopStore, CurrentUserStore } from 'stores';
import { IntegrationCacheStore } from 'stores/CacheStore';

import IntegrationRecordObject from './Classes/IntegrationRecordObject';

class IntegrationStoreC extends AbstractStore {
  isReloaded = false;
  modalIntegrationForm = null;

  integrationAccount = [];

  integrationFetchController = new AbortController();
  integrationAccountFetchController = new AbortController();

  async getIntegrationAccountByShop(
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore()
  ) {
    if (_.isNil(shopId)) {
      throw new Error('shop not set');
    }

    if (shopId === -1) return;

    let key = `${shopId}-integration-records`;

    if (IntegrationCacheStore.getData(key)) {
      this.integrationAccount = _.get(
        IntegrationCacheStore.getData(key),
        'data'
      );
      this.loaded = true;

      return;
    }

    try {
      IntegrationCacheStore.setData(key, tableStore);
      this.pending = true;
      this.loaded = false;

      tableStore.pending = true;
      tableStore.loaded = false;

      this.integrationFetchController = new AbortController();
      this.integrationFetchSignal = this.integrationFetchController.signal;

      const { data } = await webServiceProvider.get(
        `v1/shop/${shopId}/integration/account`,
        this.integrationFetchSignal
      );

      if (!_.isNil(data)) {
        tableStore.data.push(data);
        tableStore.setDataMetadata(tableStore.data.length);
      }

      this.integrationAccount = tableStore.data;
      this.loaded = true;
    } catch (err) {
      errors.response(err, 'Sorry! We are unable to load integrations data!');
    } finally {
      this.pending = false;
    }
  }

  async getIntegrationRecordsByShop(shopId, tableStore) {
    if (!shopId) {
      throw new Error('shop not set');
    }

    try {
      tableStore.pending = true;
      tableStore.loaded = false;

      await this.integrationFetchController.abort();

      this.integrationFetchController = new AbortController();
      this.integrationFetchSignal = this.integrationFetchController.signal;

      const { data } = await webServiceProvider.get(
        `v1/shops/${shopId}/integrations`,
        this.integrationFetchSignal
      );

      transaction(() => {
        tableStore.data = [];

        for (let i = 0; i < data.length; i++) {
          let integrationObj = new IntegrationRecordObject(data[i]);

          this.data.set(data[i].accountId, integrationObj);
          tableStore.data.push(data[i].accountId);
        }

        tableStore.setDataMetadata(data.length);
      });
    } catch (err) {
      errors.response(err, 'Sorry! We are unable to load integrations data!');
      tableStore.setError('Sorry! We are unable to load integrations data!');
    }
  }

  async checkIfShopHasIntegration(shopId) {
    if (!shopId) {
      throw new Error('shop not set');
    }

    let key = `${shopId}-has-integration`;

    if (IntegrationCacheStore.getData(key) !== undefined) {
      return IntegrationCacheStore.getData(key);
    }

    try {
      await this.integrationFetchController.abort();

      this.integrationFetchController = new AbortController();
      this.integrationFetchSignal = this.integrationFetchController.signal;

      const { data } = await webServiceProvider.get(
        `v1/shop/${shopId}/integration/has-integration`,
        this.integrationFetchSignal
      );

      IntegrationCacheStore.setData(key, data);

      return data;
    } catch (err) {
      errors.response(err, 'Sorry! We are unable to load integrations data!');
    }
  }

  /**
   *
   * @param payload {object}
   * @param payload.shopId {number}
   * @param payload.alias {string}
   * @param payload.username {string}
   * @param payload.password {string}
   * @param payload.database {string}
   * @param payload.url {string}
   * @param [payload.defaultMigrationStartTimestamp] {string}
   * @returns {Promise<Response>}
   */
  async createGeotabIntegrationRecord(payload) {
    return webServiceProvider.post(
      `v1/shops/${payload.shopId}/integrations/geotab`,
      payload
    );
  }

  /**
   *
   * @param payload {object}
   * @param payload.shopId {number}
   * @param payload.alias {string}
   * @param payload.authorizationCode {string}
   * @param [payload.defaultMigrationStartTimestamp] {string}
   * @returns {Promise<Response>}
   */
  async createSmartcarIntegrationRecord(payload) {
    return webServiceProvider.post(
      `v1/shops/${payload.shopId}/integrations/smartcar`,
      payload
    );
  }

  getGeotabSyncStatus = async (
    { offset, limit, filter = {}, sort } = {},
    tableStore,
    shopId = ShopStore.currentShop.id
  ) => {
    tableStore.pending = true;
    tableStore.loaded = false;

    offset = !_.isNil(offset) ? offset : tableStore.offset;
    limit = !_.isNil(limit) ? limit : tableStore.limit;
    filter = filter || tableStore.filter;

    if (shopId === -1 && CurrentUserStore.user.role === 'admin') {
      shopId = undefined;
      return;
    }

    try {
      const params = {
        offset,
        limit,
        ...filter,
      };

      const { data, meta } = await webServiceProvider.getMany(
        `v1/integrations/geotab/${shopId}`,
        params
      );

      transaction(() => {
        tableStore.data.clear();

        tableStore.data.replace(data);
        this.data.set('syncStatus', {
          meta,
          data,
        });
      });
      tableStore.setDataMetadata(
        this.data.get('syncStatus').meta.total,
        offset,
        limit,
        sort,
        filter
      );
    } catch (err) {
      errors.response(err, 'Error in fetching data for Geotab Sync Status');
    }
  };

  handleMotiveOauth = async (code) => {
    try {
      await webServiceProvider.post('v1/motive-oauth', {
        code,
      });
    } catch (err) {
      // TODO: handle error, let user know
      // errors.response(err, 'Error in handling Motive integration OAuth code');
    }
  };
}

decorate(IntegrationStoreC, {
  getIntegrationRecordsByShop: action,
  getIntegrationAccountByShop: action,
  createIntegrationRecord: action,
  getGeotabSyncStatus: action,
  isReloaded: observable,
  modalIntegrationForm: observable,
  integrationAccount: observable,
});

const IntegrationStore = new IntegrationStoreC();
export default IntegrationStore;
