import React, { Component } from 'react';
import { Observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';

import { Button, List, Drawer, Popover, Tooltip, Icon, Result } from 'antd';

import { webServiceProvider } from 'shared';

import { calcMileage } from 'helpers/unitCalculations';
import CurrentUserStore from 'stores/CurrentUserStore';
import SingleTrip from 'containers/CarProfile/CarTripsView/map/SingleTrip';
import { Loading } from 'components';

const TripItem = styled(List.Item)`
  && {
    padding: 20px;
    display: flex;
    background: ${(props) => (props.selected ? '#f0f8ff' : 'inherit')};
  }
  && > div {
    flex: 1;
  }
  && > div > div {
    flex: 1;
  }

  && p {
    margin-bottom: 0px;
  }
  && .fuel {
    margin-bottom: 8px;
  }
`;

class TripCompairison extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      page: 1,
      loading: true,
      selected: null,
      total: 1,
      error: false,
    };
  }

  componentDidUpdate() {
    if (this.state.loading && this.props.visible && this.state.data === null) {
      this.getData();
    }
  }

  getData = async () => {
    const vin = this.props.car.vin;
    try {
      const data = await webServiceProvider.get(`v1/trips/groups?vin=${vin}`);
      this.setState({
        data: data.tripGroups,
        loading: false,
        total: data.tripGroups.length / 5,
      });
    } catch (e) {
      this.setState({ error: true, loading: false });
    }
  };

  onSelect = (bucketHash) => {
    this.setState({ selected: bucketHash });
  };

  changePagination = (page, pageSize) => {
    this.setState({ page: page });
  };

  render() {
    const { car } = this.props;

    return (
      <Drawer
        visible={this.props.visible}
        onClose={this.props.onCloseSimilarTrips}
      >
        {!this.state.error ? (
          <div>
            <h4>Select a group of trips you want to compare.</h4>
            <Button
              onClick={() =>
                this.props.history.push(
                  `/car/${car.id}/trips/match?${this.state.selected}`
                )
              }
              type="primary"
              block
              disabled={this.state.selected === null}
              style={{ marginBottom: '12px' }}
            >
              Show Trips
            </Button>
            {this.state.loading ? (
              <Loading />
            ) : (
              <List
                style={{ width: '100%' }}
                pagination={{
                  disabled: this.state.loading,
                  position: 'top',
                  pageSize: 5,
                  current: this.state.page,
                  onChange: this.changePagination,
                  total: this.state.total,
                  simple: true,
                }}
                loading={this.state.loading}
                dataSource={this.state.data}
                renderItem={(item) => (
                  <Observer>
                    {() => {
                      const distance =
                        item.distance && parseFloat(item.distance) > 0
                          ? `${calcMileage(
                              parseFloat(item.distance)
                            ).toReadable()} ${
                              CurrentUserStore.user.displayedOdometer
                            }`
                          : 'Unavailable';
                      return (
                        <TripItem
                          onClick={() => this.onSelect(item.bucket_hash)}
                          selected={this.state.selected === item.bucket_hash}
                        >
                          <Popover
                            placement="left"
                            trigger="click"
                            content={
                              <div
                                style={{
                                  height: '612px',
                                  width: '400px',
                                }}
                              >
                                <SingleTrip
                                  data={{
                                    location_polyline: item.waypoints.data,
                                  }}
                                  sorting={false}
                                  zoom={11}
                                ></SingleTrip>
                              </div>
                            }
                          >
                            <Tooltip title="click to show on map">
                              <Icon
                                type="global"
                                style={{
                                  fontSize: 30,
                                  marginRight: '6px',
                                }}
                              />
                            </Tooltip>
                          </Popover>
                          <div>
                            <h6>Amount of Similar Trips: {item.npool}</h6>
                            <p>
                              Last Driven:{' '}
                              {moment(item.last_trip_time * 1000)
                                .tz(CurrentUserStore.user.settings.timezone)
                                .format('L')}
                            </p>
                            <p>Distance: {distance}</p>
                          </div>
                        </TripItem>
                      );
                    }}
                  </Observer>
                )}
              />
            )}
          </div>
        ) : (
          <Result>No Data available</Result>
        )}
      </Drawer>
    );
  }
}

TripCompairison.propTypes = {
  car: PropTypes.object,
  visible: PropTypes.bool,
  onCloseSimilarTrips: PropTypes.func,
  page: PropTypes.number,
  history: PropTypes.object.isRequired,
};

export default withRouter(TripCompairison);
