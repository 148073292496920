import React, { useState, useEffect } from 'react';
import { Modal, Button, Typography, Input, Icon } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';

const { TextArea } = Input;

const TextAreaWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    bottom: 0;
    width: 16px;
    right: 10px;
    color: #d4d4d4;
    cursor: pointer;
  }
`;

const CustomTextArea = styled(TextArea)`
  font-size: 14px;
  resize: none;
  height: 200px;

  &.ant-input {
    cursor: pointer;
    padding-top: 6px;
    min-height: 99px;
    font-size: 13px;
    line-height: 15px;
  }
`;

const ModalHeader = styled.div`
  background-color: #02103d;
  color: white;
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .anticon-close {
    color: white;
    font-size: 20px;
  }
`;

const ModalBody = styled.div`
  padding: 24px;
`;

const CustomModal = styled(Modal)`
  .ant-modal-header {
    padding: 0;
    border-bottom: none;
  }

  .ant-modal-content {
    border-radius: 4px;

    .ant-modal-close {
      display: none;
    }
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    border-top: none;
    padding: 16px 24px;
  }
`;

const RestoreAssetModal = ({ visible, onCancel, onOk, isRestoring }) => {
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (visible) {
      setNotes('');
    }
  }, [visible]);

  const handleOk = () => {
    onOk(notes);
  };

  return (
    <CustomModal
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          data-test="restore-asset-button"
          key="ok"
          type="danger"
          ghost
          onClick={handleOk}
          loading={isRestoring}
        >
          Restore Asset(s)
        </Button>,
      ]}
      title={null}
      closeIcon={<Icon type="close" />}
    >
      <ModalHeader>
        Restore Asset(s)
        <Icon type="close" onClick={onCancel} />
      </ModalHeader>
      <ModalBody>
        <Typography.Paragraph strong>
          Are you sure you want to restore asset(s)?
        </Typography.Paragraph>
        <Typography.Paragraph>
          Restored assets will be restored back into your active fleet, resuming
          tracking and maintenance scheduling activity.
        </Typography.Paragraph>
        <Typography.Paragraph strong>
          Notes section (optional):
        </Typography.Paragraph>
        <TextAreaWrapper>
          <CustomTextArea
            id="restore-notes-textarea"
            data-test="restore-notes-textarea"
            placeholder="Notes about why vehicle is being restored..."
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
          <EditIcon
            onClick={(e) => {
              e.stopPropagation();
              // focus the textarea
              const textarea = document.getElementById(
                'restore-notes-textarea'
              );
              textarea.focus();
            }}
          />
        </TextAreaWrapper>
      </ModalBody>
    </CustomModal>
  );
};

RestoreAssetModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  isRestoring: PropTypes.bool,
};

RestoreAssetModal.defaultProps = {
  isRestoring: false,
};

export default RestoreAssetModal;
