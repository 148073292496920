export default [
  {
    id: 0,
    conversationData: [
      {
        type: 'sent',
        message:
          'hey Domnic, Pitstop report shows there are 5 critical and 10 major issues. Can you please check it?',
        sentAt: '3:08:35 PM',
      },
      {
        type: 'received',
        message:
          'Yes, confirm that vehicle A has 2 critical and 5 major. Vehicle B has 3 critical and 5 major. I will send vehicles back to store for check up',
        sentAt: '3:10:28 PM',
      },
      {
        type: 'received',
        message:
          'hey, just let you know that a technician confirms those issues',
        sentAt: '3:20:28 PM',
      },
      {
        type: 'sent',
        message: 'Great! Pitstop do good job',
        sentAt: '3:35:45 PM',
      },
    ],
  },
  {
    id: 1,
    conversationData: [
      {
        type: 'sent',
        message: 'Hi, can you check the cranking voltage? Pitstop has an alert',
        sentAt: '3:03:28 PM',
      },
      {
        type: 'received',
        message: 'Yes, I will do.',
        sentAt: '3:05:47 PM',
      },
      {
        type: 'received',
        message: 'Hi sir, I confirm the vehicle has a motor problem',
        sentAt: '3:06:47 PM',
      },
      {
        type: 'sent',
        message: 'Alright, please send the vehicle back to system',
        sentAt: '3:07:52 PM',
      },
      {
        type: 'received',
        message:
          'Hey, the vehicle has been sent back to the store for checking',
        sentAt: '3:12:36 PM',
      },
      {
        type: 'sent',
        message:
          'Hey, Pitstop alert vehicle A has a brake issue. Please check it! These predictive alerts are helpful for us',
        sentAt: '3:13:04 PM',
      },
    ],
  },
  {
    id: 2,
    conversationData: [
      {
        type: 'sent',
        message: 'Hi, this is your fleet manager',
        sentAt: '3:08:35 PM',
      },
      {
        type: 'received',
        message: 'Hi, vehicle B has an oil leak. Please advise!',
        sentAt: '3:10:28 PM',
      },
      {
        type: 'sent',
        message:
          'Hey, have you do oil change for vehicle B? Can you send the vehicle to shop ABC?',
        sentAt: '3:11:25 PM',
      },
      {
        type: 'received',
        message:
          'Yes, I did. Oil change for vehicle B was implemented yesterday',
        sentAt: '3:12:36 PM',
      },
      {
        type: 'sent',
        message:
          'Please set up a maintenance service for Oil Change every 5,000km to all vehicles!',
        sentAt: '3:13:28 PM',
      },
    ],
  },
];
