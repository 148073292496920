/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import { Input, Icon } from 'antd';

export const columnTextFilterProps = (dataIndex, name) => {
  let inputTimer = null;
  let dropdownArgs = {};
  let dropDownComponent = (
    <Input
      placeholder={`Search ${name}`}
      value={dropdownArgs.selectedKeys && dropdownArgs.selectedKeys[0]}
      onChange={e => {
        dropdownArgs.setSelectedKeys(e.target.value ? [e.target.value] : []);
        delayedConfirm(dropdownArgs.confirm, 300);
      }}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
  );

  return {
    filterDropdown: args => {
      dropdownArgs = args;
      return <div style={{ padding: 8 }}>{dropDownComponent}</div>;
    },
    filterIcon: filtered => (
      <Icon
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined, display: 'none' }}
      />
    ),
    dropDownComponent: dropDownComponent
  };

  function delayedConfirm(cb, timeout) {
    clearTimeout(inputTimer);
    inputTimer = setTimeout(cb, timeout);
  }
};
