import React, { Component } from 'react';
import _ from 'lodash';

import { observer } from 'mobx-react';

import { Row, Col } from 'antd';

import { IssueStore, ShopStore, PitstopCookie } from 'stores';

import { DealershipChooser } from 'components';
import IssuesTable from './IssuesTable';

class IssuesPage extends Component {
  render() {
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <h1>Issues</h1>
          </Col>

          <Col span={24}>
            <DealershipChooser />
          </Col>

          {/*<Col span={24}>
            <IssueLegend />
    </Col>*/}
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <IssuesTable
              isEnabled={ShopStore.currentShop.id !== -1}
              id={`shop-${ShopStore.currentShop.id}`}
              fetchData={(params, store, startDate, endDate) => {
                // dont get all issues if show only 1 table is not selected
                if (_.isNil(PitstopCookie.get('isAllToOne')) && _.isNil(params?.filter?.source)) {
                  return;
                }
                IssueStore.getIssuesForShopId(
                  {
                    ...params,
                    dontCheckPms: true,
                  },
                  store,
                  ShopStore.currentShop.id,
                  startDate,
                  endDate
                );
              }}
              downloadCsv={async (params, startDate, endDate) => {
                if (!_.isNil(startDate)) {
                  params.startDate = startDate;
                }
                if (!_.isNil(endDate)) {
                  params.endDate = endDate;
                }
                return IssueStore.downloadIssuesCsv(params, ShopStore.currentShop.id);
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default observer(IssuesPage);
