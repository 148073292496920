import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { Empty } from 'antd';
import { BarChart } from 'shared/PitstopUI/PitstopChart';

import { CarStore, ReportsDataStore } from 'stores';

class VehicleScore extends Component {
  get data() {
    let report = ReportsDataStore.vehiclesScoreReport.data;

    report = _.orderBy(report, 'score', 'asc');

    let data = _.uniqBy([...report], 'carName');
    data = _.filter(data, (d) => d['score']);

    if (!_.isEmpty(data)) {
      data = _.map(data, (d) => {
        let label = d['carName'] || d['vin'];

        return {
          name: label,
          y: d['score'],
          color: '#E8575A',
        };
      });
    }

    return {
      key: 'Vehicle Score',
      xAxis: 'Unit ID',
      yAxis: 'Score (Higher is better)',
      value: data,
    };
  }

  render() {
    if (CarStore.demo === false) return <Empty />;
    return (
      <>{_.isEmpty(this.data.value) ?
          <p>Please upload service or work order data to get access to this insight.</p> :

          <BarChart
            loaded={ReportsDataStore.vehiclesScoreReport.loaded}
            data={this.data}
          />
        }
      </>
    );
  }
}

export default observer(VehicleScore);
