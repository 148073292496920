import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { Layout, Menu, Icon, Button, Avatar, Badge, Dropdown } from 'antd';

import { chatUtils } from 'helpers';

import { AppStore, CurrentUserStore, ShopStore } from 'stores';

const { Header: AntdHeader } = Layout;

const StyledAntDHeader = styled(AntdHeader)`
  &&& {
    padding: 0px 20px;
    position: fixed;
    top: 0;
    z-index: 200;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;

    @media (max-width: 576px) {
      padding: ${(props) =>
        props.isexcludedpathname === 'false' ? '0px' : '0px 20px'};
      position: ${(props) =>
        props.isexcludedpathname === 'false' && 'relative'};
      flex-direction: ${(props) =>
        props.isexcludedpathname === 'false' && 'column'};
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  &&& {
    width: 120px;
    height: 45px;
    margin: auto;

    span {
      width: 100%;
      height: 100%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: scale-down;
    }
  }
`;

const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;

  #menu {
    width: 100%;
  }
`;

const MenuStyled = styled(Menu)`
  &&& {
    line-height: 64px;
    float: right;

    .ant-menu-item {
      padding: 0px 20px;
    }

    @media (max-width: 576px) {
      .ant-menu-item {
        padding: 0px 10px;
      }
    }
  }
`;

const StyledBadge = styled(Badge)`
  && {
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;

    p {
      font-size: 12px;
    }
  }
`;

const defaultShopLogoUrl = require('assets/logos/pitstop-logo.png');

class Header extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    toggleSideBar: PropTypes.func,
  };

  defaultShopLogoUrl =
    'https://dashboard-campaign.s3.us-east-2.amazonaws.com/plogo.svg';

  toggleSidebar = async () => {
    this.props.toggleSideBar();
  };

  getAppUnreadMessagesCounts = () => {
    let unreadMessages = [];

    let messagesKeys = Array.from(CurrentUserStore.notifications.keys());

    _.forEach(messagesKeys, (key) => {
      let unreadMessagesCount = chatUtils.getAppUnreadMessagesCounts(
        CurrentUserStore.user.name,
        CurrentUserStore.notifications.get(key).data
      );

      let user =
        CurrentUserStore.user.role === 'dealership'
          ? chatUtils.getDriverInfo(
              toJS(CurrentUserStore.fleetManagersDrivers).data,
              key
            )
          : chatUtils.getFleetManagerInfo(
              toJS(CurrentUserStore.fleetManagersDrivers).data
            );

      if (unreadMessagesCount > 0) {
        unreadMessages.push({
          userId: key,
          name: `${user.first_name} ${user.last_name}`,
          data: CurrentUserStore.notifications.get(key).data,
        });
      }
    });

    return unreadMessages;
  };

  getMenu = () => {
    let unreadMessages = this.getAppUnreadMessagesCounts();

    if (_.isEmpty(unreadMessages))
      return (
        <Menu>
          <Menu.Item>You have no messages</Menu.Item>
        </Menu>
      );

    return (
      <Menu>
        {_.map(unreadMessages, (message) => {
          let unreadMessagesCount = 0;

          unreadMessagesCount = chatUtils.getAppUnreadMessagesCounts(
            CurrentUserStore.user.name,
            message.data
          );

          if (unreadMessagesCount === 0) return <></>;

          return (
            <Menu.Item key={message.userId}>
              <Link to="/pages/chat">
                <b>{`${message.name.capitalizeFirstLetter()}`}</b> sent{' '}
                <b>{`${unreadMessagesCount}`}</b> messages
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  filterUnreadMessages = () => {
    let messages =
      CurrentUserStore.user.role === 'dealership'
        ? toJS(CurrentUserStore.driversChatHistory.data)
        : toJS(CurrentUserStore.user.messages);

    if (messages) {
      let res = _.filter(
        CurrentUserStore.user.role === 'dealership' ? messages : messages.data,
        (message) => {
          let unreadMessagesCount = chatUtils.getAppUnreadMessagesCounts(
            CurrentUserStore.user.name,
            message.data
          );

          return unreadMessagesCount !== 0;
        }
      );

      return res;
    }

    return [];
  };

  get adminHeader() {
    // let unreadMessages = this.getAppUnreadMessagesCounts();

    let headers = [
      <Menu.Item key="/FAQ" onClick={() => this.props.history.push('/FAQ')}>
        Learning Center
      </Menu.Item>,
      // <Menu.Item
      //   key="/billing"
      //   onClick={() => this.props.history.push('/billing')}
      // >
      //   Billing
      // </Menu.Item>,
      <Menu.Item
        key="/profile"
        onClick={() => this.props.history.push('/profile')}
      >
        Profile
      </Menu.Item>,
    ];

    if (!AppStore.isOnGeotabDashboard()) {
      headers = [
        ...headers,
        <Menu.Item
          key={4}
          onClick={() => {
            AppStore.showLogoutModal.set(true);
          }}
        >
          {AppStore.loggedIn.get() ? 'Logout' : 'Login'}
        </Menu.Item>,
      ];
    }

    return headers;
  }

  get userHeader() {
    let unreadMessages = this.getAppUnreadMessagesCounts();

    let headers = [
      <Menu.Item key="/notifications">
        <Dropdown overlay={this.getMenu()} placement="bottomCenter" arrow>
          <span>
            Notifications
            <StyledBadge count={unreadMessages.length} />
          </span>
        </Dropdown>
      </Menu.Item>,
      <Menu.Item
        key="/profile"
        onClick={() => this.props.history.push('/profile')}
      >
        {CurrentUserStore.user.role === 'technician' ? 'Settings' : 'Profile'}
      </Menu.Item>,
      // <Menu.Item
      //   key="/billing"
      //   onClick={() => this.props.history.push('/billing')}
      // >
      //   Billing
      // </Menu.Item>,
    ];

    if (!AppStore.isOnGeotabDashboard()) {
      headers = [
        ...headers,
        <Menu.Item
          key={4}
          onClick={() => {
            AppStore.showLogoutModal.set(true);
          }}
        >
          {AppStore.loggedIn.get() ? 'Logout' : 'Login'}
        </Menu.Item>,
      ];
    }

    return headers;
  }

  get noAuthHeader() {
    return [];
  }

  isexcludedpathname = () => {
    let { pathname } = this.props.history.location;
    return ['/login', '/register'].includes(pathname);
  };

  render() {
    const { location } = this.props;
    let sections = [];
    if (CurrentUserStore.loaded) {
      if (!CurrentUserStore.user) {
        sections = this.noAuthHeader;
      } else {
        switch (CurrentUserStore.user.role) {
          case 'admin':
          case 'dealership':
            sections = this.adminHeader;
            break;
          case 'user':
          default:
            sections = this.userHeader;
        }
      }
    }

    let shopLogo = ShopStore.currentShop.logoUrl || defaultShopLogoUrl;

    return (
      <StyledAntDHeader
        isexcludedpathname={this.isexcludedpathname().toString()}
        className="header"
      >
        <StyledAvatar
          shape="square"
          alt="logo"
          src={shopLogo}
          onClick={() =>
            this.props.history.push(AppStore.loggedIn.get() ? '/' : '/login')
          }
        />

        <MenuContainer>
          <Button
            ghost
            size="large"
            onClick={() => this.toggleSidebar()}
            style={{
              color: 'white',
              borderWidth: '0px',
              margin: 'auto 0px',
            }}
          >
            <Icon
              type="menu"
              style={{
                fontSize: 24,
              }}
            />
          </Button>

          <div id="menu">
            <MenuStyled
              theme="dark"
              mode="horizontal"
              selectedKeys={[location.pathname]}
            >
              {sections}
            </MenuStyled>
          </div>
        </MenuContainer>
      </StyledAntDHeader>
    );
  }
}

export default withRouter(observer(Header));
