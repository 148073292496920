import React, { Component } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import styled from 'styled-components';

import { Empty, Select, Switch } from 'antd';
import { GroupedBarChart } from 'shared/PitstopUI/PitstopChart';

import { CarStore, ReportsDataStore } from 'stores';

const ChartOptions = styled.div`
  display: flex
`;

const ChartSwitches = styled.div`
  width: 15%
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 2vw;
  margin: 0 1vw 1vh 1vw;

`;

class EngineIdleScore extends Component {
  constructor(props){
    super(props);
    this.state = {dataType: 'cumulative_idle_time_past_month', chartData: null, showIdleTime: false, showIdleFrac: true};
  }
  changeShowIdleTime = () => {
    //If IdleFrac is on, then allow IdleTime to be turned on and off freely, otherwise turn IdleFrac on if you're turning IdleTime off so that
    //The chart is never blank
    this.state.showIdleFrac ?
    this.setState({showIdleTime: !this.state.showIdleTime})
    : this.setState({showIdleTime: !this.state.showIdleTime, showIdleFrac: true});
    
  }
  changeShowIdleFrac = () => {
    this.state.showIdleTime ? 
    this.setState({showIdleFrac: !this.state.showIdleFrac})
    : this.setState({showIdleFrac: !this.state.showIdleFrac, showIdleTime: true});
  }
  changeScore = (event) => {
    this.setState({dataType: event});
  }

  get data() {
    let timeColour = '#b50733';
    let fracColour = '#16b507';
    let report = ReportsDataStore.engineIdleScoreReport.data;
    report = _.orderBy(report, 'cumulative_idle_time_past_month', 'asc');
    let data = _.uniqBy([...report], 'carName');
    data = _.orderBy(data, 'cumulative_idle_frac_past_month', 'asc');
    data = _.filter(data, (item) => item['cumulative_idle_frac_past_month'] > 0 && item['cumulative_idle_time_past_month'] > 0);
    let displayScale;
    if (!_.isEmpty(data)){
      let carNames = data.map(item => {
        return (
          !_.isNil(item['carName']) ? item['carName'] : item['vin']
        );
      });
      let xAxis = {categories: carNames};
      let idleTime = this.state.dataType === 'cumulative_idle_time_past_month' ?
                        data.map(item => item['cumulative_idle_time_past_month']) :
                        data.map(item => item['cumulative_idle_time_past_week']);
    
      if (Math.max(...idleTime) < 60){
        displayScale = 'Seconds';
      } else if (Math.max(...idleTime) < 5400) {
        displayScale = 'Minutes';
        idleTime = idleTime.map(item => item/60);
      } else {
        displayScale = 'Hours';
        idleTime = idleTime.map(item => item/3600);
      }
      
      let idleFrac = this.state.dataType === 'cumulative_idle_time_past_month' ? 
                      data.map(item => item['cumulative_idle_frac_past_month']) :
                      data.map(item => item['cumulative_idle_frac_past_week']);
      let series;
      if (this.state.showIdleTime && this.state.showIdleFrac){
        series = [{name: 'Idle Time', data: idleTime, color: timeColour, yAxis: 0}, {name:'Idle Percentage', data: idleFrac, color: fracColour, yAxis: 1}];
      } else if (this.state.showIdleTime){
        series = [{name: 'Idle Time', data: idleTime, color: timeColour}];
      } else if (this.state.showIdleFrac){
        series = [{name:'Idle Percentage', data: idleFrac, color: fracColour}];
      }
      let yAxis = [];
      const timeYAxis = {
                          labels: {
                            enabled: true,
                            format: `{value} ${displayScale}`,
                            style: {
                              color: timeColour
                            }
                          },
                          title: {
                            text: `Idle Time ${displayScale}`,
                            style: {
                              color: timeColour
                            }
                          },
                        };
      const fracYAxis = {
                          labels: {
                            enabled: true,
                            format: '{value} %',
                            style:{
                              color: fracColour
                            }
                          },
                          title: {
                            text: 'Idle Percentage',
                            style: {
                              color: fracColour
                            }
                          },
                          opposite: true
                        };
      if (this.state.showIdleTime && this.state.showIdleFrac){
        yAxis = [timeYAxis, fracYAxis];
      } else if (this.state.showIdleTime){
        yAxis = [timeYAxis];
      } else if (this.state.showIdleFrac){
        yAxis = [{...fracYAxis, opposite: false}];
      }
      return {xAxis: xAxis, series: series, yAxis: yAxis};
    }
    return {
      key: 'Engine Idle Time',
      xAxis: 'Unit ID',
      yAxis: (this.state.dataType === 'cumulative_idle_time_past_month' || this.state.dataType === 'cumulative_idle_time_past_week') ? 
              `Time Spent Idling (${displayScale})` : 'Idle Percentage (0 to 100)',
      value: data,
    };
  }

  render() {
    if (CarStore.demo === false) return <Empty />;
    return (
      <>
        <GroupedBarChart
          loaded={ReportsDataStore.engineIdleScoreReport.loaded}
          data={this.data}
        />
        {ReportsDataStore.engineIdleScoreReport.loaded ? <ChartOptions>
          <div>
            <p>Time Period</p>
            <Select name='data_type' id='data_type' onChange={this.changeScore} value={this.state.dataType}>
              <Select.Option value='cumulative_idle_time_past_month'>Past Month</Select.Option>
              <Select.Option value='cumulative_idle_time_past_week'>Past Week</Select.Option>
            </Select>
          </div>
          <ChartSwitches>
            <Switch checked={this.state.showIdleFrac} onChange={this.changeShowIdleFrac}/>
            <p>Idle Percentage</p>
            <Switch checked={this.state.showIdleTime} onChange={this.changeShowIdleTime}/>
            <p>Idle Time</p>
          </ChartSwitches>
          </ChartOptions>: null}
      </>
    );
  }
}

export default observer(EngineIdleScore);
