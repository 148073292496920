import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { Form, Select, DatePicker, Checkbox, Input, Button } from 'antd';

import { ContactStore, AppStore, ShopStore } from 'stores';

import ContactFormModal from 'components/Contacts/ContactFormModal';

const { Option } = Select;
const { TextArea } = Input;

class WrappedRecipientForm extends Component {
  state = {
    cars: [],
    contacts: [],
    savingContact: false,
  };

  CONTACT_FORM_MODAL_ID = 'contact_form_modal_id';

  async componentDidMount () {
    this.props.setCallables({
      handleSubmit: this.handleSubmit
    });

    // For now, populate state.cars with the car that was passed in.
    if (!isEmpty(this.props.initialValue.car)) {
      this.setState({
        cars: [this.props.initialValue.car]
      });
    }

    this.fetchShopContacts();
  }

  /**
   * Fetch shop contacts and populate
   */
  fetchShopContacts = async () => {
    try {
      const contacts = await ContactStore.getShopContacts(ShopStore.currentShop.id);
      this.setState({ contacts: contacts || [] });
    } catch (error) {
      AppStore.addError('Error loading contacts');
    }
  }

  handleSubmit = async () => {
    return this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) throw err;
      return values;
    });
  };

  onSubmitContactForm = async (data) => {
    try {
      await this.setState({ savingContact: true });
      // create a new contact
      await ContactStore.createShopContact({
        ...data,
        shopId: ShopStore.currentShop.id
      });
      AppStore.addSuccess('Contact saved successfully');
      await this.fetchShopContacts();
      AppStore.openModals.set(this.CONTACT_FORM_MODAL_ID, false);
    } catch (error) {
      console.log(error);
      AppStore.addError('Error to save contact: ' + error.message);
    } finally {
      await this.setState({ savingContact: false });
    }
  }

  showModalToAddNewContact = () => {
    AppStore.openModals.set(this.CONTACT_FORM_MODAL_ID, true);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const carOptions = this.state.cars.map((car) => (
      <Option key={car.id} value={car.id}>
        {`${car.mmy} - ${car.vin}`}
      </Option>
    ));

    const contactOptions = this.state.contacts.map((contact) => (
      <Option key={contact.id} value={contact.id}>
        {`${contact.name} - ${contact.email}`}
      </Option>
    ));

    const initialCar = this.props.initialValue.car;
    const initialAssignedTo = this.props.initialValue.assignedTo;

    return (
      <>
        <Form>
          <Form.Item label="Select Vehicle">
            {getFieldDecorator('vehicle', {
              initialValue: initialCar?.id,
              rules: [
                {
                  required: true,
                  message: 'Please select the vehicle!'
                }
              ]
            })(<Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select a vehicle"
              optionFilterProp="children"
              onChange={(value) => this.props.onChangeValue('vehicle', value)}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {carOptions}
            </Select>)}
          </Form.Item>
          {this.props.showAppointmentTime && (
            <Form.Item label="Appointment Time">
              {getFieldDecorator('startTimeAppointment', {
                initialValue: this.props.initialValue.startTimeAppointment,
                rules: [
                  {
                    required: true,
                    message: 'Please select the appointment time.'
                  }
                ]
              })(<DatePicker
                style={{ width: '100%' }}
                onChange={(value) => this.props.onChangeValue('startTimeAppointment', value)}
                showTime
              />)}
            </Form.Item>
          )}
          <Form.Item label="Assigned To">
            {getFieldDecorator('assignedTo', {
              initialValue: initialAssignedTo?.id,
              rules: [
                {
                  required: true,
                  message: 'Please select a contact.'
                }
              ]
            })(<Select
              showSearch
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Select a contact"
              optionFilterProp="children"
              onChange={(value) => this.props.onChangeValue('assignedTo', value)}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {contactOptions}
            </Select>)}
            <Button
              icon="plus"
              onClick={() => this.showModalToAddNewContact()}
            >New Contact</Button>
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('attachReport', {
              checked: this.props.initialValue.attachReport,
            })(
              <Checkbox
                checked={this.props.initialValue.attachReport}
                onChange={(e) => this.props.onChangeValue('attachReport', e.target.checked)}
              >Attach Vehicle Health Report as PDF in the email</Checkbox>
            )}
          </Form.Item>

          <Form.Item label="Aditional Information">
            {getFieldDecorator('description', {
              initialValue: this.props.initialValue.description
            })(
              <>
                <TextArea
                  rows={4}
                  style={{ marginTop: 16 }}
                  onChange={(e) => this.props.onChangeValue('description', e.target.value)}
                />
                <span>This will be automatically added in the body of the email with the appointment details.</span>
              </>
            )}
          </Form.Item>
        </Form>

        <ContactFormModal
          modalId={this.CONTACT_FORM_MODAL_ID}
          saving={this.state.savingContact}
          title={'Add Vendor'}
          defaultData={{}}
          onCancel={() => {
            AppStore.openModals.set(this.CONTACT_FORM_MODAL_ID, false);
          }}
          onSubmit={(data) => {
            this.onSubmitContactForm(data);
          }}
        />
      </>
    );
  }
}

WrappedRecipientForm.propTypes = {
  showAppointmentTime: PropTypes.bool,
  initialValue: PropTypes.object,
  setCallables: PropTypes.func,
  form: PropTypes.object,
  onChangeValue: PropTypes.func
};

WrappedRecipientForm.defaultProps = {
  showAppointmentTime: true,
  initialValue: {
    car: {},
    startTimeAppointment: null,
    assignedTo: {},
    attachReport: false,
    description: ''
  },
  setCallables: () => { },
  form: {},
  onChangeValue: () => { }
};

const RecipientForm = Form.create({ name: 'register' })(WrappedRecipientForm);

export default RecipientForm;
