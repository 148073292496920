import { observable, decorate, action, computed } from 'mobx';
import _ from 'lodash';

class AsyncObject {
  loaded = false;
  pending = false;
  isError = false;
  errorMessage = '';
  data = null;

  get isEmpty() {
    return _.isNil(this.data) || _.isEmpty(this.data);
  }

  setData = data => {
    this.data = data;
    this.loaded = true;
    this.pending = false;
  };

  setError = message => {
    this.loaded = true;
    this.pending = false;
    this.isError = true;
    this.errorMessage = message;
  };

  resetError = () => {
    this.isError = false;
  };

  reset = () => {
    this.loaded = false;
    this.pending = false;
    this.isError = false;
    this.errorMessage = '';
    this.data = null;
  };
}

decorate(AsyncObject, {
  pending: observable,
  loaded: observable,
  isError: observable,
  errorMessage: observable,
  data: observable,
  setError: action,
  resetError: action,
  reset: action,
  isEmpty: computed
});

export default AsyncObject;
