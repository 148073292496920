import axios from 'axios';
import { OktaConfig } from './OktaConfig';

// Function to create a Base64 URL safe encoding
const base64UrlEncode = (str) => {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

// Function to generate a code verifier and code challenge
const generateCodeVerifierAndChallenge = async () => {
  const codeVerifierLength = 64; // Ensure this length is between 43 and 128
  const array = new Uint8Array(codeVerifierLength);
  window.crypto.getRandomValues(array);
  const codeVerifier = Array.from(array, byte => ('0' + byte.toString(16)).slice(-2)).join('').slice(0, codeVerifierLength);

  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
  const codeChallenge = base64UrlEncode(hashBuffer);

  return { codeVerifier, codeChallenge };
};

const OktaAuthService = {
  getAuthorizationUrl: async () => {
    const { codeVerifier, codeChallenge } = await generateCodeVerifierAndChallenge();
    sessionStorage.setItem('OktaCodeVerifier', codeVerifier); // Store codeVerifier in sessionStorage
    const authUrl = `${OktaConfig.domain}/oauth2/default/v1/authorize?client_id=${OktaConfig.clientId}&response_type=code&scope=openid%20profile%20email&redirect_uri=${encodeURIComponent(OktaConfig.redirectUri)}&state=state-1234&nonce=nonce-1234&code_challenge=${codeChallenge}&code_challenge_method=S256`;
    return authUrl;
  },

  getToken: async () => {
    const codeVerifier = sessionStorage.getItem('OktaCodeVerifier'); // Retrieve codeVerifier from sessionStorage
    const authCode = sessionStorage.getItem('OktaAuthCode');
    // sessionStorage.removeItem('OktaCodeVerifier'); // Clear codeVerifier from sessionStorage
    try {
      const params = new URLSearchParams();
      params.append('grant_type', 'authorization_code');
      params.append('code', authCode);
      params.append('redirect_uri', OktaConfig.redirectUri);
      params.append('client_id', OktaConfig.clientId);
      params.append('code_verifier', codeVerifier);

      const response = await axios.post(`${OktaConfig.domain}/oauth2/default/v1/token`, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error getting token:', error.response ? error.response.data : error);
      throw error;
    }
  },

  getUserInfo: async () => {
    const oktaTokenString = sessionStorage.getItem('OktaToken');
    const oktaToken =  oktaTokenString ? JSON.parse(oktaTokenString) : null;
    try {
      const response = await axios.get(`${OktaConfig.domain}/oauth2/default/v1/userinfo`, {
        headers: {
          Authorization: `Bearer ${oktaToken.access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error getting user info:', error);
      throw error;
    }
  },

  signOut: async () => {
    const oktaTokenString = sessionStorage.getItem('OktaToken');
    const oktaToken =  oktaTokenString ? JSON.parse(oktaTokenString) : null;
    const sessionKeys = Object.keys(sessionStorage);
    const localKeys = Object.keys(localStorage);
    const sessionOktaKeys = sessionKeys.filter(key => key.startsWith('Okta'));
    const localOktaKeys = localKeys.filter(key => key.startsWith('Okta'));
    if(localOktaKeys.length !== 0 || sessionOktaKeys.length !== 0) {
      

      sessionOktaKeys.forEach(key => {
        sessionStorage.removeItem(key);
      });

      localOktaKeys.forEach(key => {
        localStorage.removeItem(key);
      });

      await axios.delete(`${OktaConfig.domain}/api/v1/sessions/me`, {
        headers: {
          Authorization: `Bearer ${oktaToken.access_token}`,
        },
      });

      const logoutUrl = `${OktaConfig.domain}/oauth2/default/v1/logout?id_token_hint=${oktaToken.id_token}&post_logout_redirect_uri=${encodeURIComponent(OktaConfig.postLogoutRedirectUri)}`;

      // Redirect the user to the logout URL
      window.location.href = logoutUrl;
    }
  }
}

export default OktaAuthService;
