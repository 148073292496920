/**
Abstract Class for States
**/
import { observable, decorate, action } from 'mobx';
import _ from 'lodash';

class AbstractStore {
  pending = false;
  loaded = false;
  data = new Map();

  MESSAGE = 'Loading';

  setData = (data) => {
    _.forEach(data, (val, key) => {
      this.data.set(key, val);
    });

    this.pending = false;
    this.loaded = true;
  };

  reset = () => {
    this.pending = false;
    this.loaded = false;
    this.data.clear();
  };
}

export default decorate(AbstractStore, {
  pending: observable,
  loaded: observable,
  data: observable,
  setData: action,
});
