import { CarStore } from 'stores';

export const getPidName = pid => {
  const PID_NAMES = {
    '2102': 'DTC',
    '2103': 'Fuel System 1 Status',
    '214F': 'Fuel System 2 Status',
    '2100': 'Mass Airflow Sensor',
    '2104': 'Calculated Load Value (%)',
    '2105': 'Engine Coolant Temperature (C)',
    '2106': 'Short Term Fuel Trim - Bank1 (%)',
    '2107': 'Long Term Fuel Trim - Bank1 (%)',
    '2108': 'Short Term Fuel Trim - Bank2 (%)',
    '2109': 'Long Term Fuel Trim - Bank2 (%)',
    '210C': 'Engine RPM (/min)',
    '210D': 'Vehicle Speed Sensor (km/h)',
    '210E': 'Ignition Advance (degrees)',
    '210F': 'Intake Air Temperature (C)',
    '2110': 'Mass Air Flow Sensor (g/s)',
    '2111': 'Absolute Throttle Position (%)',
    '2142': 'Battery Voltage',
    '2131': 'Distance Traveled Since Codes Cleared',
    '213C': 'Catalyst Temperature: Bank 1, Sensor 1',
    '214C': 'Commanded throttle actuator',
    '2121': 'Distance traveled with malfunction indicator lamp (MIL) on',
    '2119': 'Oxygen Sensor 6, A: Voltage, B: Short term fuel trim',
    '2146': 'Ambient air temperature',
    '2145': 'Relative throttle position',
    '2118': 'Oxygen Sensor 5, A: Voltage, B: Short term fuel trim',
    '212F': 'Fuel Tank Level Input',
    '2144': 'Fuel–Air commanded equivalence ratio',
    '2115': 'Oxygen Sensor 2, A: Voltage, B: Short term fuel trim',
    '2143': 'Absolute load value',
    '2150': 'Maximum value for air flow rate from mass air flow sensor',
    '2133': 'Absolute Barometric Pressure',
    '211C': 'OBD standards this vehicle conforms to',
    '212E': 'Commanded evaporative purge',
    '2114': 'Oxygen Sensor 1, A: Voltage, B: Short term fuel trim',
    '210A': 'Fuel pressure (gauge pressure)',
    '2101':
      'Monitor status since DTCs cleared. (Includes malfunction indicator lamp (MIL) status and number of DTCs.)',
    '213D': 'Catalyst Temperature: Bank 2, Sensor 1',
    '2149': 'Accelerator pedal position D',
    '211F': 'Run time since engine start',
    '2147': 'Absolute throttle position B',
    '2132': 'Evap. System Vapor Pressure',
    '2151': 'Fuel Type',
    '210B': 'Intake manifold absolute pressure',
    '2113': 'Oxygen sensors present (in 2 banks)',
    'crankingVoltage': 'Cranking Voltage',
    'tirePressureRearLeft': 'Tire Pressure Rear Left',
    'tirePressureRearRight': 'Tire Pressure Rear Right',
    'tirePressureFrontLeft': 'Tire Pressure Front Left',
    'tirePressureFrontRight': 'Tire Pressure Front Right',
    'gearPosition': 'Gear Position',
    'deviceTotalFuel': 'Device Total Fuel',
    'deviceTotalIdleFuel': 'Device Total Idle Fuel',
    'fuelLevelPercentage': 'Fuel Level Percentage (%)',
    'passengerOccupancy': 'Passenger Occupancy',
    'totalFuelUsed': 'Total Fuel Used',
    'DieselExhaustFluid': 'Diesel Exhaust Fluid'
  };

  return `${PID_NAMES[pid] || pid}`;
};

export const getCar = carId => {
  return CarStore.data.get(carId);
};

export const getPathName = history => {
  return history.location.pathname;
};
