import { decorate, computed, action } from 'mobx';
import { webServiceProvider } from 'shared';
import AbstractStore from './abstract/AbstractStore';

class CarStatusStore extends AbstractStore {
  defaultStatusList = [
    { key: 'new', description: 'Running', color: '#5BB668', is_default: true },
    { key: 'open-work-order', description: 'Open Work Order', color: '#437BF6' },
    { key: 'repair-in-progress', description: 'Repair in Progress', color: '#ED7B33' },
    { key: 'other', description: 'Other - Blocked', color: '#E53224' }
  ];

  shopStatuses = [];
  isLoadingStatuses = false;

  get getIsLoadingStatuses () {
    return this.isLoadingStatuses;
  }

  get getDefaultStatusList() {
    return this.defaultStatusList;
  }

  get getShopStatuses() {
    return this.shopStatuses;
  }

  fetchShopCarStatuses = async (shopId) => {
    if (!shopId || shopId === '-1' || shopId === -1) {
      return;
    }
    try {
      this.isLoadingStatuses = true;
      const { data: { rows } } = await webServiceProvider.get(`v1/shops/${shopId}/statuses`);
      if (!rows || rows.length === 0) {
        // create default statuses
        await this.updateShopStatuses(shopId, this.getDefaultStatusList);
        return this.fetchShopCarStatuses(shopId);
      }
      this.shopStatuses = rows;
      return rows;
    } catch (error) {
      console.log('Error fetching shop car statuses');
      console.error(error);
    } finally {
      this.isLoadingStatuses = false;
    }
  }

  updateShopStatuses = async (shopId, statuses) => {
    try {
      const { data: { rows } } = await webServiceProvider.put(`v1/shops/${shopId}/statuses`, { statuses });
      this.shopStatuses = rows;
      return rows;
    } catch (error) {
      throw error;
    }
  }
}

decorate(CarStatusStore, {
  getDefaultStatusList: computed,
  getShopStatuses: computed,
  getIsLoadingStatuses: computed,
  updateShopStatuses: action,
  fetchShopCarStatuses: action
});

var CSStore = new CarStatusStore();
export default CSStore;
