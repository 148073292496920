import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Spin, Alert, Card } from 'antd';
import { CarObject } from 'stores/Classes';
import _ from 'lodash';
import { computed, decorate } from 'mobx';

class CarAppointments extends Component {
  async componentDidMount() {
    await this.getCarAppointments();
  }

  getCarAppointments = async () => {
    const { car } = this.props;

    if (!car.service_appointment.loaded) {
      await car.getAppointments();
    }

    if (!car.routineAppointments.loaded) {
      await car.getRoutineAppointments();
    }
  };

  get formattedAppointments() {
    const { car } = this.props;

    let appointments = _.map(car.service_appointment.data, (item) => {
      return {
        appointmentDate: moment(item.appointmentDate).format('lll'),
        state: item.state,
        comments: item.comments,
      };
    });

    let routineAppointments = _.map(car.routineAppointments.data, (item) => {
      return {
        date: moment(item.appointment.appointmentDate).format('lll'),
        state: item.title,
        comments: item.appointment.comments,
      };
    });

    return [...appointments, ...routineAppointments];
  }

  render() {
    const { car } = this.props;

    if (car.service_appointment.isError || car.routineAppointments.isError) {
      return <Alert message="Unable to fetch services" type="error" showIcon />;
    }

    if (!car.service_appointment.loaded || car.routineAppointments.pending) {
      return <Spin />;
    }

    if (_.isEmpty(this.formattedAppointments)) {
      return <Alert message="No services were found" type="info" showIcon />;
    }

    return (
      <>
        <h4>Appointments</h4>

        {_.map(this.formattedAppointments, (appointment, i) => (
          <Card key={i} title={appointment.state}>
            <p>
              <strong>Date:</strong> {appointment.date}
            </p>
            <p>
              <strong>Comments:</strong> {appointment.comments}
            </p>
          </Card>
        ))}
      </>
    );
  }
}

CarAppointments.propTypes = {
  car: PropTypes.instanceOf(CarObject),
};

export default observer(
  decorate(CarAppointments, {
    formattedAppointments: computed,
  })
);
