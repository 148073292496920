import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Observer, observer } from 'mobx-react';
import { computed, decorate, observable, observe, toJS, when } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

import { Col, DatePicker, Icon, Row, Select, Tag } from 'antd';

import { PitstopButton, PitstopTable } from 'shared';
import Timezones from 'components/Timezones.json';

import { AppStore, ShopStore, TableStore, UserStore } from 'stores';
import { RolesOptions } from 'stores/Classes/UserObject';

import ViewUserCars from './ViewUserCars';
import SaveUserCarsModal from './SaveUserCarsModal';
import SelectCars from './SelectCars';
import { Logger } from 'stores/Classes';

const ButtonsContainer = styled.div`
  margin: 15px 0px;

  #setupBtn {
    margin-right: 20px;
  }
`;

class UsersTable extends Component {
  static propTypes = {
    shopId: PropTypes.number,
    isUserSelectionEnabled: PropTypes.bool,
    enableUserSelection: PropTypes.func,
    isDateSelectionEnabled: PropTypes.bool,
    isPrioritySelectionEnabled: PropTypes.bool,
    isVehicleSelectionEnabled: PropTypes.bool,
    sendEmailConfiguration: PropTypes.func,
    sendPriorityAlertsEmailConfiguration: PropTypes.func,
    sendFleetSegmentConfiguration: PropTypes.func,
    getCurrentSelectedPriorities: PropTypes.func,
    columnKeys: PropTypes.array,
    isEditable: PropTypes.bool,
    showSetupButton: PropTypes.bool,
    onChangeUserIdsSelected: PropTypes.func,
    onClickNotification: PropTypes.func,
    ref: PropTypes.object,
  };

  SAVE_USER_CAR_MODAL = 'SAVE_USER_CAR_MODAL';
  VEHICLE_HEALTH_REPORT_MODAL = 'VEHICLE_HEALTH_REPORT_MODAL';
  EDIT_VEHICLE_HEALTH_REPORT_MODAL_ID = 'EDIT_VEHICLE_HEALTH_REPORT_MODAL_ID';
  ADD_VEHICLE_HEALTH_REPORT_MODAL_ID = 'ADD_VEHICLE_HEALTH_REPORT_MODAL_ID';
  EDIT_WORK_ORDER_NOTIFICATION_MODAL_ID =
    'EDIT_WORK_ORDER_NOTIFICATION_MODAL_ID';

  EDIT_ISSUE_ALLERT_MODAL_ID = 'EDIT_ISSUE_ALLERT_MODAL_ID';
  ADD_ISSUE_ALLERT_MODAL_ID = 'ADD_ISSUE_ALLERT_MODAL_ID';
  CHANGE_USER_NOTIFICATIONS_MODAL_ID = 'CHANGE_USER_NOTIFICATIONS_MODAL_ID';

  ADD_PM_NOTIFICATIONS_MODAL_ID = 'ADD_PM_NOTIFICATIONS_MODAL_ID';

  tableStore = new TableStore();

  state = {
    selectedUser: undefined,
    selectedUserIds: [],
    emailSendingDate: '',
    timezone: 'America/New_York',
    selectedCarIds: [],
    selectedEmails: [],
  };

  get shopId() {
    return ShopStore.currentShop.id;
  }

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.setState(
      {
        selectedUserIds: [],
      },
      async () => {
        await this.getEmailVehicleHealthReportConfig();
      }
    );
  });

  componentDidMount() {
    if (this.props.ref) {
      this.props.ref(this);
    }
  }

  isIssueModal() {
    return AppStore.openModals.get(this.ADD_ISSUE_ALLERT_MODAL_ID);
  }

  isWeeklyReportModal() {
    return AppStore.openModals.get(this.ADD_VEHICLE_HEALTH_REPORT_MODAL_ID);
  }

  isUserNotificationsModal() {
    return AppStore.openModals.get(this.CHANGE_USER_NOTIFICATIONS_MODAL_ID);
  }

  isPmModel() {
    return AppStore.openModals.get(this.ADD_PM_NOTIFICATIONS_MODAL_ID);
  }

  dataDisposer;
  setTableStore(tableStore) {
    this.unsubscribe();
    this.tableStore = tableStore;
    when(
      () => {
        return (
          !_.isEmpty(tableStore.data) &&
          (this.isIssueModal() ||
            this.isWeeklyReportModal() ||
            this.isPmModel()) &&
          ShopStore.shopNotifications
        );
      },
      async () => {
        const userIds = toJS(tableStore.data);
        const users = _.map(userIds, (userId) => UserStore.data.get(userId));
        await this.getEmailVehicleHealthReportConfig();
        const vehicleHealthReportObserver = toJS(
          ShopStore.shopNotifications.get(ShopStore.currentShop.id)
        );
        const objects = toJS(
          vehicleHealthReportObserver?.data?.emailConfiguration
        );
        let emailConfigs;
        if (this.isIssueModal()) {
          emailConfigs = _.filter(
            objects,
            (a) => a.email_report_type === 'PriorityAlerts'
          );
        } else if (this.isWeeklyReportModal()) {
          emailConfigs = _.filter(
            objects,
            (a) => a.email_report_type === 'DiagnosticEmail'
          );
        } else if (this.isPmModel()) {
          emailConfigs = _.filter(
            objects,
            (a) => a.email_report_type === 'PmAlerts'
          );
        }
        if (_.isEmpty(users)) return;
        if (_.isEmpty(emailConfigs)) return;

        if (this.isPmModel()) {
          let selectedUserIds = [];
          users.forEach((user) => {
            const userPmAlertsConfigs = _.filter(
              emailConfigs,
              (config) => config.id_user === user.id
            );
            userPmAlertsConfigs.forEach((config) => {
              if (
                config.configuration &&
                !_.isEmpty(config.configuration.actions) &&
                !_.isEmpty(config.configuration.notificationType)
              ) {
                selectedUserIds.push(user.id);
              }
            });
          });
          this.onSelectUsers(_.uniq(selectedUserIds));
        } else {
          const emails = _.map(emailConfigs, (a) => a.emails);
          let selectedUserIds = [];
          for (const emailData of emails) {
            if (!emailData) continue;
            const selectedUser = _.find(
              users,
              (user) => user.email === emailData.email
            );
            if (!_.isNil(selectedUser)) {
              selectedUserIds.push(selectedUser.id);
            }
          }
          // no emails. saved id_user
          const idUsers = _.map(emailConfigs, (a) => a.id_user);
          for (const idUser of idUsers) {
            if (!idUser) continue;
            const selectedUser = _.find(users, (user) => user.id === idUser);
            if (!_.isNil(selectedUser)) {
              selectedUserIds.push(selectedUser.id);
            }
          }
          this.onSelectUsers(_.uniq(selectedUserIds));
        }
      }
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  unsubscribe() {
    if (_.isFunction(this.dataDisposer)) this.dataDisposer();
  }

  get data() {
    let data = _.map(this.tableStore.data, (id) => UserStore.data.get(id));

    return _.filter(data, Boolean);
  }

  getEmailVehicleHealthReportConfig = async () => {
    if (
      this.shopId !== -1 &&
      ShopStore.shopNotifications.get(this.shopId) &&
      !ShopStore.shopNotifications.get(this.shopId).pending
    ) {
      await ShopStore.getShopNotifications(this.shopId);
    }
  };

  getCurrentlySelectedPriorities = async () => {
    if (ShopStore.currentShop.id !== -1) {
      let selected = await this.props.getCurrentSelectedPriorities(
        this.props.shopId
      );

      if (selected) {
        this.setState({
          selectedPriorities: selected,
        });
      }
    }
  };

  getUserReports = (user, emailReports) => {
    return _.filter(emailReports, (report) => {
      let emails =
        typeof report.emails === 'string'
          ? JSON.parse(report.emails)
          : report.emails;
      if (!emails && report.id_user && report.id_user === user.id) {
        return true;
      }
      if (!emails) return false;
      return _.find(emails, (e) => e.email === user.email);
    });
  };

  allColumns = [
    {
      key: 'firstName',
      dataIndex: 'firstName',
      editable: true,
      filter: true,
      title: 'First Name',
      required: true,
      width: '15%',
    },
    {
      key: 'lastName',
      dataIndex: 'lastName',
      editable: true,
      filter: true,
      title: 'Last Name',
      required: true,
      width: '15%',
    },
    {
      key: 'email',
      dataIndex: 'email',
      filter: true,
      title: 'Email/Username',
      required: true,
      render: (fieldValue, row) => {
        let emailOrUsername = fieldValue;
        if (fieldValue.indexOf('username.pitstop') > -1) {
          emailOrUsername = fieldValue.split('@')[0];
        }
        return <Link to={`/user/${row.userId}`}>{emailOrUsername}</Link>;
      },
    },
    {
      key: 'displayedRole',
      dataIndex: 'displayedRole',
      editable: true,
      filter: false,
      inputType: 'select',
      selectOptions: RolesOptions,
      title: 'Role',
      required: true,
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      filter: false,
      editable: true,
      title: 'Phone',
      required: false,
    },
    {
      key: 'pm_method',
      dataIndex: 'pm_method',
      filter: false,
      editable: true,
      title: 'Method',
      required: false,
      render: (text, user) => {
        return (
          <Observer key={user.id}>
            {() => {
              let vehicleHealthReportObserver = toJS(
                ShopStore.shopNotifications.get(this.shopId)
              );

              const objects = toJS(
                vehicleHealthReportObserver?.data?.emailConfiguration
              );

              const pmAlerts = _.filter(
                objects,
                (object) =>
                  object.email_report_type === 'PmAlerts' &&
                  object.id_user === user.id
              );

              if (_.isEmpty(pmAlerts)) return 'None';

              const notificationTypes = pmAlerts.flatMap(
                (alert) => alert.configuration?.notificationType || []
              );

              const uniqueNotificationTypes = [
                ...new Set(notificationTypes),
              ].join(', ');

              return <span>{uniqueNotificationTypes || 'None'}</span>;
            }}
          </Observer>
        );
      },
    },
    {
      key: 'pm_type',
      dataIndex: 'pm_type',
      filter: false,
      editable: true,
      title: 'PM Types',
      required: false,
      render: (text, user) => {
        return (
          <Observer key={user.id}>
            {() => {
              let vehicleHealthReportObserver = toJS(
                ShopStore.shopNotifications.get(this.shopId)
              );

              const objects = toJS(
                vehicleHealthReportObserver?.data?.emailConfiguration
              );

              const pmAlerts = _.filter(
                objects,
                (object) =>
                  object.email_report_type === 'PmAlerts' &&
                  object.id_user === user.id
              );

              if (_.isEmpty(pmAlerts)) return 'None';

              const actionsDescriptions = pmAlerts.flatMap(
                (alert) =>
                  alert.configuration?.actions.map((actionCode) => {
                    switch (actionCode) {
                      case 'O':
                        return 'Overdue';
                      case 'D':
                        return 'Due Soon';
                      case 'U':
                        return 'Upcoming';
                      default:
                        return actionCode;
                    }
                  }) || []
              );

              const uniqueActionsDescriptions = [
                ...new Set(actionsDescriptions),
              ].join(', ');

              return <span>{uniqueActionsDescriptions || 'None'}</span>;
            }}
          </Observer>
        );
      },
    },
    // {
    //   key: 'totalCarsAssociated',
    //   title: 'Vehicles',
    //   required: true,
    //   render: (user) => {
    //     return (
    //       <Observer key={user.id}>
    //         {() => (
    //           <p
    //             style={{ color: '#123fbf', cursor: 'pointer' }}
    //             onClick={() => {
    //               this.setState({ selectedUser: user }, () => {
    //                 AppStore.openModals.set(this.SAVE_USER_CAR_MODAL, true);
    //               });
    //             }}
    //           >
    //             <Icon type="edit" />
    //             {user.totalCarsAssociated}
    //           </p>
    //         )}
    //       </Observer>
    //     );
    //   },
    // },
    {
      title: 'Maintenance Report Schedule',
      key: 'vehicleHealthReport',
      filter: false,
      required: true,
      render: (user) => {
        return (
          <Observer key={'1'}>
            {() => {
              let vehicleHealthReportObserver = toJS(
                ShopStore.shopNotifications.get(ShopStore.currentShop.id)
              );
              const objects = toJS(
                vehicleHealthReportObserver?.data?.emailConfiguration
              );

              const diagnosticNotifications = _.filter(
                objects,
                (object) => object.email_report_type === 'DiagnosticEmail'
              );

              if (_.isEmpty(diagnosticNotifications)) return 'None';

              // current user notifications
              const userReports = this.getUserReports(
                user,
                diagnosticNotifications
              );

              if (_.isEmpty(userReports)) return 'None';

              let result = _.map(userReports, (report) => {
                if (!_.isNil(report?.send_next_email_at)) {
                  return (
                    <div key={user.email} style={{ display: 'flex' }}>
                      <p style={{ fontSize: 14, margin: 'auto' }}>
                        {moment(report.send_next_email_at).format('LLL')}
                      </p>
                    </div>
                  );
                }
              });

              return _.isEmpty(_.filter(result, Boolean))
                ? 'None'
                : _.map(result, (item) => item);
            }}
          </Observer>
        );
      },
    },
    {
      title: 'Vehicle Priority Alerts',
      key: 'vehiclePriorityAlerts',
      width: '200px',
      filter: false,
      required: true,
      render: (user) => {
        return (
          <Observer key={user.id}>
            {() => {
              let vehicleHealthReportObserver = toJS(
                ShopStore.shopNotifications.get(this.shopId)
              );

              const objects = toJS(
                vehicleHealthReportObserver?.data?.emailConfiguration
              );

              const priorityAlerts = _.filter(
                objects,
                (object) => object.email_report_type === 'PriorityAlerts'
              );

              if (_.isEmpty(priorityAlerts)) return 'None';

              // current user reports
              const userReports = this.getUserReports(user, priorityAlerts);

              if (_.isEmpty(userReports)) return 'None';

              let result = _.map(userReports, (report) => {
                const priorities = report?.configuration?.subscribeTo;
                if (!_.isEmpty(priorities)) {
                  return (
                    <Row key={`${user.id}-${_.random(1000)}`}>
                      {(_.includes(priorities, 0) ||
                        _.includes(priorities, 1)) && (
                        <Col span={8}>
                          <Tag color="#dec431">Minor</Tag>
                        </Col>
                      )}

                      {(_.includes(priorities, 2) ||
                        _.includes(priorities, 3)) && (
                        <Col span={8}>
                          <Tag color="#e28931">Major</Tag>
                        </Col>
                      )}

                      {(_.includes(priorities, 4) ||
                        _.includes(priorities, 5)) && (
                        <Col span={8}>
                          <Tag color="#f92d2e">Critical</Tag>
                        </Col>
                      )}
                    </Row>
                  );
                }
              });

              return _.isEmpty(_.filter(result, Boolean))
                ? 'None'
                : _.map(result, (item) => item);
            }}
          </Observer>
        );
      },
    },
    {
      title: 'Work Order Notifications',
      key: 'workOrderNotifications',
      width: '200px',
      filter: false,
      required: true,
      render: (user) => {
        return (
          <Observer key={`${user.id}-workOrderNotification-${uuidv4()}`}>
            {() => {
              const vehicleHealthReportObserver = toJS(
                ShopStore.shopNotifications.get(this.shopId)
              );

              const configRecords = toJS(
                vehicleHealthReportObserver?.data?.workOrderConfiguration
              );

              const configByUserId = _.find(
                configRecords,
                (i) => i.userId === user.id
              );

              const byEmail = _.get(configByUserId, 'byEmail');
              const bySMS = _.get(configByUserId, 'bySMS');

              const methods = [];
              if (bySMS) {
                methods.push('Text');
              }
              if (byEmail) {
                methods.push('Email');
              }
              return methods.join(', ') || 'None';
            }}
          </Observer>
        );
      },
    },
  ];

  actionColumns = () => [
    {
      key: 'edit',
      onClick: () => {},
      component: () => {
        return <div>Edit User</div>;
      },
    },
    {
      key: 'delete',
      onClick: () => {},
      component: () => {
        return <div>Delete User</div>;
      },
    },
  ];

  transformColumnsToNotEditable = (rows, propIsEditable) => {
    const isEditable = _.isNil(propIsEditable) ? true : propIsEditable;
    if (isEditable === false) {
      return _.map(rows, (row) => {
        return { ...row, editable: false };
      });
    } else {
      return rows;
    }
  };

  generateColumns = (propsColumnKeys) => {
    const isPMNotificationsModalOpen = AppStore.openModals.get(
      this.ADD_PM_NOTIFICATIONS_MODAL_ID
    );
    let columns = this.allColumns.map((column) => ({
      ...column,
      filter: isPMNotificationsModalOpen ? false : column.filter,
    }));

    if (AppStore.openModals.get(this.ADD_ISSUE_ALLERT_MODAL_ID)) {
      columns = _.filter(
        columns,
        (col) =>
          !_.includes(
            [
              'displayedRole',
              'phone',
              'totalCarsAssociated',
              'vehicleHealthReport',
              'workOrderNotifications',
            ],
            col.key
          )
      );
    }

    if (AppStore.openModals.get(this.ADD_VEHICLE_HEALTH_REPORT_MODAL_ID)) {
      columns = _.filter(
        columns,
        (col) =>
          !_.includes(
            [
              'displayedRole',
              'phone',
              'totalCarsAssociated',
              'vehiclePriorityAlerts',
              'workOrderNotifications',
            ],
            col.key
          )
      );
    }

    if (AppStore.openModals.get(this.EDIT_WORK_ORDER_NOTIFICATION_MODAL_ID)) {
      columns = _.filter(
        columns,
        (col) =>
          !_.includes(
            [
              'displayedRole',
              'totalCarsAssociated',
              'vehicleHealthReport',
              'vehiclePriorityAlerts',
            ],
            col.key
          )
      );
    }

    if (isPMNotificationsModalOpen) {
      columns = _.filter(
        columns,
        (col) =>
          !_.includes(
            [
              'displayedRole',
              'totalCarsAssociated',
              'vehicleHealthReport',
              'vehiclePriorityAlerts',
              'workOrderNotifications',
            ],
            col.key
          )
      );
    }

    if (_.isEmpty(propsColumnKeys)) {
      return this.transformColumnsToNotEditable(columns, this.props.isEditable);
    }

    let filteredColumns = _.filter(columns, (column) => {
      return (
        _.includes(propsColumnKeys, column.key) ||
        _.includes(propsColumnKeys, column.dataIndex) ||
        column.required
      );
    });

    return this.transformColumnsToNotEditable(
      filteredColumns,
      this.props.isEditable
    );
  };

  onEdit = async ({ newValue: updatedUser }) => {
    if (updatedUser) {
      await UserStore.updateUser(updatedUser.id, updatedUser);
    }

    this.setState({
      selectedUserIds: [updatedUser.id],
    });
  };

  onDelete = async (user) => {
    try {
      AppStore.addLoading('Removing user...');
      await UserStore.deleteUser(user.id);
    } catch (error) {
      Logger.error(error);
      AppStore.addError('Failed to remove user');
    } finally {
      AppStore.removeLoading('Removing user...');
    }
  };

  onSelectUsers = (userIds) => {
    this.setState({
      selectedUserIds: userIds,
    });
    const userIdsSelected = this.data
      .filter((a) => userIds.includes(a.id))
      .map((a) => {
        return {
          id: a.id,
          name: `${a.firstName} ${a.lastName}`,
          firstName: a.firstName,
          lastName: a.lastName,
          email: a.email,
          phone: a.phone,
        };
      });
    const updateSelectedIds = this.props.onChangeUserIdsSelected;
    if (_.isFunction(updateSelectedIds)) updateSelectedIds(userIdsSelected);
  };

  sendEmailConfiguration = async () => {
    let { emailSendingDate, timezone, selectedUserIds } = this.state;

    await this.props.sendEmailConfiguration(
      emailSendingDate,
      timezone,
      selectedUserIds
    );

    this.setState({
      selectedUserIds: [],
      emailSendingDate: '',
    });
  };

  sendPriorityAlertsEmailConfiguration = async () => {
    let { selectedPriorities } = this.state;

    await this.props.sendPriorityAlertsEmailConfiguration(selectedPriorities);

    this.getCurrentlySelectedPriorities();
  };

  sendFleetSegmentConfiguration = async () => {
    let { selectedCarIds, selectedUserIds } = this.state;

    await this.props.sendFleetSegmentConfiguration(
      selectedCarIds,
      selectedUserIds
    );
  };

  onChange = (e) => {
    let selectedPriorities = this.state.selectedPriorities;
    for (let i = 0; i < e.length; i++) {
      if (selectedPriorities.includes(e[i])) {
        selectedPriorities = selectedPriorities.filter(function(
          value,
          index,
          arr
        ) {
          return value !== e[i];
        });
      } else {
        selectedPriorities.push(e[i]);
      }
    }

    this.setState({ selectedPriorities: selectedPriorities });
  };

  onSaveModal = () => {
    // refresh the user list
    this.tableStore.reset();
  };

  render() {
    const { selectedUserIds, selectedCarIds } = this.state;
    const {
      isUserSelectionEnabled,
      enableUserSelection,
      isDateSelectionEnabled,
      isPrioritySelectionEnabled,
      isVehicleSelectionEnabled,
    } = this.props;

    const showSetupButton = _.isNil(this.props.showSetupButton)
      ? true
      : this.props.showSetupButton;

    const onClickUserNotification = (user) => {
      if (this.props.onClickNotification) {
        const vehicleHealthReportObserver = toJS(
          ShopStore.shopNotifications.get(ShopStore.currentShop.id)
        );
        const notificationConfiguration = toJS(
          vehicleHealthReportObserver?.data?.emailConfiguration
        );
        this.props.onClickNotification(user, notificationConfiguration);
      }
    };

    return (
      <>
        {isUserSelectionEnabled && (
          <div style={{ margin: '20px 0px' }}>
            {isDateSelectionEnabled && (
              <div>
                <p>
                  <b>Select date to send the vehicle health report:</b>
                </p>

                <div style={{ marginBottom: 18 }}>
                  <Icon
                    style={{ fontSize: 14, marginRight: 8 }}
                    type="info-circle"
                  />
                  <i>
                    The email will be sent automatically every week from the
                    date you set!
                  </i>
                </div>

                <DatePicker
                  showTime
                  onChange={(date) => {
                    this.setState({
                      emailSendingDate: moment(date).toISOString(),
                    });
                  }}
                />

                <Select
                  style={{ width: 200, margin: '0px 15px' }}
                  showSearch
                  placeholder="Timezone"
                  defaultValue="America/New_York"
                  onChange={(timezone) =>
                    this.setState({
                      timezone,
                    })
                  }
                >
                  {Object.keys(Timezones).map((key) => (
                    <Select.Option key={key} value={key}>
                      {Timezones[key]}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
          </div>
        )}
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <p style={{ margin: '15px 0px' }}>
              <b>Select users:</b>
            </p>
          </Col>

          <Col span={24}>
            <PitstopTable
              id={`${this.shopId}-users-list`}
              columns={this.generateColumns(this.props.columnKeys)}
              data={this.data}
              fetchData={(filters, table) => {
                UserStore.fetchUsersForTable(filters, table);
              }}
              height={isUserSelectionEnabled ? 200 : null}
              rowKey={(record) => record?.userId || record?.id}
              expandedRowRender={(user) => {
                return (
                  <ViewUserCars shopId={this.shopId} userId={user.userId} />
                );
              }}
              expandIconAsCell={false}
              expandIconColumnIndex={null}
              getTableStore={(tableStore) => this.setTableStore(tableStore)}
              onDelete={this.onDelete}
              onEdit={this.onEdit}
              rowSelection={
                isUserSelectionEnabled
                  ? {
                      selectedRowKeys: selectedUserIds,
                      onChange: this.onSelectUsers,
                      hideDefaultSelections: true,
                    }
                  : null
              }
              actionColumns={this.actionColumns()}
              hasNotification={
                ShopStore.currentShop.id && ShopStore.currentShop.id !== -1
              }
              onClickNotification={onClickUserNotification}
            />
          </Col>
        </Row>

        {isVehicleSelectionEnabled && (
          <>
            <p style={{ margin: '15px 0px' }}>
              <b>Select vehicles:</b>
            </p>

            <SelectCars
              id={this.shopId}
              rowSelection={{
                selectedCarIds: [...selectedCarIds],
                onChange: (values) => {
                  this.setState({
                    selectedCarIds: values,
                  });
                },
              }}
            />
          </>
        )}

        {isUserSelectionEnabled && showSetupButton && (
          <ButtonsContainer>
            <PitstopButton
              id="setupBtn"
              onClick={async () => {
                isUserSelectionEnabled && isVehicleSelectionEnabled
                  ? await this.sendFleetSegmentConfiguration()
                  : isPrioritySelectionEnabled
                  ? await this.sendPriorityAlertsEmailConfiguration()
                  : await this.sendEmailConfiguration();

                this.setState({
                  selectedCarIds: [],
                  selectedUserIds: [],
                  selectedPriorities: [],
                });
              }}
            >
              Setup Configuration
            </PitstopButton>
            <PitstopButton
              type="secondary"
              id="cancelBtn"
              onClick={() => {
                enableUserSelection();
                if (isPrioritySelectionEnabled)
                  this.getCurrentlySelectedPriorities();
              }}
            >
              Cancel
            </PitstopButton>
          </ButtonsContainer>
        )}

        <SaveUserCarsModal
          id={this.SAVE_USER_CAR_MODAL}
          shopId={ShopStore.currentShop.id}
          user={this.state.selectedUser}
          onSaveModal={this.onSaveModal}
        />
      </>
    );
  }
}

decorate(UsersTable, {
  tableStore: observable,
  data: computed,
  shopId: computed,
});

export default observer(UsersTable);
