import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import Promise from 'bluebird';

import { Select, Card, Empty, Row, Col } from 'antd';

import { CarStore, CurrentUserStore } from 'stores';

import { CarObject } from 'stores/Classes';

import { webServiceProvider } from 'shared';
import { showDemoVinOrRealVin } from 'shared/utils';
import { getPidName } from 'containers/AnalyticsView/utils';
import { errors } from 'helpers';

import { Loading } from 'components';
import CompairisonMap from './CompairisonMap';
import AnalyticsPlayground from 'containers/AnalyticsView/AnalyticsPlayground';

const Option = Select.Option;

class TripCompairisonPage extends Component {
  constructor() {
    super();
    this.tripIDs = null;
    this.colors = ['#A82A33', '#36D5DB', '#2A752A', '#D68FD6', '#1D3557'];
    this.state = {
      tripsData: [],
      loading: false,
      loaded: false,
      graphKeys: ['Speed[km/h]', 'RPM[min-1]', '210C', '210D'],
      currentMarkerTime: 0,
      currentMarkerTripId: 0,
    };
  }

  async componentDidMount() {
    let vehicleID = Number(this.props.match.params.id);
    let tripIDs = this.props.match.params.tripIDs;

    this.setState(
      {
        loading: true,
      },
      async () => {
        if (!CarStore.data.has(vehicleID)) {
          await CarStore.getCarById(this.props.match.params.id);
        }

        this.tripIDs = _.split(tripIDs, ',');

        await this.getTrips();

        this.setState({
          loaded: true,
          loading: false,
        });
      }
    );
  }

  getTrips = async () => {
    let tripsData = await Promise.map(
      this.tripIDs,
      (tripID) => this.fetchTripData(tripID),
      { concurrency: 3 }
    );

    this.setState({ tripsData });
  };

  fetchTripData = async (id) => {
    try {
      const results = await webServiceProvider.getMany(`v1/trip/${Number(id)}`);
      let data = results.response;
      return data;
    } catch (err) {
      errors.response(err, 'Unable to get trip data');
    }
  };

  dropdownView = () => {
    let graphKeys = [...this.state.graphKeys];

    return (
      <Select
        mode="multiple"
        fullWidth
        value={graphKeys}
        style={{ width: '100%' }}
        placeholder="Select PID Values to graph"
        onChange={this.setGraphKeys}
      >
        {_.map(this.allValidKeys, (key) => (
          <Option key={key}>{getPidName(key)}</Option>
        ))}
      </Select>
    );
  };

  setCurrentMarkerTime = async (currentMarkerTripId, currentMarkerTime) => {
    this.setState({ currentMarkerTripId, currentMarkerTime });
  };

  render() {
    const { loaded, loading, tripsData } = this.state;

    if (loading) return <Loading />;

    if (!loaded) return <></>;

    if (_.isEmpty(tripsData)) return <Empty />;

    const car = CarStore.data.get(Number(this.props.match.params.id));

    return (
      <div>
        <Row gutter={[18, 18]}>
          <Col span={24}>
            <h4>Compare your selected trips for vehicle with VIN: {showDemoVinOrRealVin(car.vin)}</h4>
          </Col>

          <Col lg={8} xs={24}>
            <Row style={{ height: 700 }}>
              <CompairisonMap data={tripsData} colors={this.colors} />
            </Row>
          </Col>

          <Col lg={16} xs={24}>
            {/* <Row gutter={[16, 16]}>
              <Col span={24}>
                <p style={{ fontWeight: 'bold' }}>
                  Select the data You want to display on the graph below.
                </p>
                {this.dropdownView()}
              </Col>
            </Row> */}

            <Row
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 710,
                overflowY: 'scroll',
              }}
              gutter={[16, 16]}
            >
              {_.map(tripsData, (trip, i) => {
                let title = `Trip from
              ${moment
                .tz(
                  trip.timeStart * 1000,
                  CurrentUserStore.user.settings.timezone
                )
                .format('LLL')}
              to
              ${moment
                .tz(
                  trip.timeEnd * 1000,
                  CurrentUserStore.user.settings.timezone
                )
                .format('LLL')}`;

                return (
                  <Col key={i} span={24}>
                    <Card
                      size="small"
                      style={{ padding: 12 }}
                      key={trip.timeStart}
                      title={
                        <h4
                          style={{
                            display: 'flex',
                            fontSize: 14,
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: this.colors[i],
                              width: '40px',
                              height: '14px',
                              marginRight: '6px',
                            }}
                          />
                          {title}
                        </h4>
                      }
                    >
                      <AnalyticsPlayground
                        tripId={trip.tripId}
                        showAnalytics={true}
                        showEmissionTable={false}
                        alarms={toJS(trip.alarms)}
                        setCurrentMarkerTime={(currentMarkerTime) => {
                          this.setCurrentMarkerTime(
                            trip.tripId,
                            currentMarkerTime
                          );
                        }}
                        car={car}
                        startDate={moment.tz(
                          trip.timeStart * 1000,
                          CurrentUserStore.user.settings.timezone
                        )}
                        endDate={moment.tz(
                          trip.timeEnd * 1000,
                          CurrentUserStore.user.settings.timezone
                        )}
                        setEmissionTime={() => {}}
                        selectedGraphKeys={this.state.graphKeys}
                        onSelectGraphKeys={(keys) =>
                          this.setState({
                            graphKeys: keys,
                          })
                        }
                      />
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

TripCompairisonPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  car: PropTypes.instanceOf(CarObject),
};

export default observer(TripCompairisonPage);
