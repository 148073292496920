import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import AlgorithmIcon from './AlgorithmIcon';
import InsightsCardWrapper from './InsightsCardWrapper';
import _ from 'lodash';

const IconContainer = styled.div`
  display: flex;
`;

class InsightsPage extends Component{
  static propTypes = {
    records: PropTypes.array
  };

  constructor(props){
    super(props);
    //For caching
    //this.state = {selectedAlgo: this.props.records[0].algo_name};
    //For not caching
    this.state = {selectedAlgo: _.get(this.props.records, '[0][0].item')};
  }

  selectIcon = (algo_name) => {
    this.setState({selectedAlgo: algo_name});
  }

  render(){
    const algorithmRecords = this.props.records.filter(record => !_.isNil(record[0]));
    return(
      <>
        {/*Icon for caching
        <IconContainer>
          {this.props.records.map(record => {
            return <AlgorithmIcon 
                    iconName={record.item} 
                    status={record.report.status} 
                    selected={record.algo_name === this.state.selectedAlgo} 
                    key={record.algo_name} 
                    selectFunction={() => this.selectIcon(record.algo_name)}/>;
          })}
        </IconContainer> */}
        {/* Insights card for caching
          <InsightsCardWrapper algorithmInfo={this.props.records.find(record => record.algo_name === this.state.selectedAlgo)} />
        */}
        {this.state.selectedAlgo ? 
        <>
        <IconContainer>
            {this.props.records.map(record => {
              return !_.isNil(record[0]) ? <AlgorithmIcon
                iconName={record[0].item}
                status={record[0].report.status}
                selected={record[0].item === this.state.selectedAlgo}
                key={record[0].item}
                selectFunction={() => this.selectIcon(record[0].item)} />
                : null;
            })}
          </IconContainer>
            <InsightsCardWrapper algorithmInfo={algorithmRecords.find(record => record[0].item === this.state.selectedAlgo)} /> </>
        : <p> No results available, please check back later.</p>}
      </>
    );
  }
}

export default InsightsPage;