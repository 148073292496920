import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { Spin, Tabs } from 'antd';
import { Sticky, StickyContainer } from 'react-sticky';

import { customFleets } from 'shared';
import { showDemoVinOrRealVin } from 'shared/utils';

import MapIcon from 'assets/icons/map.png';

import { CarStore, ShopStore } from 'stores';
import { CarObject } from 'stores/Classes';

import { AnalyticsView } from 'containers/AnalyticsView';
import CarAppointments from './CarAppointments';
import CarProfileHeader from './CarProfileHeader';
import CarTripsView from './CarTripsView';
import CarReportsSection from './CarReportsSection';
import CarIssuesPage from './CarIssuesPage';
import CarDocumentSections from './CarDocumentSections';
import AssetWorkOrders from 'containers/TrailerProfile/AssetWorkOrders';

const { TabPane } = Tabs;

const VehicleHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  > p {
    font-size: 14px;
    margin: 0 20px;
  }
  > p > strong {
    font-size: 20px;
  }
`;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky topOffset={-64}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        style={{
          ...style,
          zIndex: 4,
          background: '#fff',
          top: '64px',
        }}
      />
    )}
  </Sticky>
);

class CarProfile extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    car: PropTypes.instanceOf(CarObject),
  };

  state = {
    activeKey: this.activeKey,
  };

  get activeKey() {
    if (this.props.match.params.selector) {
      return this.props.match.params.selector
        .toNormalString()
        .capitalizeFirstLetter();
    }

    return this.car?.carType?.toLowerCase() === 'trailer' ? 'General' : 'Service';
  }

  get car() {
    return CarStore.data.get(Number(this.props.match.params.id));
  }

  get shopId() {
    return ShopStore.currentShop.id === -1
      ? this.car.shopId
      : ShopStore.currentShop.id;
  }

  async componentDidMount() {
    if (!CarStore.data.has(Number(this.props.match.params.id))) {
      await CarStore.getCarById(this.props.match.params.id);
    }
    // // car info
    // const car = CarStore.data.get(Number(this.props.match.params.id));
    // if (car?.carType?.toLowerCase() === 'trailer') {
    //   // redirect to trailer work order page
    //   this.props.history.replace(
    //     `/trailer/${this.props.match.params.id}/work-orders`
    //   );
    // }
  }

  getTabs = (car) => {
    let leanDashboardPanels = [
      <TabPane tab="General" key="General">
        <CarProfileHeader car={car} shopId={this.shopId} />
      </TabPane>,
    ];
    if (!customFleets.motiveFleets.includes(ShopStore.currentShop.id)) {
      leanDashboardPanels.push(
        <TabPane tab="Trips" key="Trips">
          <CarTripsView car={car} shopId={this.shopId} />
        </TabPane>
      );
    }
    const vehicleSpecificTabs = [
      <TabPane tab="Service" key="Service">
        <CarIssuesPage car={car} />
      </TabPane>,
      <TabPane tab="Analytics" key="Analytics">
        <AnalyticsView car={car} />
      </TabPane>,
      <TabPane tab="Pitstop Insights" key="Pitstop Insights">
        <CarReportsSection car={car} />
      </TabPane>,
      <TabPane tab="Appointments" key="Appointments">
        <CarAppointments car={car} />
      </TabPane>,
    ]
    const visibleTabs = car.carType?.toLowerCase() === 'vehicle' ? vehicleSpecificTabs : [];
    leanDashboardPanels = [
      ...leanDashboardPanels,
      ...visibleTabs,
      <TabPane tab="Work Orders" key="Work Orders">
        <AssetWorkOrders car={car} shopId={this.shopId} />
      </TabPane>,
      <TabPane tab="Documents" key="Documents">
        <CarDocumentSections car={car} />
      </TabPane>,
    ];
    let tabs = leanDashboardPanels;

    if (customFleets.gmrv.includes(this.shopId)) {
      // tabs for FCA, GMRV
      let customTabs = ['General', 'Trips', 'Service', 'Pitstop Insights'];

      tabs = _.filter(tabs, (tab) => customTabs.includes(tab.key));
    } else if (customFleets.bmc.includes(this.shopId)) {
      // tabs for bmc
      let customTabs = ['General', 'Trips', 'Service', 'Appointments'];

      tabs = _.filter(tabs, (tab) => customTabs.includes(tab.key));
    } else if (customFleets.wolfline.includes(this.shopId)) {
      // tabs for wolf line construction
      let customTabs = ['General', 'Service', 'Appointments'];

      tabs = _.filter(tabs, (tab) => customTabs.includes(tab.key));
    }

    return tabs.map((el) => {
      if (el.key === this.state.activeKey) {
        return el;
      } else {
        return <TabPane tab={el.key} key={el.key} />;
      }
    });
  };

  changeTab = (activeKey) => {
    this.props.history.replace(
      `/car/${this.props.match.params.id}/${activeKey.toReadableUrl()}`
    );
    this.setState({ activeKey: activeKey });
  };

  vinFrag(car) {
    const { vin } = car;
    return vin && <p>{`VIN: ${showDemoVinOrRealVin(vin)}`}</p>;
  }

  carNameFrag(car) {
    const { carName } = car;
    return carName && <p>{`Unit Id: ${carName}`}</p>;
  }

  render() {
    if (!this.car || !this.car.loaded) {
      return <Spin size="large" tip="Loading..." />;
    }

    return (
      <div
        style={{
          maxHeight: '100%',
          minWidth: '77%',
          marginTop: 10,
        }}
      >
        <VehicleHeaderContainer>
          <p style={{ fontSize: '20px' }}>
            <strong>{`${this.car.mmy}`}</strong>
          </p>
          {!customFleets.motiveFleets.includes(ShopStore.currentShop.id) && (
            <Link to={`/location?car=${this.props.match.params.id}`}>
              <img
                src={MapIcon}
                style={{ height: '4vh' }}
                alt="Live Location"
              />
            </Link>
          )}
          {this.vinFrag(this.car)}
          {this.carNameFrag(this.car)}
        </VehicleHeaderContainer>
        <StickyContainer>
          <Tabs
            defaultActiveKey={this.state.activeKey}
            size="large"
            onChange={(activeKey) => this.changeTab(activeKey)}
            renderTabBar={renderTabBar}
          >
            {this.getTabs(this.car)}
          </Tabs>
        </StickyContainer>
      </div>
    );
  }
}

decorate(CarProfile, {
  shopId: computed,
});

export default observer(CarProfile);
