import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';

import IsScoreTypeReversed from './IsScoreTypeReversed';

const getAxisTitleText = (item) => {
  switch (item) {
    case 'Battery':
      return 'Two-Week Failure Probability';

    case 'Brakes':
      return 'Percentile Score';

    case 'Diesel Exhaust Fluid':
      return 'Tank Level (%)';

    case 'Cranking Voltage':
      return 'Cranking Voltage (V)';

    case 'Sensor Health':
      return 'Number of Failing Sensors';

    case 'DEF System':
      return 'Recent Rapid Drain Alerts';

    case 'Parked Vehicle  Battery':
      return 'Days Since Last Driven';

    default:
      return 'Score';
  }
};

const AlgorithmScoreHistoryChart = (props) => {
  //How far back to look through algorithm results in seconds.
  //It is currently one month
  const lookbackDays = 30;
  const lookbackPeriod = 60 * 60 * 24 * lookbackDays * 1000;
  const isLowScoreBad = IsScoreTypeReversed(_.get(props, 'algorithmInfo[0].item'));
  const scores = props.algorithmInfo.map(result => result.report.score).reverse();
  const tripDates = props.algorithmInfo.map(result => new Date(result.report.trip_date)).reverse();
  if (_.isEmpty(tripDates)) {
    return <p>No Data</p>;
  }
  const latestUnixTime = _.last(tripDates).getTime();
  tripDates.forEach((tripDate, index, arr) => {
    arr[index] = [tripDate.getTime(), scores[index]];
  });
  const tripData = tripDates.filter(item => item[0] > (latestUnixTime - lookbackPeriod));
  //Finding all of the scores that are not associated with a pass so that the minimum threshold for a failed result can be established
  const failResults = props.algorithmInfo.filter(result => result.report.status !== 'pass');
  const failScores = failResults.map(result => result.report.score);
  const maxFailScore = _.max(failScores);
  const minFailScore = _.min(failScores);
  const failThresholdScore = isLowScoreBad ? maxFailScore : minFailScore;
  const zoneAndDataConfig = _.isFinite(failThresholdScore) ? {
    zoneAxis: 'y',
    zones: [
      {
        value: failThresholdScore,
        color: isLowScoreBad ? 'red' : 'black'
      },

      {
        color: isLowScoreBad ? 'black' : 'red'
      }
    ],
    data: tripData
  } : {
    zoneAxis: 'y',
    zones: [{ color: 'black' }],
    data: tripData
  };
  const chartOptions = {
    title: {
      text: 'Line chart',
      style: {
        display: 'none'
      }
    },
    legend: {
      enabled: false
    },
    credits: { enabled: false },
    xAxis: {
      type: 'datetime',
      AxisTypeValue: 'datetime',
      ordinal: false,
      labels: {
        format: '{value:%m-%d}'
      },
      tickColor: '#FFFFFF',
    },
    yAxis: {
      tickColor: '#FFFFFF',
      gridLineColor: '#FFFFFF',
      title: {
        text: getAxisTitleText(_.get(props, 'algorithmInfo[0].item'))
      },
    },
    plotOptions: {
      series: {
        marker: {
          symbol: 'circle'
        }
      }
    },
    tooltip: {
      shared: true
    },
    series: [
      {
        type: 'line',
        name: 'Score',
        ...zoneAndDataConfig
      }]
  };
  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

AlgorithmScoreHistoryChart.propTypes = {
  algorithmInfo: PropTypes.object
};

export default AlgorithmScoreHistoryChart;