import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Icon, Tabs } from 'antd';

import { PitstopButton, PitstopModal } from 'shared';

import DealershipChooser from 'components/DealershipChooser';
import AppStore from 'stores/App';
import RoutineServiceForm from 'containers/Form/RoutineServiceForm';
import NewIssueReport from './NewIssueReport';
import ServiceHistory from './ServiceHistory';

const { TabPane } = Tabs;

const GuideContainer = styled.div`
  display: flex;

  i,
  p {
    margin: auto 5px;
  }
`;

const ModalContainer = styled(PitstopModal)`
  &&& {
    .ant-modal-footer {
      display: none;
    }
  }
`;

class MaintenancePage extends Component {
  state = {
    visible: false,
    activeKey: this.props.match.params.selector || 'routine-service'
  };

  VIDEO_MODAL_ID = 'VIDEO_MODAL_ID';

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.saveService(values);
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  getTabs = () => [
    <TabPane tab="Routine Service" key="routine-service">
      <RoutineServiceForm />
    </TabPane>,
    <TabPane tab="New Issue Report" key="new-issue-report">
      <NewIssueReport />
    </TabPane>,
    <TabPane
      tab="Upload Service History/Repair Order Data"
      key="log-maintenance-report"
    >
      <ServiceHistory />
    </TabPane>
  ];

  changeTab = activeKey => {
    this.props.history.replace(`/maintenance/${activeKey}`);
    this.setState({ activeKey });
  };

  render() {
    return (
      <>
        <h1>PM Scheduler</h1>
        <p>Edit vehicles maintenance with your preferences</p>
        <PitstopButton
          type="primary"
          onClick={() => AppStore.openModals.set(this.VIDEO_MODAL_ID, true)}
        >
          <GuideContainer>
            <Icon type="info-circle" />
            <p>Explore Maintenance Module</p>
          </GuideContainer>
        </PitstopButton>

        <ModalContainer
          id={this.VIDEO_MODAL_ID}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          width="60%"
          height="50%"
          style={{ marginTop: 30 }}
          bodyStyle={{ padding: 0 }}
          onOk={() => {}}
          onCancel={() => () =>
            AppStore.openModals.set(this.VIDEO_MODAL_ID, false)}
        >
          <video width="100%" height="100%" controls muted>
            <source
              src={require('./routine-service-guide.mp4')}
              type="video/mp4"
            />
          </video>
        </ModalContainer>

        <DealershipChooser />
        <Tabs
          defaultActiveKey={this.state.activeKey}
          size="large"
          onChange={activeKey => this.changeTab(activeKey)}
        >
          {this.getTabs()}
        </Tabs>
      </>
    );
  }

  static propTypes = {
    carId: PropTypes.number,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };
}

export default withRouter(observer(MaintenancePage));
