import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { DealershipChooser } from 'components';
import { Form, Select, InputNumber, Button, Card, Col, Row, Spin, notification } from 'antd'; // New: Import necessary components for filters
import styled from 'styled-components';
import { observe } from 'mobx';
import { ShopStore } from 'stores';
import { get } from 'lodash';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const { Option } = Select; // New: Import Option from Select

const H2Styled = styled.h2`
  font-size: 1.2rem;
`;

class PartRepeatVisitsPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  state = {
    isLoadingPartRepeatVisits: false,
    isLoadingReasonsForVisits: false,
    dataForReasonsForVisits: [],
    dataForPartRepeatVisits: [],
    timePeriod: 360, // New: Add timePeriod to state
    limit: 20, // New: Add limit to state
  };

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.disposer();
  }

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.init();
  });

  loadDataForPartRepeatVisits = async () => { // of interest
    try {
      this.setState({ isLoadingPartRepeatVisits: true });
      const { timePeriod, limit } = this.state; // New: Destructure timePeriod and limit from state
      const response = await ShopStore.getShopDataForPartRepeatVisits(
        ShopStore.currentShop.id,
        timePeriod, // New: Pass timePeriod as parameter
        limit // New: Pass limit as parameter
      );
      const data = get(response, 'data', []);

      // Process the data
      const processedData = [];
      const yAxisCategories = data.map(item => item.vmrs_sys_description); // will replace part_name with field for part identifier

      data.forEach((item, index) => {
        processedData.push({
          x: index,
          y: item.visit_freq,
          part_name: item.vmrs_sys_description,
        });
      });

      this.setState({
        dataForPartRepeatVisits: processedData,
        part_names: yAxisCategories
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
      this.setState({ dataForPartRepeatVisits: [] });
    } finally {
      this.setState({ isLoadingPartRepeatVisits: false });
    }
  };

  // loadDataForReasonsForVisits = async () => { // of interest
  //   try {
  //     this.setState({ isLoadingReasonsForVisits: true });
  //     const data = await ShopStore.getShopDataForReasonsForVisits(
  //       ShopStore.currentShop.id
  //     );
  //     this.setState({ dataForReasonsForVisits: data });
  //   } catch (error) {
  //     notification.error({
  //       message: 'Error',
  //       description: error.message,
  //     });
  //   } finally {
  //     this.setState({ isLoadingReasonsForVisits: false });
  //   }
  // };

  init = async () => {
    // load data for Part Repeat Visits
    this.loadDataForPartRepeatVisits();
    // load data for Reasons for Visits
    // this.loadDataForReasonsForVisits();
  };

  handleFilterChange = (changedFields) => { // New: Handle filter changes
    this.setState(changedFields);
  };

  handleApplyFilters = () => { // New: Apply filters
    this.init();
  };

  render() {
    const { timePeriod, limit, isLoadingPartRepeatVisits } = this.state;

    const PartRepeatVisitsChartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: this.state.part_names, // Adjusted field name
        crosshair: true,
        title: {
          text: 'VMRS Description'
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Number of Visits',
        },
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          // point: { commented out because clicking on bar shouldn't bring user anywhere
          //   events: {
          //     click: function () {
          //       // Construct the URL dynamically
          //       const part_name = this.options.part_name;
          //       const customUrl = `http://dashboard.pitstopconnect.com/car/${part_name}`;
          //       // window.location.href = customUrl;
          //       window.open(customUrl, '_blank');
          //     }
          //   }
          // },
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [
        {
          name: 'Visits',
          data: this.state.dataForPartRepeatVisits.map(item => ({
            y: item.y,
            part_name: item.part_name, // Include part name in the data point
          })), // Adjusted field name
          color: '#183cbc',
        },
      ],
    };


    // const reasonsForVisitsChartOptions = {
    //   chart: {
    //     type: 'column',
    //   },
    //   title: {
    //     text: 'Reasons for Visits',
    //   },
    //   xAxis: {
    //     categories: dataForReasonsForVisits.map(item => item.name), // Adjusted field name
    //     crosshair: true,
    //   },
    //   yAxis: {
    //     min: 0,
    //     title: {
    //       text: 'Cost',
    //     },
    //   },
    //   series: [
    //     {
    //       name: 'Cost',
    //       data: dataForReasonsForVisits.map(item => item.amount), // Adjusted field name
    //     },
    //   ],
    // };

    return (
      <Fragment>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <h1>Parts Visit Frequency</h1>
          </Col>
        </Row>
        <DealershipChooser />
        <Form layout="inline" onValuesChange={this.handleFilterChange}> {/* New: Add form for filters */}
          <Form.Item label="Time Period">
            <Select defaultValue={30} value={timePeriod} onChange={value => this.handleFilterChange({ timePeriod: value })}>
              <Option value={30}>30 days</Option>
              <Option value={90}>90 days</Option>
              <Option value={180}>180 days</Option>
              <Option value={360}>360 days</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Limit">
            <InputNumber min={1} defaultValue={20} value={limit} onChange={value => this.handleFilterChange({ limit: value })} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={this.handleApplyFilters}>Apply Filters</Button> {/* New: Apply Filters button */}
          </Form.Item>
        </Form>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Spin spinning={isLoadingPartRepeatVisits}>
              <Card>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <H2Styled>Part Repeat Visits</H2Styled>
                  </Col>
                  <Col span={24}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={PartRepeatVisitsChartOptions}
                    />
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>
          {/* <Col span={8}>
            <Spin spinning={this.state.isLoadingPartCosts}>
              <Card>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <H2Styled>Part Costs</H2Styled>
                  </Col>
                  <Col span={24}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={PartCostsChartOptions}
                    />
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col> */}
        </Row>
      </Fragment>
    );
  }
}

export default withRouter(observer(PartRepeatVisitsPage));