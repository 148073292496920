import Cookies from 'js-cookie';

import { AppStore } from 'stores';

import GeotabConnector from './GeotabConnector';

class PitstopCookieC {
  cookieCanBeStoredByGeotabConnector() {
    return AppStore.isOnGeotabDashboard() && AppStore.getGeotabSSOVersion() > 1;
  }

  set(name, value, cookieSettings) {
    if (this.cookieCanBeStoredByGeotabConnector()) {
      GeotabConnector.setCookie(name, value);
    } else {
      if (AppStore.isOnGeotabDashboard()) {
        cookieSettings = { samesite: 'none', secure: true };
      }

      Cookies.set(name, value, cookieSettings);
    }
  }

  get(name) {
    if (this.cookieCanBeStoredByGeotabConnector()) {
      return GeotabConnector.getCookie(name);
    }

    if (AppStore.isOnGeotabDashboard()) {
      return Promise.resolve(Cookies.get(name));
    }

    return Cookies.get(name);
  }

  remove (name) {
    if (AppStore.isOnGeotabDashboard()) {
      return Promise.resolve(Cookies.remove(name));
    }

    Cookies.remove(name);
  }
}

const PitstopCookie = new PitstopCookieC();
export default PitstopCookie;
