import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReportsDataStore } from 'stores';

// Import React Table
import ReactTable from 'react-table';
import 'react-table/react-table.css';

const ReactTableStyled = styled(ReactTable)`
  text-align: center;
  maragin-top: 20px;
`;

class ServicePriorityTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      page: 0,
      amount: 5,
    };
  }

  render() {
    return (
      <div style={{ marginTop: '20px' }}>
        {/* <RaisedButton style={{"fontFamily": "Lato", "marginBottom": 30}} secondary label="Create New Campaign" onClick={() => { self.props.history.push('/campaign/create'); }} /> */}
        <br />
        <ReactTableStyled
          columns={[
            {
              Header: 'Vehicle',
              id: 'car.make_model_year',
              accessor: (data) => {
                return (
                  <div>
                    <Link to={'/car/' + data.id_car}>
                      <span>{data.car_info}</span>
                    </Link>
                  </div>
                );
              },
            },
            {
              Header: 'Total Services',
              id: 'car.total_services',
              accessor: 'total_service_count',
            },
            {
              Header: 'Critical Services',
              id: 'car.critical_services',
              accessor: 'critical_service_count',
            },
            {
              Header: 'Major Services',
              id: 'car.high_priority',
              accessor: 'major_service_count',
            },
            {
              Header: 'Minor Services',
              accessor: 'minor_service_count',
            },
            {
              Header: 'Distance',
              id: 'car.distance',
              accessor: 'total_distance',
            },
          ]}
          // manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          data={ReportsDataStore.serviceReport}
          pages={Math.round(ReportsDataStore.serviceReport.length / 5)} // Display the total number of pages
          loading={ReportsDataStore.drivingReport.pending} // Display the loading overlay when we need it
          defaultPageSize={5}
          sortable={true}
          defaultSorted={[
            {
              id: 'car.total_services',
              desc: true,
            },
          ]}
          className="-highlight smallTable"
        />
      </div>
    );
  }
}

export default observer(ServicePriorityTable);
