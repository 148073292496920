import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { Input, Icon, Tooltip } from 'antd';

import AppStore from 'stores/App';

const TextStlyed = styled.p`
  flex: 1;
  margin-bottom: 0;
  align-self: center;
`;

const EditIcon = styled(Icon)`
  width: 30px;
  align-self: flex-end;
  margin-bottom: 0;
  align-self: center;
`;

const InputStyled = styled(Input)`
  && {
    .ant-input {
      padding-right: 50px;
    }

    .ant-input-suffix {
      i {
        padding: 0px 2px;
      }
    }
  }
`;

export default function TextField({
  placeholder,
  type,
  defaultValue,
  onSubmit,
  noValueText,
  isEdit = false,
}) {
  const [value, setValue] = useState(defaultValue);
  const [editable, setEditable] = useState(isEdit);

  const onSubmitEdit = async () => {
    try {
      await onSubmit(value);
      setEditable(false);
    } catch (err) {
      AppStore.addError(err.message);
    }
  };

  if (editable) {
    return (
      <>
        <InputStyled
          required
          placeholder={placeholder}
          type={type || 'string'}
          suffix={[
            <Tooltip key="close-circle" title="Close">
              <Icon
                type="close-circle"
                onClick={() => {
                  setValue(defaultValue);
                  setEditable(false);
                }}
              />
            </Tooltip>,
            <Tooltip key="check-circle" title="Confirm">
              <Icon type="check-circle" onClick={onSubmitEdit} />
            </Tooltip>,
          ]}
          defaultValue={defaultValue}
          onChange={(e) => setValue(e.target.value)}
          onPressEnter={onSubmitEdit}
        />
      </>
    );
  }

  return (
    <>
      <TextStlyed>
        {!_.isNil(value) ? value : noValueText || placeholder}
      </TextStlyed>
      <Tooltip title="Edit">
        <EditIcon type="edit" onClick={() => setEditable(true)} />
      </Tooltip>
    </>
  );
}

TextField.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noValueText: PropTypes.string,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
};
