import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import {
  Card,
  Col,
  Row,
  Statistic,
  Icon,
  Form,
  InputNumber,
  Button,
  Spin,
  Empty,
  Select
} from 'antd';

import { ShopStore, ReportsDataStore, CarStore } from 'stores';

import { customFleets } from 'shared';

import { RoiSamples } from './sample-data';

const Option = Select.Option;
  
const StyledFormItem = styled(Form.Item)`
  & label {
    float: left;
  }
`;

const NoIssuesSection = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  i {
    font-size: 36px;
    margin-right: 12px;
  }
`;

class DownTimeCalc extends Component {
  static propTypes = {
    form: PropTypes.object,
    isSampleEnable: PropTypes.bool,
  };

  state = {
    //Defaults for Nussbaum (1065)
    serviceHours: ShopStore.currentShop.id === 1065 ? 2 : 1,
    serviceCost: ShopStore.currentShop.id === 1065 ? 750 : 70,
    chartDisplayType: 'hours'
  };

  get report() {
    let roiReport = ReportsDataStore.roiReport.data;
    let { isSampleEnable } = this.props;

    let indexRoiReports = _.findIndex(roiReport, (report) =>
      _.sum([
        Number(report['count']['replace']),
        Number(report['count']['repair']),
        Number(report['count']['adjust']),
        Number(report['count']['inspect']) !== 0,
      ])
    );

    if (isSampleEnable) {
      return {
        downtimeMonths: RoiSamples.downtimeMonths,
        downtimeHoursDefault: RoiSamples.downtimeHoursDefault,
        downtimeHoursSavedDefault: RoiSamples.downtimeHoursSavedDefault,
      };
    }

    if (_.isEmpty(roiReport) || indexRoiReports === -1) return {};

    roiReport = _.slice(roiReport, indexRoiReports);

    let downtimeMonths = _.map(roiReport, (report) =>
      moment(report['services_by_month'])
        .utc()
        .format('MMM-YY')
    );

    let downtimeHoursDefault = _.map(roiReport, (report) =>
      _.sum([
        Number(report['count']['replace']),
        Number(report['count']['repair']),
        Number(report['count']['adjust']),
        Number(report['count']['inspect']),
      ])
    );

    let downtimeHoursSavedDefault = _.map(roiReport, (report, i) =>
      _.sum([
        Number(report['count']['replace']),
        Number(report['count']['repair']),
        (Number(report['count']['adjust']) ||
          Number(report['count']['inspect'])) &&
          1,
      ])
    );

    let result = {
      downtimeMonths: downtimeMonths,
      downtimeHoursDefault: downtimeHoursDefault,
      downtimeHoursSavedDefault: downtimeHoursSavedDefault,
    };

    return result;
  }

  chartOptions(yAxis, unit, data) {
    let plotLines = [];

    if (customFleets.cardinalCourier.includes(ShopStore.currentShop.id)) {
      plotLines = [
        {
          color: '#009e73	',
          width: 2,
          value: 20,
          dashStyle: 'longdashdot',
        },
      ];
    }

    return {
      title: {
        text: `Avg. Downtime ${yAxis} (${unit})`,
      },
      xAxis: {
        title: {
          text: 'Months',
        },
        categories: this.report.downtimeMonths,
        plotLines,
      },
      yAxis: {
        title: {
          text: yAxis,
        },
      },
      tooltip: {
        valueSuffix: unit,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
          },
          enableMouseTracking: true,
        },
      },
      series: [
        {
          type: 'line',
          name: `Actual ${yAxis === 'Cost' ? yAxis : 'Downtime'} (${unit})`,
          data: data[0],
          color: '#f92d2e',
        },
        {
          type: 'line',
          name: `Estimated ${
            yAxis === 'Cost' ? yAxis : 'Downtime'
          } with Pitstop (${unit})`,
          data: data[1],
          dashStyle: 'longdash',
          color: '#123fbf',
        },
      ],
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // Temp defaults for Nussbaum (shop 1065)
        let serviceHours = values.avgHoursPerService
          ? Number(values.avgHoursPerService)
          : ShopStore.currentShop.id === 1065 ? 2 : 1;
        let serviceCost = values.avgCostPerService
          ? Number(values.avgCostPerService)
          : ShopStore.currentShop.id === 1065 ? 750 : 200;

        this.setState({
          serviceCost,
          serviceHours,
        });
      }
    });
  };

  changeDisplayChart = (event) => {
    this.setState({'chartDisplayType': event});
  }

  render() {
    let { getFieldDecorator } = this.props.form;
    let { downtimeHoursDefault, downtimeHoursSavedDefault } = this.report;
    let { serviceCost, serviceHours, chartDisplayType } = this.state;

    if (CarStore.demo === false) return <Empty />;

    if (
      CarStore.pending ||
      (CarStore.demo !== false && ReportsDataStore.roiReport.pending)
    ) {
      return <Spin tip="Loading..." />;
    }

    if (
      _.isEmpty(this.report) ||
      CarStore.demo === false ||
      ShopStore.currentShop.id === 665
    ) {
      return (
        <NoIssuesSection>
          <Icon type="check-circle" theme="twoTone" />
          <p>
            In order to view ROI, Please share Repair Order/Service History for
            your vehicles!
          </p>
        </NoIssuesSection>
      );
    }

    return (
      <>
        <Row gutter={16}>
          <Col xxl={{ span: 5 }} xl={{ span: 8 }}>
            <Card>
              <Statistic
                title="Downtime Hours Saved with Pitstop"
                value={
                  (_.sum(downtimeHoursDefault) -
                    _.sum(downtimeHoursSavedDefault)) *
                  serviceHours
                }
                precision={2}
                valueStyle={{ color: '#3f8600' }}
                prefix={<Icon type="arrow-down" />}
                suffix="Hrs"
              />
            </Card>
          </Col>
          <Col xxl={{ span: 5 }} xl={{ span: 8 }}>
            <Card>
              <Statistic
                title="Downtime Cost Saved with Pitstop"
                value={
                  (_.sum(downtimeHoursDefault) -
                    _.sum(downtimeHoursSavedDefault)) *
                  serviceCost
                }
                precision={2}
                valueStyle={{ color: '#3f8600' }}
                prefix={
                  <>
                    <Icon type="arrow-down" />$
                  </>
                }
              />
            </Card>
          </Col>
        </Row>
        {chartDisplayType === 'hours' ?
        <HighchartsReact
          highcharts={Highcharts}
          options={this.chartOptions('Hours', 'Hrs', [
            downtimeHoursDefault.map((hour) => hour * serviceHours),
            downtimeHoursSavedDefault.map((hour) => hour * serviceHours),
          ])}
        /> :
          <HighchartsReact
            highcharts={Highcharts}
            options={this.chartOptions('Cost', '$', [
              downtimeHoursDefault.map((hour) => hour * serviceCost),
              downtimeHoursSavedDefault.map((hour) => hour * serviceCost),
            ])}
        />
          }
          <p>Chart type:</p>
          <Select onChange={this.changeDisplayChart} defaultValue='hours'>
            <Option value="hours">Hours saved</Option>
            <Option value="cost">Cost saved</Option>
          </Select>

        <h4>Downtime Calculator</h4>
        <Form
          labelCol={{ xxl: { span: 10 }, xl: { span: 12 } }}
          wrapperCol={{ span: 12 }}
          onSubmit={this.handleSubmit}
          style={{ display: 'flex' }}
        >
          <Col xxl={{ span: 10 }} xl={{ span: 12 }}>
            <StyledFormItem
              label="Avg. Downtime Hours Per Service"
              extra={ShopStore.currentShop.id === 1065 ? "Default: 2 hour/ Service" : "Default: 1 hour/ Service"}
            >
              {getFieldDecorator('avgHoursPerService', {
                rules: [{ type: 'number' }],
              })(<InputNumber min={1} />)}
            </StyledFormItem>
          </Col>
          <Col xxl={{ span: 10 }} xl={{ span: 10 }}>
            <StyledFormItem
              label="Avg. Downtime Cost Per Service"
              extra={ShopStore.currentShop.id === 1065 ? "Default: $750/ Service" : "Default: $200/ Service"}
            >
              {getFieldDecorator('avgCostPerService', {
                rules: [{ type: 'number' }],
              })(<InputNumber min={1} />)}
            </StyledFormItem>
          </Col>
          <Col xxl={{ span: 8 }} xl={{ span: 4 }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Calculate
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </>
    );
  }
}

const DownTimeCalculator = Form.create({ name: 'downtime-calc' })(
  observer(
    decorate(DownTimeCalc, {
      report: computed,
    })
  )
);

export default DownTimeCalculator;
