import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PitstopModal } from 'shared';
// import { UserForm } from './UserForm';
import { UsersToAddTable } from './UsersToAddTable';
import UserObject from 'stores/Classes/UserObject';
import UserStore from 'stores/UserStore';
import ShopStore from 'stores/ShopStore';
import CurrentUserStore from 'stores/CurrentUserStore';
import { observer } from 'mobx-react';
import AppStore from 'stores/App';
import UserFormWithUsername from './UserFormWithUsername';
import { Logger } from 'stores/Classes';
import { Checkbox, notification } from 'antd';
import PrintLoginCredentialsButton from 'components/Users/PrintLoginCredentialsButton';

class AddUsersModal extends Component {
  static propTypes = {
    inviterId: PropTypes.number.isRequired,
    shopId: PropTypes.number,
    id: PropTypes.string.isRequired,
    onAddUsers: PropTypes.func,
  };

  initialState = {
    users: [],
    isPrintCredentials: false,
    isSendCredentialsByPhone: false,
  };

  constructor(props) {
    super(props);
    this.state = { ...this.initialState };
  }

  printLoginCredentialsButtonRef = null;

  componentDidMount() {
    if (CurrentUserStore.user.role === 'admin' && !ShopStore.loaded) {
      ShopStore.getShops();
    }
  }

  addUsers = async () => {
    const { users } = this.state;
    const { inviterId } = this.props;

    try {
      AppStore.openModals.set(this.props.id, false);
      AppStore.addLoading('Adding users to shop...');
      let usersAdded = [];
      for (const user of users) {
        try {
          const userAdded = await UserStore.addUserToShop({
            shopId: user.fleet,
            inviterId,
            firstName: user.firstName,
            role: user.role,
            shopRole: user.shopApiRole,
            lastName: user.lastName,
            phone: user.phone,
            email: user.email,
            username: user.email,
            password: user.password,
            isSendCredentialsByPhone: this.state.isSendCredentialsByPhone,
          });
          usersAdded.push(userAdded);
        } catch (error) {
          Logger.error(error);
          let message = error.message;
          if (
            String(error?.message).indexOf('is already used') &&
            error?.message.includes('@username.pitstop')
          ) {
            // get the first part of the email
            const email = error?.message
              .split(' ')
              .find((part) => part.includes('@username.pitstop'));
            const username = email.split('@')[0];
            message = `The username ${username} is already in use. Please try another one.`;
          }
          AppStore.addError(message);
        }
      }
      if (usersAdded.length > 0) {
        // show a notification with success message and the title: "User(s) Added"
        notification.info({
          message: 'User(s) Added',
          description: (
            <>
              <p>User(s) have been added successfully.</p>
              <PrintLoginCredentialsButton
                ref={(ref) => (this.printLoginCredentialsButtonRef = ref)}
                label="Print login credentials & instructions"
                type="link"
                users={usersAdded}
              />
            </>
          ),
          duration: 0,
        });
        if (this.state.isPrintCredentials) {
          await this.printLoginCredentialsButtonRef.handlePrint();
        }
        this.resetState();
        if (this.props.onAddUsers) {
          this.props.onAddUsers();
        }
      }
    } catch (err) {
      let message = err.message;
      AppStore.addError(message);
    } finally {
      AppStore.removeLoading('Adding users to shop...');
    }
  };

  resetState = () => {
    this.setState({ ...this.initialState });
  };

  onClickDeleteUser = (user) => {
    this.setState({
      users: this.state.users.filter((u) => u.email !== user.email),
    });
  };

  onSubmit = (user) => {
    const userObj = { ...user, shop: [{ id: user.shopId }] };
    // if not duplicate, add user to the list
    if (
      !this.state.users.find(
        (u) => u.email.toLowerCase() === userObj.email.toLowerCase()
      )
    ) {
      delete userObj['shopId'];
      this.setState({ users: [...this.state.users, new UserObject(userObj)] });
    }
  };

  render() {
    const { users } = this.state;
    const shops = Array.from(ShopStore.data.values());
    // if (CurrentUserStore.user.role === 'admin') {
    //   shops = Array.from(ShopStore.data.values());
    // }
    return (
      <PitstopModal
        id={this.props.id}
        title="Add User"
        width={800}
        okText="Finish"
        onOk={this.addUsers}
        onCancel={this.resetState}
        okButtonProps={{ disabled: this.state.users.length === 0 }}
      >
        {/* <UserForm shops={shops} onSubmit={this.onSubmit} /> */}
        <UserFormWithUsername
          shops={shops}
          currentShopName={ShopStore.currentShop?.name}
          onSubmit={this.onSubmit}
        />
        <br />
        <UsersToAddTable
          users={users}
          onClickDeleteUser={(user) => this.onClickDeleteUser(user)}
        />
        <div
          style={{
            width: '500px',
            position: 'absolute',
            bottom: '85px',
            left: '30px',
          }}
        >
          <div className="sendLoginCredentialsByPhone">
            <Checkbox
              checked={this.state.isSendCredentialsByPhone}
              onChange={(e) =>
                this.setState({ isSendCredentialsByPhone: e.target.checked })
              }
            >
              Send login credentials and instructions to the phone number
              provided
            </Checkbox>
          </div>
          <div className="printLoginCredentials">
            <Checkbox
              checked={this.state.isPrintCredentials}
              onChange={(e) =>
                this.setState({ isPrintCredentials: e.target.checked })
              }
            >
              Print login credentials and instructions
            </Checkbox>
          </div>
        </div>
      </PitstopModal>
    );
  }
}

export default observer(AddUsersModal);
