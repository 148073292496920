import React, { Component } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Col,
  Row,
  List,
  Typography,
  Skeleton,
  Select,
  InputNumber,
} from 'antd';

import { convertToMonetary } from 'shared/utils';

const { Text } = Typography;
const { Option } = Select;

const Wrapper = styled.div`
  border: solid 1px #ddd;
  padding: 20px;
`;

class PurchaseOrdersCostSummary extends Component {
  static propTypes = {
    childRef: PropTypes.func,
    lineItems: PropTypes.array,
    tax: PropTypes.object,
    onChangeTaxType: PropTypes.func,
    onChangeTaxValue: PropTypes.func,
  };

  defaultTaxObj = {
    type: 'percentage',
    value: 0,
  };

  state = {
    data: [],
  };

  get localTax() {
    return this.props.tax;
  }

  onChangeTaxType = (value) => {
    if (this.props.onChangeTaxType) {
      this.props.onChangeTaxType(value);
    }
  };

  onChangeTaxValue = (value) => {
    if (this.props.onChangeTaxValue) {
      this.props.onChangeTaxValue(value);
    }
  };

  get currentLineItems() {
    return this.props.lineItems;
  }

  get summaryItems() {
    return [
      {
        description: 'Parts',
        amount: _.chain(this.currentLineItems)
          .filter((lineItem) => lineItem.type === 'part')
          .sumBy((lineItem) => {
            return (lineItem.unitCost || 0) * (lineItem.quantity || 0);
          })
          .value(),
      },
      {
        description: 'Services',
        amount: _.chain(this.currentLineItems)
          .filter((lineItem) => lineItem.type === 'service')
          .sumBy((lineItem) => {
            return (lineItem.unitCost || 0) * (lineItem.quantity || 0);
          })
          .value(),
      },
      {
        description: 'Subtotal',
        amount: _.chain(this.currentLineItems)
          .sumBy((lineItem) => {
            return (lineItem.unitCost || 0) * (lineItem.quantity || 0);
          })
          .value(),
      },
      {
        description: 'Tax',
        amount: this.localTax.value,
        amountType: this.localTax.type || 'percentage',
        extraFields: [
          <Select
            defaultValue={this.localTax.type || 'percentage'}
            onChange={this.onChangeTaxType}
            key="type"
            style={{ width: '60px', marginLeft: '5px' }}
          >
            <Option key="percentage" value="percentage">
              %
            </Option>
            <Option key="absolute" value="absolute">
              $
            </Option>
          </Select>,
          <InputNumber
            key="value"
            defaultValue={this.localTax.value || 0}
            onChange={this.onChangeTaxValue}
            formatter={(value) =>
              `${
                this.localTax.type === 'absolute' ? '$ ' : ''
              }${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '70px', marginRight: '10px', marginLeft: '5px' }}
          />,
        ],
      },
    ];
  }

  get total() {
    const costsTotal = _.sumBy(this.currentLineItems, (i) => {
      return (i.unitCost || 0) * (i.quantity || 0);
    });
    const taxValue =
      this.localTax.type === 'percentage'
        ? (this.localTax.value / 100) * costsTotal
        : parseFloat(this.localTax.value);
    const total = _.sum([costsTotal, taxValue]);
    return total;
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  toPercentage(value = 0) {
    return value.toLocaleString('en-US', {
      style: 'percent',
    });
  }

  render() {
    const showExtraField = (item) => {
      if (item.extraFields && item.extraFields.length) {
        return item.extraFields.map((field) => field);
      }
      return <></>;
    };

    const showCurrencyOrPct = (item) => {
      if (item.amountType && item.amountType === 'percentage') {
        return <div>{this.toPercentage(item.amount / 100)}</div>;
      }
      return <div>{convertToMonetary(item.amount)}</div>;
    };

    return (
      <Wrapper>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <List
              header={<Text strong>Cost Summary</Text>}
              footer={
                <List.Item>
                  <List.Item.Meta title={<Text strong>Total</Text>} />
                  <div>{convertToMonetary(this.total)}</div>
                </List.Item>
              }
              dataSource={this.summaryItems}
              renderItem={(item) => (
                <List.Item>
                  <Skeleton title={false} loading={item.loading} active>
                    <List.Item.Meta title={item.description} />
                    {showExtraField(item)}
                    {showCurrencyOrPct(item)}
                  </Skeleton>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

decorate(PurchaseOrdersCostSummary, {
  currentLineItems: computed,
  total: computed,
  summaryItems: computed,
  localTax: computed,
  tax: observable,
});

export default observer(PurchaseOrdersCostSummary);
