import { decorate, action } from 'mobx';
import { webServiceProvider } from '../shared';

const DIRECT_ENDPOINT = `${process.env.REACT_APP_API_URL}v1`;

class PurchaseOrderStore {
  fetchShopPurchaseOrders = async (shopId, filters = {}) => {
    try {
      if (!shopId) {
        throw new Error('Invalid Id');
      }

      if (shopId === '-1' || shopId === -1) {
        return;
      }

      const abort = new AbortController();

      const query = new URLSearchParams();
      query.append('id_shop', shopId);
      if (filters.search) {
        query.append('search', filters.search);
      }
      if (filters.status) {
        query.append('status', filters.status);
      }

      const url = `${DIRECT_ENDPOINT}/purchase-order?${query.toString()}`;

      const { data } = await webServiceProvider.directFetchWithMethod(
        url,
        'GET',
        null,
        abort.signal
      );

      return data;
    } catch (err) {
      throw err;
    }
  };

  savePurchaseOrder = async ({ id, ...payload }) => {
    try {
      if (!payload) {
        throw new Error('Invalid Payload');
      }

      const abort = new AbortController();

      if (id) {
        const url = `${DIRECT_ENDPOINT}/purchase-order/${id}`;

        const data = await webServiceProvider.directFetchWithMethod(
          url,
          'PATCH',
          payload,
          abort.signal
        );

        return data;
      }

      const url = `${DIRECT_ENDPOINT}/purchase-order`;

      const data = await webServiceProvider.directFetchWithMethod(
        url,
        'POST',
        payload,
        abort.signal
      );

      return data;
    } catch (err) {
      throw err;
    }
  };

  deletePurchaseOrder = async (id) => {
    try {
      if (!id) {
        throw new Error('Invalid Id');
      }

      const abort = new AbortController();

      const url = `${DIRECT_ENDPOINT}/purchase-order/${id}`;

      await webServiceProvider.directFetchWithMethod(
        url,
        'DELETE',
        {
          id,
        },
        abort.signal
      );

      return;
    } catch (err) {
      throw err;
    }
  };

  getPurchaseOrderDetails = async (id) => {
    try {
      if (!id) {
        throw new Error('Invalid Id');
      }

      const abort = new AbortController();

      const url = `${DIRECT_ENDPOINT}/purchase-order/${id}`;

      const data = await webServiceProvider.directFetchWithMethod(
        url,
        'GET',
        null,
        abort.signal
      );

      return data;
    } catch (err) {
      throw err;
    }
  };
}

decorate(PurchaseOrderStore, {
  fetchShopPurchaseOrders: action,
  savePurchaseOrder: action,
  deletePurchaseOrder: action,
});

const purchaseOrderStore = new PurchaseOrderStore();
export default purchaseOrderStore;
