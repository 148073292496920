import L from 'leaflet';
import HealthyMarkerIcon from 'assets/healthy-marker.svg';
import MajorMarkerIcon from 'assets/major-marker.svg';
import MinorMarkerIcon from 'assets/minor-marker.svg';
import CriticalMarkerIcon from 'assets/critical-marker.svg';
import ShopMarkerIcon from 'assets/car-repair.png';

export const HealthyMarker = L.icon({
  iconUrl: HealthyMarkerIcon,
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  popupAnchor: [-20, -10]
});

export const MajorMarker = L.icon({
  iconUrl: MajorMarkerIcon,
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  popupAnchor: [-20, -10]
});

export const MinorMarker = L.icon({
  iconUrl: MinorMarkerIcon,
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  popupAnchor: [-20, -10]
});

export const CriticalMarker = L.icon({
  iconUrl: CriticalMarkerIcon,
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  popupAnchor: [-20, -10]
});

export const ShopMarker = L.icon({
  iconUrl: ShopMarkerIcon,
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  popupAnchor: [-20, -10]
})
