import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import { Empty } from 'antd';

import CurrentUserStore from 'stores/CurrentUserStore';
import { ScatterPlot } from 'shared/PitstopUI/PitstopChart';

const LabelStyled = styled.h3`
  text-align: center;
  margin: 0.5em 0em;
`;

function graphOptions(
  yAxisTitle,
  seriesData,
  options = {
    plotLines: null,
    tooltip: null,
    events: null
  }
) {
  return {
    title: {
      text: `<b>${yAxisTitle || ''}</b>`
    },
    yAxis: {
      title: {
        text: `<b>${yAxisTitle}</b>`
      }
    },
    xAxis: {
      type: 'datetime',
      ...(options.plotLines && { plotLines: options.plotLines })
    },
    time: {
      getTimezoneOffset: timestamp => {
        var zone = CurrentUserStore.user.settings.timezone,
          timezoneOffset = -moment.tz(timestamp, zone).utcOffset();

        return timezoneOffset;
      }
    },
    tooltip: {
      animation: false,
      formatter: function () {
        if (options.tooltip && options.tooltip.formatter) {
          let tooltipText = options.tooltip.formatter(this);
          if (tooltipText) return tooltipText;
        }

        return `${moment
          .utc(this.point.x)
          .tz(CurrentUserStore.user.settings.timezone)
          .format('LLL')} <br/> <b>${this.point.series.name}</b>: ${
          this.point.y
        }`;
      }
    },
    plotOptions: {
      series: {
        events: {
          afterAnimate: function () {
            if (this.name === 'alert (significance probability < 0.05)') {
              document.getElementsByClassName('highcharts-plot-lines-0')[0] &&
                document
                  .getElementsByClassName('highcharts-plot-lines-0')[0]
                  .setAttribute('visibility', 'hidden');
            }
          }
        },
        point: {
          events: {
            mouseOut: function () {
              if (options.events && options.events.mouseOut) {
                options.events.mouseOut(this);
              }
            },
            mouseOver: function () {
              if (options.events && options.events.mouseOver) {
                options.events.mouseOver(this);
              }
            }
          }
        }
      }
    },
    series: [...seriesData]
  };
}

function aisinDisplay(aisin_display) {
  let zScoreData1 = _.filter([...aisin_display], data => data.pcoff_z > 2.57);
  zScoreData1 = _.map(zScoreData1, data => ({
    x: data.rtc_time_start * 1000,
    y: data.pcoff_z
  }));

  let zScoreData2 = _.filter([...aisin_display], data => data.pcoff_z < -2.57);
  zScoreData2 = _.map(zScoreData2, data => ({
    x: data.rtc_time_start * 1000,
    y: data.pcoff_z
  }));

  let sessionMarkData = _.map([...aisin_display], data => ({
    x: data.rtc_time_start * 1000,
    y: data.session_mark,
    marker: { enabled: data.session_mark !== 0 }
  }));

  let pc34Data = _.map([...aisin_display], data => ({
    x: data.rtc_time_start * 1000,
    y: data.pcoff_wsum
  }));

  let meanIntereventTimeData = _.map([...aisin_display], data => ({
    x: data.rtc_time_start * 1000,
    y: data.mean_interevent_time
  }));

  let lagBetaData = _.map([...aisin_display], data => ({
    x: data.rtc_time_start * 1000,
    y: data.lag_beta
  }));

  let pc1Data = _.map([...aisin_display], data => ({
    x: data.rtc_time_start * 1000,
    y: data.pc1_wsum
  }));

  let pc2Data = _.map([...aisin_display], data => ({
    x: data.rtc_time_start * 1000,
    y: data.pc2_wsum
  }));

  const options = {
    tooltip: {
      formatter: function (_this) {
        if (_this.point.y === 0) return false;
      }
    }
  };

  return {
    zScore: graphOptions(
      'Z score',
      [
        {
          name: 'Z score > 2.57',
          data: zScoreData1,
          color: '#f7a35c'
        },
        {
          name: 'Z score < -2.57',
          data: zScoreData2,
          color: '#7cb5ec'
        },
        {
          name: 'session mark',
          data: sessionMarkData,
          animation: false,
          color: '#1bb512',
          states: {
            inactive: {
              opacity: 1
            }
          }
        }
      ],
      options
    ),
    pc34: graphOptions(
      'pc3 + pc4',
      [
        {
          name: 'pc3 + pc4',
          data: pc34Data
        },
        {
          name: 'session mark',
          data: sessionMarkData,
          animation: false,
          color: '#1bb512',
          states: {
            inactive: {
              opacity: 1
            }
          }
        }
      ],
      options
    ),
    mean_interevent_time: graphOptions(
      'mean inter-event time',
      [
        { name: 'mean inter-event time', data: meanIntereventTimeData },
        {
          name: 'session mark',
          data: sessionMarkData,
          animation: false,
          color: '#1bb512',
          states: {
            inactive: {
              opacity: 1
            }
          }
        }
      ],
      options
    ),
    lag_beta: graphOptions(
      'Lag beta (1.0 sec, pc1 vs pc2)',
      [
        { name: 'Lag beta (1.0 sec, pc1 vs pc2)', data: lagBetaData },
        {
          name: 'session mark',
          data: sessionMarkData,
          animation: false,
          color: '#1bb512',
          states: {
            inactive: {
              opacity: 1
            }
          }
        }
      ],
      options
    ),
    pc1: graphOptions('', [{ name: 'pc1', data: pc1Data }], options),
    pc2: graphOptions(
      '',
      [
        {
          name: 'pc2',
          data: pc2Data,
          color: '#f7a35c'
        }
      ],
      options
    ),
    sessionMarkData
  };
}

function aisinNomAlerts(nom_probability_alerts) {
  let d2final = _.map([...nom_probability_alerts], alert => ({
    x: alert.window_time * 1000,
    y: alert.d2final
  }));

  let d2first = _.map([...nom_probability_alerts], alert => ({
    x: alert.window_time * 1000,
    y: alert.d2first
  }));

  let significance_probability = _.filter(
    [...nom_probability_alerts],
    alert => alert.alert_indicator !== 1
  );
  significance_probability = _.map(
    [...significance_probability],
    probability => ({
      x: probability.window_time * 1000,
      y: probability.d2first,
      z: probability.significance_probability
    })
  );

  let alertFlags = _.filter(
    [...nom_probability_alerts],
    alert => alert.alert_indicator === 1
  );
  alertFlags = _.map(alertFlags, alert => ({
    x: alert.window_time * 1000,
    y: alert.d2first,
    z: alert.significance_probability
  }));

  let graphSeries = [
    {
      name: 'd2final (distance to final distribution)',
      data: d2final,
      color: '#7cb5ec',
      lineWidth: 3,
      marker: {
        enabled: false
      }
    },
    {
      name: 'd2first (distance to initial distribution)',
      data: d2first,
      color: '#f7a35c',
      lineWidth: 3,
      marker: {
        enabled: false
      }
    },
    {
      name: 'significance probability',
      showInLegend: false,
      data: significance_probability,
      marker: {
        radius: 5
      }
    },
    {
      name: 'alert (significance probability < 0.05)',
      data: alertFlags,
      marker: {
        radius: 11,
        fillColor: 'red'
      }
    }
  ];

  const options = {
    plotLines: !_.isEmpty(alertFlags) && [
      {
        color: '#FF0000',
        width: 2,
        value: alertFlags[0].x
      }
    ],
    tooltip: {
      formatter: function (_this) {
        if (
          [
            'alert (significance probability < 0.05)',
            'significance probability'
          ].indexOf(_this.point.series.name) !== -1
        ) {
          return `${moment
            .utc(_this.point.x)
            .tz(CurrentUserStore.user.settings.timezone)
            .format(
              'LLL'
            )}<br/><b>d2first (distance to initial distribution):</b> ${
            _this.point.y
          }<br/><b>significance probability:</b> ${_this.point.z}`;
        }
      }
    },
    events: {
      mouseOut: function (_this) {
        if (
          !_.isEmpty(alertFlags) &&
          _this.series.name === 'alert (significance probability < 0.05)' &&
          _this.y === alertFlags[0].y &&
          _this.z === alertFlags[0].z
        ) {
          document
            .getElementsByClassName('highcharts-plot-lines-0')[0]
            .setAttribute('visibility', 'hidden');
        }
      },
      mouseOver: function (_this) {
        if (
          !_.isEmpty(alertFlags) &&
          _this.series.name === 'alert (significance probability < 0.05)' &&
          _this.y === alertFlags[0].y &&
          _this.z === alertFlags[0].z
        ) {
          document
            .getElementsByClassName('highcharts-plot-lines-0')[0]
            .setAttribute('visibility', 'visible');
        }
      }
    }
  };

  return (
    <>
      <ScatterPlot
        options={graphOptions('', graphSeries, options)}
        turboThreshold={d2final.length}
      />
    </>
  );
}

function aisinEventFailuretimes(aisin_display, event_based_failuretimes) {
  let meanIntereventTimeData = _.map([...aisin_display], data => ({
    x: data.rtc_time_start * 1000,
    y: data.mean_interevent_time
  }));

  // random min, max - need to ask Chrism for more details
  let getMinMax = () => {
    return [
      {
        min: -0.9,
        max: 9.7
      },
      {
        min: -1.3,
        max: 11.2
      },
      {
        min: -1,
        max: 10
      },
      {
        min: -1.3,
        max: 10.5
      }
    ];
  };

  let graphSeries = _.map([...event_based_failuretimes], (event, index) => {
    let minMax = getMinMax()[index];

    let predictedFailureTimes = _.map(
      event.predicted_failure_times,
      (time, index) => ({
        x: time * 1000,
        y: index === 0 ? minMax.max - 0.3 : minMax.max
      })
    );

    let color = () => {
      switch (event.id) {
        case 1:
          return '#2ead2a';
        case 2:
          return '#e31212';
        case 3:
          return '#12debf';
        default:
          return '#000000';
      }
    };

    return {
      name: `bracket ${event.id}`,
      color: color(),
      data: [
        {
          x: event.bracket_start_time * 1000,
          y: minMax.min
        },
        {
          x: event.bracket_end_time * 1000,
          y: minMax.min
        },
        ...predictedFailureTimes
      ]
    };
  });

  graphSeries = [
    { name: 'mean inter-event time', data: meanIntereventTimeData },
    ...graphSeries
  ];

  return (
    <>
      <ScatterPlot
        options={graphOptions('', graphSeries)}
        turboThreshold={graphSeries[0].length}
      />
    </>
  );
}

class AisinGraph extends Component {
  static propTypes = {
    data: PropTypes.object
  };

  render() {
    if (!this.props.data) return <></>;

    let {
      aisin_display,
      event_based_failuretimes,
      nom_probability_alerts
    } = this.props.data;

    return (
      <>
        <LabelStyled>pc1</LabelStyled>
        {_.isEmpty(aisin_display) ? (
          <Empty
            description="There is no data present for the time period you selected.
               Please try another time range!"
          />
        ) : (
          <ScatterPlot
            options={aisinDisplay(aisin_display)['pc1']}
            turboThreshold={
              aisinDisplay(aisin_display)['sessionMarkData'].length
            }
          />
        )}

        <LabelStyled>pc2</LabelStyled>
        {_.isEmpty(aisin_display) ? (
          <Empty
            description="There is no data present for the time period you selected.
               Please try another time range!"
          />
        ) : (
          <ScatterPlot
            options={aisinDisplay(aisin_display)['pc2']}
            turboThreshold={
              aisinDisplay(aisin_display)['sessionMarkData'].length
            }
          />
        )}

        <LabelStyled>
          Z score for off manifold deviation &gt; 2.57, &lt; -2.57
        </LabelStyled>
        {_.isEmpty(aisin_display) ? (
          <Empty
            description="There is no data present for the time period you selected.
               Please try another time range!"
          />
        ) : (
          <ScatterPlot
            options={aisinDisplay(aisin_display)['zScore']}
            turboThreshold={
              aisinDisplay(aisin_display)['sessionMarkData'].length
            }
          />
        )}

        <LabelStyled>Off manifold deviation (pc3 + pc4)</LabelStyled>
        {_.isEmpty(aisin_display) ? (
          <Empty
            description="There is no data present for the time period you selected.
               Please try another time range!"
          />
        ) : (
          <ScatterPlot
            options={aisinDisplay(aisin_display)['pc34']}
            turboThreshold={
              aisinDisplay(aisin_display)['sessionMarkData'].length
            }
          />
        )}

        <LabelStyled>
          Trend in average time between anomalous events
        </LabelStyled>
        {_.isEmpty(aisin_display) ? (
          <Empty
            description="There is no data present for the time period you selected.
                Please try another time range!"
          />
        ) : (
          <>
            <ScatterPlot
              options={aisinDisplay(aisin_display)['mean_interevent_time']}
              turboThreshold={
                aisinDisplay(aisin_display)['sessionMarkData'].length
              }
            />
            <ScatterPlot
              options={aisinDisplay(aisin_display)['lag_beta']}
              turboThreshold={
                aisinDisplay(aisin_display)['sessionMarkData'].length
              }
            />
          </>
        )}

        <LabelStyled>Alert times based on anomalous events</LabelStyled>
        {_.isEmpty(aisin_display) && _.isEmpty(event_based_failuretimes) ? (
          <Empty
            description="There is no data present for the time period you selected.
            Please try another time range!"
          />
        ) : (
          aisinEventFailuretimes(aisin_display, event_based_failuretimes)
        )}

        <LabelStyled>Alert times based on NoM distribution changes</LabelStyled>
        {_.isEmpty(nom_probability_alerts) ? (
          <Empty
            description="There is no data present for the time period you selected.
            Please try another time range!"
          />
        ) : (
          aisinNomAlerts(nom_probability_alerts)
        )}
      </>
    );
  }
}

export default observer(AisinGraph);
