import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'antd';

const UserCell = ({ chatUser, selectedSectionId, onSelectUser }) => {
  return (
    <div
      className={`gx-chat-user-item ${
        selectedSectionId === chatUser.id ? 'active' : ''
      }`}
      onClick={() => {
        onSelectUser(chatUser);
      }}
    >
      <Row className="gx-chat-user-row" type="flex">
        <Col span={18}>
          <span className="gx-name h4">{chatUser.name}</span>
          <div className="gx-chat-info-des gx-text-truncate">
            {chatUser.lastMessage &&
              chatUser.lastMessage.substring(0, 25) + '...'}
          </div>
        </Col>

        <Col span={6}>
          {chatUser.unreadMessage > 0 ? (
            <div className="chat-user-notifications">
              <div className="gx-rounded-circle">{chatUser.unreadMessage}</div>
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

UserCell.propTypes = {
  chatUser: PropTypes.object,
  selectedSectionId: PropTypes.object,
  onSelectUser: PropTypes.func,
};

export default UserCell;
