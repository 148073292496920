import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';

import { Empty } from 'antd';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

import { CarStore, CurrentUserStore, ReportsDataStore } from 'stores';

import { calcMileage, calcFuel } from 'helpers/unitCalculations';

const ReactTableStyled = styled(ReactTable)`
  text-align: center;
  maragin-top: 20px;
`;

class DrivingDistanceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      page: 0,
      amount: 5,
    };
  }

  // check if fuelConsumption has 0 data, don't add to table
  fuelConsumption = () => {
    if (ReportsDataStore.drivingReport) {
      let fuelValues = _.filter(
        ReportsDataStore.drivingReport.data,
        (data) => data.total_fuel_consumption !== 0
      );

      if (fuelValues.length !== 0) {
        return {
          Header: `Fuel Consumption (${CurrentUserStore.user.settings.fuelUnit})`,
          id: 'car.fuel_consumption',
          accessor: 'total_fuel_consumption',
          Cell: (data) => {
            const fuelConsumption = calcFuel(data?.original?.total_fuel_consumption, '').toFixed(2);
            return <span>{fuelConsumption}</span>;
          },
        };
      }
      return null;
    }
    return null;
  };

  render() {
    if (CarStore.demo === false) return <Empty />;

    let userOdometer = !CurrentUserStore.user.displayedOdometer
      ? 'Miles'
      : CurrentUserStore.user.displayedOdometer.charAt(0).toUpperCase() +
        CurrentUserStore.user.displayedOdometer.slice(1);

    let columns = [
      {
        Header: 'Vehicle',
        id: 'car.make_model_year',
        accessor: (data) => {
          let name = _.join(
            _.filter([data.car_make, data.car_model, data.car_year], Boolean),
            ' '
          );

          return (
            <div>
              <Link to={'car/' + data.id_car}>
                <span>
                  {_.isEmpty(name) ? data.car_name || data.vin : name}
                </span>
              </Link>
            </div>
          );
        },
      },
      {
        Header: `${userOdometer} Covered`,
        id: 'total_distance',
        accessor: 'total_distance',
        Cell: (data) => {
          let totalDistance =
            calcMileage(parseFloat(data.original.total_distance)) || 0;
          return <span>{totalDistance}</span>;
        },
      },
      {
        Header: 'Hours',
        id: 'car.driving_time',
        accessor: 'total_trip_time',
        Cell: (data) => {
          return (
            <span>{Math.round(data.original.total_trip_time / (60 * 60))}</span>
          );
        },
      },
    ];
    if (this.fuelConsumption() !== null) {
      columns.push(this.fuelConsumption());
    }

    return (
      <div style={{ marginTop: '20px' }}>
        <br />
        <ReactTableStyled
          columns={columns}
          sortable={true}
          defaultSorted={[
            {
              id: 'total_distance',
              desc: true,
            },
          ]}
          data={ReportsDataStore.drivingReport.data}
          pages={Math.round(ReportsDataStore.drivingReport.data.length / 5)}
          loading={ReportsDataStore.drivingReport.pending}
          defaultPageSize={5}
          className="-highlight smallTable"
        />
      </div>
    );
  }
}

export default observer(DrivingDistanceTable);
