import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate, observe } from 'mobx';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Empty } from 'antd';
import { DoughnutChart } from 'shared/PitstopUI/PitstopChart';

import { interpolateColors } from 'helpers/color-generator';

import { ShopStore, ReportsDataStore, TableStore, CarStore } from 'stores';

class VMRSSummary extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  tableStore = new TableStore();

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    await this.getReport();
  });

  async componentDidMount() {
    await this.getReport();
  }

  componentWillUnmount() {
    this.disposer();
  }

  get report() {
    return ReportsDataStore.vmrsReport;
  }

  get chartData() {
    let serieColors = interpolateColors(this.report.count);

    return _.map(this.report.data, (d, i) => {
      return {
        name:
          _.get(d, 'text') &&
          String(d.text)
            .toLowerCase()
            .capitalizeFirstLetter(),
        color: serieColors[i],
        y: _.get(d, 'count') && Number(d.count),
      };
    });
  }

  getReport = async () => {
    if (ShopStore.currentShop.id !== -1) {
      await ReportsDataStore.getVMRSReport(ShopStore.currentShop.id);
    }
  };

  render() {
    if (CarStore.demo === false) {
      return <Empty />;
    }

    return (
      <>
        <DoughnutChart
          type="vmrs"
          chart={{
            height: 250,
            width: '50%',
            margin: [0, 250, 0, 0],

            legend: {
              align: 'right',
              layout: 'vertical',
              verticalAlign: 'middle',
              width: '50%',
            },
          }}
          data={{
            seriesName: 'issues',
            value: this.chartData,
          }}
          click={() => {}}
          loaded={this.report.loaded}
        />
      </>
    );
  }
}

decorate(VMRSSummary, {
  report: computed,
});

export default withRouter(observer(VMRSSummary));
