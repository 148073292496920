import React, { Component } from 'react';
import { observe } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Promise } from 'bluebird';

import { Form, Button, Modal, Upload, Icon, Menu, Radio, Alert } from 'antd';

import { cgp, general } from './data/csvFormat';
import { webServiceProvider } from 'shared';
import { AppStore, ShopStore, CurrentUserStore } from 'stores';

import { SelectCars } from 'containers/Users';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 0px;
  }
`;

class UploadCsvAntdForm extends Component {
  state = {
    selectedCarIds: [],
    csvFiles: [],
    option: 'general',
    odoUnits: CurrentUserStore.user.displayedOdometer,
    dateFormat: 'DD/MM/YYYY',
    wrongFileUploads: [],
    isWrongFileUploadsVisible: false,
  };

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.setState({
      selectedCarIds: [],
    });
  });

  onSelect = (ids) => {
    this.setState({
      selectedCarIds: ids,
    });
  };

  handleFile = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      /* Update state */
      this.setState({ data });
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  getFileName = (fileName) => {
    let strArr = _.split(fileName, '.');

    return {
      name: `${moment().format('YYYY-MM-DD')}_${_.first(strArr)}`,
      extension: _.last(strArr),
    };
  };

  uploadFile = async (file) => {
    let files = [];

    let blob = new Blob([file.originFileObj], {
      type: 'application/vnd.ms-excel',
    });

    let _file = new File(
      [blob],
      `${this.getFileName(file.name).name}.${
        this.getFileName(file.name).extension
      }`,
      {
        type: 'application/vnd.ms-excel',
      }
    );

    files.push(_file);

    if (this.getFileName(file.name).extension === 'xlsx') {
      // convert data to worksheet
      const ws = XLSX.utils.aoa_to_sheet(this.state.data);

      // generate XLSX file
      let csvFile = XLSX.utils.sheet_to_csv(ws);

      let blob = new Blob([csvFile], {
        type: 'application/vnd.ms-excel',
      });

      let _file = new File([blob], `${this.getFileName(file.name).name}.csv`, {
        type: 'application/vnd.ms-excel',
      });

      files.push(_file);
    }

    await Promise.try(async () => {
      let { option, selectedCarIds, dateFormat, odoUnits } = this.state;

      await Promise.map(files, async (file) => {
        const fd = new FormData();
        fd.append('singleInputFileName', file);

        if (option !== 'general') {
          fd.append('whichFormatter', option);
        }

        fd.append('dateFormat', dateFormat);
        fd.append('odoUnits', odoUnits);

        AppStore.addLoading('Uploading CSV file...');

        let result = await webServiceProvider.postFormData(
          `v1/${selectedCarIds[0]}/serviceHistory`,
          fd
        );

        if (!result.success) {
          let wrongFileUploads = _.map(
            _.slice(result.result.details, 0, 5),
            (detail) => {
              let keys = Object.keys(detail.errors);

              let message = [];

              _.forEach(keys, (key) => {
                message.push(
                  `Row: ${Number(detail.row) + 1} - Column: ${key} - Issue: ${
                    detail.errors[key].issue
                  } - Valid Type: ${detail.errors[key].validType}`
                );
              });

              return message;
            }
          );

          this.setState({
            wrongFileUploads: wrongFileUploads,
            isWrongFileUploadsVisible: true,
          });
          throw Error('Wrong File Upload!');
        }
      });
    })
      .catch((err) => {
        if (err.message === 'Wrong File Upload!')
          throw Error('Wrong File Upload!');

        throw Error('Sorry! Uploading CSV files has failed!');
      })
      .finally(() => AppStore.removeLoading('Uploading CSV file...'));
  };

  beforeUpload = async (file) => {
    this.handleFile(file);
  };

  handleChange = (info) => {
    let fileList = [...info.fileList];

    if (_.isEmpty(fileList)) {
      this.setState({
        csvFiles: [],
      });
    } else {
      fileList = [_.last(fileList)];

      fileList = _.map(fileList, (file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });

      this.setState({
        csvFiles: fileList,
      });
    }
  };

  handleRemove = (file) => {
    let { csvFiles } = this.state;
    csvFiles = csvFiles.filter((f) => f.uid !== file.uid);

    this.setState({
      csvFiles,
    });
  };

  onPreview = (file) => {
    if (file && file.originFileObj) {
      var blob = new Blob([file.originFileObj], {
        type: 'application/vnd.ms-excel',
      });

      saveAs(blob, file.originFileObj.name);
    }
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  sendCSVFiles = async () => {
    let { csvFiles, selectedCarIds } = this.state;

    try {
      if (ShopStore.currentShop.id === -1) {
        throw Error('Please select another shop!');
      }

      if (_.isEmpty(csvFiles)) {
        throw Error('Please upload CSV files!');
      }

      if (_.isEmpty(selectedCarIds)) {
        throw Error('Please select vehicle!');
      }

      await this.uploadFile(this.state.csvFiles[0]);

      AppStore.addSuccess('Uploading CSV files done!');
    } catch (err) {
      if (err.message !== 'Wrong File Upload!') AppStore.addError(err.message);
    }
  };

  render() {
    const props = {
      customRequest: this.dummyRequest,
      onChange: this.handleChange,
      beforeUpload: this.beforeUpload,
      onRemove: this.handleRemove,
      multiple: false,
      accept: '.csv, .xlsx',
      onPreview: this.onPreview,
    };

    const { odoUnits, selectedCarIds } = this.state;

    return (
      <>
        <Upload {...props} fileList={this.state.csvFiles}>
          <Button>
            <Icon type="upload" />
            Upload CSV
          </Button>
        </Upload>

        <div style={{ margin: '10px 0px' }}>
          <b>
            <p>Select Odometer Unit</p>
          </b>
          <div>
            <Radio.Group
              onChange={(e) =>
                this.setState({
                  odoUnits: e.target.value,
                })
              }
              defaultValue={odoUnits === 'km' ? 'k' : 'm'}
            >
              <Radio value="k">km (Kilometer)</Radio>
              <Radio value="m">m (Mile)</Radio>
            </Radio.Group>
          </div>
        </div>

        <div style={{ margin: '10px 0px' }}>
          <b>
            <p>Select Date Format</p>
          </b>
          <div>
            <Radio.Group
              onChange={(e) =>
                this.setState({
                  dateFormat: e.target.value,
                })
              }
              defaultValue="MM/DD/YYYY"
            >
              <Radio value="MM/DD/YYYY">MM/DD/YYYY</Radio>
              <Radio value="DD/MM/YYYY">DD/MM/YYYY</Radio>
            </Radio.Group>
          </div>
        </div>

        <div style={{ margin: '10px 0px' }}>
          <b>
            <p>Select file formatter:</p>
          </b>
          <Menu
            onClick={(e) =>
              this.setState({
                option: e.key,
              })
            }
            selectedKeys={[this.state.option]}
            mode="horizontal"
          >
            <Menu.Item key="general">
              <p>Option 1</p>
            </Menu.Item>

            <Menu.Item key="CGP">
              <p>Option 2</p>
            </Menu.Item>
          </Menu>

          {this.state.option === 'general' && (
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  {_.map(_.keys(general[0]), (key, i) => (
                    <th key={i}>{key}</th>
                  ))}
                </tr>

                {_.map(general, (record, i) => {
                  return (
                    <tr key={i}>
                      {_.map(record, (rec, i) => (
                        <td key={i}>{rec}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          {this.state.option === 'CGP' && (
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  {_.map(_.keys(cgp[0]), (key, i) => (
                    <th key={i} style={{ padding: 2 }}>
                      {key}
                    </th>
                  ))}
                </tr>

                {_.map(cgp, (record, i) => {
                  return (
                    <tr key={i}>
                      {_.map(record, (rec, i) => (
                        <td key={i} style={{ padding: 2 }}>
                          {rec}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>

        <b>
          <p>Select One Vehicle</p>
        </b>
        <SelectCars
          rowSelection={{
            selectedCarIds: selectedCarIds,
            onChange: this.onSelect,
            type: 'radio',
          }}
        />

        <Button
          style={{ marginTop: '10px' }}
          type="primary"
          onClick={this.sendCSVFiles}
        >
          Upload files
        </Button>

        <StyledModal
          title={(() => (
            <Alert
              type="error"
              message="Error"
              description="Please fix issues with interface type: RepairOrder"
              banner
            />
          ))()}
          visible={this.state.isWrongFileUploadsVisible}
          onOk={() =>
            this.setState({
              isWrongFileUploadsVisible: false,
            })
          }
          closable={false}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          {_.map(_.flatten(this.state.wrongFileUploads), (fail) => (
            <p>{fail}</p>
          ))}
        </StyledModal>
      </>
    );
  }
}

const UploadCsvForm = Form.create({ name: 'upload-csv-form' })(
  UploadCsvAntdForm
);

export default observer(UploadCsvForm);
