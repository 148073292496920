class LoggerService {
  constructor() {
    this.initialized = true;
  }

  log(message, context) {
    if (this.initialized) {
      console.log(message, context);
    }
  }

  debug(message, context) {
    if (this.initialized) {
      console.debug(message, context);
    }
  }

  info(message, context) {
    if (this.initialized) {
      console.info(message, context);
    }
  }

  warn(message, context) {
    if (this.initialized) {
      console.warn(message, context);
    }
  }

  error(message, context) {
    if (this.initialized) {
      console.error(message, context);
    }
  }
}

const Logger = new LoggerService();
export default Logger;
