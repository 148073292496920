import _ from 'lodash';

const messageObj = {
  source: {
    type: ''
  },
  _id: '',
  role: '',
  authorId: '',
  avatarUrl: '',
  type: '',
  received: 0,
  text: ''
};

const conversionObj = {
  _id: '',
  appMakerLastRead: 0,
  appUserLastRead: 0,
  unreadCount: 0
};

const getAppUnreadMessagesCounts = (
  name,
  data = { allMessages: [messageObj], conversation: conversionObj }
) => {
  if (_.isEmpty(data)) return 0;

  let { appMakerLastRead } = data.conversation;

  let unreadMessages = _.filter(data.allMessages, message => {
    return message.name !== name && message.received > appMakerLastRead;
  });

  return unreadMessages.length;
};

const getFleetManagerInfo = (data = []) => {
  if (_.isEmpty(data)) return '';

  return data[0];
};

const getDriverInfo = (data = [], key) => {
  if (_.isEmpty(data)) return '';

  let users = _.filter(data, user => user.id === key);

  if (_.isEmpty(users)) return '';

  return users[0];
};

const chatUtils = {
  getAppUnreadMessagesCounts,
  getFleetManagerInfo,
  getDriverInfo
};

export default chatUtils;
