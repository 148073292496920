import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Battery } from 'assets/algorithm_insights_icons/Battery.svg';
import { ReactComponent as BatteryActive } from 'assets/algorithm_insights_icons/Battery-active.svg';
import { ReactComponent as Brakes } from 'assets/algorithm_insights_icons/Brake.svg';
import { ReactComponent as BrakesActive } from 'assets/algorithm_insights_icons/Brake-active.svg';
import { ReactComponent as CrankingVoltage } from 'assets/algorithm_insights_icons/Cranking-voltage.svg';
import { ReactComponent as CrankingVoltageActive } from 'assets/algorithm_insights_icons/Cranking-voltage-active.svg';
import { ReactComponent as DEFLevel } from 'assets/algorithm_insights_icons/DEF-level.svg';
import { ReactComponent as DEFLevelActive } from 'assets/algorithm_insights_icons/DEF-level-active.svg';
import { ReactComponent as DEFSystem } from 'assets/algorithm_insights_icons/DEF-system.svg';
import { ReactComponent as DEFSystemActive } from 'assets/algorithm_insights_icons/DEF-system-active.svg';
import { ReactComponent as Sensor } from 'assets/algorithm_insights_icons/Sensor.svg';
import { ReactComponent as SensorActive } from 'assets/algorithm_insights_icons/Sensor-active.svg';


const AlgorithmIconSelector = (props) => {
  if (props.selected){
    if (props.iconName === 'Battery'){
      return <BatteryActive />;
    }
    if (props.iconName === 'Brakes'){
      return <BrakesActive />;
    }
    if (props.iconName === 'Fluids'){
      return <DEFLevelActive />;
    }
    if (props.iconName === 'Diesel Exhaust Fluid'){
      return <DEFLevelActive />;
    }
    if (props.iconName === 'Cranking Voltage'){
      return <CrankingVoltageActive />;
    }
    if (props.iconName === 'Sensor Health'){
      return <SensorActive />;
    }
    if (props.iconName === 'DEF System'){
      return <DEFSystemActive />;
    }
    if (props.iconName === 'Parked Vehicle  Battery'){
      return <BatteryActive />;
    }
    return <SensorActive />;
  }
  else if (!props.selected){
    if (props.iconName === 'Battery'){
      return <Battery />;
    }
    if (props.iconName === 'Brakes'){
      return <Brakes />;
    }
    if (props.iconName === 'Fluids'){
      return <DEFLevel />;
    }
    if (props.iconName === 'Diesel Exhaust Fluid'){
      return <DEFLevel />;
    }
    if (props.iconName === 'Cranking Voltage'){
      return <CrankingVoltage />;
    }
    if (props.iconName === 'Sensor Health'){
      return <Sensor />;
    }
    if (props.iconName === 'DEF System'){
      return <DEFSystem />;
    }
    if (props.iconName === 'Parked Vehicle  Battery'){
      return <Battery />;
    }
    return <Sensor />;
  }
  return null;

};

AlgorithmIconSelector.propTypes = {
  selected: PropTypes.bool,
  iconName: PropTypes.string
};

export default AlgorithmIconSelector;