import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import PropTypes from 'prop-types';

import { Empty } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { WidgetToolTip } from 'shared/PitstopUI/PitstopChart';
import { PitstopTable } from 'shared';
import moment from 'moment-timezone';

import { CarStore, ReportsDataStore, ShopStore } from 'stores';
import { TableStore } from 'stores/abstract';

class AlgorithmStats extends Component {
  static propTypes = {
    setStore: PropTypes.func
  }

  tableStore = new TableStore();

  get data() {
    return this.tableStore.data;
  }

  getReport = async (
    shopId = ShopStore.currentShop.id,
    filters,
    tableStore
  ) => {
    await ReportsDataStore.getAlgorithmStatsReport(shopId, tableStore);
  }

  getAlertIndicator = (days_since_run) => {
    if (days_since_run === 'N/A'){
      return <ExclamationCircleOutlined style={{color:'red'}} />;
    } else if (parseFloat(days_since_run) > 0.04){
      return <ExclamationCircleOutlined style={{color:'red'}} />;
    } else{
      return null;
    }
  }

  columns = [
    {
      title: 'Algorithm',
      key: 'Algorithm',
      render: (item) => item.name
    },
    {
      title: 'Vehicles with Algorithm Running',
      key: 'Vehicles Running',
      render: (item) => item.percent_running
    },
    {
      title: 'Time Remaining For Result',
      key: 'Time Remaining',
      render: (item) => item.est_time_remaining
    },
    {
      title: 'Algorithm Last Ran',
      key: 'Last Ran',
      render: (item) => item.last_run === 'N/A' ? 'N/A' : moment(item.last_run * 1000).fromNow()
    }
  ];
  render() {
    if (CarStore.demo === false) return <Empty />;
    let shopId = ShopStore.currentShop.id;
    return (
      <>
        <WidgetToolTip title="This table shows the status of our various algorithms running on your fleet" 
                       colour='#123fbf'/>
        
        <PitstopTable
          id={`${ShopStore.currentShop.id}-algo-stats`}
          columns={this.columns}
          data={this.data}
          rowKey="id"
          limit={10}
          fetchData={async (filters, tableStore) => {
            if (shopId !== -1) {
              this.getReport(shopId, filters, tableStore);
            }
          }}
          getTableStore={(tableStore) => {
            this.tableStore = tableStore;
            if (this.props.setStore){
              this.props.setStore(tableStore);
            }}
          }
        />
        
      </>
    );
  }
}

decorate(AlgorithmStats, {
  tableStore: observable,
  data: computed,
});

export default observer(AlgorithmStats);
