import { observable, decorate, transaction, action, computed } from 'mobx';
import moment from 'moment-timezone';
import ReactGA from 'react-ga';
import _ from 'lodash';

import { AsyncData } from 'stores/abstract';
import { UserObject } from 'stores/Classes';
import { UserRoles } from 'stores/Classes/UserObject';
import {
  AppStore,
  ShopStore,
  UserStore,
  IssueStore,
  CarStore,
  IntegrationStore,
  PitstopCookie,
} from 'stores';
import {
  IssuesTableCacheStore,
  PitstopTableCacheStore,
  ReportCacheStore,
  IntegrationCacheStore,
} from './CacheStore';

import { webServiceProvider } from 'shared';
import { errors } from 'helpers';

class CurrentUserStoreC {
  MESSAGE = 'Loading In User Profile';

  user = new UserObject();

  loaded = false;
  pending = false;
  isRunningOnGeotabAddIn = window.name === 'pitstop';
  fleetManagersDrivers = new AsyncData();
  notifications = new Map();
  abortController = new AbortController();

  hubspot = null;

  constructor() {
    this.user = undefined;
  }

  get isCardinalCourierAccount() {
    return (
      CurrentUserStore.user.email.includes('@cardinalcouriers.com') ||
      CurrentUserStore.user.email === 'developers@pitstopconnect.com'
    );
  }

  get isAdmin() {
    return CurrentUserStore.user.role === UserRoles.admin;
  }

  get isTechnician() {
    return CurrentUserStore.user.role === 'technician';
  }

  get allowManager() {
    return this.user.allowManager;
  }

  getCurrentUser = async (showError = true) => {
    try {
      this.pending = true;
      this.loaded = false;

      let userId;

      if (AppStore.isOnGeotabDashboard()) {
        userId = await PitstopCookie.get('userId');
      } else {
        userId = PitstopCookie.get('userId');
      }

      if (_.isNil(userId)) {
        throw Error(`Invalid userId: ${userId}`);
      }

      const returnedUser = await webServiceProvider.getById(
        'user',
        Number(userId)
      );

      if (!returnedUser.activated) {
        this.pending = false;
        throw Error('User not activated');
      }

      this.user = UserStore.UserObject(returnedUser);

      UserStore.addUserToStore(this.user.id, this.user);

      await this.getHubspotContact(this.user.email);

      if (returnedUser.role === 'dealership') {
        _.forEach(returnedUser.shop, (item) => {
          ShopStore.addShopToStore(item.id, {
            ...item,
            user: { id: this.user.id },
          });
        });

        if (this.isCardinalCourierAccount) {
          ShopStore.setCurrentShop(374);
        } else {
          if (!_.isEmpty(returnedUser.shop)) {
            // find the first parent shop
            const parentShop = returnedUser.shop.find(
              (shop) => shop.id_parent_shop === null
            );
            // only for travis for now...
            ShopStore.setCurrentShop(
              parentShop && userId.toString() === '13000'
                ? parentShop.id
                : returnedUser.shop[0].id
            );
          }
        }
      }
      if (_.isNil(_.get(this.user, 'settings.timezone'))) {
        this.user.settings.timezone = 'America/New_York';
      }
      moment.tz.setDefault(this.user.settings.timezone || 'America/New_York');
      if (this.user.role === 'dealership') {
        this.user.getPayments();
      }

      AppStore.loggedIn.set(true);

      AppStore.removeLoading(this.MESSAGE);
      ReactGA.set({ dimension1: this.user.id });
      ReactGA.set({ dimension2: this.user.email });
      if (!_.isEmpty(this.user.shop)) {
        ReactGA.set({ dimension3: this.user.shop[0].name });
        ReactGA.set({ dimension4: this.user.shop[0].id });
      }
      this.loaded = true;
      this.pending = false;
    } catch (err) {
      if (showError) {
        errors.response(err, err.message);
        throw err;
      }
    } finally {
      this.pending = false;
    }
  };

  /**
   * Authenticate the user, set cookies and return it or null if error
   * @param {*} username
   * @param {*} password
   * @param {*} callback
   * @returns UserObject | null
   */
  login = async (username, password, callback = () => {}) => {
    try {
      const data = await webServiceProvider.post('login', {
        username,
        password,
      });

      PitstopCookie.set('accessToken', data.accessToken);
      PitstopCookie.set('userId', data.user.id);
      PitstopCookie.set('refreshToken', data.refreshToken);

      const userObject = UserStore.UserObject(data.user);

      callback(true, userObject);
      return userObject;
    } catch (e) {
      let friendlyMessage = '';
      if (e.message) {
        friendlyMessage = e.message;
      } else {
        friendlyMessage = 'Failed to connect to server';
      }
      callback(false, null);
      AppStore.addError(friendlyMessage);
      return null;
    }
  };

  loginWithRefreshToken = async (refreshToken) => {
    try {
      const data = await webServiceProvider.post('login/refresh', {
        refreshToken,
      });

      PitstopCookie.set('accessToken', data.accessToken);
      PitstopCookie.set('userId', data.user.id);
      PitstopCookie.set('refreshToken', data.refreshToken || refreshToken);

      return UserStore.UserObject(data.user);
    } catch (e) {
      let friendlyMessage = '';
      if (e.message) {
        friendlyMessage = e.message;
      } else {
        friendlyMessage = 'Failed to connect to server';
      }
      AppStore.addError(friendlyMessage);
      return null;
    }
  };

  loginGeotab = async (
    database,
    sessionId,
    username,
    serverPath,
    callback = () => {}
  ) => {
    try {
      this.abortController.abort();
      this.abortController = new AbortController();
      const data = await webServiceProvider.post(
        'login/geotab',
        {
          database,
          sessionId,
          username,
          serverPath,
        },
        this.abortController.signal
      );
      PitstopCookie.set('accessToken', data.accessToken);
      PitstopCookie.set('userId', data.user.id);
      PitstopCookie.set('refreshToken', data.refreshToken);
      callback(true, UserStore.UserObject(data.user));
    } catch (e) {
      callback(false, null);
      AppStore.addError('Invalid geotab credentials');
      this.pending = false;
      this.loaded = true;
    }
  };

  logout = () => {
    const MESSAGE = 'Logging Out';
    transaction(() => {
      try {
        AppStore.addLoading(MESSAGE);

        PitstopCookie.remove('accessToken');
        PitstopCookie.remove('userId');
        PitstopCookie.remove('refreshToken');
        PitstopCookie.remove('latestRoute');

        this.user = undefined;

        AppStore.loggedIn.set(false);

        ShopStore.reset();
        IssueStore.reset();
        IntegrationStore.reset();
        IntegrationStore.modalIntegrationForm = null;
        CarStore.reset();
        UserStore.reset();

        PitstopTableCacheStore.reset();
        ReportCacheStore.reset();
        IssuesTableCacheStore.reset();
        IntegrationCacheStore.reset();
      } catch (err) {
        errors.response(err, 'Error in logout');
      } finally {
        this.pending = false;
        this.loaded = false;
        AppStore.removeLoading(MESSAGE);
        window.location.reload();
      }
    });
  };

  resetPassword = async (email) => {
    try {
      await webServiceProvider.post('login/resetPassword', {
        email,
      });
      AppStore.addSuccess(
        'A password reset link has been sent to the email address'
      );
    } catch (e) {
      AppStore.addSuccess(
        'A password reset link has been sent to the email address'
      );
    }
  };

  getFleetManagersDriversMap = async () => {
    try {
      this.fleetManagersDrivers.loaded = false;
      this.fleetManagersDrivers.pending = true;

      let result = await webServiceProvider.getMany(
        `v1/user/${this.user.id}/fleet-drivers`
      );

      this.fleetManagersDrivers.data.replace(result);
    } catch (err) {
      this.fleetManagersDrivers.setError(err.message);
    } finally {
      this.fleetManagersDrivers.loaded = true;
      this.fleetManagersDrivers.pending = false;
    }
  };

  createHubspotContact = async (payload = {}) => {
    try {
      let data = await webServiceProvider.post('v1/hubspot/contact', {
        ...payload,
      });
      this.hubspot = data;
    } catch (err) {
      throw err;
    }
  };

  getHubspotContact = async (email) => {
    try {
      let data = await webServiceProvider.getMany('v1/hubspot/contact', {
        email,
      });
      this.hubspot = data;
    } catch (err) {
      console.error(err);
    }
  };

  updateHubspotContact = async (payload) => {
    try {
      let data = await webServiceProvider.put('v1/hubspot/contact', {
        contactId: this.hubspot.data.id,
        data: payload,
      });
      this.hubspot = data;
    } catch (err) {
      console.error(err);
    }
  };
}

decorate(CurrentUserStoreC, {
  user: observable,
  loaded: observable,
  pending: observable,
  fleetManagersDrivers: observable,
  getFleetManagersDriversMap: action,
  isAdmin: computed,
  isTechnician: computed,
  notifications: observable,
  hubspot: observable,
  getCurrentUser: action,
  allowManager: computed,
});

const CurrentUserStore = new CurrentUserStoreC();
export default CurrentUserStore;
