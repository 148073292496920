import React, { Component } from 'react';
import { computed, decorate } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import humanizeDuration from 'humanize-duration';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Table } from 'antd';

class AvgSessionTimeReport extends Component {
  static propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool
  };

  columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.length - b.email.length,
      render: email => <a href={`mailto:${email}`}>{email}</a>
    },
    {
      title: 'Total Session Time',
      dataIndex: 'totalSessionTime',
      key: 'totalSessionTime',
      sorter: (a, b) => a.totalSessionTime - b.totalSessionTime,
      defaultSortOrder: 'descend',
      render: totalSessionTime => humanizeDuration(totalSessionTime * 1000)
    },
    {
      title: 'Total Page Views',
      dataIndex: 'totalPageViews',
      key: 'totalPageViews',
      sorter: (a, b) => a.totalPageViews - b.totalPageViews,
      defaultSortOrder: 'descend'
    },
    {
      title: 'Last Active Time',
      dataIndex: 'lastActiveTime',
      key: 'lastActiveTime',
      sorter: (a, b) => a.lastActiveTime - b.lastActiveTime,
      defaultSortOrder: 'descend',
      render: lastActiveTime => moment(lastActiveTime).format('ll')
    }
  ];

  get dataSource() {
    let data = _.map(this.props.data, d => {
      return {
        email: d.email,
        totalSessionTime: _.sum(
          _.map(d.activeTimeList, item => Number(item.sessionTime))
        ),
        lastActiveTime: _.get(_.first(d.activeTimeList), 'date'),
        totalPageViews: _.sum(
          _.map(d.activeTimeList, item => Number(item.pageViews))
        )
      };
    });

    return data;
  }

  render() {
    const { loading } = this.props;

    return (
      <>
        <p style={{ fontWeight: 500 }}>User Avg. Session Time</p>
        <Table
          columns={this.columns}
          loading={loading}
          dataSource={this.dataSource}
        />
      </>
    );
  }
}

decorate(AvgSessionTimeReport, {
  dataSource: computed
});

export default observer(AvgSessionTimeReport);
