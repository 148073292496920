import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import _ from 'lodash';
import styled from 'styled-components';
import HighchartsReact from 'highcharts-react-official';
import { Select } from 'antd';

import { ReportsDataStore } from 'stores';
import { TableStore } from 'stores/abstract';
import { WidgetToolTip } from 'shared/PitstopUI/PitstopChart';

const InsightBoxWrapper = styled.div`
  display: flex;
  height: 20vh;
`;

const InsightBox = styled.div`
  border: solid;
  border-color: #f2f2f2;
  width: 32%;
  height: 90%;
  margin: 1%;
`;

const InsightTitle = styled.p`
  margin: 3%;
  margin-left: 5%;
  font-weight: 550;
`;

const InsightValue = styled.p`
  margin-left: 5%;
  font-size: 2em;
`;

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChartContainer = styled.div`
  margin: 2%;
`;

class FleetWideCostsByMonth extends Component {
  constructor(props){
    super(props);
    this.state = {startDate: null, endDate: null, initializeDefaults: true};
  }

  tableStore = new TableStore();
  monthNames = {
  'January': 1,
  'February': 2,
  'March': 3,
  'April': 4,
  'May': 5,
  'June': 6,
  'July': 7,
  'August': 8,
  'September': 9,
  'October': 10,
  'November': 11,
  'December': 12
  };

  get data() {
    let data = ReportsDataStore.vehicleCostStatsReport.data;
    return data;
  }
  
  onChangeStartDate(startDate){
    this.setState({startDate: startDate});
  }

  onChangeEndDate(endDate){
    this.setState({endDate: endDate});
  }

  render(){
    const cost_stats = _.get(this.data, 'cost_stats', {});
    const data = [];
    //Sorting available years from most recent to least recent
    const years = Object.keys(cost_stats);
    years.sort();
    const startYear = _.isNil(this.state.startDate) ? null : +this.state.startDate.split(', ')[1];
    const endYear = _.isNil(this.state.endDate) ? null: +this.state.endDate.split(', ')[1];
    const yearsInRange = _.isNil(this.state.startDate) || _.isNil(this.state.endDate) ? years : [startYear, ..._.range(startYear, endYear)];
    if (!_.isEmpty(cost_stats) && this.state.initializeDefaults){
      const firstYear = years[0];
      const lastYear = years.slice(-1)[0];
      const firstYearMonths = Object.keys(cost_stats[firstYear]);
      firstYearMonths.sort((a,b) => {
        return this.monthNames[a] - this.monthNames[b];
      });
      const lastYearMonths = Object.keys(cost_stats[lastYear]);
      lastYearMonths.sort((a,b) => {
        return this.monthNames[a] - this.monthNames[b];
      });
      const defaultStartTime = firstYearMonths[0] + ', ' + firstYear;
      const defaultEndTime = lastYearMonths.slice(-1)[0] + ', ' + lastYear;
      this.setState({startDate: defaultStartTime, endDate: defaultEndTime, initializeDefaults: false});
    }
    let monthsInRange = [];
    const costPerMonth = [];
    const costPerVisitPerMonth = [];
    const visitsPerMonth = [];
    const monthYearPairs = [];
    let months = [];
    let totalCostInRange = 0;
    let costPerVisitInRange = 0;
    let numVisitsInRange = 0;
    let numMonthsInRange = 0;
    let startMonth;
    let endMonth;
    _.forEach(years, year => {
      months = Object.keys(cost_stats[year]);
      months.sort((a,b) => {
        return this.monthNames[a] - this.monthNames[b];
      });
      _.forEach(months, month => {
        monthYearPairs.push(month+', '+year);
      });
    });
    _.forEach(yearsInRange, year => {
      monthsInRange = [];
      
      months = Object.keys(cost_stats[year]);
      months.sort((a,b) => {
        return this.monthNames[a] - this.monthNames[b];
      });
      if (_.isNil(this.state.startDate) || _.isNil(this.state.endDate)){
        monthsInRange = months;
      } else {
        if (+this.state.startDate.slice(-4) < year){
          startMonth = 'January';
        } else {
          startMonth = this.state.startDate.slice(0,-6);
        }
        if (+this.state.endDate.slice(-4) > year) {
          endMonth = 'December';
        } else {
          endMonth = this.state.endDate.slice(0, -6);
        }
        _.forEach(months, month => {
          if ((this.monthNames[month] >= this.monthNames[startMonth]) & (this.monthNames[month] <= this.monthNames[endMonth])){
            monthsInRange.push(month);
          }
        });
      }
      _.forEach(monthsInRange, month => {
        totalCostInRange += cost_stats[year][month]['Total Cost'];
        costPerVisitInRange += cost_stats[year][month]['Avg Cost Per Visit'];
        numVisitsInRange += cost_stats[year][month]['Number of Visits'];
        numMonthsInRange += 1;
        costPerMonth.push(Math.round(cost_stats[year][month]['Total Cost']));
        costPerVisitPerMonth.push(Math.round(cost_stats[year][month]['Avg Cost Per Visit']));
        visitsPerMonth.push(cost_stats[year][month]['Number of Visits']);
        data.push({
          monthYearPair: month+', '+year,
          totalCost: '$'+(Math.round(cost_stats[year][month]['Total Cost'] * 100)/100).toLocaleString(),
          totalVisits: cost_stats[year][month]['Number of Visits'],
          costPerVisit: '$'+(Math.round(cost_stats[year][month]['Avg Cost Per Visit'] * 100)/100).toLocaleString()
        });
      });
    });
    totalCostInRange = '$'+(Math.round(totalCostInRange*100)/100).toLocaleString();
    costPerVisitInRange = '$'+(Math.round((costPerVisitInRange/numMonthsInRange)*100)/100).toLocaleString();
    const dateValues = {categories: data.map(item => item.monthYearPair)};
    const yAxis1 = {
                    labels: {
                      enabled: true,
                      format: '{value}',
                      style: {
                        color: 'black'
                      } 
                    },
                    title: {
                      text: 'Cost ($)',
                      style: {
                        color: 'black'
                      }
                    },
                  };
    const yAxis2 = [{
                    labels: {
                      enabled: true,
                      format: '{value}',
                      style: {
                        color: 'black'
                      } 
                    },
                    title: {
                      text: 'Number of Visits',
                      style: {
                        color: 'black'
                      }
                    },
                  }];

    const yAxis3 = [{
                    labels: {
                      enabled: true,
                      format: '{value}',
                      style: {
                        color: 'black'
                      } 
                    },
                    title: {
                      text: 'Cost Per Visit ($)',
                      style: {
                        color: 'black'
                      }
                    }
                  }];
      
    const series = [{name: 'Total Cost Per month', data: costPerMonth, color: '#667fff', yAxis: 0}];
    const series2 = [{name: 'Number of Shop Visits', data: visitsPerMonth, color: '#24c0ae', yAxis: 0}];
    const series3 = [{name: 'Average Cost Per Visit', data: costPerVisitPerMonth, color: '#ffd223', yAxis: 0}];
    const startOptions = _.isNil(this.state.endDate) ? 
                        monthYearPairs.map(monthYear => <Select.Option value={monthYear} key={monthYear}>{monthYear}</Select.Option>) :
                        monthYearPairs.slice(0,monthYearPairs.indexOf(this.state.endDate)+1).map(
                          monthYear => <Select.Option value={monthYear} key={monthYear}>{monthYear}</Select.Option>);
    const endOptions = _.isNil(this.state.startDate) ? 
                      monthYearPairs.map(monthYear => <Select.Option value={monthYear} key={monthYear}>{monthYear}</Select.Option>) :
                      monthYearPairs.slice(monthYearPairs.indexOf(this.state.startDate)).map(
                      monthYear => <Select.Option value={monthYear} key={monthYear}>{monthYear}</Select.Option>);


  return(
    <>
    <div style={{'display':'flex', 'justify-content':'space-between'}}>
    <WidgetToolTip title='Fleet-wide maintenance costs divided up by month from service records' />
    <div style={{'display':'flex'}}>
      <p style={{margin:'3px'}}>From</p>
      <Select style={{margin:'3px'}}
        value={this.state.startDate}
        name='startDate' 
        id='startDate'
        onChange={(val) => this.onChangeStartDate(val)}
      >
        {startOptions}
      </Select>
      <p style={{margin:'3px'}}>To</p>
      <Select style={{margin:'3px'}}
        value={this.state.endDate}
        name='endDate' 
        id='endDate'
        onChange={(val) => this.onChangeEndDate(val)}
      >
        {endOptions}
      </Select>
    </div>
    </div>
    {!_.isEmpty(cost_stats) ?
    <>
      <InsightBoxWrapper>
        <InsightBox>
          <InsightTitle>Total Maintenance Cost</InsightTitle>
          <InsightValue>{totalCostInRange}</InsightValue>
        </InsightBox>
        <InsightBox>
          <InsightTitle>Maintenance Cost Per Visit</InsightTitle>
          <InsightValue>{costPerVisitInRange}</InsightValue>
        </InsightBox>
        <InsightBox>
          <InsightTitle>Total Shop Visits</InsightTitle>
          <InsightValue>{numVisitsInRange}</InsightValue>
        </InsightBox>
      </InsightBoxWrapper>
      <ChartWrapper>
        <ChartContainer>
          <HighchartsReact 
            options={
              {
              chart: {
                height: 500,
                type: 'column',
              },
              title: { text: undefined },
              exporting: { enabled: false },
              credits: { enabled: false },
              plotOptions: {
                series: {
                  borderWidth: 0,
                  dataLabels: {
                    enabled: false,
                    format: '{point.y:.2f}',
                  },
                },
              },
              xAxis: dateValues,
              yAxis: yAxis1,
              legend: {
                enabled: true,
              },
              tooltip: {
                enabled: true,
              },
              series: series,
            }}
          
        />
        </ChartContainer>
        <ChartContainer>
          <HighchartsReact 
            options={
              {
              chart: {
                height: 500,
                type: 'column',
              },
              title: { text: undefined },
              exporting: { enabled: false },
              credits: { enabled: false },
              plotOptions: {
                series: {
                  borderWidth: 0,
                  dataLabels: {
                    enabled: false,
                    format: '{point.y:.2f}',
                  },
                },
              },
              xAxis: dateValues,
              yAxis: yAxis2,
              legend: {
                enabled: true,
              },
              tooltip: {
                enabled: true,
              },
              series: series2,
            }}
          
        />
        </ChartContainer>
      </ChartWrapper>
      <ChartWrapper>
        <ChartContainer>
          <HighchartsReact 
            options={
              {
              chart: {
                height: 500,
                type: 'column',
              },
              title: { text: undefined },
              exporting: { enabled: false },
              credits: { enabled: false },
              plotOptions: {
                series: {
                  borderWidth: 0,
                  dataLabels: {
                    enabled: false,
                    format: '{point.y:.2f}',
                  },
                },
              },
              xAxis: dateValues,
              yAxis: yAxis3,
              legend: {
                enabled: true,
              },
              tooltip: {
                enabled: true,
              },
              series: series3,
            }}
          
        />
        </ChartContainer>
      </ChartWrapper>
    </>:
    <p>Please upload service records to gain access to these analytics</p>}
    </>
  );
  }
}


export default withRouter(observer(FleetWideCostsByMonth));