import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { Icon } from 'antd';

import { PitstopButton, PitstopModal, customFleets } from 'shared';

import { AppStore, CurrentUserStore, ShopStore } from 'stores';
import { UserRoles } from 'stores/Classes/UserObject';

import { DealershipChooser } from 'components';
import { CreateCarModal } from 'containers/CreateCar';
import { CarListTable } from 'containers/CarListTable';

const Wrapper = styled.div`
  position: relative;
`;

const AddCarButton = styled(PitstopButton)`
  position: absolute;
  top: 16px;
  right: 0px;
`;

const GuideContainer = styled.div`
  display: flex;

  i,
  p {
    margin: auto 5px;
  }
`;

const ModalContainer = styled(PitstopModal)`
  &&& {
    .ant-modal-footer {
      display: none;
    }
  }
`;

const OrderedList = styled.div`
  ol {
    margin: 10px 0px;
    counter-reset: item;
  }

  ol li {
    display: block;
  }

  ol li:before {
    content: counter(item) '. ';
    counter-increment: item;
    font-weight: bold;
  }
`;

class CarsPage extends Component {
  ADD_VEHICLE_MODAL_ID = 'ADD_VEHICLE_MODAL_ID';
  VIDEO_MODAL_ID = 'VIDEO_MODAL_ID';

  componentDidMount() {
    // redirect the user to the /vehicles page
    return this.props.history.push('/vehicles');
    // if (CurrentUserStore.user.role !== UserRoles.customer) {
    //   if (!ShopStore.loaded && !ShopStore.pending) {
    //     ShopStore.getShops();
    //   }
    // }
  }

  openAddCarModal = () => {
    AppStore.openModals.set(this.ADD_VEHICLE_MODAL_ID, true);
  };

  render() {
    return (
      <Wrapper>
        <AddCarButton icon="plus" onClick={this.openAddCarModal}>
          Add Vehicle
        </AddCarButton>
        <h1>All Vehicles</h1>
        <p>Click into each vehicle to explore each in greater detail</p>

        {customFleets.gmrv.includes(ShopStore.currentShop.id) && (
          <PitstopButton
            type="primary"
            onClick={() => AppStore.openModals.set(this.VIDEO_MODAL_ID, true)}
          >
            <GuideContainer>
              <Icon type="info-circle" />
              <p>User Guide</p>
            </GuideContainer>
          </PitstopButton>
        )}

        <ModalContainer
          title=""
          id={this.VIDEO_MODAL_ID}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          width="60%"
          height="50%"
          style={{ marginTop: 30 }}
          bodyStyle={{ padding: 0 }}
          onOk={() => {}}
          onCancel={() => () =>
            AppStore.openModals.set(this.VIDEO_MODAL_ID, false)}
        >
          <video width="100%" height="100%" controls muted>
            <source src={require('./vehicles_view.mp4')} type="video/mp4" />
          </video>
        </ModalContainer>

        <DealershipChooser />

        <CarListTable />

        {customFleets.gmrv.includes(ShopStore.currentShop.id) ? (
          <>
            <PitstopModal
              id={this.ADD_VEHICLE_MODAL_ID}
              onOk={() => {}}
              onCancel={() => {}}
              cancelButtonProps={{ style: { display: 'none' } }}
            >
              <OrderedList>
                <p>
                  <b>
                    Vehicle will be added automatically, please follow these
                    steps:
                  </b>

                  <ol>
                    <li>Connect the device (Firefly) to the vehicle</li>
                    <li>Take a trip / Perform a Test</li>
                    <li>
                      Once the trip is finished/test is performed, the vehicle
                      will automatically be added to Pitstop within an hour
                    </li>
                  </ol>
                </p>
              </OrderedList>
            </PitstopModal>
          </>
        ) : (
          <CreateCarModal id={this.ADD_VEHICLE_MODAL_ID} />
        )}
      </Wrapper>
    );
  }
}

export default withRouter(observer(CarsPage));
