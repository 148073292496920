import React, { Component } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import { Icon, Spin } from 'antd';

import HighchartsReact from 'highcharts-react-official';
import ReportsDataStore from 'stores/ReportsDataStore';

const NoIssuesSection = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  i {
    font-size: 36px;
    margin-right: 12px;
  }
`;

class BarChart extends Component {
  render() {
    const { history } = this.props;
    let { xAxis, yAxis, value, key } = this.props.data;

    let vehicleHealthScoreReport = toJS(ReportsDataStore.vehicleHealthScoreReport);

    if (!this.props.loaded) {
      return (
        <Spin tip="Loading..." />
      );
    }

    if (_.isEmpty(value)) {
      return (
        <NoIssuesSection>
          <Icon type="check-circle" theme="twoTone" />
          <p>You have no {key} data!</p>
        </NoIssuesSection>
      );
    }

    return (
      <>
        <HighchartsReact
          options={{
            chart: {
              height: 500,
              type: 'column',
            },
            title: { text: undefined },
            exporting: { enabled: false },
            credits: { enabled: false },
            plotOptions: {
              series: {
                borderWidth: 0,
                dataLabels: {
                  enabled: true,
                  format: '{point.y:.2f}',
                },
                events: {
                  click: function (event) {
                    let clickedPointName = event.point.category;

                    let clickedCar = _.filter(
                      vehicleHealthScoreReport.data,
                      (report) => report.carName === clickedPointName
                    );

                    if (!_.isEmpty(clickedCar)) {
                      history.push(`/car/${clickedCar[0].carId}/general`);
                    }
                  },
                },
              },
            },
            xAxis: {
              title: {
                text: `<b>${xAxis}</b>`,
              },
              categories: _.map(value, (v) => v.name),
              labels: {
                rotation: -45,
                style: {
                  fontSize: '10px',
                  fontFamily: 'Verdana, sans-serif',
                },
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: `<b>${yAxis}</b>`,
              },
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              enabled: false,
            },
            series: [
              {
                name: key,
                data: value,
              },
            ],
          }}
        ></HighchartsReact>
      </>
    );
  }

  static propTypes = {
    data: PropTypes.object,
    history: PropTypes.object,
    loaded: PropTypes.bool
  };
}

export default withRouter(observer(BarChart));
