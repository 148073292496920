import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Empty } from 'antd';

import { ReportsDataStore, CarStore } from 'stores';

import { DoughnutChart } from 'shared/PitstopUI/PitstopChart';

class IdlingTimeRatio extends Component {
  get data() {
    let totalIdlingTime = ReportsDataStore.totalIdlingTime;
    let totalDrivingTime = ReportsDataStore.totalDrivingTime;

    return {
      seriesName: 'time ratio',
      units: 'minutes',
      emptyMessage: 'Your fleet has no Idling alarms',
      value: [
        {
          name: 'Idling Time',
          color: '#f2706d',
          y: totalIdlingTime || 0,
        },
        {
          name: 'Driving Time',
          color: '#20ce9d',
          y: totalIdlingTime === 0 ? 0 : totalDrivingTime,
        },
      ],
    };
  }

  render() {
    if (CarStore.demo === false) return <Empty />;

    return (
      <DoughnutChart
        chart={{
          height: 250,
          margin: [0, 250, 0, 0],
          legend: {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'top',
            x: Number(`${window.innerWidth > 1600 ? -100 : 0}`),
            y: 90,
          },
        }}
        data={this.data}
        loaded={
          ReportsDataStore.drivingReport.loaded &&
          ReportsDataStore.alarmReport.loaded
        }
      />
    );
  }
}

export default observer(IdlingTimeRatio);
