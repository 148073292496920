import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Col, Row, Button, Checkbox } from 'antd';

import { PitstopModal } from 'shared';
import UsersTable from '../UsersTable';
import { AppStore, CurrentUserStore, ShopStore } from 'stores';
import { observer } from 'mobx-react';
import SuccessModal from './SuccessModal';
import {  toJS } from 'mobx';
import PropTypes from 'prop-types';

const StyledModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      .ant-modal-title {
        color: #ffffff;
      }
    }
    
    .ant-table-header {
      marginTop: 0 !important;  
      marginBottom: 0 !important;  
      background-color: transparent !important;
    }
  
  }
`;

class PMNotificationsModal extends Component {

  state = {
    notificationType: [],
    actions: [],
    users: [],
    saving: false
  };

  PM_ALLERT_SUCCESS_MODAL_ID = 'PM_ALLERT_SUCCESS_MODAL_ID';
  ADD_PM_NOTIFICATIONS_MODAL_ID = 'ADD_PM_NOTIFICATIONS_MODAL_ID';
  EDIT_ISSUE_ALLERT_MODAL_ID = 'EDIT_ISSUE_ALLERT_MODAL_ID';

  onNotificationTypeChange = (e) => {
    const { notificationType } = this.state;
    const newNotificationType = e.target.checked
      ? [...notificationType, e.target.value]
      : notificationType.filter(type => type !== e.target.value);

    this.setState({ notificationType: newNotificationType });
  };

  onActionsChange = (e) => {
    const { actions } = this.state;
    const newActions = e.target.checked
      ? [...actions, e.target.value]
      : actions.filter(action => action !== e.target.value);

    this.setState({ actions: newActions });
  };

  onChangeUserIdsSelected = (selectedUsers) => {
    this.setState({ users: selectedUsers.map(u => ({
      id: u.id,
      firstName: u.firstName,
      lastName: u.lastName,
      email: u.email,
      phone: u.phone
    }))});
  };

  onUpdatePmNotifications = async () => {
    const jsonObject = {
      notificationType: this.state.notificationType,
      actions: this.state.actions,
    };

    if (jsonObject.notificationType.length === 0) {
      AppStore.addError('Please select how to be notified');
      return;
    }

    if (jsonObject.actions.length === 0) {
      AppStore.addError('Please select what threshold to be notified at');
      return;
    }

    if(this.state.users.length === 0) {
      AppStore.addError('Please select user from the user list');
      return;
    }

    try {
    this.setState({ saving: true });
    AppStore.addLoading('Saving');

    let timezone = CurrentUserStore.user.settings.timezone;
    if (_.isNil(timezone)) {
      timezone = 'America/Toronto';
    }

    const shopNotifications = toJS(ShopStore.shopNotifications.get(ShopStore.currentShop.id));
    const pmAlertsNotifications = _.chain(shopNotifications)
        .get('data.emailConfiguration')
        .filter((a) => a.email_report_type === 'PmAlerts')
        .value();

    for (const user of this.state.users) {
      const userNotifications = pmAlertsNotifications.filter((a) => a.id_user === user.id);
        if (_.isEmpty(userNotifications)) {
          await ShopStore.sendPmAlertsConfig(
            ShopStore.currentShop.id,
            [user],
            moment(),
            timezone,
            jsonObject
          );
        } else {
          await ShopStore.updatePmAlertsConfig(
            userNotifications[0].id,
            ShopStore.currentShop.id,
            [user],
            jsonObject
          );
        }
        await ShopStore.getShopNotifications(ShopStore.currentShop.id, true);

        AppStore.openModals.set(this.ADD_PM_NOTIFICATIONS_MODAL_ID, false);
        AppStore.openModals.set(this.PM_ALLERT_SUCCESS_MODAL_ID, true);
        
    }
    } catch (err) {
      AppStore.addError(`Error in updating Pm alerts: ${err.message}`);
    } finally {
      this.setState({ saving: false });
      AppStore.removeLoading('Saving');
    }
  };
  

  async componentDidMount() {
    this.setState({
      notificationType: [],
      actions: []
    });
  }

  static get propTypes() {
    return {
      title: PropTypes.string,
      modalId: PropTypes.string,
    };
  }

  render() {
    let { saving } = this.state;
    let { title, modalId } = this.props;
    
    return (
      <>
        <SuccessModal
          modalId={this.PM_ALLERT_SUCCESS_MODAL_ID}
        >
          <Row gutter={[16, 16]} align="middle" style={{ textAlign: 'center' }}>
            <Col span={24}>
              <h5>PM Alert notification configs are saved.</h5>
            </Col>
          </Row>
        </SuccessModal>
        <StyledModal
          id={modalId}
          title={title}
          width={850}
          okText="Confirm"
          footer={<>
            <Button
              key="cancel"
              onClick={() => {
                AppStore.openModals.set(this.ADD_PM_NOTIFICATIONS_MODAL_ID, false);
              }}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={this.onUpdatePmNotifications}
              loading={saving}
            >
              Confirm
            </Button>
          </>}
        >
          <p style={this.textStyle}>
            An alert will be sent automatically whenever a vehicle reaches the threshold percentage selected.
          </p>

          <Row>
        <Col span={8}>
          <p style={{ fontWeight: 700 }}>Select how to be notified:</p>
          <div>
            <Checkbox
              value="Email"
              onChange={this.onNotificationTypeChange}
              checked={this.state.notificationType.includes('Email')}
            >
              Email
            </Checkbox>

            <Checkbox
              value="Text"
              onChange={this.onNotificationTypeChange}
              checked={this.state.notificationType.includes('Text')}
            >
              Text Message
            </Checkbox>
          </div>
        </Col>
        <Col span={16}>
          <p style={{ fontWeight: 700 }}>Select what threshold to be notified at:</p>
          <div>
            <Checkbox
              value="O"
              onChange={this.onActionsChange}
              checked={this.state.actions.includes('O')}
            >
              Overdue (100%+)
            </Checkbox>

            <Checkbox
              value="D"
              onChange={this.onActionsChange}
              checked={this.state.actions.includes('D')}
            >
              Due Soon (80 - 99%)
            </Checkbox>

            <Checkbox
              value="U"
              onChange={this.onActionsChange}
              checked={this.state.actions.includes('U')}
            >
              Upcoming (70 - 79%)
            </Checkbox>
          </div>
        </Col>
      </Row>

          {!AppStore.openModals.get(this.EDIT_ISSUE_ALLERT_MODAL_ID) && (
            <UsersTable
              selectedUserIds={[]}
              isUserSelectionEnabled={true}
              sendEmailConfiguration={() => {}}
              sendPriorityAlertsEmailConfiguration={() => {}}
              getCurrentSelectedPriorities={() => {}}
              isDateSelectionEnabled={false}
              isPrioritySelectionEnabled={false}
              enableUserSelection={() => {}}
              isVehicleSelectionEnabled={false}
              sendFleetSegmentConfiguration={() => {}}
              columnKeys={[
                'firstName',
                'lastName',
                'email',
                'phone',
                'pm_method',
                'pm_type'
              ]}
              isEditable={false}
              showSetupButton={false}
              onChangeUserIdsSelected={this.onChangeUserIdsSelected}
            />
          )}
        </StyledModal>
      </>
    );
  }
}

export default observer(PMNotificationsModal);

