import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import validator from 'validator';
import _ from 'lodash';

import { Row, Col, notification, Spin } from 'antd';

import {
  AppStore,
  CurrentUserStore,
  IntegrationStore,
  ShopStore,
} from 'stores';
import { ContactLink } from 'v3/components/pitstop';

const Container = styled.div`
  padding-right: 2vw;

  h1 {
    font-size: 2.8rem;
    font-weight: 600;
    color: #000000;
  }

  p {
    font-weight: 400;
    color: #8c8c8c;
    opacity: 0.9;
  }

  label {
    font-weight: 600;
    display: block;
  }

  input {
    margin: 1.4vh 0vh;
  }
`;

const DashLine = styled.div`
  opacity: 0.2;
  border-top: 2px dashed #000000;
`;

const ConnectButton = styled.button`
  cursor: pointer;
  border: none;
  background: #02103d;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: #ffffff;

  height: 3rem;
  min-width: 8rem;

  a {
    color: #ffffff;
  }

  &:disabled {
    background: #c4c4c4;
    cursor: not-allowed;
  }
`;

const ErrorField = styled.div`
  font-size: 0.8rem;
  color: red;
  font-style: italic;
`;

const Img1 = styled.img`
  height: 15vh;
  background: #ffffff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  border: ${props => props.isSelected && '2px solid #02103d'};
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;

  filter: grayscale(1);

  &.selected {
    filter: grayscale(0);
  }
`;

const OtherTelematic = styled.div`
  height: 5vh;
  background: #ffffff;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  border: ${props => props.isSelected && '2px solid #02103d'};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;

  p {
    color: #000000;
    margin: 0;

    span {
      font-weight: 600;
    }
  }
`;

class Onboarding extends Component {
  static propTypes = {
    isUserLogin: PropTypes.func,
    history: PropTypes.object.isRequired,
  };

  state = {
    isSelectedDemoData: false,
    isGeotabSelected: false,
    isSamsaraSelected: false,
    isOtherSelected: false,
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getError = (type, value) => {
    if (_.isEmpty(value) || _.isNil(value)) {
      return <ErrorField>{type} is required!</ErrorField>;
    }

    if (type === 'email' && !validator.isEmail(value)) {
      return <ErrorField>Email is invalid</ErrorField>;
    }

    return null;
  };

  onSubmit = async e => {
    e.preventDefault();

    this.setState({
      isSubmitted: true,
    });

    const { email, password } = this.state;

    if (email && password) {
      this.props.isUserLogin(true);

      notification.open({
        key: 'login',
        description: (
          <>
            <Spin size="large" style={{ marginRight: '0.6rem' }} />
            You are logging into Pitstop dashboard...
          </>
        ),
        duration: 0,
      });

      try {
        ShopStore.reset();
        await CurrentUserStore.login(email, password, async success => {
          if (success) {
            await CurrentUserStore.getCurrentUser();
          }
        });
      } catch (err) {
        notification.error({
          message: 'Error',
          key: 'error',
          description: <Container>{err.message}</Container>,
        });
      } finally {
        notification.close('login');
        this.props.isUserLogin(false);
      }
    }
  };

  setIntegrationType = () => {
    const { isGeotabSelected, isSamsaraSelected, isOtherSelected } = this.state;

    if (isGeotabSelected) {
      IntegrationStore.modalIntegrationForm = 'geotab-integration-form';
    }

    if (isSamsaraSelected) {
      IntegrationStore.modalIntegrationForm = 'samsara-integration-form';
    }

    if (isOtherSelected) {
      IntegrationStore.modalIntegrationForm = 'other-integration-form';
    }

    if (_.isNil(IntegrationStore.modalIntegrationForm)) {
      AppStore.addWarning('Please select your favor integration!');
      return;
    }

    this.props.history.push('/');
  };

  onSelectDemoData = () => {
    if (this.state.isSelectedDemoData) return;
    return this.setState({
      isSelectedDemoData: true,
    }, () => {
      IntegrationStore.modalIntegrationForm = 'demo-data';
      return this.props.history.push('/');
    });
  }

  render() {
    const { isGeotabSelected, isSamsaraSelected, isOtherSelected } = this.state;

    return (
      <Container id="register-form">
        <Row gutter={[16, 16]}>
          <Col>
            <h1>Connect Your Fleet</h1>
          </Col>

          <Col>
            <p>
              Connect your fleet with Pitstop by log in with Geotab, Samsara and
              many more !
            </p>

            <DashLine />
          </Col>

          <Col xs={12} style={{ margin: '3vh 0vh' }}>
            <Img1
              src={require('v3/assets/geotab.png')}
              alt="geotab"
              isSelected={isGeotabSelected}
              onClick={() =>
                this.setState({
                  isGeotabSelected: !isGeotabSelected,
                  isSamsaraSelected: false,
                  isOtherSelected: false,
                })
              }
              className={isGeotabSelected ? 'selected' : ''}
            />
          </Col>

          <Col xs={12} style={{ margin: '3vh 0vh' }}>
            <Img1
              src={require('v3/assets/samsara.png')}
              alt="samsara"
              isSelected={isSamsaraSelected}
              onClick={() =>
                this.setState({
                  isSamsaraSelected: !isSamsaraSelected,
                  isGeotabSelected: false,
                  isOtherSelected: false,
                })
              }
              className={isSamsaraSelected ? 'selected' : ''}
            />
          </Col>

          <Col span={24} style={{ display: 'flex' }}>
            <OtherTelematic isSelected={isOtherSelected}>
              <p
                onClick={() =>
                  this.setState({
                    isOtherSelected: !isOtherSelected,
                    isGeotabSelected: false,
                    isSamsaraSelected: false,
                  })
                }
              >
                Choose other <span>telematic integrations</span>
              </p>
            </OtherTelematic>
          </Col>

          <Col span={24} style={{ display: 'flex', marginTop: 10 }}>
            {this.state.isSelectedDemoData && <Spin style={{ marginRight: '0.6rem' }} />}
            <p
              style={{ color: this.state.isSelectedDemoData ? 'grey' : '#000000', cursor: 'pointer' }}
              onClick={this.onSelectDemoData}
            >
              Explore Pitstop with{' '}
              <span
                style={{
                  color: this.state.isSelectedDemoData ? 'grey' : '#000000',
                  fontWeight: 600,
                  textDecoration: 'underline',
                }}
              >
                Demo Data
              </span>
            </p>
          </Col>

          <Col span={24}>
            <ConnectButton
              disabled={
                !isGeotabSelected && !isSamsaraSelected && !isOtherSelected
              }
              onClick={this.setIntegrationType}>
              Connect
            </ConnectButton>
          </Col>

          <Col span={24} style={{ marginTop: 150 }}>
            <ContactLink />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(Onboarding);
