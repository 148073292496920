import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  .checkmark {
    display: inline-block;
    width: 22px;
    height: 22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
  }

  .checkmark_circle {
    position: absolute;
    width: 22px;
    height: 22px;
    background-color: #36b37e;
    border-radius: 11px;
    left: 0;
    top: 0;
  }

  .checkmark_stem {
    position: absolute;
    width: 3px;
    height: 9px;
    background-color: #fff;
    left: 11px;
    top: 6px;
  }

  .checkmark_kick {
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: #fff;
    left: 8px;
    top: 12px;
  }
`;

const CheckMark = () => {
  return (
    <Container>
      <span className="checkmark">
        <div className="checkmark_circle"></div>
        <div className="checkmark_stem"></div>
        <div className="checkmark_kick"></div>
      </span>
    </Container>
  );
};

export default CheckMark;
