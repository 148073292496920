import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CurrentUserStore from 'stores/CurrentUserStore';
import UserStore from 'stores/UserStore';
import { Form, Icon, Input, Button } from 'antd';

class EditProfilePage extends Component {
  updateUser = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        UserStore.updateUser(
          CurrentUserStore.user.id,
          {
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            phone: values.phone.trim()
          },
          success => {
            if (success) {
              CurrentUserStore.getCurrentUser();
            }
          }
        );
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Form onSubmit={this.updateUser} className="edit-profile-form">
          <Form.Item label="First Name">
            {getFieldDecorator('firstName', {
              rules: [
                { required: true, message: 'Please input your first name!' }
              ],
              initialValue: CurrentUserStore.user.firstName
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="First Name"
              />
            )}
          </Form.Item>
          <Form.Item label="Last Name">
            {getFieldDecorator('lastName', {
              rules: [
                { required: true, message: 'Please input your last name!' }
              ],
              initialValue: CurrentUserStore.user.lastName
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Last Name"
              />
            )}
          </Form.Item>
          <Form.Item label="Phone Number">
            {getFieldDecorator('phone', {
              rules: [{ message: 'Please input your phone number!' }],
              initialValue: CurrentUserStore.user.phone
            })(
              <Input
                prefix={
                  <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Phone"
              />
            )}
          </Form.Item>
          <Form.Item label="Email">
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  disabled: true,
                  message: 'Please input your email!'
                }
              ],
              initialValue: CurrentUserStore.user.email
            })(
              <Input
                disabled
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Email"
              />
            )}
          </Form.Item>
          <Form.Item label="Role">
            {getFieldDecorator('role', {
              rules: [
                {
                  required: true,
                  disabled: true,
                  message: 'Please input your role!'
                }
              ],
              initialValue: CurrentUserStore.user.displayedRole
            })(
              <Input
                disabled
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Role"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Update Info
            </Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }
}

EditProfilePage.propTypes = {
  form: PropTypes.object.isRequired
};

export default Form.create({ name: 'edit-profile-form' })(EditProfilePage);
