import React from 'react';
import { Switch } from 'antd';
// import PropTypes from 'prop-types';

export function PitstopSwitch(props) {
  const { ...otherProps } = props;
  return <Switch {...{ ...otherProps }} />;
}
//
// PitstopSwitch.propTypes = {
//   type: PropTypes.string.isRequired,
//   children: PropTypes.node
// };
//
// PitstopSwitch.defaultProps = {
//   type: 'primary'
// };
