import React from 'react';
import { Row, Col } from 'antd';
import { PropTypes } from 'prop-types';

import { Button, Icon } from 'antd';
import { ShopStore } from 'stores';

const PurchaseOrdersPageHeader = ({ onClickAdd, title, hideActions }) => {
  return (
    <Row>
      <Col span={hideActions ? 24 : 18}>
        <h1>{title}</h1>
      </Col>
      {!hideActions && (
        <Col span={6}>
          <div style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={() => onClickAdd()}>
              <Icon type="plus" />
              Add{' '}
              {ShopStore.currentShop.id === 374 ? 'Invoice' : 'Purchase Order'}
            </Button>
          </div>
        </Col>
      )}
    </Row>
  );
};

PurchaseOrdersPageHeader.propTypes = {
  onClickAdd: PropTypes.func,
  title: PropTypes.string,
  hideActions: PropTypes.bool,
};

PurchaseOrdersPageHeader.defaultProps = {
  onClickAdd: () => {},
  title: ShopStore.currentShop.id === 374 ? 'Invoices' : 'Purchase Orders',
  hideActions: false,
};

export default PurchaseOrdersPageHeader;
