import React, { Component } from 'react';
import { computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { PitstopTable } from 'shared';
import { showDemoVinOrRealVin } from 'shared/utils';
import { CarStore, ShopStore, TableStore, UserStore } from 'stores';

class SelectCars extends Component {
  propTypes = {
    rowSelection: PropTypes.object,
  };

  tableStore = new TableStore();

  columns = [
    {
      dataIndex: 'vin',
      filter: true,
      title: 'VIN',
      width: 250,
      render: (vin) => {
        return showDemoVinOrRealVin(vin);
      },
    },
    { dataIndex: 'carName', filter: true, title: 'Unit ID', width: 150 },
    { dataIndex: 'make', filter: true, title: 'Make', width: 150 },
    { dataIndex: 'model', filter: true, title: 'Model', width: 150 },
    { dataIndex: 'year', filter: true, title: 'Year', width: 150 },
    {
      dataIndex: 'userId',
      filter: true,
      title: 'Driver',
      width: 150,
      render: (userId) => {
        let user = UserStore.data.get(userId);

        return (
          <p>
            {user?.firstName} {user?.lastName}
          </p>
        );
      },
    },
  ];

  get data() {
    let data = _.map(this.tableStore.data, (id) => CarStore.data.get(id));

    return _.filter(data, Boolean);
  }

  render() {
    const { selectedCarIds, onChange, type } = this.props.rowSelection;

    return (
      <PitstopTable
        id={`${ShopStore.currentShop.id}-cars-select-list`}
        data={this.data}
        columns={this.columns}
        fetchData={(filters, table) => {
          CarStore.getShopCarsForTable(
            filters,
            table,
            ShopStore.currentShop.id
          );
        }}
        getTableStore={(tableStore) => (this.tableStore = tableStore)}
        height={400}
        rowSelection={{
          selectedRowKeys: selectedCarIds,
          onChange: onChange,
          hideDefaultSelections: true,
          type,
          selections: [
            {
              key: 'all-data',
              text: 'Select All Data',
              onSelect: (e) => {
                this.setState({
                  selectedCars: e,
                });
              },
            },
          ],
        }}
        rowKey="id"
      />
    );
  }
}

decorate(SelectCars, {
  tableStore: observable,
  data: computed,
});

export default observer(SelectCars);
