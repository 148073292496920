import { decorate } from 'mobx';

import AbstractStore from './abstract/AbstractStore';
import { webServiceProvider } from '../shared';


class OdometerSnapshotStore extends AbstractStore {
  abortController = new AbortController();

  /**
   * Get odometer snapshots in a range of months.
   * @param {Number} shopId
   * @param {Moment} monthFrom
   * @param {Moment} monthTo
   * @returns a list of odometer snapshots
   */
  getShopOdometerSnapshotInRange = async (shopId, monthFrom, monthTo) => {
    if (!shopId) {
      return [];
    }

    // abort previous request
    this.abortController.abort();
    this.abortController = new AbortController();

    const data = await webServiceProvider.getMany(
      'v1/odometer-snapshot',
      {
        shopId: shopId,
        monthFrom: monthFrom.startOf('month').format('YYYY-MM-DD'),
        monthTo: monthTo.endOf('month').format('YYYY-MM-DD'),
      },
      this.abortController.signal
    ).then(r => r.data);

    return data;
  };
}

decorate(OdometerSnapshotStore, {});

export default new OdometerSnapshotStore();
