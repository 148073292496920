import AppStore from './App';
import _ from 'lodash';

class GeotabConnectorC {
  MessageType = {
    GET_CREDENTIALS: 'get_credentials',
    SET_COOKIE: 'set_cookie',
    GET_COOKIE: 'get_cookie'
  };

  _getCredentialsCallback;
  geotabHost;
  listenerIsRegistered = false;

  requestsWithResponse = []

  listener(message) {
    if (!this._isValidOrigin(message.origin)) return;
    if (AppStore.getGeotabSSOVersion() > 1) {
      this.geotabHost = message.origin;
      if (message.data === undefined) return;
      const { requestId, data } = message.data;
      const responsePromise = this.requestsWithResponse[requestId];
      if (responsePromise === undefined) return;
      responsePromise(data);
      delete this.requestsWithResponse[requestId];
    } else {
      if (!_.isNil(this._getCredentialsCallback)) {
        this._getCredentialsCallback(message.data, message.origin);
        this._getCredentialsCallback = null;
      }
    }
  }

  registerListener() {
    if (this.listenerIsRegistered) return;
    this.listenerIsRegistered = true;
    window.addEventListener(
      'message',
      message => {
        this.listener(message);
      },
      false
    );
  }

  _uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r && 0x3) || 0x8);
      return v.toString(16);
    });
  }

  _sendMessage(message) {
    let geotabHost = '*';
    if (this.geotabHost !== undefined) geotabHost = this.geotabHost;
    if (AppStore.getGeotabSSOVersion() > 1) message.requestId = this._uuidv4();
    window.parent.postMessage(message, geotabHost);
    return message.requestId;
  }

  async _sendMessageWithResponse(message) {
    const requestId = this._sendMessage(message);
    if (AppStore.getGeotabSSOVersion() <= 1) return;
    const timeout = new Promise((_r, rej) => setTimeout(() => rej('Timeout'), 1000));
    const response = new Promise((resolve, _) => {
      this.requestsWithResponse[requestId] = resolve;
    });
    return Promise.race([response, timeout]);
  }

  async getCredentials(getCredentialsCallback) {
    let credentials;
    if (AppStore.getGeotabSSOVersion() > 1) {
      credentials = await this._sendMessageWithResponse({ type: this.MessageType.GET_CREDENTIALS });
    } else {
      this._getCredentialsCallback = getCredentialsCallback;
      return this._sendMessageWithResponse(this.MessageType.GET_CREDENTIALS);
    }

    return {
      credentials,
      origin: this.geotabHost
    };
  }

  setCookie(name, value) {
    const message = {
      type: this.MessageType.SET_COOKIE,
      name,
      value
    };
    this._sendMessage(message);
  }

  getCookie(name) {
    const message = {
      type: this.MessageType.GET_COOKIE,
      name
    };
    return this._sendMessageWithResponse(message);
  }

  _isValidOrigin(origin) {
    const splitHttps = origin.split('://');
    if (splitHttps[0] !== 'https') return false;
    const originWithoutHttps = splitHttps[1];
    const validHosts = ['geotab.com', 'att.com'];
    const host = originWithoutHttps.split('.').slice(1).join('.');
    return validHosts.includes(host);
  }
}

const GeotabConnector = new GeotabConnectorC();
export default GeotabConnector;
