import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { Empty, Icon, Input, Table } from 'antd';

import {
  TableStore,
  CarStore,
  CurrentUserStore,
  ShopStore,
  ReportsDataStore,
} from 'stores';

import { calcMileage, convertSecondsToHours } from 'helpers/unitCalculations';

import { PitstopTable } from 'shared';
import { WidgetToolTip } from 'shared/PitstopUI/PitstopChart';
import { DefSystemHealthSamples } from './sample-data';

/*const NoIssuesSection = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  i {
    font-size: 36px;
    margin-right: 12px;
  }
`;*/

const VehicleContainer = styled.div`
  p {
    margin-bottom: 0px;
  }
`;

const InputContainer = styled(Input)`
  margin: 20px 0px;
  width: 50%;
`;

class DefSystemHealth extends Component {
  static propTypes = {
    history: PropTypes.object,
    setStore: PropTypes.func
  };

  state = {
    vin: '',
  };

  tableStore = new TableStore();

  get data() {
    return this.tableStore.data;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.vin !== prevState.vin) {
      await this.filterValueFetch('vin', this.state.vin);
    }
  }

  columns = [
    {
      title: 'Vehicle',
      key: 'make',
      render: (record) => {
        let mmy = `${record.car_make || ''} ${record.car_model ||
          ''} ${record.car_year || ''}`;

        return (
          <VehicleContainer>
            <Link to={`car/${record.id_car}`}>{record.car_name || mmy}</Link>
            <p>{record.car_name && mmy}</p>
            <p>{record.vin}</p>
            <p>
              {`${Math.round(calcMileage(record.mileage_total)).toReadable()} 
        ${CurrentUserStore.user.displayedOdometer}`}
            </p>
            <p>
              {!_.isNaN(record.engine_hours)
                ? `${Math.round(
                    convertSecondsToHours(record.engine_hours)
                  ).toReadable()} engine hours`
                : ''}
            </p>
          </VehicleContainer>
        );
      },
    },
    {
      title: 'Number of alerts',
      key: 'alerts',
      sorter: true,
      render: (record) => {
        if (_.get(record, 'def_system_health_alert'))
          return _.get(record, 'def_system_health_alert')[
            'four_week_rapid_drain_alerts'
          ];
      },
    },
  ];

  filterValue = async (key, value) => {
    if (this.tableStore) {
      this.setState({ [key]: value });
    }
  };

  filterValueFetch = async (key, value) => {
    const filter = { ...this.tableStore.filter, [key]: value };

    if (!value || value.length === 0) {
      delete filter[key];
    }

    await this.getReport(this.tableStore, {
      offset: 0,
      filter,
    });
  };

  getReport = async (
    tableStore,
    filters,
    shopId = ShopStore.currentShop.id
  ) => {
    if (_.isNil(filters.sort)) {
      filters.sort = '-alerts';
    }
    await ReportsDataStore.getDefSystemHealthReport(
      shopId,
      filters,
      tableStore
    );
  };

  filterValue = async (key, value) => {
    if (this.tableStore) {
      this.setState({ [key]: value });
    }
  };

  render() {
    let shopId = ShopStore.currentShop.id;
    if (CarStore.demo) {
      return (
        <Table
          columns={this.columns}
          dataSource={DefSystemHealthSamples}
          pagination={{ pageSize: 5 }}
          onRowClick={(car) => this.props.history.push(`car/${car.id_car}`)}
        />
      );
    }

    if (CarStore.demo === false) return <Empty />;
    return (
        <>
        <WidgetToolTip title='This table shows how many DEF alerts each vehicle has had in the past month. An alert is triggered every time the tank gets low enough that it is predicted to run out within 3 days. This could be due to drivers not filling their DEF very often or vehicles using more DEF than normal.'/>
        <InputContainer
          placeholder="Vin"
          value={this.state.vin}
          prefix={<Icon type="search" />}
          onChange={(e) => this.filterValue('vin', e.target.value)}
        />
        <PitstopTable
          id={`${shopId}-def-system-health`}
          columns={this.columns}
          data={this.data}
          rowKey="id"
          limit={5}
          fetchData={async (filters, table) => {
            if (shopId !== -1) {
              await this.getReport(table, filters, shopId);
            }
          }}
          getTableStore={(tableStore) => {
            this.tableStore = tableStore;
            if (this.props.setStore){
              this.props.setStore(tableStore);
            }
            
          }}
          onRowClick={(car) => this.props.history.push(`car/${car.id_car}`)}
        />
        </>
    );
  }
}

decorate(DefSystemHealth, {
  tableStore: observable,
  data: computed,
});

export default withRouter(observer(DefSystemHealth));
