import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Radio, TimePicker, Checkbox, Tag, Spin, Button } from 'antd';
import { PitstopModal } from 'shared';
import styled from 'styled-components';
import { AppStore } from 'stores';

const StyledModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      .ant-modal-title {
        color: #ffffff;
      }
    }
  }
`;

const CHANGE_USER_NOTIFICATIONS_MODAL_ID = 'CHANGE_USER_NOTIFICATIONS_MODAL_ID';

const UserNotificationsModal = ({
  modalId,
  title,
  isLoading = false,
  onUpdateUserNotifications = () => { },
  notificationsTypesIds = null,
  checkedPriorityList = [],
  defaultSelectedHour = null,
  defaultSelectedWeekDay = null,
  loading = false,
}) => {
  const [selectedWeekDay, setSelectedWeekDay] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const [priorityCheckedList, setPriorityCheckedList] = useState([]);

  const weekDays = [
    { weekDay: 'Sun', value: 0 },
    { weekDay: 'Mon', value: 1 },
    { weekDay: 'Tue', value: 2 },
    { weekDay: 'Wed', value: 3 },
    { weekDay: 'Thu', value: 4 },
    { weekDay: 'Fri', value: 5 },
    { weekDay: 'Sat', value: 6 }
  ];

  useEffect(
    () => {
      setSelectedWeekDay(defaultSelectedWeekDay !== undefined ? parseInt(defaultSelectedWeekDay) : null);
      setSelectedHour(defaultSelectedHour);
      setPriorityCheckedList(checkedPriorityList);
    },
    [checkedPriorityList, defaultSelectedHour, defaultSelectedWeekDay],
  );

  const onChangePriority = (priority) => {
    if (_.indexOf(priorityCheckedList, priority) !== -1) {
      _.remove(priorityCheckedList, (item) => item === priority);
    } else {
      priorityCheckedList.push(priority);
    }

    setPriorityCheckedList([...priorityCheckedList]);
  };

  return (
    <StyledModal
      id={modalId}
      title={title}
      width={850}
      okText="Confirm"
      footer={(<>
        <Button
          key="back"
          onClick={() => {
            AppStore.openModals.set(CHANGE_USER_NOTIFICATIONS_MODAL_ID, false);
          }}
        >
          Cancel
        </Button>
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => onUpdateUserNotifications({
            selectedWeekDay, selectedHour, priorityCheckedList, notificationsTypesIds
          })}
        >
          Save
        </Button>
      </>)}
    >
      {isLoading ?
        (
          <Spin size="large" />
        )
        : (
          <>
            <Row>
              <Col span={12}>
                <p style={{ fontWeight: 700 }}>Run every:</p>
                <Radio.Group
                  defaultValue={selectedWeekDay}
                  value={selectedWeekDay}
                  onChange={(e => setSelectedWeekDay(e.target.value))}
                >
                  { weekDays.map((value, index) => {
                    return <Radio.Button key={index} value={value.value}>{value.weekDay}</Radio.Button>;
                  })}
                </Radio.Group>
              </Col>
              <Col span={12}>
                <p style={{ fontWeight: 700 }}>At:</p>
                <TimePicker
                  use12Hours
                  style={{width: 300}}
                  defaultValue={selectedHour}
                  value={selectedHour}
                  onChange={(time, _) => setSelectedHour(time)}
                />
              </Col>
            </Row>

            <p style={{ fontWeight: 700, marginTop: 40 }}>
              Select which priorities to subscribe:
            </p>

            <Row>
              <Col span={24}>
                <Checkbox
                  checked={_.indexOf(priorityCheckedList, 'minor') !== -1}
                  onChange={() => onChangePriority('minor')}
                >
                  <Tag color="#dec431">Minor</Tag>
                </Checkbox>

                <Checkbox
                  checked={_.indexOf(priorityCheckedList, 'major') !== -1}
                  onChange={() => onChangePriority('major')}
                >
                  <Tag color="#e28931">Major</Tag>
                </Checkbox>

                <Checkbox
                  checked={_.indexOf(priorityCheckedList, 'critical') !== -1}
                  onChange={() => onChangePriority('critical')}
                >
                  <Tag color="#f92d2e">Critical</Tag>
                </Checkbox>
              </Col>
            </Row>
          </>
        )
      }
    </StyledModal>
  );
};

UserNotificationsModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  onUpdateUserNotifications: PropTypes.func,
  notificationsTypesIds: PropTypes.any,
  checkedPriorityList: PropTypes.array,
  defaultSelectedHour: PropTypes.any,
  defaultSelectedWeekDay: PropTypes.any,
  loading: PropTypes.bool,
};

export default UserNotificationsModal;
