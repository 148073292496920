import React from 'react';
import PropTypes from 'prop-types';

const SentMessageCell = ({ conversation }) => {
  return (
    <div className="gx-chat-item">
      {/* <Avatar
        className="gx-size-40 gx-align-self-end"
        src="https://via.placeholder.com/150x150"
        alt={conversation.name}
      /> */}

      <div className="gx-bubble-block">
        <div className="gx-bubble">
          <div className="gx-message">
            {conversation.message && <p>{conversation.message}</p>}

            {conversation.mediaUrl && (
              <div style={{ height: 350 }}>
                <img
                  height="100%"
                  width="100%"
                  src={conversation.mediaUrl}
                  alt={conversation.altText}
                />
              </div>
            )}
          </div>
          <div className="gx-time gx-text-muted gx-text-left gx-mt-2">
            {conversation.sentAt}
          </div>
        </div>
      </div>
    </div>
  );
};

SentMessageCell.propTypes = {
  conversation: PropTypes.object,
};

export default SentMessageCell;
