import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { computed, decorate, observable, observe } from 'mobx';
import moment from 'moment';
import _ from 'lodash';

import { Button } from 'antd';

import { PitstopTable } from 'shared';

import { IntegrationStore, ShopStore, TableStore } from 'stores';

class IntegrationTable extends Component {
  static propTypes = {
    history: PropTypes.object,
    setTableStore: PropTypes.func,
  };

  tableStore = new TableStore();

  disposer = observe(IntegrationStore, 'isReloaded', async () => {
    if (IntegrationStore.isReloaded) {
      this.tableStore.reset();
    }
  });

  get data() {
    let data = _.map(this.tableStore.data, (id) =>
      IntegrationStore.data.get(id)
    );

    return _.filter(data, Boolean);
  }

  componentWillUnmount() {
    this.disposer();
  }

  columns = [
    { title: 'Account Id', dataIndex: 'accountId' },
    { title: 'Alias', dataIndex: 'alias' },
    { title: 'Type', dataIndex: 'type' },
    {
      title: 'Last Sync Status',
      dataIndex: 'lastSync.status',
      render: (val, row) => {
        const lastSyncStatusOk = _.get(row, 'lastSync.isStatusOk');
        const lastSyncDetail = _.get(row, 'lastSync.statusDetail');

        const [color, text] = (() => {
          if (!lastSyncDetail) return ['#000000', 'no record'];

          if (lastSyncStatusOk) return ['#52c41a', 'ok'];
          if (
            _.chain(lastSyncDetail)
              .find({ status: 'fail' })
              .get('count')
              .value()
          ) {
            return ['#f5222d', 'failed'];
          }
          return ['#faad14', 'in progress'];
        })();

        return <span style={{ color }}>{text}</span>;
      },
    },
    {
      title: 'Last Sync At',
      dataIndex: 'lastSync.timestamp',
      render: (val) => {
        if (!val) return;
        return moment(val).fromNow();
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: () => {
        return (
          <span>
            <Button type={'danger'} onClick={() => alert('not implemented')}>
              Delete
            </Button>
          </span>
        );
      },
    },
  ];

  render() {
    let shopId = ShopStore.currentShop.id;

    return (
      <PitstopTable
        id={`${shopId}-integrationTable`}
        columns={this.columns}
        data={this.data}
        rowKey="accountId"
        fetchData={(filters, tableStore) => {
          IntegrationStore.getIntegrationRecordsByShop(
            ShopStore.currentShop.id,
            tableStore
          );
        }}
        getTableStore={(tableStore) => {
          this.tableStore = tableStore;
        }}
        onRowClick={() => {
          this.props.history.push(
            `/shops/${ShopStore.currentShop.id}/geotab/sync-status`
          );
        }}
      />
    );
  }
}

decorate(IntegrationTable, {
  tableStore: observable,
  data: computed,
});

export default withRouter(observer(IntegrationTable));
