import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card } from 'antd';


import ReportHistoryTable from './ReportHistoryTable';
import CurrentUserStore from 'stores/CurrentUserStore';
import AlgorithmScoreHistoryChart from './AlgorithmScoreHistoryChart';

const CardWrapper = styled.div`
  display: grid;
  gridTemplateColumns: 1fr 1fr 1fr 1fr;
`;

const StatusDiv = styled.div`
  
`;

const StatusCard = styled.div`
  display: grid
  gridTemplateColumns: 1fr 1fr
`;

const CardTitle = styled.p`
  font-size: 18px;
  color: black
`;

const CardInfo = styled.p`
  font-size: 25px;
  color: #5e5e5e;
`;

const ExplanationText = styled.p`
  font-size: 15;
`;

const cardStyleTemplate = {
  'boxShadow': '0 0 10px 3px #f2f2f2',
  'borderRadius': '15px',
  margin: '5px'
};

const reportDateStyle = {
  ...cardStyleTemplate,
  'gridColumnStart': '1',
  'gridColumnEnd': '2',
  'gridRowStart': '4',
  'gridRowEnd': '6'
};

const statusBoxStyle = {
  ...cardStyleTemplate,
  'gridColumnStart': '2',
  'gridColumnEnd': '3',
  'gridRowStart': '4',
  'gridRowEnd': '6'
};

const tripBoxStyle = {
  ...cardStyleTemplate,
  'gridColumnStart': '3',
  'gridColumnEnd': '5',
  'gridRowStart': '1',
  'gridRowEnd': '6'
};

const explanationBoxStyle = {
  ...cardStyleTemplate,
  'gridColumnStart': '1',
  'gridColumnEnd': '3',
  'gridRowStart': '1',
  'gridRowEnd': '2'
};
const resultHistoryChartStyle = {
  ...cardStyleTemplate,
  'gridColumnStart': '1',
  'gridColumnEnd': '3',
  'gridRowStart': '2',
  'gridRowEnd': '4'
};
/*
const tripHistoryBoxStyle = {
  ...cardStyleTemplate,
  'gridColumnStart': '3',
  'gridColumnEnd': '5',
  'gridRowStart': '4',
  'gridRowEnd': '6'
};

const firstGraphBoxStyle = {
  ...cardStyleTemplate,
  'gridColumnStart': '1',
  'gridColumnEnd': '3',
  'gridRowStart': '4',
  'gridRowEnd': '6'
};*/

const changeTimeZone = time => {
  const timeZone = CurrentUserStore.user.settings.timezone;
  return (typeof time === 'string' ? new Date(time) : time).toLocaleString('en-US', {timeZone: timeZone});
};

const InsightsCardWrapper = (props) => {
  /* Caching format
  const tripDate = props.algorithmInfo.trip_date.slice(0,-5).split('T');
  const tripDateDisplay = tripDate[0] + ' ' + tripDate[1];
  const algorithmStatus = props.algorithmInfo.report.status;
  const algorithmStatusColour = algorithmStatus === 'pass' ? {color: '#52c41a'} : {color: '#ff4d4f'};
  const algorithmExplanation = props.algorithmInfo.report.explanation; */
  //Non-caching format
  const tripDateDisplay = changeTimeZone(props.algorithmInfo[0].trip_date);
  const algorithmStatus = props.algorithmInfo[0].report.status;
  const algorithmStatusColour = algorithmStatus === 'pass' ? {color: '#52c41a'} : {color: '#ff4d4f'};
  const algorithmExplanation = props.algorithmInfo[0].report.explanation;
  return(
    <CardWrapper>
      <Card style={reportDateStyle} > 
        <CardTitle>Report Date</CardTitle>
        <CardInfo>{tripDateDisplay}</CardInfo>
      </Card>
      <Card style={statusBoxStyle} >
        <StatusCard>
          <StatusDiv>
            <CardTitle>Status</CardTitle>
            <CardInfo style={algorithmStatusColour}>{algorithmStatus}</CardInfo>
          </StatusDiv>
          {/*
          <StatusDiv>
            <CardTitle>Reason</CardTitle>
            <CardInfo>N/A</CardInfo>
          </StatusDiv>*/}
        </StatusCard>
      </Card>
      {/*
      <Card style={tripBoxStyle} > 
        <CardTitle>Trip Map</CardTitle>
        <CarTripsView />
      </Card>*/}
      <Card style={explanationBoxStyle}>
        <CardTitle>Result Explanation</CardTitle>
        <ExplanationText>{algorithmExplanation}</ExplanationText>
      </Card>
      {/*Not in fallback version
      <Card style={firstGraphBoxStyle}>
        <CardTitle>Chart</CardTitle>
        <ExplanationText>Coming soon...</ExplanationText>
      </Card> */}
      <Card style={tripBoxStyle} >
        <CardTitle>Report History</CardTitle>
        <ReportHistoryTable history={props.algorithmInfo} item={props.algorithmInfo[0].item} />{/* Add this back for the caching://.testHistory} />*/}
      </Card>
      <Card style={resultHistoryChartStyle}>
        <CardTitle>Result Score History</CardTitle>
        <AlgorithmScoreHistoryChart algorithmInfo={props.algorithmInfo}/>
      </Card>
    </CardWrapper>
  );
};

InsightsCardWrapper.propTypes = {
  algorithmInfo: PropTypes.object
};

export default InsightsCardWrapper;