import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';

import CurrentUserStore from 'stores/CurrentUserStore';

import SingleTrip from './map/SingleTrip';
import AnalyticsPlayground from 'containers/AnalyticsView/AnalyticsPlayground';
import EmmissionTable from './EmissionTable';
import MultipleTrips from './map/MultipleTrips';
import { customFleets } from 'shared';

const DetailArea = styled.div`
  flex-direction: column;
  width: 100%;
  height: 575px;
  overflow-y: scroll;
`;

class TripDetailArea extends Component {
  state = {
    selectedTrip: null,
    currentMarkerTime: 0,
    emissionStartTimestamp: null,
    emissionEndTimestamp: null,
    selectedGraphKeys: ['Speed[km/h]', 'RPM[min-1]', '210C', '210D'],
  };

  setCurrentMarkerTime = async (x) => {
    this.setState({ currentMarkerTime: x });
  };

  componentDidMount() {
    if (!_.isEmpty(this.props.selectedTrip)) {
      this.setState({
        selectedTrip: this.props.selectedTrip,
        emissionStartTimestamp: this.props.selectedTrip.timeStart,
        emissionEndTimestamp: this.props.selectedTrip.timeEnd,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedTrip.id !== prevProps.selectedTrip.id) {
      if (!_.isEmpty(this.props.selectedTrip)) {
        this.setState({
          selectedTrip: this.props.selectedTrip,
          emissionStartTimestamp: this.props.selectedTrip.timeStart,
          emissionEndTimestamp: this.props.selectedTrip.timeEnd,
        });
      }
    }
  }

  render() {
    const {
      car,
      snapToRoad,
      showEmissionTable,
      showAnalytics,
      isAllTripsOnMap,
    } = this.props;

    const {
      selectedTrip,
      emissionStartTimestamp,
      emissionEndTimestamp,
    } = this.state;

    if (selectedTrip) {
      return (
        <DetailArea>
          {!isAllTripsOnMap && (
            <>
              <EmmissionTable
                car={car}
                startDate={moment(emissionStartTimestamp * 1000)}
                endDate={moment(emissionEndTimestamp * 1000)}
                showEmissionTable={showEmissionTable}
              />

              <AnalyticsPlayground
                tripId={selectedTrip.tripId}
                showAnalytics={
                  customFleets.npl.includes(this.props.car.shopId)
                    ? true
                    : showAnalytics
                }
                showEmissionTable={showEmissionTable}
                alarms={toJS(selectedTrip.alarms)}
                setCurrentMarkerTime={this.setCurrentMarkerTime}
                car={car}
                startDate={moment.tz(
                  selectedTrip.timeStart * 1000,
                  CurrentUserStore.user.settings.timezone
                )}
                endDate={moment.tz(
                  selectedTrip.timeEnd * 1000,
                  CurrentUserStore.user.settings.timezone
                )}
                setEmissionTime={(time) => {
                  this.setState({
                    emissionStartTimestamp: time.startTimestamp,
                    emissionEndTimestamp: time.endTimestamp,
                  });
                }}
                selectedGraphKeys={this.state.selectedGraphKeys}
                onSelectGraphKeys={(keys) =>
                  this.setState({
                    selectedGraphKeys: keys,
                  })
                }
              />
            </>
          )}

          {!customFleets.npl.includes(this.props.car.shopId) && (
            <div
              style={{
                height: showAnalytics || showEmissionTable ? 250 : '100%',
              }}
            >
              {isAllTripsOnMap ? (
                <MultipleTrips
                  selectedTrip={selectedTrip}
                  data={car.trips.data}
                  snapToRoad={snapToRoad}
                />
              ) : (
                <SingleTrip
                  selectedTrip={selectedTrip}
                  trips={car.trips.data}
                  currentMarkerTime={this.state.currentMarkerTime}
                  showAnalytics={showAnalytics}
                  showEmissionTable={showEmissionTable}
                  sorting={true}
                  snapToRoad={snapToRoad}
                />
              )}
            </div>
          )}
        </DetailArea>
      );
    }

    return (
      <DetailArea
        description={
          <span>Select a trip to see fuel consumption and map.</span>
        }
      />
    );
  }
}

TripDetailArea.propTypes = {
  car: PropTypes.object,
  trips: PropTypes.object,
  selectedTrip: PropTypes.object,
  showAnalytics: PropTypes.bool,
  showEmissionTable: PropTypes.bool,
  snapToRoad: PropTypes.bool,
  isAllTripsOnMap: PropTypes.bool,
};

export default observer(TripDetailArea);
