import React from 'react';
import { observer } from 'mobx-react';

import PropTypes from 'prop-types';

import DealershipChooser from 'components/DealershipChooser';
import SelectCustomerImg from 'assets/3SelectedCustomers.png';
import styled from 'styled-components';
import CarStore from 'stores/CarStore';
import { SelectableCarListTable } from 'containers/CarListTable';
import { SelectedUsers } from './components';

const SelectUserWrapper = styled.div`
  display: flex;
  .img {
    flex: 1;
    text-align: center;
    margin: 0 auto;
  }
  .img img {
    max-width: 100%;
  }
  .content {
    flex: 2;
    height: 160px;
    margin-bottom: 12px;
    overflow-y: auto;
  }
`;

class CampaignUserSection extends React.Component {
  static propTypes = {
    selectedCars: PropTypes.array,
    setValue: PropTypes.func.isRequired
  };

  render() {
    return (
      <React.Fragment>
        <h1>Choose users to send this to</h1>
        <DealershipChooser />
        <SelectUserWrapper>
          <div className="img">
            <img alt="Choose Your Users" src={SelectCustomerImg} />
          </div>
          <div className="content">
            <h3>Selected Users:</h3>
            <SelectedUsers
              selectedUsers={this.props.selectedCars
                .map(el => CarStore.data.get(el).userId)
                .filter(el => el !== undefined && el !== null)}
            />
          </div>
        </SelectUserWrapper>
        <h3>Select Users:</h3>
        <SelectableCarListTable
          id="selectableCarTable-Campaign"
          setValue={this.props.setValue}
          selectedCars={this.props.selectedCars}
        />
      </React.Fragment>
    );
  }
}

export default observer(CampaignUserSection);
