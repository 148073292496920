import React from 'react';
import { Icon } from 'antd';
import './IconWithBorders.css';
import PropTypes from 'prop-types';

const IconWithBorders = ({ icon, fontSize = '28' }) => {
  return (
    <div className="icon-container">
      <Icon type={icon} style={{ fontSize: `${fontSize}px` }} />
    </div>
  );
};

IconWithBorders.propTypes = {
  icon: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
};

export default IconWithBorders;
