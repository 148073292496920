import React, { Component } from 'react';
import { PitstopModal } from 'shared';
import { Col, Button, Radio, Row, TimePicker } from 'antd';
import UsersTable from '../UsersTable';
import moment from 'moment-timezone';
import { AppStore, ShopStore } from 'stores';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { reaction, toJS } from 'mobx';
import styled from 'styled-components';

const StyledModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      .ant-modal-title {
        color: #ffffff;
      }
    }
  }
`;

class EmailReportModal extends Component {
  static get propTypes() {
    return {
      modalId: PropTypes.string
    };
  }

  state = {
    selectedWeekDay: null,
    selectedUserIds: [],
    selectedHour: this.getNineAmHour(),
    saving: false
  };

  ADD_VEHICLE_HEALTH_REPORT_MODAL_ID = 'ADD_VEHICLE_HEALTH_REPORT_MODAL_ID';

  healthReportId;
  constructor(props) {
    super(props);

    reaction(() => ShopStore.shopNotifications.get(ShopStore.currentShop.id),
      notification => {
        // const emailsReports = _.get(toJS(notification), 'data.emailConfiguration', []);
        // const diagnosticEmailConfigs = _.filter(emailsReports, (i) => i.email_report_type === 'DiagnosticEmail');
        // if (_.isEmpty(diagnosticEmailConfigs)) return;
        // // this.healthReportId = diagnosticEmailConfigs.id;
        // this._updateStateFromDateObject(diagnosticEmailConfigs.send_next_email_at);
      });
  }

  weekDays = [
    { weekDay: 'Sun', value: 0 },
    { weekDay: 'Mon', value: 1 },
    { weekDay: 'Tue', value: 2 },
    { weekDay: 'Wed', value: 3 },
    { weekDay: 'Thu', value: 4 },
    { weekDay: 'Fri', value: 5 },
    { weekDay: 'Sat', value: 6 }
  ];

  getNineAmHour() {
    const nineAmHour = moment();
    nineAmHour.seconds(0);
    nineAmHour.minutes(0);
    nineAmHour.hour(9);
    return nineAmHour;
  }

  textStyle = {
    fontSize: 14
  };

  onChangeUserIdsSelected(selectedUserIds) {
    // selectedUserIds
    this.setState({selectedUserIds: selectedUserIds});
  }

  _updateStateFromDateObject(date) {
    if (_.isNil(date)) return;
    date = moment(date);
    this.setState({ selectedWeekDay: date.day() });
    this.setState({ selectedHour: date });
  }

  /**
  * _updateEmailReport - Updates the user's weekly report email settings.
  *
  * @returns {Boolean} True if success, false if failed.
  */
  async _updateEmailReport () {
    try {
      this.setState({ saving: true });
      // Destructure state variables
      const { selectedUserIds: selectedUsers, selectedWeekDay, selectedHour } = this.state;

      // Check if a day of the week and hour were selected
      if (_.isNil(selectedWeekDay)) {
        AppStore.addError('Please select a day of the week');
        return false;
      }
      if (_.isNil(selectedHour)) {
        AppStore.addError('Please select a time to send the weekly report');
        return false;
      }

      // Check if users were selected
      if (_.isNil(selectedUsers) || _.isEmpty(selectedUsers)) return;

      // Get shop notifications
      const shopNotifications = toJS(ShopStore.shopNotifications.get(ShopStore.currentShop.id));

      // Filter out diagnostic notifications
      const diagnosticNotifications = _.chain(shopNotifications)
        .get('data.emailConfiguration')
        .filter((a) => a.email_report_type === 'DiagnosticEmail')
        .value();

      // Generate Date Object
      const dateObject = ShopStore.generateDateObject(moment(), selectedWeekDay, selectedHour);

      // Iterates over selected users
      for (const user of selectedUsers) {
        const userReports = diagnosticNotifications.filter((a) => a.id_user === user.id);

        // If the user has no vehicle health reports create one with sendEmailVehicleHealthReportConfig()
        // Else update it with updateEmailVehicleHealthReportConfig()
        if (_.isEmpty(userReports)) {
          // Check if the user is saved using emails
          await ShopStore.checkAndRemoveEmailBasedNotificationsForUser(diagnosticNotifications, user);
          await ShopStore.sendEmailVehicleHealthReportConfig(
            ShopStore.currentShop.id,
            [user.id],
            dateObject,
            'America/Toronto'
          );
        } else {
          await ShopStore.updateEmailVehicleHealthReportConfig(
            userReports[0].id,
            ShopStore.currentShop.id,
            [user.id],
            dateObject,
            'America/Toronto'
          );
        }
      }

      // Close Email Report Modal and update shop notification
      AppStore.openModals.set(this.ADD_VEHICLE_HEALTH_REPORT_MODAL_ID, false);
      await ShopStore.getShopNotifications(ShopStore.currentShop.id, true);
    } catch (error) {
      console.error(error);
      AppStore.addError('Sorry, there was an error saving your changes. Please try again.');
    } finally {
      this.setState({ saving: false });
    }
  }

  render () {
    let { saving } = this.state;

    return (
      <StyledModal
        id={this.props.modalId}
        title="Add Weekly Maintenance Report Schedule"
        width={850}
        okText="Confirm"
        footer={<>
          <Button
            key="cancel"
            onClick={() => {
              AppStore.openModals.set(this.ADD_VEHICLE_HEALTH_REPORT_MODAL_ID, false);
            }}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={() => this._updateEmailReport()}
            loading={saving}
          >
            Confirm
          </Button>
        </>}
      >
        <p
          style={this.textStyle}>
          A maintenance report email will be sent automatically on the day of the
          week and time you select.
        </p>

        <Row>
          <Col span={12}>
            <p style={this.textStyle}><b>Run every:</b></p>
            <Radio.Group
              defaultValue={this.weekDays[1].value}
              value={this.state.selectedWeekDay}
              onChange={(e => this.setState({ selectedWeekDay: e.target.value }))}>
              { this.weekDays.map((value, index) => {
                return <Radio.Button key={index} value={value.value}>{value.weekDay}</Radio.Button>;
              })}
            </Radio.Group>
          </Col>
          <Col span={12}>
            <p style={this.textStyle}><b>At:</b></p>
            <TimePicker
              use12Hours
              style={{width: 300}}
              defaultValue={this.state.selectedHour}
              value={this.state.selectedHour}
              onChange={(time, _) => {this.setState({selectedHour: time});}}
            />
          </Col>
        </Row>
        <UsersTable
          isUserSelectionEnabled={true}
          sendEmailConfiguration={() => {}}
          sendPriorityAlertsEmailConfiguration={() => {}}
          getCurrentSelectedPriorities={() => {}}
          isDateSelectionEnabled={false}
          isPrioritySelectionEnabled={false}
          enableUserSelection={() => {}}
          isVehicleSelectionEnabled={false}
          sendFleetSegmentConfiguration={() => {}}
          columnKeys={['firstName', 'lastName', 'email', 'vehicleHealthReport']}
          isEditable={false}
          showSetupButton={false}
          onChangeUserIdsSelected={this.onChangeUserIdsSelected.bind(this)}
        />
      </StyledModal>
    );
  }
}

export default EmailReportModal;