import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { Col, Row, Checkbox, Button } from 'antd';

import { PitstopModal } from 'shared';
import UsersTable from '../UsersTable';
import { AppStore, ShopStore } from 'stores';
import { observer } from 'mobx-react';
import SuccessModal from './SuccessModal';
import PropTypes from 'prop-types';

const StyledModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      .ant-modal-title {
        color: #ffffff;
      }
    }
  }
`;


class WorkOrderModal extends Component {
  EDIT_WORK_ORDER_NOTIFICATION_MODAL_ID =
    'EDIT_WORK_ORDER_NOTIFICATION_MODAL_ID';
  EDIT_WORK_ORDER_NOTIFICATION_SUCCESS_MODAL_ID =
    'EDIT_WORK_ORDER_NOTIFICATION_SUCCESS_MODAL_ID';

  state = {
    selectedNotificationTypes: {
      byEmail: false,
      bySMS: false,
    },
    selectedUsers: [],
    saving: false,
  };

  static get propTypes() {
    return {
      selectedEmails: PropTypes.array,
      title: PropTypes.string,
      modalId: PropTypes.string,
    };
  }

  onChangeUsersSelected = (selectedUsers) => {
    this.setState({ selectedUsers });
  };

  onChange = (notificationTypeKey, checked) => {
    const { selectedNotificationTypes } = this.state;
    _.set(selectedNotificationTypes, notificationTypeKey, checked);
    this.setState({ selectedNotificationTypes });
  };

  computeSelections() {
    const { selectedNotificationTypes, selectedUsers } = this.state;
    const { byEmail, bySMS } = selectedNotificationTypes;
    return _.map(selectedUsers, user => ({ userId: user.id, byEmail, bySMS }));
  }

  onUpdateWorkOrderNotifications = async () => {
    const selections = this.computeSelections();

    if (_.isEmpty(selections)) {
      AppStore.addError('Please select work order notifications');
      return;
    }

    try {
      this.setState({ saving: true });
      AppStore.addLoading('Saving');

      await ShopStore.sendWorkOrderNotificationConfig(
        ShopStore.currentShop.id,
        selections
      );

      AppStore.openModals.set(
        this.EDIT_WORK_ORDER_NOTIFICATION_MODAL_ID,
        false
      );
      AppStore.openModals.set(
        this.EDIT_WORK_ORDER_NOTIFICATION_SUCCESS_MODAL_ID,
        true
      );
      await ShopStore.getShopNotifications(ShopStore.currentShop.id, true);
    } catch (err) {
      AppStore.addError(
        `Error in updating work order notification config: ${err.message}`
      );
    } finally {
      this.setState({ saving: false });
      AppStore.removeLoading('Saving');
    }
  };

  onConfirmSuccessModal = async () => {
    await ShopStore.getShopNotifications(ShopStore.currentShop.id, true);
  };

  render() {
    const { saving } = this.state;
    const { title, modalId } = this.props;

    return (
      <>
        <SuccessModal
          modalId={this.EDIT_WORK_ORDER_NOTIFICATION_SUCCESS_MODAL_ID}
          onConfirm={this.onConfirmSuccessModal}
        >
          <Row gutter={[16, 16]} align="middle" style={{ textAlign: 'center' }}>
            <Col span={24}>
              <h5>Work order notification configs are saved.</h5>
            </Col>
          </Row>
        </SuccessModal>

        <StyledModal
          id={modalId}
          title={title}
          width={850}
          okText="Confirm"
          footer={
            <>
              <Button
                key="cancel"
                onClick={() => {
                  AppStore.openModals.set(
                    this.EDIT_WORK_ORDER_NOTIFICATION_MODAL_ID,
                    false
                  );
                }}
              >
                Cancel
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={this.onUpdateWorkOrderNotifications}
                loading={saving}
              >
                Confirm
              </Button>
            </>
          }
        >
          <p style={this.textStyle}>
            An alert will be sent automatically whenever a user is assigned to a
            work order or the status changes on a work order.
          </p>

          <Row>
            <Col span={12}>
              <p style={{ fontWeight: 700 }}>Select how to be notified:</p>

              <div>
                <Checkbox
                  onChange={(e) => this.onChange('byEmail', e.target.checked)}
                >
                  Email
                </Checkbox>

                <Checkbox
                  onChange={(e) => this.onChange('bySMS', e.target.checked)}
                >
                  Text Message
                </Checkbox>
              </div>
            </Col>
          </Row>

          <UsersTable
            isUserSelectionEnabled={true}
            sendEmailConfiguration={() => {}}
            sendPriorityAlertsEmailConfiguration={() => {}}
            getCurrentSelectedPriorities={() => {}}
            isDateSelectionEnabled={false}
            isPrioritySelectionEnabled={false}
            enableUserSelection={() => {}}
            isVehicleSelectionEnabled={false}
            sendFleetSegmentConfiguration={() => {}}
            columnKeys={[
              'firstName',
              'lastName',
              'email',
              'phone',
              'workOrderNotifications',
            ]}
            isEditable={false}
            showSetupButton={false}
            onChangeUserIdsSelected={this.onChangeUsersSelected}
          />
        </StyledModal>
      </>
    );
  }
}

export default observer(WorkOrderModal);
