import React, { Component } from 'react';
import styled from 'styled-components';

import { Slider } from 'v3/components/pitstop';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  padding: 4vw 5vw 0vw 12vw;

  background: #02103d;
  border: 1px dashed #fffffff;
  box-sizing: border-box;

  h2 {
    color: #ffffff;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.375rem;
  }

  h3 {
    color: #4f75b9;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  p {
    color: #d9d9d9;
    line-height: 1.5rem;
    font-weight: 400;
  }

  li {
    font-size: 1rem;
    color: #d9d9d9;
  }
`;

const Img1 = styled.div`
  height: 40vh;
  margin: 5vh 0vh;
`;

class AuthSliderBackground extends Component {
  state = { step: 0 };

  intervalID = 0;

  componentDidMount() {
    this.intervalID = setInterval(() => {
      let step = this.state.step;

      step++;

      if (step === 4) {
        step = 0;
      }

      this.setState({
        step,
      });
    }, 5 * 3600);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    const { step } = this.state;

    return (
      <Container id="authBackground">
        <Background style={{ height: '100%' }}>
          {(step === -1 || step === 0) && (
            <>
              <a
                href="https://pitstopconnect.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('assets/logos/pitstop-logo.png')}
                  alt="logo"
                  height="70"
                />
              </a>

              <Img1>
                <img
                  src={require('v3/assets/vehicle_summary.png')}
                  alt="vehicle_summary"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </Img1>

              <div id="content">
                <h3>PREPARE FOR THE FUTURE</h3>

                <h2>Upgrade your maintenance strategy</h2>

                <p>
                  Though fleet managers know they need data to make efficient
                  maintenance decisions, many fail to fully leverage their data
                  to develop proper insights.
                </p>

                <p>
                  An effective maintenance platform like Pitstop leverages the
                  power of AI, analytics and cloud-based technology to make
                  accurate forecasts and assist technicians in identifying
                  faults.
                </p>
              </div>
            </>
          )}

          {step === 1 && (
            <>
              <a
                href="https://pitstopconnect.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('assets/logos/pitstop-logo.png')}
                  alt="logo"
                  height="70"
                />
              </a>

              <Img1>
                <img
                  src={require('v3/assets/solutions.png')}
                  alt="solutions"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </Img1>

              <h3>PREPARE FOR THE FUTURE</h3>

              <h2>What can Pitstop do?</h2>

              <ul>
                <li>Communicate with your drivers through our mobile app</li>
                <li>Constantly monitor vehicle data in real-time</li>
                <li>
                  Track & generate ROI reports to share cross-functionally
                </li>
                <li>
                  Send predictive failures to service shop to streamline
                  maintenance schedule
                </li>
                <li>Easy-to-use and integrate with current workflow</li>
                <li>
                  Increase employee retention and make a fleet manager’s job
                  easier
                </li>
              </ul>
            </>
          )}

          {step === 2 && (
            <>
              <a
                href="https://pitstopconnect.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('assets/logos/pitstop-logo.png')}
                  alt="logo"
                  height="70"
                />
              </a>

              <Img1>
                <img
                  src={require('v3/assets/data_dictionary.png')}
                  alt="data_dictionary"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </Img1>

              <h3>PREPARE FOR THE FUTURE</h3>

              <h2>Did you know?</h2>

              <p>
                We leverage our ‘data dictionary’ and AI technology to
                anticipate critical vehicle issues months in advance, bringing
                fleets closer to zero unplanned downtime while saving money,
                lowering emissions and increasing safety.
              </p>
            </>
          )}

          {step === 3 && (
            <>
              <a
                href="https://pitstopconnect.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('assets/logos/pitstop-logo.png')}
                  alt="logo"
                  height="70"
                />
              </a>

              <Img1>
                <img
                  src={require('v3/assets/user_reviews.png')}
                  alt="user_reviews"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </Img1>

              <h3>PREPARE FOR THE FUTURE</h3>

              <h2>What are people saying about Pitstop?</h2>

              <p>
                “Too often vehicle and equipment maintenance can be wasteful; we
                spend unnecessarily, thinking we’re doing the right thing but
                we’re giving up the valuable remaining life in those parts and
                fluids.
              </p>

              <p>
                With Pitstop, we can start to look more accurately at the
                maintenance required and can begin to plan downtime
                accordingly.” Paul Lagerwerf - Director, Fleet and Equipment at
                NPL Canada
              </p>
            </>
          )}

          <Slider
            isActive={step === 0}
            onClick={() => this.setState({ step: 0 })}
          />
          <Slider
            isActive={step === 1}
            onClick={() => this.setState({ step: 1 })}
          />
          <Slider
            isActive={step === 2}
            onClick={() => this.setState({ step: 2 })}
          />
          <Slider
            isActive={step === 3}
            onClick={() => this.setState({ step: 3 })}
          />
        </Background>
      </Container>
    );
  }
}

export default AuthSliderBackground;
