import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Alert } from 'antd';
import { PitstopButton } from 'shared';

const Wrapper = styled.div`
  button {
    margin-top: 20px;
  }
`;

const CampaignCreateCompleted = () => {
  return (
    <Wrapper>
      <Alert
        message="Campaign Send Successfully!"
        type="success"
        showIcon
        description="Campaign was successfully created and has been sent out to your
          customer."
      />
      <Link to="/campaign" activeClassName="active">
        <PitstopButton type="primary">View Campaigns</PitstopButton>
      </Link>
    </Wrapper>
  );
};

export default CampaignCreateCompleted;
