import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import moment from 'moment-timezone';

import { DatePicker, Row, Col } from 'antd';

import { webServiceProvider } from 'shared';
import { AppStore, ShopStore } from 'stores';

import DealershipChooser from 'components/DealershipChooser';

import AvgSessionTimeReport from './AvgSessionTimeReport';
import PopularPagesReport from './PopularPagesReport';

const { RangePicker } = DatePicker;

class GeneralReport extends Component {
  state = {
    startDate: moment().subtract(3, 'months').toISOString(),
    endDate: moment().toISOString(),
    userAvgSessionTime: [],
    popularPages: [],
    loading: false
  };

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    if (ShopStore.currentShop.id !== -1) {
      try {
        this.setState({ loading: true });

        await this.getDashboardUsageReport();
      } catch (err) {
        AppStore.addError(err.message);
      } finally {
        this.setState({ loading: false });
      }
    }
  });

  async componentDidMount() {
    try {
      this.setState({ loading: true });

      await this.getDashboardUsageReport();
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.startDate !== prevState.startDate ||
      this.state.endDate !== prevState.endDate
    ) {
      try {
        this.setState({ loading: true });

        await this.getDashboardUsageReport();
      } catch (err) {
        AppStore.addError(err.message);
      } finally {
        this.setState({ loading: false });
      }
    }
  }

  componentWillUnmount() {
    this.disposer();
  }

  getDashboardUsageReport = async () => {
    try {
      let { startDate, endDate } = this.state;

      let report = await webServiceProvider.getMany(
        `v1/shop/${ShopStore.currentShop.id}/dashboard-usage-report`,
        {
          startDate,
          endDate
        }
      );

      this.setState({
        userAvgSessionTime: report.userAvgSessionTime.data,
        popularPages: report.popularPages.data
      });
    } catch (err) {
      throw Error(err);
    }
  };

  onChangeDates = dates => {
    this.setState({
      startDate: moment(dates[0]).toISOString(),
      endDate: moment(dates[1]).toISOString()
    });
  };

  render() {
    let { userAvgSessionTime, popularPages, loading } = this.state;

    return (
      <>
        <p style={{ fontWeight: 500, fontSize: 20 }}>Dashboard Usage Report</p>
        <Row gutter={[0, 8]}>
          <Col>
            <DealershipChooser />
          </Col>

          <Col style={{ display: 'flex' }}>
            <p style={{ marginRight: 15 }}>Select Your Time Range:</p>
            <RangePicker
              defaultValue={[moment().subtract(3, 'months'), moment()]}
              onChange={dates => this.onChangeDates(dates)}
            />
          </Col>

          <Col>
            <AvgSessionTimeReport data={userAvgSessionTime} loading={loading} />
          </Col>

          <Col>
            <PopularPagesReport data={popularPages} loading={loading} />
          </Col>
        </Row>
      </>
    );
  }
}

export default observer(GeneralReport);
