import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Empty } from 'antd';
import { DoughnutChart } from 'shared/PitstopUI/PitstopChart';

import { interpolateColors } from 'helpers/color-generator';

import { ReportsDataStore, CarStore } from 'stores';

class PredictiveInsights extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  get data() {
    let result = ReportsDataStore.predictiveInsightReport.data;

    if (CarStore.demo) {
      result = [
        { failure: 'Fuel Trim Anomalies', count: 37 },
        { failure: 'Battery Failure', count: 9 },
        { failure: 'Brake Failure', count: 18 },
        { failure: 'Fluids Failure', count: 20 },
        { failure: 'Cranking Voltage Failure', count: 13 },
        { failure: 'Tire Failure', count: 15 },
      ];
    }

    let serieColors = interpolateColors(result.length);

    return {
      seriesName: 'predicted failures',
      value: _.map(result, (r, i) => ({
        name: r.failure,
        color: serieColors[i],
        y: Number(r.count) || 0,
      })),
    };
  }

  render() {
    if (CarStore.demo === false) return <Empty />;

    return (
      <DoughnutChart
        chart={{
          height: 250,
          margin: [0, 250, 0, 0],
          legend: {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'top',
            x: Number(`${window.innerWidth > 1600 ? -100 : 0}`),
            y: 90,
          },
        }}
        data={this.data}
        loaded={ReportsDataStore.predictiveInsightReport.loaded}
        onClick={() => this.props.history.push('/issues?source=algorithm')}
      ></DoughnutChart>
    );
  }
}

decorate(PredictiveInsights, {
  data: computed,
});

export default withRouter(observer(PredictiveInsights));
