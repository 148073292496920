const getSearchParamByKey = (search, key) => {
  let params = new URLSearchParams(search);

  return params.get(key);
};

const query = {
  getSearchParamByKey
};

export default query;
