import { decorate, action, observable, transaction } from 'mobx';

import { webServiceProvider } from 'shared';

import ShopStore from 'stores/ShopStore';
import AppStore from 'stores/App';

class LocationServiceC {
  locationReports = [];
  loadedLocation = false;

  fetchLocationForShop = async (
    shopId = ShopStore.currentShop.id,
    params = {}
  ) => {
    this.loadedLocation = false;
    try {
      const { data } = await webServiceProvider.getMany(
        `v1/shops/${shopId}/cars/location`,
        params
      );
      transaction(() => {
        this.loadedLocation = true;
        this.locationReports = data;
      });
    } catch (e) {
      AppStore.addError('Error in fetching location data!');
    }
  };

  reset = () => {
    transaction(() => {
      this.locationReports = [];
      this.loadedLocation = false;
    });
  };
}

decorate(LocationServiceC, {
  loadedLocation: observable,
  locationReports: observable,
  fetchLocationForShop: action,
  reset: action
});

export const LocationService = new LocationServiceC();
