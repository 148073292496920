import styled from 'styled-components';

const FilterDropdown = styled.div`
  .filter-dropdown-list {
    padding: 8px 12px;
    border-bottom: solid 1px #e8e8e8;

    .filter-dropdown-list-item {
      margin-bottom: 5px;
    }
  }

  .filter-dropdown-actions {
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
  }

  &.assigned-to-dropdown {
    min-width: 300px;
    width: 300px;
  }
`;

export default FilterDropdown;
