/* eslint-disable react/prop-types */
import React from 'react';
import { Icon, Input, InputNumber, Popconfirm, Form, Tooltip } from 'antd';
import { PitstopSelect } from '../PitstopSelect';
import styled from 'styled-components';

const FlexRow = styled('span')`
  display: flex;

  .anticon {
    font-size: 1.1rem;
  }
`;

const FormItem = Form.Item;
const EditableContext = React.createContext();

const EditableRow = ({ form, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  get input() {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    if (this.props.inputType === 'select') {
      return <PitstopSelect options={this.props.selectOptions} />;
    }
    return <Input />;
  }

  render() {
    const {
      editing,
      dataIndex,
      title,
      // eslint-disable-next-line no-unused-vars
      inputType,
      record,
      // eslint-disable-next-line no-unused-vars
      index,
      // eslint-disable-next-line no-unused-vars
      selectOptions,
      required,
      ...restProps
    } = this.props;
    return (
      <EditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required,
                        message: `Please Input ${title}!`,
                      },
                    ],
                    initialValue: record[dataIndex],
                  })(this.input)}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

export const editableGridComponents = {
  body: {
    row: EditableFormRow,
    cell: EditableCell,
  },
};

export const getEditableGridActionColumn = ({
  isEditing,
  onCancel,
  onDelete,
  onEdit,
  onSave,
  hasNotification,
  onClickNotification,
}) => ({
  title: '',
  dataIndex: 'editAction',
  // eslint-disable-next-line react/display-name
  render: (text, record) => {
    const editing = isEditing(record);
    return (
      <EditableGridActionsPanel
        editing={editing}
        onCancel={onCancel}
        onDelete={onDelete}
        onEdit={onEdit}
        onSave={onSave}
        record={record}
        hasNotification={hasNotification}
        onClickNotification={onClickNotification}
      />
    );
  },
});

export function EditableGridActionsPanel({
  editing,
  onCancel,
  onDelete,
  onEdit,
  onSave,
  record,
  hasNotification,
  onClickNotification,
}) {
  return (
    <div>
      {editing ? (
        <span>
          <EditableContext.Consumer>
            {(form) => (
              <Icon
                type="check"
                onClick={() => {
                  form.validateFields((error, formValue) => {
                    if (error) {
                      return;
                    }
                    onSave(record, formValue);
                  });
                }}
                style={{ marginRight: 8 }}
              />
            )}
          </EditableContext.Consumer>
          <Popconfirm title="Sure to cancel?" onConfirm={() => onCancel()}>
            <Icon type="close" />
          </Popconfirm>
        </span>
      ) : (
        <FlexRow>
          {hasNotification ? (
            <Tooltip title="Click to edit notifications">
              <Icon
                type="notification"
                onClick={() => onClickNotification(record)}
                style={{ marginRight: 8 }}
              />
            </Tooltip>
          ) : (
            <></>
          )}
          <Icon
            type="edit"
            onClick={() => onEdit(record)}
            style={{ marginRight: 8 }}
          />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => onDelete(record)}
          >
            <Icon type="delete" />
          </Popconfirm>
        </FlexRow>
      )}
    </div>
  );
}
