import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import { PitstopModal } from 'shared';
import { CreateCarForm } from './CreateCarForm';
import CarStore from 'stores/CarStore';
import CurrentUserStore from 'stores/CurrentUserStore';
import ShopStore from 'stores/ShopStore';
import { observer } from 'mobx-react';
import { AppStore } from 'stores';

class CreateCarModal extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  columns = [
    { dataIndex: 'vin', title: 'VIN' },
    { dataIndex: 'baseMileage', title: 'Mileage' },
    { dataIndex: 'carName', title: 'Unit ID' },
    { dataIndex: 'scannerId', title: 'Device ID' },
  ];

  extraColumns = [
    { dataIndex: 'make', title: 'Make' },
    { dataIndex: 'model', title: 'Model' },
    { dataIndex: 'year', title: 'Year' },
  ];

  extraFields = [
    { label: 'Make', name: 'make', required: true, type: 'text' },
    { label: 'Model', name: 'model', required: true, type: 'text' },
    { label: 'Year', name: 'year', required: true, type: 'number' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      cars: [],
      extraFields: [],
      error: undefined,
      form: undefined,
    };
  }

  addCars = async () => {
    const { cars, error, form } = this.state;
    let ignoreVinDecodingFailure = error === 'CannotDecodeVin' ? true : false;
    try {
      AppStore.addLoading('Vehicle is being added...');

      for (let i = 0; i < cars.length; i++) {
        await CarStore.addCar({
          ...cars[i],
          userId: CurrentUserStore.user.id,
          baseMileage: Number(cars[i].baseMileage),
          ignoreVinDecodingFailure: ignoreVinDecodingFailure,
        });
      }

      form && form.resetFields();
      this.clearCars();
      this.clearExtraFields();
    } catch (e) {
      this.setState({
        extraFields: e.error === 'CannotDecodeVin' ? this.extraFields : [],
        error: e.error,
      });
      this.clearCars();
      throw e;
    } finally {
      AppStore.removeLoading('Vehicle is being added...');
    }
  };

  clearCars = () => {
    this.setState({
      cars: [],
    });
  };

  clearExtraFields = () => {
    this.setState({
      extraFields: [],
      error: undefined,
    });
  };

  addToPending = (car, form) => {
    this.setState({
      cars: [
        ...this.state.cars,
        { ...car, shopId: car.shopId || ShopStore.currentShop.id },
      ],
      form: form,
    });
  };

  render() {
    let shops = [];
    if (CurrentUserStore.user.role === 'admin') {
      shops = Array.from(ShopStore.data.values());
    }
    const { cars, extraFields, error, form } = this.state;

    let notifyMessage;
    switch (error) {
      case 'CannotDecodeVin':
        notifyMessage = 'Please Add Vehicle Manually';
        break;
      default:
        notifyMessage = undefined;
    }

    return (
      <PitstopModal
        id={this.props.id}
        title="Add Vehicle"
        width={800}
        okText="Add Vehicle"
        successMessage="Added Vehicles Successfully"
        failureMessage={error && error}
        notifyMessage={notifyMessage}
        onOk={this.addCars}
        onCancel={this.clearCars}
        form={form}
        clearExtraFields={this.clearExtraFields}
        okButtonProps={{ disabled: this.state.cars.length === 0 }}
      >
        <CreateCarForm
          onSubmit={this.addToPending}
          extraFields={extraFields}
          shops={shops}
        />
        <br />
        {cars.length !== 0 && (
          <Table
            columns={
              extraFields[0]
                ? this.columns.concat(this.extraColumns)
                : this.columns
            }
            dataSource={cars}
            rowKey="vin"
          />
        )}
      </PitstopModal>
    );
  }
}
export default observer(CreateCarModal);
