import React from 'react';
import { observer } from 'mobx-react';

import styled from 'styled-components';
import { Icon, Card, Divider } from 'antd';
import PropTypes from 'prop-types';
import CarStore from 'stores/CarStore';
import { SelectedUsers } from './components';

const PreviewWrapper = styled.div`
  h3 {
    margin-bottom: 20px;
    margin-top: 16px;
  }
  img {
    max-width: 100%;
    width: auto;
    max-height: 400px;
  }
  .ant-avatar {
    margin-right: 12px;
  }
  .campaign-type i.anticon {
    margin-right: 12px;
  }
`;

class CampaignPreviewSection extends React.Component {
  static propTypes = {
    contactMethod: PropTypes.string,
    messageContent: PropTypes.string,
    title: PropTypes.string,
    selectedCars: PropTypes.array,
    mediaUrl: PropTypes.string
  };
  render() {
    const { contactMethod, title, messageContent, mediaUrl } = this.props;

    return (
      <PreviewWrapper>
        <h1>Campaign Preview</h1>
        {contactMethod === 'chat' ? (
          <p className="campaign-type">
            <Icon type="message" />
            Pitstop Chat Message
          </p>
        ) : (
          <p className="campaign-type">
            <Icon type="mail" />
            Customer Email
          </p>
        )}
        <h3>Customers:</h3>
        <SelectedUsers
          selectedUsers={this.props.selectedCars
            .map(el => CarStore.data.get(el).userId)
            .filter(el => el !== undefined && el !== null)}
        />
        <h3>Message:</h3>
        <Card>
          <h4>Campaign Title: {title}</h4>
          <Divider />
          {mediaUrl ? <img src={mediaUrl} alt="campaignImage" /> : null}
          {contactMethod === 'chat' ? (
            <p>{messageContent}</p>
          ) : (
            <p dangerouslySetInnerHTML={{ __html: messageContent }} />
          )}
        </Card>
      </PreviewWrapper>
    );
  }
}

export default observer(CampaignPreviewSection);
