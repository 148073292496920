const enabledEmissionTableFleets = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return [231, 525, 315, 233, 314, 313, 327];
  }

  return [511, 822];
};

const reportConfig = {
  enabledEmissionTableFleets: enabledEmissionTableFleets()
};

export default reportConfig;
