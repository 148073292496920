import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, PropTypes } from 'mobx-react';
import { Table, Spin } from 'antd';
import _ from 'lodash';

import { ReportsDataStore } from 'stores';


class vehiclesWithSpecificIssues extends React.Component {
  static propTypes = {
    history: PropTypes.function
  }
  constructor(props) {
    super(props);
    this.state = { searchQuery: 'ABS' };
  }

  onChangeSearchQuery = event => {
    this.setState({ searchQuery: event.target.value });
  }

  onSearch = () => {
    ReportsDataStore.getVehiclesWithSpecificIssues(this.state.searchQuery);
  }

  get data() {
    const tableData = [];
    ReportsDataStore.vehiclesWithSpecificIssues.data.map(entry => {
      if (_.isEmpty(tableData.find(dataItem => dataItem.vin === entry.vin))) {
        tableData.push({
          key: entry.vin + entry.code,
          idCar: entry.id_car,
          vin: entry.vin,
          vehicleInfo: {unitId: entry.car_name, make: entry.car_make, model: entry.car_model, year: entry.car_year},
          lastTriggeredAt: entry.last_triggered_at,
          codes: [{ key: entry.code, code: entry.code, description: entry.description, id: entry.id }]
        });
      } else {
        tableData.find(dataItem => dataItem.vin === entry.vin).codes.push({ key: entry.code, code: entry.code, description: entry.description, id: entry.id });
        const thisDataItem = tableData.find(dataItem => dataItem.vin === entry.vin);
        if (thisDataItem.last_triggered_at < entry.last_triggered_at){
          thisDataItem.lastTriggeredAt = entry.last_triggered_at;
        }
      }
      return null;
    });
    return tableData;
  }

  expandedColumns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    }
  ];

  columns = [
    {
      title: 'Vehicle ID',
      dataIndex: 'vehicleInfo',
      key: 'vehicleInfo',
      sorter: (car1, car2) => {
          return car1.vehicleInfo.unitId.localeCompare(car2.vehicleInfo.unitId, 'en', {numeric: true});
      },
      sortDirections: ['ascend','descend', 'ascend'],
      render: car => {
        return (
        <>
          <strong >{car.unitId}</strong>
          <p>{car.make} {car.model} {car.year}</p>
        </>
      );}
    },
    {
      title: 'Number of Alerts',
      dataIndex: 'numAlerts',
      key: 'numAlerts',
      render: (index, car) => car.codes.length,
      sorter: (car1, car2) => car1.codes.length - car2.codes.length,
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend'
    },
    {
      title: 'Last Issue Trigger',
      dataIndex: 'lastTriggered',
      key: 'lastTriggered',
      render: (index, car) => ((new Date(car.lastTriggeredAt)).toString()).split('GMT')[0],
      sorter: (car1, car2) => (new Date(car1.lastTriggeredAt)).getTime() - (new Date(car2.lastTriggeredAt)).getTime(),
      sortDirections: ['descend', 'ascend', 'descend']
    }
  ];

  render() {
    return (
      <>
        {/*<div>
          <p>Search for issues</p>
            <input type="text" 
            value={this.state.searchQuery}
            onChange={this.onChangeSearchQuery}/>
            <Button onClick={this.onSearch}>Search</Button>
        </div>*/}
        {_.isEmpty(this.data) ? <Spin tip='loading' /> :
          <Table dataSource={this.data} columns={this.columns} onRow={(record, rowIndex) => {
            return {
              style: { cursor: 'pointer' },
              onClick: event => this.props.history.push('/car/' + record.idCar)
            };
          }}
            expandedRowRender={car => {
              return <Table dataSource={car.codes} columns={this.expandedColumns} onRow={(record, rowIndex) => {
                return {
                  style: { cursor: 'pointer' },
                  onClick: event => this.props.history.push('/issues/' + record.id + '/v2')
                };
              }}
              />;
            }} />}
      </>
    );
  }
}

export default withRouter(observer(vehiclesWithSpecificIssues));