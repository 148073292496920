import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Empty } from 'antd';

import ReceivedMessageCell from './ReceivedMessageCell';
import SentMessageCell from './SentMessageCell';

const Conversation = ({ conversationData, selectedUser }) => {
  if (_.isEmpty(conversationData)) {
    return (
      <Empty test-id="empty" description="No conversation history found" />
    );
  }

  return (
    <div className="gx-chat-main-content">
      {_.map(conversationData, (conversation, index) =>
        conversation.type === 'sent' ? (
          <SentMessageCell key={index} conversation={conversation} />
        ) : (
          <ReceivedMessageCell
            key={index}
            conversation={conversation}
            user={selectedUser}
          />
        )
      )}
    </div>
  );
};

Conversation.propTypes = {
  conversationData: PropTypes.array.isRequired,
  selectedUser: PropTypes.object.isRequired,
};

export default Conversation;
