import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { EditorState, convertToRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class HTMLEditor extends Component {
  static propTypes = {
    messageContent: PropTypes.string,
    setValue: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    var editorState;
    if (!this.props.messageContent || this.props.messageContent.length === 0) {
      editorState = EditorState.createEmpty();
    } else {
      var contentState = stateFromHTML(this.props.messageContent);
      editorState = EditorState.createWithContent(contentState);
    }

    this.state = {
      editorState: editorState
    };
  }

  onEditorStateChange = editorState => {
    const htmlContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    const event = document.createEvent('Event');
    event.data = htmlContent;
    this.props.setValue('messageContent', event.data);
    this.setState({
      editorState
    });
  };

  render() {
    const { editorState } = this.state;
    return (
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={this.onEditorStateChange}
      />
    );
  }
}

export default observer(HTMLEditor);
