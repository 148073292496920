import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Button } from 'antd';
import { AppStore } from 'stores';

const MarkAsCurrentBtn = ({ issue, onClick, title, type, shouldDisableButton, issues }) => {
  return (
    <Button
      onClick={async () => {
        AppStore.addLoading('Updating...');

        if (!_.isEmpty(issues)) {
          try {
            await Promise.all(
              issues.map((issue) => {
                return issue.setAsNew(false);
              })
            );
            AppStore.addSuccess('The selected issues were marked as new.');
          } catch (error) {
            AppStore.addError('Unable to update issues.');
          }
        } else {
          await issue.setAsNew();
        }

        AppStore.removeLoading('Updating...');

        await onClick();
      }}
      type={type}
      disabled={shouldDisableButton}
    >
      { title || 'Move To Current' }
    </Button>
  );
};

MarkAsCurrentBtn.propTypes = {
  issue: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  issues: PropTypes.array,
  shouldDisableButton: PropTypes.bool
};

MarkAsCurrentBtn.defaultProps = {
  shouldDisableButton: false,
  issue: {},
  issues: [],
};

export default MarkAsCurrentBtn;
