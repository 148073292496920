import React, { Component } from 'react';
import styled from 'styled-components';

import { Row, Col } from 'antd';

import {
  OnboardingForm,
  OnboardingSliderBackground,
} from 'v3/components/forms/onboarding';

const Container = styled.div`
  height: 100%;
  width: 100%;

  opacity: ${(props) => props.isLogin && 0.8};
  pointer-events: ${(props) => props.isLogin && 'none'};
`;

const OnboardingFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  padding: 2vw 6vw;

  #register-form {
    margin: auto;
  }

  #login-text {
    color: #02103d;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: right;

    a {
      font-weight: 600;
      color: #02103d;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;

    #login-text {
      margin: 5vw 0vw;
      text-align: left;
    }
  }
`;

class OnboardingPage extends Component {
  render() {
    return (
      <Container>
        <Row type="flex" style={{ height: '100%' }}>
          <Col lg={14} xs={24}>
            <OnboardingSliderBackground />
          </Col>

          <Col lg={10} xs={24}>
            <OnboardingFormContainer>
              <OnboardingForm
                isUserLogin={(isLogin) => {
                  this.setState({
                    isLogin: isLogin,
                  });
                }}
              />
            </OnboardingFormContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default OnboardingPage;
