import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

import { Button, Input, Tooltip, notification } from 'antd';

import { CurrentUserStore, UserStore } from 'stores';
import { Logger } from 'stores/Classes';

class UserDetailsSection extends Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      userActivity: PropTypes.object,
    }).isRequired,
  };

  state = {
    isEditingPassword: false,
    currentPassword: '',
    isSavingNewPassword: false,
  };

  handleSavePassword = async () => {
    try {
      this.setState({ isSavingNewPassword: true });
      await UserStore.dealershipChangePassword(
        this.props.user.id,
        this.state.currentPassword
      );
      this.setState({ isEditingPassword: false });
      notification.success({
        message: 'Password changed successfully',
      });
    } catch (e) {
      Logger.error(e);
      notification.error({
        message: 'Error changing password',
        description: e.message,
      });
    } finally {
      this.setState({ isSavingNewPassword: false });
    }
  };

  render() {
    let extra = null;
    if (CurrentUserStore.user.role === 'admin') {
      extra = (
        <Button
          href={`https://mixpanel.com/report/899125/explore/#user?distinct_id=${this.props.user.id}`}
          target="_blank"
        >
          View Mixpanel
        </Button>
      );
    }
    const user = {
      ...this.props.user,
      mixpanel: {
        appVersion: 'Not Available',
        os: 'Not Available',
        osVersion: 'Not Available',
        phoneModel: 'Not Available',
        city: 'Not Available',
        countryCode: 'Not Available',
        region: 'Not Available',
        timeZone: 'Not Available',
      },
    };

    let lastSeen = 'Never logged in before';
    let totalLoginCount = 'Never logged in before';
    if (
      this.props.user.userActivity &&
      this.props.user.userActivity.lastLoginTimestamp
    ) {
      lastSeen = moment(this.props.user.userActivity.lastLoginTimestamp).format(
        'MMMM Do YYYY, h:mm:ss a'
      );
      if (this.props.user.userActivity.loginCount) {
        totalLoginCount = this.props.user.userActivity.loginCount;
      }
    }

    let emailOrUsername = user.email;
    if (emailOrUsername.indexOf('username.pitstop') > -1) {
      emailOrUsername = emailOrUsername.split('@')[0];
    }

    return (
      <React.Fragment>
        <div className="flex-row">
          <div className="col-md-4">
            <h5>User Details</h5>

            <label>Email/Username</label>
            <p>
              {emailOrUsername.indexOf('@') > -1 ? (
                <a href={`mailto:${emailOrUsername}`}>{emailOrUsername}</a>
              ) : (
                emailOrUsername
              )}
            </p>

            {/* If it's a username only */}
            {emailOrUsername.indexOf('@') === -1 && (
              <div style={{ marginBottom: 10 }}>
                <label>New Password</label>
                <div>
                  {this.state.isEditingPassword ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Input
                        type="text"
                        id="password"
                        value={this.state.currentPassword || user.password}
                        onChange={(e) => {
                          this.setState({ currentPassword: e.target.value });
                        }}
                        style={{ width: 200, marginRight: 5 }}
                      />
                      <Tooltip title="Save">
                        <Button
                          loading={this.state.isSavingNewPassword}
                          onClick={() => {
                            this.handleSavePassword();
                          }}
                          icon="save"
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <p style={{ margin: 0, marginRight: 2 }}>********</p>
                      <Tooltip title="Edit">
                        <Button
                          onClick={() => {
                            this.setState({ isEditingPassword: true }, () => {
                              document.querySelector('input#password').focus();
                            });
                          }}
                          icon="edit"
                          size="small"
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            )}

            <label>Phone Number</label>
            <p>{user.phone || 'Not available'}</p>

            <label>Created At</label>
            <p>{moment(user.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</p>

            {isMobile && (
              <>
                <label>App Version</label>
                <p>{user.mixpanel.appVersion}</p>
              </>
            )}

            {CurrentUserStore.user.role === 'admin' && (
              <>
                <label>Last Login Time</label>
                <p>{lastSeen}</p>

                <label>Total Login Count</label>
                <p>{totalLoginCount}</p>
              </>
            )}
          </div>
          {/* <div className="col-md-4">
            <h5>Device Details</h5>
            <label>OS</label>
            <p>{user.mixpanel.os}</p>
            <label>OS Version</label>
            <p>{user.mixpanel.osVersion}</p>
            <label>Phone Model</label>
            <p>{user.mixpanel.phoneModel}</p>
          </div> */}
          {/* <div className="col-md-4">
            <h5>Location Details</h5>
            <label>City:</label>
            <p>{user.mixpanel.city}</p>
            <label>Country Code:</label>
            <p>{user.mixpanel.countryCode}</p>
            <label>Region:</label>
            <p>{user.mixpanel.region}</p>
            <label>Time Zone:</label>
            <p>{user.mixpanel.timeZone}</p>
          </div> */}
        </div>
        {extra}
      </React.Fragment>
    );
  }
}

export default observer(UserDetailsSection);
