import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import styled from 'styled-components';

import { Row, Col, Tooltip, Icon, Input, Spin, Card } from 'antd';

import { PitstopTable } from 'shared';

import { ShopStore, IntegrationStore } from 'stores';
import { TableStore } from 'stores/abstract';

const StyledLabel = styled.p`
  font-size: 1.2em;
  font-weight: 500;
`;

class GeotabSyncStatus extends Component {
  static propTypes = {
    match: PropTypes.object,
  };

  state = {
    carId: '',
  };

  tableStore = new TableStore();

  get columns() {
    let columns = [
      {
        key: 'carId',
        title: 'Vehicle Name',
        render: (record) => {
          return (
            <Link to={`/car/${record.id}`}>{record.car_name || record.id}</Link>
          );
        },
      },
      {
        key: 'mileageUpdate',
        title: 'Last Mileage Update',
        render: (record) => {
          return this.displayTimestamp(record.last_sync_at);
        },
      },
      {
        key: 'sensorUpdate',
        title: 'Last Sensor Data Update',
        render: (record) => {
          return this.displayTimestamp(record.last_sync_at);
        },
      },
      {
        key: 'dtcUpdate',
        title: 'Last DTC Update',
        render: (record) => {
          return (
            <>
              {this.displayTimestamp(record.dtc_updated_at) || 'N/A'} -{' '}
              {record.dtc_description || 'N/A'}
            </>
          );
        },
      },
    ];

    return columns;
  }

  displayTimestamp = (timestamp) => {
    if (!timestamp) return;
    return moment(timestamp).format('lll');
  };

  get shopId() {
    return this.props.match.params.shopId || ShopStore.currentShop.id;
  }

  get totalMileageReported() {
    let total = _.filter(this.tableStore.data, (d) => d.last_sync_at);
    return total.length;
  }

  get totalDTCReported() {
    let total = _.filter(this.tableStore.data, (d) => d.dtc_updated_at);
    return total.length;
  }

  get totalSensorDataReported() {
    let total = _.filter(this.tableStore.data, (d) => d.last_sync_at);
    return total.length;
  }

  filterValue = async (key, value) => {
    this.setState({ [key]: value });

    const filter = { ...this.state.tableStore.filter, [key]: value };
    if (!value || value.length === 0) {
      delete filter[key];
    }
    await IntegrationStore.getGeotabSyncStatus(
      {
        offset: this.tableStore.offset || 0,
        limit: this.tableStore.limit || 10,
        filter,
      },
      this.tableStore
    );
  };

  get data() {
    return _.filter(this.tableStore.data, Boolean);
  }

  render() {
    let syncStatus = IntegrationStore.data.get('syncStatus');

    return (
      <>
        <h1>Geotab Data Updates</h1>
        {!this.tableStore.loaded ? (
          <Spin tip="Loading..." />
        ) : (
          <Card style={{ width: 500, margin: '20px 0px' }}>
            <Row gutter={[16, 16]}>
              <Col span={14}>
                <StyledLabel>Total Vehicles Migrated</StyledLabel>
              </Col>
              <Col>{syncStatus.meta.total}</Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col>
                <StyledLabel style={{ fontSize: 18 }}>
                  Number of vehicles that sent the following data:
                </StyledLabel>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={14}>
                <StyledLabel>Mileage</StyledLabel>
              </Col>
              <Col>{syncStatus.meta.totalVehiclesLastSync}</Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={14}>
                <StyledLabel>Sensor Data</StyledLabel>
              </Col>
              <Col>{syncStatus.meta.totalVehiclesLastSync}</Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={14}>
                <StyledLabel>DTC</StyledLabel>
              </Col>
              <Col>{syncStatus.meta.totalVehiclesDTCUpdate}</Col>
            </Row>
          </Card>
        )}
        <Row gutter={[32, 32]}>
          <Col span={6}>
            <Tooltip trigger={['focus']} title="Unit ID" placement="topLeft">
              <Input
                placeholder="Unit ID"
                value={this.state.carId}
                prefix={<Icon type="search" />}
                onChange={(e) => this.filterValue('carId', e.target.value)}
              />
            </Tooltip>
          </Col>
        </Row>
        <PitstopTable
          id={`${ShopStore.currentShop.id}-geotabStatus`}
          columns={this.columns}
          rowKey="id"
          data={this.data}
          fetchData={(filters, table) => {
            IntegrationStore.getGeotabSyncStatus(filters, table, this.shopId);
          }}
          getTableStore={(tableStore) => (this.tableStore = tableStore)}
        />
      </>
    );
  }
}

decorate(GeotabSyncStatus, {
  data: computed,
  tableStore: observable,
});

export default withRouter(observer(GeotabSyncStatus));
