import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  DatePicker,
  Modal,
  Form,
  Input,
  Radio,
  AutoComplete,
  InputNumber,
} from 'antd';

import { partList } from './data/general';
import { AppStore } from 'stores';

class CustomServiceAntdForm extends Component {
  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  validateForm = () => {
    return this.props.form.validateFields((err, values) => {
      if (err) {
        AppStore.addError('Some fields in the form are required!');
      }
    });
  };

  renderForm = () => {
    const { form, serviceName } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form layout="vertical">
        {serviceName === 'newIssueReport' && (
          <Form.Item label="Reported By">
            {getFieldDecorator('reportedBy', {
              rules: [
                {
                  required: true,
                  message: 'Please input name of who reported',
                },
              ],
            })(<Input type="textarea" />)}
          </Form.Item>
        )}

        <Form.Item label="Action / Service Name">
          {getFieldDecorator('action', {
            rules: [
              {
                required: true,
                message: 'Please input a Service name!',
              },
            ],
          })(
            <AutoComplete
              type="textarea"
              dataSource={[
                'Change',
                'Inspect',
                'Perform',
                'Replace',
                'Remind',
                'Rotate',
              ]}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          )}
        </Form.Item>
        <Form.Item
          label={
            serviceName.includes('custom-routine') ? 'Activity' : 'Part Name'
          }
        >
          {getFieldDecorator('partName', {
            rules: [
              {
                required: true,
                message: 'Please input a Part Name!',
              },
            ],
          })(
            <AutoComplete
              type="textarea"
              dataSource={partList}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          )}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description')(
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
          )}
        </Form.Item>
        <Form.Item label="Priority">
          {getFieldDecorator('priority', {
            rules: [
              {
                required: true,
              },
            ],
          })(
            <Radio.Group size="large">
              <Radio value="Minor" size="large">
                <p
                  style={{
                    backgroundColor: '#dec431',
                    color: 'white',
                    display: 'inline',
                    padding: '5px',
                    borderRadius: '3px',
                  }}
                >
                  Minor
                </p>
              </Radio>
              <Radio value="Major" size="large">
                <p
                  style={{
                    backgroundColor: '#e28931',
                    color: 'white',
                    display: 'inline',
                    padding: '5px',
                    borderRadius: '3px',
                  }}
                >
                  Major
                </p>
              </Radio>
              <Radio value="Critical" size="large">
                <p
                  style={{
                    backgroundColor: '#f92d2e',
                    color: 'white',
                    display: 'inline',
                    padding: '5px',
                    borderRadius: '3px',
                  }}
                >
                  Critical
                </p>
              </Radio>
            </Radio.Group>
          )}
        </Form.Item>

        {serviceName === 'serviceHistoryForm' && (
          <>
            <Form.Item label="Service Performed Date">
              {getFieldDecorator('performedDate', {
                initialValue: moment(),
                rules: [{ required: true, message: 'Field is required' }],
              })(<DatePicker />)}
            </Form.Item>
            <Form.Item label="Cause Description">
              {getFieldDecorator('causeDescription')(<Input type="textarea" />)}
            </Form.Item>
            <Form.Item label="Complaints Description">
              {getFieldDecorator('complaintsDescription')(
                <Input type="textarea" />
              )}
            </Form.Item>
            <Form.Item label="Corrected Description">
              {getFieldDecorator('correctedDescription')(
                <Input type="textarea" />
              )}
            </Form.Item>
            <Form.Item label="Total Cost (Labor + Parts)">
              {getFieldDecorator('totalCost', {
                initialValue: 1,
                rules: [
                  {
                    required: true,
                    type: 'number',
                    message: 'Field is required and must be a number!',
                  },
                ],
              })(<InputNumber min={1} />)}
            </Form.Item>
            <Form.Item label="Labor Hours">
              {getFieldDecorator('laborHours', {
                initialValue: 1,
                rules: [
                  {
                    required: true,
                    type: 'number',
                    message: 'Field is required and must be a number!',
                  },
                ],
              })(<InputNumber min={1} />)}
            </Form.Item>
          </>
        )}

        {serviceName === 'customIssue' && (
          <Form.Item label="Status">
            {getFieldDecorator('status')(
              <Radio.Group>
                <Radio.Button value="waitingForApproval" size="large">
                  Waiting for Invoice
                </Radio.Button>
                <Radio.Button value="new" size="large">
                  Current
                </Radio.Button>
                <Radio.Button value="done" size="large">
                  Done
                </Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        )}
      </Form>
    );
  };
  render() {
    const { visible, onCancel, onCreate, serviceName } = this.props;

    return (
      <div>
        {!serviceName.includes('customIssue') ? (
          this.renderForm()
        ) : (
          <Modal
            visible={visible}
            title="Create a Custom Service"
            okText="Create"
            onCancel={onCancel}
            onOk={onCreate}
          >
            {this.renderForm()}
          </Modal>
        )}
      </div>
    );
  }
}

CustomServiceAntdForm.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  form: PropTypes.object,
  serviceName: PropTypes.string,
  childRef: PropTypes.func,
};

const CustomServiceForm = Form.create({ name: 'custom-service-form' })(
  CustomServiceAntdForm
);
export default CustomServiceForm;
