import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Table,
  Row,
  Icon,
  Button,
  Col,
  Popconfirm,
  Tooltip,
  Select,
  Spin,
} from 'antd';
import styled from 'styled-components';
import { convertToMonetary } from 'shared/utils';
import { ShopStore } from 'stores';

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    color: #333333;
  }

  .anticon {
    font-size: 1.1rem;
  }
`;

const PurchaseOrderTable = ({
  loading,
  data,
  onChangeTable,
  onDeletePurchaseOrder,
  shopParts,
  shopVendors,
  shopUsers,
}) => {
  const [
    confirmationPopoversVisible,
    setConfirmationPopoversVisible,
  ] = useState([]);

  return (
    <Table
      loading={loading}
      columns={[
        {
          title: `${ShopStore.currentShop.id === 374 ? 'Invoice' : 'PO'} #`,
          key: 'purchase_order_number',
          dataIndex: 'purchase_order_number',
          sorter: false,
          render: (purchase_order_number, row) => {
            return (
              <Row>
                {/* <Col span={24}>#{purchase_order_number}</Col> */}
                <Col span={24}>
                  <Link to={`/purchase-orders/${row.ref_code}`}>
                    #{purchase_order_number}
                  </Link>
                </Col>
              </Row>
            );
          },
        },
        {
          title: 'Reference',
          key: 'reference_number',
          dataIndex: 'reference_number',
          sorter: false,
          render(reference_number) {
            if (!reference_number) return <div>N/A</div>;
            return (
              <Row>
                <Col span={24}>#{reference_number}</Col>
              </Row>
            );
          },
        },
        {
          title: 'Status',
          key: 'status',
          dataIndex: 'status',
          sorter: false,
          filters: [
            { text: 'Open', value: 'open' },
            { text: 'Posted', value: 'posted' },
          ],
          render: (status, row) => {
            return (
              <Popconfirm
                placement="top"
                title={
                  'Changing the status to Posted means you cannot edit this purchase order anymore. Do you wish to continue?'
                }
                visible={confirmationPopoversVisible.indexOf(row.id) > -1}
                onConfirm={() => {
                  onChangeTable({
                    statusChange: true,
                    status: 'posted',
                    rowId: row.id,
                  });
                  setConfirmationPopoversVisible(
                    confirmationPopoversVisible.filter((id) => id !== row.id)
                  );
                }}
                onCancel={() => {
                  setConfirmationPopoversVisible(
                    confirmationPopoversVisible.filter((id) => id !== row.id)
                  );
                  status = 'open';
                }}
                okText="Yes"
                cancelText="No"
              >
                <Select
                  style={{ width: 120 }}
                  value={status}
                  onChange={(value) => {
                    if (value === 'posted') {
                      setConfirmationPopoversVisible([
                        ...confirmationPopoversVisible,
                        row.id,
                      ]);
                    }
                  }}
                  readOnly={status === 'posted'}
                  disabled={status === 'posted'}
                >
                  <Select.Option value="open">Open</Select.Option>
                  <Select.Option value="posted">Posted</Select.Option>
                </Select>
              </Popconfirm>
            );
          },
        },
        {
          title: 'Vendor',
          key: 'id_vendor',
          dataIndex: 'id_vendor',
          sorter: false,
          render: (id_vendor, row) => {
            if (row.loadingVendors) {
              return <Spin />;
            }
            const vendor = shopVendors.find(
              (vendor) => vendor.id === id_vendor
            );
            return (
              <Row>
                <Col span={24}>{vendor ? vendor.name : 'N/A'}</Col>
              </Row>
            );
          },
        },
        {
          title: 'Parts',
          key: 'lineItems',
          dataIndex: 'lineItems',
          sorter: false,
          render: (lineItems, row) => {
            if (row.loadingParts) {
              return <Spin />;
            }
            // if line items is empty return N/A
            if (!lineItems || lineItems.length === 0) {
              return (
                <Row>
                  <Col span={24}>N/A</Col>
                </Row>
              );
            }
            const parts = lineItems.filter(
              (lineItem) => lineItem.type === 'part'
            );
            if (!parts || parts.length === 0) {
              return (
                <Row>
                  <Col span={24}>N/A</Col>
                </Row>
              );
            }
            const firstTwoParts = parts.slice(0, 2);
            const otherParts = parts.slice(2);
            const hiddenLength = otherParts.length;
            return (
              <Row>
                {firstTwoParts.map((partLineItem, index) => {
                  const part = shopParts.find(
                    (shopPart) => shopPart.id === partLineItem.entityId
                  );
                  return (
                    <Col key={index} span={24}>
                      {part ? part.name : partLineItem.entityId}
                    </Col>
                  );
                })}
                {otherParts.length > 0 && (
                  <Col span={24}>
                    <Tooltip
                      placement="bottom"
                      title={otherParts.map((partLineItem, index) => {
                        const part = shopParts.find(
                          (shopPart) => shopPart.id === partLineItem.entityId
                        );
                        return (
                          <Row key={index}>
                            <Col span={24}>
                              {part ? part.name : partLineItem.entityId}
                            </Col>
                          </Row>
                        );
                      })}
                    >
                      <Button style={{ padding: 0 }} type="link">
                        <Icon type="plus" />
                        {hiddenLength} more
                      </Button>
                    </Tooltip>
                  </Col>
                )}
              </Row>
            );
          },
        },
        {
          title: 'Created By',
          key: 'created_by',
          dataIndex: 'created_by',
          sorter: false,
          render: (_, row) => {
            if (row.loadingUsers) {
              return <Spin />;
            }
            const user = shopUsers.find(
              (user) => user.userId === row.created_by
            );
            const userName = user
              ? `${user.firstName} ${user.lastName}`.trim() || user.email
              : 'N/A';
            return (
              <Row>
                <Col span={24}>{userName}</Col>
              </Row>
            );
          },
        },
        {
          title: 'Created At',
          key: 'created_at',
          dataIndex: 'created_at',
          sorter: false,
          render: (created_at) => {
            if (!created_at) return <div>N/A</div>;
            // return toLocaleString without hours and minutes
            return <div>{new Date(created_at).toLocaleDateString()}</div>;
          },
        },
        {
          title: 'Total Cost',
          key: 'totalCost',
          dataIndex: 'totalCost',
          sorter: false,
          render: (_, row) => {
            const totalSubtotal = row.lineItems.reduce((acc, lineItem) => {
              return acc + (lineItem.unitCost || 0) * (lineItem.quantity || 0);
            }, 0);
            const taxValue =
              row.tax.type === 'percentage'
                ? (row.tax.value / 100) * totalSubtotal
                : parseFloat(row.tax.value);
            const totalCost = totalSubtotal + taxValue;
            const monetaryVal = convertToMonetary(totalCost, true);
            return (
              <Row>
                <Col span={24}>{monetaryVal}</Col>
              </Row>
            );
          },
        },
        {
          title: '',
          key: 'actions',
          dataIndex: 'actions',
          render: (unused, row) => {
            return (
              <Row>
                {/* <Icon style={{ float: 'right' }} type="more" /> */}
                <ActionsWrapper>
                  <Popconfirm
                    placement="top"
                    title={'Are you sure?'}
                    onConfirm={() => onDeletePurchaseOrder(row)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="link">
                      <Icon type="delete" />
                    </Button>
                  </Popconfirm>
                </ActionsWrapper>
              </Row>
            );
          },
        },
      ]}
      dataSource={data}
      rowKey="id"
      onChange={onChangeTable}
    />
  );
};

PurchaseOrderTable.defaultProps = {
  data: [],
  onChangeTable: () => {},
  onDeletePurchaseOrder: () => {},
  loading: false,
  shopVendors: [],
  shopParts: [],
  shopUsers: [],
};

PurchaseOrderTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onChangeTable: PropTypes.func,
  onDeletePurchaseOrder: PropTypes.func,
  loading: PropTypes.bool,
  shopVendors: PropTypes.arrayOf(PropTypes.object),
  shopParts: PropTypes.arrayOf(PropTypes.object),
  shopUsers: PropTypes.arrayOf(PropTypes.object),
};

export default PurchaseOrderTable;
