import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment-timezone';

import { Spin } from 'antd';

import { PitstopStepper, PitstopButton, PitstopModal } from 'shared';

import AppStore from 'stores/App';
import CarStore from 'stores/CarStore';
import AppointmentStore from 'stores/AppointmentStore';
import CurrentUserStore from 'stores/CurrentUserStore';

//Views
import AppointmentSelectUsersSection from './AppointmentSelectUsersSection';
import AppointmentDateTimeSection from './AppointmentDateTimeSection';
import AppointmentPreviewSection from './AppointmentPreviewSection';
import CampaignCreateCompleted from './AppointmentCompletedSection';

const ButtonArea = styled.div`
  display: flex;
  button:nth-child(2) {
    margin-left: auto;
  }
  margin: 10px 0px;
`;
const ContentSection = styled.div`
  margin-top: 10px;
`;

class CreateAppointmentPage extends Component {
  static propTypes = {
    selectedCarId: PropTypes.number,
    initialBookServiceDateTime: PropTypes.object,
    history: PropTypes.object,
  };

  SPIN_APPOINTMENT_MODAL = 'SPIN_APPOINTMENT_MODAL';

  state = {
    finished: false,
    stepIndex: 0,
    description: '',
    startTimeAppointment: undefined,
    endTimeAppointment: undefined,
    title: undefined,
    selectedCars: [],
  };

  disposer = observe(AppStore.openModals, () => {
    let currentPath = this.props.history.location.pathname;

    // check currenPath to avoid redirect to CreateAppointMent
    // check BOOK_SERVICE_MODAL & finished to redirect when successfully create
    if (
      !currentPath.includes('/appointment') &&
      !AppStore.openModals.get('BOOK_SERVICE_MODAL') &&
      this.state.finished
    ) {
      this.setState({
        finished: false,
        stepIndex: 0,
      });
    }
  });

  componentDidMount() {
    if (this.props.selectedCarId) {
      let selectedCars = [this.props.selectedCarId];

      this.setState({
        selectedCars,
      });
    }

    if (this.props.initialBookServiceDateTime) {
      this.setState({
        startTimeAppointment: this.props.initialBookServiceDateTime,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedCarId !== prevProps.selectedCarId) {
      let selectedCars = [this.props.selectedCarId];

      this.setState({
        selectedCars,
        stepIndex: 0,
        startTimeAppointment: undefined,
        endTimeAppointment: undefined,
      });
    }

    if (this.props.initialBookServiceDateTime !== prevProps.initialBookServiceDateTime) {
      this.setState({
        startTimeAppointment: this.props.initialBookServiceDateTime,
      });
    }
  }

  setValue = (name, value, callback = () => { }) => {
    this.setState({ [name]: value }, callback);
  };

  get isDisabled() {
    let { stepIndex } = this.state;

    if (this.titles[stepIndex] === 'Select Vehicle') {
      return this.state.selectedCars.length === 0;
    }
    if (this.titles[stepIndex] === 'Select Date and Time') {
      return !this.state.startTimeAppointment;
    }
    return false;
  }

  goToPage = async (index) => {
    if (
      index === this.titles.length &&
      this.buttonLabel === 'Confirm Appointment'
    ) {
      let {
        selectedCars,
        description,
        startTimeAppointment,
        endTimeAppointment,
        assignedTo,
        attachReport,
      } = this.state;

      let selectedCarId = selectedCars[0] || this.props.selectedCarId;

      const selectedCar = CarStore.data.get(selectedCarId);

      var appointmentObject = {
        carId: selectedCarId,
        userId: CurrentUserStore.user.id,
        comments: description,
        appointmentDetails: {
          state: 'reminder',
          startTime: moment(startTimeAppointment).toISOString(),
          endTime: moment(endTimeAppointment).toISOString(),
          timezone: CurrentUserStore.user.settings.timezone,
        },
        existingUser: true,
      };

      try {
        await this.childCallables.handleSubmit();

        AppStore.openModals.set(this.SPIN_APPOINTMENT_MODAL, true);

        let appointment = await AppointmentStore.createAppointment(
          selectedCar.shopId,
          appointmentObject
        );

        if (!_.isNil(appointment.success)) {
          await AppointmentStore.sendContactEmail(appointment.id, {
            contactId: assignedTo,
            attachReport
          });

          this.setState({
            finished: true,
          });
        }
      } catch (err) {
        console.log(err);
        AppStore.addError('Sorry! We are unable to create the appointment!');
      } finally {
        AppStore.openModals.set(this.SPIN_APPOINTMENT_MODAL, false);
      }
      return;
    }
    this.setState({ stepIndex: index });
  };

  setChildCallables = (callables) => {
    this.childCallables = callables;
  };

  get steps() {
    let { selectedCars, startTimeAppointment, endTimeAppointment } = this.state;

    let steps = [
      {
        title: 'Select Vehicle',
        content: (
          <AppointmentSelectUsersSection
            key="appointmentUser"
            selectedCars={selectedCars}
            setValue={this.setValue}
          />
        ),
      },
      {
        title: 'Select Date and Time',
        content: (
          <AppointmentDateTimeSection
            key="appointmentTime"
            selectedCarId={this.props.selectedCarId || selectedCars[0]}
            startTimeAppointment={startTimeAppointment}
            endTimeAppointment={endTimeAppointment}
            setValue={this.setValue}
          />
        ),
      },
      {
        title: 'Confirm Appointment',
        content: (
          <AppointmentPreviewSection
            setCallables={this.setChildCallables}
            key="appointmentPreview"
            startTimeAppointment={startTimeAppointment}
            endTimeAppointment={endTimeAppointment}
            selectedCarId={this.props.selectedCarId || selectedCars[0]}
            description={this.state.description}
            setValue={this.setValue}
          />
        ),
      },
    ];

    if (this.props.selectedCarId) {
      steps = steps.slice(1);
    }

    return steps;
  }

  get buttonLabel() {
    let { stepIndex } = this.state;

    if (this.titles[stepIndex] === 'Confirm Appointment') {
      return 'Confirm Appointment';
    }
    return 'Next';
  }

  get contents() {
    let { stepIndex } = this.state;
    let contents = _.map(this.steps, (step) => step.content);

    return contents[stepIndex];
  }

  get titles() {
    let titles = _.map(this.steps, (step) => step.title);

    return titles;
  }

  render() {
    const { stepIndex, finished } = this.state;

    if (finished) {
      return (
        <>
          <h1>Appointment Created!</h1>
          <CampaignCreateCompleted
            onClick={async () => {
              await CarStore.data
                .get(this.props.selectedCarId)
                .getAppointments();

              this.setState({
                finished: false,
                stepIndex: 0,
                startTimeAppointment: undefined,
                endTimeAppointment: undefined,
              });
            }}
          />
        </>
      );
    }

    return (
      <>
        <h1>Book Appointment</h1>
        <PitstopStepper steps={this.titles} index={stepIndex} />
        <ContentSection>{this.contents}</ContentSection>

        <ButtonArea>
          <PitstopButton
            disabled={stepIndex === 0}
            type="secondary"
            onClick={() => this.goToPage(stepIndex - 1)}
          >
            Back
          </PitstopButton>
          <PitstopButton
            disabled={this.isDisabled}
            type="primary"
            onClick={() => this.goToPage(stepIndex + 1)}
          >
            {this.buttonLabel}
          </PitstopButton>
        </ButtonArea>

        <PitstopModal
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          onOk={() => {}}
          onCancel={() => {}}
          title=""
          id={this.SPIN_APPOINTMENT_MODAL}
        >
          <Spin tip="Creating appointment..." />
        </PitstopModal>
      </>
    );
  }
}

export default withRouter(observer(CreateAppointmentPage));
