import _ from 'lodash';

import { AppStore, CurrentUserStore } from 'stores';
import AbstractStore from './abstract/AbstractStore';
import { UserRoles } from './Classes/UserObject';

import { webServiceProvider } from 'shared';

class AppointmentStoreC extends AbstractStore {
  MESSAGE = 'Loading in Appointments';

  createAppointment = async (shopId, appointmentObject) => {
    try {
      if (CurrentUserStore.user.role === UserRoles.customer) {
        const result = await webServiceProvider.post('utility/serviceRequest', {
          ...appointmentObject,
          shopId: CurrentUserStore.user.shopId,
          options: {
            ...appointmentObject.appointmentDetails,
            state: 'tentative',
            salesPerson: appointmentObject.comments,
          },
        });
        return result;
      } else {
        const { data } = await webServiceProvider.post(
          `v1/shops/${shopId}/appointment`,
          appointmentObject
        );
        return data;
      }
    } catch (e) {
      AppStore.addError('Unable to create appointment.');
    }
  };

  sendReportToContact = async (shopId, params) => {
    try {
      if (_.isNil(params.carId) || _.isNil(params.contactId)) {
        throw Error();
      }
      await webServiceProvider.post(
        `v1/appointment/send-report-to-contact`,
        {
          ...params,
          shopId,
        }
      );
    } catch (err) {
      AppStore.addError(`Error in sending report to contact: ${err.message}`);
    }
  };

  getShopAppointmentEvents = async (shopId) => {
    try {
      this.loaded = false;

      const data = await webServiceProvider.getMany(
        `shop/${shopId}/appointments`
      );

      this.data = data;
      this.loaded = true;

      return data;
    } catch (e) {
      AppStore.addError('Unable to load appointments for shop.');
    }
  };

  getShopAppointmentEventsInRange = async (shopId, from, to) => {
    try {
      this.loaded = false;

      const data = await webServiceProvider.getMany(
        `shop/${shopId}/appointments`,
        {
          params: {
            from,
            to,
          },
        }
      );

      this.data = data;
      this.loaded = true;

      return data;
    } catch (e) {
      AppStore.addError('Unable to load appointments for shop.');
    }
  };

  sendAppointmentEmail = async (
    appointmentId,
    params = { name: null, email: null }
  ) => {
    try {
      if (_.isNil(params.name) && _.isNil(params.email)) {
        throw Error();
      }
      await webServiceProvider.post(
        `v1/appointment/${appointmentId}/notify-shop`,
        params
      );
    } catch (err) {
      AppStore.addError(`Error in sending appointment email: ${err.message}`);
    }
  };

  sendContactEmail = async (
    appointmentId,
    params = {}
  ) => {
    try {
      if (_.isNil(params.contactId)) {
        throw Error('Contact ID is required.');
      }

      await webServiceProvider.post(
        `v1/appointment/${appointmentId}/notify-contact`,
        params
      );
    } catch (err) {
      AppStore.addError(`Error in sending contact email: ${err.message}`);
    }
  };
}

const AppointmentStore = new AppointmentStoreC();
export default AppointmentStore;
