import { Button, Col, Row, Tag } from 'antd';
import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const FiltersWrapperRow = styled(Row)`
  .filter-tag {
    padding: 2px 15px;
    background: #ffffff;
    border-radius: 1rem;
  }
`;

function CurrentFilterBadges({
  currentFiltersApplied,
  onClearAllFilters,
  onCloseTag,
}) {
  return (
    <FiltersWrapperRow style={{ marginBottom: 10 }} gutter={[16, 16]}>
      <Col span={24}>
        {currentFiltersApplied.length === 0 ? (
          <Button disabled size="small" style={{ marginRight: 10 }}>
            No filters selected
          </Button>
        ) : (
          <Button
            size="small"
            style={{ marginRight: 10 }}
            type="primary"
            onClick={onClearAllFilters}
          >
            Clear all filters
          </Button>
        )}
        {/* Add badges with current selected filters here */}
        {currentFiltersApplied.map((filter) => {
          return (
            <Tag
              key={filter.key}
              className="filter-tag"
              closable
              onClose={() => onCloseTag(filter)}
            >
              {filter.label}
            </Tag>
          );
        })}
      </Col>
    </FiltersWrapperRow>
  );
}

CurrentFilterBadges.propTypes = {
  currentFiltersApplied: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      label: PropTypes.string,
    })
  ),
  onClearAllFilters: PropTypes.func,
  onCloseTag: PropTypes.func,
};

CurrentFilterBadges.defaultProps = {
  currentFiltersApplied: [],
  onClearAllFilters: () => {},
  onCloseTag: () => {},
};

export default CurrentFilterBadges;
