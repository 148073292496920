import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PitstopModal } from 'shared';

import {
  Row,
  Col,
  Button,
  Typography,
  Input,
  InputNumber,
  Checkbox,
} from 'antd';
import { Link } from 'react-router-dom';

const { Text } = Typography;

const { TextArea } = Input;

const StyledModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      .ant-modal-title {
        color: #ffffff;
      }
    }
  }
`;

const AddPartModal = ({
  modalId,
  loadingSavingNewPart,
  onSubmit,
  onCancel,
  shopParts = [],
}) => {
  const [number, setNumber] = useState('');
  const [description, setDescription] = useState('');
  const [cost, setCost] = useState(0);
  const [addToInventory, setAddToInventory] = useState(false);

  return (
    <StyledModal
      id={modalId}
      title={'Add New Part'}
      width={640}
      footer={
        <>
          <Button
            key="back"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              onSubmit({
                number,
                description,
                cost,
                addToInventory,
              });
            }}
            loading={loadingSavingNewPart}
          >
            Save
          </Button>
        </>
      }
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Text strong>Part Number</Text>
          <Input
            style={{ width: '100%' }}
            value={number}
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />
          {String(number).trim() &&
            shopParts.find(
              (shopPart) =>
                String(shopPart.number).trim() === String(number).trim()
            ) && (
              <Typography.Text type="danger">
                A part with this number already exists in your inventory. To
                avoid duplicates, increase its stock count instead of adding a
                new part.{' '}
                <Link
                  to={`/parts/${
                    shopParts.find(
                      (shopPart) =>
                        String(shopPart.number).trim() === String(number).trim()
                    ).id
                  }`}
                >
                  Go to Existing Part
                </Link>
              </Typography.Text>
            )}
        </Col>
        <Col span={24}>
          <Text strong>Description</Text>
          <TextArea
            id="description"
            rows={4}
            placeholder="Add Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Col>
        <Col span={24}>
          <Text strong>Unit Cost:</Text>
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            value={cost}
            onChange={(value) => {
              setCost(value);
            }}
          />
        </Col>
        <Col span={24}>
          <Checkbox
            style={{
              marginTop: '10px',
            }}
            checked={addToInventory}
            onChange={(e) => {
              setAddToInventory(e.target.checked);
            }}
          >
            Check box to add part to Parts List inventory. Leave unchecked to
            exclude from inventory
          </Checkbox>
        </Col>
      </Row>
    </StyledModal>
  );
};

AddPartModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  loadingSavingNewPart: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  shopParts: PropTypes.array,
};

export default AddPartModal;
