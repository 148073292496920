import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Result } from 'antd';

function ErrorFallback() {
  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={
        <Button type="primary">
          <Link to="/">Back Home</Link>
        </Button>
      }
    />
  );
}

export default ErrorFallback;
