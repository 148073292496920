export const partList = [
  'Air Filter',
  'Battery',
  'Brakes',
  'Coolant',
  'Exhaust',
  'Lights',
  'Natural Inspection',
  'PM Inspection',
  'State Inspection',
  'Oil',
  'Spark Plugs',
  'Suspenion',
  'Tires',
  'Transmission'
];
