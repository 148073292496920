import React from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import { observe } from 'mobx';
import {
  Form,
  Select,
  Row,
  Col,
  Input,
  Button,
  message,
  Spin,
  Typography,
  Collapse,
  Popconfirm,
  Tooltip,
  DatePicker,
} from 'antd';
import { AppStore, ShopStore, UserStore, WorkOrderStore } from 'stores';
import ContactStore from 'stores/ContactStore';
import styled from 'styled-components';
import DealershipChooser from 'components/DealershipChooser';
import ContactFormModal from 'components/Contacts/ContactFormModal';
import AddPartModal from 'containers/WorkOrder/modals/AddPartModal';
import PurchaseOrdersCostSummary from './PurchaseOrdersCostSummary';
import PurchaseOrderLineItem from './PurchaseOrderLineItem';
import moment from 'moment';

const { Option } = Select;
const { Text } = Typography;
const { Panel } = Collapse;

const PurchaseOrderStatusEnum = {
  open: 'Open',
  posted: 'Posted',
};

const ActionsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const AddItemsActions = styled.div`
  display: flex;
`;

const FormWrapper = styled.div`
  form {
    padding-top: 20px;
  }

  .ant-form-item-label {
    line-height: 14px;

    label {
      font-size: 13px;
      font-weight: 400;
    }
  }
`;

const CostSummaryWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  .purchase-orders-cost-summary {
    max-width: 400px;
  }
`;

class PurchaseOrdersForm extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    shopId: PropTypes.number,
    childRef: PropTypes.func,
    onSelectVendor: PropTypes.func,
    onSelectCreatedBy: PropTypes.func,
    purchaseOrder: PropTypes.object,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    savingWorkOrder: PropTypes.bool,
    isNew: PropTypes.bool,
    hideSelectFleet: PropTypes.bool,
  };

  state = {
    initialLoading: false,
    savingContact: false,
    loadingSavingNewPart: false,
    shopVendors: [],
    shopUsers: [],
    shopParts: [],
    parts: [],
    services: [],
    lineItems: [],
    tax: {
      type: 'percentage',
      value: 0,
    },
    disableFields: false,
    confirmationPopoverVisible: false,
  };

  CONTACT_FORM_MODAL_ID = 'contact_form_modal_id';
  ADD_PART_MODAL_ID = 'add_part_modal_id';

  async componentDidMount() {
    const { childRef } = this.props;
    childRef(this);

    this.init();
  }

  shopChangeWatcher = observe(ShopStore.currentShop, 'id', () => {
    if (ShopStore.isAdmin()) return;
    return this.init();
  });

  componentWillUnmount() {
    this.shopChangeWatcher();
  }

  get createdByOptions() {
    return this.state.shopUsers.map((user, index) => (
      <Option key={index} value={user.userId}>
        {`${user.firstName || ''} ${user.lastName || ''}`.trim() || user.email}
      </Option>
    ));
  }

  get vendorOptions() {
    return this.state.shopVendors.map((vendor, index) => (
      <Option key={index} value={vendor.id}>
        {vendor.name}
      </Option>
    ));
  }

  get partOptions() {
    const onlyUniquePartsByNumber = this.state.shopParts.reduce((acc, part) => {
      if (
        !acc.find((p) => String(p.number).trim() === String(part.number).trim())
      ) {
        acc.push(part);
      }
      return acc;
    }, []);
    const parts = onlyUniquePartsByNumber.map((part, index) => {
      let displayText =
        `${part.number} ${part.description}`.trim() || part.name;
      if (displayText === 'null null') {
        displayText = part.name;
      }
      return (
        <Option key={index} value={part.id}>
          {displayText}
        </Option>
      );
    });
    return [
      <Option key="new" value="new">
        [Select Part]
      </Option>,
      ...parts,
    ];
  }

  get serviceOptions() {
    return [];
  }

  init = async () => {
    try {
      this.setState({ initialLoading: true });
      await this.refreshVendors();
      await this.refreshUsers();
      await this.refreshShopParts();
    } catch (error) {
      message.error('Error on fetching vendors and users', 3);
    } finally {
      this.setState({ initialLoading: false });
    }
  };

  refreshVendors = async (shopId = ShopStore.currentShop.id) => {
    try {
      const contacts = await ContactStore.getShopContacts(shopId);
      await this.setState({ shopVendors: contacts || [] });
    } catch (error) {
      console.log(error);
    }
  };

  refreshUsers = async (shopId = ShopStore.currentShop.id) => {
    try {
      const users = await UserStore.fetchShopUsers(shopId);
      await this.setState({ shopUsers: users || [] });
    } catch (error) {
      console.log(error);
    }
  };

  refreshShopParts = async (shopId = ShopStore.currentShop.id) => {
    try {
      const parts = await WorkOrderStore.getShopParts(shopId, {
        include_deleted: true,
      });
      await this.setState({ shopParts: parts || [] });
    } catch (error) {
      console.log(error);
    }
  };

  onSubmitForm = () => {
    if (this.state.disableFields) {
      const entityName =
        ShopStore.currentShop.id === 374 ? 'Invoice' : 'Purchase Order';
      return message.error(entityName + ' is posted, you cannot edit it', 3);
    }

    this.props.form.validateFields(async (err, payload) => {
      if (err) {
        let friendlyMsg = '';
        const errorKeys = Object.keys(err);
        if (
          errorKeys &&
          errorKeys[0] &&
          err[errorKeys[0]] &&
          err[errorKeys[0]].errors &&
          err[errorKeys[0]].errors.length &&
          err[errorKeys[0]].errors[0].message
        ) {
          friendlyMsg = err[errorKeys[0]].errors[0].message;
        } else {
          friendlyMsg = 'Error on saving Work Order';
        }
        return message.error(friendlyMsg, 3);
      }
      this.props.onSave({
        ...payload,
        id_shop: ShopStore.currentShop.id,
        purchase_order_number: payload.purchaseOrderNumber,
        reference_number: payload.referenceNumber,
        created_by: payload.createdBy,
        id_vendor: payload.vendor,
        lineItems: this.state.lineItems,
        tax: this.state.tax,
      });
    });
  };

  onClickAddExistingPart = () => {
    this.setState({
      lineItems: [
        ...this.state.lineItems,
        {
          type: 'part',
          entityId: 'new',
          quantity: 1,
          volume: '',
          volumeUnit: '',
          unitCost: 0,
          totalCost: 0,
        },
      ],
    });
  };

  onClickAddService = () => {
    this.setState({
      lineItems: [
        ...this.state.lineItems,
        {
          type: 'service',
          entityId: '',
          quantity: 1,
          volume: '',
          volumeUnit: '',
          unitCost: 0,
          totalCost: 0,
        },
      ],
    });
  };

  onSubmitAddVendorModal = async (data) => {
    try {
      await this.setState({ savingContact: true });
      const { data: contact } = await ContactStore.createShopContact({
        ...data,
        shopId: ShopStore.currentShop.id,
      });
      AppStore.addSuccess('Contact saved successfully');
      await this.refreshVendors();
      // select the new vendor
      this.props.form.setFieldsValue({ vendor: contact.id });
      AppStore.openModals.set(this.CONTACT_FORM_MODAL_ID, false);
    } catch (error) {
      console.log(error);
      AppStore.addError('Error to save contact: ' + error.message);
    } finally {
      await this.setState({ savingContact: false });
    }
  };

  onSubmitAddPartModal = async (data) => {
    try {
      this.setState({
        loadingSavingNewPart: true,
      });
      const { description, number, cost, addToInventory = false } = data;
      const getName = () =>
        number ? `${number} - ${description}` : description;
      const payload = {
        id_shop: ShopStore.currentShop.id,
        name: getName(),
      };
      const newPart = await WorkOrderStore.createPartOnCustomEndpoint(payload);
      // update the new part with the description and number
      await WorkOrderStore.updatePartOnCustomEndpoint({
        id: newPart.id,
        description,
        number,
        cost,
        addToInventory,
      });
      message.success('Part saved successfully', 3);
      AppStore.openModals.set(this.ADD_PART_MODAL_ID, false);
      await this.refreshShopParts();

      this.setState({
        lineItems: [
          ...this.state.lineItems,
          {
            type: 'part',
            entityId: newPart.id,
            quantity: 1,
            volume: '',
            volumeUnit: '',
            unitCost: cost,
            totalCost: cost,
          },
        ],
      });
    } catch (error) {
      let friendlyMsg =
        'Sorry, we had some problems to save the Part. Try again later';
      if (error && error.message && JSON.parse(error.message)) {
        const errorObj = JSON.parse(error.message);
        friendlyMsg = isArray(errorObj.message)
          ? errorObj.message[0]
          : errorObj.message;
      }
      message.error(friendlyMsg, 3);
    } finally {
      this.setState({
        loadingSavingNewPart: false,
      });
    }
  };

  render() {
    const {
      getFieldDecorator,
      getFieldError,
      isFieldTouched,
      setFieldsValue,
    } = this.props.form;

    const statusOptions = Object.keys(PurchaseOrderStatusEnum)
      .map((purchaseOrderStatusKey) => ({
        key: purchaseOrderStatusKey,
        value: PurchaseOrderStatusEnum[purchaseOrderStatusKey],
      }))
      .map((purchaseOrderStatus) => {
        return (
          <Option key={purchaseOrderStatus.key} value={purchaseOrderStatus.key}>
            {purchaseOrderStatus.value}
          </Option>
        );
      });

    const purchaseOrderNumberError =
      isFieldTouched('purchaseOrderNumber') &&
      getFieldError('purchaseOrderNumber');
    const createdByError =
      isFieldTouched('createdBy') && getFieldError('createdBy');
    const statusError = isFieldTouched('status') && getFieldError('status');
    const vendorError = isFieldTouched('vendor') && getFieldError('vendor');

    if (this.state.initialLoading) {
      return (
        <LoadingWrapper>
          <Spin />
        </LoadingWrapper>
      );
    }

    return (
      <FormWrapper>
        {this.props.hideSelectFleet ? null : <DealershipChooser />}

        <Form
          onSubmit={(event) => {
            event.stopPropagation();
            event.preventDefault();
            this.onSubmitForm();
          }}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label={
                  ShopStore.currentShop.id === 374
                    ? 'Invoice Number:'
                    : 'Purchase Order Number:'
                }
                validateStatus={purchaseOrderNumberError ? 'error' : ''}
                help={purchaseOrderNumberError || ''}
              >
                {getFieldDecorator('purchaseOrderNumber', {
                  rules: [
                    {
                      required: true,
                      message: 'PO Number required!',
                    },
                  ],
                  initialValue: this.props.purchaseOrder.purchase_order_number,
                })(
                  <Input
                    disabled={true}
                    readOnly={true}
                    prefix="#"
                    allowClear={true}
                    placeholder="000000"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Reference number:">
                {getFieldDecorator('referenceNumber', {
                  rules: [],
                  initialValue: this.props.purchaseOrder.reference_number,
                })(
                  <Input
                    disabled={this.state.disableFields}
                    readOnly={this.state.disableFields}
                    prefix="#"
                    allowClear={true}
                    placeholder="000000"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Created by:"
                validateStatus={createdByError ? 'error' : ''}
                help={createdByError || ''}
              >
                {getFieldDecorator('createdBy', {
                  rules: [{ required: true, message: 'Created by required!' }],
                  initialValue: this.props.purchaseOrder.created_by,
                })(
                  <Select
                    disabled={this.state.disableFields}
                    readOnly={this.state.disableFields}
                    style={{
                      width: '100%',
                    }}
                    placeholder="Select a User"
                    optionFilterProp="children"
                  >
                    {this.createdByOptions}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: 20,
            }}
            gutter={16}
          >
            <Col span={6}>
              <Form.Item
                label="Select Vendor:"
                validateStatus={vendorError ? 'error' : ''}
                help={vendorError || ''}
                style={{
                  marginBottom: 5,
                }}
              >
                {getFieldDecorator('vendor', {
                  rules: [],
                  initialValue: this.props.purchaseOrder.id_vendor,
                })(
                  <Select
                    disabled={this.state.disableFields}
                    readOnly={this.state.disableFields}
                    style={{
                      width: '100%',
                    }}
                    placeholder="Search by Vendor Name"
                    optionFilterProp="children"
                  >
                    {this.vendorOptions}
                  </Select>
                )}
              </Form.Item>
              <Button
                disabled={this.state.disableFields}
                icon="plus"
                onClick={() => {
                  AppStore.openModals.set(this.CONTACT_FORM_MODAL_ID, true);
                }}
              >
                Add New Vendor
              </Button>
            </Col>
            <Col span={6}>
              {/* add invoice_date */}
              <Form.Item label="Invoice Date:">
                {getFieldDecorator('invoice_date', {
                  rules: [],
                  initialValue: this.props.purchaseOrder.invoice_date
                    ? moment(this.props.purchaseOrder.invoice_date)
                    : null,
                })(
                  <DatePicker
                    style={{ width: '100%' }}
                    disabled={this.state.disableFields}
                    readOnly={this.state.disableFields}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Popconfirm
                placement="top"
                title={
                  'Changing the status to Posted means you cannot edit this purchase order anymore.\r\nDo you wish to continue?'
                }
                visible={this.state.confirmationPopoverVisible}
                onConfirm={() => {
                  setFieldsValue({
                    status: 'posted',
                  });
                  this.setState({
                    confirmationPopoverVisible: false,
                  });
                }}
                onCancel={() => {
                  // change the status to open
                  setFieldsValue({
                    status: 'open',
                  });
                  this.setState({
                    confirmationPopoverVisible: false,
                  });
                }}
                okText="Yes"
                cancelText="No"
              />
              <Form.Item
                label="Status:"
                validateStatus={statusError ? 'error' : ''}
                help={statusError || ''}
              >
                {getFieldDecorator('status', {
                  rules: [{ required: true, message: 'Status required!' }],
                  initialValue: this.props.purchaseOrder.status,
                })(
                  <Select
                    disabled={this.state.disableFields}
                    readOnly={this.state.disableFields}
                    style={{
                      width: '100%',
                    }}
                    placeholder="Select a Status"
                    optionFilterProp="children"
                    onChange={(value) => {
                      if (value === 'posted') {
                        this.setState({
                          confirmationPopoverVisible: true,
                        });
                      }
                    }}
                  >
                    {statusOptions}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          {/* Line items panel */}
          <Row gutter={16}>
            <Col span={24}>
              <Text strong>Line Items</Text>
            </Col>
            <Col span={24}>
              <Collapse defaultActiveKey={['1']}>
                <Panel
                  header={
                    ShopStore.currentShop.id === 374
                      ? 'New Invoice Line 1'
                      : 'New Purchase Order Line 1'
                  }
                  key="1"
                >
                  {this.state.lineItems.map((item, index) => {
                    return (
                      <PurchaseOrderLineItem
                        item={item}
                        key={index}
                        partOptions={this.partOptions}
                        serviceOptions={this.serviceOptions}
                        onRemoveItem={(item) => {
                          const lineItems = this.state.lineItems.filter(
                            (lineItem) => lineItem !== item
                          );
                          this.setState({ lineItems });
                        }}
                        onChange={(item, key, value) => {
                          // if it's a entityId change, and the item type is "part", then we need to update the unit cost with the part cost, if any
                          const lineItems = this.state.lineItems.map(
                            (lineItem) => {
                              if (lineItem === item) {
                                const lineItemToReturn = {
                                  ...lineItem,
                                  [key]: value,
                                };
                                // if is changing the entityId in a part line item
                                if (
                                  key === 'entityId' &&
                                  item.type === 'part'
                                ) {
                                  const part = this.state.shopParts.find(
                                    (part) => part.id === value
                                  );
                                  if (part?.cost) {
                                    lineItemToReturn.unitCost = parseFloat(
                                      part.cost
                                    );
                                  } else {
                                    lineItemToReturn.unitCost = 0;
                                  }
                                }
                                return lineItemToReturn;
                              }
                              return lineItem;
                            }
                          );
                          this.setState({ lineItems });
                        }}
                      />
                    );
                  })}
                  <AddItemsActions>
                    <Button
                      disabled={this.state.disableFields}
                      onClick={() => this.onClickAddExistingPart()}
                      icon="plus"
                      type="link"
                    >
                      Add Existing Part
                    </Button>
                    <Button
                      disabled={this.state.disableFields}
                      onClick={() => {
                        AppStore.openModals.set(this.ADD_PART_MODAL_ID, true);
                      }}
                      icon="plus"
                      type="link"
                    >
                      Add New Part
                    </Button>
                    <Button
                      disabled={this.state.disableFields}
                      onClick={() => this.onClickAddService()}
                      icon="plus"
                      type="link"
                    >
                      Add Service
                    </Button>
                  </AddItemsActions>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <CostSummaryWrapper>
                <PurchaseOrdersCostSummary
                  className="purchase-orders-cost-summary"
                  tax={this.state.tax}
                  lineItems={this.state.lineItems}
                  childRef={() => {}}
                  onChangeTaxValue={(value) => {
                    this.setState({
                      tax: {
                        ...this.state.tax,
                        value,
                      },
                    });
                  }}
                  onChangeTaxType={(type) => {
                    this.setState({
                      tax: {
                        ...this.state.tax,
                        type,
                      },
                    });
                  }}
                />
              </CostSummaryWrapper>
            </Col>
          </Row>
          {/* Actions */}
          <Row gutter={16}>
            <Col span={24}>
              <ActionsWrapper>
                <Button onClick={this.props.onCancel}>Cancel</Button>
                <Tooltip
                  title={
                    this.state.disableFields
                      ? (ShopStore.currentShop.id === 374
                          ? 'Invoice'
                          : 'Purchase Order') + ' is posted, you cannot edit it'
                      : this.state.confirmationPopoverVisible
                      ? 'Changing the status to Posted means you cannot edit this purchase order anymore'
                      : ''
                  }
                >
                  <Button
                    disabled={this.state.disableFields}
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: 8 }}
                    loading={this.props.savingWorkOrder}
                  >
                    Save
                  </Button>
                </Tooltip>
              </ActionsWrapper>
            </Col>
          </Row>
        </Form>
        <ContactFormModal
          modalId={this.CONTACT_FORM_MODAL_ID}
          saving={this.state.savingContact}
          title={'Add New Vendor'}
          defaultData={{}}
          onCancel={() => {
            AppStore.openModals.set(this.CONTACT_FORM_MODAL_ID, false);
          }}
          onSubmit={(data) => {
            this.onSubmitAddVendorModal(data);
          }}
        />
        <AddPartModal
          modalId={this.ADD_PART_MODAL_ID}
          loadingSavingNewPart={this.state.loadingSavingNewPart}
          onCancel={() => {
            AppStore.openModals.set(this.ADD_PART_MODAL_ID, false);
          }}
          onSubmit={(data) => {
            this.onSubmitAddPartModal(data);
          }}
          shopParts={this.state.shopParts}
        />
      </FormWrapper>
    );
  }
}

const PurchaseOrderForm = Form.create({ name: 'purchase_order_form' })(
  PurchaseOrdersForm
);

export default PurchaseOrderForm;
