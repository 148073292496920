import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CurrentUserStore from 'stores/CurrentUserStore';
import Timezones from 'components/Timezones.json';
import { Form, Button, Select, InputNumber, notification } from 'antd';
import { Typography } from 'antd';

const { Title } = Typography;
class EditSettingsForm extends Component {
  state = {
    loading: false,
  }

  updateUser = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });
        await CurrentUserStore.user.updateSettings(values);
        this.setState({ loading: false });
        notification.success({
          message: 'Success',
          description: 'Settings Updated',
          duration: 3,
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Form onSubmit={this.updateUser} className="edit-profile-form">
          <Title style={{
            fontWeight: 'normal'
          }} level={3}>General</Title>
          <Form.Item label="Timezone">
            {getFieldDecorator('timezone', {
              rules: [
                {
                  required: true,
                  message: 'Please input your preferred timezone!',
                },
              ],
              initialValue:
                CurrentUserStore.user.settings.timezone || 'America/New_York',
            })(
              <Select showSearch placeholder="Timezone">
                {Object.keys(Timezones).map((key) => (
                  <Select.Option key={key} value={key}>
                    {Timezones[key]}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Title style={{
            fontWeight: 'normal'
          }} level={3}>Vehicles</Title>
          <Form.Item label="Odometer Unit">
            {getFieldDecorator('odometer', {
              rules: [
                {
                  required: true,
                  message: 'Please input your preferred unit!',
                },
              ],
              initialValue: CurrentUserStore.user.displayedOdometer,
            })(
              <Select placeholder="Odometer Unit">
                <Select.Option value="km">KM</Select.Option>
                <Select.Option value="miles">Miles</Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Fuel Unit">
            {getFieldDecorator('fuelUnit', {
              rules: [
                {
                  required: true,
                  message: 'Please input your preferred unit!',
                },
              ],
              initialValue: CurrentUserStore.user.settings.fuelUnit || 'litre',
            })(
              <Select placeholder="Odometer Unit">
                <Select.Option value="Litre">Litre</Select.Option>
                <Select.Option value="Gallons">Gallons</Select.Option>
              </Select>
            )}
          </Form.Item>

          <Title style={{
            fontWeight: 'normal'
          }} level={3}>Work Orders</Title>
          <Form.Item label="Set Hourly Labor Rate">
            {getFieldDecorator('laborRate', {
              rules: [],
              initialValue: CurrentUserStore.user.settings.laborRate || '0',
            })(
              <InputNumber
                key="value"
                defaultValue={CurrentUserStore.user.settings.laborRate || 0}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={this.state.loading}
            >
              Update Info
            </Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }
}

EditSettingsForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Form.create({ name: 'edit-profile-form' })(EditSettingsForm);
