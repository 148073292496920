import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'antd';
import DateRangeEnum from 'helpers/DateRangeEnum';

const { Option } = Select;

const DateRangeSelect = ({ onChange, value, defaultValue }) => {
  return (
    <Select
      defaultValue={defaultValue}
      value={value}
      style={{ width: 120 }}
      onChange={onChange}
    >
      <Option value={DateRangeEnum.ONE_DAY}>1 Day</Option>
      <Option value={DateRangeEnum.THREE_DAYS}>3 Days</Option>
      <Option value={DateRangeEnum.SEVEN_DAYS}>7 Days</Option>
      <Option value={DateRangeEnum.ONE_MONTH}>1 Month</Option>
      <Option value={DateRangeEnum.THREE_MONTHS}>3 Months</Option>
    </Select>
  );
};

DateRangeSelect.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
};

DateRangeSelect.defaultProps = {
  defaultValue: DateRangeEnum.SEVEN_DAYS,
  value: DateRangeEnum.SEVEN_DAYS,
};

export default DateRangeSelect;
