import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';
import * as L from 'leaflet';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/leaflet.markercluster-src.js';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import { observer, Observer } from 'mobx-react';
import { LocationService } from 'services';
import ShopStore from 'stores/ShopStore';
import styled from 'styled-components';
import { Badge, Card, List, Row, Tooltip, Input, Icon, TreeSelect } from 'antd';
import ReportsDataStore from 'stores/ReportsDataStore';
import moment from 'moment';
import DealershipChooser from 'components/DealershipChooser';
import {
  CriticalMarker,
  HealthyMarker,
  MajorMarker,
  MinorMarker,
  ShopMarker
} from './CustomMarkers';
import { PitstopButton } from '../../shared/PitstopUI/PitstopButton';
import { PitstopSwitch } from '../../shared/PitstopUI/PitstopSwitch';
import { observe } from 'mobx';
import CarStore from 'stores/CarStore';

import { showDemoVinOrRealVin } from 'shared/utils';

import { CurrentUserStore } from 'stores';

import { Filter } from 'components';
import { queryUtils } from 'helpers';

const SidebarList = styled.div`
  height: 490px;
  overflow-x: auto;
  width: 300px;
  margin-left: 0px;

  .ant-list-pagination {
    text-align: -webkit-center;
  }
`;

const ButtonsContainer = styled.div`
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  button {
    margin-right: auto;
    margin-bottom: 8px;
  }
`;

const MapArea = styled.div`
  flex: 1;
  position: relative;
  height: 490px;
  z-index: 2;
  margin-left: 20px;

  &&& .leaflet-tooltip-right {
    margin-left: 18px;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    border: none;
    border-radius: 0;
  }
  &&& .leaflet-tooltip-right:before {
    border-right-color: rgba(0, 0, 0, 0);
  }
`;

const LocationItem = styled(List.Item)`
  && {
    background: ${(props) => (props.selected ? '#f0f8ff' : 'inherit')};
    padding: 12px 8px;
    display: flex;
    align-items: center;
  }

  button {
    margin-top: 12px;
  }

  .flex {
    flex: 1;
  }

  a {
    margin-left: 12px;
  }

  .last-update-at {
    font-size: 14px;
  }

  .last-update-at span {
    font-weight: bold;
  }

  .vin {
    font-weight: bold;
  }
`;

const LegendArea = styled.div`
  z-index: 1000;
  position: absolute;
  top: 8px;
  right: 8px;
`;

const Legend = styled(Card)`
  && {
    position: relative;
    right: 40px;
    top: 0px;
    padding: 16px;
    z-index: 1;
    max-width: 240px;
  }
  && .ant-card-body {
    padding: 0px;
  }
  && .ant-badge {
    display: block;
  }
  && .ant-btn {
    position: absolute;
    right: 16px;
    top: 16px;
  }
`;
const InfoButton = styled(PitstopButton)`
  && {
    position: absolute;
    z-index: 2;
    right: 0;
  }
`;

const Container = styled.div`
  margin: 10px 0px;
  display: flex;
  flex-direction: column;

  #servicePriority {
    display: flex;

    @media only screen and (max-width: 768px) {
      flex-direction: column;

      p,
      div {
        width: 100%;
      }
    }

    @media only screen and (min-width: 768px) {
      p {
        width: 12em;
        text-align: right;
        margin-right: 1em;
      }

      #filter {
        width: 21em;
      }
    }
  }

  #filter {
    display: flex;
    margin-bottom: 1em;

    @media only screen and (max-width: 768px) {
      flex-direction: column;

      p,
      div {
        width: 100%;
      }
    }

    @media only screen and (min-width: 768px) {
      p {
        width: 12em;
        text-align: right;
        margin-right: 1em;
      }
    }
  }
`;

const ShopMarkerFilter = styled.div`
    display: flex;
    min-width: 300px;
    margin-bottom: 1em;

    @media only screen and (max-width: 768px) {
      flex-direction: column;

      p,
      div {
        width: 100%;
      }
    }

    @media only screen and (min-width: 768px) {
      p {
        width: 12em;
        text-align: right;
        margin-right: 1em;
      }
    }
`;

const FoodExpressShopData = require('./foodExpressShopList.json');
const FoodExpressTreeSelectData = FoodExpressShopData.map(({ Name, id }) => ({
  title: Name,
  value: id
}));
const FoodExpressShopAllIds = FoodExpressShopData.map(({ id }) => id);

class LiveLocationPage extends Component {
  static propTypes = {};

  state = {
    currentPage: 1,
    selectedId: null,
    autoRefresh: false,
    locations: [],
    filterKeyValues: {
      servicePriority: [],
      carName: '',
      vin: '',
    },
    firstLoad: true,
    selectedShopIds: []
  };

  setSelectedShopValues(ids) {
    this.setState({ selectedShopIds: ids }, () => this.refreshMap());
  }

  get shopId() {
    return ShopStore.currentShop.id;
  }

  get locations () {
    return _([...LocationService.locationReports])
      .filter(
        (el) => el.location.longitude !== null && el.location.latitude !== null
      )
      .value();
  }

  autoRefresh = setInterval(async () => {
    if (this.state.autoRefresh) {
      await this.refresh();
    }
  }, 10000);

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    await this.refresh();
  });

  disposer1 = observe(CarStore, 'demo', async () => {
    if (_.isBoolean(CarStore.demo)) {
      CarStore.demo
        ? await this.refresh()
        : this.layerGroup.clearLayers() && LocationService.reset();
    }
  });

  async componentDidMount() {
    CarStore.demo !== false ? await this.refresh() : LocationService.reset();
  }

  componentWillUnmount = () => {
    this.disposer();
    this.disposer1();
    clearInterval(this.autoRefresh);
  };

  refresh = () => {
    this.setState(
      {
        filterKeyValues: {
          servicePriority: [],
          carName: '',
          vin: '',
        },
      },
      async () => await this.load()
    );
  };

  load = async (params = {}) => {
    if (this.layerGroup) {
      this.layerGroup.clearLayers();
    }

    if (this.shopId !== -1) {
      // force load to trigger
      LocationService.loadedLocation = false;
      // force driving report to be null
      ReportsDataStore.drivingReport = null;
      // always get driving report and maintenance report if shop changes
      await ReportsDataStore.getDrivingReport(this.shopId);
      await ReportsDataStore.getMaintenanceSummaryReport(this.shopId);

      await LocationService.fetchLocationForShop(this.shopId, params);

      this.setState(
        {
          currentPage: 1,
        },
        () => this.refreshMap()
      );
    }
  };

  getMarker = (carId) => {
    let priority = this.getServicePriority(carId);

    switch (priority) {
      case 'minor':
        return MinorMarker;
      case 'critical':
        return CriticalMarker;
      case 'major':
        return MajorMarker;
      default:
        return HealthyMarker;
    }
  };

  getShopMarker() {
    return ShopMarker;
  }

  refreshMap = () => {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }

    const locations = this.locations;

    if (this.mapView && locations && locations.length > 0) {
      const markers = [];
      for (let i = 0; i < locations.length; i++) {
        const { location, carName, vin, id } = locations[i];

        markers.push(
          L.marker([location.latitude, location.longitude], {
            icon: this.getMarker(id),
          })
            .bindTooltip(carName || showDemoVinOrRealVin(vin), {
              permanent: true,
              direction: 'right',
            })
            .bindPopup(
              `${carName || showDemoVinOrRealVin(vin)}<p>Last seen at ${moment(
                location.timestamp * 1000
              ).fromNow()}`
            )
            .on('click', () => {
              this.setState({ selectedId: id });
            })
        );
      }

      // shop markers
      const selectedShopData = _.filter(FoodExpressShopData,
          shopData => _.includes(this.state.selectedShopIds, shopData.id));

      _.each(selectedShopData, shopData => {
        markers.push(
          L.marker([shopData.Latitude, shopData.Longitude], {
            icon: this.getShopMarker()
          })
            .bindTooltip(shopData.Name, {
              permanent: true,
              direction: 'right',
            })
            .bindPopup(_.map(shopData, (val, key) => `<p>${key}: ${val}</p>`).join(''))
        );
      });


      if (!this.map) {
        this.map = L.map(this.mapView);
        // create the tile layer with correct attribution
        const osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        const osmAttrib =
          'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';
        const osm = new L.TileLayer(osmUrl, {
          attribution: osmAttrib,
        });
        this.map.addLayer(osm);
        this.map.on('click', () => {
          if (this.state.selectedId !== null) {
            this.setState({
              selectedId: null,
            });
          }
        });
        this.map.addControl(new L.Control.Fullscreen());
        this.layerGroup = L.layerGroup();
        this.layerGroup.addTo(this.map);
      }

      this.layerGroup.clearLayers();
      markers.forEach((marker) => {
        marker.addTo(this.layerGroup);
      });

      this.map.fitBounds(new L.featureGroup(markers).getBounds(), {
        padding: [50, 50],
      });
    } else if (this.mapView && locations && locations.length === 0) {
      if (this.layerGroup) {
        this.layerGroup.clearLayers();
      }
    }

    // check if car was passed as query param
    const defaultCarLocation = queryUtils.getSearchParamByKey(window.location.search, 'car');
    if (defaultCarLocation) {
      // get car location
      const carLocation = _.find(locations, (location) => location.id === Number(defaultCarLocation));
      if (carLocation && this.state.firstLoad && this.map) {
        this.setState({
          firstLoad: false,
          filterKeyValues: {
            ...this.state.filterKeyValues,
            carName: carLocation.carName
          }
        }, () => {
          this.onFilter('carName', carLocation.carName);
        });
      }
    }
  };

  getStatusColor = (carId) => {
    let priority = this.getServicePriority(carId);

    switch (priority) {
      case 'critical':
        return '#eb3d34';
      case 'major':
        return '#e28931';
      case 'minor':
        return '#e2eb34';
      default:
        return '#52c41a';
    }
  };

  getServicePriority = (carId) => {
    let reportDetail = _.filter(
      ReportsDataStore.totalServiceCountPerCar,
      (report) => Number(report.carId) === Number(carId)
    )[0];

    if (!_.isNil(reportDetail)) {
      const {
        critical_service_count,
        major_service_count,
        minor_service_count,
      } = reportDetail;
      if (critical_service_count > 0) return 'critical';
      if (major_service_count > 0) return 'major';
      if (minor_service_count > 0) return 'minor';
    }

    return 'success';
  };

  /**
   *
   * @param [location] {{latitude: any, longitude: any}}
   * @param [zoomLevel] {number}
   */
  setMapFocus (location, zoomLevel) {
    console.log(location);
    this.map.setView([location.latitude, location.longitude], zoomLevel, {
      animate: true,
      pan: { duration: 0.3 },
    });
  }

  onFilter = async (key, value) => {
    let { filterKeyValues } = this.state;

    filterKeyValues[key] = value;

    this.setState(
      {
        filterKeyValues,
      },
      async () => {
        let params = {};

        _.forEach(this.state.filterKeyValues, (value, key) => {
          if (!_.isEmpty(value)) {
            if (key === 'servicePriority') {
              params['hasServicePriority'] = _.map(
                this.state.filterKeyValues.servicePriority,
                (value) => value.toLowerCase()
              ).toString();
            } else {
              params = { [key]: value };
            }
          }
        });

        await this.load(params);
      }
    );
  };

  shouldShowShopMarker() {
    const foodExpressShopIds = [1241, 1245, 1246, 1247, 1249, 1250, 1251, 1253, 1252];

    return CurrentUserStore?.user?.role === 'admin' ||
      (process.env.REACT_APP_ENV === 'production' && foodExpressShopIds.includes(this.shopId));
  }

  render() {
    let loading =
      !LocationService.loadedLocation &&
      this.shopId !== -1 &&
      CarStore.demo !== false;

    return (
      <Container>
        <div id="dealership">
          <h1>Live Location</h1>
          <DealershipChooser />
        </div>
        <div id="servicePriority">
          <p>Filter by Service Priority:</p>
          <div id="filter">
            <Filter
              defaultKeys={['Critical', 'Major', 'Minor']}
              placeholder="Select priority to view vehicle locations"
              filterValues={this.state.filterKeyValues.servicePriority}
              onChange={(keys) => this.onFilter('servicePriority', keys)}
            />
          </div>
        </div>

        <div id="filter">
          <p>Filter by VIN:</p>
          <div>
            <Tooltip trigger={['focus']} title="Vin" placement="topLeft">
              <Input
                placeholder="Vin"
                name="vin"
                value={this.state.filterKeyValues.vin}
                prefix={<Icon type="search" />}
                onChange={(e) => this.onFilter('vin', e.target.value)}
              />
            </Tooltip>
          </div>
        </div>

        <div id="filter">
          <p>Filter by Unit ID:</p>
          <div>
            <Tooltip trigger={['focus']} title="Unit ID" placement="topLeft">
              <Input
                placeholder="Unit ID"
                name="carName"
                value={this.state.filterKeyValues.carName}
                prefix={<Icon type="search" />}
                onChange={(e) => this.onFilter('carName', e.target.value)}
              />
            </Tooltip>
          </div>
        </div>
        <ShopMarkerFilter hidden={!this.shouldShowShopMarker()}>
          <p>Outside shops on map:</p>
          <div>
            <TreeSelect
              allowClear={true}
              placeholder="Select a shop"
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              style={{ width: '300px' }}
              dropdownStyle={{ maxHeight: '300px' }}
              onChange={ids => this.setSelectedShopValues(ids)}
              value={this.state.selectedShopIds}
              maxTagCount={2}
              maxTagPlaceholder={omittedValues =>
                `+ ${omittedValues.length} Shops ...`
              }
              treeData={[
                {
                  title:
                    this.state.selectedShopIds.length === FoodExpressShopData.length ? (
                      <span
                        onClick={() => this.setSelectedShopValues([])}
                        style={{
                          display: 'inline-block',
                          color: '#286FBE',
                          cursor: 'pointer'
                        }}
                      >
                Unselect all
              </span>
                    ) : (
                      <span
                        onClick={() => this.setSelectedShopValues(FoodExpressShopAllIds)}
                        style={{
                          display: 'inline-block',
                          color: '#286FBE',
                          cursor: 'pointer'
                        }}
                      >
                Select all
              </span>
                    ),
                  value: 'xxx',
                  disableCheckbox: true,
                  disabled: true
                }, ...FoodExpressTreeSelectData
              ]}
            />
          </div>
        </ShopMarkerFilter>
        <Row>
          <Card bodyStyle={{ display: 'flex', margin: 10, padding: 0 }}>
            <SidebarList>
              <ButtonsContainer>
                <PitstopButton
                  icon="reload"
                  onClick={async () => await this.refresh()}
                >
                  Refresh
                </PitstopButton>
                <PitstopSwitch
                  checkedChildren="auto refresh"
                  unCheckedChildren="auto refresh"
                  defaultChecked={this.state.autoRefresh}
                  onChange={(checked) =>
                    this.setState({ autoRefresh: checked })
                  }
                />
              </ButtonsContainer>
              <List
                pagination={{
                  current: this.state.currentPage,
                  position: 'bottom',
                  pageSize: 10,
                  total: this.locations.length,
                  simple: true,
                  onChange: (page) => this.setState({ currentPage: page }),
                }}
                dataSource={this.locations}
                loading={loading}
                renderItem={(item) => (
                  <Observer>
                    {() => (
                      <LocationItem
                        selected={item.id === this.state.selectedId}
                      >
                        <div className="flex">
                          <div>
                            <Badge
                              color={this.getStatusColor(item.id)}
                              text={
                                item.carName ||
                                `${item.make} ${item.model} ${item.year}`
                              }
                            />
                          </div>
                          <p className='last-update-at'><span>Last Update At: </span>{
                            moment.tz(
                              item?.location?.timestamp * 1000,
                              CurrentUserStore.user.settings.timezone
                            ).fromNow()
                          } ({
                            moment.tz(
                              item?.location?.timestamp * 1000,
                              CurrentUserStore.user.settings.timezone
                            )
                              .format('lll')
                          })</p>
                          <p className='vin'>{showDemoVinOrRealVin(item.vin)}</p>
                          <div>
                            <PitstopButton
                              onClick={() => {
                                this.setState({ selectedId: item.id });
                                if (this.map) {
                                  this.setMapFocus(item.location, 16);
                                }
                              }}
                              type="primary"
                            >
                              View Vehicle Location
                            </PitstopButton>
                            <Link to={`car/${item.id}`}>
                              <PitstopButton type="secondary">
                                Vehicle Profile
                              </PitstopButton>
                            </Link>
                          </div>
                          <div />
                        </div>
                      </LocationItem>
                    )}
                  </Observer>
                )}
              />
            </SidebarList>
            <MapArea
              ref={(ref) => {
                this.mapView = ref;
              }}
            >
              <LegendArea>
                <InfoButton
                  type="dashed"
                  shape="circle"
                  style={{
                    backgroundColor: this.state.showLegend ? '#fff' : '#4d81c3',
                    color: this.state.showLegend ? '#000' : '#fff',
                  }}
                  icon={this.state.showLegend ? 'close' : 'info'}
                  onClick={() =>
                    this.setState({ showLegend: !this.state.showLegend })
                  }
                />
                {!this.state.showLegend ? null : (
                  <Legend>
                    <p>Vehicle Health Legend</p>
                    <Badge color="#52c41a" text="No Service Due" />
                    <Badge color="#eb3d34" text="Critical Service Required" />
                    <Badge color="#e28931" text="Major Service Required" />
                    <Badge color="#e2eb34" text="Minor Service Required" />
                  </Legend>
                )}
              </LegendArea>
            </MapArea>
          </Card>
        </Row>
      </Container>
    );
  }
}

export default observer(LiveLocationPage);
