import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Tooltip } from 'antd';
import { get } from 'lodash';
import { RoleDisplayMappingForShopUser } from '../../stores/Classes/UserObject';

export function UsersToAddTable(props) {
  const { users, onClickDeleteUser } = props;
  if (!users.length) {
    return <React.Fragment />;
  }

  const columns = [
    { dataIndex: 'firstName', title: 'First Name' },
    { dataIndex: 'lastName', title: 'Last Name' },
    { dataIndex: 'email', title: 'Email/Username' },
    { dataIndex: 'password', title: 'Password' },
    { dataIndex: 'phone', title: 'Phone' },
    {
      dataIndex: 'role',
      title: 'Role',
      render: (text) => get(RoleDisplayMappingForShopUser, text, 'N/A'),
    },
    {
      dataIndex: 'actions',
      title: '',
      render: (unused, row) => {
        return (
          <div>
            <Tooltip title="Delete">
              <Button
                type="link"
                icon="delete"
                onClick={() => {
                  if (onClickDeleteUser) {
                    onClickDeleteUser(row);
                  }
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={users} rowKey="email" />
    </div>
  );
}

UsersToAddTable.propTypes = {
  users: PropTypes.array.isRequired,
  onClickDeleteUser: PropTypes.func.isRequired,
};
