import React, { Component } from 'react';
import styled from 'styled-components';

import { Row, Col } from 'antd';

import {
  ResetPasswordForm,
  AuthSliderBackground,
} from 'v3/components/forms/auth';

const Container = styled.div`
  height: 100%;
  width: 100%;

  opacity: ${(props) => props.isLogin && 0.8};
  pointer-events: ${(props) => props.isLogin && 'none'};

  && .slick-track,
  .slick-slider,
  .ant-carousel,
  .slick-list,
  .slick-slide > div {
    height: 100% !important;
  }
`;

const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  padding: 2vw 6vw;

  #register-form {
    margin: auto;
  }
`;

class ResetPasswordPage extends Component {
  state = {
    isLogin: false,
  };

  render() {
    const { isLogin } = this.state;

    return (
      <Container isLogin={isLogin}>
        <Row type="flex" style={{ height: '100%' }}>
          <Col md={14} xs={24}>
            <AuthSliderBackground />
          </Col>

          <Col md={10} xs={24}>
            <LoginFormContainer>
              <ResetPasswordForm
                isUserLogin={(isLogin) => {
                  this.setState({
                    isLogin: isLogin,
                  });
                }}
              />
            </LoginFormContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ResetPasswordPage;
