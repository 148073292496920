import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import { Button, Card, DatePicker, Icon } from 'antd';

import { AppStore } from 'stores';

const DateField = styled.div`
  margintop: 10px 0px;

  p {
    width: 40px;
    display: inline-block;
    font-weight: 700;
  }
`;

export default function TimeSelector({ resetBtn, searchBtn }) {
  const [startTime, setStartTime] = useState(moment().subtract(7, 'days'));
  const [endTime, setEndTime] = useState(moment());

  function onReset() {
    setStartTime(moment().subtract(7, 'days'));
    setEndTime(moment());
    resetBtn();
  }

  function onSearch() {
    if (!startTime || !endTime) {
      AppStore.addNotification('Please select time you want to get data!');
      return;
    }

    if (endTime.diff(startTime, 'days') > 7) {
      AppStore.addNotification('Please select time in the range of 7 days!');
      return;
    }
    searchBtn(startTime, endTime);
  }

  return (
    <>
      <Card>
        <div>
          <p>
            <b>Select the Time Range</b>
          </p>

          <i>
            <Icon type="info-circle" style={{ fontSize: 14, marginRight: 8 }} />
            Time Selection is restricted to maximum of 7 days, since there are a
            lot of data to display
          </i>

          <DateField style={{ margin: '10px 0px' }}>
            <p style={{ fontWeight: 500, width: 80 }}>Start Date</p>

            <DatePicker
              placeholder="Start Date"
              format="YYYY-MM-DD"
              defaultValue={startTime}
              value={startTime}
              onChange={(date) => {
                setStartTime(date);
                if (endTime.diff(date, 'days') < 1) {
                  setEndTime(moment(date).add(1, 'days'));
                } else if (endTime.diff(date, 'days') > 7){
                  setEndTime(moment(date).add(7, 'days'));
                }
              }}
              disabledDate={d => d && d > moment().endOf('day')}
            />
          </DateField>

          <DateField style={{ margin: '10px 0px' }}>
            <p style={{ fontWeight: 500, width: 80 }}>End Date</p>

            <DatePicker
              placeholder="End Date"
              format="YYYY-MM-DD"
              defaultValue={endTime}
              value={endTime}
              onChange={(date) => {
                if (date.diff(startTime, 'days') < 1){
                  setStartTime(moment(date).subtract(1, 'days'));
                } else if (date.diff(startTime, 'days') > 7) {
                  setStartTime(moment(date).subtract(7, 'days'));
                }
                setEndTime(date);
              }}
              disabledDate={d => d && d > moment().endOf('day')}
            />
          </DateField>
        </div>

        <Button style={{ marginRight: 10 }} type="primary" onClick={onReset}>
          Reset Time
        </Button>

        <Button type="primary" onClick={onSearch}>
          Begin Search
        </Button>
      </Card>
    </>
  );
}

TimeSelector.propTypes = {
  resetBtn: PropTypes.func,
  searchBtn: PropTypes.func,
};
