import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import styled from 'styled-components';
import _ from 'lodash';
import queryString from 'query-string';
import moment from 'moment-timezone';
// import humanizeDuration from 'humanize-duration';

import { Col, Row, Select, Icon, Typography, Tag } from 'antd';

import { Link } from 'react-router-dom';

import {
  PitstopTable,
  PitstopButton,
  PitstopModal,
  customFleets,
} from 'shared';

import {
  AppStore,
  CarStore,
  CurrentUserStore,
  IssueStore,
  ShopStore,
} from 'stores';
import { CarListTableCacheStore } from 'stores/CacheStore';

import {
  renderIndicator,
  renderVehicleId,
  renderIssues,
  renderScanner,
  renderDriver,
  renderNextPMVisit,
  renderWorkOrderAssigned
} from './CarListTableColumns';
import CarListFilter from './CarListFilter';
import { IssuesTable } from 'containers/Issues';
import SelectCarDriverModal from 'containers/Cars/SelectCarDriverModal';
import { CreateAppointmentPage } from 'containers/CreateAppointment';
import { ShareVehicleHealthReportPage } from 'containers/ShareVehicleHealthReport';
import ConnectivityStatus from './ConnectivityStatus';

const Option = Select.Option;
const Text = Typography.Text;

const DVIRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tag {
    cursor: pointer;
    font-size: 1rem;
    padding: 4px 10px;
  }
`;

const IndicatorTableWrapper = styled.div`
  &&& {
    display: grid;

    table th.indicator,
    table td.indicator {
      position: relative;
      padding: 0;
      width: 8px;
    }
  }
`;
class CarListTable extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object
  };

  tableStore;

  state = {
    selectedCarId: undefined,
    searchKey: null,
    searchValue: null,
    connectivityStatus: null,
    maxPriority: _.isNil(queryString.parse(this.props.location.search).priorityFilter) ? 'All' : queryString.parse(this.props.location.search).priorityFilter,
    initialBookServiceDateTime: null,
    nextPmCars: [],
  };

  modalId = 'CarListSelectDriver';

  refreshTableOffset = false;

  get shopId () {
    return ShopStore.currentShop.id;
  }

  get columns () {
    let columns = [
      {
        key: 'indicator',
        className: 'indicator',
        title: null,
        render: renderIndicator,
      },
      {
        key: 'car_name',
        title: 'Vehicle ID',
        //TODO Set this to true once the bug that sets all issue counts to 0 is resolved
        sorter: false,
        render: renderVehicleId,
      },
      {
        key: 'issues',
        title: 'Issues',
        sorter: true,
        defaultSortOrder: 'descend',
        render: renderIssues,
        width: 200,
      },
      {
        field: 'driver',
        filter: false,
        title: 'Current Driver',
        render: renderDriver(this),
        width: '10%',
        rowClick: false,
      },
      {
        field: 'workOrderAssigned',
        filter: false,
        title: 'Work Order Assigned',
        render: (car) => {
          if (car.workOrdersAssigned) {
            return renderWorkOrderAssigned(car.workOrdersAssigned);
          }
          return 'N/A';
        },
        rowClick: false,
      },
      {
        field: 'dvir',
        filter: false,
        title: 'DVIR',
        render: (car) => {
          if (!car.dvir) {
            return (
              <DVIRWrapper>
                <Text>-</Text>
              </DVIRWrapper>
            );
          }
          const safetyStatus = _.get(car, 'dvir.record.safetyStatus');
          if (!safetyStatus) {
            return (
              <DVIRWrapper>
                <Text>-</Text>
              </DVIRWrapper>
            );
          }
          let color;
          let label;
          if (safetyStatus === 'safe') {
            color = 'green';
            label = 'SAFE';
          } else if (safetyStatus === 'unsafe') {
            color = 'red';
            label = 'UNSAFE';
          } else {
            color = 'geekblue';
            label = 'RESOLVED';
          }
          const vehicleDefects = _.get(car, 'dvir.record.payload.vehicleDefects', []);
          const trailerDefects = _.get(car, 'dvir.record.payload.trailerDefects', []);
          const totalDefects = vehicleDefects.length + trailerDefects.length;
          return (
            <DVIRWrapper>
              <Tag className='tag' color={color} key={car.dvir}>
                <Link
                  onClick={evt => evt.stopPropagation()}
                  to={`/dvir/${car.id}`}
                >
                  {label} {totalDefects > 0 ? '('+ totalDefects +')' : ''}
                </Link>
              </Tag>
            </DVIRWrapper>
          );
        }
      },
      {
        field: 'nextPm',
        filter: false,
        title: 'Next PM',
        render: (car) => {
          if (car.nextPm) {
            return renderNextPMVisit(car.nextPm);
          }
          return (
            renderNextPMVisit(
              _.find(this.state.nextPmCars, { carId: car.id })
            )
          );
        },
        rowClick: false,
      },
      {
        key: 'scanner',
        field: 'scanner',
        dataIndex: 'scanner',
        editable: false,
        filter: false,
        defaultSortOrder: 'descend',
        title: 'Device Connection',
        sorter: true,
        render: renderScanner,
      },
      {
        field: 'actions',
        key: 'actions',
        title: 'Actions',
        render: (car) => {
          return (
            <>
              {/* <PitstopButton
                ghost
                onClick={() => {
                  this.setState({
                    selectedCarId: car.id,
                    initialBookServiceDateTime: moment(),
                  }, () =>
                    AppStore.openModals.set('BOOK_SERVICE_MODAL', true)
                  );
                }}
              >
                Book Service
              </PitstopButton> */}
              <PitstopButton
                ghost
                onClick={() => {
                  this.setState({
                    selectedCarId: car.id,
                    initialBookServiceDateTime: moment(),
                  }, () =>
                    AppStore.openModals.set('SHARE_VEHICLE_HEALTH_REPORT_MODAL', true)
                  );
                }}
              >
                <Icon type="share-alt" />
                Health Report
              </PitstopButton>
            </>
          );
        },
        rowClick: false,
      },
    ];

    if (customFleets.motiveFleets.includes(this.shopId)) {
      columns = _.filter(
        [...columns],
        (col) => !['scanner'].includes(col.key)
      );
    }

    if (customFleets.npl.includes(this.shopId)) {
      columns = _.filter(
        [...columns],
        (col) => !['scanner', 'issuesCategory', 'image'].includes(col.key)
      );
    }

    if (customFleets.wolfline.includes(this.shopId) || customFleets.bmc.includes(this.shopId)) {
      columns = _.filter([...columns], (col) => !['scanner'].includes(col.key));
    }

    return _.filter(columns, Boolean);
  }

  get data () {
    if (_.isNil(this.tableStore)) {
      return [];
    }
    let data = _.map(this.tableStore.data, (id) => CarStore.data.get(id));

    return _.filter(data, Boolean);
  }

  get connectivityStatus () {
    return CarListTableCacheStore.getData('connectivityStatus') || 'all';
  }

  get maxPriority () {
    return CarListTableCacheStore.getData('maxPriority') || 'All';
  }

  async componentDidUpdate (prevProps, prevState) {
    let { connectivityStatus, searchValue } = this.state;
    if (
      connectivityStatus !== prevState.connectivityStatus ||
      searchValue !== prevState.searchValue ||
      this.state.maxPriority !== prevState.maxPriority
    ) {
      await this.fetchData(!!this.refreshTableOffset);
      this.refreshTableOffset = false;
    }
    if (CarListTableCacheStore.getData('maxPriority') !== this.state.maxPriority) {
      await this.fetchData(!!this.refreshTableOffset);
      this.refreshTableOffset = false;
      CarListTableCacheStore.setData('maxPriority', this.state.maxPriority);
    }
  }

  fetchData = async (shouldRefreshTable = false, filters, table = this.tableStore) => {
    if (_.isNil(table)) {
      return;
    }
    let { searchValue, searchKey } = this.state;
    if (_.isNil(filters)) {
      let { offset, limit, sort, filter } = this.tableStore;

      filters = { offset: shouldRefreshTable ? 0 : offset, limit, sort, filter };
    }

    _.set(filters, 'filter.exclude', 'issueList'); // attributes not used

    if (!_.isNil(searchValue)) {
      filters.filter = {
        ...filters.filter,
        [searchKey]: searchValue,
      };
    }

    if (!_.isNil(this.connectivityStatus)) {
      filters.filter = {
        ...filters.filter,
        connectivityStatus: this.connectivityStatus,
      };
      if (this.connectivityStatus === 'all') {
        delete filters.filter.connectivityStatus;
      }
    }
    if (!_.isNil(this.state.maxPriority)) {
      if (this.state.maxPriority === 'cars') {
        this.setState({ maxPriority: 'All' });
      }
      filters.filter = {
        ...filters.filter,
        maxPriority: this.state.maxPriority,
      };

      if (this.state.maxPriority === 'All') {
        delete filters.filter.maxPriority;
      }
    }

    if (CurrentUserStore.user.role === 'customer') {
      await CarStore.getUserCarsForTable(
        filters,
        table,
        CurrentUserStore.user.id,
        CurrentUserStore.user.shopId
      );
    } else {
      await CarStore.getShopCarsForTable(filters, table, this.shopId);
    }
  };

  setFilter = (key, value) => {
    // force to reset table
    this.setState({
      searchKey: key,
      searchValue: value,
    });
    this.refreshTableOffset = true;
  };

  render () {
    let { selectedCarId, initialBookServiceDateTime } = this.state;

    return (
      <IndicatorTableWrapper>
        {!customFleets.wolfline.includes(ShopStore.currentShop.id) && !customFleets.motiveFleets.includes(ShopStore.currentShop.id) && (
          <ConnectivityStatus />
        )}

        <CarListFilter
          tableStore={this.tableStore}
          setFilter={(key, value) => this.setFilter(key, value)}
        />
        <Row style={{ margin: '15px 0px' }}>
          <Col span={6}>
            <p style={{ marginBottom: 0 }}>Filter by Vehicle Priority</p>
            <Select
              showSearch
              placeholder="Filter by Issue Priority"
              value={this.state.maxPriority}
              style={{ width: '100%' }}
              onChange={(value) => {
                this.setState({ maxPriority: value });
                let currentUrlParams = new URLSearchParams(window.location.search);
                currentUrlParams.set('priorityFilter', value);
                this.props.history.push(window.location.pathname + '?' + currentUrlParams.toString());
                CarListTableCacheStore.setData('maxPriority', value);
              }}
            >
              <Option value="All">All</Option>
              <Option value="Critical">Critical</Option>
              <Option value="Major">Major</Option>
              <Option value="Minor">Minor</Option>
            </Select>
          </Col>
          {
            !customFleets.motiveFleets.includes(ShopStore.currentShop.id) && (
              <Col span={6} style={{ marginLeft: '20px' }}>
                <p style={{ marginBottom: 0 }}>Filter by Connectivity Status</p>

                <Select
                  showSearch
                  placeholder="Filter by Connectivity Status"
                  value={this.connectivityStatus}
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    this.setState({ connectivityStatus: value });
                    CarListTableCacheStore.setData('connectivityStatus', value);
                  }}
                >
                  <Option value="all">All</Option>
                  <Option value="Active">Active</Option>
                  <Option value="Inactive">Inactive</Option>
                  <Option value="Disconnected">Disconnected</Option>
                  <Option value="No Device">No Device</Option>
                </Select>
              </Col>
            )
          }
        </Row>

        <PitstopTable
          id={`${ShopStore.currentShop.id}-cars-list-car-page`}
          columns={this.columns}
          data={this.data}
          fetchData={(filters) => {
            this.fetchData(false, filters);
          }}
          rowKey="id"
          onRowClick={(record) => {
            this.props.history.push(`/car/${record.id}`);
          }}
          getTableStore={(tableStore) => {
            this.tableStore = tableStore;
          }}
          expandedRowRender={(car) => (
            <IssuesTable
              isEnabled={!_.isNil(car.id)}
              id={`car-${car.id}`}
              fetchData={(params, store) => {
                IssueStore.getIssuesForCarId(
                  {
                    ...params,
                  },
                  store,
                  car.id
                );
              }}
              page="carPage"
            />
          )}
        />

        <PitstopModal
          okButtonProps={{ style: { display: 'none' } }}
          onOk={() => { }}
          onCancel={() => {
            AppStore.openModals.set('BOOK_SERVICE_MODAL', false);
          }}
          title=""
          width={800}
          id="BOOK_SERVICE_MODAL"
        >
          <CreateAppointmentPage
            selectedCarId={selectedCarId}
            initialBookServiceDateTime={initialBookServiceDateTime}
          />
        </PitstopModal>

        <PitstopModal
          okButtonProps={{ style: { display: 'none' } }}
          onOk={() => { }}
          onCancel={() => {
            AppStore.openModals.set('SHARE_VEHICLE_HEALTH_REPORT_MODAL', false);
          }}
          title=""
          width={800}
          id="SHARE_VEHICLE_HEALTH_REPORT_MODAL"
        >
          <ShareVehicleHealthReportPage
            selectedCarId={selectedCarId}
          />
        </PitstopModal>

        <SelectCarDriverModal id={this.modalId} carId={selectedCarId} />
      </IndicatorTableWrapper>
    );
  }
}

decorate(CarListTable, {
  tableStore: observable,
  refreshTableOffset: observable,
  shopId: computed,
  data: computed,
  connectivityStatus: computed,
  maxPriority: computed
});

export default withRouter(observer(CarListTable));
