import React from 'react';
import { Table } from 'antd';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PropTypes } from 'mobx-react';
import styled from 'styled-components';

import { IssueStore, ShopStore, AppStore } from 'stores';
import styles from './RelevantServices.module.css';


const AnchorButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: #1e48c2;
`;

class RelevantServices extends React.Component{
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    loadingRelevantServices: false,
    relevantServiceRecords: null,
    serviceTableData: null,
    code: null
  }

  columns = [
    {
      title: 'Service Name',
      dataIndex: 'serviceTitle',
      key: 'serviceTitle',
    },
    {
      title: 'Unit ID',
      dataIndex: 'unitId',
      key: 'unitId',
      render: text => <AnchorButton>{text}</AnchorButton>,
      onCell: (record, rowIndex) => {
        return {
          onClick: (event) => {
            event.stopPropagation();
            this.props.history.push(`/car/${record.carId}`);
          }
        };
      }
    },
    {
      title: 'VMRS Sys. Code',
      dataIndex: 'vmrs',
      key: 'vmrs',
    },
    {
      title: 'Service Date',
      dataIndex: 'serviceDate',
      key: 'serviceDate',
      render: datetime => (new Date(datetime.slice(0,10))).toDateString().split(' ').slice(1).join(' ')
    },
  ];
  async componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    this.setState({code: code});
    this.loadRelevantServices();
  }

  getVisitEvents(entry){
    const visitEvents = _.get(entry, 'visitEvents');
      if (!visitEvents){ 
        return null; 
      }
      else {
        const formattedVisitEvents = [];
        visitEvents.map( visitEvent => {
          formattedVisitEvents.push({
            serviceTitle: visitEvent.action + ' - ' + visitEvent.item,
            unitId: visitEvent.unit_id,
            vmrs: visitEvent.item,//Splits to just display system code //parseInt(entry.item.split('-')[0]) ? parseInt(entry.item.split('-')[0]) : OTHER (900),
            serviceDate: visitEvent.created_at,
            carId: visitEvent.id_car,
            carServiceId: visitEvent.id,
            primaryItem: false
          });
          return null;
        });
        return formattedVisitEvents;
      }
  }

  loadRelevantServices = async () => {
    try {
      this.setState({ loadingRelevantServices: true });
      const queryParams = new URLSearchParams(window.location.search);
      const issueId = queryParams.get('issueId');
      const code = queryParams.get('code');
      const shopId = ShopStore.currentShop.id;
      const response = await IssueStore.getRelevantServices(issueId, code, shopId);
      if (!response) {
        return;
      }
      const relevantServiceRecords = response;
      this.setState({ relevantServiceRecords: relevantServiceRecords });
      if (relevantServiceRecords){
        const relevantEventsCleaned = relevantServiceRecords.filter(item => 'primaryEvent' in item);
        relevantEventsCleaned.sort((a,b) => {
          return new Date(b.primaryEvent.created_at) - new Date(a.primaryEvent.created_at);
        });
        let orderedFormattedRecords = relevantEventsCleaned.map(entry => {
          const primaryEvent = _.get(entry, 'primaryEvent');
          if (!primaryEvent) { return null; }
          const formattedRecords = [];
          formattedRecords.push({
            serviceTitle: '* ' + primaryEvent.action + ' - ' + primaryEvent.item,
            unitId: primaryEvent.unit_id,
            vmrs: primaryEvent.item,//Splits to just display system code //parseInt(entry.item.split('-')[0]) ? parseInt(entry.item.split('-')[0]) : OTHER (900),
            serviceDate: primaryEvent.created_at,
            carId: primaryEvent.id_car,
            carServiceId: primaryEvent.id,
            primaryItem: true,
            visitEvents: this.getVisitEvents(entry)

          });
          return formattedRecords;
      });
      orderedFormattedRecords = orderedFormattedRecords.filter(entry => entry !== null);
      this.setState({serviceTableData: orderedFormattedRecords.flat(1)});
      }
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      this.setState({ loadingRelevantServices: false });
    }
  };
  render(){
    return(
      <>
        <h1 style={{marginBottom: '80px'}}>Relevant Services for Code {this.state.code}</h1>
        <Table 
                rowClassName={(record, index) => _.get(record,'primaryItem') ? styles.primaryTableRow : 'visit-table-row'}
                onRow={(row) => {
                  return {
                    onClick: () => this.props.history.push(`/issues/${row.carServiceId}`),
                    style: {'cursor':'pointer'},
                  };
                }}
                dataSource={this.state.serviceTableData} 
                columns={this.columns}
                pagination={false}
                expandedRowRender={(item) => <Table dataSource={item.visitEvents} columns={this.columns} pagination={false} onRow={(row) => {
                    return {
                      onClick: () => this.props.history.push(`/issues/${row.carServiceId}`),
                      style:{'cursor': 'pointer'},
                    };
                  }}/>}
        />
      </>
    );
  }
}

export default withRouter(observer(RelevantServices));