import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input } from 'antd';

import { PitstopSelect } from './PitstopSelect';

export class PitstopFormInlinePartial extends Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onSubmit(values, this.props.form);
      // this.props.form.resetFields();
    });
  };

  render() {
    const { fields, form, submitText } = this.props;
    const { getFieldDecorator, getFieldsError } = form;

    return (
      <Form layout="inline" onSubmit={this.handleSubmit}>
        {fields.map(field => {
          const rules = [];
          if (field.type === 'email') {
            rules.push({ type: 'email', message: 'Invalid email' });
          }
          if (field.required) {
            rules.push({ required: true, message: 'Required' });
            if (field.type === 'text') {
              rules.push({ whitespace: true, message: 'Required' });
            }
          }
          return (
            <Form.Item key={field.name} label={field.label}>
              {getFieldDecorator(field.name, {
                rules
              })(
                field.type === 'select' ? (
                  <PitstopSelect options={field.selectOptions} />
                ) : (
                  <Input />
                )
              )}
            </Form.Item>
          );
        })}
        <Button
          disabled={hasErrors(getFieldsError())}
          type="primary"
          htmlType="submit"
        >
          {submitText || 'Submit'}
        </Button>
      </Form>
    );
  }
}

function hasErrors(fieldsError) {
  return Object.values(fieldsError).some(v => !!v);
}

export const PitstopFormInline = Form.create({
  onValuesChange(props, changedValues, allValues) {
    if (props.onChange) {
      props.onChange(allValues);
    }
  }
})(PitstopFormInlinePartial);

PitstopFormInlinePartial.propTypes = {
  form: PropTypes.object,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      required: PropTypes.bool,
      selectOptions: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      )
      // type: PropTypes.oneOf(['email', 'select', 'text']).isRequired
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string
};
