import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';

import { CarStore, ReportsDataStore, CurrentUserStore } from 'stores';

import { Empty } from 'antd';
import { DoughnutChart } from 'shared/PitstopUI/PitstopChart';

class SpeedingDistanceRatio extends Component {
  get data() {
    let totalSpeedingDistance = ReportsDataStore.totalSpeedingDistance;
    let totalDrivingDistance = ReportsDataStore.totalDrivingDistance;

    return {
      seriesName: 'distance ratio',
      units: CurrentUserStore.user.settings.odometer || 'km',
      emptyMessage: 'Your fleet has no Speeding alarms',
      value: [
        {
          name: 'Speeding Distance',
          color: '#f2706d',
          y: totalSpeedingDistance || 0,
        },
        {
          name: 'Driving Distance',
          color: '#20ce9d',
          y: totalSpeedingDistance === 0 ? 0 : totalDrivingDistance,
        },
      ],
    };
  }

  render() {
    if (CarStore.demo === false) return <Empty />;

    return (
      <DoughnutChart
        chart={{
          height: 250,
          margin: [0, 250, 0, 0],
          legend: {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'top',
            x: Number(`${window.innerWidth > 1600 ? -100 : 0}`),
            y: 90,
          },
        }}
        data={this.data}
        loaded={
          ReportsDataStore.alarmReport.loaded &&
          ReportsDataStore.drivingReport.loaded
        }
      />
    );
  }
}

decorate(SpeedingDistanceRatio, {
  data: computed,
});

export default observer(SpeedingDistanceRatio);
