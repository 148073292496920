import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MsgImg from 'assets/2AddAMessage.png';
import HTMLEditor from './components/HTMLEditor';
import { Input, Upload, Icon } from 'antd';
import AppStore from 'stores/App';
import { webServiceProvider } from 'shared';

const Dragger = Upload.Dragger;
const { TextArea } = Input;

const MessageWrapper = styled.div`
  display: flex;

  && > div.img {
    flex: 1;
  }
  && > div.img > img {
    width: 100%;
  }
  && > div {
    flex: 2;
    height: 100%;
    position: relative;
  }

  && > div.chat-section {
    display: flex;
  }
  && > div.chat-section > textarea {
    flex: 2;
    margin-right: 10px;
  }
  && > div.chat-section > * {
    flex: 1;
    margin-bottom: 40px;
  }
`;

class CampaignMessageSection extends React.Component {
  static propTypes = {
    contactMethod: PropTypes.string,
    messageContent: PropTypes.string,
    fileList: PropTypes.array,
    setValue: PropTypes.func.isRequired
  };

  onDrop = async () => {
    const fd = new FormData();
    fd.append('singleInputFileName', this.props.fileList[0]);
    try {
      this.props.fileList[0].status = 'uploading';
      this.props.setValue('fileList', [this.props.fileList[0]]);
      const data = await webServiceProvider.postFormData(
        'dashboard/v1/uploadimage',
        fd
      );
      this.props.fileList[0].status = 'done';
      this.setState('fileList', [this.props.fileList[0]]);
      AppStore.addSuccess(
        `${this.props.fileList[0].name} file uploaded successfully.`
      );
      this.props.setValue('uploadedFileName', this.props.fileList[0].name);
      this.props.setValue('mediaUrl', data.fileUrl);
      this.props.setValue('mediaType', data.mediaType);
    } catch (e) {
      this.props.fileList[0].status = 'error';
      this.props.setValue('fileList', [this.props.fileList[0]]);
      AppStore.addError(
        `Uploading ${this.props.fileList[0].name} file upload failed.`
      );
    }
  };

  beforeFileUpload = file => {
    this.props.setValue('fileList', [file], () => {
      this.onDrop();
    });

    return false;
  };

  removeFile = () => {
    this.props.setValue('fileList', []);
  };

  render() {
    return (
      <React.Fragment>
        <h1>What would you like to send?</h1>
        <MessageWrapper>
          <div className="img">
            <img alt="Add a message to your campaign." src={MsgImg} />
          </div>

          {this.props.contactMethod === 'chat' ? (
            <div className="chat-section">
              <TextArea
                rows={4}
                rowsmax={9}
                placeholder="Add the message of your campaign here. We recommend a 50 words text and a clear and direct message for your users."
                id="messageContent"
                value={this.props.messageContent}
                onChange={ev =>
                  this.props.setValue('messageContent', ev.target.value)
                }
              />
              <Dragger
                name="file"
                multiple={false}
                onCancel={this.removeFile}
                beforeUpload={this.beforeFileUpload}
                onRemove={this.removeFile}
                fileList={this.props.fileList}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
              ,
            </div>
          ) : (
            <HTMLEditor
              messageContent={this.props.messageContent}
              setValue={this.props.setValue}
            />
          )}
        </MessageWrapper>
      </React.Fragment>
    );
  }
}

export default observer(CampaignMessageSection);
