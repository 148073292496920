import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Form, DatePicker, Button, Card, Col, Row, Spin, notification } from 'antd';
import styled from 'styled-components';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DealershipChooser } from 'components';
import ShopStore from 'stores/ShopStore';
import moment from 'moment';

const { RangePicker } = DatePicker;

const H2Styled = styled.h2`
  font-size: 1.2rem;
`;

class AssetAvailability extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  state = {
    isLoadingAvailability: false,
    availabilityData: [],
    startDate: moment().subtract(3, 'months'), // Default to 3 months ago
    endDate: moment(), // Default to today
  };

  componentDidMount() {
    this.fetchAvailabilityData();
  }

  fetchAvailabilityData = async () => {
    const { startDate, endDate } = this.state;
    const shopId = ShopStore.currentShop.id;

    if (!shopId || !startDate || !endDate) {
      notification.error({
        message: 'Missing Date Error!',
        description: 'Please select start date and end date to apply filters.',
      });
      return;
    }

    this.setState({ isLoadingAvailability: true });

    try {
      // Call API to get asset availability data
      const response = await ShopStore.getAssetAvailabilityData(
        shopId,
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD')
      );
      
      // Transform response data for chart consumption
      const availabilityData = response.data.map(item => ({
        timeframe: item.timeframe,
        upPercentage: parseFloat(item.uppercentage),
        downPercentage: parseFloat(item.downpercentage),
      }));

      this.setState({ availabilityData });
    } catch (error) {
      console.error("Failed to load asset availability data:", error);
    } finally {
      this.setState({ isLoadingAvailability: false });
    }
  };

  handleDateRangeChange = (dates) => {
    if (dates) {
      const [startDate, endDate] = dates;
      this.setState({ startDate, endDate });
    } else {
      this.setState({ startDate: null, endDate: null });
    }
  };

  handleApplyFilters = () => {
    this.fetchAvailabilityData();
  };

  render() {
    const { availabilityData, isLoadingAvailability, startDate, endDate } = this.state;

    const availabilityChartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: availabilityData.map(item => item.timeframe),
        title: {
          text: 'Timeframe',
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: '% of Up vs Down',
        },
        labels: {
          format: '{value}%',
        },
      },
      plotOptions: {
        column: {
          stacking: 'percent',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `${this.y}%`;
            },
          },
        },
      },
      series: [
        {
          name: 'Up',
          data: availabilityData.map(item => item.upPercentage),
          color: '#183cbc',
        },
        {
          name: 'Down',
          data: availabilityData.map(item => item.downPercentage),
          color: '#e63946',
        },
      ],
    };

    return (
      <Fragment>
        <Row gutter={[8, 8]} justify="space-between" align="middle">
          <Col span={20}>
            <h1>Asset Availability</h1>
          </Col>
        </Row>
        <DealershipChooser />
        <Form layout="inline" style={{ marginTop: '10px' }}>
          <Form.Item label="Select Date Range">
            <RangePicker
              value={[startDate, endDate]}
              onChange={this.handleDateRangeChange}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={this.handleApplyFilters}>Apply Filters</Button>
          </Form.Item>
        </Form>
        <Row style={{ marginTop: '10px' }} gutter={[8, 8]}>
          <Col span={24}>
            <Spin spinning={isLoadingAvailability}>
              <Card>
                <Row gutter={[8, 8]}>
                  <Col style={{ marginTop: '5px' }} span={4}>
                    <H2Styled>Asset Availability</H2Styled>
                  </Col>
                  <Col span={24}>
                    {availabilityData.length > 0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={availabilityChartOptions}
                      />
                    ) : (
                      <p>No data available for the selected filters.</p>
                    )}
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default withRouter(observer(AssetAvailability));
