import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Popover, Icon } from 'antd';
import AlarmInfo from './AlarmInfo';

export default class AlarmMarker extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { infoVisible: false, hover: false };
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  handleClick = () => {
    this.setState({ infoVisible: !this.state.infoVisible });
  };

  render() {
    const markerStyle = this.state.hover
      ? {
          position: 'absolute',
          width: '26px',
          height: '26px',
          left: '-13px',
          top: '-13px',
          zIndex: 4
        }
      : {
          position: 'absolute',
          width: '12px',
          height: '12px',
          left: '-6px',
          top: '-6px',
          zIndex: 4
        };
    return (
      <React.Fragment>
        <Popover
          style={{
            height: '50px',
            width: '130px',
            zIndex: 5,
            top: '-50px',
            left: '-65px',
            position: 'relative'
          }}
          visible={this.state.infoVisible}
          trigger="click"
          title={
            <AlarmInfo
              alarm={this.props.name}
              render={
                <span>
                  {`Alarm: ${this.props.name}`}
                  <Icon
                    type="close"
                    onClick={() => this.handleClick()}
                    style={{ float: 'right' }}
                  />
                </span>
              }
            />
          }
          content={`triggered at: ${this.props.triggeredAt}`}
        />

        <img
          key={this.props.triggeredAt}
          style={markerStyle}
          lat={this.props.lat}
          lng={this.props.lng}
          height="12px"
          width="12px"
          alt="marker"
          onClick={() => this.handleClick()}
          onMouseEnter={() => this.toggleHover()}
          onMouseLeave={() => this.toggleHover()}
          src={require('./Icons/alarmMarker.png')}
        />
      </React.Fragment>
    );
  }
}

AlarmMarker.propTypes = {
  triggeredAt: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  name: PropTypes.string
};
