import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Table } from 'antd';

import { ReportsDataStore } from 'stores';
import { TableStore } from 'stores/abstract';
import { WidgetToolTip } from 'shared/PitstopUI/PitstopChart';


class FleetWideCosts extends Component {

  tableStore = new TableStore();
  //Name of months, used for sorting by month
  monthNames = {
  'January': 1,
  'February': 2,
  'March': 3,
  'April': 4,
  'May': 5,
  'June': 6,
  'July': 7,
  'August': 8,
  'September': 9,
  'October': 10,
  'November': 11,
  'December': 12
};
  
  get data() {
    let data = ReportsDataStore.vehicleCostStatsReport.data;
    return data;
  }

  render(){
    const cost_stats = _.get(this.data, 'cost_stats', {});
    //Calculating date range
    const years = Object.keys(cost_stats);
    let dateRange = 'N/A';
    if (!_.isEmpty(cost_stats)){
      years.sort();
      years.reverse();
      const firstYear = years[0];
      const finalYear = years.at(-1);
      //First set of months
      const firstSetMonths = Object.keys(cost_stats[firstYear]);
      firstSetMonths.sort((a,b) => {
        return this.monthNames[a] - this.monthNames[b];
      });
      const firstMonth = firstSetMonths[0];

      const finalSetMonths = Object.keys(cost_stats[finalYear]);
      finalSetMonths.sort((a,b) => {
        return this.monthNames[a] - this.monthNames[b];
      });
      finalSetMonths.reverse();
      const finalMonth = finalSetMonths[0];
      dateRange = firstMonth+', '+firstYear + ' - '+finalMonth+', '+finalYear;
    } 
    let totalCost = 0;
    let totalVisits = 0;
    _.forOwn(cost_stats['2022'], (value, key) => {
      totalCost += value['Total Cost'];
      totalVisits += value['Number of Visits'];
    });
    let costPerVisit = Math.round((totalCost/totalVisits) * 100)/100;
    const data = [{
      totalCost: '$ '+(Math.round(totalCost*100)/100).toLocaleString(),
      totalVisits: totalVisits,
      costPerVisit: '$ '+(Math.round(costPerVisit*100)/100).toLocaleString()
    }];
    const columns = [
    {
      title: 'Date Range',
      key: 'Date range',
      render: (record) => <strong>{dateRange}</strong>
    },
    {
      title: 'Total Maintenance Cost ($)',
      key: 'totalCost',
      render: (record) => <strong>{record.totalCost}</strong>
    },
    {
      title: 'Total Shop Visits',
      key: 'totalVisits',
      render: (record) => <strong>{record.totalVisits}</strong>
    },
    {
      title: 'Cost Per Visit ($)',
      key: 'costPerVisit',
      render: (record) => <strong>{record.costPerVisit}</strong>
    }
    ];
    return(
    <>
    <WidgetToolTip title='Total fleet-wide maintenance costs from service records'/>
    {!_.isEmpty(cost_stats) ? 
    <>
    <Table
          columns={columns}
          dataSource={data}
        /> 
    </>
    : <p>Please upload service data to gain access to these analytics</p>}
    </>
  );
  }
}


export default withRouter(observer(FleetWideCosts));