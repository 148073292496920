import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import styled from 'styled-components';
import _ from 'lodash';

import { Card, Col, Empty, Icon, Row, Spin, Statistic, Tooltip } from 'antd';

import { ShopStore } from 'stores';

const Container = styled.div`
  margin: 10px 0px;

  h5 {
    margin: 10px 0px;
  }
`;

class ConnectivityStatus extends Component {
  disposer = observe(ShopStore.currentShop, 'id', async () => {
    if (ShopStore.currentShop.id !== -1) {
      await ShopStore.getConnectivityStatus();
    }
  });

  async componentDidMount () {
    if (ShopStore.currentShop.id !== -1) {
      await ShopStore.getConnectivityStatus();
    }
  }

  renderView = () => {
    if (ShopStore.connectivityStatus.pending) return <Spin />;

    let data = ShopStore.connectivityStatus.data;

    if (_.isEmpty(data)) return <Empty />;

    return (
      <>
        <Row>
          <Col span={4}>
            <Card>
              <Statistic
                title="Active"
                value={data[0]?.['Active']}
                valueStyle={{ color: '#3f8600' }}
              />
            </Card>
          </Col>

          <Col span={4} style={{ margin: '0px 10px' }}>
            <Card>
              <Statistic
                title={(() => {
                  return (
                    <>
                      Others{' '}
                      <Tooltip title="Inactive, Disconnected, No Device">
                        <Icon type="info-circle" />
                      </Tooltip>
                    </>
                  );
                })()}
                value={_.sum([
                  data[0]?.['Disconnected'],
                  data[0]?.['Inactive'],
                  data[0]?.['No Device'],
                ])}
              />
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  render () {
    if (ShopStore.currentShop.id === -1) return <></>;

    return (
      <Container>
        <h5>Vehicles Connectivity Status</h5>

        {this.renderView()}
      </Container>
    );
  }
}

export default observer(ConnectivityStatus);
