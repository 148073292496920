import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Select } from 'antd';

const Option = Select.Option;

class Filter extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    defaultKeys: PropTypes.array,
    filterValues: PropTypes.array
  };

  state = {
    keys: []
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.filterValues !== prevProps.filterValues) {
      this.setState({
        keys: this.props.filterValues
      });
    }
  }

  onChange = keys => {
    this.setState(
      {
        keys
      },
      () => this.props.onChange(keys)
    );
  };

  dropdownView = () => {
    let { placeholder, defaultKeys } = this.props;

    return (
      <Select
        mode="multiple"
        showArrow
        fullWidth
        value={this.state.keys}
        style={{ width: '100%' }}
        placeholder={placeholder}
        onChange={this.onChange}
      >
        {defaultKeys.map(key => (
          <Option key={key}>{key}</Option>
        ))}
      </Select>
    );
  };

  render() {
    return <> {this.dropdownView()} </>;
  }
}

export default Filter;
