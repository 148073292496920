import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, PropTypes } from 'mobx-react';
import { Table, Spin } from 'antd';
import _ from 'lodash';

import { ReportsDataStore } from 'stores';


class VehiclesWithDEFIssues extends React.Component {
  static propTypes = {
    history: PropTypes.function
  }
  constructor(props) {
    super(props);
    this.state = { searchQuery: 'ABS' };
  }

  onChangeSearchQuery = event => {
    this.setState({ searchQuery: event.target.value });
  }

  onSearch = () => {
    ReportsDataStore.getVehiclesWithDEFIssues('DEF');
  }

  get data() {
    const tableData = [];
    ReportsDataStore.vehiclesWithDEFIssues.data.map(entry => {
      if (_.isEmpty(tableData.find(dataItem => dataItem.vin === entry.vin))) {
        tableData.push({
          key: entry.vin + entry.code,
          vin: entry.vin,
          unitId: entry.car_name,
          codes: [{ key: entry.code, code: entry.code, description: entry.description, id: entry.id }]
        });
      } else {
        tableData.find(dataItem => dataItem.vin === entry.vin).codes.push({ key: entry.code, code: entry.code, description: entry.description, id: entry.id });
      }
      return null;
    });
    return tableData;
  }

  expandedColumns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    }
  ];

  columns = [
    {
      title: 'Unit ID',
      dataIndex: 'unitId',
      key: 'unitId'
    },
    {
      title: 'VIN',
      dataIndex: 'vin',
      key: 'vin'
    }
  ];

  render() {
    return (
      <>
        {/*<div>
          <p>Search for issues</p>
            <input type="text" 
            value={this.state.searchQuery}
            onChange={this.onChangeSearchQuery}/>
            <Button onClick={this.onSearch}>Search</Button>
        </div>*/}
        {_.isEmpty(this.data) ? <Spin tip='loading' /> :
          <Table dataSource={this.data} columns={this.columns} onRow={(record, rowIndex) => {
            return {
              onClick: event => null
            };
          }}
            expandedRowRender={car => {
              return <Table dataSource={car.codes} columns={this.expandedColumns} onRow={(record, rowIndex) => {
                return {
                  style: { cursor: 'pointer' },
                  onClick: event => this.props.history.push('/issues/' + record.id)
                };
              }}
              />;
            }} />}
      </>
    );
  }
}

export default withRouter(observer(VehiclesWithDEFIssues));