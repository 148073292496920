import styled from 'styled-components';

export default styled.div`
  width: 45px;
  height: 5px;

  margin-right: 10px;
  display: inline-block;
  cursor: pointer;

  background: ${(props) => (props.isActive ? '#4F75B9' : '#ffffff')};
`;
