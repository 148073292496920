import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import _ from 'lodash';
import styled from 'styled-components';

import { PitstopTable } from 'shared';
import { CarStore, ShopStore, TableStore } from 'stores';

import {
  renderIndicator,
  renderVehicleId,
  renderIssues,
  renderScanner,
  renderDriver,
} from './CarListTableColumns';
import CarListFilter from './CarListFilter';

const IndicatorTableWrapper = styled.div`
  && table th.indicator,
  && table td.indicator {
    position: relative;
    padding: 0;
    width: 8px;
  }
`;

class SelectableCarListTable extends Component {
  static propTypes = {
    setValue: PropTypes.func.isRequired,
    selectedCars: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    userId: PropTypes.number,
  };

  tableStore = new TableStore();

  columns = [
    {
      key: 'indicator',
      className: 'indicator',
      title: null,
      render: renderIndicator,
    },
    {
      key: 'vehicleId',
      title: 'Vehicle ID',
      render: renderVehicleId,
    },
    {
      key: 'issues',
      title: 'Issues',
      render: renderIssues,
    },
    {
      field: 'driver',
      filter: false,
      title: 'Current Driver',
      render: renderDriver(this, false),
      rowClick: false,
    },
    {
      field: 'scanner',
      dataIndex: 'scanner',
      editable: false,
      filter: false,
      title: 'Device Connection',
      render: renderScanner,
    },
  ];

  get data() {
    let data = _.map(this.tableStore.data, (id) => CarStore.data.get(id));

    return _.filter(data, Boolean);
  }

  render() {
    const shopId = ShopStore.currentShop.id;
    return (
      <IndicatorTableWrapper>
        <CarListFilter tableStore={this.tableStore} />
        <PitstopTable
          height={300}
          id={this.props.id}
          columns={this.columns}
          data={this.data}
          fetchData={(filters, table) => {
            CarStore.getShopCarsForTable(filters, table, shopId);
          }}
          rowKey="id"
          getTableStore={(tableStore) => (this.tableStore = tableStore)}
          selection={{
            onChange: (selectedRowKeys) =>
              this.props.setValue('selectedCars', selectedRowKeys),
            selectedRowKeys: this.props.selectedCars,
          }}
        />
      </IndicatorTableWrapper>
    );
  }
}

decorate(SelectableCarListTable, {
  tableStore: observable,
  data: computed,
});

export default observer(SelectableCarListTable);
