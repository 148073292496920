import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { Col, Row, Tag, Checkbox, Button } from 'antd';

import { PitstopModal } from 'shared';
import UsersTable from '../UsersTable';
import { AppStore, CurrentUserStore, ShopStore } from 'stores';
import { observer } from 'mobx-react';
import SuccessModal from './SuccessModal';
import { reaction, toJS } from 'mobx';
import PropTypes from 'prop-types';

const StyledModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      .ant-modal-title {
        color: #ffffff;
      }
    }
  }
`;

class VehicleIssueAlertModal extends Component {
  EDIT_ISSUE_ALLERT_MODAL_ID = 'EDIT_ISSUE_ALLERT_MODAL_ID';
  ADD_ISSUE_ALLERT_MODAL_ID = 'ADD_ISSUE_ALLERT_MODAL_ID';
  ISSUE_ALLERT_SUCCESS_MODAL_ID = 'ISSUE_ALLERT_SUCCESS_MODAL_ID';

  state = {
    priorityCheckedList: [],
    selectedUserIds: [],
    saving: false,
  };

  async componentDidMount() {
    reaction(
      () => ShopStore.shopNotifications.get(ShopStore.currentShop.id),
      (notification) => {
        this.updateSelectedPriorities();
      }
    );
  }

  updateSelectedPriorities() {
    this.setState({ priorityCheckedList: [] });
    const shopNotifications = toJS(
      ShopStore.shopNotifications.get(ShopStore.currentShop.id)
    );
    const notifications = _.chain(shopNotifications)
      .get('data.emailConfiguration')
      .filter((a) => a.email_report_type === 'PriorityAlerts')
      .value();
    if (_.isNil(notifications) || _.isEmpty(notifications)) return;
    // for each notifications, get all selected subscriptions
    const priorityCheckedList = [];
    for (const notification of notifications) {
      const subscribeTo = _.get(notification, 'configuration.subscribeTo');
      if (_.isNil(subscribeTo)) return;
      if (subscribeTo.includes(0) || subscribeTo.includes(1)) {
        priorityCheckedList.push('minor');
      }
      if (subscribeTo.includes(2) || subscribeTo.includes(3)) {
        priorityCheckedList.push('major');
      }
      if (subscribeTo.includes(4) || subscribeTo.includes(5)) {
        priorityCheckedList.push('critical');
      }
    }
    this.setState({ priorityCheckedList: _.uniq(priorityCheckedList) });
  }

  static get propTypes() {
    return {
      selectedEmails: PropTypes.array,
      title: PropTypes.string,
      modalId: PropTypes.string,
    };
  }

  onChangeUserIdsSelected = (selectedUserIds) => {
    this.setState({ selectedUserIds });
  };

  onChange = (val) => {
    let { priorityCheckedList } = this.state;

    if (_.indexOf(priorityCheckedList, val) !== -1) {
      _.remove(priorityCheckedList, (item) => item === val);
    } else {
      priorityCheckedList.push(val);
    }

    this.setState({
      priorityCheckedList,
    });
  };

  onUpdatePriorityAlerts = async () => {
    let { selectedUserIds: selectedUsers } = this.state;

    // if (_.isEmpty(priorityCheckedList)) {
    //   AppStore.addError('Please select priority alerts');
    //   return;
    // }

    try {
      this.setState({ saving: true });
      AppStore.addLoading('Saving');
      let timezone = CurrentUserStore.user.settings.timezone;
      if (_.isNil(timezone)) {
        timezone = 'America/Toronto';
      }

      const shopNotifications = toJS(
        ShopStore.shopNotifications.get(ShopStore.currentShop.id)
      );
      const priorityAlertsNotifications = _.chain(shopNotifications)
        .get('data.emailConfiguration')
        .filter((a) => a.email_report_type === 'PriorityAlerts')
        .value();

      // for each user, check if they have a priority alert config and update or create
      for (const user of selectedUsers) {
        const userNotifications = priorityAlertsNotifications.filter(
          (a) => a.id_user === user.id
        );
        if (_.isEmpty(userNotifications)) {
          // Check if the user is saved using emails
          await ShopStore.checkAndRemoveEmailBasedNotificationsForUser(
            priorityAlertsNotifications,
            user
          );
          await ShopStore.sendEmailPriorityAlertsConfig(
            ShopStore.currentShop.id,
            [user],
            moment(),
            timezone,
            this.generatePriorityAlerts()
          );
        } else {
          await ShopStore.updateEmailPriorityAlertsConfig(
            userNotifications[0].id,
            ShopStore.currentShop.id,
            [user],
            this.generatePriorityAlerts()
          );
        }
      }

      // remove the alerts from the user that are not selected
      for (const notification of priorityAlertsNotifications) {
        if (!selectedUsers.find((a) => a.id === notification.id_user)) {
          // PATCH
          await ShopStore.updateEmailPriorityAlertsConfig(
            notification.id,
            ShopStore.currentShop.id,
            [notification.id_user],
            []
          );
        }
      }

      AppStore.openModals.set(this.EDIT_ISSUE_ALLERT_MODAL_ID, false);
      AppStore.openModals.set(this.ADD_ISSUE_ALLERT_MODAL_ID, false);

      AppStore.openModals.set(this.ISSUE_ALLERT_SUCCESS_MODAL_ID, true);

      await ShopStore.getShopNotifications(ShopStore.currentShop.id, true);
    } catch (err) {
      AppStore.addError(`Error in updating priority alerts: ${err.message}`);
    } finally {
      this.setState({ saving: false });
      AppStore.removeLoading('Saving');
    }
  };

  generatePriorityAlerts = () => {
    let { priorityCheckedList } = this.state;
    return ShopStore.generatePriorityNumbersFromPriorityList(
      priorityCheckedList
    );
  };

  onConfirmSuccessModal = async () => {
    await ShopStore.getShopNotifications(ShopStore.currentShop.id, true);
  };

  render() {
    let { priorityCheckedList, saving } = this.state;
    let { title, modalId } = this.props;

    return (
      <>
        <SuccessModal
          modalId={this.ISSUE_ALLERT_SUCCESS_MODAL_ID}
          onConfirm={this.onConfirmSuccessModal}
        >
          <Row gutter={[16, 16]} align="middle" style={{ textAlign: 'center' }}>
            <Col span={24}>
              <h5>Vehicle Issue Alerts are saved.</h5>
            </Col>
          </Row>
        </SuccessModal>

        <StyledModal
          id={modalId}
          title={title}
          width={850}
          okText="Confirm"
          footer={
            <>
              <Button
                key="cancel"
                onClick={() => {
                  AppStore.openModals.set(
                    this.ADD_ISSUE_ALLERT_MODAL_ID,
                    false
                  );
                  AppStore.openModals.set(
                    this.EDIT_ISSUE_ALLERT_MODAL_ID,
                    false
                  );
                }}
              >
                Cancel
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={this.onUpdatePriorityAlerts}
                loading={saving}
              >
                Confirm
              </Button>
            </>
          }
        >
          <p style={this.textStyle}>
            An alert email will be sent automatically whenever an issue with the
            selected prirority occurs for the user’s assigned vehicle.
          </p>

          <Row>
            <Col span={12}>
              <p style={{ fontWeight: 700 }}>
                Select which priorities to subscribe:
              </p>

              <div>
                <Checkbox
                  checked={_.indexOf(priorityCheckedList, 'minor') !== -1}
                  onChange={() => this.onChange('minor')}
                >
                  <Tag color="#dec431">Minor</Tag>
                </Checkbox>

                <Checkbox
                  checked={_.indexOf(priorityCheckedList, 'major') !== -1}
                  onChange={() => this.onChange('major')}
                >
                  <Tag color="#e28931">Major</Tag>
                </Checkbox>

                <Checkbox
                  checked={_.indexOf(priorityCheckedList, 'critical') !== -1}
                  onChange={() => this.onChange('critical')}
                >
                  <Tag color="#f92d2e">Critical</Tag>
                </Checkbox>
              </div>
            </Col>
          </Row>

          {!AppStore.openModals.get(this.EDIT_ISSUE_ALLERT_MODAL_ID) && (
            <UsersTable
              isUserSelectionEnabled={true}
              sendEmailConfiguration={() => {}}
              sendPriorityAlertsEmailConfiguration={() => {}}
              getCurrentSelectedPriorities={() => {}}
              isDateSelectionEnabled={false}
              isPrioritySelectionEnabled={false}
              enableUserSelection={() => {}}
              isVehicleSelectionEnabled={false}
              sendFleetSegmentConfiguration={() => {}}
              columnKeys={[
                'firstName',
                'lastName',
                'email',
                'vehiclePriorityAlerts',
              ]}
              isEditable={false}
              showSetupButton={false}
              onChangeUserIdsSelected={this.onChangeUserIdsSelected}
            />
          )}
        </StyledModal>
      </>
    );
  }
}

export default observer(VehicleIssueAlertModal);
