import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Col, Row, Typography, Select, InputNumber, Icon, Button } from 'antd';
import TotalAmount from 'components/TotalAmount';

const { Text } = Typography;
const { Option } = Select;

const NoFoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

class WorkOrderLineItemPartsItemDesktop extends Component {
  static propTypes = {
    childRef: PropTypes.func,
    item: PropTypes.object,
    onUpdateTotal: PropTypes.func,
    onRemoveItem: PropTypes.func,
    onAddNewPart: PropTypes.func.isRequired,
    shopParts: PropTypes.array,
    onUpdateLocalItem: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool
  };

  state = {
    currentSearchPart: ''
  }

  partValue = ''

  localItem = {
    id_vehicle_part: '',
    cost: 0,
    markup: 0,
    amount: 0,
    vehiclePart: {
      name: '',
      vmrs_component_code: '',
      source: ''
    }
  }

  get localShopParts () {
    return this.props.shopParts || [];
  }

  get partsOptions () {
    return this.localShopParts.map((part, index) => {
      let displayText = `${part.number} ${part.description}`.trim() || part.name;
      if (displayText === 'null null') {
        displayText = part.name;
      }
      return (
        <Option
          key={index}
          value={part.id}
        >
          {displayText}
        </Option>
      );
    });
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.localItem = this.props.item;
  }

  get totalAmount () {
    const cost = parseFloat(this.localItem.cost) || 0;
    const markup = parseFloat(this.localItem.markup) || 0;
    const amount = parseFloat(this.localItem.amount) || 0;

    return (cost + (cost * markup / 100)) * amount;
  }

  onChangeAmount = (newVal, fieldName) => {
    this.localItem[fieldName] = newVal;

    if (this.props.onUpdateLocalItem) {
      this.props.onUpdateLocalItem(this.localItem, fieldName);
    }

    if (this.props.onUpdateTotal) {
      this.props.onUpdateTotal(this.totalAmount);
    }
  }

  onSearchPart = (value) => {
    this.setState({ currentSearchPart: value });
  }

  onClickAddPart = () => {
    if (this.props.onAddNewPart) {
      this.props.onAddNewPart(this.state.currentSearchPart || '');
    }
  }

  onSelectPart = (value, fieldName) => {
    this.localItem[fieldName] = value;

    if (this.props.onUpdateLocalItem) {
      this.props.onUpdateLocalItem(this.localItem, fieldName);
    }
  }

  onRemoveItem = (event, itemKey) => {
    if (this.props.onRemoveItem) {
      this.props.onRemoveItem(event, itemKey);
    }
    if (this.props.onUpdateTotal) {
      this.props.onUpdateTotal(this.totalAmount);
    }
  }

  render () {
    const filterOption = (input, option) => {
      return option.props.children
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0;
    };

    const noPartFound = this.state.currentSearchPart
      ? (
        <NoFoundContainer>
          <Button
            type="link"
            icon="plus"
            onClick={this.onClickAddPart}
          >
            Add {this.state.currentSearchPart}
          </Button>
        </NoFoundContainer>
      ) : (
        <NoFoundContainer>
          <Icon style={{ fontSize: '24px' }} type="inbox" />
          <Text level={4}>No Data</Text>
        </NoFoundContainer>
      );

    this.partValue = this.localItem?.vehiclePart?.source === 'TMT' 
      ? this.localItem.vehiclePart.name + ': ' + this.localItem.vehiclePart.vmrs_component_code
      : this.localItem.id_vehicle_part;
  
    return (
      <Row style={{ margin: 0 }} gutter={[8, 8]}>
        <Col span={9}>
          <Text strong>Part</Text>
          <Select
            value={this.partValue}
            placeholder="Select a Part"
            onChange={value => this.onSelectPart(value, 'id_vehicle_part')}
            onSearch={value => this.onSearchPart(value)}
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            notFoundContent={noPartFound}
            disabled={this.props.isDisabled}
          >
            {this.partsOptions}
          </Select>
        </Col>
        <Col span={4}>
          <Text strong>Quantity:</Text>
          <InputNumber
            className='part-quantity-input'
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            value={this.localItem.amount}
            onChange={val => this.onChangeAmount(val, 'amount')}
            disabled={this.props.isDisabled}
          />
        </Col>
        <Col span={4}>
          <Text strong>Unit Cost:</Text>
          <InputNumber
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            value={this.localItem.cost}
            onChange={val => this.onChangeAmount(val, 'cost')}
            disabled={this.props.isDisabled}
          />
        </Col>
        <Col span={2}>
          <Text strong>Markup:</Text>
          <InputNumber
            value={this.localItem.markup}
            onChange={val => this.onChangeAmount(val, 'markup')}
            style={{ width: '100%' }}
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
            disabled={this.props.isDisabled}
          />
        </Col>
        <Col span={4}>
          <Text strong>Amount:</Text>
          {/* <InputNumber
            value={this.totalAmount}
            disabled={true}
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => parseFloat(value.replace(/\$\s?|(,*)/g, '')).toFixed(2)}
            style={{ width: '100%' }}
          /> */}
          <TotalAmount amount={this.totalAmount} />
        </Col>
        <Col
          key={'deletePartBtn'}
          span={1}
          style={{
            display: 'flex',
            height: '57px',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '25px'
          }}
        >
          <Icon
            type="delete"
            onClick={event => {
              event.stopPropagation();
              this.onRemoveItem(event, this.props.item.key);
            }}
          />
        </Col>
      </Row>
    );
  }
}

decorate(WorkOrderLineItemPartsItemDesktop, {
  localItem: observable,
  totalAmount: computed,
  localShopParts: computed
});

export default observer(WorkOrderLineItemPartsItemDesktop);
