const downtimeHoursDefault = [
  16,
  24,
  17,
  5,
  15,
  11,
  45,
  23,
  44,
  14,
  7,
  10,
  37,
  26,
  19,
  4,
  21,
  28,
  26,
  37,
  1,
  18,
  12,
  31,
  16,
  25,
  32,
  4,
  1,
  2,
  3,
  1,
  8,
  20,
  16,
  28,
  33,
  23,
  29,
  29,
  18,
  11,
  17,
  20,
  9
];

const downtimeHoursSavedDefault = [
  5,
  7,
  12,
  1,
  10,
  4,
  21,
  6,
  15,
  11,
  5,
  5,
  16,
  10,
  6,
  4,
  11,
  13,
  13,
  18,
  1,
  6,
  9,
  10,
  5,
  6,
  17,
  2,
  1,
  2,
  3,
  1,
  4,
  11,
  9,
  16,
  25,
  20,
  16,
  21,
  14,
  4,
  9,
  17,
  4
];

const downtimeMonths = [
  'Jan-18',
  'Feb-18',
  'Mar-18',
  'Apr-18',
  'May-18',
  'Jun-18',
  'Jul-18',
  'Aug-18',
  'Sep-18',
  'Oct-18',
  'Nov-18',
  'Dec-18',
  'Jan-19',
  'Feb-19',
  'Mar-19',
  'Apr-19',
  'May-19',
  'Jun-19',
  'Jul-19',
  'Aug-19',
  'Sep-19',
  'Oct-19',
  'Nov-19',
  'Dec-19',
  'Jan-20',
  'Feb-20',
  'Mar-20',
  'Apr-20',
  'May-20',
  'Jun-20',
  'Jul-20',
  'Aug-20',
  'Sep-20',
  'Oct-20',
  'Nov-20',
  'Dec-20',
  'Jan-21',
  'Feb-21',
  'Mar-21',
  'Apr-21',
  'May-21',
  'Jun-21',
  'Jul-21',
  'Aug-21'
];

export default {
  downtimeHoursDefault,
  downtimeHoursSavedDefault,
  downtimeMonths
};
