import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pdfjs } from 'react-pdf';

import SinglePage from './single-page';
import AllPages from './all-pages';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Container = styled.div`
  .pdf {
    font-family: sans-serif;
    text-align: center;
  }

  h4 {
    text-align: left;
    margin-left: 40px;
  }

  hr {
    border-top: 2px dotted #3f51b5;
  }

  button {
    margin-right: 10px;
    background: #3f51b5;
    color: white;
    border: none;
    padding: 5px 10px;
    width: 100px;
    cursor: pointer;
    box-shadow: 2px 2px 2px 1px #ccc;
  }

  .react-pdf__Page {
    margin-bottom: 10px;
  }
  .react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 2px 2px 5px 1px #ccc;
    border-radius: 5px;
    height: 700px !important;
    max-width: 100%;
  }

  .react-pdf__Page__annotations.annotationLayer {
    position: fixed;
    padding: 20px;
  }

  .react-pdf__Page__canvas {
    height: 700px !important;
  }

  .all-page-container {
    height: 100%;
    max-width: 100%;
    max-height: ${props => props.maxHeight || '500px'};
    overflow: auto;
  }

  canvas {
    max-width: 100%;
  }
`;

export default function PDF(props) {
  let { type, file } = props;

  return (
    <Container maxHeight={props.maxHeight}>
      <div className="pdf">
        {type === 'single' ? (
          <SinglePage pdf={file} />
        ) : (
          <div className="all-page-container">
            <AllPages width="400" pdf={file} />
          </div>
        )}
      </div>
    </Container>
  );
}

PDF.propTypes = {
  type: PropTypes.string,
  file: PropTypes.any,
  maxHeight: PropTypes.string
};
