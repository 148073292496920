import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import _ from 'lodash';

import { Row, Col } from 'antd';

import { DoughnutChart } from 'shared/PitstopUI/PitstopChart';
import { calcMileage } from 'helpers/unitCalculations';

import ReportsDataStore from 'stores/ReportsDataStore';
import CurrentUserStore from 'stores/CurrentUserStore';
import CarStore from 'stores/CarStore';
import { withRouter } from 'react-router-dom';

class DriverSummary extends Component {
  disposer = observe(CarStore, 'demo', async () => {
    if (CarStore.demo) {
      await this.load();
    }
  });

  async componentDidMount() {
    await this.load();
  }

  componentWillUnmount() {
    this.disposer();
  }

  load = async () => {
    if (!ReportsDataStore.drivingReport.loaded) {
      ReportsDataStore.getDrivingReport(this.shopId);
    }
    if (!ReportsDataStore.alarmReport.loaded) {
      ReportsDataStore.getAlarmReport(this.shopId);
    }
  };

  get carId() {
    return this.props.match.params.id;
  }

  get shopId() {
    return this.props.shopId;
  }

  get alarmReport() {
    return _.filter(
      ReportsDataStore.alarmReport.data,
      (el) => el.id_car === this.carId
    )[0];
  }

  get drivingReport() {
    return _.filter(
      ReportsDataStore.drivingReport.data,
      (el) => el.id_car === this.carId
    )[0];
  }

  get data() {
    let safetyViolationData = [],
      speedingDistanceData = [],
      idlingTimeData = [];

    let alarmReport = this.alarmReport;

    let drivingReport = this.drivingReport;

    safetyViolationData = [
      {
        name: 'High Speeding',
        color: '#A62639',
        y:
          !alarmReport || _.isEmpty(alarmReport)
            ? 0
            : alarmReport.high_speed_event_count,
      },
      {
        name: 'Hard Braking',
        color: '#E54A1B',
        y:
          !alarmReport || _.isEmpty(alarmReport)
            ? 0
            : alarmReport.braking_event_count,
      },
      {
        name: 'Acceleration',
        color: '#253C78',
        y:
          !alarmReport || _.isEmpty(alarmReport)
            ? 0
            : alarmReport.acceleration_event_count,
      },
    ];

    let isSpeedingEmpty =
      !alarmReport ||
      _.isEmpty(alarmReport) ||
      (alarmReport && alarmReport.speeding_distance_sum === 0);

    speedingDistanceData = [
      {
        name: 'Speeding',
        color: '#ED5565',
        units: 'km',
        y: isSpeedingEmpty ? 0 : alarmReport.speeding_distance_sum,
      },
      {
        name: 'Driving',
        color: '#00ce9d',
        units: 'km',
        y:
          !drivingReport || _.isEmpty(drivingReport) || isSpeedingEmpty
            ? 0
            : calcMileage(drivingReport.total_distance),
      },
    ];

    let isIdlingEmpty =
      !alarmReport ||
      _.isEmpty(alarmReport) ||
      (alarmReport && alarmReport.idling_sum === 0);

    idlingTimeData = [
      {
        name: 'Idling',
        color: '#ED5565',
        units: 'minutes',
        y: isIdlingEmpty ? 0 : alarmReport.idling_sum,
      },
      {
        name: 'Driving',
        color: '#00ce9d',
        units: 'minutes',
        y:
          !drivingReport || _.isEmpty(drivingReport) || isIdlingEmpty
            ? 0
            : drivingReport.total_trip_time,
      },
    ];

    return [
      {
        seriesName: 'Violation',
        emptyMessage: 'Your vehicle has no Violation alarms',
        value: safetyViolationData,
      },
      {
        seriesName: 'Distance Ratio',
        units: `${CurrentUserStore.user.displayedOdometer}`,
        emptyMessage: 'Your vehicle has no Speeding alarms',
        value: speedingDistanceData,
      },
      {
        seriesName: 'Time Ratio',
        units: 'minutes',
        emptyMessage: 'Your vehicle has no Idling alarms',
        value: idlingTimeData,
      },
    ];
  }

  get allAlarmsEmpty() {
    return (
      !this.alarmReport ||
      _.isEmpty(this.alarmReport) ||
      (this.alarmReport &&
        this.alarmReport.speeding_distance_sum === 0 &&
        this.alarmReport.idling_sum === 0)
    );
  }

  renderChart = (data) => {
    return (
      <DoughnutChart
        chart={{
          height: 200,
          margin: [0, 200, 0, 0],
          legend: {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'top',
            width: 200,
            x: 10,
            y: 70,
          },
        }}
        data={data}
        loaded={
          CarStore.demo === false ||
          (ReportsDataStore.drivingReport.loaded &&
            ReportsDataStore.alarmReport.loaded)
        }
      />
    );
  };

  render() {
    if (this.allAlarmsEmpty) return <></>;

    return (
      <>
        <Row>
          <h4>Vehicle Safety Alarms</h4>
        </Row>
        <Row gutter={[12, 32]}>
          {_.map(this.data, (d) => {
            return (
              <Col key={d.key} span={8}>
                <h6>{d.key}</h6>
                {this.renderChart(d)}
              </Col>
            );
          })}
        </Row>
      </>
    );
  }

  static propTypes = {
    car: PropTypes.object,
    units: PropTypes.string,
    shopId: PropTypes.number,
    match: PropTypes.object,
  };
}

export default withRouter(observer(DriverSummary));
