import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

export function PitstopButton(props) {
  const { type, children, ...otherProps } = props;
  switch (type) {
    case 'primary':
      break;
    case 'secondary':
      break;
    case 'tertiary':
      break;
    default:
      break;
  }
  return <Button {...{ ...otherProps, type }}>{children}</Button>;
}

PitstopButton.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node
};

PitstopButton.defaultProps = {
  type: 'primary'
};
