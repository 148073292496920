import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';
import moment from 'moment';

import AppStore from 'stores/App';
import AlarmMarker from 'components/AlarmMarker';

const markerStyle = {
  position: 'absolute',
  width: '12px',
  height: '12px',
  left: '-6px',
  top: '-6px',
};

function rad(x) {
  return (x * Math.PI) / 180;
}

class MultipleTrips extends Component {
  static defaultProps = {
    zoom: 11,
  };

  geodesicPolylines = [];
  startMarker = null;
  endMarker = null;
  map = null;
  maps = null;
  polylines = [];
  alarms = [];
  allSnappedCoordinates = [];

  state = {
    center: null,
    lat: null,
    lng: null,
    geodesicPolylines: [],
    placeholder: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedTrip.timeEnd !== prevProps.selectedTrip.timeEnd ||
      this.props.snapToRoad !== prevProps.snapToRoad
    ) {
      this.alarms = [];

      this.getcenter();
      this.handleMapRender(this.map, this.maps);
    }
  }

  getAnalyticsMarker = (time) => {
    // let time = String(this.props.CurrentMarkerTime);
    time = time.slice(0, -3);
    let timelist = [];
    this.props.data.location_polyline.forEach((element) => {
      timelist.push(element.timestamp);
    });
    //get closest timestamp in markers
    var closest = timelist.reduce(function(prev, curr) {
      return Math.abs(curr - Number(time)) < Math.abs(prev - Number(time))
        ? curr
        : prev;
    });
    //get lat and lng for marker with certain timestamp, break loop when found for better performance
    var BreakException = {};
    try {
      this.props.data.location_polyline.forEach((element) => {
        if (closest === element.timestamp) {
          let lat = Number(element.location[1].data);
          let lng = Number(element.location[0].data);
          closest = this.find_closest_marker(lat, lng);
          this.setState({
            lat: this.snappedCoordinates[closest].lat,
            lng: this.snappedCoordinates[closest].lng,
          });
          throw BreakException;
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
  };

  findClosestMarker(lat, lng) {
    var R = 6371; // radius of earth in km
    var distances = [];
    var closest = -1;
    for (var i = 0; i < this.allSnappedCoordinates.length; i++) {
      var mlat = this.allSnappedCoordinates[i].lat;
      var mlng = this.allSnappedCoordinates[i].lng;
      var dLat = rad(mlat - lat);
      var dLong = rad(mlng - lng);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(lat)) *
          Math.cos(rad(lat)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      distances[i] = d;
      if (closest === -1 || d < distances[closest]) {
        closest = i;
      }
    }
    return closest;
  }

  getDownSampleValues = (pathValues) => {
    // snap to road accepts only 100 points max per call
    if (pathValues.length <= 100) {
      return pathValues;
    } else {
      let sampledValues = [];

      let delnumber = Math.ceil(pathValues.length / 100);

      for (var i = 0; i < pathValues.length; i += delnumber) {
        sampledValues.push(pathValues[i]);
      }

      return sampledValues;
    }
  };

  getTripColor = (trip) => {
    let { selectedTrip } = this.props;

    if (
      selectedTrip.locationStart.latitude === trip.locationStart.latitude &&
      selectedTrip.locationStart.longitude === trip.locationStart.longitude &&
      selectedTrip.locationEnd.latitude === trip.locationEnd.latitude &&
      selectedTrip.locationEnd.longitude === trip.locationEnd.longitude
    ) {
      return 'red';
    } else {
      return 'blue';
    }
  };

  getLocationPolylines = (trips) => {
    let path = [];

    _.forEach(trips, (trip) => {
      if (
        !_.isNil(trip.location_polyline) &&
        !_.isEmpty(trip.location_polyline)
      ) {
        _.forEach(trip.location_polyline, (polyline) => {
          path.push({
            location: {
              latitude: Number(polyline.location[1].data),
              longitude: Number(polyline.location[0].data),
            },
            timestamp: polyline.timestamp,
          });
        });

        return;
      }

      if (
        !_.isNil(trip.locationStart) &&
        !_.isNil(trip.locationEnd) &&
        !_.isEmpty(trip.locationStart) &&
        !_.isEmpty(trip.locationEnd)
      ) {
        path.push({
          location: {
            latitude: trip.locationStart.latitude,
            longitude: trip.locationStart.longitude,
          },
          timestamp: trip.timeStart,
        });

        path.push({
          location: {
            latitude: trip.locationEnd.latitude,
            longitude: trip.locationEnd.longitude,
          },
          timestamp: trip.timeEnd,
        });

        return;
      }
    });

    return path;
  };

  snapToRoad = async (trip) => {
    let polylines = _.map(this.getLocationPolylines([trip]), (polyline) => [
      polyline.location.latitude,
      polyline.location.longitude,
    ]);

    //sampe down to 100 points max
    let path = this.getDownSampleValues(polylines);

    try {
      const results = await fetch(
        `https://roads.googleapis.com/v1/snapToRoads?path=${path.join(
          '|'
        )}&interpolate=true&key=AIzaSyDxDzCBe05AjpvIN1IJOq1u1q2tqzGzHpo`
      );
      let data = await results.json();

      return data.snappedPoints;
    } catch (e) {
      AppStore.addError('unable to get trip markers');
    }
  };

  handleMapRender = async (map, maps) => {
    this.map = map;
    this.maps = maps;

    for (let i = 0; i < this.props.data.length; i++) {
      this.polylines = [];

      let tripColor = this.getTripColor(this.props.data[i]);

      if (this.geodesicPolylines[i]) {
        this.geodesicPolylines[i].setMap(null);
      }

      if (this.props.snapToRoad) {
        this.polylines = await this.snapToRoad(this.props.data[i], i);
      } else {
        this.polylines = this.getLocationPolylines([this.props.data[i]]);
      }

      this.getAlarms([this.props.data[i]]);

      this.renderPolylines(this.map, this.maps, tripColor, i);
    }
  };

  renderPolylines(map, maps, tripColor, index) {
    //render polyline
    let snappedCoordinates = [];

    for (let i = 0; i < this.polylines.length; i++) {
      let latlng = new maps.LatLng(
        this.polylines[i].location.latitude,
        this.polylines[i].location.longitude
      );
      snappedCoordinates.push(latlng);
    }

    let geodesicPolyline = new maps.Polyline({
      path: snappedCoordinates,
      geodesic: true,
      options: {
        strokeColor: tripColor,
        strokeOpacity: tripColor === 'red' ? 1 : 0.3,
        strokeWeight: tripColor === 'red' ? 5 : 3,
      },
    });
    geodesicPolyline.setMap(map);

    if (this.geodesicPolylines[index]) {
      this.geodesicPolylines[index] = geodesicPolyline;
    } else {
      this.geodesicPolylines.push(geodesicPolyline);
    }

    //render markers
    //startmarker
    this.startMarker = new maps.Marker({
      position: snappedCoordinates[0],
      map: map,
      icon: {
        size: new maps.Size(12, 12),
        scaledSize: new maps.Size(12, 12),
        url: require('./Icons/startmarker.png'),
      },
    });
    this.startMarker.setMap(map);
    //endmarker
    this.endMarker = new maps.Marker({
      position: snappedCoordinates[Number(snappedCoordinates.length - 1)],
      width: '12px',
      height: '12px',
      map: map,
      icon: {
        size: new maps.Size(55, 55),
        scaledSize: new maps.Size(55, 55),
        url: require('./Icons/endmarker.png'),
      },
    });
    this.endMarker.setMap(map);

    this.allSnappedCoordinates.push(snappedCoordinates);

    // set bounds
    var bounds = new maps.LatLngBounds();
    var points = [];
    if (this.allSnappedCoordinates.length === this.props.data.length) {
      this.allSnappedCoordinates.forEach((el) =>
        el.forEach((p) => points.push(p))
      );
      for (var n = 0; n < points.length; n++) {
        bounds.extend(points[n]);
      }
      map.fitBounds(bounds);
    }
  }

  getcenter = () => {
    let location = this.props.data[0].location_polyline[0].location;

    this.setState({
      center: { lat: Number(location[1].data), lng: Number(location[0].data) },
    });
  };

  getAlarms = (trips) => {
    //read alarms out of tripobject
    let alarms = [];

    _.forEach(trips, (trip) => {
      if (_.isNil(trip.alarms) || _.isEmpty(trip.alarms)) {
        return;
      }

      _.forEach(trip.alarms, (element) => {
        this.getAlarmLocation(
          moment(element.triggeredAt)
            .tz('America/Toronto')
            .format('X')
        );

        if (this.latlng) {
          alarms.push({
            name: element.name,
            lat: this.latlng.lat,
            lng: this.latlng.lng,
            triggeredAt: moment(element.triggeredAt).format(
              '"dddd, MMMM Do YYYY, h:mm:ss a"'
            ),
          });

          this.setState({
            alarms: [...alarms],
          });
        }
      });
    });
  };

  getAlarmLocation = (time) => {
    //getting closest marker on polyline by time
    // time = time.slice(0, -3);
    let timelist = [];

    _.forEach(this.polylines, (element) => {
      timelist.push(element.timestamp);
    });

    //get closest timestamp in markers
    let closest = _.reduce(timelist, function(prev, curr) {
      return Math.abs(curr - Number(time)) < Math.abs(prev - Number(time))
        ? curr
        : prev;
    });

    if (_.isEmpty(this.allSnappedCoordinates)) return [];

    _.forEach(this.polylines, (element) => {
      if (closest === element.timestamp) {
        let lat = Number(element.location[0]);
        let lng = Number(element.location[1]);
        closest = this.findClosestMarker(lat, lng);
        let latlng = {
          lat: this.allSnappedCoordinates[closest].lat,
          lng: this.allSnappedCoordinates[closest].lng,
        };
        this.latlng = latlng;
      }
    });
  };

  render() {
    return !this.props.data[0].location_polyline ? (
      <div>
        <h4 style={{ textAlign: 'center' }}>
          There is no GPS data for the trip you selected.
        </h4>
      </div>
    ) : (
      // Important! Always set the container height explicitly
      <div style={{ height: '700px', width: '100%', marginRight: '12px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyDxDzCBe05AjpvIN1IJOq1u1q2tqzGzHpo',
          }}
          defaultCenter={this.state.center || { lat: -79, lng: 43 }}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => this.handleMapRender(map, maps)}
          hoverDistance={12 / 2}
        >
          {this.alarms.map((element) => {
            return (
              <AlarmMarker
                key={element.triggeredAt}
                lat={element.lat}
                lng={element.lng}
                name={element.name}
                triggeredAt={element.triggeredAt}
              />
            );
          })}
          <img
            style={markerStyle}
            lat={this.state.lat}
            lng={this.state.lng}
            height="12px"
            width="12px"
            alt="marker"
            src={require('./Icons/startmarker.png')}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

MultipleTrips.propTypes = {
  data: PropTypes.array,
  zoom: PropTypes.number,
  CurrentMarkerTime: PropTypes.number,
  selectedTrip: PropTypes.object,
  snapToRoad: PropTypes.bool,
};

export default MultipleTrips;
