import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { Promise } from 'bluebird';

import { Spin, Popover, Icon, Tag, Row } from 'antd';

import { CarObject } from 'stores/Classes';
import InsightsPage from './AlgorithmInsights/InsightsPage';
import CarStore from 'stores/CarStore';
import { AppStore } from 'stores';

const RedTag = styled.span`
  color: rgb(237, 85, 101);
`;
const GreenTag = styled.span`
  color: #00ce9d;
`;

const StyledLabel = styled.label`
  font-weight: bold;
  font-size: 14px;
`;

const StyledContainer = styled.div`
  && div {
    margin-bottom: 12px;
  }
`;

const NoIssuesSection = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0px;
    font-size: 14px;
  }
  i {
    font-size: 36px;
    margin-right: 12px;
  }
`;

const possibleReasons = {
  fuelTrim: [
    'Air Restriction',
    'Air Leak',
    'Not Enough Fuel',
    'Too Much Fuel',
    'Sensor Problem',
  ],
};

class CarReportsSection extends Component {
  state = {
    alerts: [],
    isAlertsLoaded: false,
  };

  async componentDidMount() {
    await this.car.getReports();

    await this.getAlertData();
  }

  get car() {
    return this.props.car;
  }

  get reports() {
    const reports =  this.car.reports.data; 

    // let brakeTestVins = ['4V4NC9EH3JN992854'];
    // // fixme: demo use only
    // if (brakeTestVins.includes(this.car.vin)) {
    //   reports.push({
    //     item: 'Brakes',
    //     description:
    //       'Battery test working: Last test for battery:  at NA with result: pass',
    //     failure: 'Frequent Heavy Braking Overtime',
    //     vin: '4V4NC9EH3JN992854',
    //     dtc: [],
    //     report: {
    //       date: 'NA',
    //       score: -99,
    //       status: 'worn',
    //       variant: 'unknown',
    //       testName: 'brake',
    //       explanation: ''
    //     }
    //   });
    // }

    return reports;
  }

  get pendingAlgorithms() {
    let algorithms = [
      'Air Filter',
      'Battery',
      'Brakes',
      'CO2',
      'Cranking Voltage',
      'Flat Sensor',
      'DEF fluid',
      'Fuel Trim',
      'NOx',
    ];

    let resultedAlgorithms = _.map(this.reports, (report) => {
      return report['item'];
    });

    let pendingAlgorithms = _.filter(
      algorithms,
      (algorithm) => !resultedAlgorithms.includes(algorithm)
    );

    return _.map(pendingAlgorithms, (algorithm, i) => ({
      key: i,
      item: algorithm,
      color: '#f5f5f5',
    }));
  }

  get columns() {
    return [
      {
        title: 'Name',
        dataIndex: 'item',
        key: 'item',
      },
      {
        title: 'Status',
        key: 'status',
        render: (el) => {
          if (el && el.report && el.report.status) {
            if (el.dtc && el.dtc.length > 0) {
              if (el.report.status === 'fail') {
                return <RedTag>Fail and Fault Codes are Present</RedTag>;
              }

              return <RedTag>Fault Codes Present</RedTag>;
            }

            if (el.report.status === 'pass') {
              return <GreenTag>{el.report.status.capitalize()}</GreenTag>;
            }

            return (
              <Popover content={el.report.explanation}>
                <RedTag>{el.report.status.capitalize()}</RedTag>
              </Popover>
            );
          }
        },
      },
      {
        title: 'Possible Reasons',
        key: 'problem',
        render: (el) => {
          if (
            el.dtc &&
            el.dtc.length > 0 &&
            el.report &&
            el.report.status === 'pass'
          ) {
            return (
              <span>
                Fault codes present on your vehicle. Click to find out more
                information.
              </span>
            );
          } else if (
            el.dtc &&
            el.dtc.length > 0 &&
            el.report &&
            el.report.status === 'fail'
          ) {
            return (
              <Popover
                content={el.algoDtcResultDescription}
                title="What this means?"
                trigger="hover"
              >
                <span>
                  {el.algoDtcResult.split('_').join(' ').capitalize()}
                  <Icon
                    style={{ fontSize: 14, paddingLeft: 9 }}
                    type="info-circle"
                  />
                </span>
              </Popover>
            );
          } else if (
            el.report.status === 'fail' &&
            el.failure === 'Fueltrim anomaly'
          ) {
            const reasons = possibleReasons.fuelTrim;
            return reasons.map((reason) => {
              return (
                <ul key={1}>
                  <li key={reason}>{reason}</li>
                </ul>
              );
            });
          } else if (el.report && el.report.status === 'pass') {
            return <span>Not Applicable</span>;
          } else {
            return (
              <Popover content={el.report.explanation}>
                <RedTag>{el.failure}</RedTag>
              </Popover>
            );
          }
        },
      },
      {
        title: 'Fault Codes Present',
        key: 'dtc',
        render: (el) => {
          if (el.dtc && el.dtc.length > 0) {
            return el.dtc.map((dtc) => {
              var link = `/issues/${dtc.id}`;
              return (
                <Link key={dtc.name} to={link}>
                  <Tag color="volcano" style={{ cursor: 'pointer' }}>
                    {dtc.name}
                  </Tag>
                </Link>
              );
            });
          } else if (el.report.status === 'fail') {
            return <Tag color="green">No Relevant DTC Present</Tag>;
          } else {
            return <Tag color="green">No Relevant DTC Present</Tag>;
          }
        },
      },
    ];
  }

  get pendingColumns() {
    return [
      {
        title: 'Name',
        dataIndex: 'item',
        render: (text, record) => {
          return {
            props: {
              style: { background: record['color'] },
            },
            children: text,
          };
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (text, record) => {
          return {
            props: {
              style: { background: record['color'] },
            },
            children: text ? (
              text.capitalizeFirstLetter()
            ) : (
              <Tag color="orange">Data is unavailable!</Tag>
            ),
          };
        },
      },
      {
        title: 'Possible Reasons',
        dataIndex: 'reason',
        render: (text, record) => {
          return {
            props: {
              style: { background: record['color'] },
            },
            children: text ? text.capitalizeFirstLetter() : 'Not Applicable',
          };
        },
      },
      {
        title: 'Fault Codes Present',
        dataIndex: 'faultCode',
        render: (text, record) => {
          return {
            props: {
              style: { background: record['color'] },
            },
            children: text ? text.capitalizeFirstLetter() : 'Not Applicable',
          };
        },
      },
    ];
  }

  alertColumns = () => {
    return [
      {
        title: 'Name',
        dataIndex: 'alertType',
      },
      {
        title: 'Date',
        dataIndex: 'rtc_time',
        render: (record) => moment.unix(record).format('LLL'),
      },
      {
        title: 'Explanation',
        dataIndex: 'explanation',
      },
    ];
  };

  getAlertData = () => {
    Promise.try(async () => {
      this.setState({
        isAlertsLoaded: false,
      });

      let [parkedBatteryAlerts, dtcClusterAlerts] = await Promise.all([
        this.car.getAlert('parkedBattery'),
        this.car.getAlert('dtcCluster'),
      ]);

      let _parkedBatteryAlerts = _.map(parkedBatteryAlerts.data, (alert) => {
        return {
          alertType: 'Parked Battery',
          explanation: alert.message,
          rtc_time: alert.rtc_time,
        };
      });

      let _dtcClusterAlerts = _.map(dtcClusterAlerts.data, (alert) => {
        return {
          alertType: 'Fault Code Group',
          explanation: alert?.faultcode_cluster?.notes,
          rtc_time: alert.rtc_time,
        };
      });

      this.setState({
        alerts: [..._parkedBatteryAlerts, ..._dtcClusterAlerts],
        isAlertsLoaded: true,
      });
    }).catch((err) => AppStore.addError(err.message));
  };

  render() {
    let testDate = moment().format('LLL');

    if (CarStore.demo === false) {
      return <h3>There is no data to display</h3>;
    }
    if (!this.car.reports.loaded) {
      return (
        <React.Fragment>
          <h4>Pitstop Insights</h4>
          <Spin tip="Loading Reports..." />
        </React.Fragment>
      );
    }
    if (this.car.reports.isError) {
      return (
        <React.Fragment>
          <h4>Pitstop Insights</h4>
          <p>{this.car.reports.errorMessage}</p>
        </React.Fragment>
      );
    }

    if (
      this.reports &&
      this.reports.length > 0 &&
      this.reports[0].report &&
      this.reports[0].report.date
    ) {
      testDate = this.reports[0].report.date;
      testDate = moment(testDate).isValid() && moment(testDate).format('LLL');
    }

    return (
      <StyledContainer>
        <Row>
          <StyledLabel>Pitstop insights were updated on {testDate}</StyledLabel>
        </Row>
        <Row>
          {this.reports.length === 0 ? (
            <NoIssuesSection>
              <Icon type="check-circle" theme="twoTone" />
              <p>Your car has nothing to report!</p>
            </NoIssuesSection>
          ) : (
            <>
              <InsightsPage records={this.reports.results.filter((result) => !_.isEmpty(result))}/> 
            </>
          )}
        </Row>

        {/* <Row>
          <StyledLabel>Pending Algorithms</StyledLabel>
        </Row>
        <Row>
          <Table
            columns={this.pendingColumns}
            dataSource={this.pendingAlgorithms}
            pagination={false}
          />
        </Row> */}
        {/*}
        <Row>
          <Alert
            message={<StyledLabel>Alerts</StyledLabel>}
            type="warning"
            showIcon
          />
        </Row>

        <Row>
          <Table
            columns={this.alertColumns()}
            loading={!this.state.isAlertsLoaded}
            dataSource={this.state.alerts}
            pagination={false}
          />
        </Row>*/}
      </StyledContainer>
    );
  }
}

CarReportsSection.propTypes = {
  car: PropTypes.instanceOf(CarObject),
};

export default observer(CarReportsSection);
