import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import PropTypes from 'prop-types';

import { Col, Row, Typography, InputNumber, Icon, Select, Input } from 'antd';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

class WorkOrderLineItemMiscItemDesktop extends Component {
  static propTypes = {
    childRef: PropTypes.func,
    item: PropTypes.object,
    onUpdateTotal: PropTypes.func,
    onRemoveItem: PropTypes.func,
    shopUsers: PropTypes.array,
    onUpdateLocalItem: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
  };

  localItem = {
    id_misc: '',
    desc: '',
    cost: 0,
  };

  get localShopUsers() {
    return this.props.shopUsers;
  }

  get shopUsersOptions() {
    return this.localShopUsers.map((user, index) => (
      <Option key={index} value={user.id}>
        {user.name}
      </Option>
    ));
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.localItem = this.props.item;
  }

  get totalAmount() {
    let num = Number(this.localItem?.cost) || 0;
    return num.toFixed(2);
  }

  // get miscValue() { // how do I change this function
  //   const shopUsers = this.localShopUsers;
  //   const isSelectedLaborAShopUser = shopUsers.find(
  //     (user) => user.id === this.localItem.id_labour
  //   );
  //   if (labourTechnician?.source && labourTechnician?.source !== 'pitstop') {
  //     return labourTechnician?.full_name || 'N/A';
  //   } else if (labourTechnician?.id_technician && !isSelectedLaborAShopUser) {
  //     return parseInt(labourTechnician.id_technician);
  //   }
  //   return this.localItem.id_labour;
  // }

  onChangeAmount = (newVal, fieldName) => {
    this.localItem[fieldName] = newVal;

    if (this.props.onUpdateLocalItem) {
      this.props.onUpdateLocalItem(this.localItem);
    }

    if (this.props.onUpdateTotal) {
      this.props.onUpdateTotal(this.totalAmount);
    }
  };

  onChangeSelectField = (value, fieldName) => {
    this.localItem[fieldName] = value;

    if (this.props.onUpdateLocalItem) {
      this.props.onUpdateLocalItem(this.localItem);
    }
  };

  onRemoveItem = (event, itemKey) => {
    if (this.props.onRemoveItem) {
      this.props.onRemoveItem(event, itemKey);
    }
    if (this.props.onUpdateTotal) {
      this.props.onUpdateTotal(this.totalAmount);
    }
  };

  render() {
    // const filterOption = (input, option) => {
    //   return (
    //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //   );
    // };

    return (
      <Row style={{ margin: 0 }} gutter={[8, 8]}>
        <Col key={'name'} span={9}>
          <Text strong>Description:</Text>
          <TextArea
            rows={4}
            placeholder="Add Description"
            value={this.localItem.desc} // Set the value to localItem.desc
            onChange={(e) => this.onChangeAmount(e.target.value, 'desc')}
            // onChange={this.props.onChangeDescription}
            disabled={this.props.isDisabled}
          />
        </Col>
        {/* <Col key={'hours'} span={5}>
          <Text strong>Hours</Text>
          <InputNumber
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            value={this.localItem.hours}
            onChange={(val) => this.onChangeAmount(val, 'hours')}
            disabled={this.props.isDisabled}
          />
        </Col> */}
        <Col key={'cost'} span={5}>
          <Text strong>Cost</Text>
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            value={this.localItem.cost}
            onChange={(val) => this.onChangeAmount(val, 'cost')}
            disabled={this.props.isDisabled}
          />
        </Col>
        <Col key={'miscAmount'} span={4}>
          <Text strong>Total Amount:</Text>
          <InputNumber
            value={this.totalAmount}
            disabled={true}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
          />
        </Col>
        <Col
          key={'deleteBtn'}
          span={1}
          style={{
            display: 'flex',
            height: '57px',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '25px',
          }}
        >
          <Icon
            type="delete"
            onClick={(event) => {
              event.stopPropagation();
              this.onRemoveItem(event, this.props.item.key);
            }}
          />
        </Col>
      </Row>
    );
  }
}

decorate(WorkOrderLineItemMiscItemDesktop, {
  localItem: observable,
  totalAmount: computed,
  localShopUsers: computed,
});

export default observer(WorkOrderLineItemMiscItemDesktop);
