import styled from 'styled-components';

import { Card } from 'antd';

export const SimpleCard = styled(Card)`
  && {
    align-items: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 6%),
      0px 6px 10px 0px rgb(0 0 0 / 4%), 0px 1px 18px 0px rgb(0 0 0 / 4%);
  }
`;
