import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Row,
  Col,
  Form,
  Select,
  Input,
  InputNumber,
  Button,
  Icon,
  Typography,
} from 'antd';

const { Text } = Typography;

const LineItemRow = styled(Row)`
  .ant-form-item-label {
    line-height: 14px;
  }
`;

const LineItemActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
`;

const NoFoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LineItem = ({ item, onRemoveItem, partOptions, onChange }) => {
  const totalCost = (item.quantity || 0) * (item.unitCost || 0);

  const filterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  const noPartFound = (
    <NoFoundContainer>
      <Icon style={{ fontSize: '24px' }} type="inbox" />
      <Text level={4}>No Data</Text>
    </NoFoundContainer>
  );

  const onSearchPart = (value) => {
    // setCurrentSearchPart(value);
  };

  return (
    <LineItemRow gutter={16}>
      <Col span={6}>
        {item.type === 'part' ? (
          <Form.Item label="Part">
            <Select
              style={{ width: '100%' }}
              placeholder="Select a Part"
              optionFilterProp="children"
              value={item.entityId}
              onChange={(val) => onChange(item, 'entityId', val)}
              onSearch={(value) => onSearchPart(value)}
              showSearch
              filterOption={filterOption}
              notFoundContent={noPartFound}
            >
              {partOptions}
            </Select>
          </Form.Item>
        ) : (
          <Form.Item label="Service">
            <Input
              value={item.entityId}
              onChange={(event) =>
                onChange(item, 'entityId', event.target.value)
              }
            />
          </Form.Item>
        )}
      </Col>
      <Col span={3}>
        <Form.Item label="Qty">
          <Input
            value={item.quantity}
            onChange={(event) => onChange(item, 'quantity', event.target.value)}
          />
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item label="Vol">
          <Input
            value={item.volume}
            onChange={(event) => onChange(item, 'volume', event.target.value)}
            placeholder="-"
          />
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item label="Vol. Unit">
          <Select
            style={{ width: '100%' }}
            placeholder="-"
            value={item.volumeUnit}
            onChange={(val) => onChange(item, 'volumeUnit', val)}
          >
            <Select.Option value="">-</Select.Option>
            <Select.Option value="cm">cm</Select.Option>
            <Select.Option value="feet">feet</Select.Option>
            <Select.Option value="gal">gal</Select.Option>
            <Select.Option value="inch">inch</Select.Option>
            <Select.Option value="l">l</Select.Option>
            <Select.Option value="m">m</Select.Option>
            <Select.Option value="ml">ml</Select.Option>
            <Select.Option value="pints">pints</Select.Option>
            <Select.Option value="quarts">quarts</Select.Option>
            <Select.Option value="sqft">sqft</Select.Option>
            <Select.Option value="sqm">sqm</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Unit Cost">
          <InputNumber
            style={{
              width: '100%',
            }}
            prefix="$"
            value={item.unitCost}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => onChange(item, 'unitCost', value)}
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Amount">
          <InputNumber
            style={{
              width: '100%',
            }}
            prefix="$"
            disabled={true}
            readOnly={true}
            value={totalCost}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
      </Col>
      <Col span={1}>
        <LineItemActionWrapper>
          <Button
            type="link"
            icon="delete"
            style={{ color: 'red' }}
            onClick={() => {
              onRemoveItem(item);
            }}
          />
        </LineItemActionWrapper>
      </Col>
    </LineItemRow>
  );
};

LineItem.propTypes = {
  item: PropTypes.object,
  onRemoveItem: PropTypes.func,
  onChange: PropTypes.func,
  partOptions: PropTypes.array,
};

export default LineItem;
