import React from 'react';
import PropTypes from 'prop-types';
import { PitstopFormInline } from 'shared';

const defaultFields = [
  { label: 'VIN', name: 'vin', required: true, type: 'text' },
  { label: 'Mileage', name: 'baseMileage', required: true, type: 'number' },
  { label: 'Unit ID', name: 'carName', required: false, type: 'text' },
  {
    label: 'Device ID (Scanner)',
    name: 'scannerId',
    required: false,
    type: 'text',
  },
];

const shopField = {
  label: 'Fleet',
  name: 'shopId',
  required: true,
  type: 'select',
};

export function CreateCarForm(props) {
  const { shops, extraFields } = props;

  let fields = !shops.length
    ? defaultFields
    : [...defaultFields, { ...shopField, selectOptions: shops }];

  return (
    <PitstopFormInline
      fields={extraFields ? fields.concat(extraFields) : fields}
      onSubmit={props.onSubmit}
      submitText="Add"
    />
  );
}

CreateCarForm.propTypes = {
  shops: PropTypes.array,
  extraFields: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
};
