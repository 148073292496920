import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';

import { Empty, Icon, Input, Table } from 'antd';

import { PitstopTable } from 'shared';

import { BrakeUsageSamples } from './sample-data';

import { calcMileage, convertSecondsToHours } from 'helpers/unitCalculations';

import { TableStore } from 'stores/abstract';
import { WidgetToolTip } from 'shared/PitstopUI/PitstopChart';
import {
  CurrentUserStore,
  ReportsDataStore,
  ShopStore,
  CarStore,
} from 'stores';

const VehicleContainer = styled.div`
  p {
    margin-bottom: 0px;
  }
`;

const InputContainer = styled(Input)`
  margin: 20px 0px;
  width: 50%;
`;

class BrakeUsage extends Component {
  static propTypes = {
    history: PropTypes.object,
    setStore: PropTypes.func
  };

  state = {
    vin: '',
  };

  tableStore = new TableStore();

  get data() {
    return this.tableStore.data;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.vin !== prevState.vin) {
      this.tableStore.reset();
      await this.filterValueFetch('vin', this.state.vin);
    }
  }

  columns = [
    {
      title: 'Vehicle',
      key: 'make',
      render: (record) => {
        let mmy = `${record.car_make || ''} ${record.car_model ||
          ''} ${record.car_year || ''}`;

        return (
          <VehicleContainer>
            <Link to={`car/${record.car_id}`}>{record.car_name || mmy}</Link>
            <p>{record.car_name && mmy}</p>
            <p>{record.vin}</p>
            <p>
              {`${Math.round(calcMileage(record.mileage_total)).toReadable()} 
        ${CurrentUserStore.user.displayedOdometer}`}
            </p>
            <p>
              {!_.isNaN(record.engine_hours)
                ? `${Math.round(
                    convertSecondsToHours(record.engine_hours)
                  ).toReadable()} engine hours`
                : ''}
            </p>
          </VehicleContainer>
        );
      },
    },
    {
      title: 'Report Date',
      key: 'reportDate',
      render: (record) => <p>{moment(record.report.date).format('ll')}</p>,
    },
    {
      title: 'Score',
      key: 'score',
      sorter: true,
      render: (record) => (
        <p>
          {record.report.score ? Number(record.report.score).toFixed(2) : 'N/A'}
        </p>
      ),
    },
  ];

  getReport = async (
    shopId = ShopStore.currentShop.id,
    filters,
    tableStore
  ) => {
    if (_.isNil(filters.sort)) {
      filters.sort = '-score';
    }

    await ReportsDataStore.getBrakeUsageReport(shopId, filters, tableStore);
  };

  filterValue = async (key, value) => {
    if (this.tableStore) {
      this.setState({ [key]: value });
    }
  };

  filterValueFetch = async (key, value) => {
    const filter = { ...this.tableStore.filter, [key]: value };

    if (!value || value.length === 0) {
      delete filter[key];
    }

    this.getReport(
      ShopStore.currentShop.id,
      {
        offset: 0,
        filter,
      },
      this.tableStore
    );
  };

  render() {
    let shopId = ShopStore.currentShop.id;

    if (CarStore.demo) {
      return (
        <Table
          rowKey="car_id"
          columns={this.columns}
          dataSource={BrakeUsageSamples}
          pagination={{ pageSize: 5 }}
        />
      );
    }

    if (CarStore.demo === false) return <Empty />;

    return (
      <>
        <WidgetToolTip title='This ranks each vehicle in accordance with our Brake Model' />
        <InputContainer
          placeholder="Vin"
          value={this.state.vin}
          prefix={<Icon type="search" />}
          onChange={(e) => this.filterValue('vin', e.target.value)}
        />

        <PitstopTable
          id={`${ShopStore.currentShop.id}-brake-usage`}
          columns={this.columns}
          data={this.data}
          rowKey="id"
          limit={5}
          fetchData={async (filters, tableStore) => {
            if (shopId !== -1) {
              this.getReport(shopId, filters, tableStore);
            }
          }}
          getTableStore={(tableStore) => {
            this.tableStore = tableStore;
            if (this.props.setStore) {
              this.props.setStore(tableStore);
            }
          }}
        />
      </>
    );
  }
}

decorate(BrakeUsage, {
  tableStore: observable,
  data: computed,
});

export default withRouter(observer(BrakeUsage));
