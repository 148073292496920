import React, { Component } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import { decorate } from 'mobx';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Row, Col, DatePicker, Divider, Spin } from 'antd';

import PitstopSimpleTable from 'components/PitstopSimpleTable';
import { AppStore, OdometerSnapshotStore, ShopStore } from 'stores';

const { MonthPicker } = DatePicker;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const MonthRangePicker = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

class OdometerSnapshot extends Component {
  columns = [
    {
      title: 'Vehicle',
      dataIndex: 'carId',
      key: 'carId',
      render: (carName, row) => {
        return (
          <Link to={`/car/${row.key}`}>
            {carName}
          </Link>
        );
      },
    },
  ];

  data = [];

  state = {
    monthFrom: moment().subtract(5, 'month'),
    monthTo: moment(),
    columns: this.columns,
    data: this.data,
    isLoading: false,
  };

  componentDidMount () {
    this.reloadData();
  }

  /**
   * Get an array of months between from and to.
   * @param {Moment} from
   * @param {Moment} to
   * @returns an array of months between from and to
   */
  getMonthsInRange (from, to) {
    const months = [];
    const current = moment(from);

    while (current.isSameOrBefore(to)) {
      months.push(moment(current));
      current.add(1, 'month');
    }

    return months;
  }

  /**
   * Reload data when monthFrom or monthTo is changed.
   */
  async reloadData () {
    try {
      this.setState({ isLoading: true });

      // get months in range
      const months = this.getMonthsInRange(this.state.monthFrom, this.state.monthTo);

      // refresh columns
      const columns = [
        ...this.columns,
        ...months.map(month => ({
          title: month.format('MMM YYYY'),
          dataIndex: month.format('MMM_YYYY'),
          key: month.format('MMM_YYYY'),
          render: value => value || '0',
        }))
      ];

      // get data from API
      const odometerResponse = await OdometerSnapshotStore.getShopOdometerSnapshotInRange(
        ShopStore.currentShop.id,
        this.state.monthFrom,
        this.state.monthTo
      );

      // group the odometer data by carId
      const odometerDataByCar = _.groupBy(odometerResponse, 'car_id');

      const carSummary = [];

      for (let carId in odometerDataByCar) {
        const carOdometers = odometerDataByCar[carId];
        const carData = {
          key: carId,
          carId: _.get(carOdometers[0], 'vin', carId),
        };
        for (let odometer of carOdometers) {
          const capturedAt = moment(odometer.capture_date_utc);
          const formattedMonth = capturedAt.format('MMM_YYYY');
          if (carData[formattedMonth]) {
            // sum up the odometer in the same month
            carData[formattedMonth] += Number(odometer.odometer_km);
          } else {
            carData[formattedMonth] = Number(odometer.odometer_km);
          }
          carData[formattedMonth] = _.round(carData[formattedMonth], 2);
        }
        carSummary.push(carData);
      }

      this.setState({
        columns,
        data: carSummary
      });
    } catch (error) {
      console.error(error);
      AppStore.addError('Failed to load the odometer snapshot data');
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onChangeFrom (monthFrom) {
    this.setState({ monthFrom }, () => {
      this.reloadData();
    });
  }

  onChangeTo (monthTo) {
    this.setState({ monthTo }, () => {
      this.reloadData();
    });
  }

  render() {
    let { monthFrom, monthTo, columns, data, isLoading } = this.state;

    if (isLoading) {
      return (
        <LoadingWrapper>
          <Spin />
        </LoadingWrapper>
      );
    }

    return (
      <Row>
        <Col>
          <HeaderWrapper>
            <span>
              Odometer report on a monthly basis.
            </span>
            <MonthRangePicker>
              <MonthPicker
                defaultValue={monthFrom}
                format="MMM-YYYY"
                onChange={(val) => this.onChangeFrom(val)}
                placeholder="From"
              />
              <Divider type="vertical" />
              <MonthPicker
                defaultValue={monthTo}
                format="MMM-YYYY"
                onChange={(val) => this.onChangeTo(val)}
                placeholder="To"
              />
            </MonthRangePicker>
          </HeaderWrapper>
        </Col>
        <Col>
          <PitstopSimpleTable
            columns={columns}
            data={data}
          />
        </Col>
      </Row>
    );
  }
}

decorate(OdometerSnapshot, {});

OdometerSnapshot.propTypes = {
  history: PropTypes.object,
  userId: PropTypes.number,
};

export default withRouter(observer(OdometerSnapshot));
