//This file just contains a hard-coded list of algorithm names which have the opposite score convention (eg a low score is bad)
//Most algorithms have a high score indicating failure (eg a high failure probability or large number of failures)
//These algorithms are the opposite where a low score indicates failure (eg DEF tank level or low voltage)

const IsScoreTypeReversed = (item) => {
  switch(item){
    case 'Cranking Voltage': 
      return true;
    case 'Diesel Exhaust Fluid':
      return true;
    default:
      return false;
  }
};

export default IsScoreTypeReversed;