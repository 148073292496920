import React from 'react';

import { Button, Checkbox } from 'antd';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { WorkOrderFilterOptions } from 'stores/Classes/WorkOrderObject';

const FilterDropdown = styled.div`
  .filter-dropdown-list {
    padding: 8px 12px;
    border-bottom: solid 1px #e8e8e8;

    .filter-dropdown-list-item {
      margin-bottom: 5px;
    }
  }

  .filter-dropdown-actions {
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
  }

  &.assigned-to-dropdown {
    min-width: 300px;
    width: 300px;
  }
`;

export const renderStatusFilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  onReset,
  onOk,
}) => (
  <FilterDropdown>
    {/* For each filterOptions display a checkbox and the name of the filterOptions */}
    <div className="filter-dropdown-list">
      {WorkOrderFilterOptions.map((filter) => {
        return (
          <div className="filter-dropdown-list-item" key={filter.value}>
            <Checkbox
              checked={selectedKeys.includes(filter.value)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedKeys([...selectedKeys, filter.value]);
                } else {
                  setSelectedKeys(
                    selectedKeys.filter((key) => key !== filter.value)
                  );
                }
              }}
            >
              {filter.text}
            </Checkbox>
          </div>
        );
      })}
    </div>
    <div className="filter-dropdown-actions">
      <Button onClick={() => onReset(clearFilters)} size="small">
        Reset
      </Button>
      <Button
        type="primary"
        size="small"
        onClick={() => onOk(selectedKeys, confirm)}
      >
        OK
      </Button>
    </div>
  </FilterDropdown>
);

renderStatusFilterDropdown.propTypes = {
  setSelectedKeys: PropTypes.func,
  selectedKeys: PropTypes.array,
  confirm: PropTypes.func,
  clearFilters: PropTypes.func,
  onReset: PropTypes.func,
  onOk: PropTypes.func,
};

renderStatusFilterDropdown.defaultProps = {
  setSelectedKeys: () => {},
  selectedKeys: [],
  confirm: () => {},
  clearFilters: () => {},
  onReset: () => {},
  onOk: () => {},
};
