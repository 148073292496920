import React, { useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Calendar } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import CustomEvent from './CustomEvent';

function customEventProps (event) {
  const getBgColorBasedOnPriorityAndStatus = (otherServicesCount) => {
    // check if there are services with priority higher than 3 - Critical
    const hasHighPriorityServices = _.some(otherServicesCount, service => service.priority > 3);
    if (hasHighPriorityServices) {
      return '#F92D2E';
    }
    // check if there are services with priority higher than 1 - Major
    const hasMediumPriorityServices = _.some(otherServicesCount, service => service.priority > 1);
    if (hasMediumPriorityServices) {
      return '#E28931';
    }
    // check if there are more than 1 service in count
    const hasOthersServices = _.some(otherServicesCount, service => _.parseInt(service.count) > 1);
    if (hasOthersServices) {
      return '#DEC431';
    }
    // only the PM service
    return '#52c41a';
  };
  const style = {
    color: '#fff',
    backgroundColor: getBgColorBasedOnPriorityAndStatus(event.otherServicesCount),
    borderRadius: '0px',
    opacity: 0.8,
    border: '0px',
    display: 'block',
    fontSize: '0.8em'
  };
  return {
    className: '',
    style: style
  };
}

function PitstopCalendar (props) {
  const { components } = useMemo(
    () => ({
      components: {
        event: CustomEvent,
      }
    }),
    []
  );

  const DnDCalendar = withDragAndDrop(Calendar);

  return (
    <DnDCalendar
      components={components}
      eventPropGetter={customEventProps}
      dayPropGetter={props.customCalendarStyle}
      localizer={props.localizer}
      selectable={true}
      style={{ height: 550 }}
      events={props.services}
      date={props.date}
      defaultDate={props.date}
      defaultView={props.defaultView}
      views={props.views}
      min={props.minTime}
      max={props.maxTime}
      drilldownView="day"
      step={15}
      timeslots={4}
      showMultiDayTimes={true}
      draggableAccessor={(event) => true}
      resizable={false}
      onEventDrop={props.onEventDrop}
      onEventResize={props.onEventResize}
      onDragStart={props.onDragStart}
      onSelectEvent={props.onSelectEvent}
      onNavigate={props.onNavigate}
      onSelectSlot={props.onSelectSlot}
      onRangeChange={props.onRangeChange}
      onShowMore={props.onShowMore}
    />
  );
}
PitstopCalendar.propTypes = {
  services: PropTypes.any,
  defaultView: PropTypes.string,
  views: PropTypes.any,
  minTime: PropTypes.any,
  maxTime: PropTypes.any,
  localizer: PropTypes.any,
  onSelectEvent: PropTypes.any,
  onSelectSlot: PropTypes.any,
  onNavigate: PropTypes.any,
  onRangeChange: PropTypes.any,
  date: PropTypes.any,
  defaultDate: PropTypes.any,
  onEventDrop: PropTypes.any,
  onEventResize: PropTypes.any,
  onDragStart: PropTypes.any,
  onShowMore: PropTypes.any,
  customCalendarStyle: PropTypes.any
};

export default PitstopCalendar;