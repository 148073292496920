import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { DealershipChooser } from 'components';
import { Form, Select, InputNumber, Input, Button, Card, Col, Row, Spin, notification } from 'antd';
import styled from 'styled-components';
import { observe } from 'mobx';
import { ShopStore } from 'stores';
import { get } from 'lodash';
import { Icon } from 'antd';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const { Option } = Select;

const H2Styled = styled.h2`
  font-size: 1.2rem;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .ant-input {
    margin-right: 20px;
  }

  .search-icon {
    font-size: 16px;
  }
`;

class AssetDurationPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  state = {
    isLoadingStatuses: false,
    isLoadingAssetDuration: false,
    assetDurationData: [],
    timePeriod: 30,
    limit: 20,
    status: 'running',
    statuses: [],
    selectedStatusDescription: '',
    searchText: '', // State for storing search input
  };

  componentDidMount() {
    this.loadStatuses();
  }

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
    }
  }

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.loadStatuses();
  });

  loadStatuses = async () => {
    try {
      this.setState({ isLoadingStatuses: true });

      const response = await ShopStore.getAssetDurationData(
        ShopStore.currentShop.id,
        30,
        20,
        'running',
        '' // Empty search text initially
      );

      const statuses = get(response, 'data.statuses', []);

      if (statuses.length > 0) {
        const firstStatus = statuses[0];
        this.setState(
          {
            statuses,
            status: firstStatus.car_status,
            selectedStatusDescription: firstStatus.status_description,
          },
          () => {
            this.loadDataForAssetDuration();
          }
        );
      } else {
        notification.warning({
          message: 'No Statuses Available',
          description: 'No available statuses found. Please try again later.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
    } finally {
      this.setState({ isLoadingStatuses: false });
    }
  };

  loadDataForAssetDuration = async () => {
    try {
      this.setState({ isLoadingAssetDuration: true });
      const { timePeriod, limit, status, searchText } = this.state;

      const response = await ShopStore.getAssetDurationData(
        ShopStore.currentShop.id,
        timePeriod,
        limit,
        status,
        searchText // Adding search text to the API call
      );

      const data = get(response, 'data.assets', []);

      if (Array.isArray(data) && data.length > 0) {
        this.setState({
          assetDurationData: data,
        });
      } else {
        notification.warning({
          message: 'No Data',
          description: 'No asset duration data available for the selected filters.',
        });
        this.setState({ assetDurationData: [] });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
      this.setState({ assetDurationData: [] });
    } finally {
      this.setState({ isLoadingAssetDuration: false });
    }
  };

  handleFilterChange = (changedFields) => {
    if (changedFields.status) {
      const selectedStatus = this.state.statuses.find(
        (status) => status.car_status === changedFields.status
      );
      this.setState({
        ...changedFields,
        selectedStatusDescription: selectedStatus ? selectedStatus.status_description : '',
      });
    } else {
      this.setState(changedFields);
    }
  };

  handleSearchTextChange = (e) => {
    this.setState({ searchText: e.target.value }, () => {
      this.loadDataForAssetDuration(); // Automatically call API when search text changes
    });
  };

  handleApplyFilters = () => {
    this.loadDataForAssetDuration();
  };

  render() {
    const { assetDurationData, timePeriod, limit, status, statuses, isLoadingStatuses, isLoadingAssetDuration, selectedStatusDescription, searchText } = this.state;

    const assetDurationChartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: assetDurationData.map(item => item.asset_name),
        crosshair: true,
        title: {
          text: 'Unit ID',
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'No. of Days',
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y;
            },
          },
        },
      },
      series: [
        {
          name: selectedStatusDescription || status.replace('-', ' '),
          data: assetDurationData.map(item => item.duration_in_days),
          color: '#183cbc',
        },
      ],
    };

    return (
      <Fragment>
        <Row gutter={[8, 8]} justify="space-between" align="middle">
          <Col span={20}>
            <h1>Asset Status Duration</h1>
          </Col>
        </Row>
        <DealershipChooser />
        {isLoadingStatuses ? (
          <Spin spinning={true}>
            <p>Loading statuses...</p>
          </Spin>
        ) : (
          <Form layout="inline" style={{ marginTop: '10px' }} onValuesChange={this.handleFilterChange}>
            <Form.Item label="Select Date Range">
              <Select
                defaultValue={30}
                value={timePeriod}
                onChange={(value) => this.handleFilterChange({ timePeriod: value })}
              >
                <Option value={30}>30 days</Option>
                <Option value={90}>90 days</Option>
                <Option value={180}>180 days</Option>
                <Option value={360}>360 days</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Select Status">
              <Select
                value={status}
                onChange={(value) => this.handleFilterChange({ status: value })}
              >
                {statuses.map(({ car_status, status_description }) => (
                  <Option key={car_status} value={car_status}>
                    {status_description}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Asset Limit">
              <InputNumber
                min={1}
                defaultValue={20}
                value={limit}
                onChange={(value) => this.handleFilterChange({ limit: value })}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={this.handleApplyFilters}>Apply Filters</Button> {/* New: Apply Filters button */}
            </Form.Item>
          </Form>
        )}
        <Row style={{ marginTop: '10px' }} gutter={[8, 8]}>
          <Col span={24}>
            <Spin spinning={isLoadingAssetDuration}>
              <Card>
                <Row gutter={[8, 8]} justify="space-between" align="middle">
                  <Col style={{ marginTop: '5px' }} span={4}>
                    <H2Styled>Asset Status Duration</H2Styled>
                  </Col>
                  <Col span={20}>
                    <SearchWrapper>
                        <Input
                          value={searchText}
                          onChange={this.handleSearchTextChange}
                          placeholder="Search Asset ID"
                          suffix={<Icon type="search" className="search-icon" />}
                          style={{ width: '20%' }}
                        />
                    </SearchWrapper>
                  </Col>
                </Row>
                <Row gutter={[8, 8]} style={{ marginTop: '10px' }}>
                  <Col span={24}>
                    {assetDurationData.length > 0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={assetDurationChartOptions}
                      />
                    ) : (
                      <p>No data available for the selected filters.</p>
                    )}
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default withRouter(observer(AssetDurationPage));
