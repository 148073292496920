import { decorate, action, transaction } from 'mobx';
import CurrentUserStore from './CurrentUserStore';
import ShopStore from './ShopStore';
import { webServiceProvider } from '../shared';
import AbstractStore from './abstract/AbstractStore';

class CampaignStoreC extends AbstractStore {
  MESSAGE = 'Loading in Campaign Details';

  getCampaignList = async (
    { offset, limit, filter, sort } = {},
    tableStore,
    shopId = ShopStore.currentShop.id
  ) => {
    if (tableStore.pending) {
      return;
    }
    tableStore.pending = true;
    tableStore.loaded = false;
    offset = offset === undefined ? tableStore.offset : offset;
    limit = limit === undefined ? tableStore.limit : limit;
    filter = filter || tableStore.filter;
    sort = sort === undefined ? tableStore.sort : sort;
    try {
      const params = {
        offset: offset / limit,
        limit,
        ...filter,
        shopId
      };

      if (sort) {
        params.sort = sort;
      } else {
        sort = undefined;
      }

      const {
        rows: data,
        totalPages: total
      } = await webServiceProvider.getMany('campaign', params);
      transaction(() => {
        tableStore.data.clear();
        for (let i = 0; i < data.length; i++) {
          this.data.set(offset * limit + i, {
            id: offset * limit + i,
            ...data[i]
          });
          tableStore.data.push(offset * limit + i);
        }
        tableStore.setDataMetadata(total * limit, offset, limit, sort, filter);
      });
    } catch (e) {
      console.error(e);
      tableStore.setError('Unable to load campaigns.');
    }
  };

  addCampaign = async (data, callback) => {
    data.idOwner = CurrentUserStore.user.id;
    data.shopId = ShopStore.currentShop.id;
    console.log(data);
    try {
      const result = await webServiceProvider.post('user/message', data);
      callback('success', result);
    } catch (e) {
      callback(e);
    }
  };
}

decorate(CampaignStoreC, {
  getCampaignList: action,
  addCampaign: action
});

const CampaignStore = new CampaignStoreC();
export default CampaignStore;
