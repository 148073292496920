import _ from 'lodash';

export const getPriorityCategory = priority => {
  if (_.isNil(priority)) return 'N/A';
  
  if (priority === -1) return 'Not Valid'

  if (priority === 2 || priority === 3) return 'Major';

  if (priority === 4 || priority === 5) return 'Critical';

  return 'Minor';
};

export const getPriorityTagColor = priority => {
  if (_.isNil(priority)) return '#c4c3c2';

  if (priority === -1) return '#c4c3c2'

  if (priority === 2 || priority === 3) return '#e28931';

  if (priority === 4 || priority === 5) return '#f92d2e';

  return '#dec431';
};

export const getStatusColor = status => {
  if (status === 'new') return 'cornflowerblue';

  return 'darkcyan';
};
