import React, { Component } from 'react';
import _ from 'lodash';

import { Input, Spin } from 'antd';

import AppStore from 'stores/App';

import { webServiceProvider } from 'shared';
import SymptomAnalyserChart from './SymptomAnalyserChart';

const { Search } = Input;

class SymptomAnalyser extends Component {
  state = {
    pending: false,
    loaded: false,
    data: []
  };

  getSearchResult = async value => {
    try {
      this.setState({
        pending: true,
        loaded: false
      });

      const results = await webServiceProvider.getMany('v1/labs/symptom', {
        search: String(value)
      });

      if (results[0].result) {
        this.setState({
          data: results[0]['Categories']
        });
      }
    } catch (e) {
      if (!_.isEmpty(value)) AppStore.addError('Error in getting report!');

      this.setState({
        data: []
      });
    } finally {
      this.setState({
        pending: false,
        loaded: true
      });
    }
  };

  render() {
    let { pending, loaded, data } = this.state;

    return (
      <div>
        <h1>Symptom Analyser</h1>
        <p style={{ fontsize: 14 }}>
          Type in an issue you are facing with your car and see which part most
          likely has a problem.
        </p>
        <Search
          placeholder="For example: 'smoke'"
          enterButton="Search"
          size="large"
          compact="true"
          onSearch={value => this.getSearchResult(value)}
          disabled={pending}
        />
        {pending && (
          <Spin style={{ marginTop: '24px' }} tip="Loading..." size="large" />
        )}

        {loaded && <SymptomAnalyserChart data={data} />}
      </div>
    );
  }
}

export default SymptomAnalyser;
