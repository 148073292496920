import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Alert } from 'antd';

import { PitstopButton } from 'shared';

const Wrapper = styled.div`
  button {
    margin-top: 20px;
  }
`;

class CampaignCreateCompleted extends Component {
  static propTypes = {
    history: PropTypes.object,
    onClick: PropTypes.func
  };

  render() {
    return (
      <Wrapper>
        <Alert
          message="Appointment Created Successfully!"
          type="success"
          showIcon
          description="Appointment was successfully created and has been sent out to your
          customer."
        />
        <PitstopButton
          onClick={() => {
            let currentPath = this.props.history.location.pathname;

            if (!currentPath.includes('/appointment')) {
              this.props.onClick();
            } else {
              this.props.history.push('/appointments');
            }
          }}
          type="primary"
        >
          View Appointments
        </PitstopButton>
      </Wrapper>
    );
  }
}

export default withRouter(CampaignCreateCompleted);
