import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import { Button, notification, Icon } from 'antd';
import { AppStore, WorkOrderStore, ShopStore } from 'stores';
import { PitstopTableCacheStore } from 'stores/CacheStore';

import CreateWorkOrderModal from 'containers/WorkOrder/modals/CreateWorkOrderModal';

const Container = styled.div`
  padding-right: 2vw;
`;

const CREATE_WORK_ORDER_MODAL_ID = 'CREATE_WORK_ORDER_MODAL_ID';

const CreateWorkOrderFromIssuesBtn = ({
  onFinish,
  title,
  type,
  shouldDisableButton,
  issues,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [seed, setSeed] = useState(1);

  const resetModalForm = () => {
    setSeed(Math.random());
  };

  const createWorkOrder = async (payload) => {
    try {
      const response = await WorkOrderStore.createWorkOrderOnCustomEndpoint({
        workOrder: {
          id_car: +payload.woSelectVehicle,
          id_shop: ShopStore.currentShop.id,
          status: payload.woStatus,
          type: payload.woRepairType,
          priority: payload.woRepairPriority,
          invoice_number: payload.woInvoiceNumber,
          assigned_to: +payload.woAssignedTo,
          created_by: +payload.woCreatedBy,
          migrate_source: 'PitStop',
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const saveWorkOrderLines = async (workOrder, lineItems = []) => {
    try {
      const formattedWorkOrderLines = WorkOrderStore.formatWorkOrderLines(
        lineItems
      );
      const payloadToSave = {
        workOrderLines: formattedWorkOrderLines,
      };
      const response = await WorkOrderStore.saveWorkOrderLinesOnCustomEndpoint(
        +workOrder.id,
        payloadToSave
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const createWorkOrderForEachVehicle = async (payload) => {
    const issuesGroupedByVehicles = _.groupBy(issues, 'car.id');
    const simpleArrayWithVehicleIssues = [];
    for (const vehicleId in issuesGroupedByVehicles) {
      simpleArrayWithVehicleIssues.push({
        vehicleId,
        issues: issuesGroupedByVehicles[vehicleId],
      });
    }

    const workOrders = [];
    for (const vehicleIssues of simpleArrayWithVehicleIssues) {
      const issues = vehicleIssues.issues;
      const workOrder = await createWorkOrder({
        woSelectVehicle: vehicleIssues.vehicleId,
        woStatus: payload.woStatus,
        woRepairType: payload.woRepairType,
        woRepairPriority: payload.woRepairPriority,
        woInvoiceNumber: payload.woInvoiceNumber,
        woAssignedTo: payload.woAssignedTo,
        woCreatedBy: payload.woCreatedBy,
      });
      await saveWorkOrderLines(workOrder, [
        {
          title: 'Issues',
          description: payload.woDescription,
          laborTotal: 0,
          partsTotal: 0,
          parts: [],
          labours: [],
          services: issues.map((issue) => ({
            id_vehicle_service: issue.id,
            key: issue.id,
            name: issue.name,
          })),
        },
      ]);
      workOrders.push(workOrder);
    }

    return workOrders;
  };

  const onSaveWorkOrder = async (payload) => {
    setIsSaving(true);
    try {
      const workOrdersCreated = await createWorkOrderForEachVehicle(payload);

      PitstopTableCacheStore.deleteKey(
        `${ShopStore.currentShop.id}-work-order-list`
      );

      const firstWorkOrder = _.first(workOrdersCreated);

      AppStore.openModals.set(CREATE_WORK_ORDER_MODAL_ID, false);
      setIsSaving(false);
      resetModalForm();
      WorkOrderStore.clearShopWorkOrdersCache();
      onFinish(firstWorkOrder);
    } catch (error) {
      let friendlyMsg =
        'Sorry, we had some problems to save the Work Order. Try again later';
      if (error && error.message && JSON.parse(error.message)) {
        const errorObj = JSON.parse(error.message);
        friendlyMsg = _.isArray(errorObj.message)
          ? errorObj.message[0]
          : errorObj.message;
      }
      notification.error({
        message: 'Oops!',
        key: 'orderSaveError',
        description: <Container>{friendlyMsg}</Container>,
      });
    }
  };

  return (
    <>
      <Button
        onClick={async () => {
          if (!_.isEmpty(issues)) {
            AppStore.openModals.set(CREATE_WORK_ORDER_MODAL_ID, true);
          }
        }}
        type={type}
        ghost
        disabled={shouldDisableButton}
      >
        <Icon type="book" />
        {title}
      </Button>

      <CreateWorkOrderModal
        key={seed}
        modalId={CREATE_WORK_ORDER_MODAL_ID}
        issues={issues}
        title={'Create Work Order'}
        isLoading={false}
        loading={isSaving}
        onSave={onSaveWorkOrder}
        type="issues"
      />
    </>
  );
};

CreateWorkOrderFromIssuesBtn.propTypes = {
  onFinish: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  issues: PropTypes.array.isRequired,
  shouldDisableButton: PropTypes.bool,
  isLoading: PropTypes.bool,
};

CreateWorkOrderFromIssuesBtn.defaultProps = {
  shouldDisableButton: false,
  type: 'primary',
  title: 'Create Work Order',
  onFinish: () => {},
  isLoading: false,
};

export default CreateWorkOrderFromIssuesBtn;
