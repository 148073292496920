import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import CarStore from 'stores/CarStore.js';

import RecipientForm from './RecipientForm';

const Wrapper = styled.div`
  h3 {
    margin-top: 16px;
  }
  i {
    margin-right: 8px;
  }
  hr {
    margin-bottom: 16px !important;
  }
`;

class AppointmentPreviewSection extends Component {
  render() {
    const {
      selectedCarId,
      startTimeAppointment,
    } = this.props;

    const car = CarStore.data.get(selectedCarId);

    const initialValue = {
      car,
      startTimeAppointment,
      attachReport: true,
    };

    return (
      <Wrapper>
        {/* BOOK FORM */}
        <RecipientForm
          showAppointmentTime={this.props.showAppointmentTime}
          setCallables={this.props.setCallables}
          initialValue={initialValue}
          onChangeValue={this.props.setValue}
        />
      </Wrapper>
    );
  }
}
AppointmentPreviewSection.propTypes = {
  showAppointmentTime: PropTypes.bool,
  startTimeAppointment: PropTypes.instanceOf(moment),
  endTimeAppointment: PropTypes.instanceOf(moment),
  selectedCars: PropTypes.array,
  description: PropTypes.string,
  selectedUser: PropTypes.number,
  selectedCarId: PropTypes.number,
  setValue: PropTypes.func.isRequired,
  setCallables: PropTypes.func,
};

AppointmentPreviewSection.defaultProps = {
  showAppointmentTime: true,
};

export default observer(AppointmentPreviewSection);
