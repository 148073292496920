import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';

class AlarmInfo extends PureComponent {
  render() {
    switch (this.props.alarm) {
      case 'Idling Time' || 'Idling':
        return (
          <Popover
            arrowPointAtCenter
            title="Alarm: Idling"
            content="This alarm gets triggered when your car is at the same location for a long period of time while the motor is turned on."
          >
            {this.props.render}
          </Popover>
        );
      case 'Hard Braking' || 'Braking':
        return (
          <Popover
            arrowPointAtCenter
            title="Alarm: Hard Braking"
            content="This alarm gets triggered when your car decelarates very fast."
          >
            {this.props.render}
          </Popover>
        );
      case 'High Speeding' || 'HighSpeedStart' || 'HighSpeedEnd':
        return (
          <Popover
            arrowPointAtCenter
            title="Alarm: High Speeding"
            content="This alarm gets triggered when the car went faster than 120 km/h."
          >
            {this.props.render}
          </Popover>
        );
      case 'Acceleration':
        return (
          <Popover
            arrowPointAtCenter
            title="Alarm: Acceleration"
            content="This alarm gets triggered when the car accelerated very fast and the RPM went above a certain level."
          >
            {this.props.render}
          </Popover>
        );
      default:
        return <div>{this.props.render}</div>;
    }
  }
}

AlarmInfo.propTypes = {
  alarm: PropTypes.string,
  render: PropTypes.element
};

export default AlarmInfo;
