import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import { Button, Table, Spin, TimePicker, Radio, Icon } from 'antd';

import AppStore from 'stores/App';
import CurrentUserStore from 'stores/CurrentUserStore';
import CarStore from 'stores/CarStore';

import { calcMileage } from 'helpers/unitCalculations';

const TableStyled = styled(Table)`
  margin-top: 15px;

  && .ant-table-body {
    margin: 0px;
  }
`;

const RadioGroup = styled(Radio.Group)`
  margin: 10px 0px;
`;

const RadioBtn = Radio.Button;

const CustomizeStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
`;

const TimeStyled = styled(TimePicker)`
  margin-right: 8px !important;
`;

class EmissionTable extends Component {
  static propTypes = {
    match: PropTypes.object,
    car: PropTypes.object,
    showAnalytics: PropTypes.bool,
  };

  state = {
    startDate: this.props.startDate,
    endDate: this.props.endDate,
    // tripDataType: 'customize',
    refresh: false,
  };

  get carId() {
    let { match, car } = this.props;

    return match ? match.params.id : car.id;
  }

  get emission() {
    return CarStore.data.get(this.carId).emission;
  }

  get dataSource() {
    let { startDate } = this.state;
    let { data } = this.emission;

    return [
      {
        key: '1',
        emission: 'CO2',
        total: !_.isNil(data.CO2.total)
          ? Number(data.CO2.total).toReadable()
          : 'NA',
        distance: !_.isNil(data.distance)
          ? calcMileage(data.distance).toReadable()
          : 'NA',
        color:
          this.props.vin === '3C4NJDDB1JT169728' &&
          moment(startDate) / 1000 === 1573237908 &&
          '#ffe58f',
      },
      {
        key: '2',
        emission: 'CO',
        total: !_.isNil(data.CO.total)
          ? Number(data.CO.total).toReadable()
          : 'NA',
        distance: !_.isNil(data.distance)
          ? calcMileage(data.distance).toReadable()
          : 'NA',
      },
      {
        key: '3',
        emission: 'C6H14',
        total: !_.isNil(data.C6H14.total)
          ? Number(data.C6H14.total).toReadable()
          : 'NA',
        distance: !_.isNil(data.distance)
          ? calcMileage(data.distance).toReadable()
          : 'NA',
      },
      {
        key: '4',
        emission: 'NO',
        total: !_.isNil(data.NO.total)
          ? Number(data.NO.total).toReadable()
          : 'NA',
        distance: !_.isNil(data.distance)
          ? calcMileage(data.distance).toReadable()
          : 'NA',
        color:
          this.props.vin === '3C4NJDDB1JT169728' &&
          moment(startDate) / 1000 === 1573230473 &&
          '#ffe58f',
      },
    ];
  }

  get columns() {
    let odometer = CurrentUserStore.user.displayedOdometer;

    return [
      {
        title: 'Emission',
        dataIndex: 'emission',
        key: 'emission',
        render(text, record) {
          return {
            props: {
              style: { background: record.color },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: 'Total [g]',
        dataIndex: 'total',
        key: 'total',
        render(text, record) {
          return {
            props: {
              style: { background: record.color },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: `Distance (${odometer})`,
        dataIndex: 'distance',
        key: 'distance',
        render(text, record) {
          return {
            props: {
              style: { background: record.color },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: `Rate [g/${odometer}]`,
        key: 'rate',
        render(record) {
          let rate = Number(record.total) / Number(record.distance);
          let data = rate === Infinity || _.isNaN(rate) ? 0 : rate.toFixed(3);

          return {
            props: {
              style: { background: record.color },
            },
            children: <div>{data}</div>,
          };
        },
      },
    ];
  }

  async componentDidMount() {
    if (this.props.showEmissionTable) {
      await this.loadData();
    }
  }

  loadData = async (replace = true) => {
    let { vin } = this.props.car;
    let { startDate, endDate } = this.state;

    // if (tripDataType === 'full') {
    //   startDate = this.props.startDate;
    //   endDate = this.props.endDate;
    // }

    try {
      this.emission.reset();

      if (this.props.showEmissionTable) {
        await CarStore.data
          .get(this.carId)
          .getEmissionData(vin, startDate, endDate, replace);

        this.setState({ refresh: false });
      }
    } catch (err) {
      AppStore.addError(`Error in loading emission data: ${err.message}`);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.startDate.isSame(this.props.startDate) ||
      !prevProps.endDate.isSame(this.props.endDate)
    ) {
      let { startDate, endDate } = this.props;

      this.setState(
        {
          // tripDataType: 'full',
          startDate,
          endDate,
        },
        async () => await this.loadData()
      );
    }

    if (
      (prevState.refresh !== this.state.refresh && this.state.refresh) ||
      (prevProps.showEmissionTable !== this.props.showEmissionTable &&
        !this.emission.loaded)
    ) {
      await this.loadData();
    }
  }

  getData = () => {
    if (
      this.state.startDate < this.props.startDate ||
      this.state.endDate > this.props.endDate ||
      this.state.endDate < this.state.startDate
    ) {
      AppStore.addError('Please select a time in the range of trip time!');
    } else if (
      this.state.endDate.diff(this.state.startDate, 'minutes') >
      12 * 60
    ) {
      AppStore.addError('Data is limited up to 3 hours. Please select again!');
      return;
    } else {
      this.setState({ refresh: true });
    }
  };

  getTripTimeRange = () => {
    let { startDate, endDate } = this.state;

    return (
      <RadioGroup
        defaultValue="full"
        value={this.state.tripDataType}
        buttonStyle="solid"
        onChange={(event) =>
          this.setState({
            tripDataType: event.target.value,
            refresh:
              startDate === this.props.startDate &&
              endDate === this.props.endDate
                ? false
                : true,
          })
        }
      >
        <RadioBtn value="full">Full Trip data</RadioBtn>
        <RadioBtn value="customize">Customize Trip data</RadioBtn>
      </RadioGroup>
    );
  };

  getCustomizeTripData = () => {
    let { startDate, endDate } = this.state;

    return (
      <>
        <CustomizeStyled>
          <TimeStyled
            onChange={(value) => this.setState({ startDate: value })}
            value={moment(startDate)}
            allowClear={false}
            format="h:mm A"
            use12Hours
          ></TimeStyled>
          <TimeStyled
            onChange={(value) => this.setState({ endDate: value })}
            value={moment(endDate)}
            allowClear={false}
            format="h:mm A"
            use12Hours
          ></TimeStyled>
          <Button type="primary" onClick={this.getData}>
            Get data
          </Button>
        </CustomizeStyled>
        {this.props.endDate.diff(this.props.startDate, 'minutes') > 12 * 60 && (
          <div style={{ display: 'flex' }}>
            <Icon
              style={{ fontSize: 12, margin: '4px 4px 0px 0px' }}
              type="info-circle"
            />
            <i>Data is limited up to 12 hours</i>
          </div>
        )}
      </>
    );
  };

  render() {
    let { startDate, endDate } = this.state;

    // if (tripDataType === 'full') {
    //   startDate = this.props.startDate;
    //   endDate = this.props.endDate;
    // }

    if (this.props.showEmissionTable) {
      if (!this.emission.loaded) {
        return (
          <Spin
            style={{
              width: this.props.showAnalytics ? '20%' : '100%',
              height: '300px',
            }}
            tip={`Calculating emissions data from ${moment(startDate).format(
              'h:mm A'
            )} to ${moment(endDate).format('h:mm A')}`}
          ></Spin>
        );
      }
      return (
        <>
          <div
            style={{
              width: this.props.showAnalytics ? '20%' : '100%',
              height: 'fit-content',
            }}
          >
            {this.getCustomizeTripData()}

            <p>
              Emissions data from{' '}
              <b>{`${moment(startDate).format('h:mm A')}`}</b> to{' '}
              <b>{`${moment(endDate).format('h:mm A')}`}</b>
            </p>
            <TableStyled
              dataSource={this.dataSource}
              columns={this.columns}
              pagination={false}
              size="small"
            ></TableStyled>
          </div>
        </>
      );
    }
    return <></>;
  }
}

EmissionTable.propTypes = {
  vin: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  showEmissionTable: PropTypes.bool,
};

decorate(EmissionTable, {
  data: computed,
});

export default observer(EmissionTable);
