import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CurrentUserStore, ShopStore, UserStore } from 'stores';
import OktaAuthService from './OktaAuthService';
import { notification, Spin, Form } from 'antd';
import styled from 'styled-components';

const Container = styled(Form)`
  padding-right: 7vw;
  h1 {
    font-size: 2.8rem;
    font-weight: 600;
    color: #000000;
  }
  p {
    font-weight: 400;
    color: #8c8c8c;
    opacity: 0.9;
  }
  label {
    font-weight: 600;
    display: block;
  }
  input {
    margin: 1.4vh 0vh;
  }
`;

const LoadingScreen = styled.div`
  margin: auto;
  text-align: center;
`;

const OktaCallback = () => {
  const history = useHistory();
  const [loadingText , setLoadingText] = useState(false)

  const createUser = async (user) => {
    // create the user
    try {
      const userCreated = await UserStore.createUser(user);
      return userCreated;
    } catch (err) {
      console.log(err)
      throw new Error("Error in creating user");
    }
  }

  const loginUser = async (email, password) => {
    try {
      let user = await CurrentUserStore.login(email, password);
      return user;
    } catch (err) {
      console.log(err)
      throw new Error("Error in login user");
    }
  }

  // First useEffect to handle with the time interval
  useEffect(() => {
    const timer = setInterval(() => {
      setLoadingText((prev) => !prev);
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  // Second useEffect to handle with user functions
  useEffect(() => {
    async function handleOKTALoginSteps() { 
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get('code');
        sessionStorage.setItem('OktaAuthCode', authCode);

        if (authCode) {
          try {
            const tokenResponse = await OktaAuthService.getToken(authCode);
            sessionStorage.setItem('OktaToken', JSON.stringify(tokenResponse));

            const userInfo = await OktaAuthService.getUserInfo(tokenResponse.access_token);
            sessionStorage.setItem('OktaUserInfo', JSON.stringify(userInfo));
          
            // User Onboard
            const password = '0oahsr75dcB6E5h7T5d7';
            const email = userInfo.email;

            const newUser = {
              shopId: 1369, // Tim Hortons Fleet
              inviterId: 448,
              firstName: userInfo.given_name,
              role: "dealership",
              shopRole: "fleetManager",
              lastName: userInfo.family_name,
              phone: undefined,
              email,
              username: userInfo.email,
              password,
              isSendCredentialsByPhone: false
            }
  
            try {
              ShopStore.reset();
              const user = await loginUser(email, password);
              if (user) { //User Already Exists
                notification.open({
        
                  key: 'login',
                  description: (
                    <>
                      <Spin size="large" style={{ marginRight: '0.6rem' }} />
                      You are logging into Pitstop dashboard...
                    </>
                  ),
                  duration: 0,
                });
        
                await CurrentUserStore.getCurrentUser();
              } else { // Create a new User
        
                notification.open({
                  key: 'login',
                  description: (
                    <>
                      <Spin size="large" style={{ marginRight: '0.6rem' }} /> 
                      Your account is being configurated...
                    </>
                  ),
                  duration: 0,
                });
        
                const newOktaUser = await createUser(newUser);
                if(newOktaUser) {
                  await loginUser(email,password);
                  await CurrentUserStore.getCurrentUser();
                } else {
                  throw new Error("Error in login user");
                }
              }
            } catch (err) {
              notification.error({
                message: 'Error',
                key: 'error',
                description: <Container>{err.message}</Container>,
              });
              throw err;
            } finally {
              notification.close('login');
            }

          } catch (error) {
            console.error('Error handling callback:', error);
            throw error;
          }
        } else {
          const oktaAuthError = new Error('Error in getting auth code');
          throw oktaAuthError;
        }

      history.push('/'); // Redirect to home or any other route after processing

      } catch (err) {
        console.error('Error handling callback:', err);
        history.push('/login'); // Redirect to home or any other route after processing
      }
    }
    handleOKTALoginSteps();

  }, [history]);

  return (
    <LoadingScreen>
      <div>
        <h1>Welcome to Pitstop Dashboard</h1>
        {// switch between texts to show different loading messages
        loadingText ? (
          <p>
            Thank you for waiting while our AI Flywheel is hard at work
            analyzing your data!
          </p>
        ) : (
          <p>
            Did You Know? Pitstop's software analyzes over 30 Billion data
            points!
          </p>
        )}
        <Spin />
      </div>
    </LoadingScreen>
  )
};

export default OktaCallback;