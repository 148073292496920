import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Scrollbars } from 'react-custom-scrollbars';

const CustomScrollbars = ({ conversationData, ...props }) => {
  const scrollbars = useRef();
  const scrollToBottom = () => scrollbars.current.scrollToBottom();

  useEffect(() => {
    scrollToBottom();
  }, [conversationData]);

  return (
    <Scrollbars
      ref={scrollbars}
      {...props}
      renderTrackHorizontal={props => (
        <div
          {...props}
          style={{ display: 'none' }}
          className="track-horizontal"
        />
      )}
    />
  );
};

CustomScrollbars.propTypes = {
  conversationData: PropTypes.array
};

export default CustomScrollbars;
