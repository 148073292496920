import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Tag, Card, Result, Row, Col } from 'antd';

import { PitstopButton, webServiceProvider } from 'shared';
import { calcFuel, calcMileage } from 'helpers/unitCalculations';

import CarObject from 'stores/Classes/CarObject';
import CarStore from 'stores/CarStore';
import AppStore from 'stores/App';
import CurrentUserStore from 'stores/CurrentUserStore';

import { Loading } from 'components';
import Utilities from 'containers/CarProfile/CarTripsView/Utilities';
import TripObject from 'stores/Classes/TripObject';

class ShareTripPage extends Component {
  state = { error: false, trip: undefined, loaded: false };

  async componentDidMount() {
    await CarStore.getCarById(this.carId);
    await this.fetchTripData(this.tripId);
  }

  fetchTripData = async (id) => {
    try {
      let data = await webServiceProvider.getMany(`v1/trip/${Number(id)}`);

      let trip = new TripObject(data.response);
      this.setState({ trip, loaded: true });
    } catch (e) {
      this.setState({ error: true, loaded: true });
      AppStore.addError('unable to get trip data');
    }
  };

  get tripId() {
    return Number(this.props.match.params.tripId);
  }

  get carId() {
    return Number(this.props.match.params.id);
  }

  get car() {
    return CarStore.data.get(this.carId);
  }

  get tripMileage() {
    let { trip } = this.state;

    if (trip.mileageAccum && parseFloat(trip.mileageAccum) > 0)
      return `${Math.round(
        calcMileage(parseFloat(trip.mileageAccum))
      ).toReadable()} ${CurrentUserStore.user.displayedOdometer}`;

    return 'N/A';
  }

  get fuelConsumption() {
    let { trip } = this.state;
    let car = CarStore.data.get(this.carId);
    if (trip.fuelConsumptionAccum && parseFloat(trip.fuelConsumptionAccum) > 0)
      return `${parseFloat(calcFuel(trip.fuelConsumptionAccum, car.scanner.scannerId)).toFixed(1)} ${
        CurrentUserStore.user.settings.fuelUnit
      }`;

    return 'N/A';
  }

  render() {
    let { trip, error, loaded } = this.state;

    if (!loaded) return <Loading />;

    if (error) {
      return (
        <Result
          status="warning"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      );
    }

    return (
      <>
        <Card style={{ marginBottom: 25 }}>
          <Row>
            <Col lg={14} sm={24}>
              <h4>
                {`Trip from ${moment(trip.timeStart * 1000).format(
                  'MMM DD, YYYY'
                )} ${moment(trip.timeStart * 1000).format(
                  'h:mm A'
                )} -  ${moment(trip.timeEnd * 1000).format('h:mm A')}`}
              </h4>
            </Col>

            <Col lg={10} sm={24}>
              <p>
                {`Mileage: ${this.tripMileage} - Fuel Consumption: 
                ${this.fuelConsumption} - Trip Type: `}
                <Tag>{trip.type ? trip.type.capitalize() : 'N/A'}</Tag>
              </p>
            </Col>
          </Row>
        </Card>

        <Row>
          <Col style={{ marginBottom: 15 }} span={24}>
            <PitstopButton
              onClick={() => this.props.history.push(`/car/${this.carId}`)}
            >
              View All Vehicle Trips
            </PitstopButton>
          </Col>
          <Col span={24}>
            <Utilities selectedTrip={trip} car={this.car} loaded={loaded} />
          </Col>
        </Row>
      </>
    );
  }
}

ShareTripPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  car: PropTypes.instanceOf(CarObject),
};

export default withRouter(observer(ShareTripPage));
