import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Row, Col } from 'antd';

import { RegisterForm, AuthSliderBackground } from 'v3/components/forms/auth';

const Container = styled.div`
  height: 100%;
  width: 100%;

  opacity: ${(props) => props.isRegister && 0.8};
  pointer-events: ${(props) => props.isRegister && 'none'};
`;

const RegisterFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  padding: 2vw 6vw;

  #register-form {
    margin: auto;
  }

  #login-text {
    color: #02103d;
    text-align: right;

    a {
      font-weight: 600;
      color: #02103d;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;

    #login-text {
      margin: 5vw 0vw;
      text-align: left;
    }
  }
`;

class RegisterPage extends Component {
  state = {
    isRegister: false,
  };

  render() {
    const { isRegister } = this.state;

    return (
      <Container isRegister={isRegister}>
        <Row type="flex" style={{ height: '100%' }}>
          <Col md={14} xs={24}>
            <AuthSliderBackground />
          </Col>

          <Col md={10} xs={24}>
            <RegisterFormContainer>
              <Row id="login-text">
                Already a member?{' '}
                <Link style={{}} to="/login">
                  Login
                </Link>
              </Row>

              <RegisterForm
                isUserRegister={(isRegister) => {
                  this.setState({
                    isRegister: isRegister,
                  });
                }}
              />
            </RegisterFormContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default RegisterPage;
