import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Row, Col } from 'antd';

class ContentRow extends Component {
  static propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.node
    ]),
    show: PropTypes.bool
  };

  render() {
    let { show, value } = this.props;

    if (!_.isNil(value) && show) {
      return (
        <Row gutter={[16, 16]}>
          <Col xl={5} sm={7} xs={24}>
            <strong>{this.props.name}</strong>
          </Col>
          <Col xl={19} sm={17} xs={24}>
            {this.props.value}
          </Col>
        </Row>
      );
    }
    return <></>;
  }
}

export default ContentRow;
