import * as d3ScaleChromatic from 'd3-scale-chromatic';

const colorRangeInfo = {
  colorStart: 0,
  colorEnd: 1,
  useEndAsStart: false
};

let calculatePoint = (i, intervalSize) => {
  var { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
  return useEndAsStart
    ? colorEnd - i * intervalSize
    : colorStart + i * intervalSize;
};

// create array of different colors based on the number of items
export let interpolateColors = dataLength => {
  var { colorStart, colorEnd } = colorRangeInfo;
  var colorRange = colorEnd - colorStart;
  var intervalSize = colorRange / dataLength;
  var i, colorPoint;
  var colorArray = [];

  for (i = 0; i < dataLength; i++) {
    colorPoint = calculatePoint(i, intervalSize);
    colorArray.push(d3ScaleChromatic.interpolateRainbow(colorPoint));
  }

  return colorArray;
};
