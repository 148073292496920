import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Upload, Icon, Row, Col, Avatar } from 'antd';

import { webServiceProvider } from 'shared';

import AppStore from 'stores/App';
import ShopStore from 'stores/ShopStore';

const Dragger = Upload.Dragger;

const StyledAvatar = styled(Avatar)`
  &&& {
    width: 100%;
    height: 200px;
    margin: auto;
    border: 1px solid #d9d9d9;
    background: #fafafa;

    span {
      width: 100%;
      height: 100%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: scale-down;
    }
  }
`;

class EditImageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      previewImage: '',
      previewVisible: false,
      mediaUrl: '',
      showImage: false
    };

    this.beforeFileUpload = this.beforeFileUpload.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  defaultShopLogoUrl =
    'https://dashboard-campaign.s3.us-east-2.amazonaws.com/plogo.svg';

  beforeFileUpload = file => {
    this.setState({ fileList: [file] }, () => {
      this.onDrop();
    });
  };

  onDrop = async () => {
    const fd = new FormData();
    fd.append('singleInputFileName', this.state.fileList[0]);

    try {
      AppStore.addLoading('Uploading image...');

      const data = await webServiceProvider.postFormData(
        'dashboard/v1/uploadShopImage?shopId=' + ShopStore.currentShop.id,
        fd
      );
      AppStore.addSuccess(
        `Image ${this.state.fileList[0].name} uploaded successfully!`
      );
      ShopStore.data.get(ShopStore.currentShop.id).logoUrl = data.fileUrl;
      ShopStore.currentShop.logoUrl = data.fileUrl;
    } catch (e) {
      AppStore.addError(
        `Image ${this.state.fileList[0].name} uploaded failed!`
      );
    } finally {
      AppStore.removeLoading('Uploading image...');
    }
  };

  render() {
    let shopLogo = ShopStore.currentShop.logoUrl || this.defaultShopLogoUrl;

    return (
      <>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Dragger
              showUploadList={false}
              name="file"
              multiple={false}
              onCancel={this.removeFile}
              beforeUpload={this.beforeFileUpload}
              onRemove={this.removeFile}
              fileList={this.state.fileList}
              onPreview={this.handlePreview}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </Col>

          <Col span={24}>
            {ShopStore.currentShop.logoUrl && (
              <StyledAvatar shape="square" alt="example" src={shopLogo} />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

export default observer(EditImageForm);
