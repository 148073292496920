import React from 'react';
import { Table, Spin, Col, Button, Checkbox, Row, Input, Tag, Popover, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from 'styled-components';
import { IssueStore, CurrentUserStore } from 'stores';
import MarkAsCompleteBtn from 'containers/IssueProfile/MarkAsCompleteBtn'; 
import CreateWorkOrderFromIssuesBtn from 'containers/IssueProfile/CreateWorkOrderFromIssuesBtn';
import IntervalSelectorModel from '../../containers/ServiceInterval/IntervalSelectorModal'; 

// Styled components
const NextPMPercentageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .percentage {
    font-size: 20px;
  }
  .description {
    font-size: 12px;
  }
  .ant-tag {
    border-radius: 100px;
    background-color: white;
    margin-top: 5px;
    font-size: 12px;
  }
`;

const FilterDropdownWrapper = styled.div`
  padding: 8px;
  .filter-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
  .checkbox-group {
    display: flex;
    flex-direction: column;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;

  .button-wrapper {
    margin-right: 10px; /* Add space between the buttons */
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .ant-input {
    margin-right: 20px;
  }

  .search-icon {
    font-size: 16px;
  }
`;

// Filter Dropdown Component for Next PM Status
class NextPMFilterDropdown extends React.Component {
  handleFilter = () => {
    const { confirm, setFilters, selectedKeys } = this.props;
    confirm();
    setFilters((prev) => ({
      ...prev,
      nextPM: selectedKeys,
      pm_status: selectedKeys.length > 0 ? selectedKeys : undefined,
    }));
  };

  handleReset = () => {
    const { clearFilters, setFilters, setSelectedKeys } = this.props;
    
    // Reset selectedKeys to an empty array
    setSelectedKeys([]);
    
    // Clear filters
    clearFilters();
  
    // Update filters in the parent component to reset the filter
    setFilters((prev) => ({
      ...prev,
      nextPM: [],
      pm_status: undefined,  // Ensure pm_status is undefined
    }));
  };

  render() {
    const { selectedKeys, setSelectedKeys } = this.props;

    return (
      <FilterDropdownWrapper>
        <Checkbox.Group
          className="checkbox-group"
          options={[
            { label: 'Overdue', value: 'Overdue' },
            { label: 'Due Soon', value: 'Due soon' },
            { label: 'No action needed', value: 'No action needed' },
          ]}
          value={selectedKeys}
          onChange={(checkedValues) => setSelectedKeys(checkedValues)}
        />
        <div style={{ borderTop: '1px solid #d9d9d9', marginBottom: '8px', marginTop: '8px' }}></div>  
        <div className="filter-buttons">
          <Button type="primary" onClick={this.handleReset} size="small">
            Reset
          </Button>
          <Button onClick={this.handleFilter} size="small">
            OK
          </Button>
        </div>
      </FilterDropdownWrapper>
    );
  }
}

// Filter Dropdown Component for Service Type
class ServiceTypeFilterDropdown extends React.Component {
  handleFilter = () => {
    const { confirm, setFilters, selectedKeys } = this.props;
    confirm();
    setFilters((prev) => ({
      ...prev,
      serviceType: selectedKeys,
    }));
  };

  handleReset = () => {
    const { clearFilters, setFilters, setSelectedKeys } = this.props;

    setSelectedKeys([]);
    clearFilters();
    setFilters((prev) => ({
      ...prev,
      serviceType: [],
    }));
  };

  render() {
    const { selectedKeys, setSelectedKeys } = this.props;

    return (
      <FilterDropdownWrapper>
        <Checkbox.Group
          className="checkbox-group"
          options={[
            { label: 'PM', value: false },
            { label: 'Safety Inspection', value: true },
          ]}
          value={selectedKeys}
          onChange={(checkedValues) => setSelectedKeys(checkedValues)}
        />
        <div style={{ borderTop: '1px solid #d9d9d9', marginBottom: '8px', marginTop: '8px' }}></div>
        <div className="filter-buttons">
          <Button type="primary" onClick={this.handleReset} size="small">
            Reset
          </Button>
          <Button onClick={this.handleFilter} size="small">
            OK
          </Button>
        </div>
      </FilterDropdownWrapper>
    );
  }
}

// Next PM Display Component (unchanged)
class NextPMWrapper extends React.Component {
  getColor = () => {
    const { pm_status, safetyInspection, safety_inspection_flag } = this.props;
    switch (pm_status) {
      case 'Overdue':
        return '#FF202F';
      case 'Due soon':
        return '#FF812A';
      case 'No action needed':
        return safetyInspection || safety_inspection_flag ? '#52c41a' : '#000000';
      default:
        return '#333333';
    }
  };

  render() {
    const {
      percent,
      pm_status,
      due_time_in_s,
      rule_type,
      due_engine_hours,
      engineHours,
      due_mileage,
      totalMileage,
      safetyInspection,
      safety_inspection_flag
    } = this.props;

    let displayValue;
    let displayText;
    if (rule_type === 'time') {
      const now = new Date();
      const dueAt = moment.unix(due_time_in_s);
      const diffInDays = dueAt.diff(now, 'days');
      const diffInMonths = Math.ceil(diffInDays / 30.4375);

      if (diffInMonths < 1) {
        displayValue = Math.abs(diffInDays);
        displayText = 'day(s)';
      } else {
        displayValue = Math.abs(diffInMonths);
        displayText = 'month(s)';
      }

      if (safetyInspection) {
        const dueInDays = moment.unix(due_time_in_s).diff(moment(), 'days');
        const overdueDays = moment().diff(moment.unix(due_time_in_s), 'days');

        const overdueOrDueIn = due_time_in_s
          ? overdueDays > 0
            ? overdueDays
            : dueInDays
          : null;

        displayValue = overdueOrDueIn;
        displayText = 'days';
      }
    } else {
      const hoursRemainingValue =
        rule_type === 'engine_hours'
          ? Math.ceil(
              (isNaN(Number(due_engine_hours)) || isNaN(Number(engineHours))
                ? 0
                : Math.abs(Number(due_engine_hours) - Number(engineHours)) / 3600)
            )
          : null;
          
          const kmRemaining =
          rule_type === 'mileage'
            ? Math.ceil(
                isNaN(Number(due_mileage)) && isNaN(Number(totalMileage))
                  ? undefined
                  : Math.abs(Number(due_mileage) - Number(totalMileage))
              )
            : undefined;
    
        const milesRemaining = kmRemaining
          ? Math.ceil(kmRemaining / 1.60934)
          : undefined;
    
        const userSettings = CurrentUserStore.user.settings;
        const milesOrKm =
          userSettings?.odometer === 'km' ? kmRemaining : milesRemaining;
        const milesOrKmText = userSettings?.odometer === 'km' ? 'km' : 'miles';
        displayText = milesOrKm !== undefined ? milesOrKmText : 'engine hours';
        const milesOrEngineHours =
          milesOrKm !== undefined ? milesOrKm : hoursRemainingValue;
        // force it to always display positive numbers
        displayValue = Math.abs(milesOrEngineHours);
    }

    return (
      <NextPMPercentageWrapper>
        <span className="percentage" style={{ color: this.getColor() }}>
          {Math.max(0, Math.floor(percent))}%
        </span>
        <span className="description" style={{ color: this.getColor() }}>
          { safetyInspection || safety_inspection_flag ? `In Compliance` : pm_status}
        </span>
        <span className="description">
          {pm_status === 'Overdue' ? 'Overdue By' : 'In'} {displayValue}{' '}
          {displayText}
        </span>
      </NextPMPercentageWrapper>
    );
  }
}

// Main PMTable Component
class PMTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      currentPage: 1,
      pageSize: props.carId ? 5 : 8, // Set page size based on whether carId is present
      total: 0,
      filters: {},
      sort: [],
      selectedRows: [],
      selectedRowKeys: [],
      searchValue: '',
      popoverVisible: false,
      safetyInspection: this.props.isSafetyInspection || false,
      safetyInspectionFilter: null,
      filtersApplied: false
    };
  }

  componentDidMount() {
    this.fetchData(this.state.currentPage, this.state.pageSize, this.state.filters, this.state.sort);
  }

  fetchData = async (page, pageSize, appliedFilters, appliedSort) => {
    try {
      this.setState({ loading: true });
      let safetyInspection = this.state.safetyInspection;
      // Set the safetyInspection flag based on filter selection (true for Safety Inspection, false for PM)
      if (!this.props.carId) {
        safetyInspection = this.state.safetyInspectionFilter !== null 
        ? this.state.safetyInspectionFilter 
        : this.state.safetyInspection;
      }
      

      const sortParam = appliedSort.length > 0 ? appliedSort.map(s => `${s.ascending ? '' : '-'}${s.key}`) : '';
      const result = await IssueStore.getPmsForNewTable(
        {
          offset: (page - 1) * pageSize,
          limit: pageSize,
          filter: { status: 'new', source: 'routine', pm_status: appliedFilters.pm_status, ...appliedFilters },
          sort: sortParam,
          search: this.state.searchValue,
          safetyInspection: safetyInspection
        },
        this.props.carId || undefined, // Send undefined instead of null if carId is not provided
        false,
        false,
      );

      if (result && result.data) {
        const mappedData = result.data.map((item, index) => ({
          key: item.id || index.toString(),
          ...item,
          serviceName: item.name,
          description: item.description || '--',
          safety_inspection_flag: item.safety_inspection_flag, // Include safety_inspection_flag
          serviceIntervals: {
            interval_month: item.routineInfo?.interval_month || null,
            interval_mileage: item.routineInfo?.interval_mileage || null,
            interval_engine_hours: item.routineInfo?.interval_engine_hours || null,
          },
          nextPMDue: {
            ...item,
            percent: item.active_pm_percent,
            pm_status: item.active_pm_status,
            dueMessages: [{ message: `In ${item.dueMileage || '--'} miles` }],
          },
          lastPMDone: item.last_pm_done_date,
        }));
        this.setState({
          data: mappedData,
          total: parseInt(result.meta?.total), // Ensure total count is updated
          loading: false,
        });
      }
    } catch (error) {
      console.error('Error fetching PM data:', error);
      this.setState({ loading: false });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const serviceTypeFilter = filters.serviceType;
    let safetyInspectionFilter = null; // Default is null, meaning no filter applied
    if (serviceTypeFilter) {
      safetyInspectionFilter = serviceTypeFilter[0] === true ? true : false; // Set based on selection
    }
    this.setState(
      {
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
        sort: sorter.field
          ? [{ key: sorter.field, ascending: sorter.order === 'ascend' }]
          : [],
        filters,
        filtersApplied: !!(filters.serviceType?.length || filters.nextPM?.length),
        safetyInspectionFilter
      },
      () => {
        this.fetchData(this.state.currentPage, this.state.pageSize, filters, this.state.sort);
      }
    );
  };

  handleClearFilters = () => {
    this.setState(
      {
        filters: { serviceType: [], nextPM: [] }, // Reset filters
        filtersApplied: false, // No filters applied now
      },
      () => {
        this.fetchData(this.state.currentPage, this.state.pageSize, this.state.filters, this.state.sort);
      }
    );
  };

  handleSearchChange = (e) => {
    this.setState({ searchValue: e.target.value }, () => {
      this.fetchData(this.state.currentPage, this.state.pageSize, this.state.filters, this.state.sort);
    });
  };

  refreshVehiclesList = () => {
    if (this.props.onIssuesListChange) {
      this.props.onIssuesListChange();
    }
  };

  renderServiceTypeColumn = (safetyInspectionFlag) => {
    if (safetyInspectionFlag) {
      return <Tag color="gold"><span style={{color:'#956B18'}}>Safety Inspection</span></Tag>;
    }
    return <Tag color="blue">PM</Tag>;
  };
  

  render() {
    const { data, loading, currentPage, pageSize, total, selectedRows, selectedRowKeys, popoverVisible , filtersApplied} = this.state;
    const { carId } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRows, selectedRowKeys });
      },
    };

    const columns = [];

    // Conditionally add Service Type column if carId is null
    if (!carId) {
      columns.push({
        title: 'Service Type',
        dataIndex: 'safety_inspection_flag',
        key: 'serviceType',
        sorter: true,
        filterDropdown: (props) => (
          <ServiceTypeFilterDropdown {...props} setFilters={(newFilters) => this.setState({ filters: newFilters })} />
        ),
        onFilter: (value, record) => record.safety_inspection_flag === value,
        render: (safetyInspectionFlag) => this.renderServiceTypeColumn(safetyInspectionFlag),
      });

      // New Assets Column
      columns.push({
        title: 'Assets',
        dataIndex: 'car',
        key: 'assets',
        sorter: true,
        render: (car) => car?.name || '--',
      });
    }

    columns.push(
      {
        title: 'Service Name',
        dataIndex: 'serviceName',
        key: 'serviceName',
        sorter: true,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text) => (text ? text : '--'),
        sorter: true,
      },
      {
        title: 'Service Intervals',
        dataIndex: 'serviceIntervals',
        key: 'serviceIntervals',
        render: (intervals) => {
          const { interval_month, interval_mileage, interval_engine_hours } = intervals;
          const userSettings = CurrentUserStore.user.settings;
          const milesOrKm = userSettings?.odometer === 'km' ? "Km" : 'Miles';
          const intervalMileage = interval_mileage != null 
                ? userSettings?.odometer === 'km' 
                  ? interval_mileage.toLocaleString() 
                  : (Math.round(interval_mileage  / 1.60934)).toLocaleString() 
                : null;
          
          return (
            <>
              {interval_month && <div>Time: Every {interval_month} Months</div>}
              {
              interval_mileage && <div>Mileage: Every {intervalMileage} {
               milesOrKm
              }
              </div>
              }
              {interval_engine_hours && <div>Engine Hours: Every {interval_engine_hours} Hours</div>}
            </>
          );
        },
        sorter: true,
      },
      {
        title: 'Next PM Due',
        dataIndex: 'nextPMDue',
        key: 'pm_status',
        render: (nextPMDue) => (
          <NextPMWrapper
            percent={nextPMDue.percent}
            pm_status={nextPMDue.pm_status}
            due_time_in_s={nextPMDue.dueTime}
            rule_type={nextPMDue.rule_type}
            due_engine_hours={nextPMDue.dueEngineHours}
            engineHours={nextPMDue.engineHours}
            due_mileage={nextPMDue.dueMileage}
            totalMileage={nextPMDue.car__mileage_total}
            safetyInspection={this.state.safetyInspection}
            safety_inspection_flag={nextPMDue.safety_inspection_flag}
          />
        ),
        align: 'center',
        filterDropdown: (props) => (
          <NextPMFilterDropdown {...props} setFilters={(newFilters) => this.setState({ filters: newFilters })} />
        ),
        sorter: true,
      },
      {
        title: 'Last PM Done',
        dataIndex: 'lastPMDone',
        key: 'lastPMDone',
        render: (date) => (date ? moment(date).format('MMM DD, YYYY') : '--'),
        sorter: true,
      }
    );

    /* columns.push({
      title: '',
      key: 'edit',
      width: 150,
      render: (_, record) => (
        <Col span={24}>
         <IntervalSelectorModel selectedService={record} />
        </Col>
      ),
      }); */
    
    return (
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <SearchWrapper>
              <Input
                placeholder="Search Service Name"
                value={this.state.searchValue}
                onChange={this.handleSearchChange}
                style={{ width: '33.33%' }}
              />
            </SearchWrapper>
          </Col>
          <Col span={24}>
            <ButtonContainer>
              <div className="button-wrapper">
                <Popover
                  content={
                    <div style={{ padding: '10px', maxWidth: '500px' }}>
                      <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <ExclamationCircleOutlined style={{ color: '#faad14', fontSize: '20px', marginRight: '8px' }} />
                        <p style={{ margin: 0 }}>Are you sure you want to remove the selected services? This action cannot be undone.</p>
                      </Row>
                      <div style={{ textAlign: 'right', marginTop: '10px' }}>
                        <Button onClick={() => this.setState({ popoverVisible: false })} style={{ marginRight: '10px' }}>
                          No
                        </Button>
                        <Button type="primary" onClick={() => {
                          IssueStore.removeSelectedServices(selectedRows).then(() => {
                            this.setState({ selectedRows: [], popoverVisible: false });
                            this.fetchData(currentPage, pageSize, this.state.filters, this.state.sort);
                            this.refreshVehiclesList();
                          });
                        }}>
                          Yes
                        </Button>
                      </div>
                    </div>
                  }
                  trigger="click"
                  visible={popoverVisible}
                  onVisibleChange={(visible) => this.setState({ popoverVisible: visible })}
                >
                  <Button
                    style={{
                      backgroundColor: selectedRows.length > 0 ? '#FF0000' : '',
                      color: selectedRows.length > 0 ? '#fff' : '',
                      borderColor: selectedRows.length > 0 ? '#FF0000' : '',
                    }}
                    disabled={selectedRows.length === 0}
                  >
                    Remove Services ({selectedRows.length})
                  </Button>
                </Popover>
              </div>
              <div className="button-wrapper">
                <MarkAsCompleteBtn
                  issues={selectedRows}
                  onClick={() => {
                    this.setState({ selectedRows: [] });
                    this.fetchData(currentPage, pageSize, this.state.filters, this.state.sort);
                    this.refreshVehiclesList();
                  }}
                  title={`Mark as Complete (${selectedRows.length})`}
                  type="primary"
                  shouldDisableButton={selectedRows.length === 0}
                />
              </div>
              <div className="button-wrapper">
                <CreateWorkOrderFromIssuesBtn
                  issues={selectedRows}
                  onFinish={(workOrderCreated) => {
                    notification.success({
                      message: 'Work orders saved',
                      description: (
                        <div>
                          The Work Order was created{' '}
                          <Button
                            style={{ padding: 0 }}
                            type="link"
                            onClick={() => this.props.history.push(`/work-order-desktop/${workOrderCreated.id}/edit`)}
                          >
                            Click Here
                          </Button>{' '}
                          to view the details and to make edits.
                        </div>
                      ),
                    });
                    this.setState({ selectedRows: [] });
                    this.fetchData(currentPage, pageSize, this.state.filters, this.state.sort);
                    this.refreshVehiclesList();
                  }}
                  title={`Create Work Order (${selectedRows.length})`}
                  shouldDisableButton={selectedRows.length === 0}
                />
              </div>
              {/* New Button for clearing filters */}
              {/* <div className="button-wrapper">
                <Button disabled={!filtersApplied} onClick={this.handleClearFilters}>
                  Clear Filters
                </Button>
              </div> */}
            </ButtonContainer>
          </Col>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={data}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: total,
                showSizeChanger: false,
              }}
              onChange={this.handleTableChange}
              rowSelection={rowSelection}
            />
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default PMTable;
