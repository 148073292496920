import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import { Spin } from 'antd';

import { WidgetToolTip } from 'shared/PitstopUI/PitstopChart';
import { ReportsDataStore } from 'stores';
import DateRangeBasedTitle from 'components/PriorityVehicles/DateRangeBasedTitle';

const CritBigNumber = styled.p`
  color: #f92d2e;
  font-size: 75px;
  margin: 0;
`;

const MajorBigNumber = styled.p`
  color: #e28931;
  font-size: 75px;
  margin: 0;
`;

const MinorBigNumber = styled.p`
  color: #e2eb34;
  font-size: 75px;
  margin: 0;
  padding: 0;
`;

const DescriptionText = styled.p`
  margin-top: 20px;
`;

const OverviewRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  border-style: hidden hidden solid hidden;
  border-color: #ededed;
  border-width: 1px;
`;

const CentredAnchor = styled.button`
  float: right;
  background: none!important;
  border: none;
  padding: 0!important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;

  &:disabled {
    color: #ccc;

    &:hover {
      cursor: not-allowed;
    }
  }
`;

class AlertsOverview extends Component {
  onClickView = (priority) => {
    const selector = 'high-priority-vehicles-title';
    document.getElementById(selector)
      .scrollIntoView({
        behavior: 'smooth',
      });
  }

  get numberOfCriticalVehicles () {
    return ReportsDataStore.numberCriticalVehicles;
  }

  shouldDisableViewButton = (priority) => {
    switch (priority) {
      case 'critical':
        return _.get(ReportsDataStore.maxServicePriorityPerCarInRange, 'critical') === 0;
      case 'majorCallDriver':
        return _.get(ReportsDataStore.maxServicePriorityPerCarInRange, 'majorCallDriver') === 0;
      case 'majorNoCall':
        return _.get(ReportsDataStore.maxServicePriorityPerCarInRange, 'majorNoCall') === 0;
      default:
        return _.get(ReportsDataStore.maxServicePriorityPerCarInRange, 'minor') === 0;
    }
  }

  render(){
    return(
      <>
        <DateRangeBasedTitle titlePrefix='High Priority Assets' />
        <WidgetToolTip title='This shows many vehicles have critical, major, or minor issues' />
        <Spin tip="Loading..." hidden={ReportsDataStore.maintenanceSummaryInRange.loaded && ReportsDataStore.highPriorityVehicles.loaded} />
        {ReportsDataStore.maintenanceSummaryInRange.loaded && ReportsDataStore.highPriorityVehicles.loaded ?
        <>
          <OverviewRow>
            <CritBigNumber>{this.numberOfCriticalVehicles}</CritBigNumber>
            <DescriptionText>Please take these vehicles in for service as soon as you can. These issues should be resolved as soon as possible.</DescriptionText>
            <div>
              <CentredAnchor
                  disabled={this.shouldDisableViewButton('critical')}
                  onClick={() => this.onClickView('critical')}
                >View</CentredAnchor>
            </div>
          </OverviewRow>
        <OverviewRow>
            <MajorBigNumber>{_.get(ReportsDataStore.maxServicePriorityPerCarInRange, 'major')}</MajorBigNumber>
            <DescriptionText>Please book a service visit at your earliest convenience. Vehicles with major issues will need attention within the next few weeks.</DescriptionText>
            <div>
              <CentredAnchor
                disabled={this.shouldDisableViewButton('majorCallDriver')}
                onClick={() => this.onClickView('majorCallDriver')}
              >View</CentredAnchor>
            </div>
        </OverviewRow>
        <OverviewRow>
            <MinorBigNumber>{_.get(ReportsDataStore.maxServicePriorityPerCarInRange, 'minor')}</MinorBigNumber>
            <DescriptionText>Actions are optional at this time. These vehicles should have their issues inspected at their next scheduled service visit.</DescriptionText>
        </OverviewRow>
        </>: null}
      </>
    );
  }

}

AlertsOverview.propTypes = {
  history: PropTypes.object.isRequired
};


export default withRouter(observer(AlertsOverview));
