import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReportsDataStore } from 'stores';

const Span = styled.span`
  color: rgba(0,0,0,0.45);
  font-size: 1rem;
`;

const DateRangeBasedTitle = ({ titlePrefix }) => {
  return <>
    <h4 level={3}>{titlePrefix} <Span>in the Past {ReportsDataStore.currentSelectedDateRangeDescription}</Span></h4>
  </>;
};

DateRangeBasedTitle.propTypes = {
  titlePrefix: PropTypes.string,
};

DateRangeBasedTitle.defaultProps = {
  titlePrefix: '',
};

export default DateRangeBasedTitle;
