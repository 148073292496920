import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  notification,
  DatePicker,
} from 'antd';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AddWarrantyComponent from './AddWarrantyComponent';
import AddDocumentsCard from './AddDocumentsCard';
import {
  AppStore,
  CarStore,
  ContactStore,
  CurrentUserStore,
  ShopStore,
} from 'stores';
import moment from 'moment';
import { Logger } from 'stores/Classes';
import ContactFormModal from 'components/Contacts/ContactFormModal';
import { isNumber } from 'lodash';

const DarkHeaderModal = styled(Modal)`
  & {
    width: 60% !important;

    @media (max-width: 1615px) {
      width: 70% !important;
    }

    @media (max-width: 1366px) {
      width: 80% !important;
    }

    @media (max-width: 1024px) {
      width: 90% !important;
    }

    @media (max-width: 768px) {
      width: 95% !important;
    }
  }

  .ant-modal-content {
    .ant-modal-close {
      .ant-modal-close-x {
        color: #ffffff;
      }
    }

    .ant-modal-header {
      background-color: #05103a;
      border-bottom: 0;

      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-body {
      padding: 12px 18px;
    }
  }

  .footer-row {
    margin-top: 21px !important;

    .ant-col {
      display: flex;
      justify-content: flex-end;
    }
  }

  .save-btn {
    margin-left: 8px;
  }
`;

const FloatingMiKmUnit = styled.span`
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  right: 35px;
  color: #b6b6b6;
`;

const WarrantyForm = styled(Form)`
  padding: 10px;

  .ant-col-12 {
    .ant-form-item {
      margin: 0;
    }
  }

  .ant-form-item-label {
    line-height: 10px;
  }

  .coverage-duration-period {
    label {
      visibility: hidden;
    }
  }
`;

const WarrantyEditForm = Form.create({ name: 'edit_warranty' })(
  // eslint-disable-next-line
  class extends React.Component {
    static propTypes = {
      form: PropTypes.object,
      onSubmit: PropTypes.func,
      onCancel: PropTypes.func,
      initialData: PropTypes.object,
      assets: PropTypes.array,
      vendors: PropTypes.array,
      saving: PropTypes.bool,
      isEditingNewWarranty: PropTypes.bool,
      onClickAddNewContact: PropTypes.func,
    };

    warrantyComponentsRef = null;
    documentsCardRef = null;

    miKmUserSettings =
      CurrentUserStore.user?.settings?.odometer === 'km' ? 'km' : 'mi';

    state = {
      saving: false,
    };

    onSubmit = async () => {
      try {
        this.setState({ saving: true });
        // for now: check if promise does not resolve in 500 milliseconds, isValidForm should be true
        // for some unknown reason, the promise does not resolve when the form is valid
        const isValidForm = await new Promise((resolve) => {
          const timeout = setTimeout(() => resolve(true), 500);
          this.props.form.validateFields((err) => {
            if (err) {
              clearTimeout(timeout);
              return resolve(false);
            }
            resolve(true);
          });
        });

        if (!isValidForm) {
          this.setState({ saving: false });
          notification.error({
            message: 'Error',
            description: 'Please fill in all required fields',
          });
          return;
        }
        const values = this.props.form.getFieldsValue();
        const components = this.warrantyComponentsRef.getComponents();
        const validComponents = components.filter(
          (component) =>
            component.name.trim() !== '' || component.description.trim() !== ''
        );

        const documents = this.documentsCardRef.getDocuments();

        this.props.onSubmit({
          ...values,
          components: validComponents,
          documents,
          id: this.props.initialData?.id || null,
          key:
            this.props.initialData?.id ||
            this.props.initialData?.key ||
            new Date().getTime(),
        });
      } catch (error) {
        Logger.error('Error updating warranty', error);
        notification.error({
          message: 'Error',
          description: 'An error occurred while updating the warranty',
        });
      }
    };

    render() {
      const { form } = this.props;
      const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
      return (
        <WarrantyForm
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.onSubmit();
          }}
        >
          <Row gutter={[8, 8]}>
            <Col span={this.props.isEditingNewWarranty ? 9 : 6}>
              <Form.Item label="Asset">
                {getFieldDecorator('assets', {
                  initialValue: this.props.initialData?.assets,
                  rules: [
                    {
                      required: true,
                      message: 'Please select at least one asset',
                    },
                  ],
                })(
                  <Select
                    disabled={!this.props.isEditingNewWarranty}
                    mode="multiple"
                    placeholder="Select assets"
                    style={{ width: '100%' }}
                    allowClear
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {this.props.assets.map((asset) => (
                      <Select.Option key={asset.id} value={asset.id}>
                        {asset.carName ||
                          asset.unitId ||
                          asset.make + ' ' + asset.model + ' ' + asset.year}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            {this.props.isEditingNewWarranty ? null : (
              <Col span={3}>
                <Form.Item label="Status">
                  {getFieldDecorator('status', {
                    initialValue: this.props.initialData?.status || 'active',
                    rules: [
                      { required: true, message: 'Please select a status' },
                    ],
                  })(
                    <Select
                      disabled={true}
                      placeholder="Select status"
                      style={{ width: '100%' }}
                      allowClear
                    >
                      <Select.Option value="Expiring Soon">
                        Expiring Soon
                      </Select.Option>
                      <Select.Option value="Active">Active</Select.Option>
                      <Select.Option value="Expired">Expired</Select.Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={3}>
              <Form.Item label="Warranty Name">
                {getFieldDecorator('warrantyName', {
                  initialValue: this.props.initialData?.warrantyName,
                  rules: [
                    { required: true, message: 'Please name the warranty' },
                  ],
                })(<Input placeholder="Warranty Name" />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Coverage Type">
                {getFieldDecorator('coverageType', {
                  initialValue: this.props.initialData?.coverageType,
                  rules: [
                    {
                      required: true,
                      message: 'Please select a coverage type',
                    },
                  ],
                })(
                  <Select
                    placeholder="Select coverage type"
                    style={{ width: '100%' }}
                    allowClear
                  >
                    <Select.Option value="standard">Standard</Select.Option>
                    <Select.Option value="extended">Extended</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Vendor">
                {getFieldDecorator('vendor', {
                  initialValue: this.props.initialData?.vendor,
                  rules: [],
                })(
                  <Select
                    placeholder="Select vendor"
                    style={{ width: '100%' }}
                    allowClear
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {this.props.vendors.map((vendor) => (
                      <Select.Option key={vendor.id} value={vendor.id}>
                        {vendor.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Button
                type="link"
                onClick={this.props.onClickAddNewContact}
                icon="plus-circle"
                style={{
                  padding: 0,
                  top: '-25px',
                }}
              >
                Add Vendor
              </Button>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={4}>
              {/* Start Date */}
              <Form.Item label="Start Date">
                {getFieldDecorator('startDate', {
                  initialValue: this.props.initialData?.startDate,
                  rules: [{ required: true, message: 'Required' }],
                  onChange: (startDate) => {
                    const duration = getFieldValue('coverageDuration');
                    const period = getFieldValue('coverageDurationPeriod');
                    if (duration && period) {
                      const startDateMoment = moment(startDate);
                      const endDateMoment = moment(startDateMoment);
                      endDateMoment.add(duration, period);
                      setFieldsValue({
                        endDate: endDateMoment,
                      });
                    }
                  },
                })(<DatePicker placeholder="Start Date" />)}
              </Form.Item>
            </Col>
            <Col span={3}>
              {/* Coverage Duration */}
              <Form.Item label="Coverage Duration">
                {getFieldDecorator('coverageDuration', {
                  initialValue: this.props.initialData?.coverageDuration,
                  rules: [{ required: true, message: 'Required' }],
                  onChange: (value) => {
                    const startDate = getFieldValue('startDate');
                    if (startDate) {
                      const startDateMoment = moment(startDate);
                      const endDateMoment = moment(startDateMoment);
                      const period = getFieldValue('coverageDurationPeriod');
                      endDateMoment.add(value, period);
                      setFieldsValue({
                        endDate: endDateMoment,
                      });
                    }
                  },
                })(
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder="Duration"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item className="coverage-duration-period" label="&nbsp;">
                {getFieldDecorator('coverageDurationPeriod', {
                  initialValue:
                    this.props.initialData?.coverageDurationPeriod || 'years',
                  onChange: (value) => {
                    const startDate = getFieldValue('startDate');
                    const duration = getFieldValue('coverageDuration');
                    if (startDate && duration) {
                      const startDateMoment = moment(startDate);
                      const endDateMoment = moment(startDateMoment);
                      endDateMoment.add(duration, value);
                      setFieldsValue({
                        endDate: endDateMoment,
                      });
                    }
                  },
                })(
                  <Select style={{ width: '100%' }}>
                    <Select.Option value="months">Months</Select.Option>
                    <Select.Option value="years">Years</Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={3}>
              {/* End date */}
              <Form.Item label="End Date">
                {getFieldDecorator('endDate', {
                  initialValue: this.props.initialData?.endDate,
                  rules: [
                    { required: true, message: 'Required' },
                    { type: 'date', message: 'Invalid date' },
                  ],
                })(<DatePicker disabled={true} placeholder="End Date" />)}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Start Meter">
                {getFieldDecorator('startMeter', {
                  initialValue: this.props.initialData?.startMeter,
                  rules: [
                    { required: true, message: 'Required' },
                    {
                      type: 'number',
                      message: 'Invalid number',
                    },
                    // positive number
                    {
                      validator: (rule, value, callback) => {
                        if (value < 0) {
                          callback('Invalid number');
                        } else {
                          callback();
                        }
                      },
                    },
                  ],
                  onChange: (value) => {
                    const range = getFieldValue('range');
                    if (isNumber(value) && isNumber(range)) {
                      setFieldsValue({
                        endMeter: value + range,
                      });
                    } else {
                      setFieldsValue({
                        endMeter: null,
                      });
                    }
                  },
                })(
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder="Start Meter"
                  />
                )}
              </Form.Item>
              <FloatingMiKmUnit>{this.miKmUserSettings}</FloatingMiKmUnit>
            </Col>
            <Col span={3}>
              <Form.Item label="Range">
                {getFieldDecorator('range', {
                  initialValue: this.props.initialData?.range,
                  rules: [
                    { required: true, message: 'Required' },
                    {
                      type: 'number',
                      message: 'Invalid number',
                    },
                    // positive number
                    {
                      validator: (rule, value, callback) => {
                        if (value < 0) {
                          callback('Invalid number');
                        } else {
                          callback();
                        }
                      },
                    },
                  ],
                  onChange: (value) => {
                    const startMeter = getFieldValue('startMeter');
                    if (isNumber(startMeter) && isNumber(value)) {
                      setFieldsValue({
                        endMeter: startMeter + value,
                      });
                    } else {
                      setFieldsValue({
                        endMeter: null,
                      });
                    }
                  },
                })(
                  <InputNumber style={{ width: '100%' }} placeholder="Range" />
                )}
              </Form.Item>
              <FloatingMiKmUnit>{this.miKmUserSettings}</FloatingMiKmUnit>
            </Col>
            <Col span={4}>
              <Form.Item label="End Meter">
                {getFieldDecorator('endMeter', {
                  initialValue: this.props.initialData?.endMeter,
                  rules: [{ required: true, message: 'Required' }],
                })(
                  <InputNumber
                    disabled={true}
                    style={{ width: '100%' }}
                    placeholder="End Meter"
                  />
                )}
              </Form.Item>
              <FloatingMiKmUnit>{this.miKmUserSettings}</FloatingMiKmUnit>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <AddWarrantyComponent
                ref={(ref) => {
                  this.warrantyComponentsRef = ref;
                }}
                initialComponents={this.props?.initialData?.components || []}
              />
            </Col>
            <Col span={12}>
              <AddDocumentsCard
                ref={(ref) => {
                  this.documentsCardRef = ref;
                }}
                documents={this.props.initialData?.documents || []}
                onFileUploaded={() => {}}
                overrideUploadProps={{
                  // accept image and pdf files
                  accept: 'image/*,.pdf',
                }}
              />
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Button
                type="primary"
                style={{ float: 'right' }}
                htmlType="submit"
                loading={this.state.saving}
              >
                Save
              </Button>
              <Button
                style={{ float: 'right', marginRight: 8 }}
                onClick={() => {
                  this.props.onCancel();
                }}
                htmlType="button"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </WarrantyForm>
      );
    }
  }
);

function WarrantyDetailsModal({
  visible,
  saving,
  isEditingNewWarranty,
  onSubmit,
  onCancel,
  warranty,
}) {
  const [assets, setAssets] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [savingContact, setSavingContact] = useState(false);

  const CONTACT_FORM_MODAL_ID = 'ADD_CONTACT_FORM_MODAL';

  // Get the list of assets
  const getAssets = async () => {
    if (!ShopStore.currentShop.id || ShopStore.currentShop.id === -1) {
      setAssets([]);
      return;
    }
    const assets = await CarStore.getShopCarsBasicInfoAndReturn(
      ShopStore.currentShop.id
    );
    setAssets(assets);
  };

  // Get the list of vendors/contacts
  const getVendors = async () => {
    if (!ShopStore.currentShop.id || ShopStore.currentShop.id === -1) {
      setVendors([]);
      return;
    }
    const vendors = await ContactStore.getShopContacts(
      ShopStore.currentShop.id
    );
    setVendors(vendors);
  };

  const onSubmitContactForm = async (data) => {
    try {
      setSavingContact(true);
      await ContactStore.createShopContact({
        ...data,
        shopId: ShopStore.currentShop.id,
      });
      AppStore.addSuccess('Vendor saved successfully');
      await getVendors();
      AppStore.openModals.set(CONTACT_FORM_MODAL_ID, false);
    } catch (error) {
      AppStore.addError('Error to save vendor: ' + error.message);
    } finally {
      setSavingContact(false);
    }
  };

  useEffect(() => {
    if (visible) {
      // Get assets and vendors when the modal is visible
      getAssets();
      getVendors();
    }
  }, [visible]);

  const handleFormSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <DarkHeaderModal
      onCancel={onCancel}
      title="Warranty Details"
      visible={visible}
      footer={null}
    >
      {warranty && (
        <>
          <Row className="content-row" gutter={[16, 16]}>
            <WarrantyEditForm
              assets={assets}
              vendors={vendors}
              isEditingNewWarranty={isEditingNewWarranty}
              initialData={{
                id: warranty.id,
                key: warranty.key,
                status: warranty.status,
                assets: warranty.id_car ? [warranty.id_car] : warranty.assets,
                warrantyName: warranty.name,
                coverageType: warranty.coverage_type,
                vendor: warranty.id_contact,
                startDate: moment(warranty.start_date).tz('America/Toronto'),
                coverageDuration: warranty.coverage_duration,
                coverageDurationPeriod: warranty.coverage_duration_unit,
                endDate: moment(warranty.end_date).tz('America/Toronto'),
                startMeter: warranty.start_meter,
                range: warranty.range_meter,
                endMeter: warranty.end_meter,
                documents: warranty.documents,
                components: warranty.components,
              }}
              saving={saving}
              onSubmit={(data) => handleFormSubmit(data)}
              onCancel={onCancel}
              onClickAddNewContact={() => {
                AppStore.openModals.set(CONTACT_FORM_MODAL_ID, true);
              }}
            />
          </Row>
          <ContactFormModal
            modalId={CONTACT_FORM_MODAL_ID}
            saving={savingContact}
            title={'Add Vendor'}
            defaultData={{}}
            onCancel={() => {
              AppStore.openModals.set(CONTACT_FORM_MODAL_ID, false);
            }}
            onSubmit={(data) => {
              onSubmitContactForm(data);
            }}
          />
        </>
      )}
    </DarkHeaderModal>
  );
}

WarrantyDetailsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  warranty: PropTypes.object,
  saving: PropTypes.bool,
  isEditingNewWarranty: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

WarrantyDetailsModal.defaultProps = {
  saving: false,
  isEditingNewWarranty: false,
};

export default WarrantyDetailsModal;
