import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import _ from 'lodash';

import { Button } from 'antd';

import { webServiceProvider } from 'shared';
import { AppStore, ShopStore, CurrentUserStore } from 'stores';

import { CustomServiceForm } from 'containers/Form';
import { SelectCars } from 'containers/Users';

class NewIssueReport extends Component {
  state = {
    selectedCarIds: [],
  };

  myRef = React.createRef();

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.setState({
      selectedCarIds: [],
    });
  });

  onSelect = (ids) => {
    this.setState({
      selectedCarIds: ids,
    });
  };

  addService = async (values) => {
    if (_.isNil(values) || _.isEmpty(values)) {
      throw Error('Invalid input');
    }

    let priority;

    switch (values.priority) {
      case 'Minor':
        priority = 1;
        break;
      case 'Major':
        priority = 3;
        break;
      case 'Critical':
        priority = 4;
        break;
      default:
        priority = 4;
    }

    let data = {
      data: {
        action: values.action,
        userId: CurrentUserStore.user.userId,
        item: values.partname,
        priority: priority,
        description: values.description || 'No description available',
      },
      status: values.status || 'new',
      carId: this.state.selectedCarIds[0],
      issueType: 'customUser',
    };

    await webServiceProvider.post('issue', data);
  };

  onSubmit = async () => {
    try {
      if (ShopStore.currentShop.id === -1) {
        throw Error('Please select another shop!');
      }

      if (_.isEmpty(this.state.selectedCarIds)) {
        throw Error('Please select vehicles!');
      }

      let result = await this.myRef.validateForm();

      AppStore.addLoading('Service is being created...');

      await this.addService(result);

      AppStore.addSuccess('Adding service history done!');
    } catch (err) {
      if (!_.isNil(err.message))
        AppStore.addError(`Error in adding service history: ${err.message}`);
    } finally {
      AppStore.removeLoading('Service is being created...');
    }
  };

  render() {
    const { selectedCarIds } = this.state;

    return (
      <>
        <CustomServiceForm
          childRef={(ref) => (this.myRef = ref)}
          serviceName="newIssueReport"
        />

        <b>
          <p>Select One Vehicle</p>
        </b>
        <SelectCars
          rowSelection={{
            selectedCarIds: selectedCarIds,
            onChange: this.onSelect,
            type: 'radio',
          }}
        />
        <p>
          <b>Total Selected Vehicles:</b> {selectedCarIds.length}
        </p>

        <Button
          style={{ marginTop: '10px' }}
          type="primary"
          onClick={this.onSubmit}
        >
          Add Service
        </Button>
      </>
    );
  }
}

export default observer(NewIssueReport);
