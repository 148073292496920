import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import humanizeDuration from 'humanize-duration';

import { Table } from 'antd';

class PopularPagesReport extends Component {
  columns = [
    {
      title: 'Page',
      dataIndex: 'pagePath',
      key: 'pagePath',
      render: pagePath => <Link to={pagePath}>{pagePath}</Link>
    },
    {
      title: 'Page Views',
      dataIndex: 'pageviews',
      key: 'pageviews',
      sorter: (a, b) => a.pageviews - b.pageviews,
      defaultSortOrder: 'descend'
    },
    {
      title: 'Time On Page',
      dataIndex: 'timeOnPage',
      key: 'timeOnPage',
      sorter: (a, b) => a.timeOnPage - b.timeOnPage,
      render: timeOnPage => humanizeDuration(timeOnPage * 1000)
    }
  ];

  render() {
    const { data, loading } = this.props;

    return (
      <>
        <p style={{ fontWeight: 500 }}>Popular Pages</p>
        <Table columns={this.columns} dataSource={data} loading={loading} />
      </>
    );
  }
}

PopularPagesReport.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

export default PopularPagesReport;
