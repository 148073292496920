import _ from 'lodash';

export const getLocationPolylines = selectedTrip => {
  let path = [];

  let tripsData = [selectedTrip];

  _.forEach(tripsData, trip => {
    if (
      !_.isNil(trip.location_polyline) &&
      !_.isEmpty(trip.location_polyline)
    ) {
      _.forEach(trip.location_polyline, polyline => {
        path.push({
          location: {
            latitude: Number(polyline.location[1].data),
            longitude: Number(polyline.location[0].data)
          },
          timestamp: polyline.timestamp
        });
      });

      return;
    }

    if (
      !_.isNil(trip.locationStart) &&
      !_.isNil(trip.locationEnd) &&
      !_.isEmpty(trip.locationStart) &&
      !_.isEmpty(trip.locationEnd)
    ) {
      path.push({
        location: {
          latitude: trip.locationStart.latitude,
          longitude: trip.locationStart.longitude
        },
        timestamp: trip.timeStart
      });

      path.push({
        location: {
          latitude: trip.locationEnd.latitude,
          longitude: trip.locationEnd.longitude
        },
        timestamp: trip.timeEnd
      });

      return;
    }
  });

  return path;
};
