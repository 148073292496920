import styled from 'styled-components';

const Card = styled.div`
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
`;

export default Card;
