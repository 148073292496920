import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Typography } from 'antd';

import { PitstopSelect, PistopTags } from 'shared';

const DEFAULT_DISPLAY_FIELD = 'vin';
const DISPLAY_FIELDS = ['make', 'model', 'name', 'vin'];
const { Text } = Typography;

function Header (props) {
  const { cars, displayField, displayFieldChange, fontSize } = props;

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Text style={{fontSize}}>Selected Vehicles: {!cars.length && 'None'}</Text>
      {cars.length !== 0 && (
        <>
          <PitstopSelect
            options={DISPLAY_FIELDS}
            onChange={displayFieldChange}
            value={displayField}
          />
        </>
      )}
    </div>
  );
}

Header.propTypes = {
  cars: PropTypes.array,
  displayField: PropTypes.string,
  displayFieldChange: PropTypes.func,
  fontSize: PropTypes.string,
};

class SelectedCars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayField: DEFAULT_DISPLAY_FIELD,
    };
    this.displayFieldChange = this.displayFieldChange.bind(this);
  }

  displayFieldChange(displayField) {
    this.setState({ displayField });
  }

  render() {
    const { cars, onUnselect, fontSize } = this.props;
    const { displayField } = this.state;

    return (
      <>
        <Header
          fontSize={fontSize}
          cars={cars}
          displayField={displayField}
          displayFieldChange={this.displayFieldChange}
        />
        <PistopTags
          closable={true}
          displayField={displayField}
          entities={cars}
          onClose={onUnselect}
          uniqueKey="id"
        />
      </>
    );
  }
}

SelectedCars.propTypes = {
  cars: PropTypes.array.isRequired,
  onUnselect: PropTypes.func.isRequired,
  fontSize: PropTypes.any,
};

export default observer(SelectedCars);
