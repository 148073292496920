import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Spin, Modal } from 'antd';

import AppStore from 'stores/App';

class Loading extends Component {
  render() {
    return (
      <Modal
        title={AppStore.isLoading.get() ? AppStore.messages[0] : 'Loading'}
        centered
        footer={null}
        visible={AppStore.isLoading.get()}
        afterClose={() => (document.getElementsByTagName('body')[0].style = '')}
      >
        <Spin size="large" /> Loading...
      </Modal>
    );
  }
}

export default observer(Loading);
