import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import TableStore from 'stores/abstract/TableStore';

import { Icon, Card, Row, Col, Tooltip } from 'antd';
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { DoughnutChart } from 'shared/PitstopUI/PitstopChart';

import {
  PitstopButton,
  webServiceProvider,
  prescriptiveMaintenanceMessages,
} from 'shared';

import { ReactComponent as MilLamp } from 'assets/icons/milLamp.svg';
import { ReactComponent as ProtectLamp } from 'assets/icons/protectLamp.svg';
import { ReactComponent as AmberWarningLamp } from 'assets/icons/amberWarningLamp.svg';
import { ReactComponent as RedStopLamp } from 'assets/icons/redStopLamp.svg';
import { ReactComponent as MilLampOff } from 'assets/icons/milLampOff.svg';
import { ReactComponent as ProtectLampOff } from 'assets/icons/protectLampOff.svg';
import { ReactComponent as AmberWarningLampOff } from 'assets/icons/amberWarningLampOff.svg';
import { ReactComponent as RedStopLampOff } from 'assets/icons/redStopLampOff.svg';

import VMRSColorMap from 'shared/VMRSColorMap';

import {
  AppStore,
  IssueStore,
  CurrentUserStore,
  CarStore,
  ShopStore,
} from 'stores';

import { CustomServiceForm } from 'containers/Form';
import { DownloadVehicleHealthReport } from 'components/download/report';

import { IssuesTable } from 'containers/Issues';

const TextRow = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
  height: 34px;
`;

const LabelText = styled.p`
  margin: 3%
  font-weight: 700;
  align-self: center;
`;

const IconWrapper = styled.p``;

const ContentText = styled.p`
  flex: 1;
  margin: 3%
  align-self: center;
  color: black;
`;

const ActionsWrapper = styled.div`
  position: absolute;
  right: -7px;
  z-index: 4;
  display: flex;
  align-items: center;

  button {
    margin-right: 10px;
  }
`;

class IssuesPage extends Component {
  static propTypes = {
    car: PropTypes.object,
    location: PropTypes.object,
  };

  state = {
    visible: false,
    category: 'new',
    vmrsData: null,
  };

  child = React.createRef();

  async componentDidMount() {
    if (!this.vehicleDetailReport.loaded) {
      await this.props.car.getVehicleDetailReport();
    }
    if (!this.engineLightStatus.loaded) {
      await this.props.car.getEngineLightStatus();
    }
    await IssueStore.getIssuesForCarId(
      {
        filter: { status: 'new' },
        shopId: this.props.car.shopId,
        limit: 50,
      },
      this.thisIssueStore,
      this.props.car.id,
      false,
      true
    );
    const newIssueIterator = this.thisIssueStore.data.values();
    const vmrs = [];
    const vmrsCounts = {};
    const vmrsFormatted = [];
    const VMRSValueToSys = {};
    for (let value of newIssueIterator) {
      if (value.vmrs) {
        VMRSValueToSys[_.get(value, 'vmrs.text').substring(0, 12)] = _.get(
          value,
          'vmrs.sys'
        );
        vmrs.push(_.get(value, 'vmrs.text').substring(0, 12));
      }
    }
    vmrs.forEach((el) => {
      vmrsCounts[el] = vmrsCounts[el] ? vmrsCounts[el] + 1 : 1;
    });
    for (let key in vmrsCounts) {
      vmrsFormatted.push({
        name: key,
        color: VMRSColorMap(VMRSValueToSys[key]),
        y: vmrsCounts[key],
      });
    }
    this.setState({ vmrsData: vmrsFormatted });
  }

  get vehicleDetailReport() {
    return this.props.car.detailReport;
  }

  get engineLightStatus() {
    const statusResponse = this.props.car.engineLightStatus;
    return statusResponse;
  }

  get issuesData() {
    if (CarStore.demo === false) {
      return {
        key: 'issues',
        value: [],
      };
    }

    const { issueCounts } = this.props.car;
    let value = [
      {
        name: 'critical',
        color: '#eb3d34',
        y: Number(issueCounts.critical) || 0,
      },
      {
        name: 'major',
        color: '#e28931',
        y: Number(issueCounts.major) || 0,
      },
      {
        name: 'minor',
        color: '#e2eb34',
        y: Number(issueCounts.minor) || 0,
      },
    ];

    return {
      key: '',
      seriesName: 'issues',
      emptyMessage: 'Your vehicle has no Issues',
      value,
    };
  }

  loadCategory = async (e) => {
    this.setState({
      category: e.target.value,
    });
  };

  saveService = async (values) => {
    try {
      AppStore.addLoading('Issue is being created...');

      const issue = await webServiceProvider.post('issue', {
        data: {
          action: values.action,
          userId: CurrentUserStore.user.userId,
          item: values.partname,
          priority:
            values.priority === 'Minor'
              ? 1
              : values.priority === 'Major'
              ? 2
              : values.priority === 'Critical'
              ? 4
              : undefined,
          description: values.description || 'No description available',
        },
        status: values.status || 'new',
        carId: this.props.car.id,
        issueType: 'customUser',
      });

      const data = {
        id: issue.id,
        action: values.action,
        source: 'user',
        name: values.partname + ' - ' + values.action,
        priority:
          values.priority === 'Minor'
            ? 1
            : values.priority === 'Major'
            ? 2
            : values.priority === 'Critical'
            ? 4
            : 'Minor',
        status: values.status || 'new',
      };

      IssueStore.addIssueToStore(issue.id, { ...data });
      data.status === 'new' && this.addUserIssueId(issue.id);
      AppStore.addSuccess('New Issue Added Successfully');
    } catch (e) {
      console.log(e);
      AppStore.addError(e.error);
      AppStore.addError('Sorry! New Issue Cannot Be Saved');
    } finally {
      AppStore.removeLoading('Issue is being created...');
    }
  };

  renderChart = (data) => {
    return (
      <DoughnutChart
        chart={{
          height: 200,
          margin: [0, 200, 0, 0],
          legend: {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'top',
            width: 150,
            x: -30,
            y: 30,
          },
        }}
        data={data}
        loaded={CarStore.demo === false || this.vehicleDetailReport.loaded}
      />
    );
  };

  addUserIssueId = (id) => {
    // this.child.addUserIssueId(id);
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.saveService(values);
      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  thisIssueStore = new TableStore();

  render() {
    const engineLightStatus = this.engineLightStatus;
    const engineLightDisplay = _.get(engineLightStatus, 'data.data[0].payload');
    let vehicleDetail = this.vehicleDetailReport;
    let maxPriority, maxAdjPriority, vehicleStatus, prescriptiveTextStyle;
    let prescriptiveIcon, maxMajorDate, routineServicePresent;
    if (_.get(vehicleDetail, 'data')) {
      maxAdjPriority = _.get(vehicleDetail, 'data.maxAdjPriority');
      maxPriority = _.max([
        maxAdjPriority,
        _.get(vehicleDetail, 'data.maxPriority'),
      ]);
      maxMajorDate = _.get(vehicleDetail, 'data.maxMajorIssueDate');
      routineServicePresent = _.get(
        vehicleDetail,
        'data.routineServicePresent'
      );
      if (maxPriority === 5 || maxPriority === 4) {
        vehicleStatus = prescriptiveMaintenanceMessages(
          maxPriority,
          maxMajorDate,
          routineServicePresent
        );
        prescriptiveTextStyle = { color: '#f92d2e' };
        prescriptiveIcon = (
          <ExclamationCircleTwoTone
            twoToneColor="#f92d2e"
            style={{ fontSize: '30px' }}
          />
        );
      } else if (maxPriority === 3 || maxPriority === 2) {
        vehicleStatus = prescriptiveMaintenanceMessages(
          maxPriority,
          maxMajorDate,
          routineServicePresent
        );
        prescriptiveTextStyle = { color: '#e28931' };
        prescriptiveIcon = (
          <ExclamationCircleTwoTone
            twoToneColor="#e28931"
            style={{ fontSize: '30px' }}
          />
        );
      } else if (maxPriority === 1 || maxPriority === 0) {
        vehicleStatus = prescriptiveMaintenanceMessages(
          maxPriority,
          maxMajorDate,
          routineServicePresent
        );
        prescriptiveTextStyle = { color: '#dec431' };
        prescriptiveIcon = (
          <ExclamationCircleTwoTone
            twoToneColor="#dec431"
            style={{ fontSize: '30px' }}
          />
        );
      } else {
        vehicleStatus = prescriptiveMaintenanceMessages(
          maxPriority,
          maxMajorDate,
          routineServicePresent
        );
        prescriptiveTextStyle = { color: 'green' };
        prescriptiveIcon = (
          <CheckCircleTwoTone
            twoToneColor="green"
            style={{ fontSize: '30px' }}
          />
        );
      }
    }

    return (
      <>
        <div style={{ display: 'flex' }}>
          {!_.isNil(maxPriority) || !_.isNil(maxAdjPriority) ? (
            <div style={{ maxWidth: '50%' }}>
              <Card
                bodyStyle={prescriptiveTextStyle}
                style={{
                  maxWidth: '100%',
                  marginBottom: '1%',
                  padding: 'auto',
                }}
              >
                <TextRow>
                  <LabelText>Recommended Action</LabelText>
                  <IconWrapper>{prescriptiveIcon}</IconWrapper>
                  <ContentText>{vehicleStatus}</ContentText>
                </TextRow>
              </Card>
            </div>
          ) : null}
          {!_.isNil(engineLightDisplay) ? (
            <Card
              style={{
                maxWidth: '28%',
                marginLeft: '2%',
                marginBottom: '1%',
                padding: 0,
              }}
            >
              <div style={{ display: 'flex' }}>
                {!_.get(engineLightDisplay, 'generalVehicleWarningLight') ? (
                  <>
                    {_.get(engineLightDisplay, 'redStopLamp') ? (
                      <RedStopLamp width={40} height={40} />
                    ) : (
                      <RedStopLampOff width={40} height={40} />
                    )}
                    <p style={{ marginLeft: '20px', marginTop: '6px' }}>
                      Engine Stop Lamp
                    </p>
                    <p
                      style={{
                        marginLeft: '35px',
                        marginTop: '6px',
                        alignSelf: 'center',
                        fontSize: '14px',
                        color: _.get(engineLightDisplay, 'redStopLamp')
                          ? 'red'
                          : '#595959',
                      }}
                    >
                      {_.get(engineLightDisplay, 'redStopLamp')
                        ? 'Active'
                        : 'Inactive'}
                    </p>
                    <Tooltip title="Red stop lamp indicates the vehicle needs to stop immediately.">
                      <InfoCircleOutlined
                        style={{
                          alignSelf: 'center',
                          marginLeft: '10px',
                          marginBottom: '8px',
                        }}
                      />
                    </Tooltip>
                  </>
                ) : null}
              </div>
              <div style={{ display: 'flex' }}>
                {!_.get(engineLightDisplay, 'generalVehicleWarningLight') ? (
                  <>
                    {_.get(engineLightDisplay, 'malfunctionLamp') ? (
                      <MilLamp width={40} height={40} />
                    ) : (
                      <MilLampOff width={40} height={40} />
                    )}
                    <p style={{ marginLeft: '20px', marginTop: '6px' }}>
                      Malfunction Lamp
                    </p>
                    <p
                      style={{
                        marginLeft: '35px',
                        marginTop: '6px',
                        alignSelf: 'center',
                        fontSize: '14px',
                        color: _.get(engineLightDisplay, 'malfunctionLamp')
                          ? 'red'
                          : '#595959',
                      }}
                    >
                      {_.get(engineLightDisplay, 'malfunctionLamp')
                        ? 'Active'
                        : 'Inactive'}
                    </p>
                    <Tooltip title="Malfunction indicator lamp (mil) indicates issues with the exhaust system.">
                      <InfoCircleOutlined
                        style={{
                          alignSelf: 'center',
                          marginLeft: '10px',
                          marginBottom: '8px',
                        }}
                      />
                    </Tooltip>
                  </>
                ) : null}
              </div>
              <div style={{ display: 'flex' }}>
                {_.get(engineLightDisplay, 'amberWarningLamp') ||
                _.get(engineLightDisplay, 'generalVehicleWarningLight') ? (
                  <AmberWarningLamp width={40} height={40} />
                ) : (
                  <AmberWarningLampOff width={40} height={40} />
                )}
                <p style={{ marginLeft: '20px', marginTop: '6px' }}>
                  Check Engine Light
                </p>
                <p
                  style={{
                    marginLeft: '28px',
                    marginTop: '6px',
                    alignSelf: 'center',
                    fontSize: '14px',
                    color:
                      _.get(engineLightDisplay, 'amberWarningLamp') ||
                      _.get(engineLightDisplay, 'generalVehicleWarningLight')
                        ? 'red'
                        : '#595959',
                  }}
                >
                  {_.get(engineLightDisplay, 'amberWarningLamp') ||
                  _.get(engineLightDisplay, 'generalVehicleWarningLight')
                    ? 'Active'
                    : 'Inactive'}
                </p>
                <Tooltip
                  title={
                    _.get(engineLightDisplay, 'amberWarningLamp')
                      ? 'Amber warning lamp, indicates issues that should be looked at soon.'
                      : 'Check engine light'
                  }
                >
                  <InfoCircleOutlined
                    style={{
                      alignSelf: 'center',
                      marginLeft: '10px',
                      marginBottom: '8px',
                    }}
                  />
                </Tooltip>
              </div>
              <div style={{ display: 'flex' }}>
                {!_.get(engineLightDisplay, 'generalVehicleWarningLight') ? (
                  <>
                    {_.get(engineLightDisplay, 'protectWarningLamp') ? (
                      <ProtectLamp width={40} height={40} />
                    ) : (
                      <ProtectLampOff width={40} height={40} />
                    )}
                    <p style={{ marginLeft: '20px', marginTop: '6px' }}>
                      Engine Protect Light
                    </p>
                    <p
                      style={{
                        marginLeft: '20px',
                        marginTop: '6px',
                        alignSelf: 'center',
                        fontSize: '14px',
                        color: _.get(engineLightDisplay, 'protectWarningLamp')
                          ? 'red'
                          : '#595959',
                      }}
                    >
                      {_.get(engineLightDisplay, 'protectWarningLamp')
                        ? 'Active'
                        : 'Inactive'}
                    </p>
                    <Tooltip title="Protect Lamp indicates that the vehicle is derating to prevent serious damage">
                      <InfoCircleOutlined
                        style={{
                          alignSelf: 'center',
                          marginLeft: '10px',
                          marginBottom: '8px',
                        }}
                      />
                    </Tooltip>
                  </>
                ) : null}
              </div>
            </Card>
          ) : null}
        </div>
        {!(ShopStore.currentShop.id === 1177) ? (
          <ActionsWrapper>
            <PitstopButton ghost onClick={() => this.showModal()}>
              <Icon type="plus" />
              Add Service
            </PitstopButton>
            <DownloadVehicleHealthReport
              carId={this.props.car.id}
              category={this.state.category}
            />
          </ActionsWrapper>
        ) : null}
        <CustomServiceForm
          childRef={(ref) => (this.child = ref)}
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          serviceName="customIssue"
        />

        <Row>
          <Col span={24}>
            {/*<IssueLegend />*/}
            <IssuesTable
              car={this.props.car}
              isEnabled={!_.isNil(this.props.car.id)}
              id={`car-${this.props.car.id}`}
              fetchData={(params, store) => {
                IssueStore.getIssuesForCarId(
                  {
                    ...params,
                    shopId: this.props.car.shopId,
                  },
                  store,
                  this.props.car.id
                );
              }}
              page="carPage"
            />
          </Col>
        </Row>
      </>
    );
  }
}

decorate(IssuesPage, {
  engineLightStatus: computed,
  vehicleDetailReport: computed,
});

export default withRouter(observer(IssuesPage));
