import { action, decorate } from 'mobx';
import { webServiceProvider } from '../shared';

const DIRECT_ENDPOINT = `${process.env.REACT_APP_API_URL}v1`;

class WarrantyStoreC {
  /**
   * Get the list of warranties
   * @param {number} shopId
   * @param {object} filters?
   * @param {string} filters.carIds? - comma separated car ids
   * @param {string} filters.search? - search string
   * @param {string} filters.statuses? - comma separated statuses
   * @param {string} filters.sort? - sort string
   * @param {number} filters.limit? - limit
   * @param {number} filters.offset? - offset
   * @returns
   */
  getWarranties = async (shopId, filters = {}) => {
    if (!shopId || shopId === '-1' || shopId === -1) {
      return;
    }

    const query = new URLSearchParams();
    query.append('shopId', shopId);

    if (filters.carIds) {
      query.append('carIds', filters.carIds);
    }
    if (filters.search) {
      query.append('search', filters.search);
    }
    if (filters.statuses) {
      query.append('statuses', filters.statuses);
    }
    if (filters.sortBy) {
      query.append('sortBy', filters.sortBy);
    }
    if (filters.limit) {
      query.append('limit', filters.limit);
    }
    if (filters.offset) {
      query.append('offset', filters.offset);
    }

    const abort = new AbortController();
    const url = `${DIRECT_ENDPOINT}/warranties?${query.toString()}`;
    const response = await webServiceProvider.directFetchWithMethod(
      url,
      'GET',
      null,
      abort.signal
    );

    return response;
  };

  /**
   * Create a new warranty
   * @param {object} payload
   * @returns the created warranty
   */
  createWarranty = async (payload) => {
    if (!payload) {
      throw new Error('Invalid Payload');
    }

    const abort = new AbortController();
    const url = `${DIRECT_ENDPOINT}/warranties`;
    const { data } = await webServiceProvider.directFetchWithMethod(
      url,
      'POST',
      payload,
      abort.signal
    );

    return data;
  };

  /**
   * Create multiple warranties
   * @param {object[]} warrantiesToAdd
   * @returns the created warranties
   */
  createWarranties = async (warrantiesToAdd) => {
    if (!warrantiesToAdd || !warrantiesToAdd.length) {
      throw new Error('Invalid Payload');
    }

    const abort = new AbortController();
    const url = `${DIRECT_ENDPOINT}/warranties/bulk`;
    const { data } = await webServiceProvider.directFetchWithMethod(
      url,
      'POST',
      {
        warrantiesToAdd,
      },
      abort.signal
    );

    return data;
  };

  /**
   * Get a warranty by ID
   * @param {number} warrantyId
   * @returns the warranty
   */
  getWarranty = async (warrantyId) => {
    if (!warrantyId) {
      throw new Error('Invalid Warranty ID');
    }

    const abort = new AbortController();
    const url = `${DIRECT_ENDPOINT}/warranties/${warrantyId}`;
    const { data } = await webServiceProvider.directFetchWithMethod(
      url,
      'GET',
      null,
      abort.signal
    );

    return data;
  };

  /**
   * Update a warranty
   * @param {number} warrantyId
   * @param {object} payload
   * @returns the updated warranty
   */
  updateWarranty = async (warrantyId, payload) => {
    if (!warrantyId || !payload) {
      throw new Error('Invalid Warranty ID or Payload');
    }

    const abort = new AbortController();
    const url = `${DIRECT_ENDPOINT}/warranties/${warrantyId}`;
    const { data } = await webServiceProvider.directFetchWithMethod(
      url,
      'PATCH',
      payload,
      abort.signal
    );

    return data;
  };

  getWarrantyComponents = async (warrantyId) => {
    if (!warrantyId) {
      throw new Error('Invalid Warranty ID');
    }

    const abort = new AbortController();
    const url = `${DIRECT_ENDPOINT}/warranties/${warrantyId}/components`;
    const { data } = await webServiceProvider.directFetchWithMethod(
      url,
      'GET',
      null,
      abort.signal
    );
    return data;
  };

  deleteWarranties = async (warrantyIds) => {
    if (!warrantyIds || !warrantyIds.length) {
      throw new Error('Invalid Warranty IDs');
    }

    const abort = new AbortController();
    const url = `${DIRECT_ENDPOINT}/warranties/bulk`;
    const { data } = await webServiceProvider.directFetchWithMethod(
      url,
      'DELETE',
      {
        warrantyIds,
      },
      abort.signal
    );

    return data;
  };
}

decorate(WarrantyStoreC, {
  fetchShopPurchaseOrders: action,
  savePurchaseOrder: action,
  deletePurchaseOrder: action,
});

const WarrantyStore = new WarrantyStoreC();
export default WarrantyStore;
