import { AppStore, PitstopCookie, CurrentUserStore } from 'stores';
import FetchCommonErrorsEnum from './enums/FetchCommonErrorsEnum';

export const webServiceProvider = {
  async delete(endpoint, signal) {
    return _delete(endpoint, signal);
  },
  async get(url, signal) {
    return _getUsingUrlOnly(url, signal);
  },
  async getById(endpoint, id) {
    return _get(`${endpoint}/${id}`);
  },
  async getMany(endpoint, params = {}, signal) {
    return _get(endpoint, params, signal);
  },
  async patch(endpoint, body) {
    return _patch(endpoint, body);
  },
  async post(endpoint, body, signal) {
    return _post(endpoint, body, signal);
  },
  async postFormData(endpoint, formData) {
    return _postFormData(endpoint, formData);
  },
  async put(endpoint, body) {
    return _put(endpoint, body);
  },
  postCustomEndpoint(endpoint, body, signal) {
    return _directFetchWithMethod(endpoint, 'POST', body, signal);
  },
  directFetchWithMethod(endpoint, method, body = {}, signal) {
    return _directFetchWithMethod(endpoint, method, body, signal);
  },
  fetchCSV: _fetchCSV,
  getPDF,
};

const _apiBaseUrl = `${process.env.REACT_APP_API_URL}`;
const _prefix = '';

async function _delete(endpoint, signal) {
  return _fetchWithMethod(endpoint, {}, 'DELETE', signal);
}

async function _directFetchWithMethod(
  endpoint,
  method,
  body,
  signal,
  maxRetries = 5
) {
  let accessToken;

  if (AppStore.isOnGeotabDashboard()) {
    accessToken = await PitstopCookie.get('accessToken');
  } else {
    accessToken = PitstopCookie.get('accessToken');
  }

  if (
    !['/', '/login', '/login/geotab', '/register', '/reset-password', '/callback'].includes(
      window.location.pathname
    ) &&
    !accessToken
  ) {
    AppStore.addError('Your token has expired. Please log in again!');

    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
    return;
  }

  const bodyData = {};

  if (method && !['GET'].includes(method)) {
    bodyData.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(endpoint, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'client-id': process.env.REACT_APP_CLIENT_ID,
      },
      method,
      ...bodyData,
      ...(signal && { signal }),
    });

    if (response.status !== 200 && response.status !== 201) {
      await response.text().then((text) => {
        throw new Error(text);
      });
    }

    return response
      .json()
      .then((json) => json)
      .catch(() => null);
  } catch (error) {
    if (error?.code && parseInt(error?.code) < 500) {
      throw error;
    }

    const shouldBypassRetries = [
      FetchCommonErrorsEnum.ABORTED_REQUEST,
      FetchCommonErrorsEnum.INVALID_INPUT_ERROR,
    ];
    if (!shouldBypassRetries.includes(error?.message) && maxRetries > 0) {
      console.info('retrying request...' + maxRetries);
      // sleep for 2 seconds
      await new Promise((resolve) => setTimeout(resolve, 2000));
      return _directFetchWithMethod(
        endpoint,
        method,
        body,
        signal,
        --maxRetries
      );
    }

    throw error;
  }
}

async function _fetch(endpoint, getFetchResponse, maxRetries = 5) {
  let accessToken;

  if (AppStore.isOnGeotabDashboard()) {
    accessToken = await PitstopCookie.get('accessToken');
  } else {
    accessToken = PitstopCookie.get('accessToken');
  }

  if (
    !['/', '/login', '/login/geotab', '/register', '/reset-password', '/callback'].includes(
      window.location.pathname
    ) &&
    !accessToken &&
    !window.location.search.includes('code') // This is for the oauth flow
  ) {
    AppStore.addError('Your token has expired. Please log in again!');

    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
    return;
  }
  const transformed = _transformUrl(endpoint);
  try {
    const response = await getFetchResponse(transformed);
    const data = await response.json();

    // If token is expired, refresh the token and try again
    let refreshToken;
    if (AppStore.isOnGeotabDashboard()) {
      refreshToken = await PitstopCookie.get('refreshToken');
    } else {
      refreshToken = PitstopCookie.get('refreshToken');
    }
    if (response.status === 401 && refreshToken) {
      await CurrentUserStore.loginWithRefreshToken(refreshToken);
      return _fetch(endpoint, getFetchResponse);
    }

    if (!response.ok) {
      throw data;
    }
    return data;
  } catch (error) {
    // if error code is lower than 500, throw error
    if (error?.code && parseInt(error?.code) < 500) {
      throw error;
    }
    const shouldBypassRetries = [FetchCommonErrorsEnum.ABORTED_REQUEST];
    if (!shouldBypassRetries.includes(error?.message) && maxRetries > 0) {
      console.info('retrying request...' + maxRetries);
      // sleep for 2 seconds
      await new Promise((resolve) => setTimeout(resolve, 2000));
      return _fetch(endpoint, getFetchResponse, --maxRetries);
    }
    throw error;
  }
}

async function _fetchWithMethod(
  endpoint,
  body,
  method,
  signal,
  shouldReturnErrResponse = false
) {
  const data = await _fetch(endpoint, async (transformedUrl) => {
    let accessToken;

    if (AppStore.isOnGeotabDashboard()) {
      accessToken = await PitstopCookie.get('accessToken');
    } else {
      accessToken = PitstopCookie.get('accessToken');
    }

    const response = await fetch(transformedUrl, {
      body: JSON.stringify(body),
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'client-id': process.env.REACT_APP_CLIENT_ID,
      },
      method,
      ...(signal && { signal }),
    });
    return response;
  });

  return data;
}

async function _get(endpoint, params = {}, signal) {
  const data = await _fetch(endpoint, async (transformedUrl) => {
    const url = new URL(transformedUrl);
    url.search = new URLSearchParams(params);
    let accessToken;

    if (AppStore.isOnGeotabDashboard()) {
      accessToken = await PitstopCookie.get('accessToken');
    } else {
      accessToken = PitstopCookie.get('accessToken');
    }

    const response = await fetch(url, {
      mode: 'cors',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'client-id': process.env.REACT_APP_CLIENT_ID,
      }),
      ...(signal && { signal }),
    });
    return response;
  });
  return data;
}

async function _getUsingUrlOnly(url, signal) {
  const data = await _fetch(url, async (transformedUrl) => {
    let accessToken;

    if (AppStore.isOnGeotabDashboard()) {
      accessToken = await PitstopCookie.get('accessToken');
    } else {
      accessToken = PitstopCookie.get('accessToken');
    }

    const response = await fetch(transformedUrl, {
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'client-id': process.env.REACT_APP_CLIENT_ID,
      },
      ...(signal && { signal }),
    });
    return response;
  });
  return data;
}

async function _patch(endpoint, body) {
  return _fetchWithMethod(endpoint, body, 'PATCH');
}

async function _post(endpoint, body, signal, shouldReturnErrResponse = false) {
  return _fetchWithMethod(
    endpoint,
    body,
    'POST',
    signal,
    shouldReturnErrResponse
  );
}

async function _postFormData(endpoint, formData) {
  const data = await _fetch(endpoint, async (transformedUrl) => {
    let accessToken;

    if (AppStore.isOnGeotabDashboard()) {
      accessToken = await PitstopCookie.get('accessToken');
    } else {
      accessToken = PitstopCookie.get('accessToken');
    }

    const response = await fetch(transformedUrl, {
      body: formData,
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'client-id': process.env.REACT_APP_CLIENT_ID,
      },
    });
    return response;
  });
  return data;
}

async function _fetchCSV(endpoint, params, signal) {
  const transformedUrl = _transformUrl(endpoint);
  const url = new URL(transformedUrl);
  url.search = new URLSearchParams(params);

  let accessToken;

  if (AppStore.isOnGeotabDashboard()) {
    accessToken = await PitstopCookie.get('accessToken');
  } else {
    accessToken = PitstopCookie.get('accessToken');
  }

  const response = await fetch(url, {
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'text/csv',
      'client-id': process.env.REACT_APP_CLIENT_ID,
    },
    ...(signal && { signal }),
  });

  if (!response.ok) {
    throw response.statusText;
  }

  const data = await response.blob();
  return data;
}

async function getPDF(endpoint, signal) {
  let accessToken;

  if (AppStore.isOnGeotabDashboard()) {
    accessToken = await PitstopCookie.get('accessToken');
  } else {
    accessToken = PitstopCookie.get('accessToken');
  }

  const resp = await fetch(_transformUrl(endpoint), {
    method: 'GET',
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/pdf',
      'client-id': process.env.REACT_APP_CLIENT_ID,
    },
    ...(signal && { signal }),
  });
  if (!resp.ok) {
    throw resp.statusText;
  }
  const data = await resp.blob();
  return data;
}

async function _put(endpoint, body) {
  return _fetchWithMethod(endpoint, body, 'PUT');
}

function _transformUrl(url) {
  return `${_apiBaseUrl}${_prefix}${url}`;
}
