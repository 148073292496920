import React from 'react';
import {
  Form,
  Input,
  Button,
  Col,
  notification,
  Row,
  Select,
  Tooltip,
  Icon,
} from 'antd';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { RolesOptions } from 'stores/Classes/UserObject';

const { Password } = Input;

class UserFormWithUsername extends React.Component {
  static propTypes = {
    form: PropTypes.object,
    onSubmit: PropTypes.func,
    initialData: PropTypes.object,
    currentShopName: PropTypes.string,
    hideVin: PropTypes.bool,
    shops: PropTypes.array,
  };

  phoneInputRef = React.createRef();

  componentDidMount() {
    const { form, initialData } = this.props;
    if (initialData) {
      form.setFieldsValue({
        phone: initialData.phone,
      });
    }
  }

  onClickSave = () => {
    // get the form values and validate
    this.props.form.validateFields((err, values) => {
      if (err) {
        notification.error({
          message: 'Error',
          description: 'Please fill in all required fields',
        });
        return;
      }

      this.props.onSubmit({
        ...values,
        id: this.props.initialData?.id || null,
      });
      // clear the form
      this.props.form.resetFields();
      // manually clear the phone input
      if (this.phoneInputRef.current) {
        this.phoneInputRef.current.value = '';
      }
      // UI clear the phone
      setTimeout(() => {
        document.getElementById('add_user_with_username_phone').value = '';
      }, 1);
    });
  };

  handlePhoneChange = (e) => {
    const { form } = this.props;
    form.setFieldsValue({ phone: e.target.value });
  };

  render() {
    const { form, currentShopName, shops } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form layout="horizontal">
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              {getFieldDecorator('firstName', {
                initialValue: this.props.initialData?.firstName,
                rules: [
                  { required: true, message: 'Please enter the First Name' },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              {getFieldDecorator('lastName', {
                initialValue: this.props.initialData?.lastName,
                rules: [
                  { required: true, message: 'Please input the Last Name' },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              label="Email/Username"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {getFieldDecorator('email', {
                  initialValue: this.props.initialData?.email,
                  rules: [
                    {
                      required: true,
                      message: 'The username is required',
                    },
                  ],
                })(<Input style={{ flex: 1 }} />)}
                <Tooltip title="If email is provided, an invitation email with the created password will be sent. If a username is provided, user(s) will log in with the username and password created.">
                  <Icon type="info-circle" style={{ marginLeft: 8 }} />
                </Tooltip>
              </div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Set Password"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {getFieldDecorator('password', {
                  initialValue: this.props.initialData?.password,
                  rules: [
                    {
                      required: true,
                      message: 'The password is required',
                    },
                  ],
                })(<Password style={{ flex: 1 }} />)}
                <Tooltip title="If a username is used, the password can be edited from the user’s profile page if they forget it.">
                  <Icon type="info-circle" style={{ marginLeft: 8 }} />
                </Tooltip>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              label="Fleet"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              {shops.length > 0
                ? getFieldDecorator('fleet', {
                    initialValue: this.props.initialData?.fleet,
                    rules: [
                      { required: true, message: 'Please select a fleet' },
                    ],
                  })(
                    <Select
                      showSearch
                      placeholder="Select a fleet"
                      optionFilterProp="children"
                      style={{ width: '100%' }}
                      allowClear
                      filterOption={(input, option) => {
                        return (
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {shops.map((shop) => (
                        <Select.Option key={shop.id} value={shop.id}>
                          {shop.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )
                : getFieldDecorator('fleet', {
                    initialValue: currentShopName,
                    rules: [
                      { required: true, message: 'Please select a fleet' },
                    ],
                  })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Role"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              {getFieldDecorator('role', {
                initialValue: this.props.initialData?.role,
                rules: [{ required: true, message: 'Please select a role' }],
              })(
                <Select
                  placeholder="Select a role"
                  style={{ width: '100%' }}
                  allowClear
                >
                  {RolesOptions.map((role) => (
                    <Select.Option key={role.id} value={role.id}>
                      {role.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              label="Phone Number"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              {getFieldDecorator('phone', {
                initialValue: this.props.initialData?.phone,
                rules: [
                  {
                    pattern: /^\+1 \(\d{3}\) \d{3}-\d{4}$/,
                    // accept any coutry code
                    // pattern: /^\+\d{1,2} \(\d{2,3}\) \d{3,5}-\d{4}$/,
                    message: 'Please enter a valid phone number',
                  },
                ],
              })(
                <InputMask
                  id="phoneWithMask"
                  mask="+1 (999) 999-9999"
                  maskChar={null}
                  onChange={this.handlePhoneChange}
                >
                  {(inputProps) => (
                    <Input {...inputProps} ref={this.phoneInputRef} />
                  )}
                </InputMask>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button
              type="secondary"
              style={{ float: 'right' }}
              onClick={this.onClickSave}
            >
              Add to list
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedUserFormWithUsername = Form.create({
  name: 'add_user_with_username',
})(UserFormWithUsername);

export default WrappedUserFormWithUsername;
