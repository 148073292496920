import React from 'react';

import { Row, Col, Typography, Card, Progress, Spin, Tooltip } from 'antd';
import PropTypes from 'prop-types';

const { Text } = Typography;

const VehicleAvailabilityCard = ({
  isLoadingVehicleAvailability,
  vehicleAvailability,
  addFiltersToQuery,
}) => {
  return (
    <Card className="summary-card">
      <div className="summary-card__title">
        <Text strong>
          Vehicle Availability{' '}
          <span className="filter-helper">
            (Click on any status below to filter by)
          </span>
        </Text>
      </div>

      <Row
        className="vehicleAvailability list"
        style={{ marginTop: '1rem' }}
        gutter={[8, 8]}
      >
        {isLoadingVehicleAvailability ? (
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin size="large" />
          </Col>
        ) : (
          <Col className="item progress-item-wrapper" span={24}>
            {vehicleAvailability.map((item, index) => (
              <Tooltip key={index} placement="top" title={'Click to filter by'}>
                <div
                  style={{
                    padding: '0.3rem',
                  }}
                  className="progress-item-wrapper__item"
                  onClick={() => addFiltersToQuery('status', item.title)}
                >
                  <Progress
                    type="circle"
                    width={100}
                    format={(percent) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxWidth: '100px',
                          }}
                        >
                          <span
                            style={{
                              color: item.color,
                              fontSize: '1.2rem',
                            }}
                          >{`${percent.toFixed(2)}%`}</span>
                          <span
                            style={{
                              color: item.color,
                              fontSize: '0.9rem',
                              marginTop: '0.5rem',
                            }}
                          >
                            {item.count} units
                          </span>
                        </div>
                      );
                    }}
                    percent={item.value}
                    strokeColor={item.color}
                  />
                  <div
                    style={{
                      color: item.color,
                    }}
                    className="progress-item-wrapper__item__title"
                  >
                    {item.title}
                  </div>
                </div>
              </Tooltip>
            ))}
          </Col>
        )}
        {!isLoadingVehicleAvailability && vehicleAvailability.length === 0 && (
          <Col
            span={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text type="secondary">No Vehicle Availability to Display</Text>
          </Col>
        )}
      </Row>
    </Card>
  );
};

VehicleAvailabilityCard.propTypes = {
  isLoadingVehicleAvailability: PropTypes.bool,
  vehicleAvailability: PropTypes.array,
  addFiltersToQuery: PropTypes.func,
};

VehicleAvailabilityCard.defaultProps = {
  isLoadingVehicleAvailability: false,
  vehicleAvailability: [],
  addFiltersToQuery: () => {},
};

export default VehicleAvailabilityCard;
