import React, { Component, createRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import DealershipChooser from 'components/DealershipChooser'; // Importing DealershipChooser component
import { withRouter } from 'react-router-dom';
import PMTable from '../Vehicles/PMTable';
import IntervalSelector from './IntervalSelector';
const { TabPane } = Tabs;

class ServiceIntervalPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 'tab1', // Set the default tab
    };

    // Create a reference to PMTable
    this.pmTableRef = createRef();
  }

  componentDidMount() {
    // Check the URL to decide which tab to show
    const { location } = this.props;
    if (location.pathname === '/service-interval-new') {
      this.setState({ activeKey: 'tab2' }); // Set to "Schedule New Service"
    } else {
      this.setState({ activeKey: 'tab1' }); // Default to "View Current Services"
    }
  }

  changeTab = activeKey => {
    this.setState({ activeKey }, () => {
      // If switching back to tab1, refresh PMTable data
      if (activeKey === 'tab1' && this.pmTableRef.current) {
        this.pmTableRef.current.fetchData(1, 8, {}, []); // Reset filters and refresh data
      }
    });
  };

  // Function to render the content of the first tab
  renderCurrentServicesTab = () => (
    <div style={{ padding: 20, textAlign: 'center' }}>
      <PMTable
        ref={this.pmTableRef}
        onIssuesListChange={() => {
          // Custom functionality when issues list changes
          //this.handleSearchChangeAndLoadVehicles();
        }}
      />
    </div>
  );

  // Function to render the content of the second tab
  renderScheduleNewServiceTab = () => (
    <div style={{ padding: 20, textAlign: 'center' }}>
       <IntervalSelector onCancel={() => this.changeTab('tab1')} />
    </div>
  );

  render() {
    return (
      <div>
        <h1>Service Intervals</h1>

        {/* DealershipChooser component added */}
        <DealershipChooser />

        {/* Tabs to switch between pages */}
        <Tabs
          activeKey={this.state.activeKey}
          size="large"
          onChange={this.changeTab}
        >
          <TabPane tab="View Current Services" key="tab1">
            {this.renderCurrentServicesTab()}
          </TabPane>
          <TabPane tab="Schedule New Service" key="tab2">
            {this.renderScheduleNewServiceTab()}
          </TabPane>
        </Tabs>
      </div>
    );
  }

  static propTypes = {
    history: PropTypes.object,
  };
}

export default withRouter(observer(ServiceIntervalPage));
