import { observable, decorate, action, transaction } from 'mobx';

class AsyncData {
  loaded = false;
  pending = false;
  isError = false;
  errorMessage = '';
  data = [];
  count = 0;

  setError = message => {
    transaction(() => {
      this.loaded = true;
      this.pending = false;
      this.isError = true;
      this.errorMessage = message;
    });
  };

  resetError = () => {
    this.isError = false;
  };

  reset = () => {
    transaction(() => {
      this.loaded = false;
      this.pending = false;
      this.isError = false;
      this.data = [];
      this.count = 0;
    });
  };
}
export default decorate(AsyncData, {
  pending: observable,
  loaded: observable,
  isError: observable,
  errorMessage: observable,
  data: observable,
  count: observable,
  setError: action,
  resetError: action,
  reset: action
});
