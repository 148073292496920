import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Card = styled.div`
  position: relative;
  height: 150px;
  width: 150px;
  margin: 5px;
  padding: 0;
  box-shadow: 0 0 10px 3px #f2f2f2;
  border-radius: 15px;
`;

const SelectedCard = styled.div`  
  position: relative;
  height: 150px;
  width: 150px;
  margin: 5px;
  padding: 0;
  box-shadow: 0 0 10px 3px #f2f2f2;
  border-radius: 15px;
  border: 3px solid #4d6fcf;
`;

const AlgorithmIconCard = (props) => {
  return (
    props.selected ? 
      <SelectedCard>
        {props.cardBody}
      </SelectedCard> :
      <Card onClick={props.selectFunction}>
        {props.cardBody}
      </Card>
  );
};

AlgorithmIconCard.propTypes = {
    selected: PropTypes.bool,
    cardBody: PropTypes.any,
    selectFunction: PropTypes.func
};

export default AlgorithmIconCard;