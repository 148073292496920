import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import _ from 'lodash';

import { Row, Col, Card } from 'antd';
import Image from './Image';

class ImagePicker extends Component {
  state = {
    picked: Map(),
  };

  componentDidMount() {
    this.props.setCallables({
      clearImagePick: this.clearImagePick,
    });
  }

  // return Card with string "Others"
  othersCard = () => {
    return (
      <Card
        hoverable
        style={{
          height: '245px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h3>Others</h3>
      </Card>
    );
  };

  // if this.props.multiple is true, you can pick multiple images
  // otherwise, only one
  handleImagePick = image => {
    const { multiple, getPickedImage } = this.props;
    let pickedImage = this.state.picked;

    let newerPickedImage;
    if (pickedImage.has(image.key)) {
      newerPickedImage = pickedImage.delete(image.key);
    } else {
      if (!multiple) {
        pickedImage = Map();
      }
      newerPickedImage = pickedImage.set(image.key, image.imgSrc);
    }
    this.setState({ picked: newerPickedImage });

    const pickedImageToArray = [];
    newerPickedImage.map((image, key) => {
      return pickedImageToArray.push({ key, value: image });
    });

    getPickedImage &&
      getPickedImage(multiple ? pickedImageToArray : pickedImageToArray[0]);
  };

  // clear the picked image
  clearImagePick = () => {
    const pickedImage = this.state.picked.clear();
    this.setState({ picked: pickedImage });
  };

  render() {
    return (
      <>
        <Row gutter={[16, 16]}>
          {_.map(this.props.imageList, (imgSrc, key) => {
            return (
              <Col key={key} span={4}>
                <Image
                  handleImagePick={() =>
                    this.handleImagePick({
                      key,
                      imgSrc,
                    })
                  }
                  isSelected={this.state.picked.has(key)}
                  imgSrc={imgSrc}
                />
              </Col>
            );
          })}
        </Row>
      </>
    );
  }

  static propTypes = {
    imageList: PropTypes.array,
    multiple: PropTypes.bool,
    getPickedImage: PropTypes.func,
    setCallables: PropTypes.func,
  };
}

export default ImagePicker;
