import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import validator from 'validator';
import _ from 'lodash';

import { Checkbox, Row, Col, notification, Spin, Input, Form } from 'antd';
import { ErrorField } from 'v3/components/pitstop';

import { AppStore, CurrentUserStore, ShopStore, UserStore } from 'stores';
import Logger from 'stores/Classes/Logger';

const eulaFile = require('containers/LicenseAgreement/pitstop-eula.pdf');

const Container = styled(Form)`
  padding-right: 7vw;

  h1 {
    font-size: 2.8rem;
    font-weight: 600;
    color: #000000;
  }

  p {
    font-weight: 400;
    color: #8c8c8c;
    opacity: 0.9;
  }

  label {
    font-weight: 600;
    display: block;
  }

  input {
    margin: 1.4vh 0vh;
  }
`;

const DashLine = styled.div`
  opacity: 0.2;
  border-top: 2px dashed #000000;
`;

const RegisterButton = styled.button`
  cursor: pointer;
  border: none;
  background: #02103d;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: #ffffff;

  height: 3rem;
  min-width: 8rem;
  margin-top: 2vh;
`;

class RegisterForm extends Component {
  static propTypes = {
    isUserRegister: PropTypes.func,
  };

  state = {
    email: null,
    password: null,
    fleetName: null,
    isAcceptedAgreement: false,
    isSubmitted: false,
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getError = (type, value) => {
    if (_.isEmpty(value) || _.isNil(value)) {
      return <ErrorField>{type} is required!</ErrorField>;
    }

    if (type === 'email' && !validator.isEmail(value)) {
      return <ErrorField>Email is invalid</ErrorField>;
    }

    if (type === 'password' && value.match(/(\s)/g)) {
      return <ErrorField>Password cannot have blank spaces</ErrorField>;
    }

    return null;
  };

  onSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      isSubmitted: true,
    });

    const { email, password, fleetName, isAcceptedAgreement } = this.state;

    // we should validate the form before submit
    if (
      this.getError('email', email) ||
      this.getError('password', password) ||
      this.getError('fleetName', fleetName) ||
      !isAcceptedAgreement
    ) {
      return;
    }

    this.props.isUserRegister(true);

    notification.open({
      key: 'register',
      description: (
        <>
          <Spin size="large" style={{ marginRight: '0.6rem' }} /> Your account
          is being created...
        </>
      ),
      duration: 0,
    });

    try {
      // create the user
      try {
        await UserStore.createUser({
          username: email,
          email: email,
          password: password,
          role: 'dealership',
        });
      } catch (err) {
        notification.error({
          message: 'Error creating user',
          key: 'error',
          description: <Container>{err.message}. Please, try again</Container>,
        });
        throw err;
      }

      // show a success message
      AppStore.addSuccess(
        'Successfully added user, logging into dashboard now...'
      );

      // login the user
      let user;
      try {
        // force logout to clear cookies and tokens
        // CurrentUserStore.logout();
        user = await CurrentUserStore.login(email, password);
      } catch (err) {
        notification.error({
          message: 'Error logging in',
          key: 'error',
          description: <Container>{err.message}. Please, manually login</Container>,
        });
        throw err;
      }

      // add the shop
      try {
        await ShopStore.addShop(
          {
            userId: user.id,
            name: fleetName,
            email: email,
            address: '',
          }
        );
      } catch (err) {
        notification.error({
          message: 'Error adding shop',
          key: 'error',
          description: <Container>{err.message}. Please, contact us</Container>,
        });
        throw err;
      }

      // remove the creating notification
      notification.close('register');
      this.props.isUserRegister(false);

      // update current user settings
      try {
        await CurrentUserStore.getCurrentUser();
        await CurrentUserStore.createHubspotContact({
          email: email,
          form_name: 'dashboard-registeration',
        });
        await CurrentUserStore.user.updateSettings({
          hasAcceptedEULA: true,
        });
      } catch (err) {
        notification.error({
          message: 'Error updating user settings',
          key: 'error',
          description: <Container>{err.message}. Please, manually login</Container>,
        });
        throw err;
      }
    } catch (err) {
      // if there is an error, we should log it
      Logger.error(err.message, { fleetName, email });
      notification.close('register');
      this.props.isUserRegister(false);
    }
  };

  render() {
    const {
      email,
      password,
      fleetName,
      isSubmitted,
      isAcceptedAgreement,
    } = this.state;

    return (
      <Container id="register-form">
        <Row>
          <Col>
            <h1>Create a free account</h1>
          </Col>

          <Col>
            <p>
              Ready to take your maintenance strategy to the next level? Get
              started below
            </p>

            <DashLine />
          </Col>

          <Col style={{ margin: '1.6vw 0vw' }}>
            <label>Work email</label>
            <Input
              name="email"
              placeholder="yourname@companyname.com"
              onChange={this.onChange}
            />
            {isSubmitted && this.getError('email', email)}
          </Col>

          <Col style={{ margin: '1.6vw 0vw' }}>
            <label>Set new password</label>
            <Input
              name="password"
              type="password"
              placeholder="Min 6 characters"
              onChange={this.onChange}
            />
            {isSubmitted && this.getError('password', password)}
          </Col>

          <Col style={{ margin: '1.6vw 0vw' }}>
            <label>Fleet Name</label>
            <Input
              name="fleetName"
              placeholder="ABC Fleet"
              onChange={this.onChange}
            />
            {isSubmitted && this.getError('fleetName', fleetName)}
          </Col>

          <Col
            span={24}
            style={{
              display: 'flex',
            }}
          >
            <Checkbox
              name="isAcceptedAgreement"
              style={{ marginRight: '0.6vw' }}
              onClick={() =>
                this.setState({
                  isAcceptedAgreement: !isAcceptedAgreement,
                })
              }
            />
            <p>
              I agree with{' '}
              <a
                style={{
                  fontWeight: 600,
                  color: '#000000',
                  fontStyle: 'italic',
                }}
                href={eulaFile}
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </a>
            </p>
          </Col>
          {isSubmitted && !isAcceptedAgreement && (
            <ErrorField style={{ textAlign: 'left', marginBottom: '1.6vw' }}>
              You must accept Terms and Conditions
            </ErrorField>
          )}

          <Col span={24}>
            <RegisterButton
              isActive={email && password && isAcceptedAgreement}
              onClick={this.onSubmit}
            >
              Register
            </RegisterButton>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default RegisterForm;
