import { observable, decorate, action, computed, transaction } from 'mobx';
import _ from 'lodash';
import moment from 'moment';

import { AppStore, UserStore, IssueStore, ShopStore, CurrentUserStore } from 'stores';
import { AsyncObject, AsyncData } from 'stores/abstract';
import { TripObject, IssueObject } from 'stores/Classes';

import { webServiceProvider, download, print } from 'shared';
import { showDemoVinOrRealVin } from 'shared/utils';
import { convertSecondsToHours } from 'helpers/unitCalculations';

class CarObject {
  loaded = false;
  pids = new AsyncData();
  emission = new AsyncData();
  trips = new AsyncData();
  service_appointment = new AsyncData();
  reports = new AsyncData();
  issues = {
    active: new AsyncData(),
    upcoming: new AsyncData(),
    done: new AsyncData(),
  };
  tireAlerts = {
    comparison: new AsyncData(),
    slowLeak: new AsyncData(),
  };
  integrationStatus = new AsyncData();

  alerts = new Map();

  currentEngineLightStatus = '';

  vinValue = ''

  set vin (value) {
    this.vinValue = value;
  }

  get vin () {
    return showDemoVinOrRealVin(this.vinValue);
  }

  constructor(jsonObject) {
    // eslint-disable-next-line no-unused-vars
    const { shop, user, issues, ...actualObject } = jsonObject;
    Object.assign(this, actualObject);
    this.pidFetchController = new AbortController();
    this.pidFetchSignal = this.pidFetchController.signal;

    this.emissionFetchController = new AbortController();
    this.emissionFetchSignal = this.emissionFetchController.signal;

    this.generalReportController = new AbortController();
    this.generalReportSignal = this.generalReportController.signal;

    this.getTripsController = new AbortController();
    this.getTripsSignal = this.getTripsController.signal;

    this.engineLightController = new AbortController();
    this.engineLightSignal = this.engineLightController.signal;

    this.detailController = new AbortController();
    this.detailSignal = this.detailController.signal;

    this.integrationStatusController = new AbortController();
    this.integrationStatusSignal = this.integrationStatusController.signal;

    if (Object.keys(actualObject).length === 0) {
      return;
    }
    this.loaded = true;
    this.shopId = jsonObject.shop ? jsonObject.shop.id : null;
    this.shopName = jsonObject.shop ? jsonObject.shop.name : null;
    if (jsonObject.issues) {
      this.issues.active.loaded = true;
      this.newIssues = jsonObject.issues
        .filter((el) => el.status === 'new');
      this.routineServicePresent = this.newIssues.find(el => el.issueType === 'routine') ? true : false;
      this.newDtcs = this.newIssues
        .filter(el => el.issueType === 'dtc' || el.issueType === 'spn' || el.issueType === 'algorithm');
      this.priorities = this.newDtcs.map(item => item['priority']);
      this.maxPriority = Math.max(...this.priorities);
      this.adjPriorities = this.newDtcs.map(item => item['adjPriority']);
      this.maxAdjPriority = Math.max(...this.adjPriorities);
      this.newMajorIssues = this.newDtcs.filter(item => item['priority'] >= 2);
      this.majorIssueDates = this.newMajorIssues.map(item => item['createdAt']);
      this.maxMajorIssueDate = new Date(Math.max(...this.majorIssueDates.map(date => new Date(date))));

      this.issues.active.data = this.newIssues
        .map((it) => {
          IssueStore.addIssueToStore(
            Number(it.id),
            new IssueObject({
              ...it,
              car: { id: this.id, name: this.carName, vin: this.vin },
            })
          );
          return it.id;
        });
      this.issues.done.loaded = true;
      this.issues.done.data = jsonObject.issues
        .filter((el) => el.status === 'done')
        .map((it) => {
          IssueStore.addIssueToStore(
            Number(it.id),
            new IssueObject({
              ...it,
              car: { id: this.id, name: this.carName, vin: this.vin },
            })
          );
          return it.id;
        });
    }

    this.detailReport = new AsyncObject();
    this.engineLightStatus = new AsyncObject();
    this.generalReport = new AsyncObject();
    this.routineAppointments = new AsyncData();
    // this.getVehicleGeneralReport();
  }

  getAppointments = async () => {
    try {
      this.service_appointment.resetError();
      this.service_appointment.loaded = false;
      this.service_appointment.data.clear();

      const { results: appointments } = await webServiceProvider.getMany(
        `car/${this.id}/appointments`
      );

      transaction(() => {
        this.service_appointment.data.replace(appointments);
        this.service_appointment.loaded = true;
      });
    } catch (e) {
      this.service_appointment.setError('Error loading appointments.');
    }
  };

  getTrips = async (fromDate, toDate, offset, pageSize, loadAlarms) => {
    await this.getTripsController.abort();

    this.getTripsController = new AbortController();
    this.getTripsSignal = this.getTripsController.signal;

    this.trips.resetError();
    let params = { vin: this.vin };
    if (typeof fromDate === 'number') {
      params.fromRTC = fromDate;
    }
    if (typeof toDate === 'number') {
      params.toRTC = toDate;
    }
    if (offset) {
      params.offset = offset;
    }
    if (pageSize) {
      params.pageSize = pageSize;
    }
    if (loadAlarms === false) {
      params.exclude = 'alarms';
    }
    try {
      this.trips.loaded = false;
      this.trips.data.clear();

      const {
        meta: metadata,
        response: trips,
      } = await webServiceProvider.getMany(
        'v1/trip',
        params,
        this.getTripsSignal
      );

      this.trips.data.replace(
        trips
          .sort((a, b) => b.timeStart - a.timeStart)
          .map((el) => new TripObject(el))
      );
      this.trips.loaded = true;
      this.trips.count = metadata.count || 0;
    } catch (e) {
      this.trips.setError('Error loading trip data.');
    }
  };

  /**
   *
   * @param options {object}
   * @param options.trip - trip object
   * @param [options.includePitstopMetaData] {boolean}
   * @returns {Promise<void>}
   */
  downloadSensorDataCSV = async (options) => {
    try {
      const { trip } = options;
      const includePitstopMetaData = options.includePitstopMetaData || false;
      const { make, model, year, vin } = this;
      const from = moment(trip.timeStart * 1000).toISOString();
      const to = moment(trip.timeEnd * 1000).toISOString();
      const order = 'ASC';
      const skipCount = 1;
      const outputFileTimestamp = moment(trip.timeStart * 1000).format(
        'YYYY-MM-DD h-mm-ssa'
      );
      const downloadFileName = `${year} ${make} ${model} - ${outputFileTimestamp}.csv`;
      const response = await webServiceProvider.fetchCSV('scan/pids/download', {
        vin,
        from,
        to,
        order,
        skipCount,
        includePitstopMetaData,
      });
      download(response, downloadFileName, 'text/csv');
    } catch (e) {
      throw Error('Unable to download sensor data!');
    }
  };

  /**
   *
   * @param options {object}
   * @param options.includePitstopMetaData {boolean}
   */
  downloadTripsCSV = async (options) => {
    try {
      const response = await webServiceProvider.fetchCSV(
        'v1/trip/summary/download',
        {
          vin: this.vin,
          includePitstopMetaData: options.includePitstopMetaData,
          offset: options.offset,
          pageSize: options.pageSize,
        }
      );
      download(response, 'trip.csv', 'text/csv');
    } catch (e) {
      throw Error('Unable to download trips data');
    }
  };

  getHealthReport = async () => {
    this.reports.resetError();
    try {
      this.reports.loaded = false;
      const { response: reports } = await webServiceProvider.getMany(
        `/data/v1/report?carId=${this.id}`
      );
      this.reports.data = reports;
      this.reports.loaded = true;
    } catch (e) {
      this.reports.setError('Error loading health reports.');
    }
  };

  getReports = async () => {
    this.reports.resetError();
    try {
      this.reports.loaded = false;
      const reports = await webServiceProvider.get(
        `v1/car/${this.id}/algoresults`
      );
      this.reports.data = reports;
      this.reports.loaded = true;
    } catch (e) {
      this.reports.setError('Error loading health reports.');
    }
  };

  getSensorData = async (
    startDate,
    endDate,
    downsample = 1,
    replace = true,
    cancel = false,
    sampleSize = 1000,
    allowedNull = false,
    excludeValues = ''
  ) => {
    this.pids.resetError();
    await this.pidFetchController.abort();
    this.pidFetchController = new AbortController();
    this.pidFetchSignal = this.pidFetchController.signal;
    try {
      if (!cancel) {
        if (replace) {
          this.pids.data = { data: {}, meta: {} };
          this.pids.loaded = false;
        }
        const pids = await webServiceProvider.getMany(
          'scan/pids',
          {
            vin: this.vin,
            from: startDate.toISOString(),
            to: endDate.toISOString(),
            order: 'ASC',
            skipCount: downsample,
            sampleSize: sampleSize,
            allowedNull,
            excludeValues,
          },
          this.pidFetchSignal
        );

        if (replace) {
          this.pids.data = pids;

          this.pids.loaded = true;
        } else {
          // we would need to replace the range of timestamps to the high res data
          Object.keys(this.pids.data.data).forEach((key) => {
            let found = false;
            this.pids.data.data[key] = this.pids.data.data[key].reduce(
              (prev, el) => {
                if (el[0] < startDate.valueOf() || el[0] > endDate.valueOf()) {
                  return prev.concat([el]);
                } else if (!found) {
                  found = true;
                  return prev.concat(pids.data[key] || []);
                } else {
                  return prev;
                }
              },
              []
            );
          });
        }
      } else {
        this.pids.reset();
      }
    } catch (e) {
      if (e.name === 'AbortError') {
        return;
      }

      if (e.error === 'ResourceNotFound') {
        this.pids.setError('There are no sensor data for this time range!');
        AppStore.addNotification(
          'There are no sensor data for this time range!'
        );

        return;
      }

      this.pids.setError('Error in loading sensor data!');
      AppStore.addError('Error in getting sensor data!');
    }
  };

  getLatestSensorData = async (cancel = false) => {
    await this.pidFetchController.abort();
    this.pidFetchController = new AbortController();
    this.pidFetchSignal = this.pidFetchController.signal;
    try {
      if (!cancel) {
        this.pids.resetError();
        this.pids.data = { data: {}, meta: {} };
        this.pids.loaded = false;

        const pids = await webServiceProvider.getMany(
          'sensordata/latest',
          {
            vin: this.vin,
          },
          this.pidFetchSignal
        );
        this.pids.data = pids;
        this.pids.loaded = true;
      } else {
        this.pids.reset();
      }
    } catch (e) {
      this.pids.setError('Error loading pid info.');
    }
  };

  getEmissionData = async (vin, startDate, endDate, replace) => {
    this.emission.resetError();
    await this.emissionFetchController.abort();
    this.emissionFetchController = new AbortController();
    this.emissionFetchSignal = this.emissionFetchController.signal;

    if (replace) {
      this.emission.data = { result: {}, meta: {} };
      this.emission.loaded = false;
    }
    try {
      const { data } = await webServiceProvider.getMany(
        'scan/aggregate',
        {
          vin,
          from: moment(startDate).toISOString(),
          to: moment(endDate).toISOString(),
        },
        this.emissionFetchSignal
      );
      this.emission.data = data;
      this.emission.loaded = true;
    } catch (e) {
      if (e.name === 'AbortError') {
        return;
      }
      this.emission.setError('Error in loading emission data');
      throw e;
    }
  };

  getIssues = async (type) => {
    if (type !== 'upcoming' && type !== 'done' && type !== 'active') {
      throw new Error('Invalid Query!');
    }
    // TODO: this is stupid AF.
    let queryType = type;
    if (type === 'done') {
      queryType = 'history';
    }
    this.issues[type].loaded = false;
    this.issues[type].resetError();
    try {
      const issues = await webServiceProvider.getMany(`car/${this.id}/issues`, {
        type: queryType,
      });
      if (type === 'all') {
        issues.results.forEach((el) => {
          if (el.status === 'pending') {
            return;
          }
          this.issues[el.status].data.replace(
            el.issues.map((it) => {
              IssueStore.addIssueToStore(
                Number(it.id),
                new IssueObject({
                  ...it,
                  car: { id: this.id, name: this.carName, vin: this.vin },
                })
              );
              return it.id;
            })
          );
        });
      } else if (type === 'upcoming') {
        this.issues['upcoming'].data.replace(
          issues.results[0].issues.map((it) => {
            IssueStore.addIssueToStore(
              Number(it.id),
              new IssueObject({
                ...it,
                car: { id: this.id, name: this.carName, vin: this.vin },
              })
            );
            return it.id;
          })
        );
      } else if (type === 'active') {
        this.issues['active'].data.replace(
          issues.results
            .filter((el) => el.status === 'new')
            .map((it) => {
              IssueStore.addIssueToStore(
                Number(it.id),
                new IssueObject({
                  ...it,
                  car: { id: this.id, name: this.carName, vin: this.vin },
                })
              );
              return it.id;
            })
        );
      }
      this.issues[type].loaded = true;
    } catch (e) {
      this.issues[type].resetError('Unable to Load in Services');
    }
  };

  setShop = async (shopId) => {
    const result = await webServiceProvider.post('v1/car/shop', {
      shopId,
      carId: this.id,
    });
    return result;
  };

  get user() {
    if (this.userId) {
      return UserStore.data.get(this.userId);
    }
    return undefined;
  }

  get mmy() {
    return `${this.make || ''} ${this.model || ''} ${this.year || ''}`;
  }

  /**
   * Unused Yet, will need to think about architecture.
   */
  setLocationData = (locationData) => {
    const { vin, carName, driver, location } = locationData;
    if (!this.loaded) {
      this.vin = vin;
      this.carName = carName;
      this.userId = driver.id;
      if (!UserStore.data.has(this.userId)) {
        UserStore.addUserToStore(this.userId, driver);
      }
    }
    this.location = {
      longitude: location.longitude,
      latitude: location.latitude,
      timestamp: location.timestamp,
    };
  };

  softDelete = async () => {
    try {
      await webServiceProvider.delete(`car?carId=${this.id}`);
      window.location.reload();
    } catch (err) {
      throw Error(err.message);
    }
  };

  getTireAlert = async () => {
    try {
      this.tireAlerts.comparison.resetError();
      this.tireAlerts.comparison.reset();

      this.tireAlerts.slowLeak.resetError();
      this.tireAlerts.slowLeak.reset();

      let { comparisonAlert, slowLeakAlert } = await webServiceProvider.get(
        `v1/car/${this.id}/tireAlert`
      );

      this.tireAlerts.comparison.setData(comparisonAlert.data);
      this.tireAlerts.slowLeak.setData(slowLeakAlert.data);
    } catch (err) {
      throw Error(err.message);
    }
  };

  downloadVehicleHealthReport = async (statuses, issuesLimit, issueSources) => {
    try {
      let data = await webServiceProvider.getPDF(
        `v1/cars/${this.id}/report?statuses=${statuses}&limit=${issuesLimit}&sources=${issueSources}&userId=${CurrentUserStore?.user?.id}`
      );
      download(data, `${this.vin} - Health Report`, 'application/pdf');
    } catch (err) {
      throw Error('Sorry! Unable to download the vehicle health report!');
    }
  };

  printVehicleHealthReport = async (statuses, issuesLimit, issueSources) => {
    try {
      let data = await webServiceProvider.getPDF(
        `v1/cars/${this.id}/report?statuses=${statuses}&limit=${issuesLimit}&sources=${issueSources}&userId=${CurrentUserStore?.user?.id}`
      );
      print(data, `${this.vin} - Health Report`, 'application/pdf');
    } catch (err) {
      console.log(err);
      throw Error('Sorry! Unable to print the vehicle health report!');
    }
  };

  downloadGMRVPidData = async ({ trip, order }) => {
    try {
      const { vin } = this;
      const from = moment(trip.timeStart * 1000).toISOString();
      const to = moment(trip.timeEnd * 1000).toISOString();
      const skipCount = 1;

      let excludeValues =
        'BatteryLevel,vin,#Satellites,tripId,tripIndicator,2Flow,2NDIR,2Operation,2Pump,2Sensors,FixType,Quality,deviceTimestamp';

      const response = await webServiceProvider.fetchCSV(
        'scan/pids/gmrv/download',
        {
          vin,
          from,
          to,
          order,
          skipCount,
          excludeValues,
          allowedNull: true,
          sampleSize: 300000,
        }
      );

      let fileName = `${moment(trip.timeStart * 1000).format(
        'YYYY-MM-DD'
      )} ${moment(trip.timeStart * 1000).format('LTS')} ${moment(
        trip.timeEnd * 1000
      ).format('LTS')}.csv`;

      download(response, fileName, 'text/csv');
    } catch (e) {
      if (e.error === 'ResourceNotFound') {
        throw Error('There are no sensor data for this time range!');
      }

      throw Error('Sorry! Unable to download PID data!');
    }
  };

  getAlert = async (alertType) => {
    let _alertType;

    switch (alertType) {
      case 'parkedBattery':
        _alertType = 'parked battery';
        break;
      default:
        _alertType = '';
    }

    try {
      if (_.isEmpty(alertType) || _.isNil(alertType))
        throw Error('Error! alertType is not specified!');

      if (this.alerts.has(alertType)) return this.alerts.get(alertType);

      let result = await webServiceProvider.get(
        `v1/car/${this.id}/alert?&alertType=${alertType}`
      );

      this.alerts.set(alertType, result);

      return this.alerts.get(alertType);
    } catch (err) {
      throw Error(`Sorry! We are unable to get ${_alertType} alert`);
    }
  };

  getEngineHours = () => {
    let { engineHours } = this;

    return engineHours
      ? Number(convertSecondsToHours(engineHours)).toFixed(1)
      : 0;
  };

  getVehicleGeneralReport = async () => {
    try {
      if (this.generalReport.loaded) return;

      this.generalReport.pending = true;

      this.generalReportController.abort();

      this.generalReportController = new AbortController();
      this.generalReportSignal = this.generalReportController.signal;

      let report = await webServiceProvider.get(
        `v1/cars/${this.id}/general-report`,
        this.generalReportSignal
      );
      this.generalReport.setData(report);
    } catch (err) {
      this.generalReport.setError(
        'Sorry! We are unable to load vehicle detail report!'
      );
    }
  };

  getEngineLightStatus = async () => {
    try {
      if (this.engineLightStatus.loaded) return;
      this.engineLightStatus.pending = true;

      this.engineLightController.abort();
      this.engineLightController = new AbortController();
      this.engineLightSignal = this.engineLightController.signal;
      let engineLightResponse = await webServiceProvider.get(
        `v1/car/${this.id}/engine-light-status`,
        this.engineLightSignal
      );
      this.engineLightStatus.setData(engineLightResponse);
    } catch (err) {
      this.engineLightStatus.setError(
        'Sorry! We are unable to load vehicle detail report!'
      );
    }
  }

  getVehicleDetailReport = async () => {
    try {
      if (this.detailReport.loaded) return;

      this.detailReport.pending = true;

      this.detailController.abort();

      this.detailController = new AbortController();
      this.detailSignal = this.detailController.signal;

      let report = await webServiceProvider.get(
        `v1/car/${this.id}/score`,
        this.detailSignal
      );
      let issues = await webServiceProvider.get(`v1/cars/${this.id}/issues?status=new&limit=100`);
      let newIssues = issues.data.filter(item => item['status'] === 'new' && (item['source'] === 'dtc' || item['source'] === 'algorithm'));
      let priorities = newIssues.map(item => item['priority']);
      let maxPriority = Math.max(...priorities);
      let adjPriorities = newIssues.map(item => item['adjPriority']);
      let maxAdjPriority = Math.max(...adjPriorities);
      let newMajorIssues = newIssues.filter(item => item['priority'] >= 2);
      let majorIssueDates = newMajorIssues.map(item => item['reportDate']);
      let maxMajorIssueDate = new Date(Math.max(...majorIssueDates.map(date => new Date(date))));
      let reportData = { ...report.data, maxPriority: maxPriority, maxAdjPriority: maxAdjPriority, maxMajorIssueDate: maxMajorIssueDate };

      this.detailReport.setData(reportData);
    } catch (err) {
      this.detailReport.setError(
        'Sorry! We are unable to load vehicle detail report!'
      );
    }
  };

  getTireAlert = async () => {
    try {
      this.tireAlerts.comparison.resetError();
      this.tireAlerts.comparison.reset();

      this.tireAlerts.slowLeak.resetError();
      this.tireAlerts.slowLeak.reset();

      let { comparisonAlert, slowLeakAlert } = await webServiceProvider.get(
        `v1/car/${this.id}/tireAlert`
      );

      this.tireAlerts.comparison.setData(comparisonAlert.data);
      this.tireAlerts.slowLeak.setData(slowLeakAlert.data);
    } catch (err) {
      throw Error(err.message);
    }
  };

  getRoutineAppointments = async (params) => {
    try {
      params = _.defaults(params, {
        offset: 0,
        limit: 20,
        source: 'routine',
        sort: '-priority',
        includeHistory: false,
      });

      this.routineAppointments.loaded = false;
      this.routineAppointments.pending = true;

      let { data } = await webServiceProvider.getMany(
        `v1/cars/${this.id}/issues`,
        {
          ...params,
        }
      );

      let shopId = ShopStore.currentShop.id;
      let source = 'routine';

      let result = _.map(data, (d) => {
        let title = `${d.car.name} ${_.join([d.action, d.item], ' - ')}`;

        if (
          d.status !== 'done' &&
          (!_.isNil(d.routineInfo.fixed_month) ||
            !_.isNil(d.routineInfo.started_month))
        ) {
          let data;

          if (d.status === 'new') {
            let date = d.reportDate;

            date = moment(date).set('hour', 8);
            date = moment(date).set('minute', 0);

            data = {
              title,
              appointment: {
                source,
                appointmentDate: moment(date).toISOString(),
                appointmentEndTime: moment(date)
                  .add(2, 'hours')
                  .toISOString(),
                carId: this.id,
                shopId,
                comments: _.join([d.action, d.item], ' - '),
                createdAt: moment(date).toISOString(),
              },
              start: moment(date).toDate(),
              end: moment(date)
                .add(2, 'hours')
                .toDate(),
            };
          }

          if (d.status === 'upcoming') {
            let date = d.routineInfo.fixed_month
              ? d.routineInfo.fixed_month
              : moment
                .unix(d.routineInfo.started_month)
                .add(d.routineInfo.interval_month, 'months')
                .unix();

            date = moment.unix(date).toISOString();

            date = moment(date).set('hour', 8);
            date = moment(date).set('minute', 0);

            data = {
              title,
              appointment: {
                source,
                appointmentDate: moment(date).toISOString(),
                appointmentEndTime: moment(date)
                  .add(2, 'hours')
                  .toISOString(),
                carId: this.id,
                shopId,
                comments: _.join([d.action, d.item], ' - '),
                createdAt: moment(date).toISOString(),
              },
              start: moment(date).toDate(),
              end: moment(date)
                .add(2, 'hours')
                .toDate(),
            };
          }

          return data;
        }
      });

      this.routineAppointments.data = _.filter([...result], Boolean);

      this.routineAppointments.loaded = true;
      this.routineAppointments.pending = false;
    } catch (err) {
      this.routineAppointments.setError('Error is getting routine services');
    }
  };

  getIntegrationStatus = async () => {
    this.integrationStatus.resetError();
    this.integrationStatusController = new AbortController();
    this.integrationStatusSignal = this.integrationStatusController.signal;
    this.integrationStatus.loaded = false;
    try {
      const { data } = await webServiceProvider.getMany(
        'v1/integrations/metrics/latest',
        { carId: this.id },
        this.integrationStatusSignal
      );
      if (this.integrationStatus.setData) {
        this.integrationStatus.setData(data);
      }

    }
    catch (err) {
      if (err.name === 'AbortError') {
        return;
      }
      console.error(err);
      throw new Error('cannot load vehicle integration status');
    }
  }
}

export default decorate(CarObject, {
  loaded: observable,
  issues: observable,
  trips: observable,
  shopId: observable,
  shopName: observable,
  totalMileage: observable,
  issueCounts: observable,
  engineLightStatus: observable,
  carName: observable,
  scanner: observable,
  userId: observable,
  make: observable,
  model: observable,
  year: observable,
  alerts: observable,
  user: computed,
  mmy: computed,
  getAppointments: action,
  getEngineLightStatus: action,
  getTrips: action,
  getHealthReport: action,
  getSensorData: action,
  getLatestSensorData: action,
  getIssues: action,
  setLocationData: action,
  getAlert: action,
  getEngineHours: action,
  routineAppointments: observable,
});
