import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { UserRoles } from 'stores/Classes/UserObject';
import { ShopStore, CurrentUserStore } from 'stores';

import { SelectableCarListTable } from 'containers/CarListTable';
import { DealershipChooser } from 'components';

class AppointmentSelectUsersSection extends React.Component {
  static propTypes = {
    selectedCars: PropTypes.array,
    setValue: PropTypes.func.isRequired,
  };

  render() {
    return (
      <React.Fragment>
        <h1>Vehicle Selection</h1>
        <DealershipChooser />

        <p>
          <b>You can only select 1 car</b>
        </p>

        <h3>Select Vehicles:</h3>
        <SelectableCarListTable
          userId={
            CurrentUserStore.user.role === UserRoles.customer
              ? CurrentUserStore.user.id
              : undefined
          }
          id={`${ShopStore.currentShop.id}-cars-list`}
          setValue={(key, val) =>
            this.props.setValue(
              key,
              val.length === 2 ? [val[1]] : val.length === 1 ? [val[0]] : []
            )
          }
          selectedCars={this.props.selectedCars}
        />
      </React.Fragment>
    );
  }
}

export default observer(AppointmentSelectUsersSection);
