import React from 'react';
import PropTypes from 'prop-types';
import { Table, Tag, Tooltip, Icon } from 'antd';


import AlgorithmScoreExplanations from 'shared/AlgorithmScoreExplanations';
import CurrentUserStore from 'stores/CurrentUserStore';

const changeTimeZone = time => {
  const timeZone = CurrentUserStore.user.settings.timezone;
  //Reformatting the time so that it gets read in correctly as UTC
  time = time.slice(-1) === 'Z' ? time : time + '.000Z';
  return (typeof time === 'string' ? new Date(time) : time).toLocaleString('en-US', {timeZone: timeZone});
};

const ReportHistoryTable = (props) => {
  const dataSource = props.history.map(item =>{
    return {
      key: item.id,
      status: item.report.status,
      lastTestDate: changeTimeZone(item.trip_date),
      score: Math.round(item.report.score * 1000)/1000
    };
  });
  const InfoIcon = <Tooltip title={AlgorithmScoreExplanations(props.item)}>
                    <Icon type="info-circle" style={{'marginTop':'5px', 'marginLeft':'5px', color:'#123fbf'}}/>
                   </Tooltip>;
  return(
    <Table 
      dataSource={dataSource}
      columns={[
        {
          title: <p>Status</p>,
          dataIndex: 'status',
          key: 'status',
          render:(status) => status === 'pass' ? <Tag color="green">Pass</Tag> : <Tag color="red">{status}</Tag>
        },
        {
          title: <p>Last Test Date</p>,
          dataIndex: 'lastTestDate',
          key: 'lastTestDate'
        },
        {
          title: <div style={{'display':'flex'}}><p>Score</p>{InfoIcon}</div>,
          dataIndex: 'score',
          key: 'score'
        }

        ]
      }

    />
  );

};

ReportHistoryTable.propTypes = {
  history: PropTypes.object,
  item: PropTypes.string
};

export default ReportHistoryTable;