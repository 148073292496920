import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import { PitstopModal, PitstopButton } from 'shared';
import { showDemoVinOrRealVin } from 'shared/utils';
import CarStore from 'stores/CarStore';
import AppStore from 'stores/App';
import UserStore from 'stores/UserStore';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import TableStore from 'stores/abstract/TableStore';
import styled from 'styled-components';

const Option = Select.Option;

const UnassignButton = styled(PitstopButton)`
  display: inline-block;
  margin-left: 16px;
`;

class SelectCarDriverModal extends Component {
  static propTypes = {
    carId: PropTypes.number,
    id: PropTypes.string.isRequired,
  };

  state = {
    selectedUsers: undefined,
    tableStore: new TableStore(),
    tableStore2: new TableStore(),
  };

  constructor(props) {
    super(props);
    this.fetchUser = debounce(this.fetchUser, 800);
  }

  get modalId() {
    return this.props.id;
  }

  componentDidUpdate = (prevProps) => {
    const { carId } = this.props;
    if (carId === undefined || carId === null) {
      return;
    }
    if (prevProps.carId !== carId) {
      if (!CarStore.data.has(carId)) {
        CarStore.getCarById(carId);
      } else {
        this.setState({ selectedUsers: CarStore.data.get(carId).userId });
      }
      this.state.tableStore.data.replace(Array.from(UserStore.data.keys()));
      this.state.tableStore2.reset();
    }
  };

  saveUser = async () => {
    const { carId } = this.props;
    const carUserId = CarStore.data.get(carId).userId;

    if (carUserId) {
      const carUser = UserStore.data.get(carUserId);
      await carUser
        .unassignCarId(carId)
        .then(() => this.assignUser())
        .catch((e) => {
          throw e;
        });
    } else {
      this.assignUser();
    }
  };

  assignUser = async () => {
    const { carId } = this.props;
    const selectedUser = UserStore.data.get(this.state.selectedUsers);

    if (selectedUser.userId !== undefined) {
      await selectedUser
        .setCarIds(carId ? [carId] : [])
        .then(() => {
          AppStore.openModals.set(this.modalId, false);
          this.isLoaded = false;
        })
        .catch((e) => {
          throw e;
        });
    }
    return;
  };

  unassignUser = async () => {
    const { carId } = this.props;
    const userId = CarStore.data.get(carId).userId;
    if (userId !== undefined && userId !== null) {
      const user = UserStore.data.get(userId);
      await user.unassignCarId(carId);
      this.setState({ selectedUsers: undefined });
      AppStore.openModals.set(this.modalId, false);
      this.isLoaded = false;
    }
  };

  fetchUser = async (value) => {
    console.log('fetching user', value);
    if (value && value.length > 0) {
      this.state.tableStore.data.clear();
      await UserStore.fetchUsersForTable(
        { offset: 0, limit: 10, filter: { firstName: value } },
        this.state.tableStore
      );
      await UserStore.fetchUsersForTable(
        { offset: 0, limit: 10, filter: { lastName: value } },
        this.state.tableStore2
      );
    } else {
      this.state.tableStore.data.replace(Array.from(UserStore.data.keys()));
      this.state.tableStore2.reset();
    }
  };

  handleChange = (value) => {
    this.setState({
      selectedUsers: value,
    });
  };

  render() {
    const { carId } = this.props;
    const car = CarStore.data.get(carId);
    if (!car) {
      return <React.Fragment />;
    }
    const user = car.user;

    const { selectedUsers } = this.state;

    return (
      <PitstopModal
        id={this.modalId}
        confirmOkMessage="Are you sure you want to assign this user?"
        failureMessage="Failed to save car user assignment"
        successMessage="Saved car user assignment successfully"
        notifyMessage="Please add your vehicle manually"
        title="Select User"
        width={800}
        onOk={this.saveUser}
      >
        <p>Vehicle VIN: {showDemoVinOrRealVin(car.vin)}</p>
        <p>
          <span>User: {user ? user.name : 'None Assigned'}</span>
          {user ? (
            <UnassignButton type="danger" onClick={this.unassignUser}>
              Unassign User
            </UnassignButton>
          ) : null}
        </p>
        <Select
          value={selectedUsers}
          placeholder="Select User"
          showSearch
          filterOption={false}
          onSearch={this.fetchUser}
          onChange={this.handleChange}
          notFoundContent={
            !this.state.tableStore.loaded ? <Spin size="small" /> : null
          }
          allowClear={true}
          style={{ width: '100%' }}
        >
          {this.state.tableStore.data
            .concat(this.state.tableStore2.data)
            .map((id) => {
              const d = UserStore.data.get(id);
              return (
                <Option key={id} value={id}>
                  {`${d.name} <${d.id}>`}
                </Option>
              );
            })}
        </Select>
      </PitstopModal>
    );
  }
}

export default observer(SelectCarDriverModal);
