import React from 'react';
import PropTypes from 'prop-types';

const ReceivedMessageCell = ({ conversation }) => {
  return (
    <div className="gx-chat-item gx-flex-row-reverse">
      <div className="gx-bubble-block">
        <div className="gx-bubble">
          <div className="gx-message">
            {conversation.message && <p>{conversation.message}</p>}

            {conversation.mediaUrl && (
              <div style={{ height: 350 }}>
                <img
                  height="100%"
                  width="100%"
                  src={conversation.mediaUrl}
                  alt={conversation.altText}
                />
              </div>
            )}
          </div>
          <div className="gx-time gx-text-muted gx-text-right gx-mt-2">
            {conversation.sentAt}
          </div>
        </div>
      </div>
    </div>
  );
};

ReceivedMessageCell.propTypes = {
  conversation: PropTypes.object,
};

export default ReceivedMessageCell;
