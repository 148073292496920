import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate } from 'mobx';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Col, Row, Collapse, Button } from 'antd';
import WorkOrderLineItemPartsItem from './WOLineItemPartsItem';

const { Panel } = Collapse;

class WorkOrderLineItemPartsDesktop extends Component {
  static propTypes = {
    childRef: PropTypes.func,
    onUpdateLineTotal: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.any,
    onAddItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    onAddNewPart: PropTypes.func.isRequired,
    shopParts: PropTypes.array,
    onUpdateLocalItem: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool
  };

  state = {};

  partsItemsRefs = {};

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  onAddItem = () => {
    this.forceUpdate();
  };

  onRemoveItem = (event, itemKey) => {
    if (this.props.onRemoveItem) {
      this.props.onRemoveItem(event, itemKey);
    }
  };

  onUpdateLineTotal = (index, lineTotal) => {
    if (this.props.onUpdateLineTotal) {
      this.props.onUpdateLineTotal(index, lineTotal);
    }
  };

  render() {
    if (!_.isEmpty(this.props.items)) {
      const addDescription = `Add ${this.props.description}`;
      const panelRows = this.props.items.map((mapItem, index) => {
        return (
          <WorkOrderLineItemPartsItem
            key={mapItem.key}
            item={mapItem}
            shopParts={this.props.shopParts}
            childRef={(ref) => (this.partsItemsRefs[index] = ref)}
            onUpdateTotal={(lineTotal) =>
              this.onUpdateLineTotal(index, lineTotal)
            }
            onRemoveItem={this.onRemoveItem}
            onAddNewPart={this.props.onAddNewPart}
            onUpdateLocalItem={(newVal, changedField) =>
              this.props.onUpdateLocalItem(mapItem.key, newVal, changedField)
            }
            isDisabled={this.props.isDisabled}
          />
        );
      });
      const renderPartAddButtons = () => {
        return (
          <>
            <Button icon="plus" type="link" onClick={this.props.onAddItem}>
              Add Existing Part
            </Button>
            <Button
              icon="plus"
              type="link"
              onClick={() => {
                if (this.props.onAddNewPart) {
                  this.props.onAddNewPart();
                }
              }}
            >
              Add New Part
            </Button>
          </>
        );
      };
      return (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Collapse defaultActiveKey={['1']} onChange={this.selectedTabs}>
              <Panel header={this.props.title} key={1}>
                {panelRows}
                {this.props.description === 'Parts' ? (
                  renderPartAddButtons()
                ) : (
                  <Button
                    style={{ display: 'block' }}
                    icon="plus"
                    type="link"
                    onClick={this.props.onAddItem}
                  >
                    {addDescription}
                  </Button>
                )}
              </Panel>
            </Collapse>
          </Col>
        </Row>
      );
    }
    return <></>;
  }
}

decorate(WorkOrderLineItemPartsDesktop, {});

export default observer(WorkOrderLineItemPartsDesktop);
