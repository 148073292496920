import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate } from 'mobx';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Col, Row, Collapse, Button } from 'antd';
import WorkOrderLineItemIssuesDesktopItem from './WOLineItemIssuesItem';

const { Panel } = Collapse;

class WorkOrderLineItemIssuesDesktop extends Component {
  static propTypes = {
    childRef: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.any,
    onAddItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    currentCarIssues: PropTypes.array,
    onUpdateLocalItem: PropTypes.func.isRequired
  };

  state = {}

  issuesItemsRef = {}

  componentDidMount () {
    const { childRef } = this.props;
    childRef(this);
  }

  onAddItem = () => {
    this.forceUpdate();
  }

  onRemoveItem = (event, itemKey) => {
    if (this.props.onRemoveItem) {
      this.props.onRemoveItem(event, itemKey);
    }
  }

  render () {
    if (!_.isEmpty(this.props.items)) {
      const addDescription = `Add ${this.props.description}`;
      const panelRows = this.props.items.map((mapItem, index) => {
        return (
          <WorkOrderLineItemIssuesDesktopItem
            key={mapItem.key}
            item={mapItem}
            currentCarIssues={this.props.currentCarIssues}
            childRef={(ref) => (this.issuesItemsRef[index] = ref)}
            onRemoveItem={this.onRemoveItem}
            onUpdateLocalItem={(newVal) => this.props.onUpdateLocalItem(mapItem.key, newVal)}
          />
        );
      });
      return (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Collapse defaultActiveKey={['1']} onChange={this.selectedTabs}>
              <Panel header={this.props.title} key={1}>
                {panelRows}
                <Button style={{ display: 'block' }} icon="plus" type="link" onClick={this.props.onAddItem}>{addDescription}</Button>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      );
    }
    return <></>;
  }
}

decorate(WorkOrderLineItemIssuesDesktop, {});

export default observer(WorkOrderLineItemIssuesDesktop);
