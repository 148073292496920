import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Modal } from 'antd';

import { AppStore, CurrentUserStore } from 'stores';
import OktaAuthService from '../../v3/components/forms/auth/OktaAuthService';

class LogoutModal extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalBody: <p>Are you sure you want to logout?</p>,
    };
  }

  submit = async (e) => {
    e.preventDefault();
    AppStore.showLogoutModal.set(false);
    CurrentUserStore.logout();
    await OktaAuthService.signOut();
    this.props.history.push('/login');
    window.location.reload();
  };

  cancel = () => {
    AppStore.showLogoutModal.set(false);
  };

  render() {
    return (
      <Modal
        title="Logout"
        centered
        visible={AppStore.showLogoutModal.get()}
        okText="Logout"
        onCancel={this.cancel}
        onOk={this.submit}
      >
        {this.state.modalBody}
      </Modal>
    );
  }
}

export default withRouter(observer(LogoutModal));
