import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import PurchaseOrdersPageHeader from 'components/PurchaseOrders/PurchaseOrdersPageHeader';
import PurchaseOrdersForm from 'components/PurchaseOrders/PurchaseOrdersForm';

import { Row, Col } from 'antd';
import { AppStore, ShopStore } from 'stores';
import PurchaseOrderStore from 'stores/PurchaseOrderStore';

class PurchaseOrdersDetailsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
  };

  state = {
    loading: false,
    purchaseOrder: {},
  };

  formRef = createRef();

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const id = this.props.match.params.id;
    if (id) {
      this.setState({
        loading: true,
      });
      const purchaseOrderDetails = await PurchaseOrderStore.getPurchaseOrderDetails(
        id
      );
      if (purchaseOrderDetails) {
        this.setState({
          purchaseOrder: purchaseOrderDetails,
        });

        // set form lineItems
        this.formRef.setState({
          lineItems: purchaseOrderDetails.lineItems,
          tax: purchaseOrderDetails.tax || {
            type: 'percentage',
            value: 0,
          },
          disableFields: purchaseOrderDetails.status === 'posted',
        });
      }
      this.setState({
        loading: false,
      });
    }
  };

  onSave = async (payload) => {
    try {
      AppStore.addLoading('Saving');
      await PurchaseOrderStore.savePurchaseOrder({
        ...payload,
        id: this.state.purchaseOrder.id,
      });
      AppStore.addSuccess(
        `${
          ShopStore.currentShop.id === 374 ? 'Invoice' : 'Purchase Order'
        } saved successfully`
      );
      this.props.history.push('/purchase-orders');
    } catch (error) {
      AppStore.addError(error.message || 'Error saving purchase order');
    } finally {
      AppStore.removeLoading('Saving');
    }
  };

  render() {
    return (
      <Row>
        <Col span={24}>
          <PurchaseOrdersPageHeader
            title={`${
              ShopStore.currentShop.id === 374 ? 'Invoice' : 'Purchase Order'
            } Details`}
            hideActions={true}
          />
        </Col>
        <Col span={18} offset={3}>
          <PurchaseOrdersForm
            childRef={(ref) => (this.formRef = ref)}
            purchaseOrder={this.state.purchaseOrder}
            onSave={this.onSave}
            onCancel={() => {
              this.props.history.push('/purchase-orders');
            }}
          />
        </Col>
      </Row>
    );
  }
}

export default withRouter(PurchaseOrdersDetailsPage);
