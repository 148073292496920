import styled from 'styled-components';

const Button = styled.button`
  cursor: pointer;
  border: none;
  background: #02103d;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: #ffffff;

  height: 3rem;
  min-width: 8rem;
  margin-top: 2vh;

  a {
    color: #ffffff;
  }
`;

export default Button;
