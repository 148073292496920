import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Button } from 'antd';

import { webServiceProvider } from 'shared';

import { AppStore, ShopStore } from 'stores';

import { SelectCars } from 'containers/Users';

import CustomServiceForm from './CustomServiceForm';

class ServiceHistoryForm extends Component {
  state = {
    selectedCarIds: [],
  };

  myRef = React.createRef();

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.setState({
      selectedCarIds: [],
    });
  });

  componentWillUnmount() {
    this.disposer();
  }

  onSelect = (ids) => {
    this.setState({
      selectedCarIds: ids,
    });
  };

  addService = async (values) => {
    if (_.isNil(values) || _.isEmpty(values)) {
      throw Error('Invalid input');
    }

    let priority;

    switch (values.priority) {
      case 'Minor':
        priority = 1;
        break;
      case 'Major':
        priority = 3;
        break;
      case 'Critical':
        priority = 4;
        break;
      default:
        priority = 4;
    }

    let data = {
      shopId: ShopStore.currentShop.id,
      data: {
        action: values.action || null,
        item: values.partname || null,
        description: values.description || 'No description available',
        causeDescription:
          values.causeDescription || 'No cause description available',
        priority: priority,
        status: 'new',
        performedDate: values.performedDate
          ? moment(values.performedDate).unix()
          : null,
        complaintDescription:
          values.complaintDescription || 'No complaints description available',
        correctedDescription:
          values.correctedDescription || 'No corrected description available',
        totalCost: Number(values.totalCost) || null,
        laborHours: Number(values.laborHours) || null,
      },
      carIds: this.state.selectedCarIds,
    };

    await webServiceProvider.post('v1/maintenanceLog', data);
  };

  onSubmit = async () => {
    try {
      if (ShopStore.currentShop.id === -1) {
        throw Error('Please select another shop!');
      }

      if (_.isEmpty(this.state.selectedCarIds)) {
        throw Error('Please select vehicles!');
      }

      let result = await this.myRef.validateForm();

      AppStore.addLoading('Service is being created...');

      await this.addService(result);

      AppStore.addSuccess('Adding service history done!');
    } catch (err) {
      if (!_.isNil(err.message))
        AppStore.addError(`Error in adding service history: ${err.message}`);
    } finally {
      AppStore.removeLoading('Service is being created...');
    }
  };

  render() {
    const { selectedCarIds } = this.state;

    return (
      <>
        <CustomServiceForm
          childRef={(ref) => (this.myRef = ref)}
          serviceName="serviceHistoryForm"
        />

        <b>
          <p>Select Vehicles</p>
        </b>
        <SelectCars
          rowSelection={{
            selectedCarIds: selectedCarIds,
            onChange: this.onSelect,
          }}
        />
        <p>
          <b>Total Selected Vehicles:</b> {selectedCarIds.length}
        </p>

        <Button
          style={{ marginTop: '10px' }}
          type="primary"
          onClick={this.onSubmit}
        >
          Add Service
        </Button>
      </>
    );
  }
}

export default observer(ServiceHistoryForm);
