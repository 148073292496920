import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { Checkbox, Col, Row, Spin, Typography } from 'antd';

import { AppStore, CarStore } from 'stores';
import { UserObject } from 'stores/Classes';

import { PitstopModal } from 'shared';
import { showDemoVinOrRealVin } from 'shared/utils';

import SelectCars from './SelectCars';
import SelectedCars from './SelectedCars';

import styled from 'styled-components';
const { Text } = Typography;

const StyledModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      .ant-modal-title {
        color: #ffffff;
      }
    }
  }
`;

const textStyle = {
  fontSize: '15px'
};

class SaveUserCarsModal extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(UserObject),
    id: PropTypes.string.isRequired,
    onSaveModal: PropTypes.func,
  };

  state = {
    selectAllVehicles: false,
    selectedCars: [],
  };

  get modalId() {
    return this.props.id;
  }

  async componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (user === undefined || user === null) {
      return;
    }

    // If user has changed
    if (prevProps.user !== user || prevProps.user.id !== user.id) {
      if (user.cars.length === user.totalCarsAssociated) {
        this.setState({ selectedCars: user.cars.slice(0) });
      } else {
        this.setState({ selectedCars: [] });

        await user.getCars({ limit: user.totalCarsAssociated });

        this.setState({ selectedCars: user.cars.slice(0) });
      }
    }
    if (!user && AppStore.openModals.get(this.modalId)) {
      this.setState({ selectedCars: [] });
      AppStore.openModals.set(this.modalId, false);
    }
  }

  saveCars = async () => {
    let { selectedCars } = this.state;
    let storedCars = this.props.user.cars.slice();

    try {
      if (this.state.selectedCars) {
        await this.props.user.assignAllFleetVehicles();
      } else {
        storedCars.map(async (id) => {
          if (selectedCars.indexOf(id) < 0) {
            await this.props.user.unassignCarId(id);
          }
        });
        if (!_.isEmpty(selectedCars)) {
          await this.props.user.setCarIds(selectedCars);
        }
      }
      AppStore.openModals.set(this.modalId, false);
      this.isLoaded = false;
      if (this.props.onSaveModal) {
        this.props.onSaveModal();
      }
    } catch (e) {
      console.log(e);
    }
  };

  onSelect = (ids) => {
    this.setState({
      selectedCars: ids,
    });
  };

  onUnselect = (car) => {
    this.setState({
      selectedCars: this.state.selectedCars.filter((id) => id !== car.id),
    });
  };

  render() {
    const { user } = this.props;
    const { selectedCars } = this.state;

    const cars = _.chain(selectedCars)
      .map((id) => CarStore.data.get(id))
      .map((car) => ({
        ...car,
        vin: showDemoVinOrRealVin(car.vin),
      }))
      .value();


    return (
      <StyledModal
        id={this.modalId}
        confirmOkMessage="Are you sure you want to save these cars?"
        failureMessage="Failed to save cars"
        successMessage="Saved cars successfully"
        title="Select Vehicle"
        width={1200}
        onOk={this.saveCars}
        centered={true}
        okText={'Save'}
        afterClose={() => this.setState({ selectAllVehicles: false })}
      >
        <Row gutter={[0, 15]}>
          <Col>
            <Text style={textStyle}>{`User: ${user ? user.name : 'Loading'}`}</Text>
          </Col>
        </Row>
        {CarStore.getCarsForUserPending ? (
          <Spin tip="Loading data..." />
        ) : (
          <>
            <Row gutter={[0, 15]}>
              <Col>
                <Checkbox
                  style={textStyle}
                  checked={this.state.selectAllVehicles}
                  onChange={(e) => this.setState({ selectAllVehicles: e.target.checked })}
                >
                  Select All vehicles
                </Checkbox>
              </Col>
            </Row>

            {!this.state.selectAllVehicles &&
              <>
                <Row gutter={[0, 15]}>
                  <Col>
                    <SelectedCars fontSize={'18px'} cars={cars} onUnselect={this.onUnselect} />
                  </Col>
                </Row>
                <Row gutter={[0, 15]}>
                  <Col>
                    <SelectCars
                      rowSelection={{
                        selectedCarIds: selectedCars || user.cars,
                        onChange: this.onSelect,
                      }}
                    />
                  </Col>
                </Row>
              </>
            }
          </>
        )}
      </StyledModal>
    );
  }
}

export default observer(SaveUserCarsModal);
