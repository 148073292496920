import numeral from 'numeral';

/* eslint-disable no-extend-native */
String.prototype.capitalizeFirstLetter = function() {
  return this.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1)
  );
};

String.prototype.capitalize = function() {
  return this.replace(
    /\w\S*/,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

String.prototype.isEmail = function() {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(this.toLowerCase());
};

String.prototype.toReadableUrl = function() {
  return this.replace(' ', '-').toLowerCase();
};

String.prototype.toNormalString = function() {
  return this.replace('-', ' ').toLowerCase();
};

Number.prototype.toReadable = function() {
  return numeral(this).format('0,00.0');
};
