import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate } from 'mobx';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Col, Row, Collapse, Button } from 'antd';
import WorkOrderLineItemLaborItem from './WOLineItemLaborsItem';

const { Panel } = Collapse;

class WorkOrderLineItemLaborsDesktop extends Component {
  static propTypes = {
    childRef: PropTypes.func,
    onUpdateLineTotal: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.any,
    onAddItem: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func,
    shopUsers: PropTypes.array,
    onUpdateLocalItem: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool
  };

  state = {}

  laborsItemsRefs = {}

  componentDidMount () {
    const { childRef } = this.props;
    childRef(this);
  }

  onRemoveItem = (event, itemKey) => {
    if (this.props.onRemoveItem) {
      this.props.onRemoveItem(event, itemKey);
    }
  }

  onUpdateLineTotal = (index, lineTotal) => {
    if (this.props.onUpdateLineTotal) {
      this.props.onUpdateLineTotal(index, lineTotal);
    }
  }

  render () {
    if (!_.isEmpty(this.props.items)) {
      const addDescription = `Add ${this.props.description}`;
      const panelRows = this.props.items.map((mapItem, index) => {
        return (
          <WorkOrderLineItemLaborItem
            key={mapItem.key}
            item={mapItem}
            childRef={(ref) => (this.laborsItemsRefs[index] = ref)}
            shopUsers={this.props.shopUsers}
            onUpdateTotal={lineTotal => this.onUpdateLineTotal(index, lineTotal)}
            onRemoveItem={this.onRemoveItem}
            onUpdateLocalItem={(newVal) => this.props.onUpdateLocalItem(mapItem.key, newVal)}
            isDisabled={this.props.isDisabled}
          />
        );
      });
      return (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Collapse defaultActiveKey={['1']} onChange={this.selectedTabs}>
              <Panel header={this.props.title} key={1}>
                {panelRows}
                <Button style={{ display: 'block' }} icon="plus" type="link" onClick={this.props.onAddItem}>{addDescription}</Button>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      );
    }
    return <></>;
  }
}

decorate(WorkOrderLineItemLaborsDesktop, {});

export default observer(WorkOrderLineItemLaborsDesktop);
