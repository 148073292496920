import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';

import { Empty, Spin } from 'antd';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

import { CarStore, ReportsDataStore } from 'stores';

let ReactTableStyled = styled(ReactTable)`
  text-align: center;
  maragin-top: 20px;
`;

class VehicleRankingTable extends Component {
  get data() {
    let report = ReportsDataStore.vehiclesScoreReport.data;
    report = _.orderBy(report, 'score', 'asc');
    report = _.uniqBy([...report], 'carName');
    report = _.map(report, item => {
      return{
      ...item,
      score: Math.round(item.score * 100)/100
      };
    });

    report = _.map(report, item => {
      return {
        ...item, 
        srRatio: Math.round(item.srRatio * 100)/100
      };
    });

    report = _.map(report, item => {
      return {
        ...item,
        totalCost: Math.round(_.get(item,'cost_stats[total_cost_to_date]') * 100)/100
      };
    });   
    
    return report;
  }

  render() {
    if (CarStore.demo === false) return <Empty />;

    if (ReportsDataStore.vehiclesScoreReport.pending)
      return <Spin tips="Getting data..." />;

    return (
      <ReactTableStyled
        columns={[
          {
            Header: 'Ranking',
            id: 'ranking',
            accessor: '',
            Cell: (row) => {
              return <div>{row.index + 1}</div>;
            },
          },
          {
            Header: 'Unit ID',
            accessor: 'carName',
            Cell: (row) => {
              let { carId } = row.original;
              return <Link to={`car/${carId}`}>{row.value}</Link>;
            },
          },
          {
            Header: 'Total Repair Cost',
            accessor: 'totalCost'
          },
          {
            Header: 'Avg Days B/W Services',
            accessor: 'avgDaysBwService',
          },
          {
            Header: 'Service to Repair Ratio',
            accessor: 'srRatio',
          },
        ]}
        data={this.data}
        sortable={true}
        defaultSorted={[
          {
            id: 'ranking',
            asc: true,
          },
        ]}
        defaultPageSize={5}
      />
    );
  }
}

decorate(VehicleRankingTable, {
  data: computed,
});

export default withRouter(observer(VehicleRankingTable));
