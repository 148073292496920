import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment-timezone';

import { Col, Icon, Input, Row, Tooltip, notification, Spin } from 'antd';

import {
  Card,
  Button,
  DashLine,
  ErrorField,
  ContactLink,
} from 'v3/components/pitstop';
import { PitstopModal } from 'shared';

import { AppStore, IntegrationStore, ShopStore } from 'stores';

const Container = styled.div`
  text-align: center;

  h2 {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3rem;
  }

  h3 {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-top: 10rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  label {
    font-weight: 600;
    display: block;
    text-align: left;
    width: 100%;
  }

  input {
    margin: 1.2vh 0vh;
  }
`;

const QuestionCircle = styled.img`
  width: 16px;
  height: 16px;
  margin: auto;
`;

const IconCheckCircle = styled(Icon)`
  width: 64px;
  height: 64px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

class GeotabIntegrationForm extends Component {
  static propTypes = {
    id: PropTypes.string,
  };

  state = {
    step: 1,
    username: null,
    password: null,
    database: null,
    url: null,
    isSubmitted: false,
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  modalId = 'geotab-integration-form';

  getError = (type, value) => {
    if (_.isEmpty(value) || _.isNil(value)) {
      return (
        <ErrorField style={{ textAlign: 'left' }}>
          {type} is required!
        </ErrorField>
      );
    }

    return null;
  };

  onConnect = async () => {
    this.setState({
      isSubmitted: true,
    });

    const { username, password, database, url } = this.state;

    if (
      _.isNil(username) ||
      _.isNil(password) ||
      _.isNil(database) ||
      _.isNil(url)
    )
      return;

    try {
      notification.open({
        key: 'geotab-integration',
        description: (
          <>
            <Spin size="large" style={{ marginRight: '0.6rem' }} />
            Adding Geotab integration...
          </>
        ),
        duration: 0,
      });

      await IntegrationStore.createGeotabIntegrationRecord({
        shopId: ShopStore.currentShop.id,
        username: username,
        password: password,
        alias: username,
        database: database,
        url: url,
        defaultMigrationStartTimestamp: moment().startOf('date'),
      });

      this.setState({ step: 3 });
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      notification.close('geotab-integration');
    }
  };

  render() {
    const { step, username, password, database, url, isSubmitted } = this.state;

    return (
      <PitstopModal
        id={this.props.id || this.modalId}
        style={{ top: '8vw' }}
        bodyStyle={{
          padding: 0,
        }}
        width={1030}
        footer={null}
      >
        <Container>
          <Card>
            <Row
              type="flex"
              justify="center"
              style={{ height: '100%', padding: '50px 0px' }}
            >
              {step === 1 && (
                <>
                  <Col span={16}>
                    <h2>Connect Your Geotab Plus Account</h2>

                    <h3>
                      Before you start please ensure you are using Google Chrome
                      or Firefox browser
                    </h3>

                    <p style={{ marginBottom: '10rem' }}>
                      This is to ensure a hiccup-free integration
                    </p>

                    <Button
                      style={{ minWidth: '10rem' }}
                      onClick={() => this.setState({ step: 2 })}
                    >
                      Get Started
                    </Button>
                  </Col>
                </>
              )}

              {step === 2 && (
                <>
                  <Col span={16}>
                    <h2>Connect To Geotab</h2>

                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <label>Username</label>
                        <Input
                          name="username"
                          placeholder="Username"
                          onChange={this.onChange}
                        />
                        {isSubmitted && this.getError('username', username)}
                      </Col>

                      <Col span={12}>
                        <label>Password</label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Password"
                          onChange={this.onChange}
                        />
                        {isSubmitted && this.getError('password', password)}
                      </Col>
                    </Row>

                    <DashLine style={{ margin: '4vh 0vh' }} />

                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <div style={{ display: 'flex' }}>
                          <label>Database</label>
                          <Tooltip
                            title={
                              <img
                                src={require('v3/assets/geotab-database-info.png')}
                                alt=""
                              />
                            }
                            placement="bottomRight"
                          >
                            <QuestionCircle
                              src={require('v3/assets/question-circle.png')}
                              alt="question-circle"
                            />
                          </Tooltip>
                        </div>
                        <Input
                          name="database"
                          placeholder="www.geotab.com/database/"
                          onChange={this.onChange}
                        />
                        {isSubmitted && this.getError('database', database)}
                      </Col>

                      <Col span={12}>
                        <div style={{ display: 'flex' }}>
                          <label>URL</label>
                          <Tooltip
                            title={
                              <img
                                src={require('v3/assets/geotab-url-info.png')}
                                alt=""
                              />
                            }
                            placement="bottomRight"
                          >
                            <QuestionCircle
                              src={require('v3/assets/question-circle.png')}
                              alt="question-circle"
                            />
                          </Tooltip>
                        </div>
                        <Input
                          name="url"
                          placeholder="www.geotab.com/"
                          onChange={this.onChange}
                        />
                        {isSubmitted && this.getError('url', url)}
                      </Col>
                    </Row>

                    <Button
                      style={{ minWidth: '10rem', marginTop: '10vh' }}
                      onClick={() => {
                        this.onConnect();
                      }}
                    >
                      Connect
                    </Button>
                  </Col>
                </>
              )}

              {step === 3 && (
                <>
                  <Col
                    span={16}
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <IconCheckCircle
                      type="check-circle"
                      theme="twoTone"
                      twoToneColor="#52c41a"
                    />

                    <h3 style={{ marginTop: '1vh' }}>
                      Your Geotab fleet is connected
                    </h3>

                    <Button
                      style={{ minWidth: '10rem', marginTop: '2vh' }}
                      onClick={() => {
                        AppStore.openModals.set(this.modalId, false);
                        AppStore.openModals.set(
                          'ADD_INTEGRATION_MODAL_ID',
                          false
                        );

                        AppStore.openModals.set('PROFILE_FORM_ID', true);
                      }}
                    >
                      Complete Profile
                    </Button>
                  </Col>
                </>
              )}

              <Col span={16}>
                <ContactLink />
              </Col>
            </Row>
          </Card>
        </Container>
      </PitstopModal>
    );
  }
}

export default GeotabIntegrationForm;
