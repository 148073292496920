import { observable, decorate } from 'mobx';

class ShopObject {
  loaded = false;
  userId = null;

  constructor(data) {
    Object.assign(this, data);
    if (data.user) {
      this.userId = data.user.id;
    }
  }
}

export default decorate(ShopObject, {
  loaded: observable
});
