import React from 'react';
import styled from 'styled-components';

import { Col, Row } from 'antd';

import { getPriorityTagColor } from 'services/Issue';

const Indicator = styled.div`
  background: ${(props) => props.color};
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

const IssueLegend = () => {
  return (
    <Row gutter={[8, 8]}>
      <Col span={8} style={{ display: 'flex' }}>
        <Indicator color={getPriorityTagColor(5)} />
        <p>Critical - Book an appointment right away with your mechanic</p>
      </Col>

      <Col span={8} style={{ display: 'flex' }}>
        <Indicator color={getPriorityTagColor(3)} />
        <p>Major - Book an appointment in the coming weeks</p>
      </Col>

      <Col span={8} style={{ display: 'flex' }}>
        <Indicator color={getPriorityTagColor(1)} />
        <p>Minor - You can wait till your next service visit.</p>
      </Col>
    </Row>
  );
};

export default IssueLegend;
