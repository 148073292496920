import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Spin } from 'antd';

import {
  AppStore,
  ShopStore,
  CarStore,
  AppointmentStore,
  CurrentUserStore,
} from 'stores';
import { UserRoles } from 'stores/Classes/UserObject';

import { webServiceProvider } from 'shared';

import DealershipChooser from 'components/DealershipChooser';
import { AppointmentProfileModal } from 'containers/AppointmentProfile';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { decorate, observable } from 'mobx';

// a localizer for BigCalendar
const localizer = momentLocalizer(moment);

class AppointmentCalendar extends Component {
  modalId = 'showAppointmentModal';

  isGettingAppointments = false;

  state = {
    date: this.props.date,
    appointmentDetails: undefined,
    appointments: [],
    selectedStart: null,
    selectedEnd: null,
    routineServices: [],
  };

  getShopId = () => {
    return this.props.shopId || ShopStore.currentShop.id;
  };

  async componentDidMount() {
    if (!this.props.selectedCarId) {
      if (this.getShopId() !== -1) {
        this.isGettingAppointments = true;

        await AppointmentStore.getShopAppointmentEvents(this.getShopId());

        let routineServices = await this.getShopRoutineServices(
          this.getShopId()
        );

        this.props.setAppointmentTime &&
          this.props.setAppointmentTime(null, null);
        this.setState({
          appointments: [...routineServices, ...this.getExistedAppointments()],
        });

        this.isGettingAppointments = false;
      }
    }

    if (this.props.selectedCarId) {
      if (CurrentUserStore.user.cars.length === 0) {
        await CurrentUserStore.user.getCars({
          limit: CurrentUserStore.user.totalCarsAssociated,
        });
      }

      this.isGettingAppointments = true;

      await CarStore.data.get(this.props.selectedCarId).getAppointments();

      let routineServices = await this.getCarRoutineServices(
        this.props.selectedCarId
      );

      this.props.setAppointmentTime &&
        this.props.setAppointmentTime(null, null);
      this.setState({
        appointments: [...routineServices, ...this.getExistedAppointments()],
      });

      this.isGettingAppointments = false;
    }
  }

  async componentDidUpdate(prevProps) {
    if (!this.props.selectedCarId && this.props.shopId !== prevProps.shopId) {
      if (this.getShopId() !== -1) {
        this.isGettingAppointments = true;

        await AppointmentStore.getShopAppointmentEvents(this.getShopId());

        let routineServices = await this.getShopRoutineServices(
          this.getShopId()
        );

        this.props.setAppointmentTime &&
          this.props.setAppointmentTime(null, null);

        this.setState({
          appointments: [...routineServices, ...this.getExistedAppointments()],
        });

        this.isGettingAppointments = false;
      }
    }

    if (this.props.selectedCarId !== prevProps.selectedCarId) {
      if (CurrentUserStore.user.cars.length === 0) {
        await CurrentUserStore.user.getCars({
          limit: CurrentUserStore.user.totalCarsAssociated,
        });
      }

      this.isGettingAppointments = true;

      await CarStore.data.get(this.props.selectedCarId).getAppointments();

      let routineServices = await this.getCarRoutineServices(
        this.props.selectedCarId
      );

      this.props.setAppointmentTime &&
        this.props.setAppointmentTime(null, null);
      this.setState({
        appointments: [...routineServices, ...this.getExistedAppointments()],
      });

      this.isGettingAppointments = false;
    }
  }

  getCarRoutineServices = async (carId) => {
    let params = {
      offset: 0,
      limit: 5,
      source: 'routine',
      sort: '-priority',
      includeHistory: false,
    };

    let { data } = await webServiceProvider.getMany(`v1/cars/${carId}/issues`, {
      ...params,
    });

    let shopId = ShopStore.currentShop.id;
    let source = 'routine';

    let result = _.map(data, (d) => {
      let title = `${d.car.name} ${_.join([d.action, d.item], ' - ')}`;

      if (
        d.status !== 'done' &&
        (!_.isNil(d?.routineInfo?.fixed_month) ||
          !_.isNil(d?.routineInfo?.started_month))
      ) {
        let data;

        if (d.status === 'new') {
          let date = d.reportDate;

          date = moment(date).set('hour', 8);
          date = moment(date).set('minute', 0);

          data = {
            title,
            appointment: {
              source,
              appointmentDate: moment(date).toISOString(),
              appointmentEndTime: moment(date).add(2, 'hours').toISOString(),
              carId,
              shopId,
              comments: _.join([d.action, d.item], ' - '),
              createdAt: moment(date).toISOString(),
            },
            start: moment(date).toDate(),
            end: moment(date).add(2, 'hours').toDate(),
          };
        }

        if (d.status === 'upcoming') {
          let date = d.routineInfo.fixed_month
            ? d.routineInfo.fixed_month
            : moment
                .unix(d.routineInfo.started_month)
                .add(d.routineInfo.interval_month, 'months')
                .unix();

          date = moment.unix(date).toISOString();

          date = moment(date).set('hour', 8);
          date = moment(date).set('minute', 0);

          data = {
            title,
            appointment: {
              source,
              appointmentDate: moment(date).toISOString(),
              appointmentEndTime: moment(date).add(2, 'hours').toISOString(),
              carId,
              shopId,
              comments: _.join([d.action, d.item], ' - '),
              createdAt: moment(date).toISOString(),
            },
            start: moment(date).toDate(),
            end: moment(date).add(2, 'hours').toDate(),
          };
        }

        return data;
      }
    });

    return _.filter([...result], Boolean);
  };

  getShopRoutineServices = async (shopId) => {
    let params = {
      offset: 0,
      limit: 100,
      source: 'routine',
      sort: '-priority',
      includeHistory: false,
    };

    let { data } = await webServiceProvider.getMany(
      `v1/shops/${shopId}/issues`,
      {
        ...params,
      }
    );

    let source = 'routine';

    let result = _.map(data, (d) => {
      let car = {
        mmy: _.join([d.car.make, d.car.model, d.car.year], ' '),
        carName: d.car.name,
        vin: d.car.vin,
      };

      let title = `${d.car.name} ${_.join([d.action, d.item], ' - ')}`;

      if (
        d.status !== 'done' &&
        (!_.isNil(d.routineInfo.fixed_month) ||
          !_.isNil(d.routineInfo.started_month))
      ) {
        let data;

        if (d.status === 'new') {
          let date = d.reportDate;

          date = moment(date).set('hour', 8);
          date = moment(date).set('minute', 0);

          data = {
            title,
            appointment: {
              source,
              appointmentDate: moment(date).toISOString(),
              appointmentEndTime: moment(date).add(2, 'hours').toISOString(),
              car,
              shopId,
              comments: _.join([d.action, d.item], ' - '),
              createdAt: moment(d.reportDate).toISOString(),
            },
            start: moment(date).toDate(),
            end: moment(date).add(2, 'hours').toDate(),
          };
        }

        if (d.status === 'upcoming') {
          let date = d.routineInfo.fixed_month
            ? d.routineInfo.fixed_month
            : moment
                .unix(d.routineInfo.started_month)
                .add(d.routineInfo.interval_month, 'months')
                .unix();

          date = moment.unix(date).toISOString();

          date = moment(date).set('hour', 8);
          date = moment(date).set('minute', 0);

          data = {
            title,
            appointment: {
              source,
              appointmentDate: moment(date).toISOString(),
              appointmentEndTime: moment(date).add(2, 'hours').toISOString(),
              car,
              shopId,
              comments: _.join([d.action, d.item], ' - '),
              createdAt: moment.unix(date).toISOString(),
            },
            start: moment(date).toDate(),
            end: moment(date).add(2, 'hours').toDate(),
          };
        }

        return data;
      }
    });

    return _.filter([...result], Boolean);
  };

  onSelectEvent = (event) => {
    if (event.title && event.title === 'Selected Timeslot') {
      return;
    }

    this.setState({ appointmentDetails: event.appointment }, () =>
      AppStore.openModals.set(this.modalId, true)
    );
    return true;
  };

  onSelectSlot = (event) => {
    let { selectedStart, selectedEnd, ableSlotSelection } = this.props;

    //to disable selecting a time slot in the months view
    if (
      !moment(event.start).isSame(moment(selectedStart)) &&
      !moment(event.end).isSame(moment(selectedEnd)) &&
      ableSlotSelection
    ) {
      if (this.props.setAppointmentTime) {
        this.props.setAppointmentTime(event.start, event.end);
      }

      this.setState({
        appointmentDetails: event.appointment,
      });
    }
  };

  getExistedAppointments = () => {
    if (this.props.selectedCarId) {
      let car = CarStore.data.get(this.props.selectedCarId);

      if (!_.isNil(car.service_appointment)) {
        return _.map(car.service_appointment.data, (d) => ({
          start: moment
            .tz(d.appointmentDate, CurrentUserStore.user.settings.timezone)
            .toDate(),
          end: moment
            .tz(d.appointmentEndTime, CurrentUserStore.user.settings.timezone)
            .toDate(),
          title: `${car.model} ${car.year}`,
          appointment: {
            carId: this.props.selectedCarId,
            ...d,
          },
        }));
      }
    }

    return _.map(AppointmentStore.data.results, (el) => ({
      start: moment
        .tz(
          el.appointment.appointmentDate,
          CurrentUserStore.user.settings.timezone
        )
        .toDate(),
      end: moment
        .tz(
          el.appointment.appointmentEndTime,
          CurrentUserStore.user.settings.timezone
        )
        .toDate(),
      title: `${el.user.firstName} - ${el.car.carModel} ${el.car.carYear}`,
      appointment: el,
    }));
  };

  render() {
    let { appointmentDetails, date } = this.state;
    let { shopId, views, defaultView, selectedStart, selectedEnd } = this.props;

    if (this.getShopId() !== -1 && this.isGettingAppointments) {
      return <Spin tip="Getting Appointments..." />;
    }

    let minTime = moment().set('hour', 6);
    minTime = minTime.set('minute', 0).toDate();

    let maxTime = moment().set('hour', 22);
    maxTime = maxTime.set('minute', 0).toDate();

    let appointments = [...this.state.appointments];

    if (selectedStart && selectedEnd) {
      let newAppointmentObj = {
        start: selectedStart,
        end: selectedEnd,
        title: 'Selected Timeslot',
      };
      appointments = [...appointments, newAppointmentObj];
    }

    return (
      <>
        {CurrentUserStore.user.role === UserRoles.admin && (
          <DealershipChooser />
        )}
        {(shopId !== -1 ||
          CurrentUserStore.user.role === UserRoles.customer) && (
          <>
            <Calendar
              localizer={localizer}
              selectable={true}
              style={{ height: 550 }}
              events={appointments}
              date={moment(date).toDate()}
              defaultDate={moment(date).toDate()}
              onSelectEvent={this.onSelectEvent}
              onNavigate={(date) => this.setState({ date })}
              onSelectSlot={this.onSelectSlot}
              defaultView={defaultView}
              views={views}
              min={minTime}
              max={maxTime}
              drilldownView="day"
              step={15}
              timeslots={4}
              showMultiDayTimes={true}
            />
            <AppointmentProfileModal
              id={this.modalId}
              appointmentDetails={appointmentDetails}
            />
          </>
        )}
      </>
    );
  }
}

AppointmentCalendar.propTypes = {
  shopId: PropTypes.number,
  defaultView: PropTypes.string,
  views: PropTypes.array,
  setAppointmentTime: PropTypes.func,
  selectedCarId: PropTypes.number,
  ableSlotSelection: PropTypes.bool,
  date: PropTypes.instanceOf(moment),
  selectedStart: PropTypes.instanceOf(Date),
  selectedEnd: PropTypes.instanceOf(Date),
};

AppointmentCalendar.defaultProps = {
  defaultView: 'month',
  views: ['month', 'week', 'day'],
};

export default observer(
  decorate(AppointmentCalendar, {
    isGettingAppointments: observable,
  })
);
