import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { PitstopButton } from 'shared/PitstopUI';

import { AppStore, ShopStore } from 'stores';

import DealershipChooser from 'components/DealershipChooser';
import AddIntegrationModal from './AddIntegrationModal';
import IntegrationTable from './IntegrationTable';

const Header = styled.div`
  display: flex;
  && > h1 {
    flex: 1;
  }
`;

class IntegrationPage extends Component {
  static propTypes = {
    integrationRecordTable: PropTypes.bool,
    addIntegrationBtn: PropTypes.bool,
    title: PropTypes.string,
    location: PropTypes.object,
  };

  static defaultProps = {
    integrationRecordTable: true,
    addIntegrationBtn: false,
    title: 'Add Integration',
  };

  modalId = 'ADD_INTEGRATION_MODAL_ID';

  openModal = () => {
    AppStore.openModals.set(this.modalId, true);
  };

  render() {
    let shopId = ShopStore.currentShop.id; // required to observe value change
    let { title } = this.props;

    return (
      <>
        {this.props.integrationRecordTable && (
          <>
            <Header>
              <h1>Integration</h1>
              <PitstopButton icon="plus" onClick={this.openModal}>
                {title}
              </PitstopButton>
            </Header>
            <DealershipChooser />
            <IntegrationTable />
          </>
        )}
        {this.props.addIntegrationBtn && (
          <PitstopButton
            style={{
              margin: '0 10px',
              fontSize: '20px',
              borderRadius: ' 4px',
            }}
            icon="plus"
            onClick={this.openModal}
          >
            {title}
          </PitstopButton>
        )}
        <AddIntegrationModal
          title={'Add Integration'}
          width={1230}
          onOk={() => {}}
          afterCreate={async () => {
            AppStore.openModals.clear();
          }}
          footer={null}
          id={this.modalId}
          shopId={shopId}
          {...this.props}
        />
      </>
    );
  }
}

export default withRouter(observer(IntegrationPage));
