import React from 'react';

import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

// Import React Table
import { PitstopButton, PitstopTable } from 'shared';
import styled from 'styled-components';
import CampaignStore from 'stores/CampaignStore';
import DealershipChooser from 'components/DealershipChooser';
import ShopStore from 'stores/ShopStore';

const NewCampaignButton = styled(PitstopButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const Wrapper = styled.div`
  position: relative;
`;

class CampaignHome extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };
  columns = [
    { dataIndex: 'name', filter: false, title: 'Name' },
    { dataIndex: 'type', filter: false, title: 'Type' },
    { dataIndex: 'content', filter: false, title: 'Message' },
    { dataIndex: 'total_send', filter: false, title: 'Total Send' },
    { dataIndex: 'total_read', filter: false, title: 'Total Read' },
    {
      dataIndex: 'created_at',
      filter: false,
      title: 'Launch Date',
      render: (createdAt) => <span>{createdAt || 'Not Available'}</span>,
    },
  ];

  render() {
    const { id: shopId } = ShopStore.currentShop;
    return (
      <Wrapper>
        <h1>Campaigns</h1>
        <p>Create and Track your campaigns</p>
        <DealershipChooser />
        <NewCampaignButton
          type="primary"
          icon="plus"
          onClick={() => {
            this.props.history.push('/campaign/create');
          }}
        >
          Create New Campaign
        </NewCampaignButton>
        <br />

        <PitstopTable
          id={`${ShopStore.currentShop.id}-campaign-list`}
          columns={this.columns}
          data={CampaignStore.data}
          fetchData={(params, store) =>
            CampaignStore.getCampaignList(params, store, shopId)
          }
          rowKey="id"
        />
      </Wrapper>
    );
  }
}

export default withRouter(observer(CampaignHome));
