import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Form } from 'antd';

import { AppStore, CurrentUserStore, GeotabConnector } from 'stores';

import ROIImage from 'assets/roi.png';

const Spacing = styled.div`
  height: 30px;
`;

const Item = styled.li`
  font-size: 18px;
`;

const Image = styled.img`
  display: block;
  max-width: 900px;
  width: 100%;
  margin-bottom: 20px;
`;

class LoginGeotab extends Component {
  MESSAGE = 'Logging In';
  triedToAuthenticate = false;

  constructor() {
    super();
    this.state = {
      areCookiesEnable: AppStore.areCookiesEnable(),
      isOnGeotabDashboard: AppStore.isOnGeotabDashboard(),
    };
  }

  componentDidMount() {
    this.props.toggleSideBar();
    if (AppStore.isOnGeotabDashboard()) {
      // GeotabConnector.registerListener();
      this.getGeotabCredentials().then((res) => {
        const { credentials, origin } = res;
        if (credentials) {
          this.loginWithCredentials(credentials, origin);
        }
      });
    }
  }

  async getGeotabCredentials() {
    if (AppStore.getGeotabSSOVersion() > 1) {
      return GeotabConnector.getCredentials();
    } else {
      return new Promise((resolve, _) => {
        GeotabConnector.getCredentials((credentials, origin) => {
          resolve({ credentials, origin });
        });
      });
    }
  }

  loginWithCredentials(credentials, origin) {
    if (this.triedToAuthenticate === true) return;
    this.triedToAuthenticate = true;
    AppStore.addLoading(this.MESSAGE);
    CurrentUserStore.loginGeotab(
      credentials.database,
      credentials.sessionId,
      credentials.userName,
      origin,
      (success) => {
        AppStore.removeLoading(this.MESSAGE);
        if (success) {
          CurrentUserStore.getCurrentUser();
          this.props.toggleSideBar();
        }
      }
    );
  }

  showEnableCookieTutorial = () => {
    return (
      <div>
        <h3>
          Pitstop addin is only guaranteed to work on Google Chrome and Firefox
        </h3>
        {/* <Spacing></Spacing> */}
        <Image src={ROIImage} alt="ROI" />
        <h5>
          If your browser is not supported and you want to use Pitstop addin on
          it, you must enable Third-Party Cookies. Allowing Third-Party Cookies
          will allow tracking programs to track your web activity, so aways
          remember to disable it after leaving Pitstop.
        </h5>
        <h5>
          Alternativelly you can use any browser to access Pitstop without any
          additional step at{' '}
          <a href="https://dashboard.pitstopconnect.com/">
            https://dashboard.pitstopconnect.com
          </a>
        </h5>
        <Spacing></Spacing>
        <h4>Brave</h4>
        <ol>
          <Item>Open settings</Item>
          <Item>Search for {'"Cookie Blocking"'}</Item>
          <Item>Set it to {'"Disabled"'}</Item>
        </ol>
        <h4>Safari</h4>
        <ol>
          <Item>Open Preferences</Item>
          <Item>Click on {'"Privacy"'} tab</Item>
          <Item>
            Disable {'"Website tracking -> Prevent cross-site tracking"'}
          </Item>
        </ol>
        <Spacing></Spacing>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {!this.state.isOnGeotabDashboard && (
          <h3>
            This page only works on{' '}
            <a href="https://geotab.com">https://geotab.com</a> addin
          </h3>
        )}
        {this.state.isOnGeotabDashboard &&
          !this.state.areCookiesEnable &&
          this.showEnableCookieTutorial()}
      </React.Fragment>
    );
  }

  static propTypes = {
    toggleSideBar: PropTypes.func,
  };
}

export default Form.create({ name: 'login_geotab_form' })(
  observer(LoginGeotab)
);
