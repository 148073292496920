import print from 'print-js';

function getPDFFromBlob(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

function printer(data, strFileName, strMimeType) {
  const documentObjectUrl = URL.createObjectURL(data);
  // get device info. Check if iOS
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (iOS) {
    return getPDFFromBlob(data).then((pdfData) => {
      // create a window with title
      const tab = window.open();
      tab.document.write(`<title>${strFileName}.pdf</title>`);
      tab.location.href = pdfData;
    });
  }
  print({ printable: documentObjectUrl, type: 'pdf' });
}

export default printer;
