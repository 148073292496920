import React from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';


import { DealershipChooser } from 'components';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const WidgetContainer = styled.div`
  border: solid;
  border-width: 2px;
  border-color: #f9f9f9;
  margin: 5px;
  padding: 12px;
`;

const accuracyTableData = [
    {
    key: '1',
    predictionTitle: 'Breakdown Predicted',
    towYes: 23,
    towNo: 68,
  },
  {
    key: '2',
    predictionTitle: 'Breakdown Not Predicted',
    towYes: 26,
    towNo: 516,
  },
];

const columns = [
  {
    title: '',
    dataIndex: 'predictionTitle',
    key: 'predictionTitle',
  },
  {
    title: 'Breakdown Occurred',
    dataIndex: 'towYes',
    key: 'towYes',
  },
  {
    title: 'Breakdown Did Not Occur',
    dataIndex: 'towNo',
    key: 'towNo',
  },
];

class AlertsAccuracyDashboard extends React.Component{

  render(){
    return(
      <>
        <DealershipChooser />
        <GridContainer>
          <WidgetContainer>
            <h4>Pitstop Alerts Accuracy</h4>
            <Table 
              dataSource={accuracyTableData} 
              columns={columns}
              pagination={false}
            />
          </WidgetContainer>
        </GridContainer>
      </>
    );
  }

}

export default withRouter(observer(AlertsAccuracyDashboard));