export default [
  {
    action: 'pass',
    algorithm: '1135785376',
    car_id: 56161,
    car_make: 'Hino',
    car_model: 'NF',
    car_name: '80-670406',
    car_year: '2017',
    created_at: '2021-03-31T07:18:41.128Z',
    description:
      'The percentile score shows how high the brake usage of this vehicle is, relative to other similar vehicles in your fleet. For example: A percentile score of 95% means that the vehicle is amongst the top 5% of similar vehicles in your fleet with high brake usage. Do not worry if the score is too low (close to 0) or too high (close to 99). This means that not enough data has been acquired to give an accurate estimate. It could also mean that the vehicle has not seen much use (low score)/seen high use (high score) recently.',
    failure: 'Brake Model',
    id: 34059,
    item: 'Brakes',
    mileage_total: '288160.2',
    report: { date: '2021-09-19T05:48:21.959075+00:00', score: 96.41 },
    vin: 'DEMO-017',
  },
  {
    action: 'pass',
    algorithm: '1135785376',
    car_id: 56157,
    car_make: 'Ford',
    car_model: '150',
    car_name: '317-5310',
    car_year: '2015',
    created_at: '2021-03-30T18:52:09.262Z',
    description:
      'Accel test working: Last test for accel at 2021-06-08T22:01:44.933271+00:00 with result pass',
    failure: 'Brake Model',
    id: 33806,
    item: 'Brakes',
    mileage_total: '1022.0463509318099',
    report: { date: '2021-06-08T22:01:44.933271+00:00', score: 95.78 },
    vin: 'DEMO-016',
  },
  {
    action: 'pass',
    algorithm: '1135785376',
    car_id: 50134,
    car_make: 'Hino',
    car_model: 'NF',
    car_name: '50-670412',
    car_year: '2017',
    created_at: '2021-03-31T01:17:22.428Z',
    description:
      'The percentile score shows how high the brake usage of this vehicle is, relative to other similar vehicles in your fleet. For example: A percentile score of 95% means that the vehicle is amongst the top 5% of similar vehicles in your fleet with high brake usage. Do not worry if the score is too low (close to 0) or too high (close to 99). This means that not enough data has been acquired to give an accurate estimate. It could also mean that the vehicle has not seen much use (low score)/seen high use (high score) recently.',
    failure: 'Brake Model',
    id: 33976,
    item: 'Brakes',
    mileage_total: '389952.6',
    report: { date: '2021-09-18T07:49:39.174857+00:00', score: 92.91 },
    vin: 'DEMO-019',
  },
];
