const ScatterDataAfterBreakdown = [[9.611412118055556, 1],
 [5.569131631944445, 4],
 [0.956130289351852, 7],
 [15.822605474537037, 17],
 [0.5930755439814814, 19],
 [8.666554131944444, 20],
 [8.896904131944444, 20],
 [8.66665957175926, 20],
 [8.672824618055555, 20],
 [8.666374618055556, 20],
 [0.6853564814814815, 21],
 [0.6853564814814815, 21],
 [7.644464351851852, 25],
 [0.54615, 25],
 [7.764961655092593, 25],
 [1.6092558217592594, 29],
 [14.833293055555556, 32],
 [6.970986539351851, 40],
 [0.620760150462963, 40],
 [0.48360906249999996, 41],
 [4.078658136574075, 41],
 [3.775975115740741, 41],
 [0.5986803587962963, 52],
 [4.648882789351852, 54],
 [0.5347114930555555, 54],
 [0.6167836458333333, 55],
 [0.6300478819444445, 58],
 [0.6182590625, 65],
 [0.7802792476851851, 65],
 [0.019011145833333333, 67],
 [3.55524375, 68],
 [7.013846493055556, 68],
 [0.5509789004629629, 68],
 [0.8696354166666667, 71],
 [0.8729314814814815, 71],
 [0.07798695601851852, 71],
 [1.6370615393518517, 72],
 [0.023130243055555555, 72],
 [0.00754332175925926, 72],
 [0.7812668981481482, 74],
 [14.099117789351851, 74],
 [2.606381712962963, 76],
 [1.8046814814814816, 76],
 [0.6102008449074074, 76],
 [0.6194133101851852, 80],
 [0.6714539004629629, 80],
 [6.889587268518518, 83],
 [6.8895871875, 83],
 [10.76698449074074, 86],
 [12.621550659722221, 89],
 [18.621683252314813, 91],
 [8.704354201388888, 91],
 [1.699840474537037, 91],
 [17.734899502314814, 93],
 [17.899633217592594, 93],
 [8.68568599537037, 93],
 [17.734899502314814, 93],
 [17.899633217592594, 93],
 [17.90000297453704, 93],
 [12.741415625, 94],
 [0.6201348032407407, 94],
 [2.805835185185185, 94],
 [16.710827465277777, 100],
 [11.89838545138889, 100],
 [0.6279360300925926, 100],
 [1.7901951736111112, 101],
 [1.7901951736111112, 101],
 [0.6220662384259259, 103],
 [0.03856952546296297, 105],
 [0.06298376157407407, 105],
 [3.6123172453703707, 105],
 [0.6470969097222222, 108],
 [0.6794243865740741, 108],
 [1.634135613425926, 108],
 [1.634135613425926, 108],
 [1.634135613425926, 108],
 [0.6861406250000001, 110],
 [0.6861406250000001, 110],
 [2.8444869560185184, 110],
 [7.607597650462964, 110],
 [4.817335381944445, 111],
 [5.619196446759259, 111],
 [14.629769363425927, 113],
 [14.629769363425927, 113],
 [2.840266122685185, 113],
 [0.6844924421296296, 116],
 [7.808993900462963, 120],
 [0.654263275462963, 121],
 [12.721983368055554, 122],
 [17.610421724537037, 128],
 [3.8822412847222223, 128],
 [14.626232638888888, 128],
 [0.5255403125, 135],
 [0.5543023148148148, 135],
 [0.5494560532407408, 137],
 [0.5494560532407408, 137],
 [11.886679363425925, 138],
 [0.5372896643518518, 139],
 [1.6565563657407407, 139],
 [0.655724849537037, 141],
 [19.614620983796296, 142],
 [0.6734797106481482, 143],
 [0.8018973379629629, 143],
 [8.61932056712963, 144],
 [6.73032167824074, 144],
 [0.972624224537037, 144],
 [0.8620248495370371, 155],
 [0.8620247337962964, 155],
 [0.7601594097222222, 159],
 [0.5268715625, 163],
 [6.520862303240741, 163],
 [0.5268715625, 163],
 [0.6896798263888889, 165],
 [0.0013318634259259258, 165],
 [3.1407180208333334, 171],
 [7.662456284722222, 179],
 [7.66245605324074, 179],
 [10.011482488425926, 180],
 [0.026947569444444444, 180],
 [0.026947569444444444, 180],
 [1.0008626967592593, 184],
 [0.9124640856481481, 187],
 [14.88355165509259, 187],
 [1.8305189814814815, 188],
 [3.8323319097222224, 188],
 [0.5498019675925926, 190],
 [0.5498017361111112, 190],
 [7.625217280092593, 190],
 [0.5498017361111112, 190],
 [14.84187040509259, 197],
 [0.005110266203703704, 198],
 [0.6243581018518518, 199],
 [0.6414802083333333, 199],
 [0.6243581018518518, 199],
 [1.0066330208333332, 200],
 [6.835187696759259, 207],
 [14.92210829861111, 213],
 [14.92210829861111, 213],
 [11.046317511574074, 214],
 [11.046317395833332, 214],
 [0.5361895486111111, 221],
 [14.798427546296296, 221],
 [0.5381267013888889, 226],
 [15.814394756944443, 226],
 [16.605788344907406, 229],
 [0.7438721875000001, 235],
 [0.6290653125, 235],
 [13.628647604166666, 237],
 [7.8122601041666675, 240],
 [0.7081685532407407, 245],
 [8.919621446759258, 246],
 [0.533046875, 249],
 [0.5330471064814815, 249],
 [2.791508564814815, 255],
 [0.6927901967592592, 266],
 [8.734515081018518, 267],
 [8.735460300925926, 267],
 [16.745878738425926, 275],
 [0.016044641203703705, 275],
 [0.828439548611111, 276],
 [0.6225076388888889, 276],
 [0.7227749189814815, 279],
 [0.6549596412037036, 284],
 [1.666818252314815, 285],
 [1.7270251967592594, 286],
 [8.795484525462964, 288],
 [0.9391982291666666, 294],
 [0.9391982291666666, 294]];

export default ScatterDataAfterBreakdown;