import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import _ from 'lodash';

import { Button, Anchor, Divider, Collapse, Spin, Typography } from 'antd';

import ShopStore from 'stores/ShopStore';

import {
  GeneralFAQ,
  ConnectivityFAQ,
  CostFAQ,
  PrivacyFAQ,
  GeotabGuide,
  TrainingVideos
} from './FAQContent';

import { customFleets } from 'shared';

const GMRVFAQ = React.lazy(() => import('./custom/gmrv/GMRV'));
const CardinalCourierFAQ = React.lazy(() =>
  import('./custom/CardinalCourierFAQ')
);

const { Link } = Anchor;
const { Text } = Typography;

const Panel = Collapse.Panel;

const FAQWrapper = styled.div`
  display: flex;
  justify-content: center;
  && > #anchor-section {
    width: 240px;
  }
  && > #faq-section {
    flex: 1;
  }
  && > #faq-section h4 {
    margin-top: 20px;
  }
`;

class FAQ extends Component {
  get faq() {
    return (
      <>
        <div id="anchor-section">
          <Text strong>Navigation</Text>
          <Anchor bounds={200}>
            <Link href="#training-videos" title="Training Videos" />
            <Link href="#general-faq" title="General" />
            <Link href="#connectivity-faq" title="GEOTAB Integration Guide" />
            <Link href="#cost-faq" title="Cost and Billing" />
            <Link href="#privacy-faq" title="Privacy" />
          </Anchor>
        </div>
        <div id="faq-section">
          <div className="accordion-group">
            <h4 id="training-videos">Training Videos</h4>
            <Collapse accordion>
              {TrainingVideos.map(el => (
                <Panel header={el.title} key={el.title}>
                  {el.content}
                </Panel>
              ))}
            </Collapse>
            <h4 id="general-faq">General FAQ</h4>
            <Collapse accordion>
              {GeneralFAQ.map(el => (
                <Panel header={el.title} key={el.title}>
                  <p>{el.content}</p>
                </Panel>
              ))}
            </Collapse>
            <h4 id="connectivity-faq">Connectivity and Device FAQ</h4>
            <Collapse accordion>
              {ConnectivityFAQ.map(el => (
                <Panel header={el.title} key={el.title}>
                  <p>{el.content}</p>
                </Panel>
              ))}
            </Collapse>
            <h4 id="connectivity-faq">GEOTAB Integration Guide</h4>
            <Collapse accordion>
              {GeotabGuide.map(el => (
                <Panel header={el.title} key={el.title}>
                  <p>{el.content}</p>
                </Panel>
              ))}
            </Collapse>
            <h4 id="cost-faq">Cost and Billing</h4>
            <Collapse accordion>
              {CostFAQ.map(el => (
                <Panel header={el.title} key={el.title}>
                  <p>{el.content}</p>
                </Panel>
              ))}
            </Collapse>
            <h4 id="privacy-faq">Privacy FAQ</h4>
            <Collapse accordion>
              {PrivacyFAQ.map(el => (
                <Panel header={el.title} key={el.title}>
                  <p>{el.content}</p>
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </>
    );
  }

  isGMRVShop = () => {
    return !_.isEmpty(
      _.filter(customFleets.gmrv, shopId => ShopStore.currentShop.id === shopId)
    );
  };

  isCardinalCourierShop = () => {
    return !_.isEmpty(
      _.filter(
        customFleets.cardinalCourier,
        shopId => ShopStore.currentShop.id === shopId
      )
    );
  };

  render() {
    return (
      <React.Suspense fallback={<Spin tip="Loading..." />}>
        {this.isCardinalCourierShop() ? (
          <>
            <CardinalCourierFAQ />
          </>
        ) : (
          <>
            <h1>Learning Center</h1>
            <p>
              We are constantly looking for feedback on the dashboard. Please
              let us know what features you would like to have through the
              contact link below.
            </p>
            <Button href="mailto:info@pitstopconnect.com">Contact Us</Button>
            <a href="mailto:info@pitstopconnect.com">
              {' '}
              info@pitstopconnect.com
            </a>
            <Divider />
            <FAQWrapper>
              {this.isGMRVShop() ? <GMRVFAQ /> : this.faq}
            </FAQWrapper>
          </>
        )}
      </React.Suspense>
    );
  }
}

export default observer(FAQ);
