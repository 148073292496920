import React from 'react';
import { Link } from 'react-router-dom';
import { PitstopButton } from 'shared';
import { Carousel } from 'antd';
import styled from 'styled-components';
import Slide1 from 'assets/Slide1.png';
import Slide2 from 'assets/Slide2.png';
import Slide3 from 'assets/Slide3.png';

const CampaignSlider = styled.div`
  .ant-carousel {
    margin: 0 auto;
    width: 716px;
  }
  .ant-carousel .slick-slide {
    text-align: center;
    /* height: 500px; */
    overflow: hidden;
  }
  .ant-carousel .slick-dots button {
    background: #000;
  }
  .ant-carousel .slick-dots .slick-active button {
    background: #000;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;

  a {
    margin: 0 auto;
  }
`;

const CampaignCreate = () => {
  return (
    <CampaignSlider>
      <h1>Create Campaign</h1>
      <Carousel autoplay>
        <div>
          <img src={Slide1} alt="campaignImage2" />
        </div>
        <div>
          <img src={Slide2} alt="campaignImage2" />
        </div>
        <div>
          <img src={Slide3} alt="campaignImage2" />
        </div>
      </Carousel>
      <ButtonContainer>
        <Link to="/campaign/create/steps">
          <PitstopButton>Create Campaign</PitstopButton>
        </Link>
      </ButtonContainer>
    </CampaignSlider>
  );
};

export default CampaignCreate;
