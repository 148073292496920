import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Spin } from 'antd';

import HighchartsReact from 'highcharts-react-official';

class PieChart extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    chart: PropTypes.object,
    isWidget: PropTypes.bool,
    click: PropTypes.func,
    loaded: PropTypes.bool.isRequired,
    units: PropTypes.string,
    data: PropTypes.shape({
      seriesName: PropTypes.string.isRequired,
      value: PropTypes.array.isRequired,
      units: PropTypes.string,
      emptyMessage: PropTypes.string,
    }),
    history: PropTypes.object.isRequired,
  };

  get data() {
    let { value, units } = this.props.data;

    if (_.isEmpty(value)) return [];

    return _.map(value, (d) => ({
      name: d.name.capitalizeFirstLetter(),
      color: d.color,
      y: d.y,
      units: units || '',
      events: {
        click: () => this.props.click && this.props.click(d.name),
      },
    }));
  }

  render() {
    let { size, height, margin, legend } = this.props.chart;
    let { loaded } = this.props;
    let { seriesName, units } = this.props.data;

    if (!loaded) {
      return <Spin tip="Loading..." />;
    } else {
      return (
        <>
          <HighchartsReact
            options={{
              chart: {
                height,
                margin,
                type: 'pie',
              },
              title: { text: undefined },
              exporting: { enabled: false },
              credits: { enabled: false },
              plotOptions: {
                pie: {
                  shadow: false,
                  cursor: 'pointer',
                  dataLabels: {
                    enabled: false,
                  },
                  showInLegend: true,
                  events:{
                    click: (event) => {
                      this.props.onClick ? this.props.onClick() : console.log('onClick null');
                    }
                  }
                },
              },
              legend: {
                align: legend.align,
                layout: legend.layout,
                verticalAlign: legend.verticalAlign,
                width: legend.width,
                x: legend.x,
                y: legend.y,
                labelFormatter: function() {
                  return `${this.name}: ${this.y} ${this.units}`;
                },
              },
              tooltip: {
                valueSuffix: ` ${units || ''}`,
              },
              series: [
                {
                  showInLegend: true,
                  name: seriesName && seriesName.capitalizeFirstLetter(),
                  colorByPoint: true,
                  size,
                  innerSize: '50%',
                  data: this.data,
                },
              ],
            }}
          />
        </>
      );
    }
  }
}

decorate(PieChart, {
  data: computed,
});

export default withRouter(observer(PieChart));
