import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import validator from 'validator';
import _ from 'lodash';

import { Row, Col, notification, Spin, Input } from 'antd';
import { ErrorField } from 'v3/components/pitstop';

import { CurrentUserStore } from 'stores';

const Container = styled.div`
  padding-right: 7vw;

  h1 {
    font-size: 2.8rem;
    font-weight: 600;
    color: #000000;
  }

  p {
    font-weight: 600;
  }

  label {
    font-weight: 600;
    display: block;
  }

  input {
    margin: 1.4vh 0vh;
  }
`;

const DashLine = styled.div`
  opacity: 0.2;
  border-top: 2px dashed #000000;
`;

const ResetButton = styled.button`
  cursor: pointer;
  border: none;
  background: #02103d;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: #ffffff;

  height: 3rem;
  min-width: 8rem;
  margin-top: 2vh;
`;

class ResetPasswordForm extends Component {
  state = {
    email: null,
    isSubmitted: false,
    isFinished: false,
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getError = (type, value) => {
    if (_.isEmpty(value) || _.isNil(value)) {
      return <ErrorField>{type} is required!</ErrorField>;
    }

    if (type === 'email' && !validator.isEmail(value)) {
      return <ErrorField>Email is invalid</ErrorField>;
    }

    return null;
  };

  onSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      isSubmitted: true,
    });

    const { email } = this.state;

    // validate email before sending request
    if (this.getError('email', email)) return;

    notification.open({
      key: 'reset-password',
      description: (
        <>
          <Spin size="large" style={{ marginRight: '0.6rem' }} />
          Your password is reset...
        </>
      ),
      duration: 0,
    });

    try {
      await CurrentUserStore.resetPassword(email);
      this.setState({
        isFinished: true,
      });
    } catch (err) {
      notification.error({
        message: 'Error',
        key: 'error',
        description: <>{err.message}</>,
      });
    } finally {
      notification.close('reset-password');
    }
  };

  render() {
    const { email, isSubmitted, isFinished } = this.state;

    return (
      <Container id="register-form">
        <Row>
          <Col>
            <h1>Forgot Password ?</h1>
          </Col>

          <DashLine />

          <Col span={24}>
            {!isFinished ? (
              <>
                <Col style={{ margin: '1.6vw 0vw' }}>
                  <label>Registered email</label>
                  <Input
                    name="email"
                    placeholder="yourname@companyname.com"
                    onChange={this.onChange}
                  />
                  {isSubmitted && this.getError('email', email)}
                </Col>

                <ResetButton isActive={email} onClick={this.onSubmit}>
                  Reset Password
                </ResetButton>
              </>
            ) : (
              <>
                <p>
                  Password reset link sent to{' '}
                  <span
                    style={{
                      color: '#8C8C8C',
                      fontWeight: 400,
                      fontSize: '1.2rem',
                    }}
                  >
                    {email}
                  </span>
                </p>
              </>
            )}

            <ResetButton style={{ marginLeft: '1.2vw' }}>
              <Link
                to="/login"
                style={{
                  color: '#ffffff',
                }}
              >
                Back to Login?
              </Link>
            </ResetButton>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ResetPasswordForm;
