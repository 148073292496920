//Map that intakes a top-level VMRS code and outputs a colour in HSL (hue, saturation, lightness) format. This is so VMRS system codes
//can have the same colour throughout the app
//It works by mapping uniquely a VMRS system code to the hue (continuously), a saturation value (of either 50 or 100), and a lightness value of
//25, 50, or 75
function VMRSColorMap(VMRS) {
  //Largest VMRS system value to calculate everything relative to
  const maxVMRS = 100;
  function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }
  let hue, saturation, lightness;
  if (VMRS > maxVMRS) {
    hue = 100;
    saturation = 100;
    lightness = 0;
  } else if (VMRS < maxVMRS / 6) {
    hue = Math.round(VMRS / (maxVMRS / 6) * 360)
    saturation = 50;
    lightness = 25;
  } else if (VMRS < (2 * maxVMRS / 6)) {
    hue = Math.round((VMRS - maxVMRS / 6) / (maxVMRS / 6) * 360)
    saturation = 50;
    lightness = 50;
  } else if (VMRS < (3 * maxVMRS / 6)) {
    hue = Math.round((VMRS - 2 * maxVMRS / 6) / (maxVMRS / 6) * 360)
    saturation = 50;
    lightness = 75;
  } else if (VMRS < (4 * maxVMRS / 6)) {
    hue = Math.round((VMRS - 3 * maxVMRS / 6) / (maxVMRS / 6) * 360)
    saturation = 100;
    lightness = 25;
  } else if (VMRS < (5 * maxVMRS / 6)) {
    hue = Math.round((VMRS - 4 * maxVMRS / 6) / (maxVMRS / 6) * 360)
    saturation = 100;
    lightness = 50;
  } else {
    hue = Math.round((VMRS - 5 * maxVMRS / 6) / (maxVMRS / 6) * 360)
    saturation = 100;
    lightness = 75;
  }
  console.log(`${hslToHex(hue, saturation, lightness)}`);
  return `${hslToHex(hue, saturation, lightness)}`
};

export default VMRSColorMap;