import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ShopStore } from 'stores';
import styled from 'styled-components';
import { Form, Button, Typography, notification, InputNumber, Spin } from 'antd';

const { Text } = Typography;

const Container = styled.div`
  padding-right: 2vw;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px;
`;

class EditProfilePage extends Component {
  componentDidMount () {
    this.load();
  }

  state = {
    loadingInitial: true,
    savingForm: false,
    markAsCompleteThresholdHours: {
      value: 5,
      validateStatus: 'success',
      errorMsg: null,
    }
  }

  // Used in case whe want to validate the number
  validateNumber = (number) => {
    if (number < 1) {
      return {
        validateStatus: 'error',
        errorMsg: 'Number must be greater or equal to 1',
      };
    }
    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  }

  load = async () => {
    const { result } = await ShopStore.getShopSettings(this.shopId);
    if (
      !_.isEmpty(result) &&
      !_.isNil(result[0].settings) &&
      !_.isNil(result[0].settings.markAsCompleteThresholdHours)
    ) {
      const value = _.get(result[0].settings, 'markAsCompleteThresholdHours', 5);
      return this.setState({
        markAsCompleteThresholdHours: {
          ...this.validateNumber(value),
          value
        }
      }, () => {
        this.setState({ loadingInitial: false });
      });
    }
    this.setState({ loadingInitial: false });
  }

  updateFleetRules = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err) => {
      if (!err) {
        try {
          this.setState({ savingForm: true });
          await ShopStore.upsertShopSettings(
            ShopStore.currentShop.id,
            {
              markAsCompleteThresholdHours: this.state.markAsCompleteThresholdHours.value
            }
          );
          notification.success({
            message: 'Rules updated',
            key: 'fleetRulesSuccess',
            description: <Container>The Fleet rules were updated!</Container>,
          });
        } catch (error) {
          notification.error({
            message: 'Oops!',
            key: 'fleetRulesError',
            description: <Container>{<Text>Error to update the fleet rules</Text>}</Container>,
          });
        } finally {
          this.setState({ savingForm: false });
        }
      }
    });
  };

  render() {
    return this.state.loadingInitial
    ? <LoadingContainer><Spin tip="Loading..." /></LoadingContainer>
    : (
      <React.Fragment>
        <Form onSubmit={this.updateFleetRules} className="edit-fleet-rules-form">
          <Form.Item
            label="Clear Fault Codes After"
            validateStatus={this.state.markAsCompleteThresholdHours.validateStatus}
            help={this.state.markAsCompleteThresholdHours.errorMsg}
          >
            <>
              <InputNumber
                placeholder="5"
                value={this.state.markAsCompleteThresholdHours.value}
                onChange={value => this.setState({
                  markAsCompleteThresholdHours: {
                    ...this.validateNumber(value),
                    value,
                  },
                })}
              />
              <Text style={{ marginLeft: '3px' }} type="secondary">Driving Hours</Text>
            </>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={this.state.markAsCompleteThresholdHours.validateStatus !== 'success'}
              loading={this.state.savingForm}
            >
              Update Rules
            </Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }
}

EditProfilePage.propTypes = {
  form: PropTypes.object.isRequired
};

export default Form.create({ name: 'edit-fleet-rules-form' })(EditProfilePage);
