import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Modal,
  Row,
  Table,
  notification,
  Tooltip,
  Select,
} from 'antd';
import { PropTypes } from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ShopStore } from 'stores';

const DarkHeaderModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-close {
      .ant-modal-close-x {
        color: #ffffff;
      }
    }

    .ant-modal-header {
      background-color: #05103a;
      border-bottom: 0;

      .ant-modal-title {
        color: #ffffff;
      }
    }

    .ant-modal-body {
      padding: 12px 18px;
    }
  }

  .footer-row {
    margin-top: 21px;
  }

  .helper-text {
    padding: 8px;
    display: flex;
    align-items: center;
    min-height: 45px;

    p {
      font-size: 0.8rem;
      margin: 0;
      padding: 0;
    }
  }

  .save-btn {
    margin-left: 8px;
  }
`;

const AssetForm = styled(Row)`
  padding: 0;

  .ant-col-12 {
    .ant-form-item {
      margin: 0;
    }
  }
`;

const AddAssetForm = Form.create({ name: 'add_asset' })(
  // eslint-disable-next-line
  class extends React.Component {
    static propTypes = {
      form: PropTypes.object,
      onSubmit: PropTypes.func,
      initialData: PropTypes.object,
      currentShopName: PropTypes.string,
      hideVin: PropTypes.bool,
    };

    onClickSave = () => {
      // get the form values and validate
      this.props.form.validateFields((err, values) => {
        if (err) {
          notification.error({
            message: 'Error',
            description: 'Please fill in all required fields',
          });
          return;
        }

        this.props.onSubmit({
          ...values,
          id: this.props.initialData?.id || null,
        });
        // clear the form
        this.props.form.resetFields();
      });
    };

    render() {
      const { form, currentShopName, hideVin } = this.props;
      const { getFieldDecorator } = form;
      return (
        <AssetForm gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Asset Type">
              {getFieldDecorator('assetType', {
                initialValue: this.props.initialData?.assetType,
                rules: [{ required: true, message: 'Please select a type' }],
              })(
                <Select
                  placeholder="Select a type"
                  style={{ width: '100%' }}
                  allowClear
                >
                  <Select.Option value="vehicle">Vehicle</Select.Option>
                  <Select.Option value="trailer">Trailer</Select.Option>
                  <Select.Option value="other">Other</Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Unit ID">
              {getFieldDecorator('unitId', {
                initialValue: this.props.initialData?.unitId,
                rules: [
                  { required: true, message: 'Please input the unit ID' },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Fleet">
              {getFieldDecorator('fleet', {
                initialValue: this.props.initialData?.fleet || currentShopName,
                rules: [
                  { required: true, message: 'Please input asset fleet' },
                ],
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          {!hideVin && (
            <Col span={12}>
              <Form.Item label="VIN/Serial No">
                {getFieldDecorator('vin', {
                  initialValue: this.props.initialData?.vin,
                })(<Input />)}
              </Form.Item>
            </Col>
          )}
          <Col span={hideVin ? 24 : 12}>
            <Form.Item label="Year">
              {getFieldDecorator('year', {
                initialValue: this.props.initialData?.year,
                rules: [
                  {
                    pattern: /^\d{4}$/,
                    message: 'The year must be a 4-digit number',
                  },
                ],
              })(<Input maxLength={4} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Make">
              {getFieldDecorator('make', {
                initialValue: this.props.initialData?.make,
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Model">
              {getFieldDecorator('model', {
                initialValue: this.props.initialData?.model,
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Mileage">
              {getFieldDecorator('mileage', {
                initialValue: this.props.initialData?.mileage,
                rules: [
                  {
                    pattern: new RegExp(/^\d+$/),
                    message: 'Mileage must be a number',
                  },
                ],
              })(<Input min={0} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Engine Hours">
              {getFieldDecorator('engineHours', {
                initialValue: this.props.initialData?.engineHours,
                rules: [
                  {
                    pattern: new RegExp(/^\d+$/),
                    message: 'Engine Hours must be a number',
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Location">
              {getFieldDecorator('location', {
                initialValue: this.props.initialData?.location,
              })(<Input min={0} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Main Driver">
              {getFieldDecorator('mainDriver', {
                initialValue: this.props.initialData?.mainDriver,
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button
              type="secondary"
              style={{ float: 'right' }}
              onClick={this.onClickSave}
            >
              Save Unit
            </Button>
          </Col>
        </AssetForm>
      );
    }
  }
);

const AssetsToAddTable = ({ assets, onDelete, onEdit }) => {
  const columns = [
    {
      title: 'Unit ID',
      dataIndex: 'unitId',
      key: 'unitId',
    },
    {
      title: 'VIN/Serial No',
      dataIndex: 'vin',
      key: 'vin',
      render: (text) =>
        text ? text : <Icon type="ellipsis" style={{ fontSize: '1rem' }} />,
    },
    {
      title: 'Fleet',
      dataIndex: 'fleet',
      key: 'fleet',
    },
    {
      title: 'Other',
      dataIndex: 'other',
      key: 'other',
      align: 'center',
      render: (_, row) => {
        // if there is no data, show the ellipsis icon
        const hasOtherData =
          row.assetType ||
          row.year ||
          row.make ||
          row.model ||
          row.mileage ||
          row.engineHours ||
          row.location ||
          row.mainDriver;

        const others = [
          { label: 'Asset Type', value: row.assetType },
          { label: 'Year', value: row.year },
          { label: 'Make', value: row.make },
          { label: 'Model', value: row.model },
          { label: 'Mileage', value: row.mileage },
          { label: 'Engine Hours', value: row.engineHours },
          { label: 'Location', value: row.location },
          { label: 'Main Driver', value: row.mainDriver },
        ];

        const onlyValidFields = others.filter((otherField) => {
          return otherField.value;
        }, []);

        const jsx = onlyValidFields.map((otherField, index) => {
          return (
            <Row key={index}>
              <Col span={24}>
                {otherField.label}: {otherField.value}
              </Col>
            </Row>
          );
        });

        return hasOtherData ? (
          <Tooltip placement="bottom" title={jsx}>
            <Button type="link" style={{ padding: 0 }}>
              More Details
            </Button>
          </Tooltip>
        ) : (
          <Icon type="ellipsis" style={{ fontSize: '1rem' }} />
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'right',
      render: (_, row) => (
        <>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              onEdit(row);
            }}
          >
            <Icon type="edit" />
          </Button>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              onDelete(row.id);
            }}
          >
            <Icon type="delete" />
          </Button>
        </>
      ),
    },
  ];

  return <Table dataSource={assets} columns={columns} rowKey="id" />;
};

AssetsToAddTable.propTypes = {
  assets: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

function AddAssetModal({ visible, onOk, onCancel, type, hideVin }) {
  const [assetsToAdd, setAssetsToAdd] = useState([]);
  const [initialData, setInitialData] = useState({});

  // Reset assetsToAdd every time the modal becomes visible
  useEffect(() => {
    if (visible) {
      setAssetsToAdd([]);
      setInitialData({});
    }
  }, [visible]);

  const onAddAssetToList = (values) => {
    setAssetsToAdd([
      ...assetsToAdd,
      {
        ...values,
        id: new Date().getTime(),
      },
    ]);
  };

  const deleteAsset = (id) => {
    setAssetsToAdd(assetsToAdd.filter((asset) => asset.id !== id));
  };

  const editAsset = (assetData) => {
    setInitialData(assetData);
  };

  return (
    <DarkHeaderModal
      onCancel={onCancel}
      title="Add Asset Without Telematics"
      visible={visible}
      footer={null}
    >
      <AddAssetForm
        initialData={initialData}
        hideVin={hideVin}
        onSubmit={({ id, ...values }) => {
          if (id) {
            // if the id is present, it means we are editing
            const updatedAssets = assetsToAdd.map((asset) => {
              if (asset.id === id) {
                return {
                  ...asset,
                  ...values,
                };
              }
              return asset;
            });
            setAssetsToAdd(updatedAssets);
            setInitialData({});
            return;
          }
          onAddAssetToList(values);
        }}
        currentShopName={ShopStore.currentShop?.name}
      />
      <AssetsToAddTable
        assets={assetsToAdd}
        onDelete={deleteAsset}
        onEdit={editAsset}
      />
      <Row className="footer-row" gutter={[16, 16]}>
        <Col className="helper-text" span={16}>
          <p>Click "Save" when finished adding units to your account</p>
        </Col>
        <Col span={8}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            className="save-btn"
            type="primary"
            onClick={() => {
              onOk(assetsToAdd);
            }}
          >
            Save
          </Button>
        </Col>
      </Row>
    </DarkHeaderModal>
  );
}

AddAssetModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.string,
  hideVin: PropTypes.bool,
};

AddAssetModal.defaultProps = {
  hideVin: false,
};

export default AddAssetModal;
