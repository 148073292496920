const prescriptiveMaintenanceMessages = (maxPriority, maxMajorIssueDate, routineService, issueCounts = null) => {
  if (issueCounts === null){
    let now = new Date();
    let oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    if (maxPriority >= 4){
      return 'This vehicle has high priority issues. Please take it in for a service visit as soon as you can';
    }
    else if (maxPriority >= 2) {
      if (oneWeekAgo < maxMajorIssueDate){
        return 'This vehicle has issues that may be problematic. Please contact the driver about the state of the vehicle.';
      } else {
        return 'This vehicle has issues that may be problematic. These issues will need attention at your next scheduled service visit.';
      }
    }
    else if (routineService === true){
      return 'This vehicle has an overdue routine service';
    }  
    else {
      return 'No action recommended';
    }
  }
  else {
    if (issueCounts.critical >= 1){
      return 'This vehicle has high priority issues. Please take it in for a service visit as soon as you can';
    }
    else if (issueCounts.major_call_driver >= 1) {
      return 'This vehicle has issues that may be problematic. Please contact the driver about the state of the vehicle.';
    }
    else if (issueCounts.major_no_call >= 1){
      return 'This vehicle has issues that may be problematic. These issues will need attention at your next scheduled service visit.';
    }
    else if (routineService === true){
      return 'This vehicle has an overdue routine service';
    }  
    else {
      return 'No action recommended';
    }
  }
};

export default prescriptiveMaintenanceMessages;