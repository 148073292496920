import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'antd';

const VideoWrapper = ({ title, embedVideoSrc, videoUrl }) => {
  return (
    <>
      <div style={{ position: 'relative', paddingBottom: '43.4375%', height: 0, marginBottom: '15px' }}>
        <iframe
          src={embedVideoSrc}
          title={title}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        </iframe>
      </div>
      <Button type="primary" icon="video-camera" href={videoUrl} target="_blank">
        Can't see the video? Click here.
      </Button>
    </>
  );
};

VideoWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  embedVideoSrc: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
};

export default VideoWrapper;
