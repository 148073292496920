import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import HighchartsReact from 'highcharts-react-official';
import { Collapse, Result, Row, Col } from 'antd';

import { interpolateColors } from 'helpers/color-generator';

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
  }
`;

const PartContainer = styled.div`
  && {
    display: flex;

    h6:first-child,
    p:first-child {
      width: 60%;
    }

    p {
      border-bottom: 1px solid grey;
    }
  }
`;

class SymptomAnalyserChart extends Component {
  static propTypes = {
    data: PropTypes.array,
    symptomData: PropTypes.object
  };

  getSymptomData(data) {
    let colors = interpolateColors(data.length);

    let value = _.map(data, (d, i) => ({
      name: d['category'],
      color: colors[i],
      y: d['probability']
    }));

    return {
      key: 'Symptom',
      value
    };
  }

  renderDetails = data => {
    return (
      <StyledCollapse accordion bordered={false}>
        {_.map(data, (d, i) => {
          return (
            <Panel
              key={i}
              header={
                <PartContainer>
                  <h6>{`${d['category']}`}</h6>
                  <h6>{`${Math.round(d['probability'])}%`}</h6>
                </PartContainer>
              }
              tabindex={d['id']}
            >
              {_.map(d.parts, (partsDetail, i) => (
                <PartContainer key={i}>
                  <p>{partsDetail.partname}</p>
                  <p>{`${Math.round(partsDetail.part_probability)}%`}</p>
                </PartContainer>
              ))}
            </Panel>
          );
        })}
      </StyledCollapse>
    );
  };

  renderChart = data => {
    return (
      <HighchartsReact
        options={{
          chart: {
            type: 'pie',
            height: 300,
            marginRight: Number(`${window.innerWidth > 1600 ? 100 : 0}`)
          },
          title: { text: undefined },
          exporting: { enabled: false },
          credits: { enabled: false },
          plotOptions: {
            pie: {
              shadow: false,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          tooltip: {
            valueSuffix: '%'
          },
          legend: {
            align: 'left',
            layout: 'vertical',
            verticalAlign: 'top',
            y: 100
          },
          accessibility: {
            point: {
              valueSuffix: '%'
            }
          },
          series: [
            {
              name: 'Issues',
              showInLegend: true,
              colorByPoint: true,
              innerSize: '50%',
              data: data.value
            }
          ]
        }}
      ></HighchartsReact>
    );
  };

  render() {
    let { data } = this.props;

    return (
      <>
        {!_.isNil(data) &&
          (_.isEmpty(data) ? (
            <Result
              status="warning"
              title="No Results were found. Please change your search and try again."
            />
          ) : (
            <Row style={{ marginTop: '50px' }}>
              <Col xl={12} sm={24} xs={24}>
                {this.renderChart(this.getSymptomData(data))}
              </Col>
              <Col xl={12} sm={24} xs={24}>
                {this.renderDetails(data)}
              </Col>
            </Row>
          ))}
      </>
    );
  }
}

export default SymptomAnalyserChart;
