import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { decorate, observable, observe } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PitstopButton } from 'shared';
import { ShopStore } from 'stores';
import DealershipChooser from 'components/DealershipChooser';
import { Link } from 'react-router-dom';
import { Row, Col, Icon } from 'antd';
import PitstopSimpleTable from 'components/PitstopSimpleTable';
import ContactStore from 'stores/ContactStore';
import ContactFormModal from 'components/Contacts/ContactFormModal';
import AppStore from 'stores/App';

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 25px 0 10px;
`;

const ActionButtonsWrapper = styled.div`
  right: 15px;

  button {
    margin-left: 10px;
  }
`;

class ContactPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  shopId = ShopStore.currentShop.id;
  CONTACT_FORM_MODAL_ID = 'contact_form_modal_id';

  state = {
    savingContact: false,
    loading: false,
    data: [],
    contactModalTitle: 'Add Vendor',
    selectedContactData: {},
  };

  columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Link onClick={(event) => this.onClickDetails(event, record)} to="/">
          {text}
        </Link>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => {
        return text || '-';
      }
    },
    {
      title: 'Contact',
      dataIndex: 'sales_person',
      key: 'sales_person',
      render: (text) => {
        return text || '-';
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'right',
      render: (a, record) => (
        <Fragment>
          <Icon
            type="edit"
            onClick={() => this.onClickEdit(record)}
            style={{ marginRight: 8 }}
          />
          {/* <Popconfirm
            title="Sure to delete?"
            onConfirm={() => this.onConfirmDelete(record)}
          >
            <Icon type="delete" />
          </Popconfirm> */}
        </Fragment>
      ),
    },
  ];

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    return this.refreshShopContacts(ShopStore.currentShop.id);
  });

  componentWillUnmount() {
    this.disposer();
  }

  componentDidMount() {
    this.refreshShopContacts();
  }

  onClickAddContact = () => {
    this.setState(
      {
        contactModalTitle: 'Add Vendor',
        selectedContactData: {},
      },
      () => {
        AppStore.openModals.set(this.CONTACT_FORM_MODAL_ID, true);
      }
    );
  };

  onClickDetails = (event, contact) => {
    event.preventDefault();
    this.onClickEdit(contact);
  };

  /**
   * Open modal to edit contact
   */
  onClickEdit = async (contact) => {
    this.setState(
      {
        contactModalTitle: 'Edit Contact',
        selectedContactData: { ...contact, salesPerson: contact.sales_person },
      },
      () => {
        AppStore.openModals.set(this.CONTACT_FORM_MODAL_ID, true);
      }
    );
  };

  /**
   * Delete contact
   * @param {Object} contact
   */
  onConfirmDelete = async (contact) => {
    try {
      await this.setState({ loading: true });
      await ContactStore.deleteShopContact(contact.id);
      AppStore.addSuccess('Contact deleted successfully');
      await this.refreshShopContacts();
    } catch (error) {
      console.log(error);
      AppStore.addError('Error to delete contact: ' + error);
    } finally {
      this.setState({ loading: false });
    }
  };

  refreshShopContacts = async (shopId = ShopStore.currentShop.id) => {
    try {
      await this.setState({ loading: true });
      const contacts = await ContactStore.getShopContacts(shopId);
      await this.setState({ data: contacts });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  onSubmitContactForm = async (data) => {
    try {
      await this.setState({ savingContact: true });
      if (data.contactId) {
        // update the contact
        await ContactStore.updateShopContact(data.contactId, {
          ...data,
        });
      } else {
        // create a new contact
        await ContactStore.createShopContact({
          ...data,
          shopId: ShopStore.currentShop.id,
        });
      }
      AppStore.addSuccess('Contact saved successfully');
      await this.refreshShopContacts();
      // clear the form
      this.setState({ selectedContactData: {} });
      AppStore.openModals.set(this.CONTACT_FORM_MODAL_ID, false);
    } catch (error) {
      console.log(error);
      AppStore.addError('Error to save contact: ' + error.message);
    } finally {
      await this.setState({ savingContact: false });
    }
  };

  render() {
    return (
      <Fragment>
        <ContactFormModal
          modalId={this.CONTACT_FORM_MODAL_ID}
          saving={this.state.savingContact}
          title={this.state.contactModalTitle}
          defaultData={this.state.selectedContactData}
          onCancel={() => {
            this.setState({ selectedContactData: {} });
            AppStore.openModals.set(this.CONTACT_FORM_MODAL_ID, false);
          }}
          onSubmit={(data) => {
            this.onSubmitContactForm(data);
          }}
        />

        <DealershipChooser />
        <ActionsWrapper>
          <h1>Vendor Management</h1>
          <ActionButtonsWrapper>
            <PitstopButton icon="plus" onClick={() => this.onClickAddContact()}>
              Add Vendor
            </PitstopButton>
          </ActionButtonsWrapper>
        </ActionsWrapper>

        <Row>
          <Col span={24}>
            <PitstopSimpleTable
              loading={this.state.loading}
              columns={this.columns}
              data={this.state.data}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

decorate(ContactPage, {
  shopId: observable,
});

export default withRouter(observer(ContactPage));
