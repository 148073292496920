import React from 'react';
import { Card, Tooltip, Spin, Typography } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Text = Typography.Text;

const DVIRAmountText = styled.p`
  font-size: 55px;
  font-weight: bold;
  text-align: center;
  margin: 0;
  padding: 0;
`;

const DVIRCategoryContainer = styled.div`
  .categoryTitle {
    height: 50px;
  }

  .dvir-category-container {
    cursor: pointer;

    &:hover {
      p {
        text-decoration: underline;
        text-decoration-color: #9b9b9b;
        text-decoration-thickness: 1px;
        text-underline-offset: 3px;
      }
    }
  }
`;

const DVIRCategoriesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 90%;
`;

const DVIRCountWidget = ({ onClickAmount, dvirValues, isLoaded }) => {
  return (
    <Card className="summary-card">
      <div className="summary-card__title">
        <Text strong>
          DVIR{' '}
          <span className="filter-helper">
            (Click on Safe, Unsafe or Resolved below to filter by)
          </span>
        </Text>
      </div>
      {isLoaded ? (
        <>
          <DVIRCategoriesContainer>
            <DVIRCategoryContainer className="left">
              <Tooltip placement="top" title={'Click to filter by'}>
                <div
                  className="dvir-category-container"
                  onClick={() => onClickAmount('safe')}
                >
                  <DVIRAmountText style={{ color: '#237804' }}>
                    {_.get(dvirValues, 'safe', 0)}
                  </DVIRAmountText>
                  <p className="categoryTitle">Vehicles Safe</p>
                </div>
              </Tooltip>
            </DVIRCategoryContainer>
            <DVIRCategoryContainer className="right">
              <Tooltip placement="top" title={'Click to filter by'}>
                <div
                  className="dvir-category-container"
                  onClick={() => onClickAmount('unsafe')}
                >
                  <DVIRAmountText style={{ color: '#d4380d' }}>
                    {_.get(dvirValues, 'unsafe', 0)}
                  </DVIRAmountText>
                  <p className="categoryTitle">Vehicles Unsafe</p>
                </div>
              </Tooltip>
            </DVIRCategoryContainer>
            <DVIRCategoryContainer>
              <Tooltip placement="top" title={'Click to filter by'}>
                <div
                  className="dvir-category-container"
                  onClick={() => onClickAmount('resolved')}
                >
                  <DVIRAmountText style={{ color: '#003a8c' }}>
                    {_.get(dvirValues, 'resolved', 0)}
                  </DVIRAmountText>
                  <p className="categoryTitle">Resolved</p>
                </div>
              </Tooltip>
            </DVIRCategoryContainer>
          </DVIRCategoriesContainer>
        </>
      ) : (
        <Spin tip="Loading..." />
      )}
    </Card>
  );
};

DVIRCountWidget.propTypes = {
  onClickAmount: PropTypes.func,
  dvirValues: PropTypes.object,
  isLoaded: PropTypes.bool,
};

export default DVIRCountWidget;
