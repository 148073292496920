import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import DocumentUploadList from './modals/DocumentUploadList';
import PropTypes from 'prop-types';

const DocumentUploader = ({
  onFileUploaded,
  wo_files,
  overrideUploadProps = {},
  customClass = '',
  showTitle = true,
  maxVisibleItems = null,
  childRef,
}) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (wo_files.wo_documents && wo_files.wo_documents.length > 0) {
      const getFileName = (documentOrUrl, index) => {
        // here is a file name example: dcff6c40-9013-11ef-b0d4-9d8c8c0161cdCanvec F225283 (1).pdf
        // check pattern of uuid.v1
        const uuidPattern = new RegExp(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/);
        if (documentOrUrl.name) {
          return documentOrUrl.name;
        }
        const name = documentOrUrl.split('/').pop(); // get the last part of the URL
        if (uuidPattern.test(name)) {
          return name.replace(uuidPattern, ''); // remove the UUID
        }
        return name;
      };
      const initialFileList = wo_files.wo_documents.map(
        (documentOrUrl, index) => ({
          uid: `old-doc-${index}`,
          name: getFileName(documentOrUrl, index),
          status: 'done',
          url: documentOrUrl.url ? documentOrUrl.url : documentOrUrl,
        })
      );

      setFileList(initialFileList);
    }
  }, [wo_files.wo_documents]);

  const handleFileChange = ({ file, fileList: newFileList }) => {
    const filteredList = newFileList.filter(
      (f) => !f.size || f.size / 1024 / 1024 <= 10
    );

    if (newFileList.length > filteredList.length) {
      notification.open({
        message: <div style={{ color: 'red' }}>Error</div>,
        description: 'File size must be less than 10MB.',
      });
    }

    setFileList(filteredList);
  };

  const beforeUpload = (file) => {
    const isGreaterThan2M = file.size / 1024 / 1024 > 10;
    if (isGreaterThan2M) {
      return false;
    }

    onFileUploaded(file);
    return false;
  };

  if (childRef) {
    childRef({
      beforeUpload,
      setFileList
    });
  }

  const uploadProps = {
    accept: '.pdf',
    onRemove: (file) => {
      setFileList((curr) => {
        const index = curr.indexOf(file);
        const newFileList = curr.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
      onFileUploaded(file, 'removed');
    },
    onPreview: () => {},
    fileList,
    onChange: handleFileChange,
    beforeUpload,
    multiple: true,
    ...overrideUploadProps,
  };

  return (
    <div
      className={`document-uploader-container ${
        customClass ? customClass : ''
      }`}
    >
      {showTitle && (
        <div>
          <b>Add Documents:</b>
        </div>
      )}
      <DocumentUploadList
        uploadProps={uploadProps}
        maxVisibleItems={maxVisibleItems}
      />
    </div>
  );
};

DocumentUploader.propTypes = {
  onFileUploaded: PropTypes.func,
  wo_files: PropTypes.object,
  overrideUploadProps: PropTypes.object,
  showTitle: PropTypes.bool,
  maxVisibleItems: PropTypes.number,
  customClass: PropTypes.string,
};

DocumentUploader.defaultProps = {
  onFileUploaded: () => {},
  wo_files: {
    wo_images: [],
    wo_documents: [],
  },
  overrideUploadProps: {},
  showTitle: true,
  maxVisibleItems: null,
  customClass: '',
};

export default DocumentUploader;
