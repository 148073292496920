import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Steps } from 'antd';

const Step = Steps.Step;

class PitstopStepper extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <Steps size="small" current={this.props.index}>
            {this.props.steps.map(el => (
              <Step title={el} key={el} />
            ))}
          </Steps>
        </div>
      </div>
    );
  }
}

PitstopStepper.propTypes = {
  index: PropTypes.number,
  onClick: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.string)
};

PitstopStepper.defaultProps = {
  index: 0,
  steps: []
};

export default observer(PitstopStepper);
