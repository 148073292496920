import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import _ from 'lodash';

import { Input, Icon, Tooltip } from 'antd';

import { TableStore } from 'stores/abstract';
import { CarStore } from 'stores';

const FilterSection = styled.div`
  display: flex;
  max-width: 900px;
  margin-bottom: 12px;
  && > div {
    flex: 1;
    display: inline-flex;
    align-items: center;
    margin-right: 20px;
  }
  && > div p {
    width: 80px;
    margin-bottom: 0;
  }
  && > div input {
    flex: 1;
  }
`;

class CarListFilter extends Component {
  state = {
    filter: this.tableStore ? { ...this.tableStore.filter } : {},
  };

  get tableStore() {
    return this.props.tableStore || {};
  }

  filterValue = async (key, value) => {
    let { filter } = this.state;

    if (_.isEmpty(value)) {
      delete filter[key];
    } else {
      filter[key] = value;
    }

    this.setState(
      {
        filter,
      },
      () => {
        this.props.setFilter(key, value);
      }
    );
  };

  filterValueFetch = async (key, value) => {
    let filter = { ...this.tableStore.filter, [key]: value };
    let limit = this.tableStore.limit;
    let sort = this.tableStore.sort;

    if (!value || value.length === 0) {
      delete filter[key];
    }

    await CarStore.getShopCarsForTable(
      {
        offset: 0,
        filter,
        limit,
        sort,
      },
      this.tableStore
    );
  };

  render() {
    let { filter } = this.state;

    return (
      <>
        <FilterSection>
          <div>
            <Tooltip trigger={['focus']} title="Make" placement="topLeft">
              <Input
                placeholder="Make"
                value={
                  this.tableStore.loaded
                    ? this.tableStore.filter?.make
                    : filter?.make
                }
                prefix={<Icon type="search" />}
                onChange={(e) => this.filterValue('make', e.target.value)}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip trigger={['focus']} title="Model" placement="topLeft">
              <Input
                placeholder="Model"
                value={
                  this.tableStore.loaded
                    ? this.tableStore.filter?.model
                    : filter?.model
                }
                prefix={<Icon type="search" />}
                onChange={(e) => this.filterValue('model', e.target.value)}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip trigger={['focus']} title="Year" placement="topLeft">
              <Input
                placeholder="Year"
                value={
                  this.tableStore.loaded
                    ? this.tableStore.filter?.year
                    : filter?.year
                }
                prefix={<Icon type="search" />}
                onChange={(e) => this.filterValue('year', e.target.value)}
              />
            </Tooltip>
          </div>
          <div />
        </FilterSection>

        <FilterSection>
          <div>
            <Tooltip trigger={['focus']} title="Vin" placement="topLeft">
              <Input
                placeholder="Vin"
                value={
                  this.tableStore.loaded
                    ? this.tableStore.filter?.vin
                    : filter?.vin
                }
                prefix={<Icon type="search" />}
                onChange={(e) => this.filterValue('vin', e.target.value)}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip trigger={['focus']} title="Unit ID" placement="topLeft">
              <Input
                placeholder="Unit ID"
                value={
                  this.tableStore.loaded
                    ? this.tableStore.filter?.carName
                    : filter?.carName
                }
                prefix={<Icon type="search" />}
                onChange={(e) => this.filterValue('carName', e.target.value)}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip trigger={['focus']} title="Device" placement="topLeft">
              <Input
                placeholder="Device"
                value={
                  this.tableStore.loaded
                    ? this.tableStore.filter?.scanner
                    : filter?.scanner
                }
                prefix={<Icon type="search" />}
                onChange={(e) => this.filterValue('scanner', e.target.value)}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip trigger={['focus']} title="Driver" placement="topLeft">
              <Input
                placeholder="Driver"
                value={
                  this.tableStore.loaded
                    ? this.tableStore.filter?.name
                    : filter?.name
                }
                prefix={<Icon type="search" />}
                onChange={(e) => this.filterValue('name', e.target.value)}
              />
            </Tooltip>
          </div>
        </FilterSection>
      </>
    );
  }
}

CarListFilter.propTypes = {
  tableStore: PropTypes.instanceOf(TableStore),
  userId: PropTypes.number,
  setFilter: PropTypes.func,
};

export default observer(CarListFilter);
