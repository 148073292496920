import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Popconfirm, Table } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import WarrantyDetailsModal from './WarrantyDetailsModal';
import { startCase } from 'lodash';

const CustomTable = styled(Table)`
  // Table headers with 13px font size
  .ant-table-thead th {
    font-size: 13px;
  }
`;

class WarrantiesToAdd extends Component {
  state = {
    selectedRowKeys: [],
    warrantyToEdit: null,
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onClickEdit = (warranty) => {
    this.setState({
      warrantyToEdit: {
        ...warranty,
        id: warranty.id,
        name: warranty.warrantyName,
        coverage_type: warranty.coverageType,
        id_contact: warranty.vendor,
        start_date: warranty.startDate,
        coverage_duration: warranty.coverageDuration,
        coverage_duration_unit: warranty.coverageDurationPeriod,
        end_date: warranty.endDate,
        start_meter: warranty.startMeter,
        range_meter: warranty.range,
        end_meter: warranty.endMeter,
        documents: warranty.documents,
        components: warranty.components,
      },
    });
  };

  handleWarrantyEdit = (data) => {
    if (this.props.onUpdateWarranty) {
      this.props.onUpdateWarranty(data);
      this.setState({
        warrantyToEdit: null,
      });
    }
  };

  render() {
    const {
      warranties,
      assets = [],
      vendors = [],
      onClickDelete = () => {},
      onBulkDeleteWarranties = () => {},
    } = this.props;
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const columns = [
      {
        title: 'Unit IDs',
        dataIndex: 'assets',
        key: 'assets',
        render: (arr) => {
          return arr
            .map((assetId) => {
              const asset = assets.find((asset) => asset.id === assetId);
              return asset ? asset.unitId : assetId;
            })
            .join(', ');
        },
      },
      {
        title: 'Name',
        dataIndex: 'warrantyName',
        key: 'warrantyName',
      },
      {
        title: 'Coverage Type',
        dataIndex: 'coverageType',
        key: 'coverageType',
        sorter: (a, b) => a.coverageType.localeCompare(b.coverageType),
        render: (coverageType) => {
          return startCase(coverageType);
        },
      },
      {
        title: 'Vendor',
        dataIndex: 'vendor',
        key: 'vendor',
        render: (vendorId) => {
          const vendor = vendors.find((vendor) => vendor.id === vendorId);
          return vendor ? vendor.name : vendorId;
        },
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (date) => new Date(moment(date).toDate()).toLocaleDateString(),
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
        render: (date) => new Date(moment(date).toDate()).toLocaleDateString(),
      },
      {
        title: 'Start Meter',
        dataIndex: 'startMeter',
        key: 'startMeter',
      },
      {
        title: 'Range',
        dataIndex: 'range',
        key: 'range',
      },
      {
        title: 'End Meter',
        dataIndex: 'endMeter',
        key: 'endMeter',
        sorter: (a, b) =>
          parseInt(a.endMeter.replace(/[^0-9]/g, '')) -
          parseInt(b.endMeter.replace(/[^0-9]/g, '')),
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <>
            <Popconfirm
              title="Are you sure you want to delete this warranty?"
              onConfirm={() => onClickDelete(record.key)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" icon="delete" />
            </Popconfirm>
            <Button
              onClick={() => this.onClickEdit(record)}
              type="link"
              icon="edit"
            />
          </>
        ),
      },
    ];

    return (
      <div
        style={{
          padding: 12,
        }}
      >
        <Button
          disabled={this.state.selectedRowKeys.length === 0}
          type="primary"
          style={{ marginBottom: 12 }}
          onClick={() => {
            onBulkDeleteWarranties(selectedRowKeys);
            this.setState({ selectedRowKeys: [] });
          }}
        >
          Remove Warranty(s)
        </Button>
        <CustomTable
          rowKey="key"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={warranties}
        />
        <WarrantyDetailsModal
          visible={this.state.warrantyToEdit !== null}
          saving={false}
          isEditingNewWarranty={true}
          warranty={this.state.warrantyToEdit}
          onCancel={() => {
            this.setState({
              warrantyToEdit: null,
            });
          }}
          onSubmit={(data) => {
            this.handleWarrantyEdit(data);
          }}
        />
      </div>
    );
  }
}

WarrantiesToAdd.propTypes = {
  warranties: PropTypes.array,
  assets: PropTypes.array,
  vendors: PropTypes.array,
  onClickDelete: PropTypes.func,
  onBulkDeleteWarranties: PropTypes.func,
  onUpdateWarranty: PropTypes.func,
};

export default WarrantiesToAdd;
