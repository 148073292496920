import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

export function PistopTags(props) {
  const { closable, displayField, entities, onClose, uniqueKey } = props;
  return (
    <Fragment>
      {entities.map(e => (
        <Tag onClose={() => onClose(e)} closable={closable} key={e[uniqueKey]}>
          {e[displayField]}
        </Tag>
      ))}
    </Fragment>
  );
}

PistopTags.propTypes = {
  closable: PropTypes.bool,
  displayField: PropTypes.string.isRequired,
  entities: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  uniqueKey: PropTypes.string.isRequired
};
