import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserStore from 'stores/UserStore';
import AppStore from 'stores/App';
import { Form, Icon, Input, Button } from 'antd';

class EditPasswordForm extends Component {
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };
  submitResetPassword = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const MESSAGE = 'Updating Password...';
        AppStore.addLoading(MESSAGE);
        UserStore.changePassword(
          values.currentPassword,
          values.newPassword,
          (success, message) => {
            if (success) {
              AppStore.addSuccess(message);
            } else {
              if (message === 'invalid old password')
                AppStore.addError('Your current password is invalid!');
            }
            AppStore.removeLoading(MESSAGE);
            if (success) {
              this.setState({ showResetPassword: false });
            }
          }
        );
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <React.Fragment>
        <Form
          onSubmit={this.submitResetPassword}
          className="edit-password-form"
        >
          <Form.Item label="Current Password">
            {getFieldDecorator('currentPassword', {
              rules: [
                {
                  required: true,
                  message: 'Please input your current password!'
                }
              ]
            })(
              <Input
                type="password"
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Current Password"
              />
            )}
          </Form.Item>
          <Form.Item label="New Password">
            {getFieldDecorator('newPassword', {
              rules: [
                { required: true, message: 'Please input your new password!' }
              ]
            })(
              <Input
                type="password"
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="New Password"
              />
            )}
          </Form.Item>
          <Form.Item label="Confirm New Password">
            {getFieldDecorator('newPassword2', {
              rules: [
                {
                  required: true,
                  message: 'Please input your new password again!'
                },
                {
                  validator: this.compareToFirstPassword
                }
              ]
            })(
              <Input
                type="password"
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="New Password"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              disabled={this.props.saveChangesDisable}
              type="primary"
              htmlType="submit"
            >
              Update Password
            </Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }
}

EditPasswordForm.propTypes = {
  form: PropTypes.object.isRequired,
  saveChangesDisable: PropTypes.bool
};

export default Form.create({ name: 'edit-password-form' })(EditPasswordForm);
