/* eslint-disable react/prop-types */
import React, { Component } from 'react';

import { Select } from 'antd';

const { Option } = Select;

export class PitstopSelect extends Component {
  render() {
    const {
      allowClear,
      options,
      selectOptionDisplayField,
      selectOptionValueField
    } = this.props;
    const objectOptions = typeof options[0] === 'object';
    const optionDisplayField = selectOptionDisplayField || 'name';
    const optionValueField = selectOptionValueField || 'id';
    return (
      <Select
        allowClear={allowClear}
        filterOption={!objectOptions ? true : filterOption}
        showSearch
        style={{ width: 150 }}
        value={this.props.value}
        onChange={this.props.onChange}
      >
        {!objectOptions
          ? options.map(option => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))
          : options.map(option => (
              <Option
                key={option[optionValueField]}
                value={option[optionValueField]}
              >
                {option[optionDisplayField]}
              </Option>
            ))}
      </Select>
    );
  }
}

function filterOption(input, option) {
  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}
