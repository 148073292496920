export default [
  {
    id: 0,
    name: 'Domnic Brown',
    thumb: 'https://via.placeholder.com/150x150',
    status: 'online',
    mood: 'English versions from the 1914 translation by H. Rackham',
    lastMessage: 'Great! Pitstop do good job',
    unreadMessage: '4',
    lastMessageTime: 'Yesterday',
    recent: true,
  },
  {
    id: 1,
    name: 'Alex Dolgove',
    thumb: 'https://via.placeholder.com/150x150',
    status: 'away',
    mood: 'English versions from the 1914 translation by H. Rackham',
    lastMessage:
      'Hey, Pitstop alert vehicle A has a brake issue. Please check it! These predictive alerts are helpful for us',
    unreadMessage: '',
    lastMessageTime: '20 min ago',
    recent: false,
  },
  {
    id: 2,
    name: 'Tom Harris',
    thumb: 'https://via.placeholder.com/150x150',
    status: 'offline',
    mood: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
    lastMessage:
      'Please set up a maintenance service for Oil Change every 5,000km to all vehicles!',
    unreadMessage: '',
    lastMessageTime: '20/11/17',
    recent: false,
  },
];
