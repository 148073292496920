import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { Input } from 'antd';
import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';

const TextAreaWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    bottom: 0;
    width: 16px;
    right: 10px;
    color: #d4d4d4;
    cursor: pointer;
  }
`;

const CustomTextArea = styled(Input.TextArea)`
  font-size: 14px;
  resize: none;
  height: 200px;

  &.ant-input {
    cursor: pointer;
    padding-top: 6px;
    min-height: 99px;
    font-size: 13px;
    line-height: 15px;
  }
`;

const VehicleNoteTextarea = ({ car, onSave }) => {
  const originalNotes = car.notes;
  const [notes, setNotes] = useState(originalNotes || '');
  return (
    <TextAreaWrapper>
      <CustomTextArea
        onClick={(e) => {
          e.stopPropagation();
        }}
        value={notes}
        placeholder="Note vehicle condition, issues, driver feedback, or any details..."
        // autoSize={{ minRows: 4, maxRows: 5 }}
        autoSize={false}
        onChange={(e) => {
          e.stopPropagation();
          setNotes(e.target.value);
        }}
        onBlur={(e) => {
          e.preventDefault();
          onSave({ id: car.id, notes });
        }}
      />
      <EditIcon
        onClick={(e) => {
          e.stopPropagation();
          // focus the textarea
          const textarea = e.target.previousSibling;
          textarea.focus();
        }}
      />
    </TextAreaWrapper>
  );
};

VehicleNoteTextarea.propTypes = {
  car: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default VehicleNoteTextarea;
