import React, { Component } from 'react';

import { Button, Card } from 'antd';
import { Loading } from 'components';

import { AppStore, ShopStore } from 'stores';

import { webServiceProvider } from 'shared';

const { Meta } = Card;

const cardContent = [
  {
    id: 0,
    title: 'Low Notifications',
    content:
      'The threshold is higher leading to less sensitive behavior. This means that the algorithm may trigger fewer warnings. This case is used when the consumer is not worried about a particular algorithm.',
    cover: (
      <div style={{ backgroundColor: '#72BB74', width: 30, height: 50 }}></div>
    ),
  },
  {
    id: 1,
    title: 'Default',
    content:
      'Pitstop standard, as determined by our engineers and data scientists.',
    cover: (
      <div style={{ backgroundColor: '#F5E59B', width: 30, height: 50 }}></div>
    ),
  },
  {
    id: 2,
    title: 'High Notifications',
    content:
      'The threshold is lower leading to more sensitive behavior. This means that the algorithm may trigger warnings more often, leading to more notifications. This case is used for when the consumer wants to see the smallest decline in performance.',
    cover: (
      <div style={{ backgroundColor: '#ED5565', width: 30, height: 50 }}></div>
    ),
  },
];

class FtConfig extends Component {
  state = { threshold: null };

  componentDidMount() {
    this.getConfig();
  }
  getConfig = async () => {
    try {
      let data = await webServiceProvider.getMany('v1/labs/ftconfig', {
        shopID: ShopStore.currentShop.id,
      });

      this.setState(
        {
          threshold:
            data.threshold === 'low' ? 0 : data.threshold === 'high' ? 2 : 1,
        },
        () => {
          this.setState({ currentThreshold: this.state.threshold });
        }
      );
    } catch (e) {
      AppStore.addError(e.message);
    }
  };

  postConfig = () => {
    try {
      if (process.env.NODE_ENV !== 'production') {
        webServiceProvider.post('v1/labs/ftconfig', {
          shopID: ShopStore.currentShop.id,
          threshold: this.formatter(this.state.currentThreshold),
        });
      }

      AppStore.addSuccess('Sensitivity has been saved!');
    } catch (e) {
      AppStore.addError(e);
    }
  };

  formatter = (value) => {
    if (value === 0) {
      return 'low';
    }
    if (value === 1) {
      return 'med';
    }
    if (value === 2) {
      return 'high';
    }
  };

  render() {
    return (
      <div>
        <h1>Sensitivity Control</h1>
        {this.state.threshold !== null ? (
          <div style={{ width: '500px', marginTop: '50px' }}>
            {cardContent.map((content) => {
              return (
                <Card
                  key={content.id}
                  id={content.id}
                  onClick={() =>
                    this.setState({ currentThreshold: content.id })
                  }
                  hoverable
                  style={{
                    backgroundColor:
                      this.state.currentThreshold === content.id
                        ? '#A2CDF9'
                        : 'white',
                  }}
                >
                  <Meta
                    title={content.title}
                    avatar={content.cover}
                    description={content.content}
                  ></Meta>
                </Card>
              );
            })}
            <Button
              type="primary"
              onClick={this.postConfig}
              style={{ marginTop: 12 }}
            >
              Save Sensitivity
            </Button>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

export default FtConfig;
