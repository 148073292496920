import { observable, decorate, action, transaction, computed } from 'mobx';

const createId = () => {
  return `${new Date().getTime()}-${Math.floor(Math.random() * 1000)}`;
};

class TableStore {
  id = createId();
  count = 0;
  data = [];
  loaded = false;
  pending = false;
  offset = 0;
  limit = 10;
  sort = undefined;
  filter = {};
  isError = false;
  isReloaded = false;
  errorMessage = null;

  reset = () => {
    transaction(() => {
      this.loaded = false;
      this.pending = false;
      this.isError = false;
      this.count = 0;
      this.offset = 0;
      this.limit = 10;
      this.sort = undefined;
      this.filter = {};
      this.data = [];
      this.isReloaded = false;
    });
  };

  setDataMetadata = (total, offset, limit, sort, filter) => {
    transaction(() => {
      this.count = Number(total);
      this.offset = Number(offset);
      this.limit = Number(limit);
      this.sort = sort;
      this.filter = filter;
      this.loaded = true;
      this.pending = false;
      this.isReloaded = false;
    });
  };

  setError = (message) => {
    transaction(() => {
      this.data = [];
      this.count = 0;
      this.offset = 0;
      this.limit = 10;
      this.filter = {};
      this.sort = undefined;
      this.pending = false;
      this.loaded = false;
      this.isError = true;
      this.errorMessage = message;
    });
  };

  resetError = () => {
    transaction(() => {
      this.isError = false;
    });
  };

  reload = () => {
    this.isReloaded = true;
  };

  get isEmpty() {
    if (!this.loaded || this.isError) {
      return false;
    }
    return this.data.length === 0;
  }
}

decorate(TableStore, {
  loaded: observable,
  pending: observable,
  data: observable,
  offset: observable,
  limit: observable,
  filter: observable,
  isError: observable,
  errorMessage: observable,
  isReloaded: observable,
  reset: action,
  setError: action,
  resetError: action,
  reload: action,
  isEmpty: computed,
});

export default TableStore;
