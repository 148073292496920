import React, { Component } from 'react';

import { Card } from 'v3/components/pitstop';

class IntegrationPage extends Component {
  render() {
    return <Card>IntegrationPage</Card>;
  }
}

export default IntegrationPage;
