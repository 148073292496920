import React from 'react';

import VideoWrapper from 'components/FAQ/VideoWrapper';

let TrainingVideos = [
  {
    title: 'Dashboard Overview',
    content: (
      <React.Fragment>
        <span>
          Overview of Dashboard.
        </span>
        <br />
        <VideoWrapper
          title="Overview of Dashboard"
          embedVideoSrc="https://www.loom.com/embed/48717d5689794a6cb940afc65e30f43f"
          videoUrl="https://www.loom.com/share/48717d5689794a6cb940afc65e30f43f"
        />
      </React.Fragment>
    )
  },
  {
    title: 'Issues Overview',
    content: (
      <React.Fragment>
        <span>
          High Level Overview of Issues: How issues are created, where issues are found from dashboard. What issue priorities mean? Predictive issues vs dtc issues? How to edit, reprioritize and mark complete.
        </span>
        <br />
        <VideoWrapper
          title="Issues Overview"
          embedVideoSrc="https://www.loom.com/embed/0e45dc078935433abb87c9c5b0f3c523"
          videoUrl="https://www.loom.com/share/0e45dc078935433abb87c9c5b0f3c523"
        />
      </React.Fragment>
    )
  },
  {
    title: 'Issues Deep Dive',
    content: (
      <React.Fragment>
        <span>
          Overview of a detailed and complex issue explaining all fields in the issue page, mark complete, editing and prioritizing and prioritizing. How the issue moves from current to done columns. How do download pdf.
        </span>
        <br />
        <VideoWrapper
          title="Issues Deep Dive"
          embedVideoSrc="https://www.loom.com/embed/edf8d503f34e469386f6bcb382ece5c7"
          videoUrl="https://www.loom.com/share/edf8d503f34e469386f6bcb382ece5c7"
        />
      </React.Fragment>
    )
  },
];

let GeneralFAQ = [
  {
    title: 'How do you access the information?',
    content: (
      <React.Fragment>
        <span>
          As a fleet manager, you get a login to the platform where you can
          access all information collected of the vehicle.
        </span>
        <br />
        Login here:
        <br />
        <a href="dashboard.pitstopconnect.com">dashboard.pitstopconnect.com</a>
      </React.Fragment>
    )
  },
  {
    title: 'How is the information presented?',
    content: `
    Information is shown as a summary of important events/metrics on
    the reports tab of the dashboard. You can subscribe to summaries so
    they are sent via email on a daily/weekly basis.
    `
  },
  {
    title: 'Can I use my own obd2 device?',
    content: (
      <>
        Email or call our support team{' '}
        <a href="mailto:support@pitstopconnect.com">
          support@pitstopconnect.com
        </a>{' '}
        to get in touch.
      </>
    )
  },
  {
    title: 'Can I see vehicles in real-time?',
    content: `
    Once the device is connected successfully to the car the last known location
    and time it was seen is displayed. This updates every minute when the vehicle
    is on the road driving and once every 24 hours when parked.
    `
  },
  {
    title: 'Does the driver have access to the information of the vehicle?',
    content: `
    Fleet manager has an option to create a driver profile. Once the driver logins
    into the app/dashboard they will get access to the specific vehicles associated
    with their account.
    `
  },
  {
    title:
      'Can I see real fuel consumption of the vehicle? Can I organize/rank it to compare it with the entire fleet? ',
    content: `
    Yes; the platform shows a list of vehicles with fuel consumption ranked from highest to lowest.
    `
  },
  {
    title: 'How do I see the cost of each vehicle yearly?',
    content: `
    Right now you can view all the service history performed and what's coming up but it is not organized by cost.

    This is a great feature that we should add.
    `
  },
  {
    title:
      'Can I compare my drivers based on the vehicle costs such as maintenance and consumption?',
    content: `
    As of now you can compare drivers based on the safety profile of them. We do not associate maintenance to any specific user because that would blame people that may not be responsible. However driving safely is attributable to the specific person behind the wheel. The caveat is that we associate specific vehicles to drivers.

    One feature to add is to distinguish drivers from cars.
    `
  },
  {
    title: 'How frequently do you send data?',
    content: `
    Every minute when your driving and every 24 hours when the vehicle is parked.
    `
  },
  {
    title: 'How do you stop sending data from a device? ',
    content: `
    If you uninstall the hardware from the vehicle.
    `
  },
  {
    title:
      'My vehicle has a maintenance alert, is that different from Pitstop?',
    content: `
    Pitstop uses OEM maintenance schedule as a reference point and also processes other data points from your service centre, including service history. By doing this we try to deliver the most accurate upcoming maintenance for your vehicle. Often times the reminder in your car is a snapshot and Pitstop compliments it with more detailed information of your maintenance due.
    `
  },
  {
    title: 'How do I delete a car?',
    content: `
    In the vehicle profile there is a delete car button that can be pressed. Dont worry if you add the car back all of its history will be present.
    `
  },
  {
    title: 'Where is the data stored?',
    content: `
    Data is stored in the cloud using Amazon AWS and Google Big Query
    `
  },
  {
    title: 'Does this system replace my fleet manager?',
    content: `
    This is a tool to help automate mundane tasks for the fleet manager. The goal is to act as an assistant that provides insights to help take action immediately.
    `
  },
  {
    title: 'Can I view the current fuel level?',
    content: `
    Yes; Depends on the make model year (MMY) of your vehicle but most are supported.
    `
  }
];
let ConnectivityFAQ = [
  {
    title: 'Do I need to do anything to keep the device operational?',
    content: `
    Nope the device should work on its own.
    `
  },
  {
    title: 'How do I connect the device to the car?',
    content: `
    Plug the device into the OBDII port
    `
  },
  {
    title: 'Do I really need to plug a device into my car?',
    content: `
    If your vehicle is an Audi, GM, Lexus or VW in the United States we can connect directly to your vehicles connected car platform. Otherwise you do need to install an external device.
    `
  },
  {
    title: 'Do I need to charge the device?',
    content: `
    No the device is powered by the vehicle's alternator and battery.
    `
  },
  {
    title: 'How do I order a new device? ',
    content: (
      <>
        Email or call our support team{' '}
        <a href="mailto:support@pitstopconnect.com">
          support@pitstopconnect.com
        </a>{' '}
        to get in touch.
      </>
    )
  },
  {
    title: 'What temperatures does this work in? ',
    content: `
    +40 celcius to - 40 celcius.
    `
  },
  {
    title:
      'What happens if the device stops working? Does this device have a warranty?',
    content: `
    Yes! The Pitstop adapter has been thoroughly field tested and should anything go wrong with the hardware through normal operation, we have you covered for 3 years. Just ship it back to us and a new one will be shipped out.
    `
  },
  {
    title: 'Can this affect my vehicles warranty?',
    content: `
    Pitstop is a read only hardware that monitors general vehicle data. It does not write too or alter your vehicle's onboard computer in any way. For specifics depending on your vehicle you may contact your OEM’s customer support team. As stated above the adapter is covered under a warranty by Pitstop, for more details you will find terms and warranty information in your app’s settings page.
    `
  },
  {
    title: 'Can I keep the device in the car at all times?',
    content: `
    Yes the device is designed to constantly monitor the vehicle to look for anomalies. Every once in a while perform a scan so that it can upload the vehicle information. The power consumption is very low under 10mA which will not have any meaningful impact on the car battery.
    `
  },
  {
    title: 'How much data does it consume?',
    content: `
    10mb a month
    `
  },
  {
    title: 'How does it connect from the car to the dashboard?',
    content: `
    The connected car device is equipped with a sim card, much like your mobile phone. This encrypts data coming from the vehicle and sends it directly to the Pitstop servers. Our servers take this information run our machine learning models and output the information in a manner to make it easy to access.
    `
  },
  {
    title: 'Can I tell if the device has been unplugged?',
    content: `
    On the dashboard under the vehicle profile there will be a device status field that will display if the device is connected or not.
    `
  },
  {
    title: 'How can I tell if the device is working?',
    content: `
    On the dashboard under the vehicle profile there will be a device status field that will display if the device is connected or not.
    `
  }
];

let GeotabGuide = [
  {
    title: 'How to integrate GEOTAB on Pitstop?',
    content: (
      <>
        Please{' '}
        <a
          href="https://docs.google.com/document/u/1/d/e/2PACX-1vSgki_6lKzPz3ZRp01xaHagIJjHDpfVFTz0E75iqnJhe_oZvQNU9aRjHlJlJtAzUf1jWlI7KhbC1DuU/pub"
          target="blank"
        >
          Click here
        </a>{' '}
        for more details.
      </>
    )
  }
];

let CostFAQ = [
  {
    title: 'How much does it cost per vehicle per month?',
    content: (
      <>
        Email or call our support team{' '}
        <a href="mailto:support@pitstopconnect.com">
          support@pitstopconnect.com
        </a>{' '}
        to get in touch.
      </>
    )
  },
  {
    title: 'Can I demo the platform without purchasing a device?',
    content: (
      <>
        Email or call our support team{' '}
        <a href="mailto:support@pitstopconnect.com">
          support@pitstopconnect.com
        </a>{' '}
        to get in touch.
      </>
    )
  }
];
let PrivacyFAQ = [
  {
    title: 'How much does it cost per vehicle per month?',
    content: (
      <>
        Email or call our support team{' '}
        <a href="mailto:support@pitstopconnect.com">
          support@pitstopconnect.com
        </a>{' '}
        to get in touch.
      </>
    )
  },
  {
    title: 'Can I demo the platform without purchasing a device?',
    content: (
      <>
        Email or call our support team{' '}
        <a href="mailto:support@pitstopconnect.com">
          support@pitstopconnect.com
        </a>{' '}
        to get in touch.
      </>
    )
  }
];

export { TrainingVideos, GeneralFAQ, ConnectivityFAQ, CostFAQ, PrivacyFAQ, GeotabGuide };
