import React, { Component } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Spin } from 'antd';

import HighchartsReact from 'highcharts-react-official';
import ReportsDataStore from 'stores/ReportsDataStore';


class GroupedBarChart extends Component {
  render() {
    const { history } = this.props;
    let { xAxis, series, yAxis } = this.props.data;

    let engineIdleScoreReport = toJS(ReportsDataStore.engineIdleScoreReport);

    if (!this.props.loaded) {
      return (
        <Spin tip="Loading..." />
      );
    }

    /*if (_.isEmpty(value)) {
      return (
        <NoIssuesSection>
          <Icon type="check-circle" theme="twoTone" />
          <p>You have no {key} data!</p>
        </NoIssuesSection>
      );
    }*/

    return (
      <>
        <HighchartsReact
          options={
            {
            chart: {
              height: 500,
              type: 'column',
            },
            title: { text: undefined },
            exporting: { enabled: false },
            credits: { enabled: false },
            plotOptions: {
              series: {
                borderWidth: 0,
                dataLabels: {
                  enabled: true,
                  format: '{point.y:.2f}',
                },
                events: {
                  click: function (event) {
                    let clickedPointName = event.point.category;
                    let clickedCar = _.filter(
                      engineIdleScoreReport.data,
                      (report) => {
                        return report.carName === clickedPointName;}
                    );
                    if (!_.isEmpty(clickedCar)) {
                      history.push(`/car/${clickedCar[0].carId}/general`);
                    }
                  },
                },
              },
            },
            xAxis: xAxis,
            yAxis: yAxis,
            legend: {
              enabled: true,
            },
            tooltip: {
              enabled: false,
            },
            series: series,
          }}
        ></HighchartsReact>
          </>
    );
  }

  static propTypes = {
    data: PropTypes.object,
    history: PropTypes.object,
    loaded: PropTypes.bool
  };
}

export default withRouter(observer(GroupedBarChart));
