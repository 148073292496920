import React, { Component } from 'react';
import AddWarrantyModal from './AddWarrantyModal';
import WarrantyDetailsModal from './WarrantyDetailsModal';
import { Button, Table, Tag, notification } from 'antd';
import { startCase } from 'lodash';
import moment from 'moment-timezone';
import { AppStore, CurrentUserStore, ShopStore } from 'stores';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Logger } from 'stores/Classes';
import WarrantyStore from 'stores/WarrantyStore';
import { webServiceProvider } from 'shared';

const FormattedTable = styled(Table)`
  .ant-table-pagination.ant-pagination {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 12px;
  }
`;

class WarrantiesTable extends Component {
  state = {
    selectedRowKeys: [],
    isAddWarrantyModalVisible: false,
    warrantyToEdit: null,
    isUpdatingWarranty: false,
  };

  showEditWarrantyModal = async (record) => {
    try {
      AppStore.addLoading('Getting warranty components');
      const { components } = await WarrantyStore.getWarrantyComponents(
        record.id
      );
      record.components = components;
      this.setState({
        warrantyToEdit: record,
      });
    } catch (error) {
      Logger.error('Error getting warranty components', error);
      AppStore.addError('Error getting warranty components');
    } finally {
      AppStore.removeLoading('Getting warranty components');
    }
  };

  handleWarrantyDetailsModalSubmit = async ({ id, ...warrantyData }) => {
    try {
      this.setState({ isUpdatingWarranty: true });
      // upload documents if any
      if (warrantyData.documents && warrantyData.documents.length) {
        const newDocuments = warrantyData.documents.filter(
          (document) => !document.url
        );
        const newDocumentsAfterUpload = await Promise.all(
          newDocuments.map(async (file) => {
            const formData = new FormData();
            formData.append('singleInputFileName', file);
            try {
              const data = await webServiceProvider.postFormData(
                'dashboard/v1/uploadimage',
                formData
              );
              if (data.fileUrl) {
                return {
                  name: file.name,
                  url: data.fileUrl,
                };
              }
            } catch (e) {
              return null;
            }
          })
        );
        const validNewDocuments = newDocumentsAfterUpload.filter((doc) => doc);
        warrantyData.documents = [
          ...warrantyData.documents.filter((doc) => doc.url),
          ...validNewDocuments,
        ];
      }
      await WarrantyStore.updateWarranty(id, {
        ...warrantyData,
        shopId: ShopStore.currentShop.id,
        carId: warrantyData.assets[0],
        name: warrantyData.warrantyName,
        coverageType: warrantyData.coverageType,
        contactId: warrantyData.vendor,
        startDate: warrantyData.startDate,
        endDate: warrantyData.endDate,
        coverageDuration: warrantyData.coverageDuration,
        coverageDurationUnit: warrantyData.coverageDurationPeriod,
        startMeter: warrantyData.startMeter,
        endMeter: warrantyData.endMeter,
        rangeMeter: warrantyData.range,
        documents: warrantyData.documents,
        components: warrantyData.components,
      });
      notification.success({
        message: 'Success',
        description: 'Warranty updated successfully',
      });
      this.setState(
        {
          warrantyToEdit: null,
        },
        () => {
          if (this.props.onUpdate) {
            this.props.onUpdate();
          }
        }
      );
    } catch (error) {
      // notification.error({
      //   message: 'Error',
      //   description: error.message,
      // });
      Logger.error(error);
    } finally {
      this.setState({ isUpdatingWarranty: false });
    }
  };

  handleWarrantyModalSubmit = async (warrantiesToAdd) => {
    try {
      this.setState({ isLoading: true, isSavingWarranties: true });
      // for each assets, create a warranty
      const formattedWarranties = warrantiesToAdd.reduce((acc, warranty) => {
        warranty.assets.forEach((assetId) => {
          acc.push({
            carId: assetId,
            name: warranty.warrantyName,
            coverageType: warranty.coverageType,
            contactId: warranty.vendor,
            startDate: warranty.startDate,
            endDate: warranty.endDate,
            coverageDuration: warranty.coverageDuration,
            coverageDurationUnit: warranty.coverageDurationPeriod,
            startMeter: warranty.startMeter,
            endMeter: warranty.endMeter,
            rangeMeter: warranty.range,
            documents: warranty.documents,
            components: warranty.components,
          });
        });
        return acc;
      }, []);
      // upload documents if any
      await Promise.all(
        formattedWarranties.map(async (warranty) => {
          if (warranty.documents && warranty.documents.length) {
            const documents = await Promise.all(
              warranty.documents.map(async (file) => {
                const formData = new FormData();
                formData.append('singleInputFileName', file);
                try {
                  const data = await webServiceProvider.postFormData(
                    'dashboard/v1/uploadimage',
                    formData
                  );
                  if (data.fileUrl) {
                    return {
                      name: file.name,
                      url: data.fileUrl,
                    };
                  }
                } catch (e) {
                  return null;
                }
              })
            );
            warranty.documents = documents.filter((doc) => doc);
          }
        })
      );
      await WarrantyStore.createWarranties(
        formattedWarranties.map((warrantyToAdd) => {
          return {
            ...warrantyToAdd,
            shopId: ShopStore.currentShop.id,
          };
        })
      );
      notification.success({
        message: 'Success',
        description: 'Warranties added successfully',
      });
      this.setState(
        {
          isAddWarrantyModalVisible: false,
        },
        () => {
          if (this.props.onUpdate) {
            this.props.onUpdate();
          }
        }
      );
    } catch (error) {
      // notification.error({
      //   message: 'Error',
      //   description: error.message,
      // });
      Logger.error(error);
    } finally {
      this.setState({ isLoading: false, isSavingWarranties: false });
    }
  };

  render() {
    const { selectedRowKeys = [] } = this.state;
    const {
      data = [],
      assets,
      vendors,
      isLoading = false,
      hasSelection = false,
    } = this.props;

    const statusColors = {
      Active: 'green',
      'Expiring Soon': 'orange',
      Expired: 'red',
    };

    const userTimezone =
      CurrentUserStore.user?.settings?.timezone || 'America/Toronto';

    const miKmUserSettings =
      CurrentUserStore.user?.settings?.odometer === 'km' ? 'km' : 'mi';

    const columns = [
      {
        title: 'Unit ID',
        dataIndex: 'id_car',
        key: 'id_car',
        render: (id_car) => {
          const asset = assets.find((asset) => asset.id === id_car);
          return asset ? asset.unitId || asset.carName : id_car;
        },
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filters: [
          { text: 'Active', value: 'Active' },
          { text: 'Expiring Soon', value: 'Expiring Soon' },
          { text: 'Expired', value: 'Expired' },
        ],
        sorter: true,
        render: (status) => (
          <Tag color={statusColors[status]} key={status}>
            {status}
          </Tag>
        ),
      },
      {
        title: 'Coverage Type',
        dataIndex: 'coverage_type',
        key: 'coverage_type',
        sorter: true,
        render: (coverage_type) => {
          return startCase(coverage_type);
        },
      },
      {
        title: 'Vendor',
        dataIndex: 'id_contact',
        key: 'id_contact',
        render: (vendorId) => {
          const vendor = vendors.find((vendor) => vendor.id === vendorId);
          return vendor ? vendor.name : vendorId;
        },
      },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        key: 'start_date',
        render: (start_date) => {
          return moment(start_date)
            .tz(userTimezone)
            .format('ll');
        },
      },
      {
        title: 'End Date',
        dataIndex: 'end_date',
        key: 'end_date',
        sorter: true,
        render: (end_date) => {
          return moment(end_date)
            .tz(userTimezone)
            .format('ll');
        },
      },
      {
        title: 'Start Meter',
        dataIndex: 'start_meter',
        key: 'start_meter',
        render: (start_meter) => {
          return `${start_meter} ${miKmUserSettings}`;
        },
      },
      {
        title: 'Range',
        dataIndex: 'range_meter',
        key: 'range_meter',
        render: (range_meter) => {
          return `${range_meter} ${miKmUserSettings}`;
        },
      },
      {
        title: 'End Meter',
        dataIndex: 'end_meter',
        key: 'end_meter',
        sorter: true,
        render: (end_meter) => {
          return `${end_meter} ${miKmUserSettings}`;
        },
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <Button
            type="link"
            icon="edit"
            onClick={() => {
              this.showEditWarrantyModal(record);
            }}
          >
            Edit
          </Button>
        ),
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <>
        <FormattedTable
          rowKey="id"
          rowSelection={hasSelection ? rowSelection : undefined}
          columns={columns}
          dataSource={data}
          loading={isLoading}
          onChange={(pagination, filters, sorter) => {
            // this.setState({ pagination, filters, sorter }, () => {
            //   this.init();
            // });
            if (this.props.onChangePagination) {
              this.props.onChangePagination({
                pagination,
                filters,
                sorter,
              });
            }
          }}
          pagination={{
            ...this.state.pagination,
          }}
        />
        <Button
          type="link"
          icon="plus-circle"
          style={{ marginTop: 10 }}
          onClick={() => {
            this.setState({
              isAddWarrantyModalVisible: true,
            });
          }}
        >
          Add New Warranty
        </Button>
        <AddWarrantyModal
          visible={this.state.isAddWarrantyModalVisible}
          finishing={this.state.isSavingWarranties}
          preSelectedAssets={this.props.preSelectedAssets || []}
          onOk={(warrantiesToAdd) => {
            this.handleWarrantyModalSubmit(warrantiesToAdd);
          }}
          onCancel={() => {
            this.setState({
              isAddWarrantyModalVisible: false,
            });
          }}
        />
        <WarrantyDetailsModal
          visible={this.state.warrantyToEdit !== null}
          saving={this.state.isUpdatingWarranty}
          warranty={this.state.warrantyToEdit}
          assets={this.state.assets}
          vendors={this.state.vendors}
          onCancel={() => {
            this.setState({
              warrantyToEdit: null,
            });
          }}
          onSubmit={(data) => {
            this.handleWarrantyDetailsModalSubmit(data);
          }}
        />
      </>
    );
  }
}

WarrantiesTable.propTypes = {
  hasSelection: PropTypes.bool,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  assets: PropTypes.array,
  vendors: PropTypes.array,
  onUpdate: PropTypes.func,
  onChangePagination: PropTypes.func,
  preSelectedAssets: PropTypes.array,
};

WarrantiesTable.defaultProps = {
  hasSelection: false,
  isLoading: false,
  data: [],
  assets: [],
  vendors: [],
  onUpdate: null,
  onChangePagination: null,
  preSelectedAssets: [],
};

export default WarrantiesTable;
