import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { PitstopModal } from 'shared';
import ContactForm from 'components/Contacts/ContactForm';
import styled from 'styled-components';

const StyledModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      .ant-modal-title {
        color: #ffffff;
      }
    }
  }
`;

const ContactFormModal = ({
  modalId,
  title,
  onCancel,
  onSubmit,
  saving,
  defaultData,
}) => {
  return (
    <StyledModal id={modalId} title={title} footer={null}>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <ContactForm
            defaultData={defaultData}
            onCancel={onCancel}
            onSubmit={onSubmit}
            saving={saving}
          />
        </Col>
      </Row>
    </StyledModal>
  );
};

ContactFormModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  defaultData: PropTypes.object,
};

ContactFormModal.defaultProps = {
  title: 'Add Vendor',
  onCancel: () => {},
  saving: false,
  defaultData: {},
};

export default ContactFormModal;
