import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import PropTypes from 'prop-types';
import Promise from 'bluebird';
import styled from 'styled-components';
import _ from 'lodash';

import {
  Col,
  Switch,
  Button,
  Icon,
  message,
  Dropdown,
  Menu,
  Row,
  Modal,
  Spin,
  Empty,
} from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  download,
  webServiceProvider,
  customFleets,
  reportConfig,
} from 'shared';

import AppStore from 'stores/App';
import TripDetailArea from './TripDetailArea';
import { ShopStore } from 'stores';

const { confirm } = Modal;

const TripMenubarText = styled.p`
  font-size: 14px;
  margin: auto 10px;
`;

const TripMenubarButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class Utilities extends Component {
  static propTypes = {
    history: PropTypes.object,
    trips: PropTypes.object,
    selectedTrip: PropTypes.object,
    car: PropTypes.object,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    openSimilarTrips: PropTypes.func,
    openCompareTrips: PropTypes.func,
    getTrips: PropTypes.func,
    setIsAllTripsOnMap: PropTypes.func,
  };

  state = {
    isSnapToRoadToggled: false,
    isPIDToggled: this.isPIDToggled,
    isEmissionToggled: false,
    isDownloadTripAttachments: false,
    isDownloadSensorDataCsvWithPitstopMeta: false,
    isDownloadSensorDataCsv: false,
    isDownloadAllSensorDataCsv: false,
    isDownloadTripsCsv: false,
    isDownloadPidData: false,
    isAllTripsOnMap: false,
    similarTrips: false,
    compareTrips: false,
  };

  get history() {
    return this.props.history;
  }

  get isPIDToggled() {
    return customFleets.npl.includes(this.shopId) ? true : false;
  }

  get shopId() {
    return this.props.car.shopId || ShopStore.currentShop.id;
  }

  onDownloadTripAttachments = async () => {
    let { selectedTrip } = this.props;

    try {
      this.setState({
        isDownloadTripAttachments: true,
      });

      await selectedTrip.getAttachments();

      if (!selectedTrip.isError) {
        let { attachments } = selectedTrip;

        if (_.isEmpty(attachments)) {
          AppStore.addNotification('There are no files to download!');
        } else {
          await Promise.map(
            attachments,
            async (file) => {
              let _file = file['Key'].split('/');
              let fileName = _file[_file.length - 1];

              let response = await webServiceProvider.fetchCSV(
                'v1/attachments',
                {
                  filename: file['Key'],
                }
              );
              download(response, fileName);
            },
            { concurrency: 3 }
          );

          AppStore.addSuccess('Your files are downloaded successfully!');
        }
      }
    } catch (err) {
      AppStore.addError('Sorry! We cannot download your files at the moment!');
    } finally {
      this.setState({
        isDownloadTripAttachments: false,
      });
    }
  };

  onDownloadSensorDataCSV = async (options) => {
    let { car, selectedTrip } = this.props;

    try {
      if (options.includePitstopMetaData) {
        this.setState({
          isDownloadSensorDataCsvWithPitstopMeta: true,
        });
      } else {
        this.setState({
          isDownloadSensorDataCsv: true,
        });
      }

      await car.downloadSensorDataCSV({ trip: selectedTrip, ...options });
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      if (options.includePitstopMetaData) {
        this.setState({
          isDownloadSensorDataCsvWithPitstopMeta: false,
        });
      } else {
        this.setState({
          isDownloadSensorDataCsv: false,
        });
      }
    }
  };

  onDownloadAllSensorDataCSV = async (options) => {
    let { car } = this.props;

    try {
      this.setState({
        isDownloadAllSensorDataCsv: true,
      });

      await Promise.map(
        car.trips.data,
        async (trip) => {
          await car.downloadSensorDataCSV({ trip, ...options });
        },
        { concurrency: 3 }
      );
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      this.setState({
        isDownloadAllSensorDataCsv: false,
      });
    }
  };

  onDownloadTripsCsv = async (options) => {
    try {
      this.setState({
        isDownloadTripsCsv: true,
      });

      await this.props.car.downloadTripsCSV(options);
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      this.setState({
        isDownloadTripsCsv: false,
      });
    }
  };

  onDownloadGMRVPidData = async () => {
    try {
      this.setState({
        isDownloadPidData: true,
      });

      AppStore.addLoading('Getting sensor data...');

      await this.props.car.downloadGMRVPidData({
        trip: this.props.selectedTrip,
        order: 'ASC',
      });

      AppStore.addSuccess('Getting PID data is done!');
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      this.setState({
        isDownloadPidData: false,
      });
      AppStore.removeLoading('Getting sensor data...');
    }
  };

  showDeleteConfirm(deleteFunction) {
    confirm({
      title: 'Are you sure that you want to delete this trip?',
      content: 'Deleting a trip cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteFunction();
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  deleteTrip = async () => {
    try {
      await webServiceProvider.delete(
        `v1/trip/${this.props.selectedTrip.tripId}`
      );
      message.success('Trip successfully deleted!');

      if (this.history.location.pathname.includes('/sharetrip')) {
        this.history.push('/report');
      }

      this.props.getTrips && (await this.props.getTrips());
    } catch (e) {
      AppStore.addError(e);
    }
  };

  menu = () => {
    let {
      isDownloadTripAttachments,
      isDownloadSensorDataCsvWithPitstopMeta,
      isDownloadSensorDataCsv,
      isDownloadAllSensorDataCsv,
      isDownloadTripsCsv,
      isDownloadPidData,
    } = this.state;

    let { page, pageSize } = this.props;

    let menuItems = [
      <Menu.Item key="1" onClick={() => this.onDownloadTripAttachments()}>
        {!isDownloadTripAttachments ? (
          <>Download all attachments from the current Trip</>
        ) : (
          <Icon type="loading" />
        )}
      </Menu.Item>,

      <Menu.Item
        key="2"
        onClick={() =>
          this.onDownloadSensorDataCSV({
            includePitstopMetaData: true,
          })
        }
      >
        {!isDownloadSensorDataCsvWithPitstopMeta ? (
          <>Download Sensor Data for current trip</>
        ) : (
          <Icon type="loading" />
        )}
      </Menu.Item>,

      <Menu.Item
        key="3"
        onClick={() =>
          this.onDownloadSensorDataCSV({
            includePitstopMetaData: false,
          })
        }
      >
        {!isDownloadSensorDataCsv ? (
          <>Download Sensor Data for current trip (without Pitstop metadata)</>
        ) : (
          <Icon type="loading" />
        )}
      </Menu.Item>,

      <Menu.Item
        key="4"
        disabled={this.history.location.pathname.includes('/sharetrip')}
        onClick={() =>
          this.onDownloadAllSensorDataCSV({
            includePitstopMetaData: true,
          })
        }
      >
        {!isDownloadAllSensorDataCsv ? (
          <>Download Sensor Data for all trips on page</>
        ) : (
          <Icon type="loading" />
        )}
      </Menu.Item>,

      <Menu.Item
        key="5"
        disabled={this.history.location.pathname.includes('/sharetrip')}
        onClick={() =>
          this.onDownloadTripsCsv({
            includePitstopMetaData: true,
            offset: (page - 1) * pageSize,
            pageSize: pageSize,
          })
        }
      >
        {!isDownloadTripsCsv ? (
          <>Download Trips as CSV</>
        ) : (
          <Icon type="loading" />
        )}
      </Menu.Item>,
    ];

    if (customFleets.gmrv.includes(this.shopId)) {
      menuItems = _.filter(menuItems, (item) => ['1'].includes(item.key));
      menuItems.push(
        <Menu.Item key="6" onClick={() => this.onDownloadGMRVPidData()}>
          {!isDownloadPidData ? (
            <>Download Sensor Data (Only Numbers) for current trip</>
          ) : (
            <Icon type="loading" />
          )}
        </Menu.Item>
      );
    }

    return <Menu>{_.map(menuItems, (item) => item)}</Menu>;
  };

  render() {
    let {
      isSnapToRoadToggled,
      isPIDToggled,
      isEmissionToggled,
      isDownloadSensorDataCsv,
      isDownloadSensorDataCsvWithPitstopMeta,
      isDownloadAllSensorDataCsv,
      isDownloadTripsCsv,
      isDownloadTripAttachments,
      isAllTripsOnMap,
    } = this.state;

    let { car, trips, selectedTrip } = this.props;

    if (trips && !trips.loaded)
      return <Spin style={{ width: '100%' }} tip="Loading..." size="large" />;

    if ((trips && _.isEmpty(trips.data)) || !selectedTrip) return <Empty />;

    return (
      <>
        <Col
          style={{
            height: this.history.location.pathname.includes('/sharetrip')
              ? 65
              : 95,
            marginBottom:
              this.history.location.pathname.includes('/sharetrip') && 30,
            padding: 0,
          }}
          span={24}
        >
          <Col
            style={{
              padding: 0,
              marginBottom: this.history.location.pathname.includes(
                '/sharetrip'
              )
                ? 10
                : 0,
            }}
            xxl={10}
            sm={24}
          >
            {customFleets.isMultipleTrips.includes(this.shopId) && (
              <Col style={{ padding: '8px 0px' }} span={6}>
                <Col xxl={24} sm={6}>
                  <Switch
                    defaultChecked={isAllTripsOnMap}
                    onChange={() =>
                      this.setState({ isAllTripsOnMap: !isAllTripsOnMap }, () =>
                        this.props.setIsAllTripsOnMap(
                          this.state.isAllTripsOnMap
                        )
                      )
                    }
                  />
                </Col>
                <Col xxl={24} sm={18}>
                  All Trips On Map
                </Col>
              </Col>
            )}

            {!customFleets.gmrv.includes(this.shopId) &&
              !customFleets.npl.includes(this.shopId) && (
                <Col style={{ padding: '8px 0px' }} span={6}>
                  <Col xxl={24} sm={6}>
                    <Switch
                      disabled={isAllTripsOnMap}
                      defaultChecked={isSnapToRoadToggled}
                      onChange={() =>
                        this.setState({
                          isSnapToRoadToggled: !isSnapToRoadToggled,
                        })
                      }
                    />
                  </Col>
                  <Col
                    style={{
                      padding: 0,
                    }}
                    xxl={24}
                    sm={18}
                  >
                    Smooth GPS Plot
                  </Col>
                </Col>
              )}

            <Col style={{ padding: '8px 0px' }} span={6}>
              <Col xxl={24} sm={6}>
                <Switch
                  disabled={isAllTripsOnMap}
                  defaultChecked={isPIDToggled}
                  onChange={() =>
                    this.setState({ isPIDToggled: !isPIDToggled })
                  }
                />
              </Col>

              <Col
                style={{
                  padding: 0,
                }}
                xxl={24}
                sm={18}
              >
                PID Data
              </Col>
            </Col>

            {reportConfig.enabledEmissionTableFleets.includes(this.shopId) && (
              <>
                <Col style={{ padding: '8px 0px' }} span={6}>
                  <Col xxl={24} sm={6}>
                    <Switch
                      disabled={isAllTripsOnMap}
                      defaultChecked={isEmissionToggled}
                      onChange={() =>
                        this.setState({ isEmissionToggled: !isEmissionToggled })
                      }
                    />
                  </Col>
                  <Col
                    style={{
                      padding: 0,
                    }}
                    xxl={24}
                    sm={18}
                  >
                    Emission Table
                  </Col>
                </Col>
              </>
            )}
          </Col>

          <Col
            style={{
              padding: '8px 0px',
            }}
            xxl={14}
            sm={24}
          >
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <CopyToClipboard
                  text={`https://dashboard.pitstopconnect.com/car/${this.props.car.id}/sharetrip/${selectedTrip.tripId}`}
                  onCopy={() => message.success('Link copied to clipboard!')}
                >
                  <TripMenubarButton>
                    <Icon type="share-alt" />
                    <TripMenubarText>Share</TripMenubarText>
                  </TripMenubarButton>
                </CopyToClipboard>
              </Col>

              <Col span={6}>
                <Dropdown
                  overlay={this.menu()}
                  style={{
                    display: 'flex',
                    alignSelf: 'right',
                    width: '120px',
                  }}
                >
                  <TripMenubarButton>
                    {(() => {
                      const isLoading =
                        isDownloadSensorDataCsv ||
                        isDownloadSensorDataCsvWithPitstopMeta ||
                        isDownloadAllSensorDataCsv ||
                        isDownloadTripsCsv ||
                        isDownloadTripAttachments;
                      if (isLoading) return <Icon type="loading" />;

                      return (
                        <>
                          <Icon type="download" />
                          <TripMenubarText>Downloads</TripMenubarText>
                          <Icon type="down" />
                        </>
                      );
                    })()}
                  </TripMenubarButton>
                </Dropdown>
              </Col>

              {!this.history.location.pathname.includes('/sharetrip') && (
                <Col span={6}>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          style={{ alignSelf: 'right' }}
                          onClick={() => this.props.openSimilarTrips()}
                        >
                          <TripMenubarText>View Similar Trips</TripMenubarText>
                        </Menu.Item>
                        <Menu.Item
                          style={{ alignSelf: 'right' }}
                          onClick={() => this.props.openCompareTrips()}
                        >
                          <TripMenubarText>
                            Select Trips Manually
                          </TripMenubarText>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <TripMenubarButton>
                      <TripMenubarText>Compare Trips</TripMenubarText>
                      <Icon type="down" />
                    </TripMenubarButton>
                  </Dropdown>
                </Col>
              )}

              <Col span={6}>
                <TripMenubarButton
                  type="danger"
                  onClick={() => this.showDeleteConfirm(this.deleteTrip)}
                >
                  <TripMenubarText>Delete Trip</TripMenubarText>
                </TripMenubarButton>
              </Col>
            </Row>
          </Col>
        </Col>

        <TripDetailArea
          car={car}
          isAllTripsOnMap={isAllTripsOnMap}
          selectedTrip={selectedTrip}
          showAnalytics={isPIDToggled}
          showEmissionTable={isEmissionToggled}
          snapToRoad={isSnapToRoadToggled}
        />
      </>
    );
  }
}

decorate(Utilities, {
  shopId: computed,
});

export default withRouter(observer(Utilities));
