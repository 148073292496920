import { action, decorate, observe } from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';

import CurrentUserStore from './CurrentUserStore';
import ShopStore from './ShopStore';
import { AsyncData } from './abstract';
import { webServiceProvider } from 'shared';

// const serverUrl = process.env.REACT_APP_CHAT_API_URL;

class ChatStoreC {
  constructor() {
    // eslint-disable-next-line no-undef
    // this.socket = io(`${serverUrl}`);
    this.socket = {
      connect: () => {},
      disconnect: () => {},
      emit: () => {},
      on: () => {},
    };
    this.chatHistory = new AsyncData();
    this.unreadMessagesCount = new AsyncData();

    this.setup();
  }

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    if (ShopStore.currentShop.id !== -1) {
      this.setup();
    } else {
      this.socket.disconnect();
    }
  });

  setup = async () => {
    await CurrentUserStore.getFleetManagersDriversMap();
    this.socket.connect();
    this.joinRoom();
    this.listenChatHistory();
  };

  joinRoom = () => {
    let userRole = _.get(CurrentUserStore.user, 'role');

    // join all users room
    if (userRole === 'dealership') {
      _.forEach(CurrentUserStore.fleetManagersDrivers.data, (user) => {
        this.socket.emit('joinRoom', user.id);
        this.socket.emit('getChatHistory', user.id);
        this.socket.on('getChatMessage', (msg) => {
          if (msg[0].status === 'OK') {
            this.socket.emit('getChatHistory', user.id);
          }
        });
      });
    }

    // join current user room
    if (userRole === 'customer') {
      this.socket.emit('joinRoom', CurrentUserStore.user.id);
      this.socket.emit('getChatHistory', CurrentUserStore.user.id);
      this.socket.on('getChatMessage', (msg) => {
        if (msg[0].status === 'OK') {
          this.socket.emit('getChatHistory', CurrentUserStore.user.id);
        }
      });
    }
  };

  listenChatHistory = () => {
    let chatUsers = _.map(
      CurrentUserStore.fleetManagersDrivers.data,
      (user) => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        lastMessage: '',
        unreadMessage: 0,
      })
    );

    this.socket.on('chatHistory', (chatHistory) => {
      this.chatHistory.loaded = false;

      let indexOfChatUserId = _.findIndex(this.chatHistory.data, {
        userId: chatHistory.userId,
      });

      if (indexOfChatUserId === -1) {
        this.chatHistory.data.push(chatHistory);
      } else {
        this.chatHistory.data[indexOfChatUserId] = chatHistory;
      }

      CurrentUserStore.notifications.set(chatHistory.userId, {
        data: chatHistory.data,
      });

      if (this.chatHistory.data.length === chatUsers.length) {
        this.chatHistory.loaded = true;
      }
    });
  };

  hasReadMessage = async (userId) => {
    await webServiceProvider.post(`v1/user/${userId}/hasReadConversation`);

    this.socket.emit('getChatHistory', userId);
  };

  sendMessage = async (userId, msg) => {
    this.socket.emit('chatMessage', {
      userId,
      msg,
    });
  };
}

decorate(ChatStoreC, {
  listenChatHistory: action,
});

const ChatStore = new ChatStoreC();

export default observer(ChatStore);
