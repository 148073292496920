import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);

const ButtonStyled = styled.button`
  z-index: 1;
  position: relative;
  float: right;
  right: 3%;
  top: 35px;
`;

export default function ScatterPlot({ options, turboThreshold }) {
  const [chartOptions, setChartOptions] = useState(null);
  const [internalChart, setInternalChart] = useState(null);

  useEffect(() => setChartOptions(options), [options]);

  const getChartOptions = () => {
    return {
      ...chartOptions,
      exporting: {
        enabled: true
      },
      boost: {
        useGPUTranslations: true,
        usePreallocated: true
      },
      chart: {
        type: 'scatter',
        zoomType: 'xy',
        animation: false,
        events: {
          redraw: function () {
            this.hideLoading();
          },
          selection: function (e) {
            var xAxis = e.xAxis[0],
              yAxis = e.yAxis[0],
              xMin = xAxis.min,
              xMax = xAxis.max,
              yMin = yAxis.min,
              yMax = yAxis.max;

            this.showLoading();

            // run async
            setTimeout(function () {
              xAxis.axis.setExtremes(xMin, xMax, false, false);
              yAxis.axis.setExtremes(yMin, yMax, true, false);
            }, 1);

            // prevent default zoom
            return false;
          }
        }
      },
      plotOptions: {
        series: {
          ...options.plotOptions.series,
          states: {
            inactive: {
              opacity: 1
            }
          },
          turboThreshold: turboThreshold
        },
        scatter: {
          marker: {
            radius: 4
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          }
        }
      }
    };
  };

  return (
    <div className="graphView">
      <ButtonStyled
        onClick={() => {
          internalChart.showLoading();
          setTimeout(() => {
            internalChart.xAxis[0].setExtremes(null, null);
            internalChart.yAxis[0].setExtremes(null, null);
          }, 1000);
        }}
      >
        Reset Zoom
      </ButtonStyled>
      <HighchartsReact
        highcharts={Highcharts}
        options={getChartOptions()}
        callback={chart => setInternalChart(chart)}
        containerProps={{
          style: {
            height: 450,
            width: document.getElementsByClassName('graphView')[0]
              ? document.getElementsByClassName('graphView')[0].offsetWidth - 10
              : '100%'
          }
        }}
      />
    </div>
  );
}

ScatterPlot.propTypes = {
  options: PropTypes.object,
  turboThreshold: PropTypes.number
};
