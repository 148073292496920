import React, { Component } from 'react';

import { Button, Collapse, Icon } from 'antd';

import { AppStore } from 'stores';
import UploadCsvForm from 'containers/Form/UploadServiceForm';
import ServiceHistoryForm from 'containers/Form/ServiceHistoryForm';

const { Panel } = Collapse;

class ServiceHistory extends Component {
  render() {
    return (
      <>
        <h5>Upload Service History/Repair Order Data</h5>
        <p>Choose one of these options</p>

        <Collapse accordion defaultActiveKey={[]}>
          <Panel key="uploadCSV" header="Upload CSV">
            <UploadCsvForm />
          </Panel>

          <Panel key="connectDMS" header="Connect with DMS">
            <Button
              onClick={() =>
                AppStore.addSuccess(
                  'A customer team will get back to you. Thanks!'
                )
              }
            >
              <Icon type="team" />
              Connect with DMS
            </Button>
          </Panel>

          <Panel key="serviceHistoryForm" header="Service History Form">
            <ServiceHistoryForm />
          </Panel>
        </Collapse>
      </>
    );
  }
}

export default ServiceHistory;
